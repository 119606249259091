export const SELECT_PLAYER = "SELECT_PLAYER";
export const DESELECT_PLAYER = "DESELECT_PLAYER";
export const SEARCH_PLAYER = "SEARCH_PLAYER";
export const FILTER_BY_STATUS = "FILTER_BY_STATUS";
export const FILTER_BY_RISK = "FILTER_BY_RISK";
export const FILTER_BY_CONSENT = "FILTER_BY_CONSENT";
export const FILTER_BY_TEAM = "FILTER_BY_TEAM";
export const RESET_SEARCH = "RESET_SEARCH";
export const FILTER_BY_CONFERENCE = "FILTER_BY_CONFERENCE";
export const FILTER_BY_SPORT = "FILTER_BY_SPORT";
//
export const REQUEST_GET_TEAMS = "REQUEST_GET_TEAMS";
export const GET_CANDIDATE_TEAMS_SUCCESS = "GET_CANDIDATE_TEAMS_SUCCESS";
export const GET_ALL_CANDIDATE_TEAMS_SUCCESS = "GET_ALL_CANDIDATE_TEAMS_SUCCESS";
export const GET_ORGANIZATION_TEAMS_SUCCESS = "GET_ORGANIZATION_TEAMS_SUCCESS";
export const GET_TEAMS_FAILED = "GET_TEAMS_FAILED";
//
export const REQUEST_GET_PLAYERS = "REQUEST_PLAYERS";
export const GET_PLAYERS_SUCCESS = "GET_PLAYERS_SUCCESS";
export const GET_PLAYERS_FAILED = "GET_PLAYERS_FAILED";
//
export const REQUEST_GET_CANDIDATES = "REQUEST_GET_CANDIDATES";
export const GET_CANDIDATES_SUCCESS = "GET_CANDIDATES_SUCCESS";
export const GET_CANDIDATES_FAILED = "GET_CANDIDATES_FAILED";
//
export const REQUEST_GET_PLAYER = "REQUEST_GET_PLAYER";
export const GET_PLAYER_SUCCESS = "GET_PLAYER_SUCCESS";
export const GET_PLAYER_FAILED = "GET_PLAYER_FAILED";
//
export const REQUEST_UPLOAD_PLAYERS = "REQUEST_UPLOAD_PLAYERS";
export const UPLOAD_PLAYERS_SUCCESS = "UPLOAD_PLAYERS_SUCCESS";
export const UPLOAD_PLAYERS_FAILED = "UPLOAD_PLAYERS_FAILED";
//
export const REQUEST_CREATE_PLAYER = "REQUEST_CREATE_PLAYER";
export const CREATE_PLAYER_SUCCESS = "CREATE_PLAYER_SUCCESS";
export const CREATE_PLAYER_FAILED = "CREATE_PLAYER_FAILED";
//
export const REQUEST_GET_PLAYER_FEED = "REQUEST_GET_PLAYER_FEED";
export const GET_PLAYER_FEED_SUCCESS = "GET_PLAYER_FEED_SUCCESS";
export const GET_PLAYER_FEED_FAILED = "GET_PLAYER_FEED_FAILED";
export const LOADING_FEED = "LOADING_FEED";
//
export const REQUEST_EXPORT_FEED = "REQUEST_EXPORT_FEED";
export const EXPORT_FEED_SUCCESS = "EXPORT_FEED_SUCCESS";
export const EXPORT_FEED_FAILED = "EXPORT_FEED_FAILED";
//
export const REQUEST_ADD_RISK_COMMENT = "REQUEST_ADD_RISK_COMMENT";
export const ADD_RISK_COMMENT_SUCCESS = "ADD_RISK_COMMENT_SUCCESS";
export const ADD_RISK_COMMENT_FAILED = "ADD_RISK_COMMENT_FAILED";
//
export const REQUEST_DELETE_RISK_COMMENT = "REQUEST_DELETE_RISK_COMMENT";
export const DELETE_RISK_COMMENT_SUCCESS = "DELETE_RISK_COMMENT_SUCCESS";
export const DELETE_RISK_COMMENT_FAILED = "DELETE_RISK_COMMENT_FAILED";
//
export const REQUEST_ADD_ACTION_PLAN_COMMENT =
  "REQUEST_ADD_ACTION_PLAN_COMMENT";
export const ADD_ACTION_PLAN_COMMENT_SUCCESS =
  "ADD_ACTION_PLAN_COMMENT_SUCCESS";
export const ADD_ACTION_PLAN_COMMENT_FAILED = "ADD_ACTION_PLAN_COMMENT_FAILED";
//
export const REQUEST_DELETE_ACTION_PLAN_COMMENT =
  "REQUEST_DELETE_ACTION_PLAN_COMMENT";
export const DELETE_ACTION_PLAN_COMMENT_SUCCESS =
  "DELETE_ACTION_PLAN_COMMENT_SUCCESS";
export const DELETE_ACTION_PLAN_COMMENT_FAILED =
  "DELETE_ACTION_PLAN_COMMENT_FAILED";
//
export const REQUEST_ADD_EXECUTIVE_SUMMERY_COMMENT =
  "REQUEST_ADD_EXECUTIVE_SUMMERY_COMMENT";
export const ADD_EXECUTIVE_SUMMERY_COMMENT_SUCCESS =
  "ADD_EXECUTIVE_SUMMERY_COMMENT_SUCCESS";
export const ADD_EXECUTIVE_SUMMERY_COMMENT_FAILED =
  "ADD_EXECUTIVE_SUMMERY_COMMENT_FAILED";

//
export const REQUEST_DELETE_EXECUTIVE_SUMMERY_COMMENT =
  "REQUEST_DELETE_EXECUTIVE_SUMMERY_COMMENT";
export const DELETE_EXECUTIVE_SUMMERY_COMMENT_SUCCESS =
  "DELETE_EXECUTIVE_SUMMERY_COMMENT_SUCCESS";
export const DELETE_EXECUTIVE_SUMMERY_COMMENT_FAILED =
  "DELETE_EXECUTIVE_SUMMERY_COMMENT_FAILED";
//
export const REQUEST_APPROVE_CANDIDATE_PROFILE =
  "REQUEST_APPROVE_CANDIDATE_PROFILE";
export const APPROVE_CANDIDATE_PROFILE_SUCCESS =
  "APPROVE_CANDIDATE_PROFILE_SUCCESS";
export const APPROVE_CANDIDATE_PROFILE_FAILED =
  "APPROVE_CANDIDATE_PROFILE_FAILED";

//
export const REQUEST_ANALYZE_CANDIDATE_DETAILS = "REQUEST_ANALYZE_CANDIDATE_DETAILS";
export const ANALYZE_CANDIDATE_DETAILS_SUCCESS = "ANALYZE_CANDIDATE_DETAILS_SUCCESS";
export const ANALYZE_CANDIDATE_DETAILS_FAILED = "ANALYZE_CANDIDATE_DETAILS_FAILED";
//
export const REQUEST_ANALYZE_CANDIDATE_RESULTS = "REQUEST_ANALYZE_CANDIDATE_RESULTS";
export const ANALYZE_CANDIDATE_RESULTS_SUCCESS = "ANALYZE_CANDIDATE_RESULTS_SUCCESS";
export const ANALYZE_CANDIDATE_RESULTS_FAILED = "ANALYZE_CANDIDATE_RESULTS_FAILED";
//
export const REQUEST_CANDIDATE_SENTIMENT_RESULTS = "REQUEST_CANDIDATE_SENTIMENT_RESULTS";
export const CANDIDATE_SENTIMENT_RESULTS_SUCCESS = "CANDIDATE_SENTIMENT_RESULTS_SUCCESS";
export const CANDIDATE_SENTIMENT_RESULTS_FAILED = "CANDIDATE_SENTIMENT_RESULTS_FAILED";

//
export const REQUEST_EXPORT_PLAYER_REPORT = "REQUEST_EXPORT_PLAYER_REPORT";
export const EXPORT_PLAYER_REPORT_SUCCESS = "EXPORT_PLAYER_REPORT_SUCCESS";
export const EXPORT_PLAYER_REPORT_FAILED = "EXPORT_PLAYER_REPORT_FAILED";
//
export const REQUEST_EXPORT_SYSTEM_REPORT = "REQUEST_EXPORT_SYSTEM_REPORT";
export const EXPORT_SYSTEM_REPORT_SUCCESS = "EXPORT_SYSTEM_REPORT_SUCCESS";
export const EXPORT_SYSTEM_REPORT_FAIL = "EXPORT_SYSTEM_REPORT_FAIL";
//
export const REQUEST_EXPORT_PSYCOLOGIST_REPORT =
  "REQUEST_EXPORT_PSYCOLOGIST_REPORT";
export const EXPORT_PSYCOLOGIST_REPORT_SUCCESS =
  "EXPORT_PSYCOLOGIST_REPORT_SUCCESS";
export const EXPORT_PSYCOLOGIST_REPORT_FAIL = "EXPORT_PSYCOLOGIST_REPORT_FAIL";
//
export const REQUEST_REJECT_CANDIDATE_PROFILE =
  "REQUEST_REJECT_CANDIDATE_PROFILE";
export const REJECT_CANDIDATE_PROFILE_SUCCESS =
  "REJECT_CANDIDATE_PROFILE_SUCCESS";
export const REJECT_CANDIDATE_PROFILE_FAILED =
  "REJECT_CANDIDATE_PROFILE_FAILED";
//
export const REQUEST_REVIEW_COMPLETE_CANDIDATE =
  "REQUEST_REVIEW_COMPLETE_CANDIDATE";
export const REVIEW_COMPLETE_CANDIDATE_SUCCESS =
  "REVIEW_COMPLETE_CANDIDATE_SUCCESS";
export const REVIEW_COMPLETE_CANDIDATE_FAILED =
  "REVIEW_COMPLETE_CANDIDATE_FAILED";
//
export const REQUSET_CANDIDATE_REVIEW_INCOMPLETE =
  "REQUSET_CANDIDATE_REVIEW_INCOMPLETE";
export const CANDIDATE_REVIEW_INCOMPLETE_SUCCESS =
  "CANDIDATE_REVIEW_INCOMPLETE_SUCCESS";
export const CANDIDATE_REVIEW_INCOMPLETE_FAILED =
  "CANDIDATE_REVIEW_INCOMPLETE_FAILED";

//
export const REQUSET_CANDIDATE_REVIEW_SUBMITTED =
  "REQUSET_CANDIDATE_REVIEW_SUBMITTED";
export const CANDIDATE_REVIEW_SUBMITTED_SUCCESS =
  "CANDIDATE_REVIEW_SUBMITTED_SUCCESS";
export const CANDIDATE_REVIEW_SUBMITTED_FAILED =
  "CANDIDATE_REVIEW_SUBMITTED_FAILED";
//

export const REQUEST_REGENERATE_SYSTEM_REPORT =
  "REQUEST_REGENERATE_SYSTEM_REPORT";
export const REGENERATE_SYSTEM_REPORT_SUCCESS =
  "REGENERATE_SYSTEM_REPORT_SUCCESS";
export const REGENERATE_SYSTEM_REPORT_FAIL = "REGENERATE_SYSTEM_REPORT_FAIL";
//
export const REQUEST_REGENERATE_PSYCH_REPORT =
  "REQUEST_REGENERATE_PSYCH_REPORT";
export const REGENERATE_PSYCH_REPORT_SUCCESS =
  "REGENERATE_PSYCH_REPORT_SUCCESS";
export const REGENERATE_PSYCH_REPORT_FAIL = "REGENERATE_PSYCH_REPORT_FAIL";
//
export const REQUEST_UPDATE_NOTES = "REQUEST_UPDATE_NOTES";
export const UPDATE_NOTES_SUCCESS = "UPDATE_NOTES_SUCCESS";
export const UPDATE_NOTES_FAILED = "UPDATE_NOTES_FAILED";
export const REQUEST_ADD_ACTION_PLAN_CONTENT =
  "REQUEST_ADD_ACTION_PLAN_CONTENT";
export const ADD_ACTION_PLAN_CONTENT_SUCCESS =
  "ADD_ACTION_PLAN_CONTENT_SUCCESS";
export const ADD_ACTION_PLAN_CONTENT_FAILED = "ADD_ACTION_PLAN_CONTENT_FAILED";
export const REQUEST_ADD_EXECUTIVE_SUMMERY_CONTENT =
  "REQUEST_ADD_EXECUTIVE_SUMMERY_CONTENT";
export const ADD_EXECUTIVE_SUMMERY_CONTENT_SUCCESS =
  "ADD_EXECUTIVE_SUMMERY_CONTENT_SUCCESS";
export const ADD_EXECUTIVE_SUMMERY_CONTENT_FAILED =
  "ADD_EXECUTIVE_SUMMERY_CONTENT_FAILED";

export const REQUEST_TOGGLE_CANDIDATE_PAID_STATUS =
  "REQUEST_TOGGLE_CANDIDATE_PAID_STATUS";

export const TOGGLE_CANDIDATE_PAID_STATUS_SUCCESS =
  "TOGGLE_CANDIDATE_PAID_STATUS_SUCCESS";
export const TOGGLE_CANDIDATE_PAID_STATUS_FAILED =
  "TOGGLE_CANDIDATE_PAID_STATUS_FAILED";
//
export const REQUEST_ADD_CANDIDATE_LINK = "REQUEST_ADD_CANDIDATE_LINK";
export const ADD_CANDIDATE_LINK_SUCCESS = "ADD_CANDIDATE_LINK_SUCCESS";
export const ADD_CANDIDATE_LINK_FAIL = "ADD_CANDIDATE_LINK_FAIL";
//
export const REQUEST_DELETE_CANDIDATE_LINK = "REQUEST_DELETE_CANDIDATE_LINK";
export const DELETE_CANDIDATE_LINK_SUCCESS = "DELETE_CANDIDATE_LINK_SUCCESS";
export const DELETE_CANDIDATE_LINK_FAIL = "DELETE_CANDIDATE_LINK_FAIL";
//
export const REQUEST_TOGGLE_FULL_REPORT_PAYMENT_STATUS =
  "TOGGLE_FULL_REPORT_PAYMENT_STATUS";
export const TOGGLE_FULL_REPORT_PAYMENT_STATUS_SUCCESS =
  "TOGGLE_FULL_REPORT_PAYMENT_STATUS_SUCCESS";
export const TOGGLE_FULL_REPORT_PAYMENT_STATUS_FAILED =
  "TOGGLE_FULL_REPORT_PAYMENT_STATUS_FAILED";
//
export const REQUEST_TOGGLE_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS =
  "TOGGLE_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS";
export const TOGGLE_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS_SUCCESS =
  "TOGGLE_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS_SUCCESS";
export const TOGGLE_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS_FAILED =
  "TOGGLE_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS_FAILED";
//
export const REQUEST_TOGGLE_ACTION_PLAN_REPORT_PAYMENT_STATUS =
  "TOGGLE_ACTION_PLAN_REPORT_PAYMENT_STATUS";
export const TOGGLE_ACTION_PLAN_REPORT_PAYMENT_STATUS_SUCCESS =
  "TOGGLE_ACTION_PLAN_REPORT_PAYMENT_STATUS_SUCCESS";
export const TOGGLE_ACTION_PLAN_REPORT_PAYMENT_STATUS_FAILED =
  "TOGGLE_ACTION_PLAN_REPORT_PAYMENT_STATUS_FAILED";

//
export const REQUEST_EXPORT_FULL_REPORT = "REQUEST_EXPORT_FULL_REPORT";
export const EXPORT_FULL_REPORT_SUCCESS = "EXPORT_FULL_REPORT_SUCCESS";
export const EXPORT_FULL_REPORT_FAIL = "EXPORT_FULL_REPORT_FAIL";
//
export const REQUEST_EXPORT_ACTION_PLAN_REPORT =
  "REQUEST_EXPORT_ACTION_PLAN_REPORT";
export const EXPORT_ACTION_PLAN_REPORT_SUCCESS =
  "EXPORT_ACTION_PLAN_REPORT_SUCCESS";
export const EXPORT_ACTION_PLAN_REPORT_FAIL = "EXPORT_ACTION_PLAN_REPORT_FAIL";
//
export const REQUEST_EXPORT_EXECUTIVE_SUMMARY_REPORT =
  "REQUEST_EXPORT_EXECUTIVE_SUMMARY_REPORT";
export const EXPORT_EXECUTIVE_SUMMARY_REPORT_SUCCESS =
  "EXPORT_EXECUTIVE_SUMMARY_REPORT_SUCCESS";
export const EXPORT_EXECUTIVE_SUMMARY_REPORT_FAIL =
  "EXPORT_EXECUTIVE_SUMMARY_REPORT_FAIL";
//
export const REQUEST_REGENERATE_FULL_REPORT = "REQUEST_REGENERATE_FULL_REPORT";
export const REGENERATE_FULL_REPORT_SUCCESS = "REGENERATE_FULL_REPORT_SUCCESS";
export const REGENERATE_FULL_REPORT_FAIL = "REGENERATE_FULL_REPORT_FAIL";
//
export const REQUEST_REGENERATE_ACTION_PLAN_REPORT =
  "REQUEST_REGENERATE_ACTION_PLAN_REPORT";
export const REGENERATE_ACTION_PLAN_REPORT_SUCCESS =
  "REGENERATE_ACTION_PLAN_REPORT_SUCCESS";
export const REGENERATE_ACTION_PLAN_REPORT_FAIL =
  "REGENERATE_ACTION_PLAN_REPORT_FAIL";
//
export const REQUEST_REGENERATE_EXECUTIVE_SUMMARY_REPORT =
  "REQUEST_REGENERATE_EXECUTIVE_SUMMARY_REPORT";
export const REGENERATE_EXECUTIVE_SUMMARY_REPORT_SUCCESS =
  "REGENERATE_EXECUTIVE_SUMMARY_REPORT_SUCCESS";
export const REGENERATE_EXECUTIVE_SUMMARY_REPORT_FAIL =
  "REGENERATE_EXECUTIVE_SUMMARY_REPORT_FAIL";
//
export const REQUEST_UPDATE_RISK_SCORE = "REQUEST_UPDATE_RISK_SCORE";
export const UPDATE_RISK_SCORE_SUCCESS = "UPDATE_RISK_SCORE_SUCCESS";
export const UPDATE_RISK_SCORE_FAIL = "UPDATE_RISK_SCORE_FAIL";

export const REQUEST_CREATE_COACH = "REQUEST_CREATE_COACH";
export const CREATE_COACH_SUCCESS = "CREATE_COACH_SUCCESS";
export const CREATE_COACH_FAILED = "CREATE_COACH_FAILED";

//

export const GET_FILTER_CANDIDATES_REQUEST = "GET_FILTER_CANDIDATES_REQUEST"
export const GET_FILTER_CANDIDATES_SUCCESS = "GET_FILTER_CANDIDATES_SUCCESS"
export const GET_FILTER_CANDIDATES_FAILED = "GET_FILTER_CANDIDATES_FAILED"

export const UPDATE_FILTER_VALUE = "UPDATE_FILTER_VALUE"
export const RESET_FILTER_VALUE = "RESET_FILTER_VALUE"
export const IS_FILTERED_DATA = "IS_FILTERED_DATA"

export const RESET_SELECTED_FILTER_VALUE = "RESET_SELECTED_FILTER_VALUE"

export const UPDATE_TEAM_FILTER_VALUE = "UPDATE_TEAM_FILTER_VALUE"
export const RESET_TEAM_FILTER_VALUE = "RESET_TEAM_FILTER_VALUE"
export const IS_TEAM_FILTERED_DATA = "IS_TEAM_FILTERED_DATA"

export const GET_FILTER_PLAYERS_REQUEST = "GET_FILTER_PLAYERS_REQUEST"
export const GET_FILTER_PLAYERS_SUCCESS = "GET_FILTER_PLAYERS_SUCCESS"
export const GET_FILTER_PLAYERS_FAILED = "GET_FILTER_PLAYERS_FAILED"

export const GET_REQUEST_PLAN_REPORTS = "GET_REQUEST_PLAN_REPORTS";
export const GET_REQUEST_PLAN_REPORTS_SUCCESS = "GET_REQUEST_PLAN_REPORTS_SUCCESS";
export const GET_REQUEST_PLAN_REPORTS_FAILED = "GET_REQUEST_PLAN_REPORTS_FAILED";

//
export const INDIVIDUAL_ATHLETE_FORM_SUBMITTED = "INDIVIDUAL_ATHLETE_FORM_SUBMITTED";
export const INDIVIDUAL_ATHLETE_FORM_NOT_SUBMITTED = "INDIVIDUAL_ATHLETE_FORM_NOT_SUBMITTED";

export const REQUEST_DELETE_PSYCH_SUMMERY_COMMENT =
  "REQUEST_DELETE_PSYCH_SUMMERY_COMMENT";
export const DELETE_PSYCH_SUMMERY_COMMENT_SUCCESS =
  "DELETE_PSYCH_SUMMERY_COMMENT_SUCCESS";



// 
export const SAVE_ALLREADY_REQUESTED_PLUS_REPORTS = "SAVE_ALLREADY_REQUESTED_PLUS_REPORTS";
export const GET_ALLREADY_REQUESTED_PLUS_REPORTS = "GET_ALLREADY_REQUESTED_PLUS_REPORTS";


// Player sorting 
export const SELECTED_SORTING_TYPE = "SELECTED_SORTING_TYPE";
export const SELECTED_SORT_ORDER = "SELECTED_SORT_ORDER";


export const REQUEST_GET_TWITTER_INFO = "REQUEST GET TWITTER INFO";
export const GET_TWITTER_INFO_SUCCESS = "GET TWITTER INFO SUCCESS";
export const GET_TWITTER_INFO_FAILED = "GET TWITTER INFO FAILED";

export const REQUEST_GET_BIG5_INFO = "REQUEST GET BIG5 INFO";
export const GET_BIG5_INFO_SUCCESS = "GET BIG5 INFO SUCCESS";
export const GET_BIG5_INFO_FAILED = "GET BIG5 INFO FAILED";

export const REQUEST_GET_PSYCH_INFO = "REQUEST GET PSYCH INFO";
export const GET_PSYCH_INFO_SUCCESS = "GET PSYCH INFO SUCCESS";
export const GET_PSYCH_INFO_FAILED = "GET PSYCH INFO FAILED";

export const REQUEST_GET_INSTAGRAM_INFO = "REQUEST GET INSTAGRAM INFO";
export const GET_INSTAGRAM_INFO_SUCCESS = "GET INSTAGRAM INFO SUCCESS";
export const GET_INSTAGRAM_INFO_FAILED = "GET INSTAGRAM INFO FAILED";

export const REQUEST_GET_SENTIMENT_INFO = "REQUEST GET SENTIMENT INFO";
export const GET_SENTIMENT_INFO_SUCCESS = "GET SENTIMENT INFO SUCCESS";
export const GET_SENTIMENT_INFO_FAILED = "GET SENTIMENT INFO FAILED";

export const GET_VIDEO_TRANSCRIPT_REQUEST = "GET VIDEO TRANSCRIPT REQUEST";
export const GET_VIDEO_TRANSCRIPT_SUCCESS = "GET VIDEO TRANSCRIPT SUCCESS";
export const GET_VIDEO_TRANSCRIPT_FAILED = "GET VIDEO TRANSCRIPT FAILED";

export const GET_APPROVE_PLAYER = "GET APPROVE PLAYER";
export const GET_APPROVE_PLAYER_SUCCESS = "GET APPROVE PLAYER SUCCESS";
export const GET_APPROVE_PLAYER_FAILED = "GET APPROVE PLAYER FAILED";

export const GET_PLUS_REPORT = "GET PLUS REPORT";
export const GET_PLUS_REPORT_SUCCESS = "GET PLUS REPORT SUCCESS";
export const GET_PLUS_REPORT_FAILED = "GET PLUS REPORT FAILED";

export const REQUEST_GET_LITE_PLAYER = "REQUEST GET LITE PLAYER";
export const GET_LITE_PLAYER_SUCCESS = "GET LITE PLAYER SUCCESS";
export const GET_LITE_PLAYER_FAILED = "GET LITE PLAYER FAILED";

export const GET_REMOVE_FEED = "GET REMOVE FEED";
export const GET_REMOVE_FEED_SUCCESS = "GET REMOVE FEED SUCCESS";
export const GET_REMOVE_FEED_FAILED = "GET REMOVE FEED FAILED";


export const GET_RECEPTIVITI_SCORE = "RECEPTIVITI_SCORE";
export const GET_RECEPTIVITI_SCORE_SUCCESS = "GET REMOVE FEED SUCCESS";
export const GET_RECEPTIVITI_SCORE_FAILED = "GET REMOVE FEED FAILED";