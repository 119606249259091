import React, { useContext, useState } from "react";
import Summery from "./Summery";
import withPaymentLock from "../withPaymentLock";
import { accountTypes } from "modules/players/constants";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { ActionContext } from "../../../../contexts";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { useEffect } from "react";
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  // card: {
  //   display: "flex",
  //   width: "100%",
  //   justifyContent: "center",
  // },
  btnPrimary: {
    color: theme.palette.primary.main,
    border: '1px solid',
    height: '30px',
    padding: '10px',
    marginRight: '10px',
    borderRadius: '20px',
    '&:hover': {
      background: theme.palette.primary.main,
      color: '#fff'
    },
  },
}));

let PsychSummary = (props) => {
  let classes = useStyles();
  let { coachActions, snackbarActions } = useContext(ActionContext);

  let categories = {
    significantBackgroundInformation: "Significant Background Information",
    symptomsAndInformationCaptured: "Symptoms and Information Captured",
    custom: "Custom"
  };
  let custom = {
    untitled: "Untitled",
  };

  let { coachId, readOnly = false, accountType, summery } = props;
  let hasEditPriviledges =
    accountType === accountTypes.bcaPsychologist ||
    accountType === accountTypes.bcaChiefPsychologist ||
    accountType === accountTypes.superAdmin;
  let isEditable = !readOnly && hasEditPriviledges;
  const [state, setState] = React.useState({
    openCard: false,
    customCards: []
  })
  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  function formatDate(date) {
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join('') +
      +
      [
        padTo2Digits(date.getHours()),
        padTo2Digits(date.getMinutes()),
        padTo2Digits(date.getSeconds()),
      ].join('')
    );
  }



  // let initialEditorState = summery && summery
  // ? EditorState.createWithContent(convertFromRaw(summery))
  // : EditorState.createEmpty();

  // const sample = summery && summery[`${coachId}.${categories.custom}`]

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      customCards: summery && summery[`${coachId}.${categories.custom}`]
    }))
  }, [summery])
  const date = formatDate(new Date())

  function handleNewCard() {
    // setState(prevState => ({
    //   ...prevState,
    //   openCard: true
    // }))
    let data = {
      coach_id: coachId,
      category: "Custom",
      content: {
        "blocks": [
          {
            "key": "clrlg",
            "text": "Untitled",
            "type": "header-three",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "data": {}
          },
          {
            "key": "8mp07",
            "text": "Psychologist comments pending...",
            "type": "unstyled",
            "depth": 0,
            "inlineStyleRanges": [],
            "entityRanges": [],
            "data": {}
          }
        ],
        "entityMap": {}
      },
      _id: coachId + '-' + date
    };
    let success = () => {
      return snackbarActions.showSnackbar("New Note Added");
    };


    coachActions.addExecutiveSummeryContent(data, success, state.customCards);

    setState(prevState => ({
      ...prevState,
      customCards: [...state.customCards, data]
    }))

  }

  return (
    <div style={{ width: "90%",margin: '0 auto' }}>
      <div style={{ display: 'flex', justifyContent: 'right',marginBottom: '1rem' }}>
        {
          (accountType === "SUPER_ADMIN" || accountType === "BCA_PSYCHOLOGIST" || accountType === "BCA_CHIEF_PSYCHOLOGIST") ?
            <Button className={classes.btnPrimary} onClick={handleNewCard}>Add +</Button>
            :
            ""
        }
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <Summery
            isEditable={isEditable}
            coachId={coachId}
            categoryId={categories.significantBackgroundInformation}
            content={summery && summery[`${coachId}.${categories.significantBackgroundInformation}`]}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Summery
            isEditable={isEditable}
            coachId={coachId}
            categoryId={categories.symptomsAndInformationCaptured}
            content={summery && summery[`${coachId}.${categories.symptomsAndInformationCaptured}`]}
          />
        </Grid>

        {
          state.customCards && state.customCards.length > 0 ?
            state.customCards?.map(list =>
              <Grid item xs={12} sm={6}>
                <Summery
                  isEditable={isEditable}
                  coachId={coachId}
                  categoryId={categories.custom}
                  content={list && list.content}
                  open={true}
                  id={list._id}
                  currentData={state.customCards}
                  customData={list}
                />
              </Grid>
            )
            :
            ""
        }
      </Grid>
      {/* {state.openCard === true && state.openCard === true ?
        <div className={classes.card}>
          <Summery
            isEditable={isEditable}
            coachId={coachId}
            categoryId={custom.untitled}
            open={state.openCard}
          />
        </div> : ''} */}
    </div>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { coachId, categoryId } = ownProps;
  return {
    accountType: state.account.accountType,
    summery: state.coach.executiveSummery,
  };
};
export default connect(mapStateToProps)(withPaymentLock(PsychSummary));
