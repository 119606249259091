import apiHandler, { getAuthHeader } from "./apiHandler";

export const createUser = async (user) => {
  try {
    let headers = getAuthHeader();
    const response = await apiHandler.post("/auth/register", user, { headers });
    return response.data;
  } catch (err) {
    console.error(err);
    throw new Error("Failed to create user");
  }
};

export const resendVerification = async (email) => {
  try {
    const response = await apiHandler.post(
      "/auth/verification_code/resend",
      email
    );
    return response.data;
  } catch (err) {
    console.error(err);
    throw new Error("Failed to resend the verification code");
  }
};

export const confirmEmail = async (confirmation) => {
  try {
    const response = await apiHandler.post(
      "/auth/verification_code/verify",
      confirmation
    );
    return response.data;
  } catch (err) {
    console.error(err);
    throw new Error("Failed to confirm email");
  }
};

export const authenicate = async (loginData) => {
  try {
    const response = await apiHandler.post("/auth/login", loginData);
    return response.data;
  } catch (err) {
    console.error(err);
    throw new Error("Login failed");
  }
};

export const resetPassword = async (email) => {
  try {
    const response = await apiHandler.post("/auth/reset/password", email);
    return response.data;
  } catch (err) {
    console.error(err);
    throw new Error("Password Reset failed");
  }
};

export const changePassword = async (data) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.post("/auth/change-password", data, {
      headers,
    });
    return response.data;
  } catch (err) {
    console.error(err);
    throw new Error("Change Password failed");
  }
};

export const signout = async () => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.post(
      "/auth/logout/access",
      {},
      { headers }
    );
    return response.data;
  } catch (e) {
    console.error(e);
    throw new Error("Signout attempt failed");
  }
};

export const deleteAccount = async (password) => {
  try {
    const data = { password };
    const headers = getAuthHeader();
    const response = await apiHandler.post("/auth/delete/account", data, {
      headers,
    });
    return response.data;
  } catch (e) {
    console.error(e);
    throw new Error("Signout attempt failed");
  }
};

export const getProfile = async () => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get("/profile/info", { headers });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    const { full_name, email, team , sport, account_type} = response.data.info;

    return {
      fullName: full_name,
      email,
      team,
      sport,
      account_type
    };
  } catch (err) {
    // localStorage.removeItem("@accessToken");
    throw new Error(err);
  }
};

export const getUserTypes = async () => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get("/user_types", { headers });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    let userTypes = response.data.info["user_types"].reduce((obj, type) => {
      obj[type.code] = {
        ...type,
      };

      return obj;
    }, {});

    return userTypes;
  } catch (err) {
    throw new Error(err);
  }
};

const user = {
  createUser,
  resendVerification,
  confirmEmail,
  authenicate,
  resetPassword,
  changePassword,
  signout,
  deleteAccount,
  getProfile,
  getUserTypes,
};

export default user;
