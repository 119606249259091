import createContext from "./createContext";

export const [UtilsContext, UtilsContextProvider] = createContext();

const utilsContext = {
  UtilsContext,
  UtilsContextProvider,
};

export default utilsContext;
