import React, { useState } from "react";
import MuiPopover from "@material-ui/core/Popover";

let Popover = ({
  component: Component,
  anchorReference = "anchorEl",
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "center",
  },
  anchorPosition = {},
  children,
  elevation = 2,
  ComponentProps,
  ...rest
}) => {
  let [anchorEl, setAnchorEl] = useState(null);
  let open = Boolean(anchorEl);

  let handleClose = () => {
    setAnchorEl(null);
  };

  let handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div>
      <Component onClick={handleClick} {...ComponentProps} />
      <MuiPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        elevation={elevation}
      >
        {children}
      </MuiPopover>
    </div>
  );
};

export default Popover;
