import React, { useContext, useRef } from "react";
import clsx from "clsx";
import MuiDrawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PeopleIcon from "@material-ui/icons/People";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import appBarLogo from "../../../assets/perc3pt/image2vector.svg";
import PostAddIcon from "@material-ui/icons/PostAdd";
import ContactsIcon from "@material-ui/icons/Contacts";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import HowtoRegIcon from "@material-ui/icons/HowToReg";
import LayersIcon from "@material-ui/icons/Layers";
// import dashboardHomeIcon from "src/assets/apps.svg";
import DashboardIcon from "../../../assets/DashboardIcon.svg";
import Athlete from "../../../assets/Athlete.svg";
import athleteIcon from "../../../assets/useradd.svg";
import profileFinderIcon from "../../../assets/profileFinder.svg";
import consentIcon from "../../../assets/Concent.svg";
import coachIcon from "../../../assets/whistle (1).svg";
import trophyIcon from "../../../assets/trophy.svg";
import AddathleteIcon from "../../../assets/AddAthlete.svg";
import AddcoachIcon from "../../../assets/AddCoach.svg";
import menu from "../../../assets/menu-dots.svg";
import MenuIcon from "@material-ui/icons/Menu";
import DrawerItem from "./DrawerItem";
import Slide from "@material-ui/core/Slide";
import { accountTypes } from "../constants";
import { CreateAccount } from "../../account";
import { CreateCandidate, CreateCoach } from "../../players";
import { useHistory, useLocation } from "react-router-dom";
import { ActionContext } from "../../../contexts";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CodeIcon from '@material-ui/icons/Code';
import '../../../index.css';


const drawerWidth = 290;
const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="left"
      ref={ref}
      timeout={{ enter: 1000, exit: 1000 }}
      {...props}
    />
  );
});

let useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    backgroundColor: theme.palette.background.paper,
  },
  drawerOpen: {
    width: drawerWidth,
    background: '#181957',
  },
  logo: {
    display: "flex",
    alignItems: "center",
    width: 257,
    // marginLeft: -8,
    // background: '#eeeef0',
    paddingLeft: '1.8rem',
  },
  Smalllogo: {
    paddingLeft: '0.5rem',
  },
  CodeAlignData: {
    display: 'flex',
    width: 'fit-content',
    background: '#fff',
    marginLeft: 'auto',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px'
  },
  image: {
    cursor: "pointer",
  },
  closeimage: {
    width: '4rem',
    marginLeft: '0.2rem'
  },
  drawerClose: {
    overflowX: "hidden",
    // width: theme.spacing(7) + 1,
    width: '6rem',
    background: '#181957',
  },
  drawerPaper: {
    width: drawerWidth,
    overflow: "hidden",
  },
  content: {
    // backgroundColor: theme.palette.background.paper,
    background: '#181957',
    height: "100%",
    // paddingLeft: '1rem'
  },
  hide: {
    display: "none",
  },
  listItemIcon: {
    // minWidth: '2.5rem',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    marginRight: '1.2rem',
  },

  listItemText: {
    // fontWeight: 500,
    fontSize: '13px',
    fontFamily: 'Prometo',
  },
  active: {
    color: theme.palette.secondary.main,
  },
}));

const Drawer = (props) => {
  let history = useHistory();
  let location = useLocation();
  let classes = useStyles();
  let fileInputRef = useRef();

  let { isDrawerOpen, accountType } = props;
  let { appActions, dialogActions, playerActions } = useContext(ActionContext);
  let handleHomeClick = () => {
    history.push("/");
  };
  let handleUploadPlayerClick = () => {
    fileInputRef.current.click();
  };

  let handleDrawerClick = () => {
    appActions.toggleDrawer();
  };

  let handleFileSelect = (e) => {
    let data = new FormData();
    data.append("players_file", e.target.files[0]);
    const cb = (fn) => {
      return () => {
        fileInputRef.current.value = null;
        fn();
      };
    };

    // playerActions.uploadPlayers(
    //   data,
    //   cb(() => {
    //     history.push("/athlete-management");
    //   })
    // );
  };

  let dashboard = {
    name: "Dashboard",
    icon: DashboardIcon,
    route: "/",
    onClick: () => {
      history.push("/");
    },
  };

  let contacts = {
    name: "Profile Finder",
    icon: profileFinderIcon,
    route: "/profile-finder",
    onClick: () => {
      history.push("/profile-finder");
    },
  };

  let athleteCreation = {
    name: "Athlete Creation",
    icon: PersonAddIcon,
    route: null,
    onClick: () => {
      dialogActions.openDialog(<CreateCandidate />, {
        fullScreen: true,
        TransitionComponent: Transition,
      });
    },
  };

  let createAthlete = {
    name: "Add Athlete",
    icon: AddathleteIcon,
    route: "/create-athlete",
    onClick: () => {
      history.push("/create-athlete");
    },
  };

  // let coachCreation = {
  //   name: "Add Coach",
  //   icon: AddcoachIcon,
  //   route: null,
  //   onClick: () => {
  //     dialogActions.openDialog(<CreateCoach />, {
  //       fullScreen: true,
  //       TransitionComponent: Transition,
  //     });
  //   },
  // };

  let coachCreation = {
    name: "Add Coach",
    icon: AddcoachIcon,
    route: "/create-coach",
    onClick: () => {
      history.push("/create-coach");
    },
  };

  let athleteBulkCreation = {
    name: "Athlete Bulk Creation",
    icon: PostAddIcon,
    route: null,
    onClick: handleUploadPlayerClick,
  };

  // let accountManagement = {
  //   name: "Add Account",
  //   icon: PeopleIcon,
  //   route: null,
  //   onClick: () => {
  //     dialogActions.openDialog(<CreateAccount />, {
  //       fullScreen: true,
  //       TransitionComponent: Transition,
  //     });
  //   },
  // };

  let accountManagement = {
    // name: "Add Account",
    name: "Account Management",
    icon: AddathleteIcon,
    route: "/account-management",
    onClick: () => {
      history.push("/account-management");
    },
  };

  // let athleteManagment = {
  //   name: "Athlete Management",
  //   icon: LayersIcon,
  //   route: "/players",
  //   onClick: () => {
  //     history.push("/players");
  //   },
  // };

  let conferenceManagment = {
    name: "Conference Management",
    icon: AddathleteIcon,
    route: "/conference",
    onClick: () => {
      history.push("/conference");
    },
  };

  let sportsManagment = {
    name: "Sports & Positions",
    icon: AddathleteIcon,
    route: "/sports-positions",
    onClick: () => {
      history.push("/sports-positions");
    },
  };

  let infoManagment = {
    name: "More Info",
    icon: menu,
    route: "/more-info",
    onClick: () => {
      history.push("/more-info");
    },
  };

  let coachManagment = {
    name: "Coach",
    icon: coachIcon,
    route: "/coach",
    onClick: () => {
      history.push("/coach");
    },
  };

  let consentManagement = {
    name: "Consent",
    icon: consentIcon,
    route: "/consent",
    onClick: () => {
      history.push("/consent");
    },
  };

  let teamManagment = {
    name: "Team",
    icon: trophyIcon,
    route: "/team-overview",
    onClick: () => {
      history.push("/team-overview");
    },
  };

  let newAthleteManagment = {
    name: "Athlete",
    icon: Athlete,
    route: "/athlete-management",
    onClick: () => {
      history.push("/athlete-management");
    },
  };

  const topDrawerItems = {
    [accountTypes.superAdmin]: {
      dashboard,
      newAthleteManagment,
      coachManagment,
      consentManagement,
      contacts,
      conferenceManagment,
      sportsManagment
      // createAthlete,
      // coachCreation,
      // accountManagement,
      // infoManagment,
    },
    [accountTypes.bcaDdiAdmin]: {
      dashboard,
      newAthleteManagment,
      coachManagment,
      consentManagement,
      contacts,
      conferenceManagment,
      sportsManagment
      // createAthlete,
      // coachCreation,
      // accountManagement,
      // infoManagment,
    },
    [accountTypes.bcaPsychologist]: {
      dashboard,
      newAthleteManagment,
      coachManagment,
      contacts,
      // infoManagment
    },
    [accountTypes.bcaChiefPsychologist]: {
      dashboard,
      newAthleteManagment,
      coachManagment,
      contacts,
      // infoManagment
    },
    [accountTypes.orgAdmin]: {
      dashboard,
      newAthleteManagment,
      consentManagement,
      // createAthlete,
      // accountManagement,
      // infoManagment,
    },
    [accountTypes.orgPsychologist]: {
      dashboard,
      newAthleteManagment,
      // infoManagment
    },
    [accountTypes.orgUser]: {
      dashboard,
      newAthleteManagment,
      // createAthlete,
      // infoManagment
    },
    [accountTypes.candidate]: {
      dashboard,
    },
    [accountTypes.colAthleticDirector]: {
      dashboard,
      newAthleteManagment,
      coachManagment,
      // coachCreation,
      // infoManagment
    },
    [accountTypes.colHeadCoach]: {
      dashboard,
      newAthleteManagment,
      teamManagment,
      coachManagment,
      // createAthlete,
      // coachCreation,
      // infoManagment/
    },
    [accountTypes.colPositionCoach]: {
      newAthleteManagment,
      teamManagment,
      // createAthlete,
      // infoManagment
    },
    [accountTypes.colPsychologist]: {
      newAthleteManagment,
      teamManagment,
      coachManagment,
      contacts,
      // infoManagment
    },
    [accountTypes.proGeneralManager]: {
      dashboard,
      newAthleteManagment,
      teamManagment,
      coachManagment,
      // createAthlete,
      // coachCreation,
      // infoManagment
    },
    [accountTypes.proHeadCoach]: {
      dashboard,
      newAthleteManagment,
      teamManagment,
      coachManagment,
      // createAthlete,
      // coachCreation,
      // infoManagment
    },
    [accountTypes.proPositionCoach]: {
      newAthleteManagment,
      teamManagment,
      // createAthlete,
      // infoManagment
    },
    [accountTypes.proScouts]: {
      teamManagment,
      newAthleteManagment,
      // createAthlete,
      // infoManagment
    },
    [accountTypes.proSecurity]: {
      newAthleteManagment,
      teamManagment
      // createAthlete,
      // infoManagment
    },
    [accountTypes.proPsychologist]: {
      newAthleteManagment,
      teamManagment,
      coachManagment,
      contacts,
      // infoManagment
    },
  };

  const bottomDrawerItems = {
    [accountTypes.superAdmin]: {
      createAthlete,
      coachCreation,
      accountManagement,
      infoManagment,
    },
    [accountTypes.bcaDdiAdmin]: {
      createAthlete,
      coachCreation,
      accountManagement,
      infoManagment,
    },
    [accountTypes.bcaPsychologist]: {
      infoManagment
    },
    [accountTypes.bcaChiefPsychologist]: {
      infoManagment
    },
    [accountTypes.orgAdmin]: {
      createAthlete,
      // accountManagement,
      infoManagment,
    },
    [accountTypes.orgPsychologist]: {
      infoManagment
    },
    [accountTypes.orgUser]: {
      createAthlete,
      infoManagment
    },
    [accountTypes.candidate]: {

    },
    [accountTypes.colAthleticDirector]: {
      coachCreation,
      infoManagment
    },
    [accountTypes.colHeadCoach]: {
      createAthlete,
      coachCreation,
      infoManagment
    },
    [accountTypes.colPositionCoach]: {
      createAthlete,
      infoManagment
    },
    [accountTypes.colPsychologist]: {
      infoManagment
    },
    [accountTypes.proGeneralManager]: {
      createAthlete,
      coachCreation,
      infoManagment
    },
    [accountTypes.proHeadCoach]: {
      createAthlete,
      coachCreation,
      infoManagment
    },
    [accountTypes.proPositionCoach]: {
      createAthlete,
      infoManagment
    },
    [accountTypes.proScouts]: {
      createAthlete,
      infoManagment
    },
    [accountTypes.proSecurity]: {
      createAthlete,
      infoManagment
    },
    [accountTypes.proPsychologist]: {
      infoManagment
    },
  };

  return (
    <MuiDrawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: isDrawerOpen,
        [classes.drawerClose]: !isDrawerOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: isDrawerOpen,
          [classes.drawerClose]: !isDrawerOpen,
        }),
      }}
    >

      {/* <Toolbar variant="dense" /> */}

      <input
        type="file"
        accept=".csv"
        ref={fileInputRef}
        onChange={handleFileSelect}
        style={{ display: "none" }}
      />

      <div className={classes.content}>
        <div className={isDrawerOpen ? classes.logo : classes.Smalllogo}>
          <img
            src={appBarLogo}
            className={isDrawerOpen ? classes.image : classes.closeimage}
            height={50}
            onClick={handleHomeClick}
            alt="logo"
          />
        </div>
        <List>
          {Object.values(topDrawerItems[accountType]).map((drawer, index) => {
            let { onClick, icon: Icon, name, route } = drawer;
            let selected = location.pathname === route;

            return (
              <DrawerItem
                selected={selected}
                button
                onClick={onClick}
                key={index}
                disableRipple
              >
                <ListItemIcon
                  className={clsx(classes.listItemIcon, {
                    [classes.active]: selected,
                  })}
                >
                  {/* <Icon /> */}
                  <img src={Icon} alt="" style={{ width: '18px' }} />
                </ListItemIcon>
                <ListItemText
                  primary={name}
                  primaryTypographyProps={{
                    className: clsx(classes.listItemText, classes.drawerCloselogo, {
                      [classes.active]: selected,
                    }),
                  }}
                />
              </DrawerItem>
            );
          })}
        </List>
        <div style={{ borderBottom: "1px solid #fcb643", paddingright: '0.8rem', margin: '2rem 0rem', paddingLeft: 13, paddingRight: '0.8rem' }}></div>

        <List>
          {Object.values(bottomDrawerItems[accountType]).map((drawer, index) => {
            let { onClick, icon: Icon, name, route } = drawer;
            let selected = location.pathname === route;

            return (
              <DrawerItem
                selected={selected}
                button
                onClick={onClick}
                key={index}
                disableRipple
              >
                <ListItemIcon
                  className={clsx(classes.listItemIcon, {
                    [classes.active]: selected,
                  })}
                >
                  {/* <Icon /> */}
                  <img src={Icon} alt="" style={{ width: '18px' }} />
                </ListItemIcon>
                <ListItemText
                  primary={name}
                  primaryTypographyProps={{
                    className: clsx(classes.listItemText, classes.drawerCloselogo, {
                      [classes.active]: selected,
                    }),
                  }}
                />
              </DrawerItem>
            );
          })}
        </List>
        <div className={classes.CodeAlignData}>
          <Toolbar
            variant="dense"
            disableGutters
            style={{ display: 'flex', justifyContent: 'end', paddingright: '0.8rem', }}
          >
            <IconButton size="small" onClick={handleDrawerClick}>
              <CodeIcon style={{ color: '#fcb643', width: '18px' }} />
            </IconButton>
          </Toolbar>
        </div>
      </div>
    </MuiDrawer>
  );
};

let mapStateToProps = (state) => {
  return {
    isDrawerOpen: state.ui.app.isDrawerOpen,
    accountType: state.account.accountType,
  };
};

export default connect(mapStateToProps)(Drawer);
