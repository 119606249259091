import React, { useState, useContext, useEffect } from 'react'
import { Card, CardActions, CardContent, CardHeader, Button, InputBase, Grid } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ActionContext } from "../../../../contexts";
import { useConfirm } from "material-ui-confirm";
import { showSuccessMessage } from "modules/shared/Snackbar";
import ListLoading from "../ConferenceList/ListLoading";
import { makeStyles, fade } from "@material-ui/core/styles";
import CreateTeam from "./CreateTeam"
import CreateTeamDialog from "./CreateTeamDialog"
import EditIcon from "@material-ui/icons/RateReviewOutlined";
import IconButton from "@material-ui/core/IconButton";
import "./style.css"
import clsx from 'clsx'


let useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        display: "flex",
        height: `calc(100vh - 50px)`,
        zIndex: theme.zIndex.appBar,
    },
    container: {
        padding: 16,
        marginRight: "auto",
        [theme.breakpoints.down("md")]: {
            maxWidth: 550,
        },
    },
    FullName: {
        fontWeight: '700',
        color: '#333333'
    },
    Team: {
        color: '#666666',
        fontSize: '12px'
    },
    Status: {
        fontSize: '11px',
        padding: '0.1rem 0.7rem',
        borderRadius: '0.9rem',
        fontWeight: 500,
    },
    avatar: {
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    secondaryAction: {
        display: "flex",
        flexDirection: "column",
    },
    badgeContent: {
        width: 18,
        height: 18,
        backgroundColor: theme.palette.background.paper,
        border: `2px solid #fff`,
    },
    card: {
        flexGrow: 0,
        padding: '14px',
        borderRadius: '9px',
        boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.06)',
        backgroundColor: '#fff',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    search: {
        justifyContent: 'space-between',
        display: 'flex',
        position: 'relative',
        marginBottom: '14px',
        marginRight: '0px',
        marginTop: '14px',
        borderRadius: '10px',
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            alignItems: 'center',
            color: '#0971ce',
            fontWeight: 'bold',
            fontSize: '18px',
            width: 'auto',
            borderBottom: '1px solid #fdb525',
            borderRadius: 'inherit',
            paddingBottom: '1rem',
            background: 'none'
        },
    },
    searchIcon: {
        padding: '0px 5px',
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.primary.main
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: '8px 15px',
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        paddingLeft: '10px',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
            border: '1px solid #0971ce',
        },
        borderRadius: '10px',
    },
    AvatarCellDesign: {
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.main,
        fontSize: '12px',
        height: '32px',
        width: '32px',
        boxShadow: '3px 3px 8px 0px #cdcdcd',
        color: '#fff',
        fontWeight: '700'
    },
    editIcon: {
        position: 'relative',
        cursor: 'pointer',
        display: 'flex',
        color: theme.palette.primary.main
    },
}));

function TeamDetails(props) {
    const { teamData, loading, conferenceType } = props;
    let classes = useStyles();
    let confirm = useConfirm();
    let dispatch = useDispatch();
    let actions = useContext(ActionContext);
    let { playerActions, conferenceActions, dialogActions } = actions;
    let history = useHistory();
    const [searchText, setSearchText] = useState(undefined);

    const [isHovered, setIsHovered] = useState(false);
    const [getAllData, setGetAllData] = React.useState([]);

    function handleSearchText(v) {
        setSearchText(v);
    }

    const handleClick = () => {
        // history.push("/players");
    };

    const handleRedirect = () => {
        conferenceActions.selectConferencePage("list")
        conferenceActions.selectConferenceType(null);
    }


    useEffect(() => {
        return () => {
            conferenceActions.selectConferencePage("list")
            conferenceActions.selectConferenceType(null);
        }
    }, [])

    function handleEditClick(data) {
        dialogActions.openDialog(
            <CreateTeamDialog data={data} conferenceType={conferenceType} />,
            {
                maxWidth: "xs",
            }
        );
    }

    const filteredData = searchText ? teamData && teamData.filter(v => v.name.toLowerCase().includes(searchText.toLowerCase())) : teamData && teamData
    const getAllTeamDetails = (data) => {
        setGetAllData(data);
    };
    return (
        <React.Fragment>
            <div className={classes.container}>
                <div className={classes.search}>
                    <div>
                        <label><b>{conferenceType}</b> </label>

                    </div>
                    <div style={{ display: "flex" }}>
                        <CreateTeam type={conferenceType} />
                        <span
                            style={{ border: '1px solid', padding: '0.3rem 0.5rem', borderRadius: '9px', margin: '0rem 1rem', color: '#0971ce', fontWeight: 700, cursor: 'pointer', }}
                            color="default"
                            onClick={handleRedirect}
                            disableElevation
                        >
                            List Page
                        </span>
                        <InputBase
                            placeholder="Search Team Name"
                            value={searchText}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={ev => handleSearchText(ev.target.value)}
                        />
                    </div>
                </div>
                <Grid container spacing={1}>
                    {
                        loading ?
                            <ListLoading />
                            :
                            filteredData && filteredData.length === 0 ?
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', color: '#e61e21' }}>
                                    {"No team found"}
                                </div>
                                :
                                filteredData && filteredData.map(list => (
                                    <Grid item xs={12} sm={4}>
                                        {/* <Card className={classes.card} onMouseOver={() => getAllTeamDetails(list)} onMouseLeave={() => getAllTeamDetails('')}>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '100%', marginLeft: '1rem' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                                        <span className={classes.FullName}
                                                        >{list.name}</span>
                                                        {
                                                            getAllData && getAllData.id === list.id ?
                                                                <span className={clsx(classes.editIcon,"edit")}>
                                                                    <IconButton fontSize="small" onClick={() => handleEditClick(list)} >
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                </span> : ''
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </Card> */}
                                        <Card className={classes.card}>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '100%', marginLeft: '1rem' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="profile-pic">

                                                        <span className={classes.FullName}
                                                        >{list.name}</span>
                                                        {
                                                                <span className={clsx(classes.editIcon,"edit")}>
                                                                    <IconButton fontSize="small" onClick={() => handleEditClick(list)} >
                                                                        <EditIcon />
                                                                    </IconButton>
                                                                </span>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Grid>
                                ))
                    }

                </Grid>
            </div>
        </React.Fragment>
    )
}


let mapStateToProps = (state) => {
    return {
        loadingDeleteConference: state.ui.conference.deleteConferenceReducer,
        teamData: state.conference.teamByConference,
        loading: state.ui.conference.loadingTeamByConference,
        conferenceType: state.ui.conference.selectedConferenceTypeId,
    };
};

export default connect(mapStateToProps)(TeamDetails);