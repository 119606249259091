import ListItem from "@material-ui/core/ListItem";
import { alpha, withStyles } from "@material-ui/core/styles";

const PlayerCardItem = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    "&.Mui-selected": {
      backgroundColor: alpha(theme.palette.primary.light, 0.1),
    },
  },
}))(ListItem);

export default PlayerCardItem;
