import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import { Tabs } from "../../../shared/Tabs";
import ExpansionArticle from "../PlayerAttributes/ExpansionArticle";
import { List, ListItem, ListItemText, Slide } from "@material-ui/core"


const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="left"
      ref={ref}
      timeout={{ enter: 1000, exit: 1000 }}
      {...props}
    />
  );
});


const chartColors = {
  red: "#f44336",
  orange: "#ff9800",
  yellow: "#fdd835",
  green: "#4caf50",
};

let useStyles = makeStyles((theme) => ({
  risk: {
    display: "flex",
    justifyContent: "center",
  },
}));



let CommunicationStyles = (props) => {
  let classes = useStyles();
  let [selectedIndex, setSelectedIndex] = useState(0);


  const communicationLabels = ['Your Athlete', 'About Dominant Styles', 'About Influential Styles', 'About Steady Styles', 'About Compliant Styles']

  let handleTabChange = (e, index) => {
    setSelectedIndex(index);
  };
  return (
    <div>
      <div style={{ display: "flex", justifyContent: 'center', color: '#326fc7', fontFamily: 'Prometo', }}><h3>Communication Styles</h3></div>
      <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                margin: '0rem 2rem',
                width: 'auto',

              }}>
        <Tabs
          labels={communicationLabels}
          value={selectedIndex}
          onChange={handleTabChange}
        />
        <ExpansionArticle
          title="Coming Soon"
        >
          
        </ExpansionArticle>
      </div>
    </div>
  );
};

let mapStateToProps = (state, ownProps) => {

  return {
    
  };
};

export default connect(mapStateToProps)(CommunicationStyles);
