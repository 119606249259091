import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import { useConfirm } from "material-ui-confirm";
import React, { useContext } from "react";
import { connect } from "react-redux";
import PdfIcon from "../../../assets/pdf.png";
import { ActionContext } from "../../../contexts";
import { Button } from "../../shared/Button";
import ConsentFormDialog from "./ConsentFormDialog";
// import allConsents from "modules/dashboard/components/ConsentDummyData"

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #dfdfdf",
    borderRadius: 8,
    maxWidth: 300,
    width: "100%",
    transition: "transform .2s",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
}));

let ConsentFormCard = (props) => {
  let classes = useStyles();
  let confirm = useConfirm();
  let { consentFormId, consentForm, deletingConsentForm } = props;
  // let { consentFormId, deletingConsentForm } = props;
  let { consentActions, dialogActions } = useContext(ActionContext);

  // const consentForm = allConsents["pending_consent_forms"][consentFormId]

  let handleConsentFormDelete = () => {
    confirm({
      description: "Do you really want to delete the consent form?",
      confirmationText: "Yes",
    })
      .then(() => {
        let data = {
          consent_form_id: consentFormId,
        };
        consentActions.deleteConsentForm(data);
      })
      .catch(() => {
        // do nothing
      });
  };

  let handleCardClick = () => {
    let handleSuccess = () => {
      dialogActions.openDialog(<ConsentFormDialog />, { maxWidth: "md" });
    };
    consentActions.selectConsentForm(consentFormId, handleSuccess);
  };

  return (
    <Card className={classes.root} variant="outlined">
      <CardHeader
        title={consentForm.name}
        titleTypographyProps={{ variant: "subtitle2" }}
      />
      <CardContent
        style={{ flex: 1, display: "flex", justifyContent: "center" }}
      >
        <img alt="pdf-icon" src={PdfIcon} width={50} />
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="primary"
          variant="text"
          onClick={handleCardClick}
          // disabled={true}
        >
          View
        </Button>
        <Button
          size="small"
          color="primary"
          variant="text"
          onClick={handleConsentFormDelete}
          loading={deletingConsentForm}
          // disabled={true}
        >
          Delete
        </Button>
      </CardActions>
    </Card>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { consentFormId } = ownProps;
  return {
    consentForm: state.consent.consentForms.byId[consentFormId],
    deletingConsentForm: state.ui.consent.deletingConsentForm[consentFormId],
  };
};
export default connect(mapStateToProps)(ConsentFormCard);
