import React, { useContext } from "react";
import Snackbar from "./Snackbar";
import { ActionContext } from "../../../../contexts";
import { connect } from "react-redux";

const SncakbarContainer = ({ snackbar }) => {
  let actions = useContext(ActionContext);
  let { snackbarActions } = actions;
  let { isOpen, message, props } = snackbar;

  let handleClose = () => {
    snackbarActions.closeSnackbar();
  };

  return (
    <Snackbar
      isOpen={isOpen}
      message={message}
      onClose={handleClose}
      {...props}
    />
  );
};

let mapStateToProps = (state) => {
  return {
    snackbar: state.ui.snackbar,
  };
};

export default connect(mapStateToProps)(SncakbarContainer);
