import React from "react";
import reduxStore from "./store";
import config from "./config";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ActionContextProvider as ActionProvider } from "./contexts";
import { bindActionCreators } from "./utils";
import { BrowserRouter } from "react-router-dom";
import { Provider as AppStateProvider } from "react-redux";
import { Root } from "./modules/app";
import { consentActions } from "modules/consent";
import { playerActions } from "./modules/players";
import { accountActions } from "./modules/account";
import { snackbarActions } from "./modules/shared/Snackbar";
import { dialogActions } from "./modules/shared/Dialog";
import { appActions } from "./modules/app";
import { dashboardActions } from "./modules/dashboard";
import { conferenceActions } from "./modules/conference"
import { sportsActions } from "./modules/sports"
import { coachActions } from "./modules/coach"

import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

let dispatch = reduxStore.dispatch;

let actionCreatorsByDomains = {
  dialogActions,
  accountActions,
  snackbarActions,
  playerActions,
  consentActions,
  appActions,
  dashboardActions,
  conferenceActions,
  sportsActions,
  coachActions
};

let App = () => {
  let boundActionCreators = bindActionCreators(
    actionCreatorsByDomains,
    dispatch
  );

  return (
    <AppStateProvider store={reduxStore}>
      <BrowserRouter history={config.history}>
        <ActionProvider value={boundActionCreators}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Root appConfig={config} />
          </MuiPickersUtilsProvider>
        </ActionProvider>
      </BrowserRouter>
    </AppStateProvider>
  );
};

export default App;
