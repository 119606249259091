import React from "react";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import clsx from "clsx";
import { makeStyles, fade } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  avatar: {
    marginTop: theme.spacing(1),
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  content: {
    maxWidth: 500,
    padding: theme.spacing(1),
    borderRadius: 12,
    backgroundColor: theme.palette.grey[100],
  },
  self: {
    backgroundColor: fade(theme.palette.primary.light, 0.3),
  },
}));

const ChatBubble = (props) => {
  const classes = useStyles();
  const { text, isSelf = false, playerImage = null, score } = props;

  return (
    <div className={classes.root}>
      <ListItemAvatar>
        <Avatar className={classes.avatar} src={isSelf ? playerImage : null} />
      </ListItemAvatar>
      <ListItemText
        className={clsx(classes.content, { [classes.self]: isSelf })}
        primary={text}
        secondary={`Score: ${score}`}
      />
    </div>
  );
};

export default ChatBubble;
