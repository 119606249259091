import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { Formik } from "formik";
import React, { useContext } from "react";
import { connect } from "react-redux";
import { object, string } from "yup";
import appConfig from "../../../config";
import { ActionContext } from "../../../contexts";
import { errorProps } from "../../../utils";
import Button from "../../shared/Button";
import TextField from "../../shared/TextField";
import BasicInfoArticle from "./BasicInfoArticle";

let changePasswordSchema = object().shape({
  currentPassword: string().min(4).max(200).required("Enter current password"),
  newPassword: string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/,
      "Password dosen't fulfill the requirement"
    )
    .required("Enter new password"),
});

let useStyles = makeStyles((theme) => ({
  root: {
    height: `calc(100vh - 50px)`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
  },
  container: {
    paddingTop: theme.spacing(5),
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  heading: {
    padding: theme.spacing(2, 2, 1, 2),
  },
}));

let Settings = (props) => {
  let classes = useStyles();
  let { isDrawerOpen, accountState, accountUiState } = props;
  let drawerWidth = isDrawerOpen
    ? appConfig.drawer.maxWidth
    : appConfig.drawer.minWidth;
  let { loading: isSubmitting, error } = accountUiState;
  let { fullName, email, team, sport } = accountState;

  let actions = useContext(ActionContext);

  let handleFormSubmit = (values, formBag) => {
    let data = {
      email: email,
      current_password: values.currentPassword,
      new_password: values.newPassword,
    };
    let { accountActions, snackbarActions } = actions;

    let successCb = () => {
      snackbarActions.showSnackbar("Password changed successfully", {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
          autoHideDuration: 3000,
        },
      });
      formBag.resetForm({});
    };

    accountActions.changePassword(data, successCb);
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="md" className={classes.container}>
        <div>
          <BasicInfoArticle user={{ fullName, email, team , sport }} />
          <Paper variant="outlined" style={{ marginTop: 40 }}>
            <div className={classes.heading}>
              <Typography variant="h6">Change Password</Typography>
            </div>
            <div
              style={{
                padding: 16,
                paddingBottom: 32,
              }}
            >
              <div style={{ marginBottom: 16 }}>
                <Typography variant="caption" color="textSecondary">
                  New Password must contain at least 6 Characters, One
                  Uppercase, One Lowercase and One Number
                </Typography>
              </div>

              {error ? (
                <Alert severity="error" style={{ marginBottom: 16 }}>
                  {error}
                </Alert>
              ) : null}
              <Formik
                initialValues={{ currentPassword: "", newPassword: "" }}
                validationSchema={changePasswordSchema}
                onSubmit={handleFormSubmit}
              >
                {(formik) => {
                  let {
                    handleBlur,
                    handleChange,
                    errors,
                    touched,
                    handleSubmit,
                    values,
                  } = formik;

                  return (
                    <form
                      style={{ display: "flex" }}
                      noValidate
                      onSubmit={handleSubmit}
                    >
                      <TextField
                        name="currentPassword"
                        type="password"
                        label="Current Password"
                        placeholder="Current Password"
                        size="small"
                        value={values.currentPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ marginRight: 16 }}
                        fullWidth
                        required
                        {...errorProps(errors, touched, "currentPassword")}
                      />
                      <TextField
                        name="newPassword"
                        type="password"
                        label="New Password"
                        placeholder="New Password"
                        size="small"
                        value={values.newPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={{ marginRight: 16 }}
                        fullWidth
                        required
                        {...errorProps(errors, touched, "newPassword")}
                      />
                      <div>
                        <Button
                          type="submit"
                          color="primary"
                          loading={isSubmitting}
                          disableElevation
                        >
                          Change
                        </Button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </Paper>
        </div>
      </Container>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    isDrawerOpen: state.ui.app.isDrawerOpen,
    accountState: state.account,
    accountUiState: state.ui.account.changePassword,
  };
};
export default connect(mapStateToProps)(Settings);
