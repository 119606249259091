export const parseFeedResponse = (data) => {
  const parsed = data.reduce((obj, item) => {
    const { _id, date, score, text, url, ...rest } = item;
    obj[item._id] = {
      id: item._id,
      date: item.date,
      score: item.score,
      text: item.text,
      url: item.url,
      ...rest,
    };
    return obj;
  }, {});

  return parsed;
};
