import React from "react";
import IFrame from "./IFrame";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ChatBubble from "./ChatBubble";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "calc(100vh - 64px)",
    display: "flex",
    flexDirection: "column",
  },
  list: {
    height: "calc(100vh - 130px)",
    overflowY: "scroll",
    width: 500,
    "&::-webkit-scrollbar": {
      width: 8,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.background.default,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[300],
      opacity: 0.5,
    },
  },
}));

const VideoDialog = (props) => {
  const classes = useStyles();
  const {
    closeDialog,
    dialogs = [],
    title = "",
    url,
    playerImage = null,
  } = props;

  return (
    <div className={classes.root}>
      <AppBar
        elevation={0}
        color="inherit"
        position="fixed"
        style={{ borderBottom: "1px solid #dfdfdf", padding: 0, margin: 0 }}
      >
        <Toolbar>
          <Typography variant="h6" style={{ flex: 1 }}>
            {title}
          </Typography>
          <IconButton onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IFrame url={url} width={750} height={421} />
        </div>
        <div style={{ borderLeft: "1px solid #dfdfdf" }}>
          <Typography
            variant="subtitle2"
            style={{ paddingLeft: 16, paddingTop: 16, marginTop: 0 }}
          >
            Conversation
          </Typography>
          <List className={classes.list}>
            {dialogs.map((dialog, index) => {
              return (
                <ListItem key={index}>
                  <ChatBubble
                    text={dialog.text}
                    isSelf={dialog.is_self}
                    score={dialog.score}
                    playerImage={playerImage}
                  />
                </ListItem>
              );
            })}
          </List>
        </div>
      </div>
    </div>
  );
};

export default VideoDialog;
