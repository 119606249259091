import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { connect } from "react-redux";
import { sourceLanguageTypes } from "../../constants";

let socialUrls = {
  twitter: "https://twitter.com",
  instagram: "https://www.instagram.com",
  facebook: "https://www.facebook.com",
  snapchat: "https://www.snapchat.com/add",
  linkedin: "https://www.linkedin.com/in",
  email: "mailto:",
};

let useStyles = makeStyles((theme) => ({
  label: {
    textTransform: "uppercase",
    fontSize: 11,
    fontWeight: 700,
    width: 176,
    marginRight: theme.spacing(2),
  },
  value: {
    flex: 1,
    textTransform: "lowercase",
  },
}));

let Item = ({
  label,
  value,
  link,
  onClick = (f) => f,
  enableSourceLn = false,
  sourceLn = null,
}) => {
  let classes = useStyles();

  if (value === "" || value === null || value === undefined) {
    return null;
  } else if (value.toLowerCase() === "n/a" || value.toLowerCase() === "na") {
    return null;
  }

  const getSourceLanguage = () => {
    if (!enableSourceLn) {
      return null;
    }

    return (
      <span>
        {" "}
        {sourceLn
          ? `(${sourceLanguageTypes[sourceLn]})`
          : `(${sourceLanguageTypes["en"]})`}{" "}
      </span>
    );
  };

  return (
    <ListItem onClick={onClick(link)} button>
      <Typography
        variant="button"
        color="textSecondary"
        className={classes.label}
      >
        {label}
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {value} {getSourceLanguage()}
      </Typography>
      {/* <ListItemSecondaryAction> */}
      {/* <ChevronRight /> */}
      {/* </ListItemSecondaryAction> */}
    </ListItem>
  );
};

const ContactInfo = (props) => {
  let { contactInfo } = props;

  let handleLinkClick = (link) => {
    return () => {
      window.open(link, "_blank");
    };
  };

  return (
    <List>
      <Item label="Email" value={contactInfo.email} />
      <Item label="Phone" value={contactInfo.phone} />
      <Item
        label="Twitter"
        value={contactInfo.twitter}
        link={`${socialUrls.twitter}/${contactInfo.twitter}`}
        enableSourceLn={true}
        sourceLn={contactInfo.twitterSourceln}
        onClick={handleLinkClick}
      />
      <Item
        label="Instagram"
        value={contactInfo.instagram}
        link={`${socialUrls.instagram}/${contactInfo.instagram}`}
        enableSourceLn={true}
        sourceLn={contactInfo.instagramSourceln}
        onClick={handleLinkClick}
      />
      <Item
        label="Facebook"
        value={contactInfo.facebook}
        link={`${socialUrls.facebook}/${contactInfo.facebook}`}
        onClick={handleLinkClick}
      />
      <Item
        label="Snapchat"
        value={contactInfo.snapchat}
        link={`${socialUrls.snapchat}${contactInfo.snapchat}`}
        onClick={handleLinkClick}
      />
      <Item
        label="Linkedin"
        value={contactInfo.linkedin}
        link={`${socialUrls.linkedin}/${contactInfo.linkedin}`}
        enableSourceLn={true}
        sourceLn={contactInfo.linkedinSourceln}
        onClick={handleLinkClick}
      />
    </List>
  );
};

let mapStateToProps = (state, ownProps) => {
  return {
    contactInfo: state.player.contact[ownProps.playerId],
  };
};
export default connect(mapStateToProps)(ContactInfo);
