import React from "react";
import Graph from "./Graph";
import Typography from "@material-ui/core/Typography";
import { Article } from "../../../shared/Article";
import { connect } from "react-redux";
import NoData from '../../../../assets/NoData.svg';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  placeholder: {
    // height: 150,
    // width: 660,
    display: "block",
    width: '100%',
    textAlign: 'center',
    margin: '1rem 0rem',
    justifyContent: "center",
    alignItems: " center",
  },
}));

const GraphPanel = (props) => {
  let classes = useStyles();
  const { series, show, loadingPlayerFeed } = props;

  if (loadingPlayerFeed === true || show === false) {
    return null;
  }

  let hasData = false;

  series.map((s) => {
    if (s.data.length > 0) {
      hasData = true;
    }
  });

  return (
    <Article title="Sentiment Over Time">
      {hasData ? (
        <div style={{ width: 660 }}>
          <Graph series={series} />
        </div>
      ) : (
        <div className={classes.placeholder}>
          <img src={NoData} style={{ width: '11rem' }} />
          <div style={{ color: '#0971ce' }}>  No Data</div>
        </div>
      )}
    </Article>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { selectedIndex, config, playerId } = ownProps;

  const { source, show } = config[selectedIndex];

  return {
    loadingPlayerFeed:
      state.ui.player.loadingPlayerFeed[source][playerId] || false,
    series: state.player.feed[source][playerId]
      ? state.player.feed[source][playerId]["graphs"]
      : [],
    source,
    show,
  };
};

export default connect(mapStateToProps)(GraphPanel);
