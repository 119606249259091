
import React, { useContext, useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Tooltip, FormControl, InputLabel, MenuItem, Select, Box, Tabs, Tab, Typography } from "@material-ui/core"
import appConfig from "../../../config";
import PlayerAttributes from './PlayerAttributes';
import RiskFactors from './RiskFactors';
import Filter from './Filter';
import FilterAltIcon from '@material-ui/icons/FilterList';
import { ActionContext } from "../../../contexts";
import CircularProgress from "modules/shared/CircularProgress";
import InfoIcon from "@material-ui/icons/Info";
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles
} from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/ClearOutlined";
import IconButton from "@material-ui/core/IconButton";
import CommunicationStyles from './Communication/comminicationStyles';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

let useStyles = makeStyles((theme) => ({
    leftTab: {
        fontFamily: 'Prometo',
        fontSize: '14px',
        // color: '#fcb643',
    },
    // list: {
    //     display: "flex",
    //     flexDirection: "column",
    //     height: "100%",
    //     width: "100%",
    //     boxSizing: "border-box",
    //     borderRight: `1px solid ${theme.palette.divider}`,
    //     [theme.breakpoints.between("lg", "xl")]: {
    //         maxWidth: 350,
    //     },
    // },
    root: {
        backgroundColor: theme.palette.background.default,
        // display: "flex",
        height: `calc(100vh - 50px)`,
        zIndex: theme.zIndex.appBar,
    },
    container: {
        // padding: 16,
        // paddingTop: 48,
        // marginLeft: "auto",
        // marginRight: "auto",
        // flexWrap: "wrap",
        [theme.breakpoints.down("md")]: {
            maxWidth: 550,
        },
    },
    toolbar: {
        display: "flex",
        backgroundColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    btnPrimary: {
        // color: '#fff',
        // border: '1px solid #326fc7',
        // height: '30px',
        // padding: '10px',
        // marginRight: '10px',
        // borderRadius: '20px',
        // background: '#326fc7',
        // '&:hover': {
        //     background: '#326fc7',
        //     color: '#fff'
        // },
        color: '#fff',
        border: '1px solid',
        padding: '10px',
        background: '#007fdb',
        // width: '13rem',
        marginRight: '10px',
        borderRadius: '20px',
        height: '30px',
        '&:hover': {
            background: '#007fdb',
            color: '#fff'
        },
    },
    btnSecondary: {
        // color: '#fff',
        // border: '1px solid #326fc7',
        // height: '30px',
        // padding: '10px',
        // marginRight: '10px',
        // borderRadius: '20px',
        // background: '#326fc7',
        // '&:hover': {
        //     background: '#326fc7',
        //     color: '#fff'
        // }
        color: theme.palette.secondary.main,
        border: '1px solid',
        // width: '13rem',
        padding: '10px',
        marginRight: '10px',
        borderRadius: '20px',
        height: '30px',
        '&:hover': {
            background: theme.palette.secondary.main,
            color: '#fff'
        }

    },

}));

function TeamMain(props) {
    let classes = useStyles();
    let { accountType, isDrawerOpen, updateTeamFilter, loadingPlayerFilters, pageChange, sportsData, unitData, sportPosition } = props;

    let actions = useContext(ActionContext);
    let { playerActions, sportsActions } = actions;
    let accountState = useSelector((state) => state.account);

    let drawerWidth = isDrawerOpen
        ? appConfig.drawer.maxWidth
        : appConfig.drawer.minWidth;

    const [selectedTab, setSelectedTab] = useState('player')
    const [selectedPage, setSelectedPage] = useState(0)

    const handleChange = (event, newValue) => {
        setSelectedPage(newValue);
    };

    const [state, setState] = React.useState({
        sport: accountState?.sport ? accountState?.sport : "",
        unit: "",
        position: "",
        athleteType: "",
    });

    useEffect(() => {
        if (updateTeamFilter) {
            setState(updateTeamFilter);
        }
    }, [updateTeamFilter]);

    const recruit = [
        {
            "name": "Rostered Player",
            "id": "Rostered Player"
        },
        {
            "name": "Potential Recruit",
            "id": "Potential Recruit"
        }
    ]

    const handlePlayerChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
        playerActions.updateTeamFilterValue(event.target.name, event.target.value)
    }


    const handleSportChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
        playerActions.updateTeamFilterValue(event.target.name, event.target.value)
        sportsActions.getSportUnitDetails(event.target.value);
    }

    const handleUnitChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
        playerActions.updateTeamFilterValue(event.target.name, event.target.value)
        sportsActions.getSportPositionDetails(event.target.value, (accountState?.sport ? accountState?.sport : "Football"));
    }

    const handleTabChange = (value) => {
        setSelectedTab(value)
    }

    const handlePageChange = (value) => {
        setSelectedPage(value)
    }

    const handleCancel = () => {
        playerActions.resetTeamFilterValue()
        playerActions.updateTeamFilterValue("sport", (accountState?.sport ? accountState?.sport : "Football"))
        sportsActions.resetSportsApi()
        let model = {
            sport: accountState?.sport ? [accountState?.sport] : [],
            unit: [],
            position: [],
            athleteType: [],
        }
        playerActions.getAllFiltersPlayers(model)
    }

    const handleSave = () => {
        let model = {
            sport: state.sport ? [state.sport] : accountState?.sport ? [accountState?.sport] : [],
            unit: state.unit ? [state.unit] : [],
            position: state.position ? [state.position] : [],
            athleteType: state.athleteType ? [state.athleteType] : [],
        }

        playerActions.getAllFiltersPlayers(model)

    }

    const handleClearClick = (name) => {
        if (name === "unit") {
            setState({
                ...state,
                "unit": "",
                "position": ""
            });
            playerActions.updateTeamFilterValue("unit", "")
            playerActions.updateTeamFilterValue("position", "")
            sportsActions.resetSportsApi()
        } else if (name === "sport") {
            setState({
                ...state,
                "sport": "",
                "unit": "",
                "position": ""
            });
            playerActions.updateTeamFilterValue("sport", "")
            playerActions.updateTeamFilterValue("unit", "")
            playerActions.updateTeamFilterValue("position", "")
        }
        else {
            setState({
                ...state,
                [name]: "",
            });
            playerActions.updateTeamFilterValue(name, "")
        }
    }

    useEffect(() => {
        let model = {
            sport: updateTeamFilter.sport ? [updateTeamFilter.sport] : [accountState?.sport ? accountState?.sport : "Football"],
            unit: updateTeamFilter.unit ? [updateTeamFilter.unit] : [],
            position: updateTeamFilter.position ? [updateTeamFilter.position] : [],
            athleteType: updateTeamFilter.athleteType ? [updateTeamFilter.athleteType] : [],
        }
        playerActions.getAllFiltersPlayers(model)
        sportsActions.getSportsList();
    }, []);

    useEffect(() => {
        if (loadingPlayerFilters === true) {
            playerActions.isTeamFilteredData(true)
        }
    }, [loadingPlayerFilters]);

    useEffect(() => {
        if (accountType !== null) {
            if (accountState?.sport) {
                playerActions.updateTeamFilterValue("sport", accountState?.sport)
                sportsActions.getSportUnitDetails(accountState?.sport);
            } else {
                playerActions.updateTeamFilterValue("sport", "Football")
                sportsActions.getSportUnitDetails("Football");
            }

        }

    }, [accountType]);

    const info = "Team Management allows you to see the average of the Player Attributes and Behavior Risk Factors for your Rostered Players. This dashboard allows you to see at-a-glance who your leaders are across Player Attributes and Risk Factors as well as the individuals that may need more customized development plans. Toggle between each of the Player Attributes and Risk Factors to get a better sense of your team holistically."

    const theme = createMuiTheme({
        overrides: {
            MuiTooltip: {
                tooltip: {
                    fontSize: "10px",
                    color: "#fff",
                    fontWeight: 'lighter',
                    padding: '0.5rem',
                    backgroundColor: "#326fc7"
                }
            }
        }
    });
    return (
        <React.Fragment>
            <div className={classes.root} style={{ paddingLeft: drawerWidth }}>
                <div className={classes.container}>
                    <div style={{ display: "flex", alignItems: 'center', color: '#326fc7', marginTop: '1rem', fontFamily: 'Prometo' }}>
                        <h3 >{state.sport ? state.sport : accountState?.sport} Team Overview</h3>
                        {/* <h3 >Team Overview</h3> */}
                        <MuiThemeProvider theme={theme}>
                            <Tooltip arrow title={info}>
                                <InfoIcon style={{ cursor: 'pointer' }} />
                            </Tooltip>
                        </MuiThemeProvider>

                        {/* <span className={classes.TooltipALign} style={{ marginLeft: '20px' }}>
                            <Tooltip arrow title={info}>
                                <InfoIcon style={{ cursor: 'pointer' }} />
                            </Tooltip>
                        </span> */}
                        {/* {
                            selectedPage === "list" ?
                                <Button onClick={() => handlePageChange('filter')}>
                                    <Tooltip title={"Filters"}>
                                        <FilterAltIcon />
                                    </Tooltip>
                                </Button>
                                :
                                ""
                        } */}
                    </div>
                    <div style={{
                        display: 'flex', marginBottom: "20px", padding: '0.5rem 0.4rem', background: '#0971ce1a',
                        marginRight: '1rem', borderRadius: '1rem', justifyContent: 'center', alignItems: 'center'
                    }}>
                        {
                            (accountType === "COL_HEAD_COACH" || accountType === "COL_POSITION_COACH" || accountType === "PRO_POSITION_COACH" || accountType === "PRO_GENERAL_MANAGER"
                                || accountType === "PRO_HEAD_COACH" || accountType === "COL_PSYCHOLOGIST" || accountType === "PRO_PSYCHOLOGIST" || accountType === "PRO_SECURITY")
                                ?
                                <div style={{ margin: "0rem 1rem 0rem 0rem" }}>
                                    <FormControl variant="filled" sx={{ m: 1, minWidth: 200 }}>
                                        <InputLabel id="demo-simple-select-autowidth-label">Athlete Type</InputLabel>
                                        <Select
                                            style={{ width: "200px", background: '#e1e9f5' }}
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            value={state.athleteType}
                                            onChange={handlePlayerChange}
                                            label="Athlete Type"
                                            name="athleteType"
                                            endAdornment={
                                                state.athleteType !== "" ?
                                                    <IconButton sx={{ visibility: state.athleteType !== "" ? "visible" : "hidden" }}
                                                        onClick={() => handleClearClick("athleteType")}><ClearIcon />
                                                    </IconButton>
                                                    :
                                                    ""
                                            }
                                        >
                                            {
                                                recruit && recruit.map(list =>
                                                    <MenuItem key={list.id} value={list.id}>{list.name}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                                :
                                ""
                        }
                        {
                            (accountType === "COL_PSYCHOLOGIST") ?
                                <div style={{ margin: "0rem 1rem 0rem 0rem" }}>

                                    <FormControl variant="filled">
                                        <InputLabel id="demo-simple-select-autowidth-label">Sport</InputLabel>
                                        <Select
                                            style={{ width: "200px" }}
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            value={state.sport}
                                            onChange={handleSportChange}
                                            label="Sport"
                                            name="sport"
                                        >
                                            {
                                                sportsData && sportsData.map(list =>
                                                    <MenuItem value={list.name}>{list.name}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                                :
                                ""
                        }
                        {

                            (accountType === "COL_HEAD_COACH" || accountType === "COL_POSITION_COACH" || accountType === "PRO_POSITION_COACH" || accountType === "PRO_GENERAL_MANAGER"
                                || accountType === "PRO_HEAD_COACH" || accountType === "COL_PSYCHOLOGIST" || accountType === "PRO_PSYCHOLOGIST" || accountType === "PRO_SECURITY")
                                ?
                                <div style={{ margin: "0rem 1rem 0rem 0rem" }}>
                                    <FormControl variant="filled" sx={{ m: 1, minWidth: 80 }}>
                                        <InputLabel id="demo-simple-select-autowidth-label">Unit</InputLabel>
                                        <Select
                                            style={{ width: "200px", background: '#e1e9f5' }}
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            value={state.unit}
                                            onChange={handleUnitChange}
                                            autoWidth
                                            label="Unit"
                                            name="unit"
                                            endAdornment={
                                                state.unit !== "" ?
                                                    <IconButton sx={{ visibility: state.unit !== "" ? "visible" : "hidden" }}
                                                        onClick={() => handleClearClick("unit")}><ClearIcon />
                                                    </IconButton>
                                                    :
                                                    ""
                                            }
                                        >
                                            {
                                                unitData && unitData.map(list =>
                                                    <MenuItem value={list.unit}>{list.unit}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                                :
                                ""
                        }
                        {
                            (accountType === "COL_HEAD_COACH" || accountType === "COL_POSITION_COACH" || accountType === "PRO_POSITION_COACH" || accountType === "PRO_GENERAL_MANAGER"
                                || accountType === "PRO_HEAD_COACH" || accountType === "COL_PSYCHOLOGIST" || accountType === "PRO_PSYCHOLOGIST" || accountType === "PRO_SECURITY")
                                ?
                                <div style={{ margin: "0rem 1rem 0rem 0rem" }}>
                                    <FormControl variant="filled" sx={{ m: 1, minWidth: 80 }}>
                                        <InputLabel id="demo-simple-select-autowidth-label">Position</InputLabel>
                                        <Select
                                            style={{ width: "200px", background: '#e1e9f5' }}
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            value={state.position}
                                            onChange={handlePlayerChange}
                                            autoWidth
                                            label="Position"
                                            name="position"
                                            endAdornment={
                                                state.position !== "" ?
                                                    <IconButton sx={{ visibility: state.position !== "" ? "visible" : "hidden" }}
                                                        onClick={() => handleClearClick("position")}><ClearIcon />
                                                    </IconButton>
                                                    :
                                                    ""
                                            }
                                        >
                                            {
                                                sportPosition && sportPosition.map(list =>
                                                    <MenuItem value={list.position}>{list.position}</MenuItem>
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                                :
                                ""
                        }
                        {
                            accountType === "PRO_SCOUTS" ? "" :
                                <div>
                                    <Button className={classes.btnSecondary}
                                        disabled={Object.keys(updateTeamFilter).length === 0}
                                        onClick={handleCancel}>
                                        Clear
                                    </Button>
                                    <Button className={classes.btnPrimary}
                                        disabled={Object.keys(updateTeamFilter).length === 0}
                                        onClick={handleSave}>
                                        Run Report
                                    </Button>
                                </div>
                        }
                    </div>

                    {
                        loadingPlayerFilters ?
                            <CircularProgress size={72} thickness={3} color="primary" />
                            :
                            // selectedPage === "list" ?
                            <>

                                <div style={{ display: "flex", justifyContent: 'center' }}>
                                    {/* <div style={{ marginRight: "40px" }}>
                                            <Button onClick={() => handleTabChange("player")} style={{ color: selectedTab === "player" ? "green" : "" }}>Player Attributes</Button>
                                        </div>
                                        <div>
                                            <Button onClick={() => handleTabChange("risk")} style={{ color: selectedTab === "risk" ? "green" : "" }}>Risk Factors</Button>
                                        </div> */}
                                    <Box
                                        sx={{ flexGrow: 1, display: 'flex', marginRight: '1rem' }}
                                    >
                                        <Tabs
                                            style={{
                                                background: 'rgb(225 236 245)',
                                                borderRadius: '9px',
                                            }}
                                            orientation="vertical"
                                            variant="scrollable"
                                            value={selectedPage}
                                            onChange={handleChange}
                                            aria-label="Vertical tabs example"
                                            sx={{ borderRight: 1, borderColor: 'divider' }}
                                        >
                                            <Tab className={classes.leftTab} label="Player Attributes" {...a11yProps(0)} />
                                            <Tab className={classes.leftTab} label="Communication" {...a11yProps(1)} />
                                            {/* <Tab className={classes.leftTab} label="Risk Factors" {...a11yProps(1)} /> */}
                                        </Tabs>
                                        <TabPanel style={{ padding: '0' }} value={selectedPage} index={0}>
                                            <PlayerAttributes />
                                        </TabPanel>
                                        <TabPanel style={{ padding: '0' }} value={selectedPage} index={1}>
                                            <CommunicationStyles />
                                        </TabPanel>
                                        {/* <TabPanel value={selectedPage} index={1}>
                                            <RiskFactors />
                                        </TabPanel> */}
                                    </Box>
                                </div>
                                {/* <div>
                                        {
                                            selectedTab === "player" ?
                                                <PlayerAttributes />
                                                :
                                                <RiskFactors />
                                        }
                                    </div> */}
                            </>
                        // :
                        // <Filter pageChange={handlePageChange} />
                    }
                </div>

            </div>
        </React.Fragment>
    )
}


let mapStateToProps = (state) => {
    return {
        updateTeamFilter: state.player.updateTeamFilter,
        loadingPlayerFilters: state.ui.player.loadingPlayerFilters,
        sportsData: state.sports.sportsListData,
        unitData: state.sports.unitSport,
        sportPosition: state.sports.sportPosition,
        isTeamFiltered: state.player.isTeamFiltered,
        accountType: state.account.accountType,
    }
}

export default connect(mapStateToProps)(TeamMain)