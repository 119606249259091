

export const REQUEST_GET_COACHES = "REQUEST_GET_COACHES";
export const SET_COACHES_SUCCESS = "SET_COACHES_SUCCESS";
export const GET_COACHES_FAILED = "GET_COACHES_FAILED";

export const SEARCH_COACHES = "SEARCH_COACHES";
export const RESET_SEARCH = "RESET_SEARCH";

export const SELECT_COACHES = "SELECT_COACHES";
export const DESELECT_COACHES = "DESELECT_COACHES";

export const GET_FILTER_COACH_REQUEST = "GET_FILTER_COACH_REQUEST"
export const GET_FILTER_COACH_SUCCESS = "GET_FILTER_COACH_SUCCESS"
export const GET_FILTER_COACH_FAILED = "GET_FILTER_COACH_FAILED"

export const UPDATE_COACH_FILTER_VALUE = "UPDATE_COACH_FILTER_VALUE"
export const RESET_COACH_FILTER_VALUE = "RESET_COACH_FILTER_VALUE"
export const IS_COACH_FILTERED_DATA = "IS_COACH_FILTERED_DATA"


export const REQUEST_GET_COACH = "REQUEST_GET_COACH";
export const GET_COACH_SUCCESS = "GET_COACH_SUCCESS";
export const GET_COACH_FAILED = "GET_COACH_FAILED";




/////////


//
// export const REQUEST_GET_PLAYER = "REQUEST_GET_PLAYER";
// export const GET_PLAYER_SUCCESS = "GET_PLAYER_SUCCESS";
// export const GET_PLAYER_FAILED = "GET_PLAYER_FAILED";




///////////////////////////////////


export const REQUEST_GET_COACH_FEED = "REQUEST_GET_COACH_FEED";
export const GET_COACH_FEED_SUCCESS = "GET_COACH_FEED_SUCCESS";
export const GET_COACH_FEED_FAILED = "GET_COACH_FEED_FAILED";
export const LOADING_FEED = "LOADING_FEED";
// //
export const REQUEST_EXPORT_COACH_FEED = "REQUEST_EXPORT_COACH_FEED";
export const EXPORT_COACH_FEED_SUCCESS = "EXPORT_COACH_FEED_SUCCESS";
export const EXPORT_COACH_FEED_FAILED = "EXPORT_COACH_FEED_FAILED";
// //
export const REQUEST_ADD_COACH_RISK_COMMENT = "REQUEST_ADD_COACH_RISK_COMMENT";
export const ADD_COACH_RISK_COMMENT_SUCCESS = "ADD_COACH_RISK_COMMENT_SUCCESS";
export const ADD_COACH_RISK_COMMENT_FAILED = "ADD_COACH_RISK_COMMENT_FAILED";
//
export const REQUEST_DELETE_COACH_RISK_COMMENT = "REQUEST_DELETE_COACH_RISK_COMMENT";
export const DELETE_COACH_RISK_COMMENT_SUCCESS = "DELETE_COACH_RISK_COMMENT_SUCCESS";
export const DELETE_COACH_RISK_COMMENT_FAILED = "DELETE_COACH_RISK_COMMENT_FAILED";
// //
export const REQUEST_ADD_COACH_ACTION_PLAN_COMMENT =
  "REQUEST_ADD_COACH_ACTION_PLAN_COMMENT";
export const ADD_COACH_ACTION_PLAN_COMMENT_SUCCESS =
  "ADD_COACH_ACTION_PLAN_COMMENT_SUCCESS";
export const ADD_COACH_ACTION_PLAN_COMMENT_FAILED = "ADD_COACH_ACTION_PLAN_COMMENT_FAILED";
// //
export const REQUEST_DELETE_COACH_ACTION_PLAN_COMMENT =
  "REQUEST_DELETE_COACH_ACTION_PLAN_COMMENT";
export const DELETE_COACH_ACTION_PLAN_COMMENT_SUCCESS =
  "DELETE_COACH_ACTION_PLAN_COMMENT_SUCCESS";
export const DELETE_COACH_ACTION_PLAN_COMMENT_FAILED =
  "DELETE_COACH_ACTION_PLAN_COMMENT_FAILED";
// //
export const REQUEST_COACH_ADD_EXECUTIVE_SUMMERY_COMMENT =
  "REQUEST_COACH_ADD_EXECUTIVE_SUMMERY_COMMENT";
export const ADD_COACH_EXECUTIVE_SUMMERY_COMMENT_SUCCESS =
  "ADD_COACH_EXECUTIVE_SUMMERY_COMMENT_SUCCESS";
export const ADD_COACH_EXECUTIVE_SUMMERY_COMMENT_FAILED =
  "ADD_COACH_EXECUTIVE_SUMMERY_COMMENT_FAILED";

// //
// export const REQUEST_DELETE_EXECUTIVE_SUMMERY_COMMENT =
//   "REQUEST_DELETE_EXECUTIVE_SUMMERY_COMMENT";
// export const DELETE_EXECUTIVE_SUMMERY_COMMENT_SUCCESS =
//   "DELETE_EXECUTIVE_SUMMERY_COMMENT_SUCCESS";
// export const DELETE_EXECUTIVE_SUMMERY_COMMENT_FAILED =
//   "DELETE_EXECUTIVE_SUMMERY_COMMENT_FAILED";
// //
export const REQUEST_APPROVE_COACH_PROFILE =
  "REQUEST_APPROVE_COACH_PROFILE";
export const APPROVE_COACH_PROFILE_SUCCESS =
  "APPROVE_COACH_PROFILE_SUCCESS";
export const APPROVE_COACH_PROFILE_FAILED =
  "APPROVE_COACH_PROFILE_FAILED";
// //
export const REQUEST_EXPORT_COACH_REPORT = "REQUEST_EXPORT_COACH_REPORT";
export const EXPORT_COACH_REPORT_SUCCESS = "EXPORT_COACH_REPORT_SUCCESS";
export const EXPORT_COACH_REPORT_FAILED = "EXPORT_COACH_REPORT_FAILED";
// //
// export const REQUEST_EXPORT_SYSTEM_REPORT = "REQUEST_EXPORT_SYSTEM_REPORT";
// export const EXPORT_SYSTEM_REPORT_SUCCESS = "EXPORT_SYSTEM_REPORT_SUCCESS";
// export const EXPORT_SYSTEM_REPORT_FAIL = "EXPORT_SYSTEM_REPORT_FAIL";
//
export const REQUEST_EXPORT_PSYCOLOGIST_REPORT =
  "REQUEST_EXPORT_PSYCOLOGIST_REPORT";
export const EXPORT_PSYCOLOGIST_REPORT_SUCCESS =
  "EXPORT_PSYCOLOGIST_REPORT_SUCCESS";
export const EXPORT_PSYCOLOGIST_REPORT_FAIL = "EXPORT_PSYCOLOGIST_REPORT_FAIL";
//
export const REQUEST_REJECT_COACH_PROFILE =
  "REQUEST_REJECT_COACH_PROFILE";
export const REJECT_COACH_PROFILE_SUCCESS =
  "REJECT_COACH_PROFILE_SUCCESS";
export const REJECT_COACH_PROFILE_FAILED =
  "REJECT_COACH_PROFILE_FAILED";
// //
export const REQUEST_REVIEW_COMPLETE_COACH =
  "REQUEST_REVIEW_COMPLETE_COACH";
export const REVIEW_COMPLETE_COACH_SUCCESS =
  "REVIEW_COMPLETE_COACH_SUCCESS";
export const REVIEW_COMPLETE_COACH_FAILED =
  "REVIEW_COMPLETE_COACH_FAILED";
// //
export const REQUSET_COACH_REVIEW_INCOMPLETE =
  "REQUSET_COACH_REVIEW_INCOMPLETE";
export const COACH_REVIEW_INCOMPLETE_SUCCESS =
  "COACH_REVIEW_INCOMPLETE_SUCCESS";
export const COACH_REVIEW_INCOMPLETE_FAILED =
  "COACH_REVIEW_INCOMPLETE_FAILED";
// //

// export const REQUEST_REGENERATE_SYSTEM_REPORT =
//   "REQUEST_REGENERATE_SYSTEM_REPORT";
// export const REGENERATE_SYSTEM_REPORT_SUCCESS =
//   "REGENERATE_SYSTEM_REPORT_SUCCESS";
// export const REGENERATE_SYSTEM_REPORT_FAIL = "REGENERATE_SYSTEM_REPORT_FAIL";
// //
// export const REQUEST_REGENERATE_PSYCH_REPORT =
//   "REQUEST_REGENERATE_PSYCH_REPORT";
// export const REGENERATE_PSYCH_REPORT_SUCCESS =
//   "REGENERATE_PSYCH_REPORT_SUCCESS";
// export const REGENERATE_PSYCH_REPORT_FAIL = "REGENERATE_PSYCH_REPORT_FAIL";
// //
export const REQUEST_UPDATE_COACH_NOTES = "REQUEST_UPDATE_COACH_NOTES";
export const UPDATE_COACH_NOTES_SUCCESS = "UPDATE_COACH_NOTES_SUCCESS";
export const UPDATE_COACH_NOTES_FAILED = "UPDATE_COACH_NOTES_FAILED";


export const REQUEST_ADD_COACH_ACTION_PLAN_CONTENT =
  "REQUEST_ADD_COACH_ACTION_PLAN_CONTENT";
export const ADD_COACH_ACTION_PLAN_CONTENT_SUCCESS =
  "ADD_COACH_ACTION_PLAN_CONTENT_SUCCESS";
export const ADD_COACH_ACTION_PLAN_CONTENT_FAILED = "ADD_COACH_ACTION_PLAN_CONTENT_FAILED";


export const REQUEST_ADD_COACH_EXECUTIVE_SUMMERY_CONTENT =
  "REQUEST_ADD_COACH_EXECUTIVE_SUMMERY_CONTENT";
export const ADD_COACH_EXECUTIVE_SUMMERY_CONTENT_SUCCESS =
  "ADD_COACH_EXECUTIVE_SUMMERY_CONTENT_SUCCESS";
export const ADD_COACH_EXECUTIVE_SUMMERY_CONTENT_FAILED =
  "ADD_COACH_EXECUTIVE_SUMMERY_CONTENT_FAILED";

export const REQUEST_TOGGLE_COACH_PAID_STATUS =
  "REQUEST_TOGGLE_COACH_PAID_STATUS";

export const TOGGLE_COACH_PAID_STATUS_SUCCESS =
  "TOGGLE_COACH_PAID_STATUS_SUCCESS";
export const TOGGLE_COACH_PAID_STATUS_FAILED =
  "TOGGLE_COACH_PAID_STATUS_FAILED";
// //
export const REQUEST_ADD_COACH_LINK = "REQUEST_ADD_COACH_LINK";
export const ADD_COACH_LINK_SUCCESS = "ADD_COACH_LINK_SUCCESS";
export const ADD_COACH_LINK_FAIL = "ADD_COACH_LINK_FAIL";
// //
export const REQUEST_DELETE_COACH_LINK = "REQUEST_DELETE_COACH_LINK";
export const DELETE_COACH_LINK_SUCCESS = "DELETE_COACH_LINK_SUCCESS";
export const DELETE_COACH_LINK_FAIL = "DELETE_COACH_LINK_FAIL";
// //
export const REQUEST_TOGGLE_COACH_FULL_REPORT_PAYMENT_STATUS =
  "TOGGLE_COACH_FULL_REPORT_PAYMENT_STATUS";
export const TOGGLE_COACH_FULL_REPORT_PAYMENT_STATUS_SUCCESS =
  "TOGGLE_COACH_FULL_REPORT_PAYMENT_STATUS_SUCCESS";
export const TOGGLE_COACH_FULL_REPORT_PAYMENT_STATUS_FAILED =
  "TOGGLE_COACH_FULL_REPORT_PAYMENT_STATUS_FAILED";
// //
export const REQUEST_TOGGLE_COACH_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS =
  "TOGGLE_COACH_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS";
export const TOGGLE_COACH_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS_SUCCESS =
  "TOGGLE_COACH_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS_SUCCESS";
export const TOGGLE_COACH_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS_FAILED =
  "TOGGLE_COACH_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS_FAILED";
// //
export const REQUEST_TOGGLE_COACH_ACTION_PLAN_REPORT_PAYMENT_STATUS =
  "TOGGLE_COACH_ACTION_PLAN_REPORT_PAYMENT_STATUS";
export const TOGGLE_COACH_ACTION_PLAN_REPORT_PAYMENT_STATUS_SUCCESS =
  "TOGGLE_COACH_ACTION_PLAN_REPORT_PAYMENT_STATUS_SUCCESS";
export const TOGGLE_COACH_ACTION_PLAN_REPORT_PAYMENT_STATUS_FAILED =
  "TOGGLE_COACH_ACTION_PLAN_REPORT_PAYMENT_STATUS_FAILED";


//
export const REQUEST_TOGGLE_FULL_REPORT_PAYMENT_STATUS =
  "TOGGLE_FULL_REPORT_PAYMENT_STATUS";
export const TOGGLE_FULL_REPORT_PAYMENT_STATUS_SUCCESS =
  "TOGGLE_FULL_REPORT_PAYMENT_STATUS_SUCCESS";
export const TOGGLE_FULL_REPORT_PAYMENT_STATUS_FAILED =
  "TOGGLE_FULL_REPORT_PAYMENT_STATUS_FAILED";
//
export const REQUEST_TOGGLE_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS =
  "TOGGLE_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS";
export const TOGGLE_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS_SUCCESS =
  "TOGGLE_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS_SUCCESS";
export const TOGGLE_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS_FAILED =
  "TOGGLE_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS_FAILED";
//
export const REQUEST_TOGGLE_ACTION_PLAN_REPORT_PAYMENT_STATUS =
  "TOGGLE_ACTION_PLAN_REPORT_PAYMENT_STATUS";
export const TOGGLE_ACTION_PLAN_REPORT_PAYMENT_STATUS_SUCCESS =
  "TOGGLE_ACTION_PLAN_REPORT_PAYMENT_STATUS_SUCCESS";
export const TOGGLE_ACTION_PLAN_REPORT_PAYMENT_STATUS_FAILED =
  "TOGGLE_ACTION_PLAN_REPORT_PAYMENT_STATUS_FAILED";
//
export const REQUEST_EXPORT_FULL_REPORT = "REQUEST_EXPORT_FULL_REPORT";
export const EXPORT_FULL_REPORT_SUCCESS = "EXPORT_FULL_REPORT_SUCCESS";
export const EXPORT_FULL_REPORT_FAIL = "EXPORT_FULL_REPORT_FAIL";
//
export const REQUEST_EXPORT_ACTION_PLAN_REPORT =
  "REQUEST_EXPORT_ACTION_PLAN_REPORT";
export const EXPORT_ACTION_PLAN_REPORT_SUCCESS =
  "EXPORT_ACTION_PLAN_REPORT_SUCCESS";
export const EXPORT_ACTION_PLAN_REPORT_FAIL = "EXPORT_ACTION_PLAN_REPORT_FAIL";
//
export const REQUEST_EXPORT_EXECUTIVE_SUMMARY_REPORT =
  "REQUEST_EXPORT_EXECUTIVE_SUMMARY_REPORT";
export const EXPORT_EXECUTIVE_SUMMARY_REPORT_SUCCESS =
  "EXPORT_EXECUTIVE_SUMMARY_REPORT_SUCCESS";
export const EXPORT_EXECUTIVE_SUMMARY_REPORT_FAIL =
  "EXPORT_EXECUTIVE_SUMMARY_REPORT_FAIL";
//
export const REQUEST_REGENERATE_FULL_REPORT = "REQUEST_REGENERATE_FULL_REPORT";
export const REGENERATE_FULL_REPORT_SUCCESS = "REGENERATE_FULL_REPORT_SUCCESS";
export const REGENERATE_FULL_REPORT_FAIL = "REGENERATE_FULL_REPORT_FAIL";
//
export const REQUEST_REGENERATE_ACTION_PLAN_REPORT =
  "REQUEST_REGENERATE_ACTION_PLAN_REPORT";
export const REGENERATE_ACTION_PLAN_REPORT_SUCCESS =
  "REGENERATE_ACTION_PLAN_REPORT_SUCCESS";
export const REGENERATE_ACTION_PLAN_REPORT_FAIL =
  "REGENERATE_ACTION_PLAN_REPORT_FAIL";
//
export const REQUEST_REGENERATE_EXECUTIVE_SUMMARY_REPORT =
  "REQUEST_REGENERATE_EXECUTIVE_SUMMARY_REPORT";
export const REGENERATE_EXECUTIVE_SUMMARY_REPORT_SUCCESS =
  "REGENERATE_EXECUTIVE_SUMMARY_REPORT_SUCCESS";
export const REGENERATE_EXECUTIVE_SUMMARY_REPORT_FAIL =
  "REGENERATE_EXECUTIVE_SUMMARY_REPORT_FAIL";
//
export const REQUEST_UPDATE_COACH_RISK_SCORE = "REQUEST_UPDATE_COACH_RISK_SCORE";
export const UPDATE_COACH_RISK_SCORE_SUCCESS = "UPDATE_COACH_RISK_SCORE_SUCCESS";
export const UPDATE_COACH_RISK_SCORE_FAIL = "UPDATE_COACH_RISK_SCORE_FAIL";


export const INDIVIDUAL_COACH_FORM_SUBMITTED = "INDIVIDUAL_COACH_FORM_SUBMITTED";
export const INDIVIDUAL_COACH_FORM_NOT_SUBMITTED = "INDIVIDUAL_COACH_FORM_NOT_SUBMITTED";


//
export const REQUSET_COACH_REVIEW_SUBMITTED =
  "REQUSET_COACH_REVIEW_SUBMITTED";
export const COACH_REVIEW_SUBMITTED_SUCCESS =
  "COACH_REVIEW_SUBMITTED_SUCCESS";
export const COACH_REVIEW_SUBMITTED_FAILED =
  "COACH_REVIEW_SUBMITTED_FAILED";


//
export const REQUEST_ANALYZE_COACH_DETAILS = "REQUEST_ANALYZE_COACH_DETAILS";
export const ANALYZE_COACH_DETAILS_SUCCESS = "ANALYZE_COACH_DETAILS_SUCCESS";
export const ANALYZE_COACH_DETAILS_FAILED = "ANALYZE_COACH_DETAILS_FAILED";
//
export const REQUEST_ANALYZE_COACH_RESULTS = "REQUEST_ANALYZE_COACH_RESULTS";
export const ANALYZE_COACH_RESULTS_SUCCESS = "ANALYZE_COACH_RESULTS_SUCCESS";
export const ANALYZE_COACH_RESULTS_FAILED = "ANALYZE_COACH_RESULTS_FAILED";
//
export const REQUEST_COACH_SENTIMENT_RESULTS = "REQUEST_COACH_SENTIMENT_RESULTS";
export const COACH_SENTIMENT_RESULTS_SUCCESS = "COACH_SENTIMENT_RESULTS_SUCCESS";
export const COACH_SENTIMENT_RESULTS_FAILED = "COACH_SENTIMENT_RESULTS_FAILED";


//coach-brain
export const REQUEST_GET_TWITTER_INFO = "REQUEST GET TWITTER INFO";
export const GET_TWITTER_INFO_SUCCESS = "GET TWITTER INFO SUCCESS";
export const GET_TWITTER_INFO_FAILED = "GET TWITTER INFO FAILED";

export const REQUEST_GET_BIG5_INFO = "REQUEST GET BIG5 INFO";
export const GET_BIG5_INFO_SUCCESS = "GET BIG5 INFO SUCCESS";
export const GET_BIG5_INFO_FAILED = "GET BIG5 INFO FAILED";

export const REQUEST_GET_PSYCH_INFO = "REQUEST GET PSYCH INFO";
export const GET_PSYCH_INFO_SUCCESS = "GET PSYCH INFO SUCCESS";
export const GET_PSYCH_INFO_FAILED = "GET PSYCH INFO FAILED";

export const REQUEST_GET_INSTAGRAM_INFO = "REQUEST GET INSTAGRAM INFO";
export const GET_INSTAGRAM_INFO_SUCCESS = "GET INSTAGRAM INFO SUCCESS";
export const GET_INSTAGRAM_INFO_FAILED = "GET INSTAGRAM INFO FAILED";

export const REQUEST_GET_SENTIMENT_INFO = "REQUEST GET SENTIMENT INFO";
export const GET_SENTIMENT_INFO_SUCCESS = "GET SENTIMENT INFO SUCCESS";
export const GET_SENTIMENT_INFO_FAILED = "GET SENTIMENT INFO FAILED";

export const GET_VIDEO_TRANSCRIPT_REQUEST = "GET VIDEO TRANSCRIPT REQUEST";
export const GET_VIDEO_TRANSCRIPT_SUCCESS = "GET VIDEO TRANSCRIPT SUCCESS";
export const GET_VIDEO_TRANSCRIPT_FAILED = "GET VIDEO TRANSCRIPT FAILED";

export const GET_APPROVE_PLAYER = "GET APPROVE PLAYER";
export const GET_APPROVE_PLAYER_SUCCESS = "GET APPROVE PLAYER SUCCESS";
export const GET_APPROVE_PLAYER_FAILED = "GET APPROVE PLAYER FAILED";

export const GET_PLUS_REPORT = "GET PLUS REPORT";
export const GET_PLUS_REPORT_SUCCESS = "GET PLUS REPORT SUCCESS";
export const GET_PLUS_REPORT_FAILED = "GET PLUS REPORT FAILED";

export const GET_REMOVE_FEED = "GET REMOVE FEED";
export const GET_REMOVE_FEED_SUCCESS = "GET REMOVE FEED SUCCESS";
export const GET_REMOVE_FEED_FAILED = "GET REMOVE FEED FAILED";
export const GET_REQUEST_PLAN_REPORTS = "GET_REQUEST_PLAN_REPORTS";
export const GET_REQUEST_PLAN_REPORTS_SUCCESS = "GET_REQUEST_PLAN_REPORTS_SUCCESS";
export const GET_REQUEST_PLAN_REPORTS_FAILED = "GET_REQUEST_PLAN_REPORTS_FAILED";

export const SAVE_ALLREADY_REQUESTED_PLUS_REPORTS = "SAVE_ALLREADY_REQUESTED_PLUS_REPORTS";
export const GET_ALLREADY_REQUESTED_PLUS_REPORTS = "GET_ALLREADY_REQUESTED_PLUS_REPORTS";

export const REQUEST_DELETE_EXECUTIVE_SUMMERY_COMMENT =
  "REQUEST_DELETE_EXECUTIVE_SUMMERY_COMMENT";
export const DELETE_EXECUTIVE_SUMMERY_COMMENT_SUCCESS =
  "DELETE_EXECUTIVE_SUMMERY_COMMENT_SUCCESS";
export const DELETE_EXECUTIVE_SUMMERY_COMMENT_FAILED =
  "DELETE_EXECUTIVE_SUMMERY_COMMENT_FAILED";

export const GET_USER_ID_WITH_USER_HANDLE = "GET_USER_ID_WITH_USER_HANDLE"
export const GET_USER_ID_WITH_USER_HANDLE_SUCCESS = "GET_USER_ID_WITH_USER_HANDLE_SUCCESS"
export const GET_USER_ID_WITH_USER_HANDLE_FAILED = "GET_USER_ID_WITH_USER_HANDLE_FAILED"
