export const REQUEST_GET_PENDING_CONSENT_FORMS =
  "REQUEST_GET_PENDING_CONSENT_FORMS";
export const GET_PENDING_CONSENT_FORMS_SUCCESS =
  "GET_PENDING_CONSENT_FORMS_SUCCESS";
export const GET_PENDING_CONSENT_FORMS_FAILED =
  "GET_PENDING_CONSENT_FORMS_FAILED";
//
export const REQUEST_UPLOAD_CONSENT_FORMS = "REQUEST_UPLOAD_CONSENT_FORMS";
export const UPLOAD_CONSENT_FORMS_SUCCESS = "UPLOAD_CONSENT_FORMS_SUCCESS";
export const UPLOAD_CONSENT_FORMS_FAILED = "UPLOAD_CONSENT_FORMS_FAILED";
//
export const REQUEST_GET_CONSENT_FORM = "REQUEST_GET_CONSENT_FORM";
export const GET_CONSENT_FORM_SUCCESS = "GET_CONSENT_FORM_SUCCESS";
export const GET_CONSENT_FORM_FAILED = "GET_CONSENT_FORM_FAILED";
//
export const REQUEST_LINK_CONSENT_FORM = "REQUEST_LINK_CONSENT_FORM";
export const LINK_CONSENT_FORM_SUCCESS = "LINK_CONSENT_FORM_SUCCESS";
export const LINK_CONSENT_FORM_FAIL = "LINK_CONSENT_FORM_FAIL";
//
export const REQUEST_UNLINK_CONSENT_FORM = "REQUEST_UNLINK_CONSENT_FORM";
export const UNLINK_CONSENT_FORM_SUCCESS = "UNLINK_CONSENT_FORM_SUCCESS";
export const UNLINK_CONSENT_FORM_FAIL = "UNLINK_CONSENT_FORM_FAIL";

//
export const SELECT_CONSENT_PLAYER = "SELECT_CONSENT_PLAYER";
export const SELECT_CONSENT_FORM = "SELECT_CONSENT_FORM";
export const SEARCH_CONSENT_PLAYER = "SEARCH_CONSENT_PLAYER";
export const RESET_CONSENT_SEARCH = "RESET_CONSENT_SEARCH";

export const REQUEST_DELETE_CONSENT_FORM = "REQUEST_DELETE_CONSENT_FORM";
export const DELETE_CONSENT_FORM_SUCCESS = "DELETE_CONSENT_FORM_SUCCESS";
export const DELETE_CONSENT_FORM_FAILED = "DELETE_CONSENT_FORM_FAILED";

export const REQUEST_GET_LINKED_CONSENT_FORMS =
  "REQUEST_GET_LINKED_CONSENT_FORMS";
export const GET_LINKED_CONSENT_FORMS_SUCCESS =
  "GET_LINKED_CONSENT_FORMS_SUCCESS";
export const GET_LINKED_CONSENT_FORMS_FAILED =
  "GET_LINKED_CONSENT_FORMS_FAILED";
