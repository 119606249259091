import { showSnackbar } from "./state/snackbarActions";

export const showErrorMessage = (message) => {
  return showSnackbar(message, { severity: "error", autoHideDuration: 5000 });
};

export const showSuccessMessage = (message) => {
  return showSnackbar(message, { autoHideDuration: 3000 });
};

export const showInfoMessage = (message) => {
  return showSnackbar(message, { severity: "info", autoHideDuration: 3000 });
};

export const showWarnMessage = (message) => {
  return showSnackbar(message, { severity: "warning", autoHideDuration: 3000 });
};
