import React from "react";

const Step = (props) => {
  let { children, formik, render = false, ...rest } = props;

  if (render) {
    return render({ ...formik, ...rest });
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default Step;
