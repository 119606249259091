import * as types from "./dialogTypes";
import { v4 as uuid } from "uuid";

export const openDialog = (component, dialogProps = {}) => {
  const id = uuid();
  return {
    type: types.OPEN_DIALOG,
    payload: {
      id,
      component,
      props: dialogProps,
    },
  };
};

// pops the dialog from stack
export const closeDialog = (id) => {
  return {
    type: types.CLOSE_DIALOG,
    payload: {
      id,
    },
  };
};

const dialogActions = {
  openDialog,
  closeDialog,
};

export default dialogActions;
