import * as types from "./snackbarTypes";

export const showSnackbar = (message, props) => {
  return {
    type: types.SHOW_SNACKBAR,
    payload: {
      message,
      props,
    },
  };
};

export const closeSnackbar = () => {
  return {
    type: types.CLOSE_SNACKBAR,
  };
};

const snackbarActions = {
  showSnackbar,
  closeSnackbar,
};

export default snackbarActions;
