
import React, { useState } from "react";
import ExpansionArticle from "../ExpansionArticle";
import ChartAndDetails from "../ChartAndDetails";
import { chartColors } from "modules/players/constants";
import { connect } from "react-redux";
import { Box, Tabs, Tab, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import '../../../../index.css';
import { RadialGauge } from "modules/shared/Charts";
import { round } from "utils";

let useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: 'Prometo',
        fontSize: '14px',
        color: '#fcb643',
    },
    brain: {
        fontFamily: 'Prometo',
        fontSize: '15px',
        fontWeight: 'bold',
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0rem 2rem',
        width: 'auto',
        height: '20vh',
        color: '#326fc7',
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


let roundTo1PrecisionPoint = (value) => {
    return round(value, 0);
};

let options = {
    plotOptions: {
        radialBar: {
            hollow: {
                margin: 0,
                size: "40%",
                background: "#293450",
            },
            track: {
                dropShadow: {
                    enabled: true,
                    top: 2,
                    left: 0,
                    blur: 4,
                    opacity: 0.15,
                },
            },
            dataLabels: {
                name: {
                    show: false,
                    offsetY: -10,
                    color: "#fff",
                    fontSize: "13px",
                },
                value: {
                    color: "#fff",
                    fontSize: "14px",
                    offsetY: 6,
                    show: true,
                },
            },
        },
    },
    stroke: {
        lineCap: "round",
    },
};

const BeyondBig5 = (props) => {
    let classes = useStyles();
    let { coachId, customTraits, team, accountType, big5, basicInfo } = props;

    const [selectedPage, setSelectedPage] = useState(0)

    const handleChange = (event, newValue) => {
        setSelectedPage(newValue);
    };

    let handleColors = (score) => {
        if (0 <= score && score < 25) {
            return chartColors.green;
        } else if (25 <= score && score < 50) {
            return chartColors.yellow;
        } else if (50 <= score && score < 75) {
            return chartColors.orange;
        } else if (75 <= score && score < 100) {
            return chartColors.red;
        }
    };

    return (
        <React.Fragment>
            {basicInfo && basicInfo.brain === 'Receptiviti' ?
                <>
                    <Box
                        sx={{ flexGrow: 1, display: 'flex', margin: '0px 5rem' }}
                    >
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={selectedPage}
                            onChange={handleChange}
                            aria-label="Vertical tabs example"
                            sx={{ borderRight: 1, borderColor: 'divider' }}
                        >
                            <Tab className={selectedPage === 0 ? classes.root : ''} label="Natural Leadership" {...a11yProps(0)} />
                            <Tab className={selectedPage === 1 ? classes.root : ''} label="Person Centered" {...a11yProps(1)} />
                            <Tab className={selectedPage === 2 ? classes.root : ''} label="Processing Power" {...a11yProps(2)} />
                            <Tab className={selectedPage === 3 ? classes.root : ''} label="Reward Seeking" {...a11yProps(3)} />
                            <Tab className={selectedPage === 4 ? classes.root : ''} label="Individual Willpower" {...a11yProps(4)} />
                            <Tab className={selectedPage === 5 ? classes.root : ''} label="Communal" {...a11yProps(5)} />
                            <Tab className={selectedPage === 6 ? classes.root : ''} label="Authenticity" {...a11yProps(6)} />
                            <Tab className={selectedPage === 7 ? classes.root : ''} label="Clout" {...a11yProps(7)} />
                            <Tab className={selectedPage === 8 ? classes.root : ''} label="Challenge" {...a11yProps(8)} />
                            <Tab className={selectedPage === 9 ? classes.root : ''} label="Stability" {...a11yProps(9)} />
                        </Tabs>
                        <TabPanel value={selectedPage} index={0} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
                            <div>
                                <ExpansionArticle
                                    title="Natural Leadership"
                                    details="This measures the degree to which one is assertive, fast-paced or sociable. High scorers exhibit a charasmatic leader style."
                                >
                                    <ChartAndDetails
                                        // diagnosis={big5["natural_leadership"]["text"]}
                                        // details="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
                                        score={big5["natural_leadership"]["score"]}
                                        options={options}
                                        handleColors={handleColors}
                                        tabName="BeyondBig5"
                                    />
                                    {/* <div style={{ marginBottom: 48, width: "100%", display: "flex", justifyContent: "space-evenly", }}>
                                {Object.values(big5["authenticity"].sub).map((subTrait, index) => {
                                    return (
                                        <div key={index} style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: 150, }}>
                                            <RadialGauge score={roundTo1PrecisionPoint(subTrait["score"])} />
                                            {
                                                (accountType === "SUPER_ADMIN" || accountType === "BCA_PSYCHOLOGIST" || accountType === "BCA_CHIEF_PSYCHOLOGIST") ?
                                                    <p style={{ textAlign: "center", marginTop: 0, marginBottom: '0' }}>
                                                        {roundTo1PrecisionPoint(subTrait["score"])}%
                                                    </p>
                                                    :
                                                    ""
                                            }
                                            <p style={{ textAlign: "center", marginTop: 0 }}>
                                                {`+ ${subTrait["left_label"]}`}
                                            </p>
                                        </div>
                                    );
                                })}
                            </div> */}
                                </ExpansionArticle>
                            </div>
                        </TabPanel>
                        <TabPanel value={selectedPage} index={1} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
                            <div>
                                <ExpansionArticle
                                    title="Person Centered"
                                    details="This measures how  warm, supportive, and inquisitive one is about others’ lives. It highlights being agreeable, joking, polite, and interested in conversations."
                                >
                                    <ChartAndDetails
                                        // diagnosis={big5["person-centered"]["text"]}
                                        // details="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
                                        score={big5["person-centered"]["score"]}
                                        options={options}
                                        handleColors={handleColors}
                                        tabName="BeyondBig5"
                                    />
                                </ExpansionArticle>
                            </div>
                        </TabPanel>
                        <TabPanel value={selectedPage} index={2} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
                            <div>
                                <ExpansionArticle
                                    title="Processing Power"
                                    details="This measures one's ability to pay attention, and/or to process the environment and world around them."
                                >
                                    <ChartAndDetails
                                        score={big5["processing_power"]["score"]}
                                        options={options}
                                        handleColors={handleColors}
                                        tabName="BeyondBig5"
                                    />
                                </ExpansionArticle>
                            </div>
                        </TabPanel>

                        <TabPanel value={selectedPage} index={3} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
                            <div>
                                <ExpansionArticle
                                    title="Reward Seeking"
                                    details="This measures the degree to which a person is driven by an internal need for reward."
                                >
                                    <ChartAndDetails
                                        score={big5["reward-seeking"]["score"]}
                                        options={options}
                                        handleColors={handleColors}
                                        tabName="BeyondBig5"
                                    />
                                </ExpansionArticle>
                            </div>
                        </TabPanel>
                        <TabPanel value={selectedPage} index={4} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
                            <div>
                                <ExpansionArticle
                                    title="Individual Willpower"
                                    details="This measures how much one is willing to exert willpower to pursue personal goals. Indicates doing things as an individual for personal motivations or desires."
                                >
                                    <ChartAndDetails
                                        // diagnosis={big5["individual_willpower"]["text"]}
                                        // details="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
                                        score={big5["individual_willpower"]["score"]}
                                        options={options}
                                        handleColors={handleColors}
                                        tabName="BeyondBig5"
                                    />
                                </ExpansionArticle>
                            </div>
                        </TabPanel>
                        <TabPanel value={selectedPage} index={5} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
                            <div>
                                <ExpansionArticle
                                    title="Communal"
                                    details="This measures if a person is cooperating and connecting with others to improve social relationships. Indicates they are likely doing things with other people to help meet the group's goals."
                                >
                                    <ChartAndDetails
                                        // diagnosis={big5["communal"]["text"]}
                                        // details="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
                                        score={big5["communal"]["score"]}
                                        options={options}
                                        handleColors={handleColors}
                                        tabName="BeyondBig5"
                                    />
                                </ExpansionArticle>
                            </div>
                        </TabPanel>
                        <TabPanel value={selectedPage} index={6} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
                            <div>
                                <ExpansionArticle
                                    title="Authenticity"
                                    details="This measures the degree to which communication style is personal, honest and unguarded or more closed, guarded and distanced."
                                >
                                    <ChartAndDetails
                                        // diagnosis={big5["authenticity"]["text"]}
                                        // details="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
                                        score={big5["authenticity"]["score"]}
                                        options={options}
                                        handleColors={handleColors}
                                        tabName="BeyondBig5"
                                    />
                                </ExpansionArticle>
                            </div>
                        </TabPanel>
                        <TabPanel value={selectedPage} index={7} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
                            <div>
                                <ExpansionArticle
                                    title="Clout"
                                    details="This measures the degree to which communication reflects certainty and confidence."
                                >
                                    <ChartAndDetails
                                        // diagnosis={big5["clout"]["text"]}
                                        // details="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
                                        score={big5["clout"]["score"]}
                                        options={options}
                                        handleColors={handleColors}
                                        tabName="BeyondBig5"
                                    />
                                </ExpansionArticle>
                            </div>
                        </TabPanel>

                        <TabPanel value={selectedPage} index={8} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
                            <div>
                                <ExpansionArticle
                                    title="Challenge"
                                    details="This measures ones ability to overcome obstacles and achieve difficult things."
                                >
                                    <ChartAndDetails
                                        // diagnosis={big5["challenge"]["text"]}
                                        // details="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
                                        score={big5["challenge"]["score"]}
                                        options={options}
                                        handleColors={handleColors}
                                        tabName="BeyondBig5"
                                    />
                                </ExpansionArticle>
                            </div>
                        </TabPanel>
                        <TabPanel value={selectedPage} index={9} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
                            <div>
                                <ExpansionArticle
                                    title="Stability"
                                    details="This measures one's preference for prioritizing consistency in their environment and avoiding uncertainty or disruptive change."
                                >
                                    <ChartAndDetails
                                        score={big5["stability"]["score"]}
                                        options={options}
                                        handleColors={handleColors}
                                        tabName="BeyondBig5"
                                    />
                                </ExpansionArticle>
                            </div>
                        </TabPanel>

                    </Box>
                </>
                :
                <div style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '5rem', width: '85%' }}>
                    <span className={classes.brain}>This report is outdated. To see enhanced features, please contact your Blu Chip representative.</span></div>
            }
        </React.Fragment>
    );
};

let mapStateToProps = (state, ownProps) => {
    let { coachId } = ownProps;
    return {
        customTraits: state.coach.customTraits[coachId],
        team: state.account.team,
        accountType: state.account.accountType,
        basicInfo: state.coach.basic[coachId],
        big5: state.coach.deepProfile[coachId],
    };
};

// higher order component for checking payment
export default connect(mapStateToProps)(BeyondBig5);
