import React, { useContext, useEffect } from 'react'
import coneLayersLogo from "../../../assets/ConeLayers.svg";
import { Button } from '@material-ui/core'
import { ActionContext } from "../../../contexts";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

let useStyles = makeStyles((theme) => ({
    btnType: {
        border: '1px solid',
        padding: "1rem 5rem 1rem 5rem",
        borderRadius: '20px',
        color: "white",
    },
    CenterDataAlign: {
        display: 'block',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        textAlign: 'center',
    },
    btnPrimary: {
        color: '#fff',
        border: '1px solid',
        height: '30px',
        padding: '10px',
        margin: '0px 10px 10px 0px',
        borderRadius: '20px',
        background: '#007fdb',
        '&:hover': {
            background: '#007fdb',
            color: '#fff'
        },
    },
    btnSecondary: {
        color: theme.palette.secondary.main,
        border: '1px solid',
        height: '30px',
        padding: '10px',
        marginRight: '10px',
        borderRadius: '20px',
        background: theme.palette.secondary.main,
        '&:hover': {
            background: theme.palette.secondary.main,
            color: '#fff'
        }
    },



}));

function FinalPage(props) {
    let history = useHistory();
    let classes = useStyles();
    const { selecteCreateType, accountType } = props;
    let { playerActions } = useContext(ActionContext);

    const handleRedirectDashboard = () => {
        history.push("/");
        playerActions.individualAthleteFormNotSubmited()
    }

    const handleRedirectTeam = () => {
        history.push("/team-overview");
        playerActions.individualAthleteFormNotSubmited()
    }

    useEffect(() => {
        return () => {
            playerActions.individualAthleteFormNotSubmited()
        }
    }, [])

    return (
        <React.Fragment>
            <div className={classes.CenterDataAlign}>
                <div>
                    <img
                        src={coneLayersLogo}
                        alt="logo"
                    />
                </div>
                <div>
                    <h3 style={{color: '#007fdb'}}>Athlete profiles successfully submitted!!</h3>
                </div>
                <div>
                    {
                        selecteCreateType === "many" ?
                            <Button className={classes.btnPrimary}>Download</Button>
                            :
                            ""
                    }
                    {
                        (accountType === "COL_POSITION_COACH" || accountType === "COL_PSYCHOLOGIST" || accountType === "PRO_POSITION_COACH" ||
                            accountType === "PRO_SCOUTS" || accountType === "PRO_SECURITY" || accountType === "PRO_PSYCHOLOGIST") ?
                            <Button className={classes.btnPrimary} onClick={handleRedirectTeam}>Done</Button>
                            :
                            <Button className={classes.btnPrimary} onClick={handleRedirectDashboard}>Go to Dashboard</Button>
                    }

                </div>
            </div>
        </React.Fragment>
    )
}

let mapStateToProps = (state) => {
    return {
        isAthleteFormSubmit: state.ui.player.isAthleteFormSubmit,
        accountType: state.account.accountType,
    };
};
export default connect(mapStateToProps)(FinalPage);