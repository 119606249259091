import { combineReducers } from "redux";
import * as types from "./coachType";
import {
  LINK_CONSENT_FORM_SUCCESS,
  UNLINK_CONSENT_FORM_SUCCESS,
} from "../../consent";


const coachesById = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.SET_COACHES_SUCCESS: {
      return {
        ...payload,
      };
    }
    default:
      return state;
  }
};
const allCoachIds = (state = [], action) => {
  const { payload } = action;
  switch (action.type) {
    case types.SET_COACHES_SUCCESS: {
      return [...Object.keys(payload)];
    }
    default:
      return state;
  }
};
const getAllCoachData = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.SET_COACHES_SUCCESS: {
      return payload
    }

    default:
      return state;
  }
};



const allCoachList = combineReducers({
  allData: getAllCoachData,
});


const filterCoaches = (state = [], action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_FILTER_COACH_SUCCESS: {
      return payload
    }

    default:
      return state
  }
}

const updateCoachFilter = (state = {
  coachType: "All",
  conferenceType: "All",
  conference: "",
  sport: "",
  unit: "",
}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.UPDATE_COACH_FILTER_VALUE: {

      return {
        ...state,
        [payload.key]: payload.value,
      };
    }

    case types.RESET_COACH_FILTER_VALUE: {

      return {
        "conference": "",
        "sport": "",
        "unit": "",
        "coachType": "All",
        "conferenceType": "All"
      }
    }


    default:
      return state;
  }
}

const isFilterCoaches = (state = false, action) => {
  const { payload } = action
  switch (action.type) {
    case types.IS_COACH_FILTERED_DATA: {

      return payload
    }

    default:
      return state;
  }
}
const coaches = combineReducers({
  byId: coachesById,
  allIds: allCoachIds,
});

const basic = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_COACH_SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          ...payload.basic,
        },
      };
    }

    // case LINK_CONSENT_FORM_SUCCESS: {
    //   return {
    //     ...state,
    //     [payload.coachId]: {
    //       ...state[payload.coachId],
    //       consent: "Received",
    //     },
    //   };
    // }

    // case UNLINK_CONSENT_FORM_SUCCESS: {
    //   return {
    //     ...state,
    //     [payload.coachId]: {
    //       ...state[payload.coachId],
    //       consent: "Revoked",
    //     },
    //   };
    // }

    case types.COACH_REVIEW_INCOMPLETE_SUCCESS: {
      return {
        ...state,
        [payload.coachId]: {
          ...state[payload.coachId],
          status: payload.status,
        },
      };
    }

    case types.COACH_REVIEW_SUBMITTED_SUCCESS: {
      return {
        ...state,
        [payload.coachId]: {
          ...state[payload.coachId],
          status: payload.status,
        },
      };
    }

    case types.APPROVE_COACH_PROFILE_SUCCESS:
    case types.REJECT_COACH_PROFILE_SUCCESS:
    case types.REVIEW_COMPLETE_COACH_SUCCESS: {
      return {
        ...state,
        [payload.coachId]: {
          ...state[payload.coachId],
          status: payload.status,
        },
      };
    }

    case types.TOGGLE_COACH_PAID_STATUS_SUCCESS: {
      return {
        ...state,
        [payload.coachId]: {
          ...state[payload.coachId],
          isPaid: payload.isPaid,
        },
      };
    }

    default:
      return state;
  }
};

// Stats

const stats = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_COACH_SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          negativePercentage: payload.stats["negative_percentage"],
          neutralPercentage: payload.stats["neutral_percentage"],
          positivePercentage: payload.stats["positive_percentage"],
          twitter: {
            negativePercentage: payload.stats["tweets"]["negative_percentage"],
            neutralPercentage: payload.stats["tweets"]["neutral_percentage"],
            positivePercentage: payload.stats["tweets"]["positive_percentage"],
            total: payload.stats["tweets"]["total"],
          },
          instagram: {
            negativePercentage:
              payload.stats["instagram"]["negative_percentage"],
            neutralPercentage: payload.stats["instagram"]["neutral_percentage"],
            positivePercentage:
              payload.stats["instagram"]["positive_percentage"],
            total: payload.stats["instagram"]["total"],
          },
          youtube: {
            negativePercentage: "nodata",
            neutralPercentage: "nodata",
            positivePercentage: "nodata",
            total: payload.stats["videos"]["total"],
          },
        },
      };
    }
    default:
      return state;
  }
};

// Contact
const contact = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_COACH_SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          ...payload.contact,
        },
      };
    }

    default:
      return state;
  }
};

// Deep profile
const deepProfile = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_COACH_SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          ...payload.issues,
        },
      };
    }
    default:
      return state;
  }
};

//  Traits
const traits = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_COACH_SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          ...payload.traits,
        },
      };
    }
    default:
      return state;
  }
};
//  Risk
const behaviourRisk = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_COACH_SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          ...payload.behaviourRisk,
        },
      };
    }
    default:
      return state;
  }
};
const customTraits = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_COACH_SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          ...payload.customTraits,
        },
      };
    }

    default:
      return state;
  }
};

// const actionPlan = (state = {}, action) => {
//   const { payload } = action;
//   switch (action.type) {
//     case types.GET_COACH_SUCCESS: {
//       return {
//         ...state,
//         ...payload.actionPlan,
//       };
//     }

//     case types.ADD_COACH_ACTION_PLAN_COMMENT_SUCCESS: {
//       const { coachId, categoryId } = payload;
//       return {
//         ...state,
//         [`${coachId}.${categoryId}`]: payload.content,
//       };
//     }

//     default:
//       return state;
//   }
// };

// Executive summery
const executiveSummery = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_COACH_SUCCESS: {
      return {
        ...state,
        ...payload.executiveSummery,
      };
    }

    case types.ADD_COACH_EXECUTIVE_SUMMERY_COMMENT_SUCCESS: {
      const { coachId, categoryId } = payload;
      return {
        ...state,
        [`${coachId}.${categoryId}`]: categoryId === "Custom" ? [...payload.prevData, payload.data] : payload.content,
      };
    }

    case types.DELETE_EXECUTIVE_SUMMERY_COMMENT_SUCCESS: {
      const { coachId, categoryId } = payload;
      return {
        ...state,
        [`${coachId}.${categoryId}`]: payload.prevData.filter((v) => v._id !== payload._id),
      };
    }

    default:
      return state;
  }
};

// Risk
const riskCategories = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_COACH_SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          ...payload.risk,
        },
      };
    }

    case types.UPDATE_COACH_RISK_SCORE_SUCCESS: {
      return {
        ...state,
        [payload.coachId]: {
          ...state[payload.coachId],
          [payload.categoryId]: {
            ...payload.risk,
            comments: state[payload.coachId][payload.categoryId]["comments"],
          },
        },
      };
    }

    default:
      return state;
  }
};

const riskComments = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_COACH_SUCCESS: {
      return {
        ...state,
        ...payload.riskComments,
      };
    }

    case types.ADD_COACH_RISK_COMMENT_SUCCESS: {
      return {
        [payload.commentId]: {
          id: payload.commentId,
          content: payload.comment,
        },
        ...state,
      };
    }

    case types.DELETE_COACH_RISK_COMMENT_SUCCESS: {
      delete state[payload.commentId];
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

const coachCategoryComments = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_COACH_SUCCESS: {
      let comments = Object.keys(payload.risk).reduce((obj, riskId) => {
        obj[`${payload.id}.${riskId}`] = payload.risk[riskId]["comments"];
        return obj;
      }, {});

      return {
        ...state,
        ...comments,
      };
    }

    case types.ADD_COACH_RISK_COMMENT_SUCCESS: {
      let updatedComments = [
        payload.commentId,
        ...state[`${payload.coachId}.${payload.categoryId}`],
      ];

      return {
        ...state,
        [`${payload.coachId}.${payload.categoryId}`]: updatedComments,
      };
    }

    case types.DELETE_COACH_RISK_COMMENT_SUCCESS: {
      let updatedComments = state[
        `${payload.coachId}.${payload.categoryId}`
      ].filter((commentId) => commentId !== payload.commentId);

      return {
        ...state,
        [`${payload.coachId}.${payload.categoryId}`]: updatedComments,
      };
    }

    default:
      return state;
  }
};

const risk = combineReducers({
  categories: riskCategories,
  categoryComments: coachCategoryComments,
  comments: riskComments,
});

// Possible duplicate info
const possibleDuplicateInfo = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_COACH_SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          ...payload.possibleDuplicateInfo,
        },
      };
    }

    // not needed? ask eshan

    // case types.APPROVE_COACH_PROFILE_SUCCESS: {
    //   return {
    //     ...state,
    //     [payload.coachId]: {
    //       ...state[payload.coachId],
    //       isPossibleDuplicate: false,
    //     },
    //   };
    // }

    default:
      return state;
  }
};

// Notes
const notes = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_COACH_SUCCESS: {
      return {
        ...state,
        [payload.id]: {
          ...payload.notes,
        },
      };
    }

    case types.UPDATE_COACH_NOTES_SUCCESS: {
      return {
        ...state,
        [payload.coachId]: {
          ...payload.notes,
        },
      };
    }

    default:
      return state;
  }
};

const links = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_COACH_SUCCESS: {
      return {
        ...state,
        [payload.coachId]: payload.links,
      };
    }

    case types.ADD_COACH_LINK_SUCCESS: {
      return {
        ...state,
        [payload.coachId]: payload.links,
      };
    }

    case types.DELETE_COACH_LINK_SUCCESS: {
      return {
        ...state,
        [payload.coachId]: state[payload.coachId].filter(
          (link) => link.link_id !== payload.linkId
        ),
      };
    }

    default:
      return state;
  }
};

// Message
const message = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_COACH_SUCCESS: {
      return {
        [payload.id]: {
          title: payload.message["title"],
          content: payload.message["content"],
          show: payload.message["show"],
          severity: payload.message["severity"],
          buttonLabel: payload.message["button_label"],
        },
      };
    }
    default:
      return state;
  }
};

const reports = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_COACH_SUCCESS: {
      return {
        [payload.id]: payload.reports,
      };
    }

    default:
      return state;
  }
};

const reportSettings = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_COACH_SUCCESS: {
      return {
        [payload.id]: payload.reportSettings,
      };
    }

    case types.TOGGLE_COACH_FULL_REPORT_PAYMENT_STATUS_SUCCESS:
    case types.TOGGLE_COACH_ACTION_PLAN_REPORT_PAYMENT_STATUS_SUCCESS:
    case types.TOGGLE_COACH_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS_SUCCESS: {
      const currentSettings = state[payload.coachId];
      return {
        [payload.coachId]: {
          ...currentSettings,
          [payload.reportType]: payload.allowed,
        },
      };
    }

    default:
      return state;
  }
};

// Feed

const twitterFeedById = (state = {}, action) => {
  const { payload } = action;

  if (
    action.type === types.GET_COACH_FEED_SUCCESS &&
    payload.source === "twitter"
  ) {
    return {
      ...state,
      [payload.id]: {
        negatives: payload.negatives,
        positives: payload.positives,
        neutrals: payload.neutrals,
        graphs: payload.graphs,
      },
    };
  }

  return state;
};

const instagramFeedById = (state = {}, action) => {
  const { payload } = action;

  if (
    action.type === types.GET_COACH_FEED_SUCCESS &&
    payload.source === "instagram"
  ) {
    return {
      ...state,
      [payload.id]: {
        negatives: payload.negatives,
        positives: payload.positives,
        neutrals: payload.neutrals,
        graphs: payload.graphs,
      },
    };
  }

  return state;
};

const youtubeFeedById = (state = {}, action) => {
  const { payload } = action;

  if (
    action.type === types.GET_COACH_FEED_SUCCESS &&
    payload.source === "youtube"
  ) {
    return {
      ...state,
      [payload.id]: {
        negatives: payload.negatives,
        positives: payload.positives,
        neutrals: payload.neutrals,
        graphs: payload.graphs,
      },
    };
  }

  return state;
};

const newspaperFeedById = (state = {}, action) => {
  const { payload } = action;

  if (
    action.type === types.GET_COACH_FEED_SUCCESS &&
    payload.source === "newspaper"
  ) {
    return {
      ...state,
      [payload.id]: {
        negatives: payload.negatives,
        positives: payload.positives,
        neutrals: payload.neutrals,
        graphs: payload.graphs,
      },
    };
  }

  return state;
};

const feedDataById = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_COACH_FEED_SUCCESS: {
      return {
        ...state,
        ...payload.data,
      };
    }
    default:
      return state;
  }
};

const feed = combineReducers({
  twitter: twitterFeedById,
  instagram: instagramFeedById,
  youtube: youtubeFeedById,
  newspaper: newspaperFeedById,
  dataById: feedDataById,
});


const requestedReportData = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_ALLREADY_REQUESTED_PLUS_REPORTS: {
      return payload
    }

    default:
      return state
  }
}

const requestUserId = (state = '', action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_USER_ID_WITH_USER_HANDLE_SUCCESS: {
      return payload
    }

    default:
      return state
  }
}

export default combineReducers({
  allCoachList,
  updateCoachFilter,
  filterCoaches,
  isFilterCoaches,

  basic,
  possibleDuplicateInfo,
  contact,
  deepProfile,
  traits,
  customTraits,
  risk,
  // actionPlan,
  executiveSummery,
  reportSettings,
  stats,
  feed,
  notes,
  links,
  message,
  reports,
  coaches,
  requestedReportData,
  behaviourRisk,
  requestUserId
});

