import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { useContext, useState } from "react";
import { ActionContext } from "../../../contexts";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import RemainingReportDialog from "./RemainingReportDialog";
import SportsCard from "./SportsCard";
// import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    // width: 215,
    borderLeft: '5px solid #fcb643',
    borderRadius: '10px',
    // minHeight: 113,
    padding: '10px 16px',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "row-reverse",
      justifyContent: "flex-end",
      height: 64,
      padding: theme.spacing(2),
    },
  },
  title: {
    marginLeft: theme.spacing(2),
  },
  stat: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
    fontWeight: 500,
  },
}));

const StatCard = (props) => {
  let classes = useStyles();
  let { title = "", stat = "", loading, selectedId, statusName = "", sport } = props;

  let actions = useContext(ActionContext);
  let { dashboardActions, dialogActions } = actions;

  const handleRedirect = () => {
    // dashboardActions.getAllLitePlayerInfo(statusName);
    // dashboardActions.selectCard(statusName);
    // dashboardActions.resetDashboardFilter();
    if (statusName === "Team Dashboard") {
      dialogActions.openDialog(
        <SportsCard sports={sport} />,
        {
          maxWidth: "xs",
        }
      );
      dashboardActions.getAllLitePlayerInfo("status");
    }

    else if (statusName === "RemainingReports") {
      dialogActions.openDialog(
        <RemainingReportDialog />,
        {
          maxWidth: "xs",
        }
      );
      // dashboardActions.selectCard(statusName);
      // dashboardActions.resetDashboardFilter();
    } else {
      dashboardActions.getAllLitePlayerInfo(statusName);
      dashboardActions.selectCard(statusName);
      dashboardActions.resetDashboardFilter();

    }
  }

  return (
    <>
      <Paper className={classes.root} style={{ backgroundColor: selectedId === statusName ? 'rgba(253, 181, 37, 0.1)' : '' }} elevation={2} onClick={handleRedirect}>
        <Typography variant="h4" style={{ fontWeight: 700, fontSize: "22px", color: '#fcb643', }}>
          {loading ? <Skeleton animation="wave" /> : stat}
        </Typography>
        <Typography style={{ fontSize: '14px', fontWeight: 500 }}>
          {loading ? <Skeleton animation="wave" /> : title}
        </Typography>
        {/* {statusName === "Team Dashboard" ?
          <SportsCard sports={sport}
            icon={
              <EmojiEventsOutlinedIcon />
            }
          /> : ''} */}
      </Paper>
    </>
  );
};
let mapStateToProps = (state) => {
  return {

    selectedId: state.ui.dashboard.selectedCardId,

  };
};
export default connect(mapStateToProps)(StatCard);

