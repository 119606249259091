import { Divider } from "@material-ui/core";
import { AppBar, Tooltip } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { capitalize } from "../../../../utils";
import { IconButton } from "../../../shared/Button";
import ApprovePanel from "./ApprovePanel";
import MarkAsReviewCompleteToggleButton from "./MarkAsReviewCompleteToggleButton";
import Message from "./Message";
import Nav from "./Nav";
import Payments from "./Payments";
import Reports from "./Reports";
import RequestPlusReport from "./RequestPlus"
// import eachPlayer from "modules/dashboard/components/EachPlayer"
import InfoIcon from "@material-ui/icons/Info";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { ActionContext } from "../../../../contexts";
import '../../../../index.css';
import Reprocess from "../../../athlet-management/components/PlayerDetails/ReProcess";


let useStyles = makeStyles((theme) => ({
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTopLeftRadius: 8,
    background: '#0971ce1a',
    margin: '1rem',
    margin: '1rem 1rem 3rem 1rem',
    width: '97%',
    borderRadius: '9px',
    "& .MuiToolbar-regular": {
      minHeight: '30px',
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    paddingTop: theme.spacing(2),
    fontFamily: 'Prometo',
    color: '#326fc7'
  },
  nav: {
    backgroundColor: '#00000029',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: '9px',
    boxShadow: '0 2px 11px 0 rgb(0 0 0 / 16%)',
    backgroundColor: '#eeeef0',
    position: 'absolute',
    top: '5.9rem',
    zIndex: 1,
    // width: 101,
    // borderLeft: `1px solid ${theme.palette.divider}`,
    // paddingTop: theme.spacing(1),
    // borderTopRightRadius: theme.spacing(1),
    // borderBottomRightRadius: theme.spacing(1),
  },
  avatar: {
    width: 30,
    height: 30,
    marginRight: 8,
  },
  name: {
    width: 95,
    fontWeight: theme.typography.fontWeightBold,
  },
  team: {
    width: 95,
    fontSize: 10,
  },
  NameAlignData: {
    color: '#326fc7',
    fontSize: '16px',
    marginLeft: '10px'
  },
  TeamALign: {
    color: '#666666',
    fontSize: '14px',
  },
  StatusAligncss: {
    border: '1px solid #000',
    padding: '0.2rem 0.6rem',
    fontSize: '13px',
    fontWeight: '600',
    borderRadius: '9px',
  },
  SportALign: {
    color: '#666666',
    fontSize: '14px',
  },
  page: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll",
    scrollbarWidth: 7,
    background: '#ebf4fb',
    scrollbarColor: theme.palette.grey[200],
    "&::-webkit-scrollbar": {
      width: 8,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.background.default,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[300],
      height: 50,
    },
    padding: theme.spacing(2),
  },
  checkbox: {
    color: "red",
  },
  AvatarCellDesign: {
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    fontSize: '12px',
    height: '32px',
    width: '32px',
    boxShadow: '3px 3px 8px 0px #cdcdcd',
    color: '#fff',
    fontWeight: '700'
  },
}));

const PlayerDetails = (props) => {
  let classes = useStyles();
  let { coachId, pages, accountType, onClose, fromTeam, basicInfo, message, alreadyRequestedPlusReport, requestedReportData } = props;
  let [selectedIndex, setSelectedIndex] = useState(1);
  let [showMessage, setShowMessage] = useState(message.show);


  const { coachActions } = useContext(ActionContext);

  useEffect(() => {
    if (basicInfo?.isPlusReport) {
      coachActions.submitedPlusReport(basicInfo?.isPlusReport)
      coachActions.savePlusReportData(basicInfo?.plusReportData)
    }
  }, [basicInfo])

  useEffect(() => {
    return () => {
      coachActions.submitedPlusReport(false)
      coachActions.savePlusReportData({})
    }
  }, [])

  let page = pages[selectedIndex];

  if (!page) {
    return null;
  }

  let Page = page.component;

  if (!message) {
    setShowMessage(message.show)
  }


  let hasAnalyzed = true;
  let status = basicInfo.status;

  let showErrorMessage = true;

  let handleSelect = (e, index) => {
    setSelectedIndex(index);
  };

  let handleMessage = () => {
    setShowMessage(false);
  };

  const playerName = basicInfo.name.split(" ")

  return (
    <React.Fragment>
      <div className={classes.content}>
        <AppBar
          position="relative"
          color="inherit"
          elevation={0}
          className={classes.appBar}
        >
          <Toolbar>
            {/* <Hidden> */}
            <IconButton icon={<ArrowBackIcon />} onClick={onClose} />
            {/* </Hidden> */}
            {
              basicInfo.image ?
                <Avatar src={basicInfo.image} alt="player" className={classes.avatar} />
                :
                <Avatar className={classes.AvatarCellDesign} style={{ cursor: 'pointer', }} title={basicInfo.name}>
                  {
                    basicInfo.name ?
                      (
                        playerName.length > 1 ?
                          basicInfo.name.split(" ")[0][0].toUpperCase() + basicInfo.name.split(" ")[1][0].toUpperCase()
                          :
                          basicInfo.name.split(" ")[0][0].toUpperCase()
                      )
                      :
                      ""
                  }
                </Avatar>
            }
            <Typography variant="h6" className={classes.NameAlignData}>{capitalize(basicInfo.name)}</Typography>
            <Divider orientation="vertical" style={{ margin: '0px 10px 0px 10px' }} />
            <Typography className={classes.TeamALign}>{capitalize(basicInfo.team)}</Typography>
            <Divider orientation="vertical" style={{ margin: '0px 10px 0px 10px' }} />
            <Typography className={classes.SportALign}>{capitalize(basicInfo.sport) || "NA"}</Typography>
            <Divider orientation="vertical" style={{ margin: '0px 10px 0px 10px' }} />
            <Typography className={classes.StatusAligncss}
              style={{
                background: basicInfo.status === 'Completed' ? '#e0f5ea' : basicInfo.status === 'Approval Pending' ? '#cfe3f2' : basicInfo.status === 'Rejected' ? '#EBE2E9' : basicInfo.status === 'Processing' ? '#fbf3d9' : basicInfo.status === 'Psych Review Pending' ? '#f2f2f2' : basicInfo.status === 'Consent Pending' ? '#cccccc' : basicInfo.status === 'Error' ? '#fddfdf' : basicInfo.status === 'Base Report Completed' ? '#BDBDBE' : '',
                border: basicInfo.status === 'Completed' ? '1px solid #e0f5ea' : basicInfo.status === 'Approval Pending' ? '1px solid #cfe3f2' : basicInfo.status === 'Rejected' ? '1px solid #EBE2E9' : basicInfo.status === 'Processing' ? '#fbf3d9' : basicInfo.status === 'Psych Review Pending' ? '1px solid #f2f2f2' : basicInfo.status === 'Consent Pending' ? '1px solid #cccccc' : basicInfo.status === 'Error' ? '1px solid #fddfdf' : basicInfo.status === 'Base Report Completed' ? '1px solid #BDBDBE' : '1px solid #000',
                color: basicInfo.status === 'Completed' ? '#00ae4d' : basicInfo.status === 'Approval Pending' ? '#1075bf' : basicInfo.status === 'Rejected' ? '#BC5353' : basicInfo.status === 'Processing' ? '#e7b618' : basicInfo.status === 'Psych Review Pending' ? '#666666' : basicInfo.status === 'Consent Pending' ? '#000000' : basicInfo.status === 'Error' ? '#f33a3a' : basicInfo.status === 'Base Report Completed' ? '#1A73E8' : ''
              }}
            ><span >{capitalize(basicInfo.status)}</span></Typography>
            <div style={{ margin: '0px 10px 0px 10px' }}>
              {
                (basicInfo?.isPlusReport === true) ?
                  <Typography className={classes.StatusAligncss}
                    style={{
                      border: basicInfo?.status === 'Completed' ? '1px solid #0971ce' : '1px solid #fcb643',
                      color: basicInfo?.status === 'Completed' ? '#0971ce' : '#fcb643'
                    }}
                  >
                    <span >Plus Report</span>
                  </Typography>
                  :
                  <Typography className={classes.StatusAligncss}
                    style={{
                      border: '1px solid grey',
                      color: 'grey'
                    }}
                  >
                    <span >Base Report</span>
                  </Typography>
              }
            </div>
            <div style={{ flex: 1 }} />

            <div
              style={{
                display: "flex",
              }}
            >
              {
                <MarkAsReviewCompleteToggleButton
                  coachId={coachId}
                  consent={basicInfo.consent}
                  status={basicInfo.status}
                />
              }
            </div>
            {
              // (accountType === "SUPER_ADMIN" || accountType === "BCA_DDI_ADMIN" || accountType === "BCA_PSYCHOLOGIST" || accountType === "BCA_CHIEF_PSYCHOLOGIST") ?
              //   ""
              //   :
              // <Tooltip arrow title={<div>
              //   <span>
              //     {/* <b>Requested By : </b> {basicInfo?.plusReportData?.request_by ? basicInfo.plusReportData?.request_by : "--"} */}
              //     <b>Requested By : </b> {requestedReportData?.request_by ? requestedReportData?.request_by : "--"}
              //   </span>
              //   <div>
              //     {/* <b>Requested On: </b>{basicInfo?.plusReportData?.request_on ? basicInfo.plusReportData?.request_on : "--"} */}
              //     <b>Requested On: </b>{requestedReportData?.request_on ? requestedReportData?.request_on : "--"}
              //   </div>
              // </div>}
              // >
              //   <InfoIcon style={{ cursor: 'pointer' }} />
              // </Tooltip>
            }
            {
              (accountType === "SUPER_ADMIN" || accountType === "BCA_DDI_ADMIN" || accountType === "BCA_PSYCHOLOGIST" || accountType === "BCA_CHIEF_PSYCHOLOGIST" || basicInfo.team === 'Star Wars') ?
                ""
                :
                <RequestPlusReport
                  coachId={coachId}
                  status={status}
                  accountType={accountType}
                  basicInfo={basicInfo}
                />
            }
            {(accountType === "SUPER_ADMIN" || accountType === "BCA_PSYCHOLOGIST" || accountType === "BCA_CHIEF_PSYCHOLOGIST") ?
              <Reports
                coachId={coachId}
                status={status}
                accountType={accountType}
              /> : ''}

            <Payments
              coachId={coachId}
              status={status}
              accountType={accountType}
            />
              {
              (accountType === "SUPER_ADMIN" || accountType === "BCA_DDI_ADMIN")?
                <Reprocess
                  playerId={coachId}
                  status={status}
                  accountType={accountType}
                  playerInfo={basicInfo}
                  type='coach'
                /> : ''
            }

          </Toolbar>
        </AppBar>
        <div className={classes.page}>
          {showErrorMessage && (
            <div style={{ width: "100%", marginTop: "40px" }}>
              <Message
                show={showMessage}
                title={message.title}
                content={message.content}
                buttonLabel={message.buttonLabel}
                severity={message.severity}
                onClick={handleMessage}
              />
            </div>
          )}

          <div className={classes.nav}>
            <Nav
              selectedIndex={selectedIndex}
              onSelect={handleSelect}
              hasAnalyzed={hasAnalyzed}
              status={basicInfo.status}
              fromTeam={fromTeam}
              basicInfo={basicInfo}
            />
          </div>
          <div style={{ marginTop: '2rem', width: '90%' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {/* <div style={{ width: '13.4rem', display: 'block', background: '#ebf4fb', }}></div> */}
              <div style={{
                display: 'block',
                textAlign: 'center',
                borderBottom: '2px solid #ffd201',
                marginBottom: '2rem',
              }}>


                <Typography variant="h5" align="center" className={classes.title}>
                  {page.title}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  align="center"
                  style={{
                    marginBottom: 16, fontSize: '16px',
                    marginTop: '0.5rem',
                    color: '#333',
                  }}
                >
                  {page.description}
                </Typography>


              </div>
            </div>

            <Page coachId={coachId} />
          </div>
        </div>
        <ApprovePanel coachId={coachId} />
      </div>
    </React.Fragment>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { coachId } = ownProps;
  return {
    basicInfo: state.coach.basic[coachId],
    accountType: state.account.accountType,
    // exportingCoachReport: state.ui.coach.exportingCoachReport[coachId],
    message: state.coach.message[coachId],
    alreadyRequestedPlusReport: state.ui.coach.alreadyRequestedPlusReport,
    requestedReportData: state.coach.requestedReportData,
  };
};
export default connect(mapStateToProps)(PlayerDetails);
