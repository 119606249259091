import { combineReducers } from "redux";
import * as types from "./appTypes";

const isDrawerOpen = (state = true, action) => {
  switch (action.type) {
    case types.OPEN_DRAWER: {
      return true;
    }

    case types.CLOSE_DRAWER: {
      return false;
    }

    case types.TOGGLE_DRAWER: {
      return !Boolean(state);
    }

    default:
      return state;
  }
};

export default combineReducers({
  isDrawerOpen,
});
