import { bindActionCreators as reduxBindActionCreators } from "redux";

const bindActionCreators = (actionCreatorDomains = {}, dispatch) => {
  let boundActionCreatorDomains = {};

  Object.keys(actionCreatorDomains).forEach((domain) => {
    let actionCreators = actionCreatorDomains[domain];
    let boundActionCreators = reduxBindActionCreators(actionCreators, dispatch);
    boundActionCreatorDomains[domain] = boundActionCreators;
  });

  return boundActionCreatorDomains;
};

export default bindActionCreators;
