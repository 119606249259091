import React, { useContext } from 'react'
import { Card, CardActions, CardContent, CardHeader, Button } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ActionContext } from "../../../../contexts";
import { useConfirm } from "material-ui-confirm";
import { showSuccessMessage } from "modules/shared/Snackbar";

function TeamDetails(props) {
    const { result, loadingDeleteConference } = props;
    let confirm = useConfirm();
    let dispatch = useDispatch();
    let actions = useContext(ActionContext);
    let { playerActions, conferenceActions } = actions;

    let history = useHistory();


    const handleClick = () => {
        history.push("/players");
        playerActions.filterByTeam(result.name);
    };

    // const handleDelete = () => {
    //     confirm({
    //         title: `Delete result - ${result.name}?`,
    //         description: "You are about to delete the conference. Are you sure?",
    //         confirmationText: "Yes",
    //     })
    //         .then(() => {
    //             conferenceActions.conferenceDelete(result.id);
    //             dispatch(showSuccessMessage("conference deleted successfully"));
    //         })
    //         .catch(() => {
    //             // do nothing
    //         });

    // }

    return (
        <React.Fragment>
            <Card variant="outlined" style={{  width: "100%" }} onClick={handleClick}>
                <CardHeader
                    title={result.name}
                />
                {/* <CardActions>
                    <Button
                        onClick={handleDelete}
                        size="small"
                        color="primary"
                        variant="text"
                        loading={loadingDeleteConference}
                    >
                        Delete
                    </Button>
                </CardActions> */}
            </Card>
        </React.Fragment>
    )
}


let mapStateToProps = (state) => {
    return {
        loadingDeleteConference: state.ui.conference.deleteConferenceReducer,
    };
};

export default connect(mapStateToProps)(TeamDetails);