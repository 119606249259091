import * as types from "./playerTypes";
import { playerApi } from "../../../api";
import { statusTypes, reportTypes } from "../constants";
import {
  showSuccessMessage,
  showErrorMessage,
  showInfoMessage,
} from "../../shared/Snackbar";
import coachActions from "../../coach/state/coachActions"

// api actions

const getPlayerList = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_GET_PLAYERS,
      });
      const players = await playerApi.getPlayers();
      dispatch({
        type: types.GET_PLAYERS_SUCCESS,
        payload: players,
      });
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.GET_PLAYERS_FAILED,
        payload: {
          error: "Fetch players failed",
        },
      });
    }
  };
};

const getAllCandidates = () => {
  return async (dispatch) => {
    try {

      dispatch({
        type: types.REQUEST_GET_CANDIDATES
      })

      let response = await playerApi.getCandidates()

      dispatch({
        type: types.GET_CANDIDATES_SUCCESS,
        payload: response
      })

    } catch (err) {
      dispatch(showErrorMessage(err.message))
      dispatch({
        type: types.GET_CANDIDATES_FAILED
      })
    }
  }
}

const getPlayer = (playerId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_GET_PLAYER,
        payload: {
          playerId,
        },
      });

      const response = await playerApi.getPlayerDetails(playerId);

      dispatch({
        type: types.GET_PLAYER_SUCCESS,
        payload: {
          ...response,
          playerId,
        },
      });
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.GET_PLAYER_FAILED,
        payload: {
          error: "Fetch player failed",
        },
      });
    }
  };
};

const getPlayerFeed = (playerId, source) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_GET_PLAYER_FEED,
        payload: {
          playerId,
          source,
        },
      });

      const response = await playerApi.getPlayerFeed(playerId, source);

      dispatch({
        type: types.GET_PLAYER_FEED_SUCCESS,
        payload: {
          id: playerId,
          source,
          ...response,
        },
      });
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.GET_PLAYER_FEED_FAILED,
        payload: {
          error: "Fetch player feed failed",
        },
      });
    }
  };
};

const getTeamList = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_GET_TEAMS,
      });
      let response = await playerApi.getTeams();
      dispatch({
        type: types.GET_CANDIDATE_TEAMS_SUCCESS,
        payload: {
          teams: response.candidateTeams,
        },
      });
      dispatch({
        type: types.GET_ALL_CANDIDATE_TEAMS_SUCCESS,
        payload: {
          teams: response.allCandidateTeams,
        },
      });

      dispatch({
        type: types.GET_ORGANIZATION_TEAMS_SUCCESS,
        payload: {
          teams: response.organizationTeams,
        },
      });
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.GET_TEAMS_FAILED,
        payload: "Failed to fetch teams",
      });
    }
  };
};

const uploadPlayers = (data, successCb = (f) => f, failCb = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_UPLOAD_PLAYERS,
      });
      const response = await playerApi.postPlayers(data);
      successCb();
      dispatch(showInfoMessage(response.notification));
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.UPLOAD_PLAYERS_FAILED,
        payload: err,
      });
      failCb();
    }
  };
};

const createPlayer = (data, successCb = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_CREATE_PLAYER,
      });
      await playerApi.postPlayer(data);
      dispatch({
        type: types.CREATE_PLAYER_SUCCESS,
        payload: data,
      });
      successCb();
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.CREATE_PLAYER_FAILED,
        payload: err,
      });
    }
  };
};

const createCoach = (data, successCb = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_CREATE_COACH,
      });
      await playerApi.postCoach(data);
      dispatch({
        type: types.CREATE_COACH_SUCCESS,
        payload: data,
      });
      successCb();
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.CREATE_COACH_FAILED,
        payload: err,
      });
    }
  };
};

const exportFeed = (
  playerId,
  playerName,
  source,
  successCb = (f) => f,
  failCb = (f) => f
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_EXPORT_FEED,
        payload: {
          playerId,
          source,
        },
      });

      const response = await playerApi.exportPlayerFeed(playerId, source);

      const fileName = `${playerName} ${source}.csv`;

      successCb(response, fileName)();

      dispatch({
        type: types.EXPORT_FEED_SUCCESS,
        payload: {
          playerId,
          source,
        },
      });
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.EXPORT_FEED_FAILED,
        payload: {
          playerId,
          source,
        },
      });

      failCb();
    }
  };
};

const exportReport = (playerId, successCb = (f) => f, failCb = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_EXPORT_PLAYER_REPORT,
        payload: {
          playerId,
        },
      });

      dispatch(showInfoMessage("Exporting the report"));
      const response = await playerApi.exportPlayerReport(playerId);

      successCb(response, "report.pdf")();
      dispatch({
        type: types.EXPORT_PLAYER_REPORT_SUCCESS,
        payload: {
          playerId,
        },
      });
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.EXPORT_PLAYER_REPORT_FAILED,
        payload: {
          playerId,
        },
      });

      failCb();
    }
  };
};

const exportSystemReport = (
  playerId,
  playerName = "",
  successCb = (f) => f,
  failCb = (f) => f
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_EXPORT_SYSTEM_REPORT,
        payload: {
          playerId,
        },
      });

      dispatch(showInfoMessage("Exporting the report"));
      const response = await playerApi.exportSystemReport(playerId);

      const reportName = `${playerName} Analysis System.pdf`;

      setTimeout(() => {
        dispatch({
          type: types.EXPORT_SYSTEM_REPORT_SUCCESS,
          payload: {
            playerId,
          },
        });
        successCb(response, reportName)();
      }, 1000);
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.EXPORT_SYSTEM_REPORT_FAIL,
        payload: {
          playerId,
        },
      });

      failCb();
    }
  };
};

const exportPsycologistReport = (
  playerId,
  playerName,
  successCb = (f) => f,
  failCb = (f) => f
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_EXPORT_PSYCOLOGIST_REPORT,
        payload: {
          playerId,
        },
      });

      dispatch(showInfoMessage("Exporting the report"));
      const response = await playerApi.exportPsycologistReport(playerId);

      const reportName = `${playerName} Analysis Complete.pdf`;

      dispatch({
        type: types.EXPORT_PSYCOLOGIST_REPORT_SUCCESS,
        payload: {
          playerId,
        },
      });

      successCb(response, reportName)();
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.EXPORT_PSYCOLOGIST_REPORT_FAIL,
        payload: {
          playerId,
        },
      });

      failCb();
    }
  };
};

const exportFullReport = (
  playerId,
  playerName = "",
  successCb = (f) => f,
  failCb = (f) => f
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_EXPORT_FULL_REPORT,
        payload: {
          playerId,
        },
      });

      dispatch(showInfoMessage("Exporting the report"));
      const response = await playerApi.exportFullReport(playerId);

      const reportName = `${playerName} Full Report.pdf`;

      dispatch({
        type: types.EXPORT_FULL_REPORT_SUCCESS,
        payload: {
          playerId,
        },
      });
      successCb(response, reportName)();
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.EXPORT_FULL_REPORT_FAIL,
        payload: {
          playerId,
        },
      });

      failCb();
    }
  };
};

const exportActionPlanReport = (
  playerId,
  playerName = "",
  successCb = (f) => f,
  failCb = (f) => f
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_EXPORT_ACTION_PLAN_REPORT,
        payload: {
          playerId,
        },
      });

      dispatch(showInfoMessage("Exporting the report"));
      const response = await playerApi.exportActionPlanReport(playerId);

      const reportName = `${playerName} Action Plan Report.pdf`;

      dispatch({
        type: types.EXPORT_ACTION_PLAN_REPORT_SUCCESS,
        payload: {
          playerId,
        },
      });
      successCb(response, reportName)();
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.EXPORT_ACTION_PLAN_REPORT_FAIL,
        payload: {
          playerId,
        },
      });

      failCb();
    }
  };
};

const exportExecutiveSummaryReport = (
  playerId,
  playerName = "",
  successCb = (f) => f,
  failCb = (f) => f
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_EXPORT_EXECUTIVE_SUMMARY_REPORT,
        payload: {
          playerId,
        },
      });

      dispatch(showInfoMessage("Exporting the report"));
      const response = await playerApi.exportExecutiveSummaryReport(playerId);

      const reportName = `${playerName} Executive Summary Report.pdf`;

      dispatch({
        type: types.EXPORT_EXECUTIVE_SUMMARY_REPORT_SUCCESS,
        payload: {
          playerId,
        },
      });
      successCb(response, reportName)();
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.EXPORT_EXECUTIVE_SUMMARY_REPORT_FAIL,
        payload: {
          playerId,
        },
      });

      failCb();
    }
  };
};

const addRiskComment = (data, successCb = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_ADD_RISK_COMMENT,
        payload: {
          playerId: data.candidate_id,
          categoryId: data.category,
        },
      });

      const commentId = await playerApi.postRiskComment(data);

      dispatch({
        type: types.ADD_RISK_COMMENT_SUCCESS,
        payload: {
          commentId,
          comment: data.content,
          playerId: data.candidate_id,
          categoryId: data.category,
        },
      });

      dispatch(showSuccessMessage("Comment added successfully"));

      successCb();
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.ADD_RISK_COMMENT_FAILED,
        payload: {
          playerId: data.candidate_id,
          categoryId: data.category,
        },
      });
    }
  };
};

const deleteRiskComment = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_DELETE_RISK_COMMENT,
        payload: {
          playerId: data.candidate_id,
          categoryId: data.category,
        },
      });

      const commentId = await playerApi.deleteRiskComment(data);

      dispatch({
        type: types.DELETE_RISK_COMMENT_SUCCESS,
        payload: {
          playerId: data.candidate_id,
          categoryId: data.category,
          commentId: commentId,
        },
      });

      dispatch(showSuccessMessage("Comment deleted successfully"));
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.DELETE_RISK_COMMENT_FAILED,
        payload: {
          playerId: data.candidate_id,
          categoryId: data.category,
        },
      });
    }
  };
};

const addActionPlanComment = (data, successCb = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_ADD_ACTION_PLAN_COMMENT,
        payload: {
          playerId: data.candidate_id,
          categoryId: data.category,
          comment: data.content,
        },
      });

      let commentId = await playerApi.postActionPlanComment(data);

      dispatch({
        type: types.ADD_ACTION_PLAN_COMMENT_SUCCESS,
        payload: {
          playerId: data.candidate_id,
          categoryId: data.category,
          commentId,
          comment: data.content,
        },
      });

      successCb();
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.DELETE_ACTION_PLAN_COMMENT_FAILED,
        payload: {
          playerId: data.candidate_id,
          categoryId: data.category,
        },
      });
    }
  };
};

const deleteActionPlanComment = (data, prevData) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_DELETE_ACTION_PLAN_COMMENT,
        payload: {
          playerId: data.candidate_id,
          category: data.category,
          _id: data._id
        },
      });

      await playerApi.deleteActionPlanComment(data);

      dispatch({
        type: types.DELETE_ACTION_PLAN_COMMENT_SUCCESS,
        payload: {
          playerId: data.candidate_id,
          categoryId: data.category,
          _id: data._id,
          prevData: prevData
        },
      });
      dispatch(showSuccessMessage("Comment deleted successfully"));
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      // dispatch({
      //   type: types.DELETE_EXECUTIVE_SUMMERY_COMMENT_FAILED,
      //   payload: {
      //     playerId: data.candidate_id,
      //     categoryId: data.category,
      //   },
      // });
    }
  };
};

const addExecutiveSummeryComment = (data, successCb = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_ADD_EXECUTIVE_SUMMERY_COMMENT,
        payload: {
          playerId: data.candidate_id,
          categoryId: data.category,
        },
      });

      const commentId = await playerApi.postExecutiveSummeryComment(data);

      dispatch({
        type: types.ADD_EXECUTIVE_SUMMERY_COMMENT_SUCCESS,
        payload: {
          playerId: data.candidate_id,
          categoryId: data.category,
          commentId,
          title: data.title,
          comment: data.content,
        },
      });

      successCb();
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.ADD_EXECUTIVE_SUMMERY_COMMENT_FAILED,
      });
    }
  };
};

const updateNotes = (data, successCb = (f) => f, failCb = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_UPDATE_NOTES,
        payload: {
          playerId: data.candidate_id,
        },
      });

      await playerApi.postUpdateNotes(data);

      dispatch({
        type: types.UPDATE_NOTES_SUCCESS,
        payload: {
          playerId: data.candidate_id,
          notes: data.notes,
        },
      });
      dispatch(showSuccessMessage("Notes updated successfully"));
      successCb();
    } catch (err) {
      dispatch({
        type: types.UPDATE_NOTES_FAILED,
        payload: {
          playerId: data.candidate_id,
        },
      });
      dispatch(showErrorMessage(err.message));
      failCb();
    }
  };
};

const updateRiskScore = (
  data = { candidate_id: "", category: "", score: 0 },
  successCb = (f) => f,
  failCb = (f) => f
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_UPDATE_RISK_SCORE,
        payload: {
          playerId: data.candidate_id,
          categoryId: data.category,
        },
      });

      const response = await playerApi.updateRiskScore(data);

      dispatch({
        type: types.UPDATE_RISK_SCORE_SUCCESS,
        payload: {
          playerId: data.candidate_id,
          categoryId: data.category,
          risk: response.risk,
        },
      });
      dispatch(showSuccessMessage("Score updated successfully"));
      successCb();
    } catch (err) {
      dispatch({
        type: types.UPDATE_RISK_SCORE_FAIL,
        payload: {
          playerId: data.candidate_id,
          categoryId: data.category,
        },
      });
      dispatch(showErrorMessage(err.message));
      failCb();
    }
  };
};

const deleteExecutiveSummeryComment = (data, successCb = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_DELETE_EXECUTIVE_SUMMERY_COMMENT,
        payload: {
          playerId: data.candidate_id,
          categoryId: data.category,
        },
      });

      const commentId = await playerApi.deleteExecutiveSummeryComment(data);

      dispatch({
        type: types.DELETE_EXECUTIVE_SUMMERY_COMMENT_SUCCESS,
        payload: {
          playerId: data.candidate_id,
          categoryId: data.category,
          commentId,
        },
      });
    } catch (err) {
      dispatch(showErrorMessage(err.message));
    }
  };
};

const approveCandidateProfile = (
  data,
  successCb = (f) => f,
  failCb = (f) => f
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_APPROVE_CANDIDATE_PROFILE,
        payload: {
          playerId: data.candidate_id,
        },
      });

      await playerApi.postApproveCandidateProfile(data);

      dispatch({
        type: types.APPROVE_CANDIDATE_PROFILE_SUCCESS,
        payload: {
          playerId: data.candidate_id,
          status: statusTypes.processing,
        },
      });
      successCb();
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.APPROVE_CANDIDATE_PROFILE_FAILED,
        payload: {
          playerId: data.candidate_id,
        },
      });
      failCb();
    }
  };
};

const postStartAnalyze = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_ANALYZE_CANDIDATE_DETAILS,
        payload: {
          playerId: data,
        },
      });

      await playerApi.postStartAnalyze(data);

      dispatch({
        type: types.ANALYZE_CANDIDATE_DETAILS_SUCCESS,
        payload: {
          playerId: data,
        },
      });
      dispatch(postAnalyzeResults(data));

    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.ANALYZE_CANDIDATE_DETAILS_FAILED,
        payload: {
          playerId: data,
        },
      });

    }
  };
};

const postAnalyzeResults = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_ANALYZE_CANDIDATE_RESULTS,
        payload: {
          playerId: data,
        },
      });

      await playerApi.postAnalyzeResults(data);

      dispatch({
        type: types.ANALYZE_CANDIDATE_RESULTS_SUCCESS,
        payload: {
          playerId: data,
        },
      });

    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.ANALYZE_CANDIDATE_RESULTS_FAILED,
        payload: {
          playerId: data,
        },
      });

    }
  };
};

const postSentimentResults = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_CANDIDATE_SENTIMENT_RESULTS,
        payload: {
          playerId: data,
          value: false
        },
      });

      await playerApi.postSentimateResults(data);

      dispatch({
        type: types.CANDIDATE_SENTIMENT_RESULTS_SUCCESS,
        payload: {
          playerId: data,
          value: true
        },
      });

    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.CANDIDATE_SENTIMENT_RESULTS_FAILED,
        payload: {
          playerId: data,
          value: false
        },
      });

    }
  };
};

const resetSentimentResults = (playerId) => {
  return (dispatch) => {
    dispatch({
      type: types.CANDIDATE_SENTIMENT_RESULTS_SUCCESS,
      payload: {
        playerId: playerId,
        value: false
      },
    });
  }
}


const rejectCandidateProfile = (
  data,
  successCb = (f) => f,
  failCb = (f) => f
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_REJECT_CANDIDATE_PROFILE,
        payload: {
          playerId: data.candidate_id,
        },
      });

      await playerApi.postRejectCandidateProfile(data);

      dispatch({
        type: types.REJECT_CANDIDATE_PROFILE_SUCCESS,
        payload: {
          playerId: data.candidate_id,
          status: statusTypes.rejected,
        },
      });
      successCb();
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.REJECT_CANDIDATE_PROFILE_FAILED,
        payload: {
          playerId: data.candidate_id,
        },
      });
      failCb();
    }
  };
};

const markReviewCompleted = (playerId, nextStatus) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_REVIEW_COMPLETE_CANDIDATE,
        payload: {
          playerId,
        },
      });

      await playerApi.postReviewCompleted(playerId);

      dispatch({
        type: types.REVIEW_COMPLETE_CANDIDATE_SUCCESS,
        payload: {
          playerId,
          status: nextStatus,
        },
      });

      dispatch(showSuccessMessage("Candidate marked as Completed"));
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.REVIEW_COMPLETE_CANDIDATE_FAILED,
        payload: {
          playerId,
        },
      });
    }
  };
};

const markReviewIncomple = (playerId, nextStatus) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUSET_CANDIDATE_REVIEW_INCOMPLETE,
        payload: {
          playerId,
        },
      });

      await playerApi.markAsReviewIncompleted(playerId);

      dispatch({
        type: types.CANDIDATE_REVIEW_INCOMPLETE_SUCCESS,
        payload: {
          playerId,
          status: nextStatus,
        },
      });

      dispatch(showSuccessMessage("Candidate marked as Psych Review"));
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.CANDIDATE_REVIEW_INCOMPLETE_FAILED,
        payload: {
          playerId,
        },
      });
    }
  };
};

const markAsReviewSubmitted = (playerId, nextStatus) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUSET_CANDIDATE_REVIEW_SUBMITTED,
        payload: {
          playerId,
        },
      });

      await playerApi.markAsReviewSubmitted(playerId);

      dispatch({
        type: types.CANDIDATE_REVIEW_SUBMITTED_SUCCESS,
        payload: {
          playerId,
          status: nextStatus,
        },
      });

      dispatch(showSuccessMessage("Candidate marked as Psych Review"));
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.CANDIDATE_REVIEW_SUBMITTED_FAILED,
        payload: {
          playerId,
        },
      });
    }
  };
};

const addActionPlanContent = (data, successCb = (f) => f, prevData = []) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_ADD_ACTION_PLAN_COMMENT,
        payload: {
          playerId: data.candidate_id,
          categoryId: data.category,
        },
      });

      await playerApi.postActionPlanContent(data);

      dispatch({
        type: types.ADD_ACTION_PLAN_COMMENT_SUCCESS,
        payload: {
          playerId: data.candidate_id,
          categoryId: data.category,
          content: data.content,
          prevData: prevData,
          data: data
        },
      });
      dispatch(showSuccessMessage("Comment saved successfully"));
      successCb();
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.ADD_ACTION_PLAN_CONTENT_FAILED,
        payload: {
          playerId: data.candidate_id,
          categoryId: data.category,
        },
      });
    }
  };
};

const addExecutiveSummeryContent = (data, successCb = (f) => f, prevData = []) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_ADD_EXECUTIVE_SUMMERY_COMMENT,
        payload: {
          playerId: data.candidate_id,
          categoryId: data.category,
        },
      });

      await playerApi.postExecutiveSummeryComment(data);

      dispatch({
        type: types.ADD_EXECUTIVE_SUMMERY_COMMENT_SUCCESS,
        payload: {
          playerId: data.candidate_id,
          categoryId: data.category,
          content: data.content,
          prevData: prevData,
          data: data
        },
      });
      dispatch(showSuccessMessage("Comment saved successfully"));
      successCb();
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.ADD_EXECUTIVE_SUMMERY_CONTENT_FAILED,
        payload: {
          playerId: data.candidate_id,
          categoryId: data.category,
        },
      });
    }
  };
};

const markCandidateAsPaid = (playerId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_TOGGLE_CANDIDATE_PAID_STATUS,
        payload: {
          playerId,
        },
      });

      await playerApi.markCandidateAsPaid(playerId);

      dispatch({
        type: types.TOGGLE_CANDIDATE_PAID_STATUS_SUCCESS,
        payload: {
          playerId,
          isPaid: true,
        },
      });
      dispatch(showSuccessMessage("Marked as Paid"));
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.TOGGLE_CANDIDATE_PAID_STATUS_FAILED,
        payload: {
          playerId,
        },
      });
    }
  };
};

const markCandidateAsUnpaid = (playerId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_TOGGLE_CANDIDATE_PAID_STATUS,
        payload: {
          playerId,
        },
      });

      await playerApi.markCandidateAsUnpaid(playerId);

      dispatch({
        type: types.TOGGLE_CANDIDATE_PAID_STATUS_SUCCESS,
        payload: {
          playerId,
          isPaid: false,
        },
      });
      dispatch(showSuccessMessage("Marked as Unpaid"));
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.TOGGLE_CANDIDATE_PAID_STATUS_FAILED,
        payload: {
          playerId,
        },
      });
    }
  };
};

const toggleFullReport = (playerId, allowed) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_TOGGLE_FULL_REPORT_PAYMENT_STATUS,
        payload: {
          playerId,
        },
      });
      await playerApi.toggleFullReportPaymentStatus(playerId, allowed);
      dispatch({
        type: types.TOGGLE_FULL_REPORT_PAYMENT_STATUS_SUCCESS,
        payload: {
          playerId,
          reportType: reportTypes.fullReport,
          allowed,
        },
      });
      dispatch(showSuccessMessage("Payment status updated"));
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.TOGGLE_FULL_REPORT_PAYMENT_STATUS_FAILED,
        payload: {
          playerId,
          allowed,
        },
      });
    }
  };
};

const toggleActionPlanReport = (playerId, allowed) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_TOGGLE_ACTION_PLAN_REPORT_PAYMENT_STATUS,
        payload: {
          playerId,
        },
      });

      await playerApi.toggleActionPlanReportPaymentStatus(playerId, allowed);
      dispatch({
        type: types.TOGGLE_ACTION_PLAN_REPORT_PAYMENT_STATUS_SUCCESS,
        payload: {
          playerId,
          allowed,
          reportType: reportTypes.actionPlanReport,
        },
      });
      dispatch(showSuccessMessage("Payment status updated"));
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.TOGGLE_ACTION_PLAN_REPORT_PAYMENT_STATUS_FAILED,
        payload: {
          playerId,
          allowed,
        },
      });
    }
  };
};

const toggleExecutiveSummaryReport = (playerId, allowed) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_TOGGLE_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS,
        payload: {
          playerId,
        },
      });

      await playerApi.toggleExecutiveSummaryReportPaymentStatus(
        playerId,
        allowed
      );
      dispatch({
        type: types.TOGGLE_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS_SUCCESS,
        payload: {
          playerId,
          allowed,
          reportType: reportTypes.executiveSummaryReport,
        },
      });
      dispatch(showSuccessMessage("Payment status updated"));
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.TOGGLE_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS_FAILED,
        payload: {
          playerId,
          allowed,
        },
      });
    }
  };
};

const addCandidateLink = (data, successCb = (f) => f, failCb = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_ADD_CANDIDATE_LINK,
        payload: {
          playerId: data.candidate_id,
        },
      });

      const links = await playerApi.addCandidateLink(data);

      dispatch({
        type: types.ADD_CANDIDATE_LINK_SUCCESS,
        payload: {
          playerId: data.candidate_id,
          links,
        },
      });

      dispatch(showSuccessMessage("Link added"));
      successCb();
    } catch (error) {
      dispatch({
        type: types.ADD_CANDIDATE_LINK_FAIL,
        payload: {
          playerId: data.candidate_id,
        },
      });
      dispatch(showErrorMessage(error.message));
      failCb();
    }
  };
};

const deleteCandidateLink = (data) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_DELETE_CANDIDATE_LINK,
        payload: {
          playerId: data.candidate_id,
        },
      });

      dispatch({
        type: types.DELETE_CANDIDATE_LINK_SUCCESS,
        payload: {
          playerId: data.candidate_id,
          linkId: data.link_id,
        },
      });
      dispatch(showSuccessMessage("Link deleted"));
    } catch (error) {
      dispatch({
        type: types.DELETE_CANDIDATE_LINK_FAIL,
        payload: {
          playerId: data.candidate_id,
        },
      });
      dispatch(showErrorMessage(error.message));
    }
  };
};

const regenerateSystemReport = (playerId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_REGENERATE_SYSTEM_REPORT,
        payload: {
          playerId,
        },
      });

      await playerApi.reGenerateSystemReport(playerId);
      setTimeout(() => {
        dispatch({
          type: types.REGENERATE_SYSTEM_REPORT_SUCCESS,
          payload: {
            playerId,
          },
        });
        dispatch(showSuccessMessage("System report updated"));
      }, 1000);
    } catch (error) {
      dispatch({
        type: types.REGENERATE_SYSTEM_REPORT_FAIL,
        payload: {
          playerId,
        },
      });
      dispatch(showErrorMessage(error.message));
    }
  };
};

const regeneratePsychReport = (playerId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_REGENERATE_PSYCH_REPORT,
        payload: {
          playerId,
        },
      });
      await playerApi.reGeneratePsycologistReport(playerId);
      setTimeout(() => {
        dispatch({
          type: types.REGENERATE_PSYCH_REPORT_SUCCESS,
          payload: {
            playerId,
          },
        });
        dispatch(showSuccessMessage("Psychologist report updated"));
      }, 1000);
    } catch (error) {
      dispatch({
        type: types.REGENERATE_PSYCH_REPORT_FAIL,
        payload: {
          playerId,
        },
      });
      dispatch(showErrorMessage(error.message));
    }
  };
};

const regenerateFullReport = (playerId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_REGENERATE_FULL_REPORT,
        payload: {
          playerId,
        },
      });
      await playerApi.reGenerateFullReport(playerId);
      dispatch({
        type: types.REGENERATE_FULL_REPORT_SUCCESS,
        payload: {
          playerId,
        },
      });
      dispatch(showSuccessMessage("Full report updated"));
    } catch (error) {
      dispatch({
        type: types.REGENERATE_FULL_REPORT_FAIL,
        payload: {
          playerId,
        },
      });
      dispatch(showErrorMessage(error.message));
    }
  };
};

const regenerateActionPlanReport = (playerId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_REGENERATE_ACTION_PLAN_REPORT,
        payload: {
          playerId,
        },
      });
      await playerApi.reGenerateActionPlanReport(playerId);
      dispatch({
        type: types.REGENERATE_ACTION_PLAN_REPORT_SUCCESS,
        payload: {
          playerId,
        },
      });
      dispatch(showSuccessMessage("Action plan report updated"));
    } catch (error) {
      dispatch({
        type: types.REGENERATE_ACTION_PLAN_REPORT_FAIL,
        payload: {
          playerId,
        },
      });
      dispatch(showErrorMessage(error.message));
    }
  };
};

const regenerateExecutiveSummaryReport = (playerId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_REGENERATE_EXECUTIVE_SUMMARY_REPORT,
        payload: {
          playerId,
        },
      });

      await playerApi.reGenerateExecutiveSummaryReport(playerId);

      dispatch({
        type: types.REGENERATE_EXECUTIVE_SUMMARY_REPORT_SUCCESS,
        payload: {
          playerId,
        },
      });
      dispatch(showSuccessMessage("Executive summary report updated"));
    } catch (error) {
      dispatch({
        type: types.REGENERATE_EXECUTIVE_SUMMARY_REPORT_FAIL,
        payload: {
          playerId,
        },
      });
      dispatch(showErrorMessage(error.message));
    }
  };
};
// ui actions
const loadingFeed = () => {
  return {
    type: types.LOADING_FEED,
  };
};

const selectPlayer = (playerId) => {
  return {
    type: types.SELECT_PLAYER,
    payload: {
      playerId,
    },
  };
};
const submitedPlusReport = (data) => {
  return {
    type: types.SAVE_ALLREADY_REQUESTED_PLUS_REPORTS,
    payload: {
      data
    }
  }
}

const savePlusReportData = (data) => {
  return {
    type: types.GET_ALLREADY_REQUESTED_PLUS_REPORTS,
    payload: data
  }
}

const deselectPlayer = (playerId) => {
  return {
    type: types.DESELECT_PLAYER,
    payload: {},
  };
};

const searchPlayer = (key) => {
  return {
    type: types.SEARCH_PLAYER,
    payload: {
      key,
    },
  };
};

const resetSearch = () => {
  return {
    type: types.RESET_SEARCH,
  };
};

const filterByStatus = (filter) => {
  return {
    type: types.FILTER_BY_STATUS,
    payload: {
      filter,
    },
  };
};

const filterByRisk = (filter) => {
  return {
    type: types.FILTER_BY_RISK,
    payload: {
      filter,
    },
  };
};

const filterByConsent = (filter) => {
  return {
    type: types.FILTER_BY_CONSENT,
    payload: {
      filter,
    },
  };
};

const filterByTeam = (filter) => {
  return {
    type: types.FILTER_BY_TEAM,
    payload: {
      filter,
    },
  };
};

const filterByConference = (filter) => {
  return {
    type: types.FILTER_BY_CONFERENCE,
    payload: {
      filter,
    },
  };
};

const filterBySport = (filter) => {
  return {
    type: types.FILTER_BY_SPORT,
    payload: {
      filter
    }
  }
}

const getAllFiltersCandidates = (model) => {
  return async (dispatch) => {
    try {

      dispatch({
        type: types.GET_FILTER_CANDIDATES_REQUEST
      })

      let response = await playerApi.getFilterAthletes(model)

      // dispatch({
      //   type: types.GET_CANDIDATES_SUCCESS,
      //   payload: response
      // })

      dispatch({
        type: types.GET_FILTER_CANDIDATES_SUCCESS,
        payload: response
      })

    }
    catch (err) {
      dispatch(showErrorMessage(err.message))
      dispatch({
        type: types.GET_FILTER_CANDIDATES_FAILED
      })
    }
  }
}

const updateFilterValue = (key, value) => {
  return {
    type: types.UPDATE_FILTER_VALUE,
    payload: {
      key, value
    },
  };
};

const resetFilterValue = () => {
  return {
    type: types.RESET_FILTER_VALUE,
    payload: {}
  };
};
const resetSelectdFilterValue = () => {
  return {
    type: types.RESET_SELECTED_FILTER_VALUE,
    payload: {}
  };
};

const isFilteredData = (value) => {
  return {
    type: types.IS_FILTERED_DATA,
    payload: value
  };
};

const getAllFiltersPlayers = (model) => {
  return async (dispatch) => {
    try {

      dispatch({
        type: types.GET_FILTER_PLAYERS_REQUEST
      })

      let response = await playerApi.getFilterPlayers(model)

      dispatch({
        type: types.GET_FILTER_PLAYERS_SUCCESS,
        payload: response
      })

    }
    catch (err) {
      dispatch(showErrorMessage(err.message))
      dispatch({
        type: types.GET_FILTER_PLAYERS_FAILED
      })
    }
  }
}

const updateTeamFilterValue = (key, value) => {
  return {
    type: types.UPDATE_TEAM_FILTER_VALUE,
    payload: {
      key, value
    },
  };
};

const resetTeamFilterValue = () => {
  return {
    type: types.RESET_TEAM_FILTER_VALUE,
    payload: {}
  };
};

const isTeamFilteredData = (value) => {
  return {
    type: types.IS_TEAM_FILTERED_DATA,
    payload: value
  };
};

const postReqPlanReports = (model) => {
  return async (dispatch) => {
    try {

      dispatch({
        type: types.GET_REQUEST_PLAN_REPORTS
      })

      let response = await playerApi.postRequestPlanReports(model)

      dispatch({
        type: types.GET_REQUEST_PLAN_REPORTS_SUCCESS,
      })

      dispatch(savePlusReportData(response))
      dispatch(submitedPlusReport(true))

    }
    catch (err) {
      dispatch(showErrorMessage(err.message))
      dispatch({
        type: types.GET_REQUEST_PLAN_REPORTS_FAILED
      })
    }
  }
}

const individualAthleteFormSubmited = () => {
  return {
    type: types.INDIVIDUAL_ATHLETE_FORM_SUBMITTED,
    payload: true
  };
};

const individualAthleteFormNotSubmited = () => {
  return {
    type: types.INDIVIDUAL_ATHLETE_FORM_NOT_SUBMITTED,
    payload: false,
  };
};

const deletePsychSummaryComments = (data, prevData) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_DELETE_PSYCH_SUMMERY_COMMENT,
        payload: {
          playerId: data.candidate_id,
          category: data.category,
          _id: data._id
        },
      });

      await playerApi.deleteExecutiveSummeryComment(data);

      dispatch({
        type: types.DELETE_PSYCH_SUMMERY_COMMENT_SUCCESS,
        payload: {
          playerId: data.candidate_id,
          categoryId: data.category,
          _id: data._id,
          prevData: prevData
        },
      });
      dispatch(showSuccessMessage("Comment deleted successfully"));
      // successCb();
    } catch (err) {
      dispatch(showErrorMessage(err.message));
    }
  };
};


export function playerSortingType(data) {
  return {
    type: types.SELECTED_SORTING_TYPE,
    payload: data
  }
}

export function playerSortOrder(data) {
  return {
    type: types.SELECTED_SORT_ORDER,
    payload: data
  }
}
const getLitePlayerDetails = (playerId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_GET_LITE_PLAYER,
        payload: {
          playerId,
        },
      });

      const response = await playerApi.getLitePlayerDetails(playerId);

      dispatch({
        type: types.GET_LITE_PLAYER_SUCCESS,
        payload: {
          ...response,
          playerId,
        },
      });
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.GET_LITE_PLAYER_FAILED,
        payload: {
          error: "Fetch player failed",
        },
      });
    }
  };
};

const TwitterInfo = (candidateId, requestUserId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_GET_TWITTER_INFO,
      });
      const players = await playerApi.Twitter(candidateId, requestUserId);
      dispatch({
        type: types.GET_TWITTER_INFO_SUCCESS,
        payload: players,
      });
      dispatch(getLitePlayerDetails(candidateId))
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.GET_TWITTER_INFO_FAILED,
        payload: {
          error: "Fetch players failed",
        },
      });
    }
  };
};
const Big5Info = (candidateId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_GET_BIG5_INFO,
      });
      const players = await playerApi.Big5Info(candidateId);
      dispatch({
        type: types.GET_BIG5_INFO_SUCCESS,
        payload: players,
      });
      dispatch(getLitePlayerDetails(candidateId))
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.GET_BIG5_INFO_FAILED,
        payload: {
          error: "Fetch players failed",
        },
      });
    }
  };
}; const PsychUpdateInfo = (candidateId, statusId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_GET_PSYCH_INFO,
      });
      const players = await playerApi.PsychReviewUpdate(candidateId, statusId);
      dispatch({
        type: types.GET_PSYCH_INFO_SUCCESS,
        payload: players,
      });
      dispatch(getLitePlayerDetails(candidateId))
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.GET_PSYCH_INFO_FAILED,
        payload: {
          error: "Fetch players failed",
        },
      });
    }
  };
}; const InstagramInfo = (candidateId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_GET_INSTAGRAM_INFO,
      });
      const players = await playerApi.Instagram(candidateId);
      dispatch({
        type: types.GET_INSTAGRAM_INFO_SUCCESS,
        payload: players,
      });
      dispatch(getLitePlayerDetails(candidateId))
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.GET_INSTAGRAM_INFO_FAILED,
        payload: {
          error: "Fetch players failed",
        },
      });
    }
  };
}; const SentimentInfo = (candidateId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_GET_SENTIMENT_INFO,
      });
      const players = await playerApi.SentimentScore(candidateId);
      dispatch({
        type: types.GET_SENTIMENT_INFO_SUCCESS,
        payload: players,
      });
      dispatch(getLitePlayerDetails(candidateId))
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.GET_SENTIMENT_INFO_FAILED,
        payload: {
          error: "Fetch players failed",
        },
      });
    }
  };
};

const getVideoTranscript = (model, successCb = (f) => f) => {
  return async (dispatch) => {
    try {

      dispatch({
        type: types.GET_VIDEO_TRANSCRIPT_REQUEST
      })

      let response = await playerApi.getVideoTranscript(model)

      dispatch({
        type: types.GET_VIDEO_TRANSCRIPT_SUCCESS,
        payload: response
      })
      successCb();
      // dispatch(getLitePlayerDetails(model.candidate_id))
    }
    catch (err) {
      dispatch(showErrorMessage(err.message))
      dispatch({
        type: types.GET_VIDEO_TRANSCRIPT_FAILED
      })
    }
  }
}

const ApprovePlayer = (model) => {
  return async (dispatch) => {
    try {

      dispatch({
        type: types.GET_APPROVE_PLAYER
      })

      let response = await playerApi.approvePlayer(model)

      dispatch({
        type: types.GET_APPROVE_PLAYER_SUCCESS,
        payload: response
      })
      dispatch(getLitePlayerDetails(model.candidate_id))
    }
    catch (err) {
      dispatch(showErrorMessage(err.message))
      dispatch({
        type: types.GET_APPROVE_PLAYER_FAILED
      })
    }
  }
}

const PlusReport = (candidateId) => {
  return async (dispatch) => {
    try {

      dispatch({
        type: types.GET_PLUS_REPORT
      })

      let response = await playerApi.plusReport(candidateId)

      dispatch({
        type: types.GET_PLUS_REPORT_SUCCESS,
        payload: response
      })
      dispatch(getLitePlayerDetails(candidateId))
    }
    catch (err) {
      dispatch(showErrorMessage(err.message))
      dispatch({
        type: types.GET_PLUS_REPORT_FAILED
      })
    }
  }
}

const removeFeed = (playedId) => {
  return async (dispatch) => {
    try {

      dispatch({
        type: types.GET_REMOVE_FEED
      })

      let response = await playerApi.removeFeed(playedId)

      dispatch({
        type: types.GET_REMOVE_FEED_SUCCESS,
        payload: response
      })
      dispatch(getLitePlayerDetails(playedId))
    }
    catch (err) {
      dispatch(showErrorMessage(err.message))
      dispatch({
        type: types.GET_REMOVE_FEED_FAILED
      })
    }
  }
}


const getReceptivitiBig5Score = (id, type, onSucess) => {
  return async (dispatch) => {
    try {

      dispatch({
        type: types.GET_RECEPTIVITI_SCORE
      })

      let response = await playerApi.getReceptivitiBig5Score(id, type)

      dispatch({
        type: types.GET_RECEPTIVITI_SCORE_SUCCESS,
        payload: response
      })

      const responseData = response && response?.data && response.data?.info
      const errorModel = responseData && responseData["errorModel"] && responseData["errorModel"]
      const finalResponse = errorModel && errorModel["results"] && errorModel["results"]

      if (finalResponse !== undefined && "error" in (finalResponse && finalResponse[0] && finalResponse[0])) {
        dispatch(showErrorMessage(finalResponse[0]["error"]["message"]))
      } else {
        if (type === 'player') {
          dispatch(getPlayer(id));
        }
        else if (type === 'coach') {
          dispatch(coachActions.getCoach(id))
        }
        onSucess();
      }
    }
    catch (err) {
      dispatch(showErrorMessage(err.message))
      dispatch({
        type: types.GET_RECEPTIVITI_SCORE_FAILED
      })
    }
  }
}
const playerActions = {
  removeFeed,
  getLitePlayerDetails,
  PlusReport,
  ApprovePlayer,
  getVideoTranscript,
  TwitterInfo,
  InstagramInfo,
  PsychUpdateInfo,
  SentimentInfo,
  Big5Info,
  updateFilterValue,
  resetFilterValue,
  isFilteredData,
  selectPlayer,
  deselectPlayer,
  searchPlayer,
  filterByStatus,
  filterByRisk,
  filterByConsent,
  filterByTeam,
  filterByConference,
  filterBySport,
  getPlayerList,
  getPlayer,
  getPlayerFeed,
  getTeamList,
  updateNotes,
  uploadPlayers,
  createPlayer,
  createCoach,
  loadingFeed,
  exportFeed,
  resetSearch,
  exportReport,
  exportSystemReport,
  exportPsycologistReport,
  addRiskComment,
  deleteRiskComment,
  addActionPlanComment,
  addExecutiveSummeryComment,
  deleteExecutiveSummeryComment,
  deleteActionPlanComment,
  approveCandidateProfile,
  rejectCandidateProfile,
  markReviewCompleted,
  markReviewIncomple,
  addActionPlanContent,
  addExecutiveSummeryContent,
  markCandidateAsPaid,
  markCandidateAsUnpaid,
  addCandidateLink,
  deleteCandidateLink,
  regenerateSystemReport,
  regeneratePsychReport,
  toggleFullReport,
  toggleActionPlanReport,
  toggleExecutiveSummaryReport,
  exportFullReport,
  exportActionPlanReport,
  exportExecutiveSummaryReport,
  regenerateFullReport,
  regenerateActionPlanReport,
  regenerateExecutiveSummaryReport,
  updateRiskScore,
  getAllCandidates,
  getAllFiltersCandidates,
  updateTeamFilterValue,
  resetTeamFilterValue,
  isTeamFilteredData,
  getAllFiltersPlayers,
  postReqPlanReports,
  individualAthleteFormSubmited,
  individualAthleteFormNotSubmited,
  markAsReviewSubmitted,
  deletePsychSummaryComments,
  resetSelectdFilterValue,
  submitedPlusReport,
  savePlusReportData,
  postStartAnalyze,
  postAnalyzeResults,
  postSentimentResults,
  resetSentimentResults,
  playerSortingType,
  playerSortOrder,
  getReceptivitiBig5Score
};

export default playerActions;
