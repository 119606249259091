import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { FixedSizeList as List } from "react-window";
import { ActionContext } from "../../../../contexts";
import { Article } from "../../../shared/Article";
import CircularProgress from "../../../shared/CircularProgress";
import { TabPanel } from "../../../shared/Tabs";
import FeedBottomNavigation from "./FeedBottomNavigation";
import FeedExport from "./FeedExport";
import FeedItem from "./FeedItem";
import allFeedData from "modules/dashboard/components/FeedModelCard"
import { parseFeedResponse } from "../../../../api/responseParser";
import Grid from '@material-ui/core/Grid';
import NoData from '../../../../assets/NoData.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
  },
  loading: {
    marginTop: 48,
  },
  list: {
    listStyle: "none",
    "&::-webkit-scrollbar": {
      width: 8,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.background.default,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[300],
      opacity: 0.5,
    },
  },
  placeholder: {
    // height: 150,
    // width: 660,
    display: "block",
    width: '100%',
    textAlign: 'center',
    margin: '1rem 0rem',
    justifyContent: "center",
    alignItems: " center",
  },
}));

const FeetItem = (cutomProps) => (props) => {
  return <FeedItem {...cutomProps} {...props} />;
};

const getTabType = (tabIndex) => {
  switch (tabIndex) {
    case 0:
      return ["positives", "Positives"];

    case 1:
      return ["neutrals", "Neutrals"];

    case 2:
      return ["negatives", "Negatives"];

    default:
      return "";
  }
};

const FeedPanel = (props) => {
  let classes = useStyles();
  let { playerActions } = useContext(ActionContext);
  let {
    playerId,
    source,
    feed,
    loadingPlayerFeed,
    index,
    value,
    accountType,
    stats,
    exportingFeed,
    playerName,
    loadSentimentAnalysis,contactInfo
  } = props;


  let [bottomTab, setBottomTab] = useState(0);

  let [type, label] = getTabType(bottomTab);
  // let selectedFeed = Object.keys(feed[type]);
  let selectedFeed = feed[type];

  let handleBottomTabChange = (event, newValue) => {
    setBottomTab(newValue);
  };

  useEffect(() => {
      playerActions.getPlayerFeed(playerId, source);
  }, [source]);



  return (
    <TabPanel value={value} index={index}>
      <div className={classes.root}>
        {loadingPlayerFeed ? (
          <div className={classes.loading}>
            <CircularProgress size={72} thickness={2} color="primary" />
          </div>
        ) : (
          <React.Fragment>
            <Article
              title={label}
              classes={{ heading: classes.heading }}
              button={
                <FeedExport
                  playerId={playerId}
                  playerName={playerName}
                  source={source}
                  accountType={accountType}
                  loading={exportingFeed}
                  isDisabled={false}//selectedFeed.length === 0}
                  team={contactInfo.team}
                />
              }
            >
              {selectedFeed.length > 0 ? (
                <Grid container spacing={3}>

                  <Grid item xs={12} sm={12}>
                    <List
                      height={400}
                      // width={663}
                      itemCount={selectedFeed.length}
                      className={classes.list}
                      itemData={selectedFeed}
                      itemSize={100}
                    >
                      {FeetItem({ type, playerId, source })}
                    </List>
                  </Grid>


                </Grid>

              ) : (
                <div>
                  <div className={classes.placeholder}>
                    <img src={NoData} style={{ width: '11rem' }} />
                    <div style={{ color: '#0971ce' }}>  No Data</div>
                  </div>
                </div>
              )}

              <FeedBottomNavigation
                tab={bottomTab}
                stats={stats}
                onChange={handleBottomTabChange}
              />
            </Article>
          </React.Fragment>
        )}
      </div>
    </TabPanel>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { playerId, source } = ownProps;

  return {
    exportingFeed:
      state.ui.player.exportingFeed[`${playerId}.${source}`] || false,
    loadingPlayerFeed:
      state.ui.player.loadingPlayerFeed[source][playerId] || false,
    feed: state.player.feed[source][playerId] || {
      negatives: [],
      positives: [],
      neutrals: [],
      negativesSorted: [],
      positivesSorted: [],
      neutralsSorted: [],
    },
    accountType: state.account.accountType,
    stats: state.player.stats[playerId][source] || {
      negativePercentage: "nodata",
      neutralPercentage: "nodata",
      positivePercentage: "nodata",
    },
    playerName: state.player.basic[playerId]["name"],
    loadSentimentAnalysis:
      state.ui.player.loadingSentimentAnalysisStatus[playerId] || false,
    contactInfo: state.player.contact[ownProps.playerId],

  };
};
export default connect(mapStateToProps)(FeedPanel);
