import React, { useState, useContext, useRef } from 'react'
import { Button, Grid } from '@material-ui/core'
import { connect, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CreateCandidateStepper from "./CreateCandidate"
import { ActionContext } from "../../contexts";
import { useHistory, useLocation } from "react-router-dom";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FinalPage from './CreateCandidate/FinalPage';
import runDashedLogo from "../../assets/RunDashedIcon.svg";
import CircularProgress from "../shared/CircularProgress";
import clsx from "clsx";

let useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        display: "flex",
        height: `calc(100vh - 50px)`,
        zIndex: theme.zIndex.appBar,
    },
    container: {
        padding: 16,
        paddingTop: 48,
        marginLeft: "2rem",
        marginRight: "auto",
        flexWrap: "wrap",
        width: '100%',
        [theme.breakpoints.down("md")]: {
            maxWidth: 550,
        },
    },
    toolbar: {
        display: "flex",
        backgroundColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    btnType: {
        // border: '1px solid',
        // padding: "1rem 5rem 1rem 5rem",
        borderRadius: '6px',
        color: "white",
        width: '12rem'
    },
    btnType2: {
        borderRadius: '6px',
        color: "#000",
        width: '12rem'
    },
    btnPrimary: {
        color: theme.palette.primary.main,
        border: '1px solid',
        height: '30px',
        padding: '10px',
        margin: '0px 10px 10px 0px',
        borderRadius: '20px',
        '&:hover': {
            background: theme.palette.primary.main,
            color: '#fff'
        },
    },
    btnSecondary: {
        color: theme.palette.secondary.main,
        border: '1px solid',
        height: '30px',
        padding: '10px',
        marginRight: '10px',
        borderRadius: '20px',
        '&:hover': {
            background: theme.palette.secondary.main,
            color: '#fff'
        }
    },
    fileBtn: {
        border: "1px solid #ccc",
        display: "inline-block",
        padding: "8px 50px",
        cursor: "pointer",
        borderRadius: '20px',
        color: 'white',
        backgroundColor: '#228B22',
        justifyContent: 'center',
        alignItems: 'center',
    },
    fileDisplay: {
        // display: "flex",
        // flexDirection : 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: "3rem 0rem 3rem 0rem"

    },
    tempBtn: {
        backgroundColor: "#eaf4fb",
        width: "35rem",
        padding: "1rem",
        margin: "20px",
    },
    SubmitAthlet: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#eeeef0',
        width: 'fit-content',
        borderRadius: '0.5rem',
        padding: '0.2rem 0.2rem',

    },
    tempBtn1: {
        backgroundColor: "#eaf4fb",
        width: "35rem",
        padding: "1rem",
        margin: "20px",
    },
    loading: {
        justifyContent: "center",
        alignItems: "center",
      },
}));

function CreateAthlete(props) {
    let classes = useStyles();
    let { isDrawerOpen, accountType, isAthleteFormSubmit, uploadingPlayers } = props;
    const [selecteCreateType, setSelectCreateType] = useState("one")
    const [fileData, setFileData] = useState()
    let history = useHistory();
    let fileInputRef = useRef();

    // let formData = new FormData()

    let { playerActions } = useContext(ActionContext);

    const handleCreateType = (type) => {
        setSelectCreateType(type)
        if (type === "many") {
            handleUploadPlayerClick()
        }
    }

    let handleUploadPlayerClick = () => {
        fileInputRef.current.click();
    };

    let handleFileSelect = (e) => {
        // e.preventDefault();
        // let data = new FormData();
        // data.append("players_file", e.target.files[0]);

        if (e.target && e.target.files[0]) {
            // formData.append("players_file", e.target.files[0]);
            setFileData(e.target.files[0])
        }
    };

    if (uploadingPlayers && uploadingPlayers === true) {
        return (
          <div className={clsx(classes.root, classes.loading)}>
            <CircularProgress size={72} thickness={3} color="primary" />
          </div>
        );
      }

    const handleSubmit = () => {
        let formData = new FormData();
        formData.append("players_file", fileData);
        const cb = () => {
            return () => {
                fileInputRef.current.value = null;
                // fn();
                playerActions.individualAthleteFormSubmited()
                // setFileData({})
            };
        };
        playerActions.uploadPlayers(
            formData,
            cb()
        );
    }


    return (
        <React.Fragment>
            <div className={classes.root}>
                <div className={classes.container}>
                    {
                        (isAthleteFormSubmit === false) ?
                            <>
                                <div className={classes.SubmitAthlet}>
                                    <Button className={classes.btnType} style={{ backgroundColor: selecteCreateType === "one" ? "#326fc7" : "#eeeef0", color: selecteCreateType === "one" ? "#fff" : "#000" }} onClick={() => handleCreateType("one")}>Individual</Button>
                                    <Button className={classes.btnType2} style={{ backgroundColor: selecteCreateType === "many" ? "#326fc7" : "#eeeef0", color: selecteCreateType === "many" ? "#fff" : "#000" }} onClick={() => handleCreateType("many")}>Bulk</Button>
                                </div>
                                <div>
                                    {
                                        selecteCreateType === "one" ?
                                            <CreateCandidateStepper />
                                            :
                                            <div className={classes.fileDisplay}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={8}>

                                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '0rem 2rem' }}>
                                                            <label>Step 1</label>

                                                            <span className={classes.tempBtn}>
                                                                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
                                                                    Refer below template to be uploaded to avoid error.
                                                                </div>
                                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                    <span className={classes.fileBtn}>
                                                                        <a style={{ color: '#fff' }} href={process.env.PUBLIC_URL + "/Sample_BulkAthlete_File.csv"} download="sampleBulkAthlete.csv">Download Template</a>
                                                                    </span>
                                                                </div>
                                                            </span>
                                                            <span style={{ marginTop: '10px' }}><ArrowDropDownIcon style={{ fontSize: '50px' }} /></span>
                                                            <label>Step 2</label>

                                                            <span style={{ display: 'flex', justifyContent: 'center' }} className={classes.tempBtn1}>
                                                                <label >
                                                                    Update information as per the template
                                                                </label>
                                                            </span>

                                                            <span><ArrowDropDownIcon style={{ fontSize: '50px' }} /></span>
                                                            <label>Step 3</label>

                                                            <div className={classes.tempBtn}>
                                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                    <label for="file-upload" className={classes.fileBtn}>
                                                                        Browse
                                                                    </label>
                                                                    <input
                                                                        type="file"
                                                                        id="file-upload"
                                                                        accept=".csv"
                                                                        ref={fileInputRef}
                                                                        onChange={handleFileSelect}
                                                                        style={{ display: "none" }}
                                                                    />
                                                                </div>
                                                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                                                    Update registration template.
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{ display: 'flex', justifyContent: 'flex-end', }}>
                                                            <Button className={classes.btnPrimary} disabled={!fileData} onClick={handleSubmit}>Submit</Button>
                                                        </div>
                                                    </Grid>
                                                    <Grid item xs={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <img
                                                                style={{ width: '70%' }}
                                                                src={runDashedLogo}
                                                                alt="logo"
                                                            />
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                    }
                                </div>
                            </>
                            :
                            <FinalPage selecteCreateType={selecteCreateType} />
                    }
                </div>
            </div>
        </React.Fragment >
    )
}


let mapStateToProps = (state) => {
    return {
        accountType: state.account.accountType,
        isAthleteFormSubmit: state.ui.player.isAthleteFormSubmit,
        uploadingPlayers : state.ui.player.uploadingPlayers
    }
}

export default connect(mapStateToProps)(CreateAthlete)