import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { Formik } from "formik";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { object, string } from "yup";
import { ActionContext } from "../../../contexts";
import { errorProps } from "../../../utils";
import Button from "../../shared/Button";
import TextField from "../../shared/TextField";

let forgotPasswordSchema = object().shape({
  email: string().email().required("Enter email address"),
});

let useStyles = makeStyles((theme) => ({
  root: {},
  heading: {
    display: "flex",
    flexDirection: "column",
  },
  alert: {
    marginBottom: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(4),
    fontWeight: 600,
  },
  inputs: {
    paddingBottom: theme.spacing(2),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },
  link: {
    cursor: "pointer",
  },
  panel: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
}));

let ForgotPassword = () => {
  let classes = useStyles();
  let history = useHistory();
  let actions = useContext(ActionContext);
  let forgotState = useSelector((state) => state.ui.account.forgot);

  let { error } = forgotState;

  let handleRedirectToSignin = () => {
    history.push("/signin");
  };

  let handleFormSubmit = (values) => {
    // let { email } = values.email;
    let data = {
      email: values.email,
    };

    let { accountActions } = actions;

    accountActions.forgotPassword(data, handleRedirectToSignin);
  };

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <Typography variant="h5" className={classes.title}>
          Reset Password
        </Typography>
        {error ? (
          <div className={classes.alert}>
            <Alert severity="error">{error}</Alert>
          </div>
        ) : null}
      </div>
      <div className={classes.main}>
        <Formik
          initialValues={{ email: "" }}
          validationSchema={forgotPasswordSchema}
          onSubmit={handleFormSubmit}
        >
          {(formik) => {
            let {
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched,
            } = formik;
            return (
              <form onSubmit={handleSubmit} noValidate>
                <div className={classes.inputs}>
                  <Grid container spacing={3}>
                    <Grid item sm={12}>
                      <TextField
                        name="email"
                        label="Email Address"
                        placeholder="Email Address"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        required
                        autoFocus
                        {...errorProps(errors, touched, "email")}
                      />
                    </Grid>
                  </Grid>
                </div>

                <div className={classes.buttons}>
                  <Button variant="text" onClick={handleRedirectToSignin}>
                    Sign in
                  </Button>
                  <Button type="submit" color="primary" disableElevation>
                    Submit
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default ForgotPassword;
