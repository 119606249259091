import React from "react";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";
import VerifiedIcon from "@material-ui/icons/CheckCircle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { formatDate } from "../../../../utils";
import { capitalize } from "../../../../utils";
import { makeStyles } from "@material-ui/core/styles";

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  avatarContainer: {
    marginRight: 8,
  },
  avatar: {
    width: 36,
    height: 36,
  },
  infoItem: {
    padding: 0,
  },
  icon: {
    color: theme.palette.primary.main,
    marginLeft: 4,
  },
  name: {
    display: "flex",
    alignItems: "center",
  },
}));

let InfoItem = (props) => {
  let classes = useStyles();
  let { label, value } = props;

  return (
    <ListItem className={classes.infoItem}>
      <ListItemText
        primary={label}
        secondary={value || "n/a"}
        primaryTypographyProps={{
          color: "textSecondary",
          variant: "caption",
        }}
        secondaryTypographyProps={{ color: "textPrimary" }}
      />
    </ListItem>
  );
};

let PlayerDuplicateInfo = (props) => {
  let classes = useStyles();
  let { player, original = false } = props;

  return (
    <Tooltip
      title={original ? "Original Record" : "Possible Duplicate Record"}
      placement="right"
    >
      <div className={classes.root}>
        <div className={classes.avatarContainer}>
          <Avatar className={classes.avatar} />
        </div>
        <div>
          <div className={classes.name}>
            <Typography variant="subtitle2">
              {capitalize(player.name)}
            </Typography>
            {original ? (
              <VerifiedIcon fontSize="small" className={classes.icon} />
            ) : null}
          </div>

          <List dense>
            <InfoItem label="Birthday" value={formatDate(player.birthday)} />
            <InfoItem label="Email" value={player.email} />
            <InfoItem label="Team" value={player.team} />
            {original ? (
              <React.Fragment>
                <InfoItem
                  label="Created Date"
                  value={
                    (player.createdDate && formatDate(player.createdDate)) ||
                    "Unavailable"
                  }
                />
                <InfoItem
                  label="Reviewed Date"
                  value={
                    (player.reviewedDate && formatDate(player.reviewedDate)) ||
                    "Unavailable"
                  }
                />
              </React.Fragment>
            ) : null}
          </List>
        </div>
      </div>
    </Tooltip>
  );
};

export default PlayerDuplicateInfo;
