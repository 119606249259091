import React, { useContext, useEffect } from 'react'
import { Button, Box, FormControl, FormGroup, FormControlLabel, Checkbox, FormLabel, Chip, InputLabel, MenuItem, Radio, RadioGroup, Select } from "@material-ui/core"
import { connect } from "react-redux";
import { ActionContext } from "../../../contexts";
import { makeStyles } from "@material-ui/core/styles";

let useStyles = makeStyles((theme) => ({
    btnPrimary: {
        color: theme.palette.primary.main,
        border: '1px solid',
        height: '30px',
        padding: '10px',
        marginRight: '10px',
        borderRadius: '20px',
        '&:hover': {
            background: theme.palette.primary.main,
            color: '#fff'
        },
    },
    btnSecondary: {
        color: theme.palette.secondary.main,
        border: '1px solid',
        height: '30px',
        padding: '10px',
        marginRight: '10px',
        borderRadius: '20px',
        '&:hover': {
            background: theme.palette.secondary.main,
            color: '#fff'
        }
    },
}));

function Filter(props) {

    const { pageChange,  sportsData, unitData, sportPosition,  loadingFilters, updateTeamFilter } = props;
    let classes = useStyles();
    let actions = useContext(ActionContext);
    let { playerActions, sportsActions } = actions;

    const [state, setState] = React.useState({
        sport: "",
        unit: "",
        position: "",
        athleteType: "",
    });

    useEffect(() => {
        if (updateTeamFilter) {
            setState(updateTeamFilter);
        }
    }, [updateTeamFilter]);

    const recruit = [
        {
            "name": "Rostered Player",
            "id": "Rostered Player"
        },
        {
            "name": "Potential Recruit",
            "id": "Potential Recruit"
        }
    ]

    const handlePlayerChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
        playerActions.updateTeamFilterValue(event.target.name, event.target.value)
    }


    const handleSportChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
        playerActions.updateTeamFilterValue(event.target.name, event.target.value)
        sportsActions.getSportUnitDetails(event.target.value);
    }

    const handleUnitChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
        playerActions.updateTeamFilterValue(event.target.name, event.target.value)
        sportsActions.getSportPositionDetails(event.target.value, state.sport);
    }

    const handleCancel = () => {
        playerActions.resetTeamFilterValue()
    }

    const handleSave = () => {
        let model = {
            sport: state.sport ? [state.sport] : [],
            unit: state.unit ? [state.unit] : [],
            position: state.position ? [state.position] : [],
            athleteType: state.athleteType ? [state.athleteType] : [],
        }

        playerActions.getAllFiltersPlayers(model)

    }


    useEffect(() => {
        sportsActions.getSportsList();
    }, []);

    useEffect(() => {
        if (loadingFilters === true) {
            pageChange('list')
            playerActions.isTeamFilteredData(true)
        }

    }, [loadingFilters]);

    return (
        <React.Fragment>
            <div>
                <div style={{ display: 'flex', justifyContent: 'flex-start', fontWeight: 'bold', fontSize: "20px" }}>
                    Filter
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-start', fontWeight: 'bold', fontSize: "16px" }}>
                    Select all that are necessary
                </div>
                <div style={{ display: 'flex' }}>
                    <FormControl variant="filled" sx={{ m: 1, minWidth: 200 }}>
                        <InputLabel id="demo-simple-select-autowidth-label">Athlete Type</InputLabel>
                        <Select
                            style={{ width: "200px" }}
                            labelId="demo-simple-select-autowidth-label"
                            id="demo-simple-select-autowidth"
                            value={state.athleteType}
                            onChange={handlePlayerChange}
                            label="Athlete Type"
                            name="athleteType"
                        >
                            {
                                recruit && recruit.map(list =>
                                    <MenuItem key={list.id} value={list.id}>{list.name}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>

                    <div>

                        <FormControl variant="filled">
                            <InputLabel id="demo-simple-select-autowidth-label">Sport</InputLabel>
                            <Select
                                style={{ width: "200px" }}
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={state.sport}
                                onChange={handleSportChange}
                                label="Sport"
                                name="sport"
                            >
                                {
                                    sportsData && sportsData.map(list =>
                                        <MenuItem value={list.name}>{list.name}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <div>
                        <FormControl variant="filled" sx={{ m: 1, minWidth: 80 }}>
                            <InputLabel id="demo-simple-select-autowidth-label">Unit</InputLabel>
                            <Select
                                style={{ width: "200px" }}
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={state.unit}
                                onChange={handleUnitChange}
                                autoWidth
                                label="Unit"
                                name="unit"
                            >
                                {
                                    unitData && unitData.map(list =>
                                        <MenuItem value={list.unit}>{list.unit}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </div>

                    <div>
                        <FormControl variant="filled" sx={{ m: 1, minWidth: 80 }}>
                            <InputLabel id="demo-simple-select-autowidth-label">Position</InputLabel>
                            <Select
                                style={{ width: "200px" }}
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={state.position}
                                onChange={handlePlayerChange}
                                autoWidth
                                label="Position"
                                name="position"
                            >
                                {
                                    sportPosition && sportPosition.map(list =>
                                        <MenuItem value={list.position}>{list.position}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </div>
                </div>

                <div>
                    <Button className={classes.btnSecondary} onClick={handleCancel}>
                        Clear
                    </Button>
                    <Button className={classes.btnPrimary} onClick={handleSave}>
                        Run Report
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}


let mapStateToProps = (state) => {
    return {
        sportsData: state.sports.sportsListData,
        unitData: state.sports.unitSport,
        sportPosition: state.sports.sportPosition,
        loadingFilters: state.ui.player.loadingPlayerFilters,
        updateTeamFilter: state.player.updateTeamFilter,
        isTeamFiltered: state.player.isTeamFiltered,
    };
};
export default connect(mapStateToProps)(Filter);