import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import '../../../index.css';

let useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    // width: "100%",
    // maxWidth: 700,

  },
  article: {
    // border: '1px solid #ffd201',
    borderRadius: '9px',
    fontFamily: 'Prometo',
    marginBottom: '0.8rem',
    // margin: theme.spacing(2, 1, 1, 1),
    // display: "flex",
    // flexDirection: "column",
    // width: "100%",
    // maxWidth: 700,
    // borderRadius: 8,
  },
  articleHeading: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 2, 1, 2),

  },
}));

const Article = ({
  children,
  title,
  classes = {},
  icon = null,
  button = null,
}) => {
  let classNames = useStyles();

  return (
    <div className={clsx(classNames.root, classes.root)}>
      <div>
        <Paper
          square
          elevation={0}
          variant="outlined"
          className={clsx(classNames.article, classes.article)}
        >
          {title ? (
            // <div className={clsx(classNames.articleHeading, classes.heading)}>
            <div style={{
              display: 'flex',
              margin: '0rem 1rem',
              marginTop: '1rem',
              justifyContent: 'space-between',
            }}>
              {icon}
              <Typography
                variant="h6"
                style={{ textTransform: "capitalize", fontFamily: 'Prometo', overflow: "auto" }}
                component="p"
                align="center"
              >
                {title}
              </Typography>
              {button}
            </div>
          ) : null}
          {children}
        </Paper>
      </div>
    </div>
  );
};

export default Article;
