import React from "react";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/RateReviewOutlined";
import Editor from "./Editor";
import { Article } from "../Article";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/DeleteOutline";

let useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    padding: theme.spacing(2),
  },
  editorContainer: {
    width: "100%",
  },
  editor: {
    // minHeight: 100,
  },
  editcontrol: {
    // width: 40,
    // minWidth: 40,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    // borderLeft: "1px solid #f8f8f8",
  },
}));

let EditorArticle = (props) => {
  let classes = useStyles();

  let {
    notes,
    playerId,
    isEditable = false,
    isDelete, id,
    onEdit = (f) => f,
    onDelete = (f) => f,
    currentData = [],
    accountType,
    ...rest
  } = props;

  let handleEditClick = () => {
    onEdit();
  };
  let handleDelete = (id) => {
    return () => onDelete(id);
  };
 
  return (
    <Article>
      <div className={classes.container}>
        <div className={classes.editorContainer}>
          <Editor
            classes={{ editor: classes.editor }}
            readOnly={true}
            toolbarHidden
            {...rest}
          />
        </div>
        {isEditable ? (
          <div className={classes.editcontrol}>
            <IconButton onClick={handleEditClick} edge="end">
              <EditIcon fontSize="inherit" />
            </IconButton>
          </div>
        ) : null}
        {
          (accountType === "SUPER_ADMIN" || accountType === "BCA_PSYCHOLOGIST"|| accountType === "BCA_CHIEF_PSYCHOLOGIST") ?
            (isDelete ? (
              <div className={classes.editcontrol}>
                <IconButton edge="end" onClick={handleDelete(id)}>
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </div>
            ) : null) : ""}
      </div>
    </Article>
  );
};
export default EditorArticle;
