import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import MailIcon from "@material-ui/icons/Mail";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

let useStyles = makeStyles((theme) => ({
  root: {},
}));

let NotificationListItem = (props) => {
  let classes = useStyles();
  let { notification, divider } = props;
  let isInvisible = notification.status !== "Unread";

  return (
    <ListItem divider={divider} className={classes.root}>
      <ListItemIcon>
        <Badge
          color="primary"
          badgeContent={" "}
          variant="dot"
          invisible={isInvisible}
        >
          <MailIcon />
        </Badge>
      </ListItemIcon>
      <ListItemText
        primary={notification.title}
        secondary={notification.content}
      />
      <ListItemSecondaryAction>
        <Typography variant="caption" color="textSecondary">
          {moment(notification.createdOn).fromNow()}
        </Typography>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { id } = ownProps;
  return {
    notification: state.account.notifications.byId[id],
  };
};
export default connect(mapStateToProps)(NotificationListItem);
