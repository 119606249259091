import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import NeutralIcon from "@material-ui/icons/SentimentSatisfied";
import NegativeIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import PositiveIcon from "@material-ui/icons/SentimentVerySatisfied";
import { RadialBar } from "modules/shared/Charts";
import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import { round } from "utils";
import { ActionContext } from "../../../../contexts";
import { Tabs } from "../../../shared/Tabs";
import { accountTypes, chartColors } from "../../constants";
import ChartAndDetails from "../ChartAndDetails";
import ExpansionArticle from "../ExpansionArticle";
import FeedStatusCard from "../FeedStatusCard";
import ChartAndComments from "./ChartAndComments";
import RiskComments from "./RiskComments";
import RiskDialog from "./RiskDialog";

let useStyles = makeStyles((theme) => ({
  risk: {
    display: "flex",
    justifyContent: "center",
  },
}));

let roundTo1PrecisionPoint = (value) => {
  return round(value, 0);
};

let radialBarOptions = {
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "40%",
        background: "#293450",
      },
      track: {
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 4,
          opacity: 0.15,
        },
      },
      dataLabels: {
        name: {
          show: false,
          offsetY: -10,
          color: "#fff",
          fontSize: "13px",
        },
        value: {
          color: "#fff",
          fontSize: "14px",
          offsetY: 6,
          show: true,
        },
      },
    },
  },
  stroke: {
    lineCap: "butt",
  },
};

let ExecutiveSummary = (props) => {
  let classes = useStyles();
  let [selectedIndex, setSelectedIndex] = useState(0);
  let { riskCategories, playerId, accountType, customTraits, stats } = props;
  let { dialogActions } = useContext(ActionContext);
  let isEditable =
    accountType === accountTypes.bcaPsychologist ||
    accountType === accountTypes.superAdmin;

  let customTraitLabels = Object.keys(customTraits);
  let customTrait = Object.values(customTraits)[selectedIndex];

  let handleComment = (id) => {
    dialogActions.openDialog(
      <RiskComments playerId={playerId} categoryId={id} />,
      { maxWidth: "sm" }
    );
  };

  let handleRiskEdit = (categoryId) => (id, score) => {
    dialogActions.openDialog(
      <RiskDialog playerId={playerId} categoryId={categoryId} score={score} />,
      { maxWidth: "xs" }
    );
  };

  let handleTabChange = (e, index) => {
    setSelectedIndex(index);
  };

  let handleColors = (score) => {
    if (0 <= score && score < 30) {
      return chartColors.red;
    } else if (30 <= score && score < 70) {
      return chartColors.orange;
    } else if (70 <= score && score < 100) {
      return chartColors.green;
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Tabs
          labels={customTraitLabels}
          value={selectedIndex}
          onChange={handleTabChange}
        />
        <ExpansionArticle
          title={customTrait.label}
          details={customTrait.description}
        >
          <div>
            <ChartAndDetails
              diagnosis={customTrait["text"]}
              score={customTrait["score"]}
              handleColors={handleColors}
            />
            <div
              style={{
                marginBottom: 48,
                width: "100%",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              {Object.values(customTrait.sub).map((subTrait, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      maxWidth: 150,
                    }}
                  >
                    <RadialBar
                      color={handleColors(
                        roundTo1PrecisionPoint(subTrait["score"])
                      )}
                      options={radialBarOptions}
                      score={roundTo1PrecisionPoint(subTrait["score"])}
                      key={index}
                    />
                    <p style={{ textAlign: "center", marginTop: 0 }}>
                      {`+ ${subTrait["left_label"]}`}
                    </p>
                  </div>

                  // <StackedBarChart
                  //   key={index}
                  //   value={subTrait["score"]}
                  //   left={subTrait["left_label"]}
                  //   right={subTrait["right_label"]}
                  //   colors={["#007fdb", "#fcb643"]}
                  // />
                );
              })}
            </div>
          </div>
        </ExpansionArticle>
      </div>

      <div style={{ marginTop: 40 }}>
        <Typography variant="h5" align="center">
          Sentiment Analysis of Athlete’s Posts
        </Typography>
        <Typography
          variant="subtitle2"
          color="textSecondary"
          align="center"
          style={{ marginBottom: 16 }}
        >
         Percentage of the athlete's social media posts that are positive,
          neutral, or negative
        </Typography>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 8,
        }}
      >
        <FeedStatusCard
          score={stats["positivePercentage"]}
          label="Positives"
          icon={<PositiveIcon />}
        />
        <FeedStatusCard
          score={stats["neutralPercentage"]}
          label="Neutrals"
          icon={<NeutralIcon />}
        />
        <FeedStatusCard
          score={stats["negativePercentage"]}
          label="Negatives"
          icon={<NegativeIcon />}
        />
      </div>

      <div style={{ marginTop: 40, maxWidth: 700 }}>
        <Typography variant="h5" align="center">
          Potential Risk Categories
        </Typography>
        <Typography
          variant="subtitle2"
          color="textSecondary"
          align="center"
          style={{ marginBottom: 16 }}
        >
          Behavioral Risk Factors can strongly impact the physical, mental, and
          emotional framework of an individual’s overall wellbeing and
          performance outcomes. Several behaviors have been shown to exert a
          strong influence on health, wellness, and performance outcomes
          specifically: Aggression/Violence, Delinquent Behavior, Drug/Alcohol,
          Emotional Distress, and Sexual Behavior. These five behavioral risk
          factors are strongly influenced by genetics and environmental cues.
          Behaviors can be modified through behavioral interventions, social
          support, and changes in environment which can positively impact the
          wellbeing and performance outcomes for individuals
        </Typography>
      </div>
      <div className={classes.risk}>
        <ExpansionArticle
          title="Aggression/Violence"
          details="Explores one’s level of frustration, anger, and violent behavioral response. Aggression is associated with anger and violent feelings stemming from high levels of irritability, frustration, and negative emotional states that impact behavior. Violence is identified as one using physical force with the intent/desire to cause injury or harm to another individual or destruction of property."
        >
          <ChartAndComments
            id={riskCategories["Aggression/Violence"]["id"]}
            score={riskCategories["Aggression/Violence"]["score"]}
            playerId={playerId}
            categoryId="Aggression/Violence"
            onRiskEdit={handleRiskEdit("Aggression/Violence")}
            onComment={handleComment}
            diagnosis={riskCategories["Aggression/Violence"]["text"]}
            editable={isEditable}
          />
        </ExpansionArticle>
      </div>

      <div className={classes.risk}>
        <ExpansionArticle
          title="Delinquent Behavior"
          details="Explores how an individual’s behavior fits within normative range. Delinquency indicates behavior that does not conform with normative standard or expectation of one’s behavior, not adhering to rules, standards of practice, laws, or moral standards of society."
        >
          <ChartAndComments
            id={riskCategories["Delinquent Behavior"]["id"]}
            score={riskCategories["Delinquent Behavior"]["score"]}
            playerId={playerId}
            categoryId="Delinquent Behavior"
            onRiskEdit={handleRiskEdit("Delinquent Behavior")}
            onComment={handleComment}
            diagnosis={riskCategories["Delinquent Behavior"]["text"]}
            editable={isEditable}
          />
        </ExpansionArticle>
      </div>

      <div className={classes.risk}>
        <ExpansionArticle
          title="Drug/Alcohol"
          details="Explores all activities associated with substance use, drug and/or alcohol. This may be indicated to the utilization of substances for recreation activities to engaging in substance use as a maladaptive coping tool. It should be noted that individuals who drink prior to the legal age are at greater probability of participation in various risky behaviors (e.g., multiple sexual partners, unprotected intercourse, deviant behaviors) which may negatively alter decision making, physical, mental, and emotional management."
        >
          <ChartAndComments
            id={riskCategories["Drug/Alcohol"]["id"]}
            score={riskCategories["Drug/Alcohol"]["score"]}
            playerId={playerId}
            categoryId="Drug/Alcohol"
            onRiskEdit={handleRiskEdit("Drug/Alcohol")}
            onComment={handleComment}
            diagnosis={riskCategories["Drug/Alcohol"]["text"]}
            editable={isEditable}
          />
        </ExpansionArticle>
      </div>

      <div className={classes.risk}>
        <ExpansionArticle
          title="Emotional Distress"
          details="Explores one’s ability to manage emotions, cope with high stress scenarios, and balance emotions. Emotional distress can range from one managing situational stressor and coping effectively to having high psychological distress warranting a need for mental health interventions. "
        >
          <ChartAndComments
            id={riskCategories["Emotional Distress"]["id"]}
            score={riskCategories["Emotional Distress"]["score"]}
            playerId={playerId}
            categoryId="Emotional Distress"
            onRiskEdit={handleRiskEdit("Emotional Distress")}
            onComment={handleComment}
            diagnosis={riskCategories["Emotional Distress"]["text"]}
            editable={isEditable}
          />
        </ExpansionArticle>
      </div>

      <div className={classes.risk}>
        <ExpansionArticle
          title="Sexual Behavior"
          details="Explores all activities which fulfill an individual’s sexual needs. Sexual behavior is explored through the context of language, behaviors, practices, relationships, and reproductive health. The expression and evolution of one’s sexual behavior is a natural normative phenomenon, the context in which one’s sexual behavior is expressed may make the behavior abnormal or high risk. It is shown that interpersonal, social, and economic factors can influence the prevalence of risky sexual behaviors in different situations."
        >
          <ChartAndComments
            id={riskCategories["Sexual Behavior"]["id"]}
            score={riskCategories["Sexual Behavior"]["score"]}
            playerId={playerId}
            categoryId="Sexual Behavior"
            onRiskEdit={handleRiskEdit("Sexual Behavior")}
            onComment={handleComment}
            diagnosis={riskCategories["Sexual Behavior"]["text"]}
            editable={isEditable}
          />
        </ExpansionArticle>
      </div>
    </div>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { playerId } = ownProps;
  return {
    riskCategories: state.player.risk["categories"][playerId],
    accountType: state.account.accountType,
    customTraits: state.player.customTraits[playerId],
    stats: state.player.stats[playerId],
  };
};

export default connect(mapStateToProps)(ExecutiveSummary);
