import * as types from "./appTypes";

const openDrawer = () => {
  return {
    type: types.OPEN_DRAWER,
  };
};

const closeDrawer = () => {
  return {
    type: types.CLOSE_DRAWER,
  };
};

const toggleDrawer = () => {
  return {
    type: types.TOGGLE_DRAWER,
  };
};

const appAction = {
  openDrawer,
  closeDrawer,
  toggleDrawer,
};

export default appAction;
