import React from "react";
import List from "@material-ui/core/List";
import NotificationListItem from "./NotificationListItem";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import '../../../index.css';

let useStyles = makeStyles((theme) => ({
  root: {
    // maxHeight: 400,
    fontFamily: 'Prometo',
    fontSize: '19px'
  },
  subheader: {
    padding: theme.spacing(2, 1, 0, 1),
  },
}));

let NotificationList = (props) => {
  let classes = useStyles();
  let { notifications } = props;

  let hasNotifications = notifications.length > 0;

  return (
    <List
      subheader="Notifications"
      classes={{ root: classes.root, subheader: classes.subheader }}
    >
      {hasNotifications ? (
        notifications.map((id) => <NotificationListItem id={id} key={id} />)
      ) : (
        <div>
          <Typography variant="subtitle1" color="textSecondary">
            No notifications found
          </Typography>
        </div>
      )}
    </List>
  );
};

export default NotificationList;
