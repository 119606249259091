import React from "react";
import MuiTabs from "@material-ui/core/Tabs";
import MuiTab from "@material-ui/core/Tab";
import getConfig from "../../config";
import { statusTypes, accountTypes } from "modules/players/constants";
import { useSelector } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const Tab = withStyles((theme) => ({
  root: {
    textTransform: "capitalize",
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(12),
    paddingLeft: 5,
    paddingRight: 5,
    minWidth: 100,
    '.MuiTab-labelIcon .MuiTab-wrapper > *:first-child': {
      marginBottom: '0px'
    },
    "&:focus": {
      opacity: 1,
    },
  },
}))((props) => {
  return <MuiTab {...props} />;
});

const Tabs = withStyles((theme) => ({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    // width: 3,
    "& > span": {
      // maxWidth: 40,
      width: "100%",
      // backgroundColor: theme.palette.primary.main,
    },
  },
}))((props) => (
  <MuiTabs {...props} TabIndicatorProps={{ children: <span /> }} />
));

let useStyles = makeStyles((theme) => ({
  tab: {
    '&.MuiTab-textColorPrimary.Mui-selected': {
      background: '#326fc7',
      color: '#fff',
      borderRadius: '9px'
    },

    "&:active": {
      background: '#326fc7',
      color: '#fff',
      borderRadius: '9px'
    },
    width: 'auto',
    minHeight: '33px !important',
    margin: '0px 0.4rem',
    padding: '0px 1.5rem',
    borderRight: '1px solid #cdcdcd',
    //background: '#f00',
    active: {
      color: '#fcb643',
    },
    "& span": {
      display: 'contents',
      '& svg': {
        marginBottom: '0px !important',
        fontSize: '20px',
        marginRight: '0.4rem',
      }
    },
  },
}
));

export default function Nav({ onSelect, selectedIndex, status, fromTeam, basicInfo }) {
  const classes = useStyles();
  const accountType = useSelector((state) => state.account.accountType);
  const { pages } = getConfig(accountType, status);



  const hasAccess = (accountType, status, page) => {

    if (status === statusTypes.rejected || status === statusTypes.error) {
      return false;
    }
    // else if (
    //   page.id === "beyondbig5" &&
    //   basicInfo?.isPlusReport &&
    //   basicInfo.isPlusReport === true
    // ) {
    //   return true;
    // } 
    else if (
      accountType === accountTypes.bcaDdiAdmin ||
      accountType === accountTypes.bcaPsychologist ||
      accountType === accountTypes.bcaChiefPsychologist ||
      accountType === accountTypes.superAdmin
    ) {
      if (
        status === statusTypes.approvalPending ||
        status === statusTypes.processing
      ) {
        return false;
      }

      return true;
    } else if (
      accountType === accountTypes.orgAdmin ||
      accountType === accountTypes.orgPsychologist ||
      accountType === accountTypes.orgPsychologist
    ) {
      if (status === statusTypes.completed) {
        return true;
      }
      return false;
    }
    else if (
      accountType === accountTypes.colAthleticDirector || accountType === accountTypes.colHeadCoach || accountType === accountTypes.colPositionCoach ||
      accountType === accountTypes.colPsychologist || accountType === accountTypes.proGeneralManager || accountType === accountTypes.proHeadCoach ||
      accountType === accountTypes.proPositionCoach || accountType === accountTypes.proScouts || accountType === accountTypes.proSecurity || accountType === accountTypes.proPsychologist
    ) {
      if (
        status === statusTypes.approvalPending ||
        status === statusTypes.processing 
      ) {
        return false;
      }

      return true;
    }
    else {
      if ((status === statusTypes.completed || status === statusTypes.consentPending)
        && (basicInfo?.isPlusReport && basicInfo?.isPlusReport === true)) {
        return true;
      }
    }
  };

  const isDisabled = (alwaysAllow, accountType, status, page) => {
    return () => {
      if (alwaysAllow) {
        return false;
      }

      const access = hasAccess(accountType, status, page);

      return access ? false : true;
    };
  };

  return (
    <Tabs
      style={{
        alignItems: 'center',
        justifyContent: 'center'
      }}
      value={selectedIndex}
      onChange={onSelect}
      orientation="horizontal"
      textColor="primary"
      variant="scrollable"
      scrollButtons="on"
    >
      {Object.values(pages).map((page, index) => {
        const Icon = page.navIcon;
        const label = page.navName;
        const alwaysAllow = page.alwaysAllow;

        return (
          // (fromTeam === true && (page.id === "personalityTraits" || page.id === "actionPlan" || page.id === "feed")) ?
          //   ""
          //   :

          <Tab
            disabled={isDisabled(alwaysAllow, accountType, status, page)()}
            key={index}
            label={label}
            // icon={<Icon />}
            icon={<img src={Icon} alt="" style={{
              width: '16px',
              marginBottom: '0px',
              marginRight: '0.4rem',
              fill: '#f00'
            }} />}
            classes={{ root: classes.tab }}
          />

        );
      })}
    </Tabs>
  );
}
