import React from "react";
import ErrorRoundedIcon from "@material-ui/icons/ErrorSharp";

const ErrorMessage = ({ text }) => {
  return (
    <span style={{ display: "flex" }}>
      <ErrorRoundedIcon
        style={{ fontSize: 15, marginRight: 4, marginTop: 1 }}
      />{" "}
      <span>{text}</span>
    </span>
  );
};

export const getFormError = (formErrors, formTouched, fieldName) => {
  return formErrors[fieldName] && formTouched[fieldName]
    ? formErrors[fieldName]
    : null;
};

export const errorProps = (formErrors, touched, fieldName) => {
  let error = getFormError(formErrors, touched, fieldName);
  return error
    ? { error: true, helperText: <ErrorMessage text={error} /> }
    : {};
};
