import { Consent } from "../../consent";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Switch } from "react-router-dom";
import { Notifications, Settings } from "../../account";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Contacts } from "../../contacts";
import { Dashboard } from "../../dashboard";
import Players from "../../players/components/Players";
import { accountTypes } from "../../players/constants";
import { PrivateRoute } from "../../shared";
import AppBar from "../AppBar";
import { ActionContext } from "../../../contexts";
import appConfig from "../../../config";
import Drawer from "../components/Drawer";
import { ConferenceMain } from "../../conference"
import { SportsMain } from "../../sports"
import { CoachMain } from "../../coach"
import { TeamMain } from "../../team"
import { AthletManagement } from "../../athlet-management"
import { InfoMain } from "../../MoreInfo"
import { CreateAthlete } from "../../create-athlete"
import { CreateCoach } from "../../create-coach"
import { AccountList, CreateAccount } from "../../account-management"
import { Update } from "../../TodaysUpdate"
import { PlayerBrain } from "../../player-brain";
import { CoachBrain } from "../../coach-brain";




let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.default,
    height: "calc(100vh - 82px)",
    marginTop: theme.spacing(4),
  },
  content: {
    overflowY: "scroll",
    paddingTop: theme.spacing(4),
    "&::-webkit-scrollbar": {
      width: 7,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.background.paper,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[200],
      opacity: 0.5,
    },
    height: "100%",
  },
}));

let DashboardLayout = (props) => {
  let classes = useStyles();
  let { uploadingConsentForms, consentFormsById, isDrawerOpen, accountType } = props;
  let drawerWidth = isDrawerOpen
    ? appConfig.drawer.maxWidth
    : appConfig.drawer.minWidth;
  let { consentActions, playerActions } = useContext(ActionContext);
  return (
    <div style={{ display: " flex", flex: 1, flexDirection: "column" }}>
      <div className={classes.root} style={{ paddingLeft: drawerWidth }}>
        <AppBar position="fixed" color="primary" elevation={0} accountType={accountType} />
        {/* <Drawer /> */}
        <Switch>
          <PrivateRoute
            path="/account/settings"
            children={Settings}
            exact={true}
            roles={Object.values(accountTypes)}
          />

          <PrivateRoute
            path="/todays-update"
            children={Update}
            exact={true}
            roles={Object.values(accountTypes)}
          />

          <PrivateRoute
            path="/consent"
            children={Consent}
            exact={true}
            roles={[
              accountTypes.superAdmin,
              accountTypes.bcaDdiAdmin,
              accountTypes.orgAdmin,
            ]}
          />

          <PrivateRoute
            path="/players"
            children={Players}
            exact={true}
            roles={Object.values(accountTypes)}
          />

          <PrivateRoute
            path="/profile-finder"
            children={Contacts}
            exact={true}
            roles={[accountTypes.superAdmin, accountTypes.bcaDdiAdmin, accountTypes.bcaPsychologist, accountTypes.proPsychologist, accountTypes.colPsychologist, accountTypes.bcaChiefPsychologist]}
          />

          <PrivateRoute
            path="/account/notifications"
            children={Notifications}
            exact={true}
            roles={Object.values(accountTypes)}
          />

          <PrivateRoute
            path="/conference"
            children={ConferenceMain}
            exact={true}
            roles={[accountTypes.superAdmin, accountTypes.bcaDdiAdmin]}
          />

          <PrivateRoute
            path="/coach"
            children={CoachMain}
            exact={true}
            roles={[
              accountTypes.superAdmin, accountTypes.bcaDdiAdmin, accountTypes.bcaPsychologist, accountTypes.bcaChiefPsychologist,
              accountTypes.colAthleticDirector, accountTypes.colHeadCoach, accountTypes.colPsychologist,
              accountTypes.proGeneralManager, accountTypes.proHeadCoach, accountTypes.proPsychologist
            ]}
          />

          <PrivateRoute
            path="/sports-positions"
            children={SportsMain}
            exact={true}
            roles={[accountTypes.superAdmin, accountTypes.bcaDdiAdmin]}
          />

          <PrivateRoute
            path="/team-overview"
            children={TeamMain}
            exact={true}
            roles={[
              accountTypes.superAdmin, accountTypes.bcaDdiAdmin, accountTypes.colPositionCoach,
              accountTypes.proGeneralManager, accountTypes.proHeadCoach, accountTypes.proPositionCoach,
              accountTypes.proScouts, accountTypes.proSecurity, accountTypes.colHeadCoach, accountTypes.proPsychologist, accountTypes.colPsychologist
            ]}
          />

          <PrivateRoute
            path="/more-info"
            children={InfoMain}
            exact={true}
            roles={[accountTypes.superAdmin, accountTypes.bcaDdiAdmin, accountTypes.bcaPsychologist, accountTypes.bcaChiefPsychologist,
            accountTypes.orgAdmin, accountTypes.orgPsychologist, accountTypes.orgUser,
            accountTypes.colAthleticDirector, accountTypes.colHeadCoach, accountTypes.colPositionCoach,
            accountTypes.colPsychologist, accountTypes.proGeneralManager, accountTypes.proHeadCoach,
            accountTypes.proPositionCoach, accountTypes.proScouts, accountTypes.proSecurity, accountTypes.proPsychologist]}
          />

          <PrivateRoute
            path="/athlete-management"
            children={AthletManagement}
            exact={true}
            roles={[
              accountTypes.superAdmin, accountTypes.bcaDdiAdmin, accountTypes.bcaPsychologist, accountTypes.bcaChiefPsychologist,
              accountTypes.orgAdmin, accountTypes.orgPsychologist, accountTypes.orgUser,
              accountTypes.colAthleticDirector, accountTypes.colHeadCoach, accountTypes.colPositionCoach,
              accountTypes.colPsychologist, accountTypes.proGeneralManager, accountTypes.proHeadCoach,
              accountTypes.proPositionCoach, accountTypes.proScouts, accountTypes.proSecurity, accountTypes.proPsychologist
            ]}
          />

          <PrivateRoute
            path="/create-athlete"
            children={CreateAthlete}
            exact={true}
            roles={[
              accountTypes.superAdmin, accountTypes.bcaDdiAdmin,
              accountTypes.orgAdmin, accountTypes.orgUser,
              accountTypes.colHeadCoach, accountTypes.colPositionCoach,
              accountTypes.proGeneralManager, accountTypes.proHeadCoach,
              accountTypes.proPositionCoach, accountTypes.proScouts, accountTypes.proSecurity,
            ]}
          />

          <PrivateRoute
            path="/create-coach"
            children={CreateCoach}
            exact={true}
            roles={[
              accountTypes.superAdmin, accountTypes.bcaDdiAdmin,
              accountTypes.colHeadCoach, accountTypes.colAthleticDirector,
              accountTypes.proGeneralManager, accountTypes.proHeadCoach,
            ]}
          />

          <PrivateRoute
            // path="/create-user"
            path="/account-management"
            children={AccountList}
            exact={true}
            roles={[
              accountTypes.superAdmin, accountTypes.bcaDdiAdmin,
              accountTypes.orgAdmin,
            ]}
          />
          <PrivateRoute
            path="/create-user"
            children={CreateAccount}
            exact={true}
            roles={[
              accountTypes.superAdmin, accountTypes.bcaDdiAdmin,
              accountTypes.orgAdmin,
            ]}
          />

          <PrivateRoute
            path="/player-brain"
            children={PlayerBrain}
            exact={true}
            roles={[
              accountTypes.superAdmin, accountTypes.bcaDdiAdmin,
              accountTypes.orgAdmin, accountTypes.orgUser,
              accountTypes.colHeadCoach, accountTypes.colPositionCoach,
              accountTypes.proGeneralManager, accountTypes.proHeadCoach,
              accountTypes.proPositionCoach, accountTypes.proScouts, accountTypes.proSecurity,
            ]}
          />
           <PrivateRoute
            path="/coach-brain"
            children={CoachBrain}
            exact={true}
            roles={[
              accountTypes.superAdmin, accountTypes.bcaDdiAdmin,
              accountTypes.orgAdmin, accountTypes.orgUser,
              accountTypes.colHeadCoach, accountTypes.colPositionCoach,
              accountTypes.proGeneralManager, accountTypes.proHeadCoach,
              accountTypes.proPositionCoach, accountTypes.proScouts, accountTypes.proSecurity,
            ]}
          />


          <PrivateRoute
            path="/"
            children={Dashboard}
            exact={true}
            // roles={Object.values(accountTypes)}
            roles={[
              accountTypes.superAdmin, accountTypes.bcaDdiAdmin, accountTypes.bcaPsychologist, accountTypes.bcaChiefPsychologist,
              accountTypes.orgAdmin, accountTypes.orgUser, accountTypes.orgPsychologist, accountTypes.candidate,
              accountTypes.colAthleticDirector, accountTypes.colHeadCoach,
              accountTypes.proGeneralManager, accountTypes.proHeadCoach,

            ]}
          />
        </Switch>
      </div>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    isDrawerOpen: state.ui.app.isDrawerOpen,
    accountType: state.account.accountType,
  };
};
export default connect(mapStateToProps)(DashboardLayout);
