import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import AssessmentIcon from "@material-ui/icons/Assessment";
import RequestPlusDialog from "./RequestPlusDialog";
import { ActionContext } from "../../../../contexts";

const Reports = (props) => {
  const { playerId, status, basicInfo } = props;
  const { dialogActions } = useContext(ActionContext);

  const handleClick = () => {
    dialogActions.openDialog(
      <RequestPlusDialog playerId={playerId} status={status} basicInfo={basicInfo} />,
      {
        maxWidth: "xs",
      }
    );
  };

  return (
    <div style={{ marginLeft: 8 }}>
      {/* <span style={{ color: '#01284b', fontWeight: 700 }} */}
      <span
        style={{
          border: '1px solid',
          padding: '0.3rem 0.5rem',
          borderRadius: '9px',
          margin: '0rem 1rem',
          color: '#0971ce',
          fontWeight: 700,
          cursor: 'pointer',
        }}
        // variant="contained"
        color="default"
        startIcon={<AssessmentIcon />}
        onClick={handleClick}
        disableElevation
      >
        Request Plus Report
      </span>
    </div>
  );
};

export default Reports;
