import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { convertFromRaw, EditorState } from "draft-js";
import React, { useContext } from "react";
import { connect } from "react-redux";
import { ActionContext } from "../../../../contexts";
import { Article } from "../../../shared/Article";
import { EditorArticle } from "../../../shared/Editor";
import { accountTypes } from "modules/players/constants";
import LinkList from "./LinkList";
import LinksDialog from "./LinksDialog";
import NotesDialog from "./NotesDialog";
// import eachPlayer from "modules/dashboard/components/EachPlayer"

let useStyles = makeStyles((theme) => ({
  heading: {
    justifyContent: "space-between",
  },
}));

let Notes = (props) => {
  let classes = useStyles();
  let { dialogActions } = useContext(ActionContext);
  let { coachId, accountType, notes } = props;

  // const selectedPlayer = eachPlayer && eachPlayer[coachId]  
  // const notes = selectedPlayer?.notes || {}

  let editorState = notes.blocks
    ? EditorState.createWithContent(convertFromRaw(notes))
    : EditorState.createEmpty();

  let handleEditClick = () => {
    dialogActions.openDialog(
      <NotesDialog coachId={coachId} initialEditorState={editorState} />
    );
  };

  let allowedAccountTypes = [
    accountTypes.superAdmin,
    accountTypes.bcaPsychologist,
    accountTypes.bcaDdiAdmin,
  ];

  let isEditable = allowedAccountTypes.includes(accountType);

  let handleLink = () => {
    dialogActions.openDialog(<LinksDialog coachId={coachId} />, {
      maxWidth: "sm",
    });
  };

  return (
    <React.Fragment >
      <div style={{ width: "90%", margin: '0 auto' }}>
        <EditorArticle
          placeholder="Notes not added yet"
          editorState={editorState}
          isEditable={isEditable}
          onEdit={handleEditClick}
        />
        <Article
          title="Links"
          classes={{ heading: classes.heading }}
          button={
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<AddIcon />}
              onClick={handleLink}
              // disabled={true}
              disableElevation
            >
              Add Link
            </Button>
          }
        >
          <LinkList coachId={coachId} />
        </Article>
      </div>
    </React.Fragment>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { coachId } = ownProps;
  return {
    notes: state.coach.notes[coachId],
    accountType: state.account.accountType,
  };
};
export default connect(mapStateToProps)(Notes);
