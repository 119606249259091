import React from "react";
import Chart from "react-apexcharts";

const RadialBar = ({ options, score, color, height = 125, ...rest }) => {
  let mergedOptions = { ...options, colors: [color] };
  return (
    <Chart
      options={mergedOptions}
      series={[score]}
      type="radialBar"
      height={height}
      {...rest}
    />
  );
};

export default RadialBar;
