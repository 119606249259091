import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import WebIcon from "@material-ui/icons/Web";
import YouTubeIcon from "@material-ui/icons/YouTube";
import MovieIcon from "@material-ui/icons/Movie";
import DeleteIcon from "@material-ui/icons/Delete";
import CircularProgress from "../../../shared/CircularProgress";
import { sourceLanguageTypes } from "modules/players/constants";
import { connect } from "react-redux";

const getIcon = (category) => {
  switch (category) {
    case "newspaper":
      return <WebIcon />;

    case "youtube":
      return <YouTubeIcon />;

    case "video":
      return <MovieIcon />;

    default:
      return null;
  }
};

const LinkItem = (props) => {
  const { link, onDelete, onClick, loading } = props;
  const { source_ln = null } = link;
  const icon = getIcon(link.category);

  // higher order fn
  const handleLinkClick = (url) => () => {
    onClick(url);
  };

  // higher order fn
  const handleLinkDelete = (id) => () => {
    onDelete(id);
  };

  const getSourceLanguage = () => {
    return source_ln
      ? `(${sourceLanguageTypes[source_ln]})`
      : `(${sourceLanguageTypes["en"]})`;
  };

  return (
    <ListItem button onClick={handleLinkClick(link.url)}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={`${link.url} ${getSourceLanguage()}`} />
      <ListItemSecondaryAction>
        {loading ? (
          <CircularProgress color="primary" />
        ) : (
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={handleLinkDelete(link.link_id)}
            // disabled={true}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { coachId } = ownProps;
  return {
    loading: state.ui.coach.deletingCoachLink[coachId],
  };
};

export default connect(mapStateToProps)(LinkItem);
