import React from "react";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { capitalize, formatDate } from "../../../../utils";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

// import eachPlayer from "modules/dashboard/components/EachPlayer"

let useStyles = makeStyles((theme) => ({
  label: {
    textTransform: "uppercase",
    fontSize: 16,
    fontWeight: 700,
    width: 176,
    marginRight: theme.spacing(2),
  },
  value: {
    flex: 1,
    textTransform: "capitalize",
  },
}));

let Item = ({ label, value }) => {
  let classes = useStyles();

  if (value === "" || value === null || value === undefined) {
    return null;
  } else if (value.toLowerCase() === "n/a") {
    return null;
  }

  return (
    <ListItem style={{ display: 'block' }}>

      <Typography
        variant="button"
        color="textSecondary"
        className={classes.label}
      >
        {label}
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {capitalize(value)}
      </Typography>

    </ListItem>
  );
};

let BasicInfo = (props) => {
  let { playerId, basicInfo } = props;

  // const selectedPlayer = eachPlayer && eachPlayer[playerId]  
  // const basicInfo = {
  //   id: selectedPlayer && selectedPlayer._id,
  //   birthday: selectedPlayer && selectedPlayer.dob,
  //   name: selectedPlayer && selectedPlayer.full_name,
  //   risk: selectedPlayer && selectedPlayer.risk,
  //   school: selectedPlayer && selectedPlayer.school,
  //   status: selectedPlayer && selectedPlayer.status,
  //   team: selectedPlayer && selectedPlayer.team,
  //   image: selectedPlayer && selectedPlayer.image_url,
  //   consent: selectedPlayer && selectedPlayer.consent,
  //   isPaid: selectedPlayer && selectedPlayer.is_paid,
  // };

  return (
    <List>
      <Item label="name" value={basicInfo && basicInfo.name} />
      <Item label="birthday" value={formatDate(basicInfo && basicInfo.birthday)} />
      <Item label="school" value={basicInfo && basicInfo.school} />
      <Item label="hometown" value={basicInfo && basicInfo.hometown} />
      <Item label="relationship" value={basicInfo && basicInfo.relationship} />
      <Item label="race" value={basicInfo && basicInfo.race} />
    </List>
  );
};

let mapStateToProps = (state, ownProps) => {
  return {
    basicInfo: state.player.basic[ownProps.playerId],
  };
};
export default connect(mapStateToProps)(BasicInfo);
