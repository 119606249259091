import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import DoneIcon from '@material-ui/icons/Done';
import { ActionContext } from "../../../contexts";

const useStyles = makeStyles((theme) => ({
    SortByAlignDataWidth: {
        padding: '15px'
    },
    SortbySubHeader: {
        display: 'flex',
        marginBottom: '10px'
    },
    flexKeyAlign: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '3px',
        flexDirection: 'row',
        width: '8rem',
        justifyContent: 'space-between',
        padding: '3px 2px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: "#f4f7fe",
            borderRadius: '5px',
        }
    },
    IconAlign: {
        width: '0.8em'
    },
    gridALignArrows: {
        display: 'grid',
    },
    IconAlignDataColor: {
        color: '#f5b048'
    }
}));

export default function SortingPopover(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    let actions = useContext(ActionContext);

    let { playerActions } = actions;

    const [state, setState] = useState({
        isDrawerOpen: false,
        sampleList: [],
        allList: []
    });

    const toggleChecked1 = (id, val) => {
        let obj = [{ key: id, value: 'asc' }]
        const sample = state.allList.filter(v => v.key === id).map(v => v)
        if (sample && sample.length === 0) {
            setState(prevState => ({
                ...prevState,
                allList: [...state.allList, ...obj],
                sampleList: [...state.sampleList, id]
            }))
            playerActions.playerSortingType([...state.sampleList, id]);
            playerActions.playerSortOrder([...state.allList, ...obj]);
        } else {
            setState(prevState => ({
                ...prevState,
                allList: state.allList.filter(item => item.key !== id)
            }))
            playerActions.playerSortOrder(state.allList.filter(item => item.key !== id));
            var index = state.sampleList.indexOf(id);
            state.sampleList.splice(index, 1);
            playerActions.playerSortingType(state.sampleList);
            return state.sampleList;
        }
    };


    const toggleChecked = (id, val) => {
        let obj = [{ key: id, value: 'asc' }]
        const sample = state.allList && state.allList.filter(v => v.key === id).map(v => v)
        if (sample && sample.length === 0) {
            setState(prevState => ({
                ...prevState,
                allList: [...obj],
                sampleList: [id]
            }))
            playerActions.playerSortingType([id]);
            playerActions.playerSortOrder([...obj]);
        } else {
            setState(prevState => ({
                ...prevState,
                allList: [],
                sampleList: []
            }))
            playerActions.playerSortingType([]);
            playerActions.playerSortOrder([]);
        }
    };


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const sortingByData =
        [
            {
                key: "Name",
                value: "full_name"
            },
            {
                key: "Created On",
                value: "created_on"
            },
            {
                key: "Status",
                value: "status"
            },
        ]

    function handleAscDesc(id, val) {
        let obj = [{ key: id, value: val }]
        let fIndex = state.allList.findIndex(m => m.key === id);
        if (fIndex === -1) {
            setState(prevState => ({
                ...prevState,
                allList: [...state.allList, ...obj]
            }))
            playerActions.playerSortOrder([...state.allList, ...obj]);
        }
        else {
            state.allList[fIndex].value = val;
            setState(prevState => ({
                ...prevState,
                allList: [...state.allList]
            }))
            playerActions.playerSortOrder([...state.allList]);
        }
    }

    return (
        <>
            <div >
                <div aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
                    {props.icon}
                </div>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <React.Fragment>
                        <div className={classes.SortByAlignDataWidth}>
                            <label className={classes.SortbySubHeader}>SORT BY</label>

                            {
                                sortingByData.map(v =>
                                    <>
                                        <span className={classes.flexKeyAlign}>
                                            {
                                                state.sampleList && state.sampleList.indexOf(v.value) > -1 ?
                                                    <>
                                                        <div className={classes.gridALignArrows}>
                                                            <span size="small" style={{ height: '10px' }}>
                                                                <span
                                                                    onClick={() => handleAscDesc(v.value, 'asc')}
                                                                    style={{
                                                                        color: state.allList.filter(m => m.key === v.value)[0] && state.allList.filter(m => m.key === v.value)[0].value === 'asc' ? "red" : ""
                                                                    }}>
                                                                    <ExpandLessIcon className={classes.IconAlign} />
                                                                </span>
                                                            </span>
                                                            <span size="small">
                                                                <span
                                                                    onClick={() => handleAscDesc(v.value, 'desc')}
                                                                    style={{
                                                                        color: state.allList.filter(m => m.key === v.value)[0] && state.allList.filter(m => m.key === v.value)[0].value === 'desc' ? "red" : ""
                                                                    }}>
                                                                    <ExpandMoreIcon className={classes.IconAlign} />
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </>
                                                    : ""
                                            }
                                            <div onClick={() => toggleChecked(v.value, 'asc')}>{v.key}</div>
                                            {
                                                state.sampleList && state.sampleList.indexOf(v.value) > -1 ?
                                                    <span size="small">
                                                        <DoneIcon className={classes.IconAlignDataColor} />
                                                    </span>
                                                    : ""
                                            }
                                        </span>
                                    </>
                                )
                            }
                        </div>
                    </React.Fragment>
                </Popover>
            </div>
        </>
    );
}
