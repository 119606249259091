import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Typography, Tooltip } from "@material-ui/core";
import NeutralIcon from "@material-ui/icons/SentimentSatisfied";
import NegativeIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import PositiveIcon from "@material-ui/icons/SentimentVerySatisfied";
import { RadialBar } from "modules/shared/Charts";
import React, { useContext, useState } from "react";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { connect } from "react-redux";
import { round } from "utils";
import { ActionContext } from "../../../../contexts";
import { Tabs } from "../../../shared/Tabs";
import { accountTypes, chartColors } from "modules/players/constants";
import ChartAndDetails from "../ChartAndDetails";
import ExpansionArticle from "../ExpansionArticle";
import FeedStatusCard from "../FeedStatusCard";
import ChartAndComments from "./ChartAndComments";
import RiskComments from "./RiskComments";
import RiskDialog from "./RiskDialog";
import LeftTabs from '@material-ui/core/Tabs';
import LeftTab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
// import eachPlayer from "modules/dashboard/components/EachPlayer"
import InfoIcon from "@material-ui/icons/Info";
import { RadialGauge } from "modules/shared/Charts";
import '../../../../index.css';
import { useEffect } from "react";
import { MainTab, ChildTab } from "../../../shared/Tabs";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

let useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Prometo',
    fontSize: '14px',
    color: '#fcb643',
  },
  risk: {
    display: "flex",
    justifyContent: "center",
    margin: '0rem 2rem',
    width: 'auto',
  },
  brain: {
    fontFamily: 'Prometo',
    fontSize: '15px',
    fontWeight: 'bold',
    display: "flex",
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0rem 2rem',
    width: 'auto',
    height: '20vh',
    color: '#326fc7',
  },
}));

let roundTo1PrecisionPoint = (value) => {
  return round(value, 0);
};

let radialBarOptions = {
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "40%",
        background: "#293450",
      },
      track: {
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 4,
          opacity: 0.15,
        },
      },
      dataLabels: {
        name: {
          show: false,
          offsetY: -10,
          color: "#fff",
          fontSize: "13px",
        },
        value: {
          color: "#fff",
          fontSize: "14px",
          offsetY: 6,
          show: true,
        },
      },
    },
  },
  stroke: {
    lineCap: "butt",
  },
};

let ExecutiveSummary = (props) => {
  let classes = useStyles();
  let [selectedIndex, setSelectedIndex] = useState(0);
  let { coachId, accountType, stats, customTraits, riskCategories, traits, basicInfo, team, behaviourRisk } = props;
  let { dialogActions } = useContext(ActionContext);

  const allTraits = ["openness", "conscientiousness", "extroversion", "agreeableness", "emotional_range"]
  const subValue = []

  allTraits.forEach(x => {
    if (Object.keys(traits).includes(x)) {
      subValue.push(traits[x]?.value)
    }
  }
  )

  let sum = 0

  for (let i = 0; i < subValue.length; i += 1) {
    sum += subValue[i]
  }

  let developmentScore = (basicInfo?.developmentScore !== null && basicInfo?.developmentScore !== undefined) ? basicInfo?.developmentScore : sum


  // const selectedPlayer = eachPlayer && eachPlayer[coachId]
  // const riskCategories = selectedPlayer?.insights["risk"];
  // const customTraits = selectedPlayer?.insights["custom_traits"];

  let isEditable =
    accountType === accountTypes.bcaPsychologist ||
    accountType === accountTypes.superAdmin;

  // let customTraitLabels = Object.keys(customTraits);
  // let customTrait = Object.values(customTraits)[selectedIndex];


  // let customTraitLabels = Object.keys(traits);
  const customTraitLabels = ['agreeableness', 'conscientiousness', 'emotional range', 'extroversion', 'openness']
  let customTrait = Object.values(traits)[selectedIndex];


  const behaviourRiskLabels = ['Risk Seeking', 'Toxicity', 'Threat', 'Hate Speech', 'Hedonism']
  let risk = Object.values(behaviourRisk)[selectedIndex];

  const getDescription = (key) => {
    if (key === "Agreeableness") {
      return "Agreeableness refers to an individual's desire to get along with and please others. People high in agreeableness put other people's needs before their own, and have a preference for social harmony. Individuals low in agreeableness may exhibit more traits of competitiveness and may push for individual gain over team gain."
    } else if (key === "Conscientiousness") {
      return "Conscientiousness involves self-control, a strong work ethic, and a desire for tidiness or organization. Highly conscientious individuals are planners and carry out their assigned responsibilities purposefully. They can be strong willed and determined. Low conscientious individuals might struggle to execute their assigned duties, have lower self-control, and my be disorganized. Conscientiousness is associated with academic and occupational achievement."
    } else if (key === "Emotional Range") {
      return "Emotional range refers to how an individual experiences emotions when under stress or faced with adversity. Individuals low in emotional range tend to experience less negative emotions under stressful situations and have higher stress tolerance and capacity for problem solving. Those higher in emotional range tend to be more reactive, experience more negative feelings, and have more difficulty coping."
    } else if (key === "Extroversion") {
      return "Extraversion examines the degree to which a person feels energized or uplifted when interacting with others. It reflects sociability, a tendency to be outwardly positive, friendly, and active, and seek out others. Extraverts are sociable and enjoy interacting with others, assertive, and like to keep busy. Introverts are not the opposite of extraverts, but lack the strong characteristics of extraverts. Introverts are not cold or unfriendly, but are more reserved and may value being alone to help recharge."
    } else if (key === "Openness") {
      return "Openness refers to an individual's general willingness to engage in new experiences and learn new things. People high in Openness have an active imagination and are willing to entertain novel ideas. Those low in Openness tend to be more conventional and practical."
    }
  }
  const getRiskDescription = (key) => {
    if (key === "Hate Speech") {
      return "Provides the probability that a text sample contains an instance of hatred, anger, or disgust regarding any demographic group. Demographic groups are commonly differentiated by race, ethnicity, gender, sexual orientation, or disability. Expressions of racism or sexism are considered hate speech, while insults directed at a person or people with no mention of their race, gender, or other demographic markers are not hate speech."
    } else if (key === "Hedonism") {
      return "To prioritize physical pleasures, including (but not limited to) food, alcohol, drugs, and sex."
    } else if (key === "Risk Seeking") {
      return "The degree to which a person is focused on engaging in risky behaviors or activities."
    } else if (key === "Threat") {
      return "Provides the probability that a text sample contains a direct threat of any kind. This threat may be directed to the reader or recipient, a third party who isn’t reading the message, or to a type of person in general."
    } else if (key === "Toxicity") {
      return "Provides the probability that a text sample contains any content considered undesirable in civil discourse. This may include, but is not limited to threats, hate speech, offensive language, obscenities, bullying, direct insults, insensitive content, descriptions of violence, or overt sexual content."
    }
  }


  let handleComment = (id) => {
    dialogActions.openDialog(
      <RiskComments coachId={coachId} categoryId={id} />,
      { maxWidth: "sm" }
    );
  };

  let handleRiskEdit = (categoryId) => (id, score) => {
    dialogActions.openDialog(
      <RiskDialog coachId={coachId} categoryId={categoryId} score={score} />,
      { maxWidth: "xs" }
    );
  };

  let handleTabChange = (e, index) => {
    setSelectedIndex(index);
  };

  let handleColors = (score) => {
    if (0 <= score && score < 30) {
      return chartColors.red;
    } else if (30 <= score && score < 70) {
      return chartColors.orange;
    } else if (70 <= score && score < 100) {
      return chartColors.green;
    }
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const stats = {
  //   negativePercentage: selectedPlayer?.stats["negative_percentage"],
  //   neutralPercentage: selectedPlayer?.stats["neutral_percentage"],
  //   positivePercentage: selectedPlayer?.stats["positive_percentage"],
  //   twitter: {
  //     negativePercentage: selectedPlayer?.stats["tweets"]["negative_percentage"],
  //     neutralPercentage: selectedPlayer?.stats["tweets"]["neutral_percentage"],
  //     positivePercentage: selectedPlayer?.stats["tweets"]["positive_percentage"],
  //   },
  //   instagram: {
  //     negativePercentage:
  //       selectedPlayer?.stats["instagram"]["negative_percentage"],
  //     neutralPercentage: selectedPlayer?.stats["instagram"]["neutral_percentage"],
  //     positivePercentage:
  //       selectedPlayer?.stats["instagram"]["positive_percentage"],
  //   },
  //   youtube: {
  //     negativePercentage: "nodata",
  //     neutralPercentage: "nodata",
  //     positivePercentage: "nodata",
  //   },
  // }
  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#326fc7',
      color: '#fff',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }))(Tooltip);
  const [selectedRiskPage, setSelectedRiskPage] = useState(0)

  const handleChangeRiskPage = (event, newValue) => {
    setSelectedRiskPage(newValue);
  };
  const communicationLabels = ['Your Athlete', 'About Dominant Styles', 'About Influential Styles', 'About Steady Styles', 'About Compliant Styles']

  // const riskFactors = (accountType === "SUPER_ADMIN" || accountType === "BCA_PSYCHOLOGIST" || accountType === "BCA_CHIEF_PSYCHOLOGIST") ? ["Aggression/Violence", "Delinquent Behavior", "Emotional Distress", "Drug/Alcohol", "Sexual Behavior"] : ["Aggression/Violence", "Delinquent Behavior", "Emotional Distress"]
  const riskFactors = ["Risk Seeking", "Civil Discourse", "Threat", "Hate Speech", "Self-Indulgence"]
  const info = "Behavioral Risk Factors can strongly impact the physical, mental, and emotional framework of an individual’s overall wellbeing and   performance outcomes.Several behaviors have been shown to exert a strong influence on health, wellness, and performance outcomes   specifically: Risk Seeking, Toxicity, Threat, Hate Speech, Hedonism.These five behavioral risk   factors are strongly influenced by genetics and environmental cues.   Behaviors can be modified through behavioral interventions, social   support, and changes in environment which can positively impact the   wellbeing and performance outcomes for individuals."

  return (
    <div>
      <div style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        width: "100%",
      }}>
        <Box
          sx={{ flexGrow: 1, display: 'flex', margin: '0px 5rem' }}
        >
          <LeftTabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <LeftTab className={value === 0 ? classes.root : ''} label="Personality" {...a11yProps(0)} />
            {/* <LeftTab className={value === 1 ? classes.root : ''} label="Behavior Risk" {...a11yProps(1)} /> */}
            <LeftTab className={value === 1 ? classes.root : ''} label="Behaviour" {...a11yProps(1)} />
            <LeftTab className={value === 2 ? classes.root : ''} label="Communication" {...a11yProps(2)} />
          </LeftTabs>
          <TabPanel value={value} index={0} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="h5" align="center" style={{
                color: '#326fc7',
                fontSize: '19px',
                fontWeight: 'revert',
                padding: '1rem',
                fontFamily: 'Prometo',
              }}>
                Big 5 Overview
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                margin: '0rem 2rem',
                width: 'auto',

              }}
            >
              <Tabs
                labels={customTraitLabels}
                value={selectedIndex}
                onChange={handleTabChange}
              />

              <ExpansionArticle
                title={customTrait.label}
                details={getDescription(customTrait.label)}
              >
                <div>
                  <ChartAndDetails
                    diagnosis={customTrait["text"]}
                    score={customTrait["score"]}
                    handleColors={handleColors}
                  />
                  {/* <div
                    style={{
                      marginBottom: 48,
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-evenly",

                    }}
                  >
                    {Object.values(customTrait.sub).map((subTrait, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            maxWidth: 150,
                          }}
                        >
                          <RadialBar
                            color={handleColors(
                              roundTo1PrecisionPoint(subTrait["score"])
                            )}
                            options={radialBarOptions}
                            score={roundTo1PrecisionPoint(subTrait["score"])}
                            key={index}
                          />
                          <RadialGauge score={roundTo1PrecisionPoint(subTrait["score"])} />
                          <p style={{ textAlign: "center", marginTop: 0, marginBottom: '0' }}>
                            {roundTo1PrecisionPoint(subTrait["score"])}%
                          </p>
                          <p style={{ textAlign: "center", marginTop: 0 }}>
                            {`+ ${subTrait["left_label"]}`}
                          </p>
                        </div>

                        // <StackedBarChart
                        //   key={index}
                        //   value={subTrait["score"]}
                        //   left={subTrait["left_label"]}
                        //   right={subTrait["right_label"]}
                        //   colors={["#007fdb", "#fcb643"]}
                        // />
                      );
                    })}
                  </div> */}
                </div>
              </ExpansionArticle>
            </div>
            {/* 
            <div style={{ marginTop: 40 }}>
              <Typography variant="h5" align="center">
                Sentiment Analysis of Athlete’s Posts
              </Typography>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                align="center"
                style={{ marginBottom: 16 }}
              >
                Percentage of the athletes social media posts that are positive,
                neutral, or negative
              </Typography>
            </div> */}

            {/* <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 8,
              }}
            >
              <FeedStatusCard
                score={stats["positivePercentage"]}
                label="Positives"
                icon={<PositiveIcon />}
              />
              <FeedStatusCard
                score={stats["neutralPercentage"]}
                label="Neutrals"
                icon={<NeutralIcon />}
              />
              <FeedStatusCard
                score={stats["negativePercentage"]}
                label="Negatives"
                icon={<NegativeIcon />}
              />
            </div> */}
          </TabPanel>
          <TabPanel value={value} index={1} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
            {basicInfo && basicInfo.brain === 'Receptiviti' ?
              <>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography variant="h5" align="center" style={{
                    color: '#326fc7',
                    fontSize: '19px',
                    fontWeight: 'revert',
                    padding: '1rem 0.5rem 1rem 1rem',
                    fontFamily: 'Prometo',
                  }}>
                    Behavior Risk
                  </Typography>
                  <span style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 'auto',
                    cursor: "pointer"
                  }}>
                    <LightTooltip title={info} arrow>
                      <InfoOutlinedIcon style={{ fontSize: '16px' }} />
                    </LightTooltip >
                  </span>
                </div>
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}>
                  {/* <LeftTabs
                orientation="horizontal"
                variant="scrollable"
                value={selectedRiskPage}
                onChange={handleChangeRiskPage}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: 'divider' }}
              >
                {
                  riskFactors && riskFactors.map((list, index) => (
                    <LeftTab className={selectedRiskPage === index ? classes.root : ''} label={list} {...a11yProps(index)} />
                  ))
                }
              </LeftTabs> */}
                  <MainTab value={selectedRiskPage} onChange={handleChangeRiskPage}>
                    {riskFactors && riskFactors.map((list, index) => (
                      <ChildTab style={{ color: selectedRiskPage === index ? '#fcb643' : '' }} key={index} label={list} />
                    ))}
                  </MainTab>
                </div>
                <TabPanel value={selectedRiskPage} index={0}>
                  <div className={classes.risk}>
                    <ExpansionArticle
                      title="Risk Seeking"
                      details="The degree to which a person is focused on engaging in risky behaviors or activities."
                    >
                      <ChartAndDetails
                        // diagnosis={behaviourRisk["Risk Seeking"]["text"]}
                        score={behaviourRisk["Risk Seeking"]["score"]}
                        handleColors={handleColors}
                        tabName="Risk"
                      />
                    </ExpansionArticle>
                  </div>
                </TabPanel>
                <TabPanel value={selectedRiskPage} index={1}>
                  <div className={classes.risk}>
                    <ExpansionArticle
                      title="Civil Discourse"
                      details="Provides the probability that a text sample contains any content considered undesirable in civil discourse. In short, this measures the individual's willingness to engage in conversations deemed 'controversial'. This may include, but is not limited to threats, hate speech, offensive language, obscenities, bullying, direct insults, insensitive content, descriptions of violence, or overt sexual content. It is important to note this measures the degree to which an individual is engaging in the conversation. It does not indicate the manner (e.g. – supportive vs. offensive) in which an individual engages in the conversation."
                    >
                      <ChartAndDetails
                        // diagnosis={behaviourRisk["Toxicity"]["text"]}
                        score={behaviourRisk["Toxicity"]["score"]}
                        handleColors={handleColors}
                        tabName="Risk"
                      />
                    </ExpansionArticle>
                  </div>
                </TabPanel>
                <TabPanel value={selectedRiskPage} index={2}>
                  <div className={classes.risk}>
                    <ExpansionArticle
                      title="Threat"
                      details="Provides the probability that a text sample contains a direct threat of any kind. This threat may be directed to the reader or recipient, a third party who isn’t reading the message, or to a type of person in general."
                    >
                      <ChartAndDetails
                        // diagnosis={behaviourRisk["Threat"]["text"]}
                        score={behaviourRisk["Threat"]["score"]}
                        handleColors={handleColors}
                        tabName="Risk"
                      />
                    </ExpansionArticle>
                  </div>
                </TabPanel> <TabPanel value={selectedRiskPage} index={3}>
                  <div className={classes.risk}>
                    <ExpansionArticle
                      title="Hate Speech"
                      details="Provides the probability that a text sample contains an instance of hatred, anger, or disgust regarding any demographic group. Demographic groups are commonly differentiated by race, ethnicity, gender, sexual orientation, or disability. Expressions of racism or sexism are considered hate speech, while insults directed at a person or people with no mention of their race, gender, or other demographic markers are not hate speech."
                    >
                      <ChartAndDetails
                        // diagnosis={behaviourRisk["Hate Speech"]["text"]}
                        score={behaviourRisk["Hate Speech"]["score"]}
                        handleColors={handleColors}
                        tabName="Risk"
                      />
                    </ExpansionArticle>
                  </div>
                </TabPanel> <TabPanel value={selectedRiskPage} index={4}>
                  <div className={classes.risk}>
                    <ExpansionArticle
                      title="Self-Indulgence"
                      details="To prioritize physical pleasures, including (but not limited to) food, alcohol, drugs, and sex."
                    >
                      <ChartAndDetails
                        // diagnosis={behaviourRisk["Hedonism"]["text"]}
                        score={behaviourRisk["Hedonism"]["score"]}
                        handleColors={handleColors}
                        tabName="Risk"
                      />
                    </ExpansionArticle>
                  </div>
                </TabPanel>
              </>
              :
              <span className={classes.brain}>This report is outdated. To see enhanced features, please contact your Blu Chip representative.</span>

            }
          </TabPanel>
          {/* <TabPanel value={value} index={1} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="h5" align="center" style={{
                color: '#326fc7',
                fontSize: '19px',
                fontWeight: 'revert',
                padding: '1rem 0.5rem 1rem 1rem',
                fontFamily: 'Prometo',
              }}>
                Behavior Risk
              </Typography>
              <span style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 'auto',
                cursor: "pointer"
              }}>
                <LightTooltip title={info} arrow>
                  <InfoOutlinedIcon style={{ fontSize: '16px' }} />
                </LightTooltip >
              </span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                margin: '0rem 2rem',
                width: 'auto',

              }}
            >
              <Tabs
                labels={behaviourRiskLabels}
                value={selectedIndex}
                onChange={handleTabChange}
              />

              <ExpansionArticle
                title={risk.label}
                details={getRiskDescription(risk.label)}
              >
                <div>
                  <ChartAndDetails
                    diagnosis={risk["text"]}
                    score={risk["score"]}
                    handleColors={handleColors}
                  />
                </div>
              </ExpansionArticle>
            </div>
          </TabPanel> */}

          <TabPanel value={value} index={2} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography variant="h5" align="center" style={{
                color: '#326fc7',
                fontSize: '19px',
                fontWeight: 'revert',
                padding: '1rem',
                fontFamily: 'Prometo',
              }}>
                {/* Sentiment Analysis */}
                Communication Style
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                margin: '0rem 2rem',
                width: 'auto',

              }}
            >
              <Tabs
                labels={communicationLabels}
                value={selectedIndex}
                onChange={handleTabChange}
              />

              <ExpansionArticle
                title="Coming Soon"
              >
              </ExpansionArticle>
            </div>
            {/* <div className={classes.risk}>
              <ExpansionArticle
                title="Sentiment Analysis of Coach’s Posts"
                details="Percentage of the coach's social media posts that are positive, neutral, or negative"
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 8,
                  }}
                >
                  <FeedStatusCard
                    score={stats["positivePercentage"]}
                    label="Positives"
                    icon={<PositiveIcon />}
                  />
                  <FeedStatusCard
                    score={stats["neutralPercentage"]}
                    label="Neutrals"
                    icon={<NeutralIcon />}
                  />
                  <FeedStatusCard
                    score={stats["negativePercentage"]}
                    label="Negatives"
                    icon={<NegativeIcon />}
                  />
                </div>
              </ExpansionArticle>
            </div> */}
          </TabPanel>


        </Box>
      </div>



    </div>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { coachId } = ownProps;
  return {
    riskCategories: state.coach.risk["categories"][coachId],
    accountType: state.account.accountType,
    customTraits: state.coach.customTraits[coachId],
    stats: state.coach.stats[coachId],
    traits: state.coach.traits[coachId],
    basicInfo: state.coach.basic[coachId],
    team: state.account.team,
    behaviourRisk: state.coach.behaviourRisk[coachId],
  };
};

export default connect(mapStateToProps)(ExecutiveSummary);
