import axios from "axios";
import { isJwtExpired } from "../utils";

// const baseURL = process.env["REACT_APP_API_URL"];
// const baseURL = 'http://127.0.0.1:3434'
 //const baseURL = 'http://sandbox-fitinsights.eastus.cloudapp.azure.com' //process.env["REACT_APP_API_URL"];
const baseURL = 'https://fitinsights-api.net'

let apiHandler = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const getAuthHeader = () => {
  let accessToken = localStorage.getItem("@accessToken");

  if (!accessToken) {
    throw new Error("Access token not found");
  }
  let header = { Authorization: `Bearer ${accessToken}` };
  return header;
};

const handleError = async (error) => {
  try {
    let originalRequest = error.config;
    // catch access token expired
    if (error.response && error.response.data.msg === "Token has expired") {
      const refreshToken = localStorage.getItem("@refreshToken");
      if (!refreshToken) {
        // redirect to sign in
        throw new Error("Refresh token not found");
      }

      // validate refresh token
      if (isJwtExpired(refreshToken)) {
        // redirect to sign in
        throw new Error("Refresh token has expired");
      }

      // get new refresh token
      let headers = { Authorization: `Bearer ${refreshToken}` };
      let response = await apiHandler.post(
        "/auth/token/refresh",
        {},
        {
          headers,
        }
      );

      // refresh request failed
      if (response.data.status !== "Success") {
        // redirect to sign in
        throw new Error(response.data.notification);
      }

      const accessToken = response.data.info["access_token"];

      localStorage.setItem("@accessToken", accessToken);
      originalRequest.headers["Authorization"] = `Bearer ${accessToken}`;

      return apiHandler.request(originalRequest);
    }
  } catch (error) {
    sessionStorage.setItem(
      "@appAlert",
      "Session Expired. Please Sign in again"
    );
    window.location.replace("/signin");
    localStorage.removeItem("@accessToken");
    localStorage.removeItem("@refreshToken");
  }
};

apiHandler.interceptors.response.use((response) => {
  return response;
}, handleError);

export default apiHandler;
