import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { connect } from "react-redux";

const RiskComment = (props) => {
  let { comment } = props;

  return (
    <ListItem>
      <ListItemText secondary={comment.content} />
    </ListItem>
  );
};

const mapStateToProps = (state, ownProps) => {
  let { id } = ownProps;
  return {
    comment: state.coach.risk.comments[id],
  };
};
export default connect(mapStateToProps)(RiskComment);
