import React, { useContext, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { ActionContext } from "../../../contexts";
import { makeStyles } from "@material-ui/core/styles";
import ConferenceList from "./ConferenceList";
import appConfig from "../../../config";
import TeamDetails from "./ConferenceList/TeamDetails";
import Slide from "@material-ui/core/Slide";
import { useConfirm } from "material-ui-confirm";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="left"
      ref={ref}
      timeout={{ enter: 1000, exit: 1000 }}
      {...props}
    />
  );
});

let useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    height: `calc(100vh - 50px)`,
    zIndex: theme.zIndex.appBar,
  },
  list: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
    borderRight: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.between("lg", "xl")]: {
      maxWidth: 350,
    },
  },
  details: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
  },
  detailsText: {
    alignSelf: "center",
  },
  toolbar: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  container: {
    width: '100%',
    [theme.breakpoints.down("md")]: {
      maxWidth: 550,
    },
  },
}));

function ConferenceMain(props) {
  let classes = useStyles();
  let confirm = useConfirm();
  let dispatch = useDispatch();
  let { selectedId, selectedConferencePageId, isDrawerOpen } = props;

  let drawerWidth = isDrawerOpen
    ? appConfig.drawer.maxWidth
    : appConfig.drawer.minWidth;

  let actions = useContext(ActionContext);
  let { conferenceActions, dialogActions } = actions;

  useEffect(() => {
    conferenceActions.getConferenceList();

  }, []);


  return (
    <React.Fragment>
      <div className={classes.root} style={{ paddingLeft: '3rem' }}>
        <div className={classes.container}>
          {
            selectedConferencePageId === "list" ?
              <ConferenceList />
              :
              <TeamDetails />
          }
        </div>

      </div>
    </React.Fragment>
  )
}

let mapStateToProps = (state, ownProps) => {

  return {
    isDrawerOpen: state.ui.app.isDrawerOpen,
    selectedId: state.ui.conference.selectedConferenceId,
    selectedConferencePageId: state.ui.conference.selectedConferencePageId,
  };
};
export default connect(mapStateToProps)(ConferenceMain);