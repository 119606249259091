import React, { useContext, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "../../shared/TextField";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Button from "../../shared/Button";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import { useSelector } from "react-redux";
import { ActionContext } from "../../../contexts";
import { errorProps } from "../../../utils";
import { object, string } from "yup";
import { Formik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

let loginSchema = object().shape({
  email: string().email().required("Enter email address"),
  password: string().max(200).required("Enter password"),
});

let useStyles = makeStyles((theme) => ({
  root: {},
  heading: {
    display: "flex",
    flexDirection: "column",
  },
  alert: {
    marginBottom: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(4),
    fontWeight: 600,
  },
  inputs: {
    paddingBottom: theme.spacing(2),
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  link: {
    cursor: "pointer",
  },
  panel: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
}));

let Login = () => {
  let classes = useStyles();
  let history = useHistory();
  let { accountActions } = useContext(ActionContext);
  let loginState = useSelector((state) => state.ui.account.login);

  let { error, loading: isSubmitting } = loginState;

  let [isPasswordVisible, setPasswordVisibility] = React.useState(false);

  let inputPasswordType = isPasswordVisible ? "text" : "password";

  let handlePasswordVisibility = () => {
    setPasswordVisibility(!isPasswordVisible);
  };

  let handleRedirectToForgotPassword = () => {
    history.push("/signin/recovery");
  };

  let handleFormSubmit = (values, helpers) => {
    let data = {
      email: values.email,
      password: values.password,
      remember_me: false,
    };

    let handleSuccess = (accessToken, refreshToken, accountType) => {
      localStorage.setItem("@accessToken", accessToken);
      localStorage.setItem("@refreshToken", refreshToken);
      localStorage.setItem("@accountType", accountType);
      localStorage.removeItem("@appAlert");

      if (accountType === "COL_POSITION_COACH" || accountType === "COL_PSYCHOLOGIST" || accountType === "PRO_POSITION_COACH" || accountType === "PRO_SCOUTS" || accountType === "PRO_SECURITY"
        || accountType === "PRO_PSYCHOLOGIST") {
        history.push("/athlete-management");
      }
      else {
        history.push("/");
      }
    };

    let handleFail = () => {
      helpers.setValues({ password: "", email: values.email });
      helpers.setTouched({});
    };

    accountActions.signIn(data, handleSuccess, handleFail);
  };

  let handleErrorClose = () => {
    accountActions.resetLoginError();
  };

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("@appAlert");
      accountActions.resetAppAlert();
    };
  }, []);

  useEffect(() => {
    if (error && error.length > 0) {
      setTimeout(() => {
        handleErrorClose();
      }, 3500);
    }
  }, [error]);

  return (
    <div className={classes.root}>
      <div className={classes.heading}>
        <Typography variant="h5" className={classes.title}>
          Sign in to your account
        </Typography>
        {error ? (
          <div className={classes.alert}>
            <Alert severity="error" onClose={handleErrorClose}>
              {error}
            </Alert>
          </div>
        ) : null}
      </div>
      <div className={classes.main}>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={loginSchema}
          onSubmit={handleFormSubmit}
        >
          {(formik) => {
            let {
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched,
            } = formik;

            return (
              <form onSubmit={handleSubmit} noValidate>
                <div className={classes.inputs}>
                  <Grid container spacing={3}>
                    <Grid item sm={12}>
                      <TextField
                        name="email"
                        label="Email Address"
                        placeholder="Email Address"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={isSubmitting}
                        // size="small"
                        fullWidth
                        required
                        {...errorProps(errors, touched, "email")}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <TextField
                        name="password"
                        label="Password"
                        placeholder="Password"
                        type={inputPasswordType}
                        value={values.password}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handlePasswordVisibility}>
                                {isPasswordVisible ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        fullWidth
                        required
                        // size="small"
                        {...errorProps(errors, touched, "password")}
                      />
                    </Grid>
                    <Grid item sm={12} container justify="flex-start">
                      <Typography
                        variant="caption"
                        color="primary"
                        className={classes.link}
                        onClick={handleRedirectToForgotPassword}
                      >
                        Forgot Password?
                      </Typography>
                    </Grid>
                  </Grid>
                </div>

                <div className={classes.buttons}>
                  <Button
                    type="submit"
                    color="primary"
                    // size="large"
                    loading={isSubmitting}
                    disableElevation
                  >
                    Sign in
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
        <Paper elevation={4} className={classes.panel}>
          <Typography variant="subtitle2" style={{ marginBottom: 8 }}>
            FitInsights is your personal portal to player reports, 24/7.
          </Typography>
          <Typography variant="caption" color="textSecondary">
            With behavioral and social insight reports at your fingertips, you
            can quickly and easily find information you need to evaluate the fit
            of each player to navigate risk, maximize strengths and develop
            untapped potential for your team. You now have access to reports on
            all players your team requested.{" "}
          </Typography>
        </Paper>
      </div>
    </div>
  );
};

export default Login;
