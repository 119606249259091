import theme from "./theme";

let config = {
  appName: "FitInsights",
  appBarHeight: 64,
  drawer: {
    minWidth: 57,
    maxWidth: 250,
  },
  theme,
};

export default config;
