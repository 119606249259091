import { Grid, Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import React, { useContext, useEffect, useRef, useState } from "react";

import { connect } from "react-redux";
import appConfig from "../../../config";
import { ActionContext } from "../../../contexts";
import Button from "../../shared/Button";
import { TabPanel, Tabs } from "../../shared/Tabs";
import ConsentFormCard from "./ConsentFormCard";
import LinkedConsentForms from "./LinkedConsentForms";
// import allConsents from "modules/dashboard/components/ConsentDummyData"

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.default,
    height: "calc(100vh - 82px)",
    marginTop: theme.spacing(4),
  },
  content: {
    overflowY: "scroll",
    paddingTop: theme.spacing(4),
    "&::-webkit-scrollbar": {
      width: 7,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.background.paper,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[200],
      opacity: 0.5,
    },
    height: "100%",
  },
}));

const Consent = (props) => {
  let classes = useStyles();
  let inputFileRef = useRef();
  let [selectedIndex, setSelectedIndex] = useState(0);
  let { uploadingConsentForms, isDrawerOpen, consentFormsById } = props;
  let drawerWidth = isDrawerOpen
    ? appConfig.drawer.maxWidth
    : appConfig.drawer.minWidth;
  let { consentActions, playerActions } = useContext(ActionContext);

  // const consentFormsById = Object.keys(allConsents["pending_consent_forms"])

  let handleChange = (e, index) => {
    setSelectedIndex(index);
  };

  let handleFileChange = (e) => {
    let files = e.target.files;
    let data = new FormData();

    Object.keys(files).map((file) => {
      data.append(file, files[file]);
    });

    let handleSuccess = () => {
      inputFileRef.current.value = null;
    };

    consentActions.uploadConsentForms(data, handleSuccess);
  };

  let handleUploadClick = () => {
    inputFileRef.current.click();
  };

  useEffect(() => {
    consentActions.getPendingConsentForms();
    consentActions.getLinkedConsentForms();
    playerActions.getPlayerList();
  }, []);

  return (
    <div className={classes.root} style={{ paddingLeft: '0rem' }}>
      <Toolbar style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            marginLeft: 160,
          }}
        >
          <Tabs
            labels={["Pending", "Linked"]}
            value={selectedIndex}
            onChange={handleChange}
          />
        </div>
        <div>
          <input
            type="file"
            ref={inputFileRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
            accept=".pdf"
            multiple
          />
          <div>
            <Button
              color="primary"
              onClick={handleUploadClick}
              loading={uploadingConsentForms}
              size="small"
              style={{ minWidth: 162 }}
              // disabled={true}
            >
              Upload Consent Forms
            </Button>
          </div>
        </div>
      </Toolbar>

      <div className={classes.content}>
        <Container maxWidth="md">
          <TabPanel index={0} value={selectedIndex}>
            {consentFormsById.length > 0 ? (
              <Grid container spacing={4}>
                {consentFormsById.map((id) => {
                  return (
                    <Grid
                      key={id}
                      lg={3}
                      md={3}
                      sm={6}
                      justifyContent="center"
                      container
                      item
                    >
                      <ConsentFormCard consentFormId={id} />
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  height: 200,
                }}
              >
                <Typography
                  variant="caption"
                  color="textSecondary"
                  align="center"
                >
                  Please Add Consent Forms
                </Typography>
              </div>
            )}
          </TabPanel>
          <TabPanel index={1} value={selectedIndex}>
            <LinkedConsentForms />
          </TabPanel>
        </Container>
      </div>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    loadingPlayerList: state.ui.player.loadingPlayerList,
    loadingConsentForms: state.ui.consent.loadingConsentForms,
    uploadingConsentForms: state.ui.consent.uploadingConsentForms,
    consentFormsById: state.consent.consentForms.allIds,
    isDrawerOpen: state.ui.app.isDrawerOpen,
  };
};
export default connect(mapStateToProps)(Consent);
