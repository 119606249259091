import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import AssessmentIcon from "@material-ui/icons/Assessment";
import CreateTeamDialog from "./CreateTeamDialog"
import { ActionContext } from "../../../../contexts";

const CreateTeam = (props) => {
  const { type } = props;
  const { dialogActions } = useContext(ActionContext);

  const handleClick = () => {
    dialogActions.openDialog(
      <CreateTeamDialog type={type}  />,
      {
        maxWidth: "xs",
      }
    );
  };

  return (
    <div style={{ marginLeft: 8 , display : "flex"}}>
      <span 
      style={{  border: '1px solid', padding: '0.3rem 0.5rem', borderRadius: '9px', margin: '0rem 1rem', color: '#0971ce', fontWeight: 700, cursor: 'pointer'}}
        // variant="contained"
        color="default"
        startIcon={<AssessmentIcon />}
        onClick={handleClick}
        disableElevation
      >
        Create Team
      </span>
    </div>
  );
};

export default CreateTeam;
