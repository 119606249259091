import React from "react";
import PrivateRoute from "./PrivateRoute";
import { connect } from "react-redux";

let PrivateRouteContainer = ({
  isAuthenticated = false,
  accountType,
  ...rest
}) => {
  return (
    <PrivateRoute
      accountType={accountType}
      isAuthenticated={isAuthenticated}
      {...rest}
    />
  );
};

let mapStateToProps = (state) => {
  return {
    isAuthenticated: state.account.isAuthenticated,
    accountType: state.account.accountType,
  };
};

export default connect(mapStateToProps)(PrivateRouteContainer);
