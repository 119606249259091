import React from "react";
import Typography from "@material-ui/core/Typography";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "../../../shared/Editor";
import { Field } from "formik";
import { makeStyles } from "@material-ui/core/styles";

let useStyles = makeStyles((theme) => ({
  heading: {
    // marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(3),
    maxWidth: 480,
    fontWeight: 500,
    lineHeight: "60px",
  },
  form: {
    paddingTop: theme.spacing(1),
    maxWidth: '100%',
    marginRight: '1rem',
  },
  editor: {
    padding: theme.spacing(0, 1),
    border: "1px solid #f1f1f1",
  },
}));

let CreatePlayerNotes = (props) => {
  let classes = useStyles();

  let [editorState, setEditorState] = React.useState(EditorState.createEmpty());

  let onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    props.setFieldValue("notes", convertToRaw(editorState.getCurrentContent()));
  };

  return (
    <React.Fragment>
      <Typography variant="h4" className={classes.heading}>
        Is there more to add?
      </Typography>
      <div className={classes.form}>
        <Editor
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          placeholder="Begin typing..."
          classes={{ editor: classes.editor }}
        />

        <Field name="notes">
          {({ field, form: { touched, errors }, meta, ...rest }) => {
            return (
              <div>
                <input
                  style={{ display: "none" }}
                  type="text"
                  placeholder="Email"
                  name="notes"
                  {...field}
                />
              </div>
            );
          }}
        </Field>
      </div>
    </React.Fragment>
  );
};
export default CreatePlayerNotes;
