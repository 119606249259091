import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { contactsApi } from "api";
import CircularProgress from "modules/shared/CircularProgress";
import { showSuccessMessage } from "modules/shared/Snackbar";
import Tabs, { TabPanel } from "modules/shared/Tabs";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import appConfig from "../../../config";
import History from "./History";
import Query from "./Query";
import Result from "./Result";

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "calc(100vh - 50px)",
    width: "100%",
    [theme.breakpoints.only("xs")]: {
      height: "calc(100vh - 100px)",
    },
  },
  list: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
    // borderRight: `1px solid ${theme.palette.divider}`,
    // backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.between("lg", "xl")]: {
      maxWidth: '100%',
      marginTop: '1rem'
    },
  },
  details: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    boxSizing: "border-box",
    overflowY: "scroll",
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
  },
  detailsText: {
    alignSelf: "center",
  },
  toolbar: {
    display: "flex",
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  media: {
    minWidth: 140,
  },
}));

let Contacts = (props) => {
  let classes = useStyles();
  let dispatch = useDispatch();
  let { isDrawerOpen } = props;
  let [currentTab, setCurrentTab] = useState(0);
  let [setQuery] = useState({});
  let [currentHistory, setCurrentHistory] = useState({
    query: {},
    results: [],
  });
  let [queryId, setQueryId] = useState("");
  let [searching, setSearching] = useState(false);
  let [queries, setQueries] = useState([]);
  let [results, setResults] = useState([]);
  let drawerWidth = isDrawerOpen
    ? appConfig.drawer.maxWidth
    : appConfig.drawer.minWidth;

  const handleFormSubmit = async (values) => {
    let networks = [];
    let usernames = [];

    if (values.twitter.length > 0) {
      //   networks.push("twitter");
      usernames.push(values.twitter);
    }

    if (values.instagram.length > 0) {
      //   networks.push("instagram");
      usernames.push(values.instagram);
    }

    const query = {
      full_name: values.name,
      networks,
      usernames,
      city: values.city,
      keywords: values.keywords.split(","),
    };

    setSearching(true);
    const data = await contactsApi.searchSocialProfiles(query);
    setQueryId(data?._id);
    setResults(data?.results);
    setSearching(false);
    setQuery(data?.query);
  };

  const handleTabChange = (event, value) => {
    setCurrentTab(value);
  };

  const handleDeleteResult = async (resultId) => {
    const data = await contactsApi.deleteSocialSearchResult(queryId, resultId);
    setQuery(data.query);
    setQueryId(data._id);
    setResults(data.results);
    dispatch(showSuccessMessage("Record deleted successfully"));
  };

  const fetchData = async () => {
    const data = await contactsApi.getSocialSearches();
    setQueries(data);
  };

  const handleSelectHistory = (index) => {
    setCurrentHistory(queries[index]);
  };

  const handleDeleteHistory = async (queryId) => {
    await contactsApi.deleteSocialSearch(queryId);
    const updatedQueries = queries.filter((q) => q._id !== queryId);
    setQueries(updatedQueries);
    if (currentHistory._id === queryId) {
      setCurrentHistory({ query: {}, results: [] });
    }
    dispatch(showSuccessMessage("Record deleted successfully"));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <div className={classes.root} style={{ paddingLeft: '5rem' }}>
        <div className={classes.list}>
          <div
            style={{
              paddingLeft: 24,
              paddingRight: 24,
              height: 48,
              borderBottom: "1px solid #dfdfdf",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tabs
              labels={["Search", "History"]}
              // labels={["Search"]}
              value={currentTab}
              onChange={handleTabChange}
            />
          </div>
          <div
            style={{
              padding: 24,
              // backgroundColor: "#fff",
              overflowY: "scroll",
            }}
          >
            <TabPanel value={currentTab} index={0}>
              <Query onSubmit={handleFormSubmit} searching={searching} />
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <List>
                {queries.map((query, index) => (
                  <History
                    query={query.query}
                    index={index}
                    key={index}
                    onSelect={handleSelectHistory}
                    onDelete={handleDeleteHistory}
                    id={query._id}
                  />
                ))}
              </List>
            </TabPanel>
          </div>
        </div>
        <Hidden mdDown>
          <div className={classes.details}>
            <TabPanel index={0} value={currentTab}>
              {searching ? (
                <div
                  style={{
                    padding: 8,
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyItems: "center",
                      marginTop: 200,
                    }}
                  >
                    <Typography variant="body1">Finding Profiles...</Typography>
                    <Typography variant="caption" style={{ paddingBottom: 16 }}>
                      This will take up to 1 minute to complete
                    </Typography>
                    <CircularProgress size={72} thickness={3} color="primary" />
                  </div>
                </div>
              ) : (
                <div style={{ padding: 8, height: "100%" }}>
                  <Grid container spacing={2} justify="center">
                    {
                      results?.length === 0 ?
                        <Grid item xs={12} container justify="center">
                          <div 
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyItems: "center",
                            marginTop: 200,
                          }}
                          >No Results Found</div>
                        </Grid>
                        :
                        results.map((result, index) => (
                          <Grid item xs={12} key={index} container justify="center">
                            <Result
                              result={result}
                              onDelete={(f) => f}
                              withDelete={true}
                            />
                          </Grid>
                        ))}
                  </Grid>
                </div>
              )}
            </TabPanel>
            <TabPanel index={1} value={currentTab}>
              <div style={{ padding: 8, height: "100%" }}>
                <Grid container spacing={2} justify="center">
                  {currentHistory.results.map((result, index) => (
                    <Grid item xs={12} key={index} container justify="center">
                      <Result
                        result={result}
                        onDelete={handleDeleteResult}
                        withDelete={false}
                      />
                    </Grid>
                  ))}
                </Grid>
              </div>
            </TabPanel>
          </div>
        </Hidden>
      </div>
    </React.Fragment>
  );
};

let mapStateToProps = (state) => {
  return {
    isDrawerOpen: state.ui.app.isDrawerOpen,
  };
};
export default connect(mapStateToProps)(Contacts);
