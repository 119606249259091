import React, { useState, useContext, useEffect } from 'react'
import { DialogTitle, Typography, TextField, Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import PersonIcon from "@material-ui/icons/Person";
import AssesmentIcon from "@material-ui/icons/Assessment";
import { statusTypes } from "modules/players/constants";
import { ActionContext } from "../../../../contexts";
import { connect } from "react-redux";
import { blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { capitalize, formatDate } from "../../../../utils";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  enabled: {
    color: theme.palette.success.main,
  },
  pending: {
    color: theme.palette.warning.main,
  },
}));

const ReProceeDialog = (props) => {
  const classes = useStyles();
  const { playerId, status, playerInfo, type, submittingBrainReceptivity } = props;
  const { playerActions } = useContext(ActionContext);
  const [state, setState] = React.useState({
    name: '',
  })
  const { closeDialog } = props;

  const handleCloseDialog = () => {
    closeDialog();
  };


  function handleFormDataChange(key, value) {
    setState(prevState => ({
      ...prevState,
      [key]: value
    }));
  }
  const handleConfirmPlayer = (data) => {
    const handleSucess=()=>{
      handleCloseDialog();
    }
    playerActions.getReceptivitiBig5Score(data && data.id, type,handleSucess);
  }

  return (
    <React.Fragment>
      <div style={{ display: 'flex', alignItems: 'center', background: '#cdcdcd' }}>
        <DialogTitle id="simple-dialog-title" style={{ flex: 1 }}>
          Re-process
        </DialogTitle>
        <div style={{ margin: '8px 16px 8px 8px' }}>
          <IconButton onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div style={{ margin: '30px' }}>
        <Typography style={{ fontSize: '18x', color: 'green' }} >
          Are you sure you want to re-process the scores through Receptiviti with all existing language for <span style={{ fontWeight: 'bold' }}>{playerInfo.name}</span>?
        </Typography>
        {/* <div style={{ marginTop: '25px', color: 'blue' }}>
          <Typography><span style={{ fontWeight: 'bold' }}>Note: </span>The player processed last time on {formatDate(playerInfo.analysedOn)}  </Typography>
        </div> */}
        <div style={{ marginTop: '8px' }}>
          <Typography>Type player name to confirm:</Typography>
          <TextField
            style={{ width: '100%', marginTop: '8px' }}
            variant="outlined"
            size="small"
            value={state.name}
            onChange={(ev) => handleFormDataChange('name', ev.target.value)}
          />
        </div>
        <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button color="primary" variant="contained" style={{ marginLeft: '8px' }} disabled={!state.name || state.name !== playerInfo.name}
            onClick={() => handleConfirmPlayer(playerInfo)}>
            {submittingBrainReceptivity ? <CircularProgress size={24} color="inherit" /> : 'Confirm'}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};



const mapStateToProps = (state, ownProps) => {
  const { playerId } = ownProps;

  return {
    submittingBrainReceptivity: state.ui.player.submittingBrainReceptivity,
  };
};
export default connect(mapStateToProps)(ReProceeDialog);
