import React, { useState } from "react";
import ExpansionArticle from "../ExpansionArticle";
import ChartAndDetails from "../ChartAndDetails";
import { chartColors } from "modules/players/constants";
import { connect } from "react-redux";
import { Box, Tabs, Tab, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import '../../../../index.css';
import { RadialGauge } from "modules/shared/Charts";
import { round } from "utils";

let useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: 'Prometo',
        fontSize: '14px',
        color: '#fcb643',
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}


let roundTo1PrecisionPoint = (value) => {
    return round(value, 0);
};

let options = {
    plotOptions: {
        radialBar: {
            hollow: {
                margin: 0,
                size: "40%",
                background: "#293450",
            },
            track: {
                dropShadow: {
                    enabled: true,
                    top: 2,
                    left: 0,
                    blur: 4,
                    opacity: 0.15,
                },
            },
            dataLabels: {
                name: {
                    show: false,
                    offsetY: -10,
                    color: "#fff",
                    fontSize: "13px",
                },
                value: {
                    color: "#fff",
                    fontSize: "14px",
                    offsetY: 6,
                    show: true,
                },
            },
        },
    },
    stroke: {
        lineCap: "round",
    },
};

const Attributes = (props) => {
    let classes = useStyles();
    let { playerId, customTraits, team, accountType } = props;

    const [selectedPage, setSelectedPage] = useState(0)

    const handleChange = (event, newValue) => {
        setSelectedPage(newValue);
    };

    let handleColors = (score) => {
        if (0 <= score && score < 25) {
            return chartColors.green;
        } else if (25 <= score && score < 50) {
            return chartColors.yellow;
        } else if (50 <= score && score < 75) {
            return chartColors.orange;
        } else if (75 <= score && score < 100) {
            return chartColors.red;
        }
    };

    return (
        <React.Fragment>
            <Box
                sx={{ flexGrow: 1, display: 'flex', margin: '0px 5rem' }}
            >
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={selectedPage}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                    <Tab className={selectedPage === 0 ? classes.root : ''} label="Character" {...a11yProps(0)} />
                    <Tab className={selectedPage === 1 ? classes.root : ''} label="Coachability" {...a11yProps(1)} />
                    <Tab className={selectedPage === 2 ? classes.root : ''} label="Composure" {...a11yProps(2)} />
                    <Tab className={selectedPage === 3 ? classes.root : ''} label="Leadership" {...a11yProps(3)} />
                </Tabs>
                <TabPanel value={selectedPage} index={0} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
                    <div>
                        <ExpansionArticle
                            title={customTraits["character"]["label"]}
                            details={customTraits["character"]["description"]}
                        //   details="Character is defined as an assessment of an individual's mental and emotional traits, related to integrity, humility, empathy, and work ethic, that drive one’s general conduct."
                        >
                            <ChartAndDetails
                                diagnosis={customTraits["character"]["text"]}
                                details="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
                                score={customTraits["character"]["score"]}
                                options={options}
                                handleColors={handleColors}
                            />
                            <div style={{ marginBottom: 48, width: "100%", display: "flex", justifyContent: "space-evenly", }}>
                                {Object.values(customTraits["character"].sub).map((subTrait, index) => {
                                    return (
                                        <div key={index} style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: 150, }}>
                                            <RadialGauge score={roundTo1PrecisionPoint(subTrait["score"])} />
                                            {
                                                (accountType === "SUPER_ADMIN" || accountType === "BCA_PSYCHOLOGIST" || accountType === "BCA_CHIEF_PSYCHOLOGIST") ?
                                                    <p style={{ textAlign: "center", marginTop: 0, marginBottom: '0' }}>
                                                        {roundTo1PrecisionPoint(subTrait["score"])}%
                                                    </p>
                                                    :
                                                    ""
                                            }
                                            <p style={{ textAlign: "center", marginTop: 0 }}>
                                                {`+ ${subTrait["left_label"]}`}
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        </ExpansionArticle>
                    </div>
                </TabPanel>
                <TabPanel value={selectedPage} index={1} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
                    <div>
                        <ExpansionArticle
                            title={customTraits["coachability"]["label"]}
                            details={customTraits["coachability"]["description"]}
                        //   details="Coachability is defined as an individual’s ability to listen, learn, receive and utilize feedback, manage criticism, and adapt their skill set in order perform at their optimal level."
                        >
                            <ChartAndDetails
                                diagnosis={customTraits["coachability"]["text"]}
                                details="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
                                score={customTraits["coachability"]["score"]}
                                options={options}
                                handleColors={handleColors}
                            />
                            <div style={{ marginBottom: 48, width: "100%", display: "flex", justifyContent: "space-evenly", }}>
                                {Object.values(customTraits["coachability"].sub).map((subTrait, index) => {
                                    return (
                                        <div key={index} style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: 150, }}>
                                            <RadialGauge score={roundTo1PrecisionPoint(subTrait["score"])} />
                                            {
                                                (accountType === "SUPER_ADMIN" || accountType === "BCA_PSYCHOLOGIST" || accountType === "BCA_CHIEF_PSYCHOLOGIST") ?
                                                    <p style={{ textAlign: "center", marginTop: 0, marginBottom: '0' }}>
                                                        {roundTo1PrecisionPoint(subTrait["score"])}%
                                                    </p>
                                                    :
                                                    ""
                                            }
                                            <p style={{ textAlign: "center", marginTop: 0 }}>
                                                {`+ ${subTrait["left_label"]}`}
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        </ExpansionArticle>
                    </div>
                </TabPanel>
                <TabPanel value={selectedPage} index={2} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
                    <div>
                        <ExpansionArticle
                            title={customTraits["composure"]["label"]}
                            details={customTraits["composure"]["description"]}
                        //   details="Composure is defined as an individual’s ability to remain calm, persistent, and in control while under pressure or after mistakes as well as an individual's ability to thrive in high pressure situations and demonstrate enhanced performance outcomes."
                        >
                            <ChartAndDetails
                                diagnosis={customTraits["composure"]["text"]}
                                details="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
                                score={customTraits["composure"]["score"]}
                                options={options}
                                handleColors={handleColors}
                            />
                            <div style={{ marginBottom: 48, width: "100%", display: "flex", justifyContent: "space-evenly", }}>
                                {Object.values(customTraits["composure"].sub).map((subTrait, index) => {
                                    return (
                                        <div key={index} style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: 150, }}>
                                            <RadialGauge score={roundTo1PrecisionPoint(subTrait["score"])} />
                                            {
                                                (accountType === "SUPER_ADMIN" || accountType === "BCA_PSYCHOLOGIST" || accountType === "BCA_CHIEF_PSYCHOLOGIST") ?
                                                    <p style={{ textAlign: "center", marginTop: 0, marginBottom: '0' }}>
                                                        {roundTo1PrecisionPoint(subTrait["score"])}%
                                                    </p>
                                                    :
                                                    ""
                                            }
                                            <p style={{ textAlign: "center", marginTop: 0 }}>
                                                {`+ ${subTrait["left_label"]}`}
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        </ExpansionArticle>
                    </div>
                </TabPanel>
                <TabPanel value={selectedPage} index={3} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
                    <div>
                        <ExpansionArticle
                            title={customTraits["leadership"]["label"]}
                            details={customTraits["leadership"]["description"]}
                        //   details="Leadership is defined as an individual’s ability to communicate a common goal as well as influence, motivate, and inspire others by demonstrating confidence and accountability to self and others."
                        >
                            <ChartAndDetails
                                diagnosis={customTraits["leadership"]["text"]}
                                details="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
                                score={customTraits["leadership"]["score"]}
                                options={options}
                                handleColors={handleColors}
                            />
                            <div style={{ marginBottom: 48, width: "100%", display: "flex", justifyContent: "space-evenly", }}>
                                {Object.values(customTraits["leadership"].sub).map((subTrait, index) => {
                                    return (
                                        <div key={index} style={{ display: "flex", flexDirection: "column", alignItems: "center", maxWidth: 150, }}>
                                            <RadialGauge score={roundTo1PrecisionPoint(subTrait["score"])} />
                                            {
                                                (accountType === "SUPER_ADMIN" || accountType === "BCA_PSYCHOLOGIST" || accountType === "BCA_CHIEF_PSYCHOLOGIST") ?
                                                    <p style={{ textAlign: "center", marginTop: 0, marginBottom: '0' }}>
                                                        {roundTo1PrecisionPoint(subTrait["score"])}%
                                                    </p>
                                                    :
                                                    ""
                                            }
                                            <p style={{ textAlign: "center", marginTop: 0 }}>
                                                {`+ ${subTrait["left_label"]}`}
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                        </ExpansionArticle>
                    </div>
                </TabPanel>

            </Box>
        </React.Fragment>
    );
};

let mapStateToProps = (state, ownProps) => {
    let { playerId } = ownProps;
    return {
        customTraits: state.player.customTraits[playerId],
        team: state.account.team,
        accountType: state.account.accountType,
    };
};

// higher order component for checking payment
export default connect(mapStateToProps)(Attributes);
