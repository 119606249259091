export const accountTypes = {
  superAdmin: "SUPER_ADMIN",
  bcaDdiAdmin: "BCA_DDI_ADMIN",
  bcaPsychologist: "BCA_PSYCHOLOGIST",
  bcaChiefPsychologist: "BCA_CHIEF_PSYCHOLOGIST",
  orgAdmin: "ORG_ADMIN",
  orgUser: "ORG_USER",
  orgPsychologist: "ORG_PSYCHOLOGIST",
  candidate: "CANDIDATE",

  colAthleticDirector : "COL_ATHLETIC_DIRECTOR",
  colHeadCoach : "COL_HEAD_COACH",
  colPositionCoach : "COL_POSITION_COACH",
  colPsychologist : "COL_PSYCHOLOGIST",
  proGeneralManager : "PRO_GENERAL_MANAGER",
  proHeadCoach : "PRO_HEAD_COACH",
  proPositionCoach : "PRO_POSITION_COACH",
  proScouts : "PRO_SCOUTS",
  proSecurity : "PRO_SECURITY",
  proPsychologist : "PRO_PSYCHOLOGIST"
};
