export const REQUEST_SIGNIN = "REQUEST_SIGNIN";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAILED = "SIGNIN_FAILED";

export const REQUEST_RESET_PASSWORD = "REQUEST_RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

export const REQUEST_RESEND_VERIFICATION = "REQUEST_RESEND_VERIFICATION ";
export const RESEND_VERIFICATION_SUCCESS = "RESEND_VERIFICATION_SUCCESS";
export const RESEND_VERIFICATION_FAILED = "RESEND_VERIFICATION_FAILED";

export const REQUEST_VERIFY_ACCOUNT = "REQUEST_VERIFY_ACCOUNT";
export const VERIFY_ACCOUNT_SUCCESS = "VERIFY_ACCOUNT_SUCCESS";
export const VERIFY_ACCOUNT_FAILED = "VERIFY_ACCOUNT_FAILED";
export const SET_VERIFY_ERROR = "SET_VERIFY_ERROR";
export const SET_VERIFY_EMAIL = "SET_VERIFY_EMAIL";

export const REQUEST_SIGNUP = "REQUEST_SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILED = "SIGNUP_FAILED";

export const APP_SIGNOUT = "APP_SIGNOUT";

export const REQUEST_CHANGE_PASSWORD = "REQUEST_CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";

export const REQUEST_GET_PROFILE = "REQUEST_GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILED = "GET_PROFILE_FAILED";

export const REQUEST_GET_USER_TYPES = "REQUEST_GET_USER_TYPES";
export const GET_USER_TYPES_SUCCESS = "GET_USER_TYPES_SUCCESS";
export const GET_USER_TYPES_FAILED = "GET_USER_TYPES_FAILED";

export const RESET_ACCOUNT_CREATION_ERROR = "RESET_ACCOUNT_CREATION_ERROR";
export const SET_APP_ALERT = "SET_APP_ALERT";
export const RESET_APP_ALERT = "RESET_APP_ALERT";
export const RESET_LOGIN_ERROR = "RESET_LOGIN_ERROR";

export const REQUEST_GET_NOTIFICATIONS = "REQUEST_GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAILED = "GET_NOTIFICATIONS_FAILED";

export const REQUEST_MARK_NOTIFICATIONS_AS_READ =
  "REQUEST_MARK_NOTIFICATIONS_AS_READ";
export const MARK_NOTIFICATIONS_AS_READ_SUCCESS =
  "MARK_NOTIFICATIONS_AS_READ_SUCCESS";
export const MARK_NOTIFICATIONS_AS_READ_FAILED =
  "MARK_NOTIFICATIONS_AS_READ_FAILED";
