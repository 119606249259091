import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

let useStyles = makeStyles((theme) => ({
  article: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  articleHeading: {
    padding: theme.spacing(2, 2, 1, 2),
  },
  articleContent: {},
  contentItem: {
    padding: theme.spacing(2),
    display: "flex",
  },
  contentLabel: {
    textTransform: "uppercase",
    fontSize: 11,
    fontWeight: 700,
    width: 176,
    marginRight: theme.spacing(2),
  },
  contentValue: {
    flex: 1,
    textTransform: "capitalize",
  },
  contentValue2: {
    flex: 1,
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const Article = ({ user = {} }) => {
  let classes = useStyles();

  return (
    <Paper elevation={0} variant="outlined" className={classes.article}>
      <div className={classes.articleHeading}>
        <Typography variant="h6">Personal Info</Typography>
      </div>
      <List>
        <ListItem className={classes.contentItem}>
          <Typography
            variant="button"
            color="textSecondary"
            className={classes.contentLabel}
          >
            Name
          </Typography>
          <Typography variant="body1" className={classes.contentValue}>
            {user.fullName}
          </Typography>
        </ListItem>
        <ListItem className={classes.contentItem}>
          <Typography
            variant="button"
            color="textSecondary"
            className={classes.contentLabel}
          >
            Email
          </Typography>
          <Typography variant="body1" className={classes.contentValue2}>
            {user.email}
          </Typography>
        </ListItem>
        <ListItem className={classes.contentItem}>
          <Typography
            variant="button"
            color="textSecondary"
            className={classes.contentLabel}
          >
            Team
          </Typography>
          <Typography variant="body1" className={classes.contentValue2}>
            {user.team}
          </Typography>
        </ListItem>
        {
          user.sport !== "" ?
            <ListItem className={classes.contentItem}>
              <Typography
                variant="button"
                color="textSecondary"
                className={classes.contentLabel}
              >
                Sport
              </Typography>
              <Typography variant="body1" className={classes.contentValue2}>
                {user.sport}
              </Typography>
            </ListItem>
            :
            ""
        }
      </List>
    </Paper>
  );
};

export default Article;
