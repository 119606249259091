import apiHandler, { getAuthHeader } from "./apiHandler";

export const searchSocialProfiles = async (
  data = {
    full_name: "",
    networks: [],
    usernames: [],
    city: "",
    keywords: [""],
  }
) => {
  try {
    const headers = getAuthHeader();

    const response = await apiHandler.post("/social_profiles/search", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    return response.data.info;
  } catch (err) {
    throw new Error(err);
  }
};

export const getSocialSearches = async () => {
  try {
    const headers = getAuthHeader();

    const response = await apiHandler.get("/social_searches/load", {
      headers,
    });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    return response.data.info.searches;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteSocialSearch = async (queryId) => {
  try {
    const headers = getAuthHeader();

    const response = await apiHandler.get(
      `/social_search/delete/query/${queryId}`,
      {
        headers,
      }
    );

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    return response.data.info;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteSocialSearchResult = async (queryId, resultId) => {
  try {
    const headers = getAuthHeader();

    const response = await apiHandler.get(
      `/social_search/delete/result/${queryId}/${resultId}`,
      {
        headers,
      }
    );

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    return response.data.info.updated_entry;
  } catch (err) {
    throw new Error(err);
  }
};

const contact = {
  searchSocialProfiles,
  getSocialSearches,
  deleteSocialSearch,
  deleteSocialSearchResult,
};

export default contact;
