import React, { useState, useContext } from 'react'
import { Button, Card, CardHeader, Avatar, ListItemAvatar, Badge, Tooltip, Grid, InputBase, Slide, Select } from "@material-ui/core"
import { connect, useDispatch } from "react-redux";
import { makeStyles, useTheme, fade } from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/ErrorRounded";
import ListLoading from "./ListLoading"
import appConfig from "../../../config";
import SearchIcon from '@material-ui/icons/Search';
import FilterAltIcon from '@material-ui/icons/FilterList';
import { ActionContext } from "../../../contexts";
import PlayerDetails from "../components/PlayerDetails";
// import allPlayers from 'modules/dashboard/components/PlayersJson'
import _ from 'lodash'
import SortingPopover from './SortingPopOver';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const Transition = React.forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="left"
            ref={ref}
            timeout={{ enter: 1000, exit: 1000 }}
            {...props}
        />
    );
});

let useStyles = makeStyles((theme) => ({
    // root: {
    //     height: 80,
    // },
    root: {
        backgroundColor: theme.palette.background.default,
        display: "flex",
        height: `calc(100vh - 50px)`,
        zIndex: theme.zIndex.appBar,
    },
    container: {
        padding: 16,
        // paddingTop: 30,
        // marginLeft: 170,
        marginRight: "auto",
        // flexWrap: "wrap",
        [theme.breakpoints.down("md")]: {
            maxWidth: 550,
        },
    },
    FullName: {
        fontWeight: '700',
        color: '#333333'
    },
    Team: {
        color: '#666666',
        fontSize: '12px'
    },
    Status: {
        fontSize: '11px',
        padding: '0.1rem 0.7rem',
        borderRadius: '0.9rem',
        fontWeight: 500,
    },
    avatar: {
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    secondaryAction: {
        display: "flex",
        flexDirection: "column",
    },
    badgeContent: {
        width: 18,
        height: 18,
        backgroundColor: theme.palette.background.paper,
        border: `2px solid #fff`,
    },
    card: {
        // backgroundImage: 'url("/assets/images/illustrations/ChatBackground.jpeg")',
        // boxShadow: '3px 3px 10px 3px #2dbcb2',
        // width: 331px;
        // height: 72px;
        flexGrow: 0,
        // margin: '18px 20px 20px',
        padding: '14px',
        borderRadius: '9px',
        boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.06)',
        backgroundColor: '#fff',
        '&:hover': {
            // boxShadow: '3px 3px 10px 3px #F4AB3D',
            cursor: 'pointer'
        }
    },
    search: {
        justifyContent: 'space-between',
        display: 'flex',
        position: 'relative',
        marginBottom: '14px',
        marginRight: '0px',
        marginTop: '14px',
        borderRadius: '10px',
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            // backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        //marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            // marginLeft: theme.spacing(3),
            display: 'flex',
            alignItems: 'center',
            color: '#0971ce',
            fontWeight: 'bold',
            fontSize: '18px',
            width: 'auto',
            borderBottom: '1px solid #fdb525',
            borderRadius: 'inherit',
            paddingBottom: '1rem',
            background: 'none'
        },
    },
    searchIcon: {
        padding: '0px 5px',
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.primary.main
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: '8px 15px',
        // vertical padding + font size from searchIcon
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        paddingLeft: '10px',
        width: '100%',
        // border: '1px solid #2dbcb2',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
            border: '1px solid #0971ce',
        },
        borderRadius: '10px',
        // boxShadow: '3px 1px 9px 0px #cdcdcd',
    },
    AvatarCellDesign: {
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.main,
        fontSize: '12px',
        height: '32px',
        width: '32px',
        boxShadow: '3px 3px 8px 0px #cdcdcd',
        color: '#fff',
        fontWeight: '700'
    },
    btnPrimary: {
        color: '#fff',
        border: '1px solid',
        padding: '10px',
        background: '#007fdb',
        // width: '13rem',
        marginRight: '10px',
        borderRadius: '20px',
        height: '30px',
        '&:hover': {
            background: '#007fdb',
            color: '#fff'
        },
    },
    btnSecondary: {
        // color: '#fff',
        // border: '1px solid #326fc7',
        // height: '30px',
        // padding: '10px',
        // marginRight: '10px',
        // borderRadius: '20px',
        // background: '#326fc7',
        // '&:hover': {
        //     background: '#326fc7',
        //     color: '#fff'
        // },
        color: theme.palette.secondary.main,
        border: '1px solid',
        // width: '13rem',
        padding: '10px',
        height: '30px',
        marginRight: '10px',
        borderRadius: '20px',
        '&:hover': {
            background: theme.palette.secondary.main,
            color: '#fff'
        }
    },
    SortByAlign: {
        border: '1px solid #cdcdcd',
        fontSize: '12px',
        padding: '4px 8px  4px  0px',
        borderRadius: '7px',
        '&:hover': {
            backgroundColor: '#cdcdcd',
        },
        marginLeft: '10px'
    },
    ArrowAlignSort: {
        display: 'inline-grid',
        position: 'relative',
        top: '-5px'
    },
    arrowAlignFlex: {
        height: '0.5em'
    },
}));

function List(props) {
    let classes = useStyles();
    const { pageChange, loading, isDrawerOpen, allPlayers, filterValue, isFilteredApply, isFiltered, updateFilter, accountType, sortingOrderBy, sortingOrder } = props;

    let actions = useContext(ActionContext);

    let { playerActions, dialogActions, sportsActions } = actions;

    let drawerWidth = isDrawerOpen
        ? appConfig.drawer.maxWidth
        : appConfig.drawer.minWidth;


    const [searchText, setSearchText] = useState(undefined);

    function handleSearchText(v) {
        setSearchText(v);
    }

    const handleCardClick = (playerId) => {

        // return () => {
        playerActions.getPlayer(playerId);
        playerActions.selectPlayer(playerId);
        // playerActions.postStartAnalyze(playerId);

        dialogActions.openDialog(<PlayerDetails playerId={playerId} />, {
            fullScreen: true,
            TransitionComponent: Transition,
        });

        // };
    };

    const handleResetFilters = () => {
        // playerActions.getAllCandidates();
        let model = {
            conference: [],
            sport: [],
            unit: [],
            position: [],
            athleteType: [],
            conferenceType: [],
            team: []
        }

        playerActions.getAllFiltersCandidates(model)
        isFilteredApply(false)
        playerActions.isFilteredData(false)
        playerActions.resetFilterValue()
        if (accountType === "COL_HEAD_COACH" || accountType === "COL_POSITION_COACH" || accountType === "PRO_POSITION_COACH" || accountType === "PRO_GENERAL_MANAGER" || accountType === "PRO_HEAD_COACH") {
            sportsActions.resetSportsApi()
        } else {
            sportsActions.resetUnitsApi()
            sportsActions.resetSportsApi()
        }
    }

    const options = [
        { value: '', label: 'SortBy' },
        { value: 'name', label: 'Name' },
        { value: 'createdBy', label: 'CreatedBy' },
        { value: 'status', label: 'Status' }
    ]
    const [selected, setSelected] = useState(options[0].value);

    const handleChange = event => {
        setSelected(event.target.value);
    };

    const sortdata = (selected && selected === 'name') ? _.orderBy(allPlayers, ['full_name'], ['desc']) : (selected && selected === 'status') ? _.orderBy(allPlayers, ['status'], ['desc']) : allPlayers && allPlayers
    const filteredDetails = searchText ? sortdata && sortdata.filter(v => v.full_name.toLowerCase().includes(searchText.toLowerCase())) : sortdata && sortdata

    // const filteredData = _.isEmpty(sortingOrderBy) ? filteredDetails : _.orderBy(filteredDetails, sortingOrderBy && sortingOrderBy, sortingOrder && sortingOrder)
    const filteredData = _.isEmpty(sortingOrderBy) ? filteredDetails : _.orderBy(filteredDetails, [(sortingOrderBy && sortingOrderBy[0] === "created_on") ? (o) => new Date(o.created_on) : (sortingOrderBy && sortingOrderBy[0] === "full_name") ? (u) => u.full_name.toLowerCase() :  sortingOrderBy && sortingOrderBy], sortingOrder && sortingOrder)

    // const filteredData = filteredSearchData && filteredSearchData.filter(list =>
    //     (list.conference ? list.conference : '').toLowerCase().includes(updateFilter?.conference.toLowerCase()) &&
    //     (list.sport ? list.sport : '').toLowerCase().includes(updateFilter?.sport.toLowerCase()) &&
    //     (list.unit ? list.unit : '').toLowerCase().includes(updateFilter?.unit.toLowerCase()) &&
    //     (list.position ? list.position : '').toLowerCase().includes(updateFilter?.position.toLowerCase()) &&
    //     (list.athleteType ? list.athleteType : '').toLowerCase().includes((updateFilter?.athleteType === "All" ? "" : updateFilter?.athleteType).toLowerCase()) &&
    //     (list.conference_type ? list.conference_type : '').toLowerCase().includes((updateFilter?.conferenceType === "All" ? "" : updateFilter?.conferenceType).toLowerCase())
    // )


    return (
        <React.Fragment>
            {/* <div className={classes.root} style={{ paddingLeft: drawerWidth }}> */}
            <div className={classes.container}>
                <div className={classes.search}>
                    <div>
                        <label>{isFiltered ? "Filtered Athletes:" : "Total Athletes:"} </label><b>{loading ? "--" : filteredData && filteredData.length}</b>
                    </div>

                    {/* <div>
                        <select value={selected} onChange={handleChange} className={classes.inputInput}>
                            {options.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div> */}
                    <div style={{ display: 'flex' }}>
                        {
                            isFiltered ?
                                <Button className={classes.btnSecondary} onClick={handleResetFilters}>Reset Filters</Button>
                                :
                                ""
                        }
                        <Button className={classes.btnPrimary} onClick={() => pageChange('filter')}>
                            {/* <Tooltip title={"Filters"}>
                                <FilterAltIcon style={{ color: '#0971ce' }} />
                            </Tooltip> */}
                            Filter
                        </Button>

                        <InputBase
                            placeholder="Search Player Name"
                            value={searchText}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={ev => handleSearchText(ev.target.value)}
                        />

                        <SortingPopover
                            icon={
                                <span className={classes.SortByAlign}><span className={classes.ArrowAlignSort}><KeyboardArrowUpIcon className={classes.arrowAlignFlex} /><KeyboardArrowDownIcon className={classes.arrowAlignFlex} /></span>Sort by</span>
                            }
                        />
                    </div>
                </div>
                <Grid container spacing={1}>
                    {
                        loading ?
                            <ListLoading />
                            :
                            filteredData && filteredData.length === 0 ?
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', color: '#e61e21' }}>
                                    {"No players found"}
                                </div>
                                :
                                filteredData && filteredData.sort((a, b) => a.last_name > b.last_name ? 1 : -1).map(list => (
                                    <Grid item xs={12} sm={4}>
                                        <Card className={classes.card} onClick={() => handleCardClick(list._id)}>
                                            <div style={{ display: 'flex' }}>
                                                <Badge
                                                    overlap="circular"
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "right",
                                                    }}
                                                    badgeContent={
                                                        list.risk === "high" ? (
                                                            <Tooltip title={"High Risk Athlete"}>
                                                                <Avatar className={classes.badgeContent}>
                                                                    <ErrorIcon style={{ color: "#d32f2f" }} />
                                                                </Avatar>
                                                            </Tooltip>
                                                        ) : null
                                                    }
                                                >
                                                    <Avatar src={list.image_url} alt="player" className={classes.avatar} />
                                                    {/* <Avatar className={classes.AvatarCellDesign} style={{ cursor: 'pointer', }} title={list.full_name}>
                                                        {list.full_name ? list.full_name?.split(" ")[0][0].toUpperCase() + list.full_name?.split(" ")[1][0].toUpperCase() : ""}
                                                    </Avatar> */}
                                                </Badge>
                                                <div style={{ width: '100%', marginLeft: '1rem' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <span className={classes.FullName}>{list.full_name}</span>
                                                        <span className={classes.Status}
                                                            style={{
                                                                background: list.status === 'Completed' ? '#e0f5ea' : list.status === 'Approval Pending' ? '#cfe3f2' : list.status === 'Rejected' ? '#EBE2E9' : list.status === 'Processing' ? '#fbf3d9' : list.status === 'Psych Review Pending' ? '#f2f2f2' : list.status === 'Consent Pending' ? '#cccccc' : list.status === 'Error' ? '#fddfdf' : list.status === 'Base Report Completed' ? '#BDBDBE' : '',
                                                                border: list.status === 'Completed' ? '1px solid #e0f5ea' : list.status === 'Approval Pending' ? '1px solid #cfe3f2' : list.status === 'Rejected' ? '1px solid #EBE2E9' : list.status === 'Processing' ? '#fbf3d9' : list.status === 'Psych Review Pending' ? '1px solid #f2f2f2' : list.status === 'Consent Pending' ? '1px solid #cccccc' : list.status === 'Error' ? '1px solid #fddfdf' : list.status === 'Base Report Completed' ? '#BDBDBE' : '1px solid #000',
                                                                color: list.status === 'Completed' ? '#00ae4d' : list.status === 'Approval Pending' ? '#1075bf' : list.status === 'Rejected' ? '#BC5353' : list.status === 'Processing' ? '#e7b618' : list.status === 'Psych Review Pending' ? '#666666' : list.status === 'Consent Pending' ? '#000000' : list.status === 'Error' ? '#f33a3a' : list.status === 'Base Report Completed' ? '#1A73E8' : ''
                                                            }}
                                                        >{list.status}</span>
                                                    </div>
                                                    <div className={classes.Team}

                                                    >{list.team}</div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Grid>
                                ))
                    }

                </Grid>
            </div>
        </React.Fragment>
    )
}

let mapStateToProps = (state) => {

    return {
        allPlayers: state.player.filterCandidates,
        loading: state.ui.player.loadingFilters,
        isFiltered: state.player.isFiltered,
        updateFilter: state.player.updateFilter,
        accountType: state.account.accountType,
        sortingOrderBy: state.player.sortingOrderBy,
        sortingOrder: state.player.playerOrder["sortingOrder"],
    };
};
export default connect(mapStateToProps)(List);