import React from "react";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/RateReviewOutlined";
import List from "@material-ui/core/List";
import RiskComment from "./RiskComment";
import { accountTypes } from "modules/players/constants";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { round } from "../../../../utils";
import { RadialBar } from "../../../shared/Charts";

let roundTo1PrecisionPoint = (value) => {
  return round(value, 0);
};

let options = {
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "40%",
        background: "#293450",
      },
      track: {
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 4,
          opacity: 0.15,
        },
      },
      dataLabels: {
        name: {
          show: false,
          offsetY: -10,
          color: "#fff",
          fontSize: "13px",
        },
        value: {
          color: "#fff",
          fontSize: "14px",
          offsetY: 6,
          show: true,
          formatter: function (val) {
            return Math.round(val / 10);
          },
        },
      },
    },
  },
  stroke: {
    lineCap: "butt",
  },
};

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 16,
  },
  details: {
    display: "flex",
    flex: 1,
    paddingRight: theme.spacing(1),
  },
}));

const ChartAndDetails = (props) => {
  let classes = useStyles();
  let {
    id,
    diagnosis,
    score = 0,
    comments = [],
    onRiskEdit,
    onComment,
    editable = false,
    accountType,
    defaultColor,
    categoryId
  } = props;
  let roundScore = roundTo1PrecisionPoint(score);
  let color = "#f44336";
  if (score <= 33) {
    color = "#4caf50";
  } else if (score <= 66) {
    color = "#ff9800";
  }
  let handleComment = (id) => {
    return () => {
      onComment(id);
    };
  };

  let handleRiskEdit = (id, score) => {
    return () => {
      onRiskEdit(id, score);
    };
  };

  let renderRiskEdit = () => {
    if (
      accountType === accountTypes.superAdmin ||
      accountType === accountTypes.bcaPsychologist ||
      accountType === accountTypes.orgPsychologist
    ) {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: -18,
            marginLeft: -4,
          }}
        >
          {categoryId && categoryId === 'Development Score' ? '' :
            <IconButton edge="end" onClick={handleRiskEdit(id, Math.round(roundScore / 10))}>
              <EditIcon fontSize="small" />
            </IconButton>}
        </div>
      );
    }

    return null;
  };

  return (
    <div className={classes.root}>
      {/* <div style={{ width: 8, height: 8, backgroundColor: "red" }}></div> */}
      <div style={{ display: "flex", flexDirection: "column" }}>
        <RadialBar
          score={roundScore}
          options={options}
          color={defaultColor ? defaultColor : color}
          width="100"
        />
        {renderRiskEdit()}
      </div>

      <div className={classes.details}>
        <div
          style={{ display: "flex", flex: 1, justifyContent: "space-between" }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle2" style={{ paddingLeft: 16 }}>
              {diagnosis}
            </Typography>

            <List dense>
              {comments.map((comment, index) => {
                return <RiskComment key={index} id={comment} />;
              })}
            </List>
          </div>
          <div style={{ minWidth: 48 }}>
            {editable ? (
              <IconButton edge="end" onClick={handleComment(id)}>
                <EditIcon fontSize="inherit" />
              </IconButton>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { playerId, categoryId } = ownProps;

  return {
    comments: state.player.risk.categoryComments[`${playerId}.${categoryId}`],
    accountType: state.account.accountType,
  };
};
export default connect(mapStateToProps)(ChartAndDetails);
