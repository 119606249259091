import { blue } from "@material-ui/core/colors";
import DialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Grid, TextField, TextareaAutosize } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import React, { useContext } from "react";
import { connect } from "react-redux";
import { ActionContext } from "../../../../contexts";
import { Button, DialogActions } from "@material-ui/core";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  enabled: {
    color: theme.palette.success.main,
  },
  pending: {
    color: theme.palette.warning.main,
  },
  btnPrimary: {
    color: theme.palette.primary.main,
    border: '1px solid',
    height: '30px',
    padding: '10px',
    marginRight: '10px',
    borderRadius: '20px',
    '&:hover': {
      background: theme.palette.primary.main,
      color: '#fff'
    },
  },
  btnSecondary: {
    color: theme.palette.secondary.main,
    border: '1px solid',
    height: '30px',
    padding: '10px',
    marginRight: '10px',
    borderRadius: '20px',
    '&:hover': {
      background: theme.palette.secondary.main,
      color: '#fff'
    }
  },
  textarea: {
    minHeight: 80,
    width: '100%',
    border: '1px solid #cdcdcd',
    margin: '10px 0px',
    padding: '10px',
    borderRadius: '5px',
    fontSize: '16px',
    marginTop: 0,
    marginBottom: 0
  },
}));

const CreateTeamDialog = (props) => {
  const classes = useStyles();

  const { closeDialog, loadingCreateTeam, type, newTeamId, data, conferenceType } = props

  const { conferenceActions } = useContext(ActionContext);
  const id = data && data.id && data.id

  const [state, setState] = React.useState({
    name: id ? data.name : "",
    description: id ? data.description : "",
    reportCount: id ? data.reportCount : 50
  })

  const handleCloseDialog = () => {
    closeDialog();
    setState(prevState => ({
      ...prevState,
      name: "",
      description: "",
      reportCount: 50
    }));
  };



  const handleSave = () => {
    let model = {
      "sport": "",
      "name": state.name,
      "description": state.description,
      "conference": id ? conferenceType : type,
      "reportCount": state.reportCount
    }
    conferenceActions.postCreateTeam(model)
  }




  function handleFormDataChange(key, value) {
    setState(prevState => ({
      ...prevState,
      [key]: value
    }));
  }

  const getReportCount = (e) => {
    if (e.target.value <= 500) {
      setState(prevState => ({
        ...prevState,
        reportCount: Number(e.target.value)
      }));
    }
    else {
      setState(prevState => ({
        ...prevState,
        reportCount: Number(500)
      }));
    }
  };

  useEffect(() => {
    if (newTeamId !== "") {
      handleCloseDialog()
      conferenceActions.getTeamsByConferenceId(id ? conferenceType : type);
      setState(prevState => ({
        ...prevState,
        name: "",
        description: "",
        reportCount: 50
      }));
      conferenceActions.resetCreateTeam()
    }
  }, [newTeamId])

  return (
    <React.Fragment>
      <div style={{ display: "flex", justifyContent: 'space-between' }}>
        <div style={{ display: "flex", alignItems: 'center', margin: "8px 16px 8px 16px", color: "blue", fontWeight: "bold" }}>
          {id ? 'Team' : 'Create Team'}
        </div>

        <div style={{ margin: "8px 16px 8px 8px", display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={handleCloseDialog}>
            <CloseIcon style={{ color: "blue" }} />
          </IconButton>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: 'center' }}>
        <Grid container>
          <Grid item xs={12}>
            <TextField style={{ width: '90%', margin: '10px 20px 10px 20px' }} variant="outlined" value={state.name} label="Title"
              size="small" onChange={ev => handleFormDataChange("name", ev.target.value)} inputProps={
                { readOnly: id ? true : false }
              } />
          </Grid>
          <Grid item xs={12}>
            <TextareaAutosize
              size="small"
              style={{ width: '90%', margin: '10px 20px 10px 20px' }}
              value={state.description}
              onChange={(e) => handleFormDataChange('description', e.target.value)}
              className={classes.textarea}
              rowsMax={4}
              aria-label="maximum height"
              placeholder="description"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type='number'
              label="Base reports purchased"
              inputProps={{ min: 0, max: 500 }}
              variant="outlined"
              size="small"
              value={state.reportCount}
              style={{ width: '90%', margin: '10px 20px 10px 20px' }}
              onChange={getReportCount}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3)
              }}
            />

          </Grid>
        </Grid>
      </div>

      <div>
        <DialogActions>
          <Button className={classes.btnSecondary} onClick={handleCloseDialog}>Cancel</Button>
          <Button className={classes.btnPrimary} disabled={loadingCreateTeam || !state.name || !state.reportCount} onClick={handleSave}>
            {id ? 'Save' : 'Create'}</Button>
        </DialogActions>
      </div>
    </React.Fragment >
  );
};

const mapStateToProps = (state, ownProps) => {

  return {
    loadingCreateTeam: state.ui.conference.loadingCreateTeam,
    newTeamId: state.conference.newTeamId,
  };
};
export default connect(mapStateToProps)(CreateTeamDialog);
