
import * as types from "./coachType";
import { conferenceApi, coachApi,playerApi } from "../../../api";
import { statusTypes, reportTypes } from "modules/players/constants";

import {
    showSuccessMessage,
    showErrorMessage,
    showInfoMessage,
} from "../../shared/Snackbar";


const getCoachList = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_GET_COACHES,
                // payload: []
            });

            let response = await coachApi.getCoaches();

            dispatch({
                type: types.SET_COACHES_SUCCESS,
                payload: response
            });

        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.GET_COACHES_FAILED,
                payload: "Failed to fetch coaches",
            });
        }
    };
};

const searchCoach = (key) => {
    return {
        type: types.SEARCH_COACHES,
        payload: {
            key,
        },
    };
};

const resetSearch = () => {
    return {
        type: types.RESET_SEARCH,
    };
};

const selectCoach = (coachId) => {
    return {
        type: types.SELECT_COACHES,
        payload: {
            coachId,
        },
    };
};

const deselectCoach = () => {
    return {
        type: types.DESELECT_COACHES,
        payload: {},
    };
};

const getAllFilterCoaches = (model) => {
    return async (dispatch) => {
        try {

            dispatch({
                type: types.GET_FILTER_COACH_REQUEST
            })

            let response = await coachApi.getFilterCoaches(model)

            dispatch({
                type: types.GET_FILTER_COACH_SUCCESS,
                payload: response
            })

        }
        catch (err) {
            dispatch(showErrorMessage(err.message))
            dispatch({
                type: types.GET_FILTER_COACH_FAILED
            })
        }
    }

}

const updateCoachFilterValue = (key, value) => {
    return {
        type: types.UPDATE_COACH_FILTER_VALUE,
        payload: {
            key, value
        }
    }
}


const resetCoachFilter = () => {
    return {
        type: types.RESET_COACH_FILTER_VALUE,
        payload: {}
    }
}


const isCoachFiltered = (value) => {
    return {
        type: types.IS_COACH_FILTERED_DATA,
        payload: value
    };
};


const getCoach = (coachId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_GET_COACH,
                payload: {
                    coachId,
                },
            });

            const response = await coachApi.getCoachDetails(coachId);

            dispatch({
                type: types.GET_COACH_SUCCESS,
                payload: {
                    ...response,
                    coachId,
                },
            });
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.GET_COACH_FAILED,
                payload: {
                    coachId,
                    error: "Fetch coach failed",
                },
            });
        }
    };
};




const markReviewCompleted = (coachId, nextStatus) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_REVIEW_COMPLETE_COACH,
                payload: {
                    coachId,
                },
            });

            await coachApi.postCoachReviewCompleted(coachId);

            dispatch({
                type: types.REVIEW_COMPLETE_COACH_SUCCESS,
                payload: {
                    coachId,
                    status: nextStatus,
                },
            });

            dispatch(showSuccessMessage("Coach marked as Completed"));
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.REVIEW_COMPLETE_COACH_FAILED,
                payload: {
                    coachId,
                },
            });
        }
    };
};

const markReviewIncomple = (coachId, nextStatus) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUSET_COACH_REVIEW_INCOMPLETE,
                payload: {
                    coachId,
                },
            });

            await coachApi.markAsCoachReviewIncompleted(coachId);

            dispatch({
                type: types.COACH_REVIEW_INCOMPLETE_SUCCESS,
                payload: {
                    coachId,
                    status: nextStatus,
                },
            });

            dispatch(showSuccessMessage("Coach marked as P/R Pending"));
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.COACH_REVIEW_INCOMPLETE_FAILED,
                payload: {
                    coachId,
                },
            });
        }
    };
};

const approveCoachProfile = (
    data,
    successCb = (f) => f,
    failCb = (f) => f
) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_APPROVE_COACH_PROFILE,
                payload: {
                    coachId: data.coach_id,
                },
            });

            await coachApi.postApproveCoachProfile(data);

            dispatch({
                type: types.APPROVE_COACH_PROFILE_SUCCESS,
                payload: {
                    coachId: data.coach_id,
                    status: statusTypes.processing,
                },
            });
            successCb();
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.APPROVE_COACH_PROFILE_FAILED,
                payload: {
                    coachId: data.coach_id,
                },
            });
            failCb();
        }
    };
};

const rejectCoachProfile = (
    data,
    successCb = (f) => f,
    failCb = (f) => f
) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_REJECT_COACH_PROFILE,
                payload: {
                    coachId: data.coach_id,
                },
            });

            await coachApi.postRejectCoachProfile(data);

            dispatch({
                type: types.REJECT_COACH_PROFILE_SUCCESS,
                payload: {
                    coachId: data.coach_id,
                    status: statusTypes.rejected,
                },
            });
            successCb();
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.REJECT_COACH_PROFILE_FAILED,
                payload: {
                    coachId: data.coach_id,
                },
            });
            failCb();
        }
    };
};

const getCoachFeed = (coachId, source) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_GET_COACH_FEED,
                payload: {
                    coachId,
                    source,
                },
            });

            const response = await coachApi.getCoachFeed(coachId, source);

            dispatch({
                type: types.GET_COACH_FEED_SUCCESS,
                payload: {
                    id: coachId,
                    source,
                    ...response,
                },
            });
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.GET_COACH_FEED_FAILED,
                payload: {
                    error: "Fetch coach feed failed",
                },
            });
        }
    };
};

const markCoachAsPaid = (coachId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_TOGGLE_COACH_PAID_STATUS,
                payload: {
                    coachId,
                },
            });

            await coachApi.markCoachAsPaid(coachId);

            dispatch({
                type: types.TOGGLE_COACH_PAID_STATUS_SUCCESS,
                payload: {
                    coachId,
                    isPaid: true,
                },
            });
            dispatch(showSuccessMessage("Marked as Paid"));
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.TOGGLE_COACH_PAID_STATUS_FAILED,
                payload: {
                    coachId,
                },
            });
        }
    };
};

const markCoachAsUnpaid = (coachId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_TOGGLE_COACH_PAID_STATUS,
                payload: {
                    coachId,
                },
            });

            await coachApi.markCoachAsUnpaid(coachId);

            dispatch({
                type: types.TOGGLE_COACH_PAID_STATUS_SUCCESS,
                payload: {
                    coachId,
                    isPaid: false,
                },
            });
            dispatch(showSuccessMessage("Marked as Unpaid"));
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.TOGGLE_COACH_PAID_STATUS_FAILED,
                payload: {
                    coachId,
                },
            });
        }
    };
};

// const addActionPlanComment = (data, successCb = (f) => f) => {
//     return async (dispatch) => {
//         try {
//             dispatch({
//                 type: types.REQUEST_ADD_COACH_ACTION_PLAN_COMMENT,
//                 payload: {
//                     coachId: data.candidate_id,
//                     categoryId: data.category,
//                     comment: data.content,
//                 },
//             });

//             let commentId = await playerApi.postActionPlanComment(data);

//             dispatch({
//                 type: types.ADD_COACH_ACTION_PLAN_COMMENT_SUCCESS,
//                 payload: {
//                     coachId: data.candidate_id,
//                     categoryId: data.category,
//                     commentId,
//                     comment: data.content,
//                 },
//             });

//             successCb();
//         } catch (err) {
//             dispatch(showErrorMessage(err.message));
//             dispatch({
//                 type: types.DELETE_COACH_ACTION_PLAN_COMMENT_FAILED,
//                 payload: {
//                     coachId: data.candidate_id,
//                     categoryId: data.category,
//                 },
//             });
//         }
//     };
// };

const addActionPlanContent = (data, successCb = (f) => f) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_ADD_COACH_ACTION_PLAN_COMMENT,
                payload: {
                    playerId: data.candidate_id,
                    categoryId: data.category,
                },
            });

            await playerApi.postActionPlanContent(data);

            dispatch({
                type: types.ADD_COACH_ACTION_PLAN_COMMENT_SUCCESS,
                payload: {
                    playerId: data.candidate_id,
                    categoryId: data.category,
                    content: data.content,
                },
            });
            dispatch(showSuccessMessage("Comment saved successfully"));
            successCb();
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.ADD_COACH_ACTION_PLAN_CONTENT_FAILED,
                payload: {
                    playerId: data.candidate_id,
                    categoryId: data.category,
                },
            });
        }
    };
};

const addExecutiveSummeryContent = (data, successCb = (f) => f, prevData = []) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_COACH_ADD_EXECUTIVE_SUMMERY_COMMENT,
                payload: {
                    coachId: data.coach_id,
                    categoryId: data.category,
                },
            });

            await coachApi.postExecutiveSummeryComment(data);

            dispatch({
                type: types.ADD_COACH_EXECUTIVE_SUMMERY_COMMENT_SUCCESS,
                payload: {
                    coachId: data.coach_id,
                    categoryId: data.category,
                    content: data.content,
                    prevData: prevData,
                    data: data
                },
            });
            dispatch(showSuccessMessage("Comment saved successfully"));
            successCb();
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.ADD_COACH_EXECUTIVE_SUMMERY_COMMENT_FAILED,
                payload: {
                    coachId: data.coach_id,
                    categoryId: data.category,
                },
            });
        }
    };
};

const updateRiskScore = (
    data = { coach_id: "", category: "", score: 0 },
    successCb = (f) => f,
    failCb = (f) => f
) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_UPDATE_COACH_RISK_SCORE,
                payload: {
                    coachId: data.coach_id,
                    categoryId: data.category,
                },
            });

            const response = await coachApi.updateCoachRiskScore(data);

            dispatch({
                type: types.UPDATE_COACH_RISK_SCORE_SUCCESS,
                payload: {
                    coachId: data.coach_id,
                    categoryId: data.category,
                    risk: response.risk,
                },
            });
            dispatch(showSuccessMessage("Score updated successfully"));
            successCb();
        } catch (err) {
            dispatch({
                type: types.UPDATE_COACH_RISK_SCORE_FAIL,
                payload: {
                    coachId: data.coach_id,
                    categoryId: data.category,
                },
            });
            dispatch(showErrorMessage(err.message));
            failCb();
        }
    };
};

const addRiskComment = (data, successCb = (f) => f) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_ADD_COACH_RISK_COMMENT,
                payload: {
                    coachId: data.coach_id,
                    categoryId: data.category,
                },
            });

            const commentId = await coachApi.postCoachRiskComment(data);

            dispatch({
                type: types.ADD_COACH_RISK_COMMENT_SUCCESS,
                payload: {
                    commentId,
                    comment: data.content,
                    coachId: data.coach_id,
                    categoryId: data.category,
                },
            });

            successCb();
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.ADD_COACH_RISK_COMMENT_FAILED,
            });
        }
    };
};

const deleteRiskComment = (data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_DELETE_COACH_RISK_COMMENT,
                payload: {
                    coachId: data.coach_id,
                    categoryId: data.category,
                },
            });

            const commentId = await coachApi.deleteCoachRiskComment(data);

            dispatch({
                type: types.DELETE_COACH_RISK_COMMENT_SUCCESS,
                payload: {
                    coachId: data.coach_id,
                    categoryId: data.category,
                    commentId: commentId,
                },
            });
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.DELETE_COACH_RISK_COMMENT_FAILED,
                payload: {
                    coachId: data.coach_id,
                    categoryId: data.category,
                },
            });
        }
    };
};

const updateNotes = (data, successCb = (f) => f, failCb = (f) => f) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_UPDATE_COACH_NOTES,
                payload: {
                    coachId: data.coach_id,
                },
            });

            await coachApi.postUpdateNotes(data);

            dispatch({
                type: types.UPDATE_COACH_NOTES_SUCCESS,
                payload: {
                    coachId: data.coach_id,
                    notes: data.notes,
                },
            });
            dispatch(showSuccessMessage("Notes updated successfully"));
            successCb();
        } catch (err) {
            dispatch({
                type: types.UPDATE_COACH_NOTES_FAILED,
                payload: {
                    coachId: data.coach_id,
                },
            });
            dispatch(showErrorMessage(err.message));
            failCb();
        }
    };
};

const addCoachLink = (data, successCb = (f) => f, failCb = (f) => f) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_ADD_COACH_LINK,
                payload: {
                    coachId: data.coach_id,
                },
            });

            const links = await coachApi.addCoachLink(data);

            dispatch({
                type: types.ADD_COACH_LINK_SUCCESS,
                payload: {
                    coachId: data.coach_id,
                    links,
                },
            });

            dispatch(showSuccessMessage("Link added"));
            successCb();
        } catch (error) {
            dispatch({
                type: types.ADD_COACH_LINK_FAIL,
                payload: {
                    coachId: data.coach_id,
                },
            });
            dispatch(showErrorMessage(error.message));
            failCb();
        }
    };
};

const deleteCoachLink = (data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_DELETE_COACH_LINK,
                payload: {
                    coachId: data.coach_id,
                },
            });

            dispatch({
                type: types.DELETE_COACH_LINK_SUCCESS,
                payload: {
                    coachId: data.coach_id,
                    linkId: data.link_id,
                },
            });
            dispatch(showSuccessMessage("Link deleted"));
        } catch (error) {
            dispatch({
                type: types.DELETE_COACH_LINK_FAIL,
                payload: {
                    coachId: data.coach_id,
                },
            });
            dispatch(showErrorMessage(error.message));
        }
    };
};

const toggleFullReport = (coachId, allowed) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_TOGGLE_COACH_FULL_REPORT_PAYMENT_STATUS,
                payload: {
                    coachId,
                },
            });
            await coachApi.toggleFullReportPaymentStatus(coachId, allowed);
            dispatch({
                type: types.TOGGLE_COACH_FULL_REPORT_PAYMENT_STATUS_SUCCESS,
                payload: {
                    coachId,
                    reportType: reportTypes.fullReport,
                    allowed,
                },
            });
            dispatch(showSuccessMessage("Payment status updated"));
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.TOGGLE_COACH_FULL_REPORT_PAYMENT_STATUS_FAILED,
                payload: {
                    coachId,
                    allowed,
                },
            });
        }
    };
};

const toggleActionPlanReport = (coachId, allowed) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_TOGGLE_COACH_ACTION_PLAN_REPORT_PAYMENT_STATUS,
                payload: {
                    coachId,
                },
            });

            await coachApi.toggleActionPlanReportPaymentStatus(coachId, allowed);
            dispatch({
                type: types.TOGGLE_COACH_ACTION_PLAN_REPORT_PAYMENT_STATUS_SUCCESS,
                payload: {
                    coachId,
                    allowed,
                    reportType: reportTypes.actionPlanReport,
                },
            });
            dispatch(showSuccessMessage("Payment status updated"));
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.TOGGLE_COACH_ACTION_PLAN_REPORT_PAYMENT_STATUS_FAILED,
                payload: {
                    coachId,
                    allowed,
                },
            });
        }
    };
};

const toggleExecutiveSummaryReport = (coachId, allowed) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_TOGGLE_COACH_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS,
                payload: {
                    coachId,
                },
            });

            await coachApi.toggleExecutiveSummaryReportPaymentStatus(
                coachId,
                allowed
            );
            dispatch({
                type: types.TOGGLE_COACH_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS_SUCCESS,
                payload: {
                    coachId,
                    allowed,
                    reportType: reportTypes.executiveSummaryReport,
                },
            });
            dispatch(showSuccessMessage("Payment status updated"));
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.TOGGLE_COACH_EXECUTIVE_SUMMARY_REPORT_PAYMENT_STATUS_FAILED,
                payload: {
                    coachId,
                    allowed,
                },
            });
        }
    };
};

const exportFeed = (
    coachId,
    coachName,
    source,
    successCb = (f) => f,
    failCb = (f) => f
) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_EXPORT_COACH_FEED,
                payload: {
                    coachId,
                    source,
                },
            });

            const response = await coachApi.exportCoachFeed(coachId, source);

            const fileName = `${coachName} ${source}.csv`;

            successCb(response, fileName)();

            dispatch({
                type: types.EXPORT_COACH_FEED_SUCCESS,
                payload: {
                    coachId,
                    source,
                },
            });
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.EXPORT_COACH_FEED_FAILED,
                payload: {
                    coachId,
                    source,
                },
            });

            failCb();
        }
    };
};

const individualCoachFormSubmited = () => {
    return {
        type: types.INDIVIDUAL_COACH_FORM_SUBMITTED,
        payload: true
    };
};

const individualCoachFormNotSubmited = () => {
    return {
        type: types.INDIVIDUAL_COACH_FORM_NOT_SUBMITTED,
        payload: false,
    };
};

const exportFullReport = (
    coachId,
    coachName = "",
    successCb = (f) => f,
    failCb = (f) => f
) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_EXPORT_FULL_REPORT,
                payload: {
                    coachId,
                },
            });

            dispatch(showInfoMessage("Exporting the report"));
            const response = await coachApi.exportFullReport(coachId);

            const reportName = `${coachName} Full Report.pdf`;

            dispatch({
                type: types.EXPORT_FULL_REPORT_SUCCESS,
                payload: {
                    coachId,
                },
            });
            successCb(response, reportName)();
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.EXPORT_FULL_REPORT_FAIL,
                payload: {
                    coachId,
                },
            });

            failCb();
        }
    };
};

const exportActionPlanReport = (
    coachId,
    coachName = "",
    successCb = (f) => f,
    failCb = (f) => f
) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_EXPORT_ACTION_PLAN_REPORT,
                payload: {
                    coachId,
                },
            });

            dispatch(showInfoMessage("Exporting the report"));
            const response = await coachApi.exportActionPlanReport(coachId);

            const reportName = `${coachName} Action Plan Report.pdf`;

            dispatch({
                type: types.EXPORT_ACTION_PLAN_REPORT_SUCCESS,
                payload: {
                    coachId,
                },
            });
            successCb(response, reportName)();
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.EXPORT_ACTION_PLAN_REPORT_FAIL,
                payload: {
                    coachId,
                },
            });

            failCb();
        }
    };
};

const exportExecutiveSummaryReport = (
    coachId,
    coachName = "",
    successCb = (f) => f,
    failCb = (f) => f
) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_EXPORT_EXECUTIVE_SUMMARY_REPORT,
                payload: {
                    coachId,
                },
            });

            dispatch(showInfoMessage("Exporting the report"));
            const response = await coachApi.exportExecutiveSummaryReport(coachId);

            const reportName = `${coachName} Executive Summary Report.pdf`;

            dispatch({
                type: types.EXPORT_EXECUTIVE_SUMMARY_REPORT_SUCCESS,
                payload: {
                    coachId,
                },
            });
            successCb(response, reportName)();
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.EXPORT_EXECUTIVE_SUMMARY_REPORT_FAIL,
                payload: {
                    coachId,
                },
            });

            failCb();
        }
    };
};


const regenerateFullReport = (coachId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_REGENERATE_FULL_REPORT,
                payload: {
                    coachId,
                },
            });
            await coachApi.reGenerateFullReport(coachId);
            dispatch({
                type: types.REGENERATE_FULL_REPORT_SUCCESS,
                payload: {
                    coachId,
                },
            });
            dispatch(showSuccessMessage("Full report updated"));
        } catch (error) {
            dispatch({
                type: types.REGENERATE_FULL_REPORT_FAIL,
                payload: {
                    coachId,
                },
            });
            dispatch(showErrorMessage(error.message));
        }
    };
};

const regenerateActionPlanReport = (coachId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_REGENERATE_ACTION_PLAN_REPORT,
                payload: {
                    coachId,
                },
            });
            await coachApi.reGenerateActionPlanReport(coachId);
            dispatch({
                type: types.REGENERATE_ACTION_PLAN_REPORT_SUCCESS,
                payload: {
                    coachId,
                },
            });
            dispatch(showSuccessMessage("Action plan report updated"));
        } catch (error) {
            dispatch({
                type: types.REGENERATE_ACTION_PLAN_REPORT_FAIL,
                payload: {
                    coachId,
                },
            });
            dispatch(showErrorMessage(error.message));
        }
    };
};

const regenerateExecutiveSummaryReport = (coachId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_REGENERATE_EXECUTIVE_SUMMARY_REPORT,
                payload: {
                    coachId,
                },
            });

            await coachApi.reGenerateExecutiveSummaryReport(coachId);

            dispatch({
                type: types.REGENERATE_EXECUTIVE_SUMMARY_REPORT_SUCCESS,
                payload: {
                    coachId,
                },
            });
            dispatch(showSuccessMessage("Executive summary report updated"));
        } catch (error) {
            dispatch({
                type: types.REGENERATE_EXECUTIVE_SUMMARY_REPORT_FAIL,
                payload: {
                    coachId,
                },
            });
            dispatch(showErrorMessage(error.message));
        }
    };
};



const markAsReviewSubmitted = (coachId, nextStatus) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUSET_COACH_REVIEW_SUBMITTED,
                payload: {
                    coachId,
                },
            });

            await coachApi.markAsReviewSubmitted(coachId);

            dispatch({
                type: types.COACH_REVIEW_SUBMITTED_SUCCESS,
                payload: {
                    coachId,
                    status: nextStatus,
                },
            });

            dispatch(showSuccessMessage("Coach marked as Psych Review"));
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.COACH_REVIEW_SUBMITTED_FAILED,
                payload: {
                    coachId,
                },
            });
        }
    };
};

const postCoachStartAnalyze = (coachId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_ANALYZE_COACH_DETAILS,
                payload: {
                    coachId: coachId,
                },
            });

            await coachApi.postStartAnalyze(coachId);

            dispatch({
                type: types.ANALYZE_COACH_DETAILS_SUCCESS,
                payload: {
                    coachId: coachId,
                },
            });
            dispatch(postCoachAnalyzeResults(coachId));

        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.ANALYZE_COACH_DETAILS_FAILED,
                payload: {
                    coachId: coachId,
                },
            });

        }
    };
};

const postCoachAnalyzeResults = (coachId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_ANALYZE_COACH_RESULTS,
                payload: {
                    coachId: coachId,
                },
            });

            await coachApi.postAnalyzeResults(coachId);

            dispatch({
                type: types.ANALYZE_COACH_RESULTS_SUCCESS,
                payload: {
                    coachId: coachId,
                },
            });

        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.ANALYZE_COACH_RESULTS_FAILED,
                payload: {
                    coachId: coachId,
                },
            });

        }
    };
};

const postCoachSentimentResults = (coachId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_COACH_SENTIMENT_RESULTS,
                payload: {
                    coachId: coachId,
                    value: false
                },
            });

            await coachApi.postSentimateResults(coachId);

            dispatch({
                type: types.COACH_SENTIMENT_RESULTS_SUCCESS,
                payload: {
                    coachId: coachId,
                    value: true
                },
            });

        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.COACH_SENTIMENT_RESULTS_FAILED,
                payload: {
                    coachId: coachId,
                    value: false
                },
            });

        }
    };
};

const resetCoachSentimentResults = (coachId) => {
    return (dispatch) => {
        dispatch({
            type: types.COACH_SENTIMENT_RESULTS_SUCCESS,
            payload: {
                coachId: coachId,
                value: false
            },
        });
    }
}
const savePlusReportData = (data) => {
    return {
        type: types.GET_ALLREADY_REQUESTED_PLUS_REPORTS,
        payload: data
    }
}
const submitedPlusReport = (data) => {
    return {
        type: types.SAVE_ALLREADY_REQUESTED_PLUS_REPORTS,
        payload: {
            data
        }
    }
}

const postReqPlanReports = (model) => {
    return async (dispatch) => {
        try {

            dispatch({
                type: types.GET_REQUEST_PLAN_REPORTS
            })

            let response = await coachApi.postRequestPlanReports(model)

            dispatch({
                type: types.GET_REQUEST_PLAN_REPORTS_SUCCESS,
            })

            dispatch(savePlusReportData(response))
            dispatch(submitedPlusReport(true))

        }
        catch (err) {
            dispatch(showErrorMessage(err.message))
            dispatch({
                type: types.GET_REQUEST_PLAN_REPORTS_FAILED
            })
        }
    }
}

const deletePsychSummaryComments = (data, prevData) => {
    return async (dispatch) => {
      try {
        dispatch({
          type: types.REQUEST_DELETE_EXECUTIVE_SUMMERY_COMMENT,
          payload: {
            coachId: data.coach_id,
            category: data.category,
            _id: data._id
          },
        });
  
        await coachApi.deleteExecutiveSummeryComment(data);
  
        dispatch({
          type: types.DELETE_EXECUTIVE_SUMMERY_COMMENT_SUCCESS,
          payload: {
            coachId: data.coach_id,
            categoryId: data.category,
            _id: data._id,
            prevData: prevData
          },
        });
        dispatch(showSuccessMessage("Comment deleted successfully"));
        // successCb();
      } catch (err) {
        dispatch(showErrorMessage(err.message));
      }
    };
  };

const coachTwitterInfo = (coachId,requestUserId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_GET_TWITTER_INFO,
            });
            const players = await coachApi.coachTwitter(coachId,requestUserId);
            dispatch({
                type: types.GET_TWITTER_INFO_SUCCESS,
                payload: players,
            });
            dispatch(getCoach(coachId))
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.GET_TWITTER_INFO_FAILED,
                payload: {
                    error: "Fetch coaches failed",
                },
            });
        }
    };
};
const coachBig5Info = (coachId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_GET_BIG5_INFO,
            });
            const players = await coachApi.coachBig5Info(coachId);
            dispatch({
                type: types.GET_BIG5_INFO_SUCCESS,
                payload: players,
            });
            dispatch(getCoach(coachId))
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.GET_BIG5_INFO_FAILED,
                payload: {
                    error: "Fetch coaches failed",
                },
            });
        }
    };
}; 

const coachPsychUpdateInfo = (coachId, statusId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_GET_PSYCH_INFO,
            });
            const players = await coachApi.coachPsychReviewUpdate(coachId, statusId);
            dispatch({
                type: types.GET_PSYCH_INFO_SUCCESS,
                payload: players,
            });
            dispatch(getCoach(coachId))
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.GET_PSYCH_INFO_FAILED,
                payload: {
                    error: "Fetch coaches failed",
                },
            });
        }
    };
};

const coachInstagramInfo = (coachId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_GET_INSTAGRAM_INFO,
            });
            const players = await coachApi.coachInstagram(coachId);
            dispatch({
                type: types.GET_INSTAGRAM_INFO_SUCCESS,
                payload: players,
            });
            dispatch(getCoach(coachId))
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.GET_INSTAGRAM_INFO_FAILED,
                payload: {
                    error: "Fetch coaches failed",
                },
            });
        }
    };
}; 

const coachSentimentInfo = (coachId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_GET_SENTIMENT_INFO,
            });
            const players = await coachApi.coachSentimentScore(coachId);
            dispatch({
                type: types.GET_SENTIMENT_INFO_SUCCESS,
                payload: players,
            });
            dispatch(getCoach(coachId))
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.GET_SENTIMENT_INFO_FAILED,
                payload: {
                    error: "Fetch coaches failed",
                },
            });
        }
    };
};

const getCoachVideoTranscript = (model, successCb = (f) => f) => {
    return async (dispatch) => {
        try {

            dispatch({
                type: types.GET_VIDEO_TRANSCRIPT_REQUEST
            })

            let response = await coachApi.getCoachVideoTranscript(model)

            dispatch({
                type: types.GET_VIDEO_TRANSCRIPT_SUCCESS,
                payload: response
            })
            successCb();
            // dispatch(getCoach(model.candidate_id))
        }
        catch (err) {
            dispatch(showErrorMessage(err.message))
            dispatch({
                type: types.GET_VIDEO_TRANSCRIPT_FAILED
            })
        }
    }
}

const approveCoach = (model) => {
    return async (dispatch) => {
        try {

            dispatch({
                type: types.GET_APPROVE_PLAYER
            })

            let response = await coachApi.approveCoach(model)

            dispatch({
                type: types.GET_APPROVE_PLAYER_SUCCESS,
                payload: response
            })
            dispatch(getCoach(model.candidate_id))
        }
        catch (err) {
            dispatch(showErrorMessage(err.message))
            dispatch({
                type: types.GET_APPROVE_PLAYER_FAILED
            })
        }
    }
}

const coachPlusReport = (coachId) => {
    return async (dispatch) => {
        try {

            dispatch({
                type: types.GET_PLUS_REPORT
            })

            let response = await coachApi.coachPlusReport(coachId)

            dispatch({
                type: types.GET_PLUS_REPORT_SUCCESS,
                payload: response
            })
            dispatch(getCoach(coachId))
        }
        catch (err) {
            dispatch(showErrorMessage(err.message))
            dispatch({
                type: types.GET_PLUS_REPORT_FAILED
            })
        }
    }
}

const removeCoachFeed = (coachId) => {
    return async (dispatch) => {
        try {

            dispatch({
                type: types.GET_REMOVE_FEED
            })

            let response = await coachApi.removeCoachFeed(coachId)

            dispatch({
                type: types.GET_REMOVE_FEED_SUCCESS,
                payload: response
            })
            dispatch(getCoach(coachId))
        }
        catch (err) {
            dispatch(showErrorMessage(err.message))
            dispatch({
                type: types.GET_REMOVE_FEED_FAILED
            })
        }
    }
}


const getUserIdWithHandle = (userId) => {
    return async (dispatch) => {
        try {

            dispatch({
                type: types.GET_USER_ID_WITH_USER_HANDLE
            })

            let response = await coachApi.getUserId(userId)

            dispatch({
                type: types.GET_USER_ID_WITH_USER_HANDLE_SUCCESS,
                payload: response
            })
        }
        catch (err) {
            dispatch(showErrorMessage(err.message))
            dispatch({
                type: types.GET_USER_ID_WITH_USER_HANDLE_FAILED
            })
        }
    }
}

const coachActions = {
    getCoachList,
    searchCoach,
    resetSearch,
    selectCoach,
    deselectCoach,
    getAllFilterCoaches,
    updateCoachFilterValue,
    resetCoachFilter,
    isCoachFiltered,
    getCoach,
    exportFeed,
    getCoachFeed,
    // exportReport,
    // exportSystemReport,
    // exportPsycologistReport,
    addRiskComment,
    deleteRiskComment,
    // addActionPlanComment,
    // addExecutiveSummeryComment,
    // deleteExecutiveSummeryComment,
    // deleteActionPlanComment,
    approveCoachProfile,
    rejectCoachProfile,
    markReviewCompleted,
    markReviewIncomple,
    markAsReviewSubmitted,
    addActionPlanContent,
    addExecutiveSummeryContent,
    markCoachAsPaid,
    markCoachAsUnpaid,
    addCoachLink,
    deleteCoachLink,
    // regenerateSystemReport,
    // regeneratePsychReport,
    toggleFullReport,
    toggleActionPlanReport,
    toggleExecutiveSummaryReport,
    exportFullReport,
    exportActionPlanReport,
    exportExecutiveSummaryReport,
    regenerateFullReport,
    regenerateActionPlanReport,
    regenerateExecutiveSummaryReport,
    updateNotes,
    updateRiskScore,
    individualCoachFormSubmited,
    individualCoachFormNotSubmited,
    postCoachStartAnalyze,
    postCoachAnalyzeResults,
    postCoachSentimentResults,
    resetCoachSentimentResults,
    removeCoachFeed,
    coachPlusReport,
    approveCoach,
    getCoachVideoTranscript,
    coachTwitterInfo,
    coachInstagramInfo,
    coachPsychUpdateInfo,
    coachSentimentInfo,
    coachBig5Info,
    postReqPlanReports,
    savePlusReportData,
    submitedPlusReport,
    deletePsychSummaryComments,
    getUserIdWithHandle
};

export default coachActions;