// pages
import BookmarkIcon from "@material-ui/icons/Bookmark";
import CenterFocusedIcon from "@material-ui/icons/CenterFocusStrong";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import FaceIcon from "@material-ui/icons/Face";
import InfoIcon from "@material-ui/icons/Info";
import NotesIcon from "@material-ui/icons/Notes";
import PersonIcon from "@material-ui/icons/Person";
// icons
import PersonPinIcon from "@material-ui/icons/PersonPin";
import ActionPlan from "./components/ActionPlan";
import DeepProfile from "./components/DeepProfile";
import ExecutiveSummary from "./components/ExecutiveSummery";
import Feed from "./components/Feed";
import MoreInfo from "./components/MoreInfo";
import Notes from "./components/Notes";
import PersonalInfo from "./components/PersonalInfo";
import PersonalityTraits from "./components/PersonalityTraits";
//
import { accountTypes } from "./constants";

let personalInfo = {
  id: "personalInfo",
  title: "Personal Info",
  description: "Athlete's Basic Information and Contact Details",
  component: PersonalInfo,
  navName: "Peronal Info",
  navIcon: PersonIcon,
  alwaysAllow: true,
};

let executiveSummery = {
  id: "executiveSummery",
  title: "Executive Summary",
  description: "An overview of the Athlete’s traits and potential risk",
  component: ExecutiveSummary,
  navName: "Executive Summary",
  navIcon: BookmarkIcon,
  alwaysAllow: false,
};

let personalityTraits = {
  id: "personalityTraits",
  title: "Personality Traits",
  description: "Big 5 Personality Traits and Subtraits Analysis",
  component: PersonalityTraits,
  navName: "Traits",
  navIcon: FaceIcon,
  alwaysAllow: false,
};

let deepProfile = {
  id: "deepProfile",
  title: "Deep Profile",
  description: "Potential for Psychological Disorders Analysis",
  component: DeepProfile,
  navName: "Deep Profile",
  navIcon: PersonPinIcon,
  alwaysAllow: false,
};

let actionPlan = {
  id: "actionPlan",
  title: "Action Plan",
  description: "Recommended Actions by Psychologist(s)",
  component: ActionPlan,
  navName: "Action Plan",
  navIcon: CenterFocusedIcon,
  alwaysAllow: false,
};

let feed = {
  id: "feed",
  title: "Feed",
  description: "Athlete's Digital Media Feeds",
  component: Feed,
  navName: "Feed",
  navIcon: DynamicFeedIcon,
  alwaysAllow: false,
};

let notes = {
  id: "notes",
  title: "Notes",
  description: "Additional notes about the Athlete",
  component: Notes,
  navName: "Notes",
  navIcon: NotesIcon,
  alwaysAllow: true,
};

let moreInfo = {
  id: "moreInfo",
  title: "More Info",
  description:
    "FitInsights Platform's Confidentiality, Process of Analysis and Limitations",
  component: MoreInfo,
  navName: "More Info",
  navIcon: InfoIcon,
  alwaysAllow: true,
};

const getConfig = (accountType) => {
  switch (accountType) {
    case accountTypes.superAdmin: {
      // allow all pages
      return {
        pages: {
          personalInfo,
          executiveSummery,
          personalityTraits,
          deepProfile,
          actionPlan,
          feed,
          notes,
          moreInfo,
        },
      };
    }

    case accountTypes.bcaDdiAdmin: {
      // allow all pages

      return {
        pages: {
          personalInfo,
          executiveSummery,
          personalityTraits,
          deepProfile,
          actionPlan,
          feed,
          notes,
          moreInfo,
        },
      };
    }

    case accountTypes.bcaPsychologist: {
      // allow all pages
      return {
        pages: {
          personalInfo,
          executiveSummery,
          personalityTraits,
          deepProfile,
          actionPlan,
          feed,
          notes,
          moreInfo,
        },
      };
    }

    case accountTypes.orgPsychologist: {
      // allow all pages
      return {
        pages: {
          personalInfo,
          executiveSummery,
          personalityTraits,
          deepProfile,
          actionPlan,
          feed,
          notes,
          moreInfo,
        },
      };
    }

    default:
      // remove deep profile for other account types
      return {
        pages: {
          personalInfo,
          executiveSummery,
          personalityTraits,
          actionPlan,
          feed,
          notes,
          moreInfo,
        },
      };
  }
};

export default getConfig;
