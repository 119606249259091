import React from "react";
import MuiTab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import '../../../index.css';

const Tab = withStyles(theme => ({
  root: {
    textTransform: "capitalize",
    fontFamily: 'Prometo',
    // fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    paddingLeft: 5,
    paddingRight: 5,
    fontSize: '14px',
    marginRight: theme.spacing(1),
    minWidth: 0,
    // "&:active": {
    //   opacity: 1,
    //   color: '#fcb643'
    // },
    // "&:focus": {
    //   opacity: 1,
    //   color: '#fcb643'
    // }
  }
}))(props => {
  return <MuiTab disableRipple {...props} />;
});

export default Tab;
