import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import PaymentDialog from "./PaymentDialog";
import PaymentIcon from "@material-ui/icons/Payment";
import { connect } from "react-redux";
import { accountTypes } from "../../constants";
import { ActionContext } from "../../../../contexts";

const Reports = (props) => {
  const { playerId, status, accountType } = props;
  const { dialogActions } = useContext(ActionContext);

  let hasAthorizedToHandlePayments =
    accountType === accountTypes.bcaDdiAdmin ||
    accountType === accountTypes.superAdmin;

  const handleClick = () => {
    dialogActions.openDialog(
      <PaymentDialog playerId={playerId} status={status} />,
      {
        maxWidth: "xs",
      }
    );
  };

  if (!hasAthorizedToHandlePayments) {
    return null;
  }

  return (
    <div style={{ marginLeft: 8 }}>
      <Button
        variant="contained"
        color="default"
        startIcon={<PaymentIcon />}
        onClick={handleClick}
        disableElevation
      >
        Payments
      </Button>
    </div>
  );
};

let mapStateToProps = (state, ownProps) => {
  return {
    accountType: state.account.accountType,
  };
};
export default connect(mapStateToProps)(Reports);
