import { combineReducers } from "redux";
import * as types from "./consentTypes";

const loadingPdf = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_GET_CONSENT_FORM: {
      return {
        ...state,
        [payload.formId]: true,
      };
    }

    case types.GET_CONSENT_FORM_SUCCESS:
    case types.GET_CONSENT_FORM_FAILED: {
      return {
        ...state,
        [payload.formId]: false,
      };
    }

    default:
      return state;
  }
};

const loadingConsentForms = (state = false, action) => {
  switch (action.type) {
    case types.REQUEST_GET_PENDING_CONSENT_FORMS: {
      return true;
    }

    case types.GET_PENDING_CONSENT_FORMS_SUCCESS:
    case types.GET_PENDING_CONSENT_FORMS_FAILED: {
      return false;
    }

    default:
      return state;
  }
};

const loadingLinkedConsentForms = (state = false, action) => {
  switch (action.type) {
    case types.REQUEST_GET_LINKED_CONSENT_FORMS: {
      return true;
    }

    case types.LINK_CONSENT_FORM_FAIL:
    case types.LINK_CONSENT_FORM_SUCCESS: {
      return false;
    }

    default:
      return state;
  }
};
const selectedConsentForm = (state = null, action) => {
  switch (action.type) {
    case types.SELECT_CONSENT_FORM: {
      return action.payload.formId;
    }

    default:
      return state;
  }
};

const selectedPlayerId = (state = null, action) => {
  switch (action.type) {
    case types.SELECT_CONSENT_PLAYER: {
      return action.payload.playerId;
    }

    default:
      return state;
  }
};

const uploadingConsentForms = (state = false, action) => {
  switch (action.type) {
    case types.REQUEST_UPLOAD_CONSENT_FORMS: {
      return true;
    }

    case types.UPLOAD_CONSENT_FORMS_SUCCESS:
    case types.UPLOAD_CONSENT_FORMS_FAILED: {
      return false;
    }

    default:
      return state;
  }
};

const searchKey = (state = "", action) => {
  switch (action.type) {
    case types.SEARCH_CONSENT_PLAYER: {
      return action.payload.searchKey;
    }

    case types.RESET_CONSENT_SEARCH: {
      return "";
    }

    default:
      return state;
  }
};

const deletingConsentForm = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.REQUEST_DELETE_CONSENT_FORM: {
      return {
        ...state,
        [payload.consentFormId]: true,
      };
    }

    case types.DELETE_CONSENT_FORM_SUCCESS:
    case types.DELETE_CONSENT_FORM_FAILED: {
      return {
        ...state,
        [payload.consentFormId]: false,
      };
    }

    default:
      return state;
  }
};

const linkingConsentForm = (state = {}, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.REQUEST_LINK_CONSENT_FORM: {
      return {
        ...state,
        [payload.formId]: true,
      };
    }

    case types.LINK_CONSENT_FORM_SUCCESS:
    case types.LINK_CONSENT_FORM_FAIL: {
      return {
        ...state,
        [payload.formId]: false,
      };
    }

    default:
      return state;
  }
};

export default combineReducers({
  selectedPlayerId,
  selectedConsentForm,
  loadingPdf,
  loadingConsentForms,
  loadingLinkedConsentForms,
  searchKey,
  uploadingConsentForms,
  deletingConsentForm,
  linkingConsentForm,
});
