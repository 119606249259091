import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Typography, IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(15),
      height: theme.spacing(15),
      padding: theme.spacing(0, 1, 1, 1),
    },
  },
  number: {
    marginBottom: theme.spacing(1),
  },
  paper: {
    display: "flex",
    flexDirection: "column",
  },
}));

const FeedStatusCard = (props) => {
  const classes = useStyles();
  const { score, icon = null, label = "" } = props;
  return (
    <div className={classes.root}>
      <Paper variant="outlined" className={classes.paper}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <IconButton>{icon}</IconButton>
        </div>
        <Typography variant="h5" align="center" className={classes.number}>
          {`${score}%`}
        </Typography>
        <Typography variant="body2" align="center" color="textSecondary">
          {label}
        </Typography>
      </Paper>
    </div>
  );
};

export default FeedStatusCard;
