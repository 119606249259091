import { consentApi } from "../../../api";
import { showErrorMessage, showSuccessMessage } from "../../shared/Snackbar";
import * as types from "./consentTypes";

// api actions
const getPendingConsentForms = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_GET_PENDING_CONSENT_FORMS,
        payload: {},
      });

      const response = await consentApi.getPendingConsentForms();

      dispatch({
        type: types.GET_PENDING_CONSENT_FORMS_SUCCESS,
        payload: {
          forms: response,
        },
      });
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.GET_PENDING_CONSENT_FORMS_FAILED,
        payload: {},
      });
    }
  };
};

const getLinkedConsentForms = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_GET_LINKED_CONSENT_FORMS,
        payload: {},
      });
      const response = await consentApi.getLinkedConsentForms();
      dispatch({
        type: types.GET_LINKED_CONSENT_FORMS_SUCCESS,
        payload: {
          forms: response,
        },
      });
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.GET_LINKED_CONSENT_FORMS_FAILED,
        payload: {},
      });
    }
  };
};
const uploadConsentForms = (files, successCb = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_UPLOAD_CONSENT_FORMS,
      });

      const response = await consentApi.postConsentForms(files);

      dispatch({
        type: types.UPLOAD_CONSENT_FORMS_SUCCESS,
        payload: {
          forms: response.forms,
        },
      });
      dispatch(showSuccessMessage(response.notification));
      successCb();
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.UPLOAD_CONSENT_FORMS_FAILED,
      });
    }
  };
};

const getConsentForm = (id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_GET_CONSENT_FORM,
        payload: {
          formId: id,
        },
      });

      const form = await consentApi.getConsentForm(id);

      dispatch({
        type: types.GET_CONSENT_FORM_SUCCESS,
        payload: {
          form: `data:application/pdf;base64, ${form}`,
          formId: id,
        },
      });
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.GET_CONSENT_FORM_FAILED,
        payload: {
          formId: id,
        },
      });
    }
  };
};

const selectPlayer = (playerId) => {
  return {
    type: types.SELECT_CONSENT_PLAYER,
    payload: {
      playerId,
    },
  };
};

const linkConsentForm = (data, handleSuccess = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_LINK_CONSENT_FORM,
        payload: {
          playerId: data.candidate_id,
          formId: data.consent_form_id,
        },
      });

      await consentApi.postLinkConsentForm(data);

      handleSuccess();
      dispatch({
        type: types.LINK_CONSENT_FORM_SUCCESS,
        payload: {
          playerId: data.candidate_id,
          formId: data.consent_form_id,
        },
      });
      dispatch(showSuccessMessage("Successfully linked"));
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.LINK_CONSENT_FORM_FAIL,
        payload: {
          playerId: data.candidate_id,
          formId: data.consent_form_id,
        },
      });
    }
  };
};

const unlinkConsentForm = (data, consentFormId) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_UNLINK_CONSENT_FORM,
        payload: {
          playerId: data.candidate_id,
        },
      });
      let form = await consentApi.postUnlinkConsentForm(data);
      dispatch({
        type: types.UNLINK_CONSENT_FORM_SUCCESS,
        payload: {
          playerId: data.candidate_id,
          ...form,
          consentFormId,
        },
      });
      dispatch(showSuccessMessage("Successfully unlinked"));
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.UNLINK_CONSENT_FORM_FAIL,
        payload: {
          playerId: data.candidate_id,
        },
      });
    }
  };
};

const deleteConsentForm = (data, handleSuccess = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_DELETE_CONSENT_FORM,
        payload: {
          consentFormId: data.consent_form_id,
        },
      });
      await consentApi.deleteConsentForm(data);
      handleSuccess();
      dispatch({
        type: types.DELETE_CONSENT_FORM_SUCCESS,
        payload: {
          consentFormId: data.consent_form_id,
        },
      });
      dispatch(showSuccessMessage("Successfully deleted"));
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.DELETE_CONSENT_FORM_FAILED,
        payload: {
          consentFormId: data.consent_form_id,
        },
      });
    }
  };
};
// ui actions

const selectConsentForm = (formId, handleSuccess = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SELECT_CONSENT_FORM,
        payload: {
          formId,
        },
      });
      handleSuccess();
    } catch (err) {
      // possibly no errors
    }
  };
};

const searchPlayer = (searchKey) => {
  return {
    type: types.SEARCH_CONSENT_PLAYER,
    payload: {
      searchKey,
    },
  };
};

const resetSearch = () => {
  return {
    type: types.RESET_CONSENT_SEARCH,
  };
};

export default {
  getPendingConsentForms,
  getLinkedConsentForms,
  uploadConsentForms,
  selectPlayer,
  getConsentForm,
  selectConsentForm,
  searchPlayer,
  linkConsentForm,
  unlinkConsentForm,
  resetSearch,
  deleteConsentForm,
};
