import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

let useStyles = makeStyles((theme) => ({
    CenterDataAlign: {
        display: 'flex',
        justifyContent: 'center',
        margin: '6rem 0 0 0'
    },
    ComingSoon: {
        width: '315px',
        height: '150px',
        flexGrow: 0,
        margin: '42px 0 41px',
        fontFamily: 'PostNoBillsJaffnaLight',
        fontSize: '70px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.07,
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#007fdb',
        position: 'absolute',
        // top: '6rem',
    },
    Ellipse: {
        width: '233px',
        height: '233px',
        flexGrow: 0,
        margin: '0 40px 0 42px',
        opacity: '0.1',
        backgroundColor: '#0971ce',
        borderRadius: '50%',
    },
    ComingSoonText: {
        flexGrow: 0,
        margin: '60px 159px 20px 158px',
        fontFamily: 'Prometo',
        fontSize: '20px',
        fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#007fdb',
    }
}))
function ComingSoon() {
    let classes = useStyles();
    return (

        <React.Fragment>
            <div className={classes.CenterDataAlign}>
                <div className={classes.Ellipse}></div>
                <div className={classes.ComingSoon}>Coming Soon !</div>
            </div>
            <div className={classes.ComingSoonText}>Coach Profile Coming Soon !</div>

        </React.Fragment>
    )
}

export default ComingSoon