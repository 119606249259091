import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import appConfig from "../../../config";
import StatCard from "./StatCard";
import { connect } from "react-redux";
import { ActionContext } from "../../../contexts";
import { makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Slide, InputBase, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import CircularProgress from "modules/shared/CircularProgress";
import PlayerDetails from "modules/athlet-management/components/PlayerDetails";
import allPlayers from './PlayersJson';
import '../../../index.css'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="left"
      ref={ref}
      timeout={{ enter: 1000, exit: 1000 }}
      {...props}
    />
  );
});

let useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.default,
    background: '#fff',
    display: "flex",
    height: `calc(100vh - 50px)`,
    zIndex: theme.zIndex.appBar,
  },
  StatusAligncss: {
    border: '1px solid #000',
    padding: '0.2rem 0.6rem',
    fontSize: '12px',
    fontWeight: '500',
    borderRadius: '9px',
  },
  ViewALlbutton: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '1rem',
    fontSize: '13px',
    color: '#0971ce',
    fontWeight: 700,
    cursor: 'pointer',
  },
  fullName: {
    '&:hover': {
      color: '#fcb643',
      borderBottom: '1px solid #fcb643',
      cursor: 'pointer'
    },
  },
  RowDataALignment: {
    display: 'block',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  CenterAlignwidth: {
    // justifyContent: 'center',
    marginBottom: '2rem',
    cursor: 'pointer'
  },
  FlexALignDataflow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '2px solid #fcb643',
    padding: '1rem'
  },
  RowALignment: {
    border: 'solid 1px #f2f2f2',
    borderRadius: '9px',
    backgroundColor: '#fff',
    marginBottom: '0.5rem',
    display: 'flex',
  },
  FlexAlignData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  container: {
    padding: 16,
    // paddingTop: 48,
    // marginLeft: "auto",
    // marginRight: "auto",
    // flexWrap: "wrap",
    width: '100%',
    [theme.breakpoints.down("md")]: {
      maxWidth: 550,
    },
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  GridFlowALign: {
    // display: 'flex',
    // width: '-webkit-fill-available',
    // overflow: 'auto',
    // height: 'fit-content',
    padding: '1rem 2rem',
    background: '#fff'
  },
  inputRoot: {
    color: 'inherit',
  },
  DataTableALignment: {
    width: '100%',
    padding: '0.8rem 0.4rem',
    fontWeight: '300',
    display: 'block',
    color: '#333333',
    fontSize: '12px',
    justifyContent: 'start',
  },
  HeaderDataTableALignment: {
    width: '100%',
    padding: '0.8rem 0.4rem',
    display: 'block',
    fontWeight: 700,
    justifyContent: 'start',
  },
  FlexALignData: {
    display: 'flex'
  },
  inputInput: {
    padding: '8px 15px',
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    paddingLeft: '10px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
      border: '1px solid #0971d6',
      color: '#0971ce',
    },
    borderRadius: '10px',
    boxShadow: '3px 1px 9px 0px #cdcdcd',
  },
  FlexAlignData: {
    display: 'grid'
  },
  // FlexAlignFlow: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   fontSize: '12px'
  // },
  SummaryFlex: {
    display: '-webkit-inline-box',
    cursor: 'pointer',
  },
}));

const Dashboard = (props) => {
  let classes = useStyles();
  let { accountType, isDrawerOpen, stats, loadingStats, liteDetailsLoader, selectedId, liteDetails, sportsData, updateFilter, staticPlayData } = props;

  let drawerWidth = isDrawerOpen
    ? appConfig.drawer.maxWidth
    : appConfig.drawer.minWidth;
  let { playerActions, accountActions, dashboardActions, conferenceActions, sportsActions, dialogActions } =
    useContext(ActionContext);


  const { count = {}, players = [], bcaDashboard = {} } = staticPlayData

  function handleSearchText(v) {
    dashboardActions.selectDashboardFilter("searchText", v);
  }

  function handleSportChange(event) {
    dashboardActions.selectDashboardFilter("searchSport", event.target.value);
  }

  function handleViewAllChange() {
    dashboardActions.selectDashboardFilter("searchText", undefined);
    dashboardActions.selectDashboardFilter("searchSport", undefined);
  }

  const handleRedirect = (playerId) => {
    playerActions.getPlayer(playerId);
    playerActions.selectPlayer(playerId);
    // playerActions.postStartAnalyze(playerId);

    dialogActions.openDialog(<PlayerDetails playerId={playerId} fromTeam={false} />, {
      fullScreen: true,
      TransitionComponent: Transition,
    });
  }

  useEffect(() => {
    dashboardActions.getStats();
    playerActions.getTeamList();
    accountActions.getUserTypes();
    accountActions.getNotifications();
    conferenceActions.getConferenceList();
    conferenceActions.getCountries();
    sportsActions.getSportsList();
    dashboardActions.selectDummyData();
    return () => {
      dashboardActions.selectCard(null);
    };
  }, []);

  const playerFilter = selectedId === "ProReports" ? (liteDetails && liteDetails) : (liteDetails && liteDetails)
  const bcaPlayers = selectedId === "status" ? (liteDetails && liteDetails) : (liteDetails && liteDetails.filter(v => v.status === selectedId))


  const filteredSearchData = updateFilter?.searchText ? playerFilter?.filter(v => v.full_name.toLowerCase().includes(updateFilter.searchText.toLowerCase())) : playerFilter && playerFilter
  const filteredData = updateFilter?.searchSport ? filteredSearchData?.filter(v => v.sport?.toLowerCase().includes(updateFilter.searchSport.toLowerCase())) : filteredSearchData && filteredSearchData
  const filteredData1 = filteredData.sort((a, b) => a.last_name > b.last_name ? 1 : -1)

  const filteredBCASearchData = updateFilter?.searchText ? bcaPlayers?.filter(v => v.full_name.toLowerCase().includes(updateFilter.searchText.toLowerCase())) : bcaPlayers && bcaPlayers
  const filteredBCAData = updateFilter?.searchSport ? filteredBCASearchData?.filter(v => v.sport?.toLowerCase().includes(updateFilter.searchSport.toLowerCase())) : filteredBCASearchData && filteredBCASearchData
  const filteredBCAData1 = filteredBCAData.sort((a, b) => a.last_name > b.last_name ? 1 : -1)

  const [orderBy, setOrderBy] = React.useState("full_name");
  const [order, setOrder] = React.useState('asc');

  const handleRequestSort = (event, property, type) => {
    const isAsc = orderBy === property && order === 'asc';
    // setOrder(isAsc ? 'desc' : 'asc');
    setOrder(type);
    setOrderBy(property);
  };


  function alphabetically(ascending) {
    return function (a, b) {

      // equal items sort equally
      if (a[orderBy] === b[orderBy]) {
        return 0;
      }
      // nulls sort after anything else
      else if (a[orderBy] === null || a[orderBy] === "") {
        return 1;
      }
      else if (b[orderBy] === null || b[orderBy] === "") {
        return -1;
      }
      // otherwise, if we're ascending, lowest sorts first
      else if (ascending) {
        return a[orderBy].toLowerCase() < b[orderBy].toLowerCase() ? -1 : 1;
      }
      // if descending, highest sorts first
      else {
        return a[orderBy].toLowerCase() < b[orderBy].toLowerCase() ? 1 : -1;
      }
    };
  }

  const upDown = order === "desc" ? false : true

  const filteredAthletes = filteredBCAData && filteredBCAData.sort(alphabetically(upDown));
  const filteredAthletesData = filteredData && filteredData.sort(alphabetically(upDown));



  const getSorting = (propertyName) => {

    return (

      <>
        {
          orderBy === propertyName && order === 'asc' ?


            <span style={{ cursor: 'pointer' }} onClick={(ev) => handleRequestSort(ev, propertyName, 'desc')}><ArrowUpwardIcon style={{ fontSize: '1rem', color: orderBy === propertyName && order === 'asc' ? "orange" : "" }} /></span>
            :
            <span style={{ cursor: 'pointer' }} onClick={(ev) => handleRequestSort(ev, propertyName, 'asc')}><ArrowDownwardIcon style={{ fontSize: '1rem', color: orderBy === propertyName && order === 'desc' ? "orange" : "" }} /></span>
        }

      </>
    )
  }

  return (
    <div className={classes.root} >
      <div className={classes.container}>
        <div className={classes.GridFlowALign}>

          {

            (accountType === "SUPER_ADMIN" || accountType === "BCA_DDI_ADMIN" || accountType === "BCA_PSYCHOLOGIST" || accountType === "BCA_CHIEF_PSYCHOLOGIST") ?

              <Grid container spacing={1} className={classes.CenterAlignwidth}>
                <Grid item lg={3} md={6} sm={12}>
                  <StatCard
                    title="Total Athletes"
                    stat={stats.all}
                    loading={loadingStats}
                    statusName="status"
                  />
                </Grid>
                <Grid item lg={3} md={6} sm={12} >
                  <StatCard
                    title="Completed"
                    stat={stats.completed}
                    loading={loadingStats}
                    statusName="Completed"
                  />
                </Grid>
                <Grid item lg={3} md={6} sm={12} >
                  <StatCard
                    title="Consent Pending"
                    stat={stats.consentPending}
                    loading={loadingStats}
                    statusName="Consent Pending"
                  />
                </Grid>
                <Grid item lg={3} md={6} sm={12}>
                  <StatCard
                    title="Psych Review Pending"
                    stat={stats.reviewPending}
                    loading={loadingStats}
                    statusName="Psych Review Pending"
                  />
                </Grid>
                <Grid item lg={3} md={6} sm={12}>
                  <StatCard
                    title="Psych Review"
                    stat={stats.psychReview}
                    loading={loadingStats}
                    statusName="Psych Review"
                  />
                </Grid>
                <Grid item lg={3} md={8} sm={12} >
                  <StatCard
                    title="Approval Pending"
                    stat={stats.approvalPending}
                    loading={loadingStats}
                    statusName="Approval Pending"
                  />
                </Grid>
                <Grid item lg={3} md={8} sm={12} >
                  <StatCard
                    title="Base Report Completed"
                    stat={stats.baseReportCompleted}
                    loading={loadingStats}
                    statusName="Base Report Completed"
                  />
                </Grid>
                <Grid item lg={3} md={8} sm={12} >
                  <StatCard
                    title="Processing"
                    stat={stats.processing}
                    loading={loadingStats}
                    statusName="Processing"
                  />
                </Grid>
                <Grid item lg={3} md={8} sm={12}>
                  <StatCard
                    title="Error"
                    stat={stats.error}
                    loading={loadingStats}
                    statusName="Error"
                  />
                </Grid>
                <Grid item lg={3} md={8} sm={12}  >
                  <StatCard

                    title="Rejected"
                    stat={stats.rejected}
                    loading={loadingStats}
                    statusName="Rejected"
                  />
                </Grid>
              </Grid>

              :

              (accountType === "COL_ATHLETIC_DIRECTOR") ?
                <Grid container spacing={1} className={classes.CenterAlignwidth}>
                  <Grid item lg={3} md={6} sm={12} >
                    <StatCard
                      title="Total Reports"
                      stat={stats.all}
                      loading={loadingStats}
                      statusName="status"
                    />
                  </Grid>
                  <Grid item lg={3} md={6} sm={12}>
                    <StatCard
                      title="Team Dashboard"
                      stat={stats.allTeams}
                      loading={loadingStats}
                      statusName="Team Dashboard"
                      sport={sportsData}
                    />
                  </Grid>
                </Grid>
                :

                <Grid container spacing={1} className={classes.CenterAlignwidth}>
                  <Grid item lg={3} md={6} sm={12} >
                    <StatCard
                      title="Total Reports"
                      stat={stats.all}
                      loading={loadingStats}
                      statusName="status"
                    />
                  </Grid>
                  <Grid item lg={3} md={6} sm={12}>
                    <StatCard
                      title="Remaining Reports"
                      stat={stats.remainingReports}
                      loading={loadingStats}
                      statusName="RemainingReports"
                    />
                  </Grid>
                  <Grid item lg={3} md={6} sm={12} >
                    <StatCard
                      style={{ backgroundColor: selectedId === "ProReports" ? '#f00' : '#f00' }}
                      title="Pro Reports Requested"
                      stat={stats.proReports}
                      loading={loadingStats}
                      statusName="Pro Reports"
                    />
                  </Grid>
                </Grid>
          }



          <div>
            {
              selectedId !== null ?

                <Grid style={{
                  margin: '0rem 1rem',
                  boxShadow: '0 2px 12px 0 rgb(0 0 0 / 10%)',
                  padding: '1rem',
                }}>
                  {liteDetailsLoader ?
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <CircularProgress size={72} thickness={3} color="primary" />
                    </div>
                    :
                    <div>
                      <div className={classes.FlexALignDataflow}>
                        <div className={classes.FlexAlignData}>
                          {
                            (accountType === "SUPER_ADMIN" || accountType === "BCA_DDI_ADMIN" || accountType === "BCA_PSYCHOLOGIST") ?
                              <div>
                                <div style={{ fontSize: '18px', fontFamily: 'Prometo', fontWeight: 'bold', color: '#0971ce' }}>{selectedId === "status" ? "Total Athletes" : selectedId}</div>
                              </div>
                              :
                              <div>
                                <div style={{ fontSize: '18px', fontFamily: 'Prometo', fontWeight: 'bold', color: '#0971ce' }}>{selectedId === "status" ? "Total Reports" : selectedId}</div>
                              </div>
                          }
                        </div>

                        <div style={{ display: 'flex' }}>
                          <div onClick={handleViewAllChange} className={classes.ViewALlbutton}>View All</div>
                          <InputBase
                            style={{ marginRight: '1rem' }}
                            placeholder="Search Player Name"
                            value={updateFilter?.searchText !== undefined ? updateFilter?.searchText : ""}
                            name="searchText"
                            classes={{
                              root: classes.inputRoot,
                              input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={ev => handleSearchText(ev.target.value)}
                          />
                          {
                            (accountType === "PRO_GENERAL_MANAGER" || accountType === "PRO_HEAD_COACH" || accountType === "PRO_POSITION_COACH" ||
                              accountType === "PRO_SCOUTS" || accountType === "PRO_SECURITY" || accountType === "PRO_PSYCHOLOGIST") ?
                              ""
                              :
                              <FormControl variant="filled">
                                <InputLabel id="demo-simple-select-autowidth-label">Sport</InputLabel>
                                <Select
                                  style={{ width: "200px", marginBottom: '1rem', background: '#fff' }}
                                  labelId="demo-simple-select-autowidth-label"
                                  id="demo-simple-select-autowidth"
                                  value={updateFilter?.searchSport !== undefined ? updateFilter?.searchSport : ""}
                                  onChange={handleSportChange}
                                  label="Sport"
                                  name="sport"
                                >
                                  {
                                    sportsData && sportsData.map(list =>
                                      <MenuItem style={{ background: '#fff' }} value={list.name}>{list.name}</MenuItem>
                                    )
                                  }
                                </Select>
                              </FormControl>
                          }
                        </div>
                      </div>

                      <TableContainer>
                        <div>
                          <div>
                            <div className={classes.FlexALignData}>
                              <span className={classes.HeaderDataTableALignment} align="left">
                                <div className={classes.FlexAlignFlow}>
                                  <div className="profile-pic">
                                    <div className={classes.SummaryFlex}>Name
                                      <div className={orderBy === "full_name" ? "" : "edit"} >
                                        <span className={classes.FlexAlignData}>{getSorting("full_name")}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div></span>
                              <span className={classes.HeaderDataTableALignment} align="left">
                                <div className={classes.FlexAlignFlow}>
                                  <div className="profile-pic">
                                    <div className={classes.SummaryFlex}>Team
                                      <div className={orderBy === "team" ? "" : "edit"} >
                                        <span className={classes.FlexAlignData}>{getSorting("team")}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div></span>
                              <span className={classes.HeaderDataTableALignment} align="left">
                                <div className={classes.FlexAlignFlow}>
                                  <div className="profile-pic">
                                    <div className={classes.SummaryFlex}>Sport
                                      <div className={orderBy === "sport" ? "" : "edit"} >
                                        <span className={classes.FlexAlignData}>{getSorting("sport")}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div></span>
                              <span className={classes.HeaderDataTableALignment} align="right">
                                <div className={classes.FlexAlignFlow}>
                                  <div className="profile-pic">
                                    <div className={classes.SummaryFlex}>Status
                                      <div className={orderBy === "status" ? "" : "edit"} >
                                        <span className={classes.FlexAlignData}>{getSorting("status")}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div></span>
                            </div>
                          </div>
                          {
                            (accountType === "SUPER_ADMIN" || accountType === "BCA_DDI_ADMIN" || accountType === "BCA_PSYCHOLOGIST") ?
                              <div className={classes.RowDataALignment}>
                                {
                                  filteredAthletes && filteredAthletes.map((result, index) => (
                                    <div className={classes.RowALignment}>
                                      <span className={classes.DataTableALignment} style={{ fontWeight: '700', fontFamily: 'Prometo', color: '#333333', }} align="left" onClick={() => handleRedirect(result.id)}><span className={classes.fullName}>{result.full_name}</span></span>
                                      <span className={classes.DataTableALignment} style={{ color: '#666666' }} align="left">{result.team}</span>
                                      <span className={classes.DataTableALignment} style={{ color: '#666666' }} align="left">{result.sport}</span>
                                      <span className={classes.DataTableALignment} align="right"><span className={classes.StatusAligncss}
                                        style={{
                                          background: result.status === 'Completed' ? '#e0f5ea' : result.status === 'Approval Pending' ? '#cfe3f2' : result.status === 'Rejected' ? '#EBE2E9' : result.status === 'Processing' ? '#fbf3d9' : result.status === 'Psych Review Pending' ? '#f2f2f2' : result.status === 'Consent Pending' ? '#cccccc' : result.status === 'Error' ? '#fddfdf' : result.status === 'Base Report Completed' ? '#BDBDBE' : '',
                                          border: result.status === 'Completed' ? '1px solid #e0f5ea' : result.status === 'Approval Pending' ? '1px solid #cfe3f2' : result.status === 'Rejected' ? '1px solid #EBE2E9' : result.status === 'Processing' ? '#fbf3d9' : result.status === 'Psych Review Pending' ? '1px solid #f2f2f2' : result.status === 'Consent Pending' ? '1px solid #cccccc' : result.status === 'Error' ? '1px solid #fddfdf' : result.status === 'Base Report Completed' ? '1px solid #BDBDBE' : '',
                                          color: result.status === 'Completed' ? '#00ae4d' : result.status === 'Approval Pending' ? '#1075bf' : result.status === 'Rejected' ? '#BC5353' : result.status === 'Processing' ? '#e7b618' : result.status === 'Psych Review Pending' ? '#666666' : result.status === 'Consent Pending' ? '#000000' : result.status === 'Error' ? '#f33a3a' : result.status === 'Base Report Completed' ? '#1A73E8' : ''
                                        }}>{result.status}</span></span>

                                    </div>
                                  ))
                                }
                              </div>
                              :
                              <div className={classes.RowDataALignment}>
                                {
                                  filteredAthletesData && filteredAthletesData.map((result, index) => (
                                    <div className={classes.RowALignment}>
                                      <span className={classes.DataTableALignment} style={{ fontWeight: '700' }} align="left" onClick={() => handleRedirect(result.id)}><span className={classes.fullName}>{result.full_name}</span></span>
                                      <span className={classes.DataTableALignment} style={{ color: '#666666' }} align="left">{result.team}</span>
                                      <span className={classes.DataTableALignment} style={{ color: '#666666' }} align="left">{result.sport}</span>
                                      <span className={classes.DataTableALignment} align="right"><span className={classes.StatusAligncss}
                                        style={{
                                          background: result.status === 'Completed' ? '#e0f5ea' : result.status === 'Approval Pending' ? '#cfe3f2' : result.status === 'Rejected' ? '#EBE2E9' : result.status === 'Processing' ? '#fbf3d9' : result.status === 'Psych Review Pending' ? '#f2f2f2' : result.status === 'Consent Pending' ? '#cccccc' : result.status === 'Error' ? '#fddfdf' : result.status === 'Base Report Completed' ? '#BDBDBE' : '',
                                          border: result.status === 'Completed' ? '1px solid #e0f5ea' : result.status === 'Approval Pending' ? '1px solid #cfe3f2' : result.status === 'Rejected' ? '1px solid #EBE2E9' : result.status === 'Processing' ? '#fbf3d9' : result.status === 'Psych Review Pending' ? '1px solid #f2f2f2' : result.status === 'Consent Pending' ? '1px solid #cccccc' : result.status === 'Error' ? '1px solid #fddfdf' : result.status === 'Base Report Completed' ? '#BDBDBE' : '',
                                          color: result.status === 'Completed' ? '#00ae4d' : result.status === 'Approval Pending' ? '#1075bf' : result.status === 'Rejected' ? '#BC5353' : result.status === 'Processing' ? '#e7b618' : result.status === 'Psych Review Pending' ? '#666666' : result.status === 'Consent Pending' ? '#000000' : result.status === 'Error' ? '#f33a3a' : result.status === 'Base Report Completed' ? '#1A73E8' : ''
                                        }}>{result.status}</span></span>

                                    </div>
                                  ))
                                }
                              </div>
                            // <TableBody>
                            //   {
                            //     filteredData && filteredData.map((result, index) => (
                            //       <TableRow>
                            //         <TableCell align="left" onClick={() => handleRedirect(result.id)}>{result.full_name}</TableCell>
                            //         <TableCell align="left">{result.team}</TableCell>
                            //         <TableCell align="left">{result.sport}</TableCell>
                            //         <TableCell align="left"><span className={classes.StatusAligncss}>{result.status}</span></TableCell>
                            //       </TableRow>
                            //     ))
                            //   }
                            // </TableBody>
                          }
                        </div>
                        {
                          (accountType === "SUPER_ADMIN" || accountType === "BCA_DDI_ADMIN" || accountType === "BCA_PSYCHOLOGIST") ?
                            (
                              filteredAthletes && filteredAthletes.length === 0 ?
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', color: '#e61e21' }}>
                                  {"No players found"}
                                </div>
                                : ""
                            )
                            :
                            (
                              filteredAthletesData && filteredAthletesData.length === 0 ?
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', color: '#e61e21' }}>
                                  {"No players found"}
                                </div>
                                : ""
                            )
                        }
                      </TableContainer>
                    </div>
                  }
                </Grid>
                :
                ""
            }
          </div>
        </div>
      </div >
    </div >
  );
};

let mapStateToProps = (state) => {
  return {
    accountType: state.account.accountType,
    isDrawerOpen: state.ui.app.isDrawerOpen,
    stats: state.dashboard.stats,
    // stats: state.dashboard.dashBoardStats,
    loadingStats: state.ui.dashboard.loadingStats,
    liteDetailsLoader: state.ui.dashboard.liteDetailsLoader,
    selectedId: state.ui.dashboard.selectedCardId,
    liteDetails: state.dashboard.liteCandidateReducer,
    sportsData: state.sports.sportsListData,
    updateFilter: state.dashboard.updateDashboardFilter,
    staticPlayData: state.dashboard.staticPlayData,

  };
};
export default connect(mapStateToProps)(Dashboard);
