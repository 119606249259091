import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { round } from "../../../utils";
import { RadialBar } from "../../shared/Charts";
import { RadialGauge } from "../../shared/Charts";
import { connect } from "react-redux";

let roundTo1PrecisionPoint = (value) => {
  return round(value, 0);
};

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 16,
  },
  details: {
    display: "flex",
    flex: 1,
    paddingRight: theme.spacing(1),
  },
}));

let options = {
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "40%",
        background: "#293450",
      },
      track: {
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 4,
          opacity: 0.15,
        },
      },
      dataLabels: {
        name: {
          show: false,
          offsetY: -10,
          color: "#fff",
          fontSize: "13px",
        },
        value: {
          color: "#fff",
          fontSize: "14px",
          offsetY: 6,
          show: true,
        },
      },
    },
  },
  stroke: {
    lineCap: "butt",
  },
};

const ChartAndDetails = (props) => {
  let classes = useStyles();
  let { diagnosis, score = 0, handleColors, tabName, team, accountType } = props;
  let color = handleColors(score);

  return (
    <div className={classes.root}>

      <div style={{ width: tabName === 'BeyondBig5' || tabName === 'Risk' ? '100%' : '20%' }}>
        {/* {tabName === 'DeepProfile' ?
          <RadialBar
            score={roundTo1PrecisionPoint(score)}
            options={options}
            color={color}
            width="100"
          />  */}
        {tabName === 'BeyondBig5' ?
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ position: 'relative' }}>
              <div><RadialGauge score={roundTo1PrecisionPoint(score)} /></div>
              <div style={{ position: 'absolute', bottom: '-20px', left: '0', marginBottom: '1em' }}>Low</div>
              <div style={{ position: 'absolute', bottom: '-20px', right: '0', marginBottom: '1em' }}>High</div>
              <div style={{ marginBottom: '1em' }}></div>
            </div>
          </div>

          :
          tabName === 'Risk' ?
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ position: 'relative' }}>
                <div><RadialGauge score={roundTo1PrecisionPoint(score)} /></div>
                <div style={{ position: 'absolute', bottom: '-20px', left: '0', marginBottom: '1em' }}>Low</div>
                <div style={{ position: 'absolute', bottom: '-20px', right: '0', marginBottom: '1em' }}>High</div>
                <div style={{ marginBottom: '1em' }}></div>
              </div>
            </div> :
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div><RadialGauge score={roundTo1PrecisionPoint(score)} /></div>
              {
                (accountType === "SUPER_ADMIN" || accountType === "BCA_PSYCHOLOGIST" || accountType === "BCA_CHIEF_PSYCHOLOGIST") ?
                  <div>{roundTo1PrecisionPoint(score)}%</div>
                  :
                  ""
              }
            </div>
        }
      </div>


      <div className={classes.details}>
        <div
          style={{ display: "flex", flex: 1, justifyContent: "space-between" }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle2" style={{ paddingLeft: 16 }}>
              {diagnosis}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

let mapStateToProps = (state) => {

  return {
    team: state.account.team,
    accountType: state.account.accountType,
  };
};

// export default ChartAndDetails;
export default connect(mapStateToProps)(ChartAndDetails);
