import apiHandler, { getAuthHeader } from "./apiHandler";
import { parseFeedResponse } from "./responseParser";

export const getCoaches = async () => {
  try {
    let headers = getAuthHeader()
    let response = await apiHandler.get("/coaches", { headers });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification)
    }

    const stats = response.data.info.coach
    return stats

  } catch (err) {
    throw new Error(err);
  }
}

export const getFilterCoaches = async (model) => {
  try {
    let headers = getAuthHeader()
    let response = await apiHandler.post('filter_coach', model, { headers })

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification)
    }

    return response.data.info.coaches

  } catch (err) {
    throw new Error(err)
  }
}


export const getCoachDetails = async (coachId) => {
  try {
    const headers = getAuthHeader();

    const response = await apiHandler.get(`/load-coach/${coachId}`, {
      headers,
    });

    if (response && response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    const player = response.data.info.coach;

    // rearrange
    const traits = player.insights["personality traits"];

    // rearrange risk
    const risk = player.insights["risk"];
    let riskComments = {};
    let riskData = Object.keys(risk).reduce((obj, category) => {
      let comments = risk[category]["comments"].reduce((obj, comment) => {
        obj[comment._id] = {
          id: comment._id,
          author: comment.author,
          content: comment.content,
          timestamp: comment.timestamp,
        };

        return obj;
      }, {});

      riskComments = { ...riskComments, ...comments };

      obj[category] = {
        id: category,
        score: risk[category]["score"],
        text: risk[category]["text"],
        comments: Object.keys(comments),
      };

      return obj;
    }, {});

    // rearrange the action plan data
    // const actionPlan = player.insights["action_plan"];

    // const actionPlanData = Object.keys(actionPlan).reduce((obj, categoryId) => {
    //   obj[`${coachId}.${categoryId}`] = actionPlan[categoryId];
    //   return obj;
    // }, {});

    const executiveSummery = player.insights["executive_summary"];

    const executiveSummeryData = Object.keys(executiveSummery).reduce(
      (obj, categoryId) => {
        obj[`${coachId}.${categoryId}`] = executiveSummery[categoryId];
        return obj;
      },
      {}
    );

    const issues = player.insights["psychological disorders"];

    // possible duplicate info
    const duplicate = player.possible_duplicate_info;

    const possibleDuplicateInfo = {
      isPossibleDuplicate: duplicate["is_possible_duplicate"],
      possiblePlayerId: duplicate.duplicate_coach["_id"],
      possiblePlayerName: duplicate.duplicate_coach["full_name"],
      possiblePlayerBirthday: duplicate.duplicate_coach["dob"],
      possiblePlayerSchool: duplicate.duplicate_coach["school"],
      possiblePlayerTeam: duplicate.duplicate_coach["team"],
      possiblePlayerPhone: duplicate.duplicate_coach["phone"],
      possiblePlayerEmail: duplicate.duplicate_coach["email"],
      possiblePlayerCreatedOn: duplicate.duplicate_coach["created_on"],
      possiblePlayerReviewedOn: duplicate.duplicate_coach["reviewed_on"],
    };

    const contact = {
      phone: player.phone,
      email: player.email,
      twitter: player.twitter,
      twitterSourceln: player.twitter_source_ln,
      instagram: player.instagram,
      instagramSourceln: player.instagram_source_ln,
      linkedin: player.linkedin,
      linkedinSourceln: player.linkedin_source_ln,
      snapchat: player.snapchat,
      facebook: player.facebook,
    };

    const basic = {
      id: player._id,
      birthday: player.dob,
      name: player.full_name,
      risk: player.risk,
      school: player.school,
      status: player.status,
      team: player.team,
      image: player.image_url,
      consent: player.consent,
      isPaid: player.is_paid,
      sport: player && player.sport,
      isPlusReport: player?.isRequestedReport !== undefined ? player.isRequestedReport : false,
      plusReportData: (player?.isRequestedReport !== undefined) ? player?.reqPlusReport : {},
      developmentScore:  player?.development_score,
      twitterStatus: player.mim_record.twitter_feed,
      instagramStatus: player.mim_record.instagram_feed,
      sentimentStatus: player.mim_record.sentiment_status,
      psyStatus: player.mim_record.personality_status,
      brain: player.brain
    };

    const customTraits = player.insights["custom_traits"];
    const behaviourRisk = player.insights["risk"];

    const reports = {
      pyschologistReport: player.reports.psychologist_report,
      systemReport: player.reports.system_report,
    };

    const reportSettings = {
      // actionPlanReport: player["report_settings"]["action_plan"]["allowed"],
      executiveSummaryReport:
        player["report_settings"]["executive_summary"]["allowed"],
      fullReport: player["report_settings"]["full_report"]["allowed"],
    };

    const data = {
      id: player._id,
      basic,
      contact,
      traits,
      risk: riskData,
      riskComments,
      // actionPlan: actionPlanData,
      executiveSummery: executiveSummeryData,
      customTraits,
      possibleDuplicateInfo,
      issues,
      stats: player.stats,
      notes: player.notes,
      links: player.links,
      message: player.message,
      reports,
      reportSettings,
      behaviourRisk,
    };

    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const markCoachAsPaid = async (coachId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`coach/is-paid/set/${coachId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const markCoachAsUnpaid = async (coachId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`coach/is-paid/unset/${coachId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const toggleFullReportPaymentStatus = async (
  coachId,
  allowed = false
) => {
  const status = allowed ? "allow" : "restrict";

  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(
      `/set-coach-report-allowed/${coachId}/full_report/${status}`,
      {
        headers,
      }
    );
    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const toggleExecutiveSummaryReportPaymentStatus = async (
  coachId,
  allowed = false
) => {
  const status = allowed ? "allow" : "restrict";

  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(
      `/set-coach-report-allowed/${coachId}/executive_summary/${status}`,
      {
        headers,
      }
    );
    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const toggleActionPlanReportPaymentStatus = async (
  coachId,
  allowed = false
) => {
  const status = allowed ? "allow" : "restrict";

  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(
      `/set-coach-report-allowed/${coachId}/action_plan/${status}`,
      {
        headers,
      }
    );
    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const exportFullReport = async (coachId) => {
  try {
    let headers = getAuthHeader();
    let timeStamp = Date.now();
    let response = await apiHandler.get(
      `/export/report/coach/${coachId}/full_report/${timeStamp}`,
      {
        responseType: "blob",
        headers,
      }
    );

    if (response.data.status && response.data.status === "Error") {
      throw new Error(response.data.notification);
    }

    var blob = new Blob([response.data], { type: "application/pdf" });

    return blob;
  } catch (err) {
    throw new Error(err);
  }
};

export const exportExecutiveSummaryReport = async (coachId) => {
  try {
    let headers = getAuthHeader();
    let timeStamp = Date.now();
    let response = await apiHandler.get(
      `/export/report/coach/${coachId}/executive_summary/${timeStamp}`,
      {
        responseType: "blob",
        headers,
      }
    );

    if (response.data.status && response.data.status === "Error") {
      throw new Error(response.data.notification);
    }

    var blob = new Blob([response.data], { type: "application/pdf" });

    return blob;
  } catch (err) {
    throw new Error(err);
  }
};

export const exportActionPlanReport = async (coachId) => {
  try {
    let headers = getAuthHeader();
    let timeStamp = Date.now();
    let response = await apiHandler.get(
      `/export/report/coach/${coachId}/action_plan/${timeStamp}`,
      {
        responseType: "blob",
        headers,
      }
    );

    if (response.data.status && response.data.status === "Error") {
      throw new Error(response.data.notification);
    }

    var blob = new Blob([response.data], { type: "application/pdf" });

    return blob;
  } catch (err) {
    throw new Error(err);
  }
};

export const reGenerateFullReport = async (coachId) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(
      `/generate/report/coach/${coachId}/full_report`,
      {
        headers,
      }
    );
    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const reGenerateActionPlanReport = async (coachId) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(
      `/generate/report/coach/${coachId}/action_plan`,
      {
        headers,
      }
    );
    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const reGenerateExecutiveSummaryReport = async (coachId) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(
      `/generate/report/coach/${coachId}/executive_summary`,
      {
        headers,
      }
    );
    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const postApproveCoachProfile = async (data) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post("/approve-coach", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const postRejectCoachProfile = async (data) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.post("/reject-coach", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const getCoachFeed = async (coachId, source) => {
  try {
    const headers = getAuthHeader();

    const response = await apiHandler.get(`/feed/coach/${coachId}/${source}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    const negativeArr = response.data.info.negatives;
    const negatives = parseFeedResponse(negativeArr);

    const neutralsArr = response.data.info.neutrals;
    const neutrals = parseFeedResponse(neutralsArr);

    const positivesArr = response.data.info.positives;
    const positives = parseFeedResponse(positivesArr);

    const data = { ...negatives, ...neutrals, ...positives };

    return {
      negatives: Object.keys(negatives),
      positives: Object.keys(positives),
      neutrals: Object.keys(neutrals),
      data,
      graphs: response.data.info.graphs,
    };
  } catch (err) {
    console.error(err);
    throw new Error(err);
  }
};

export const updateCoachRiskScore = async (
  data = {
    coach_id: "",
    category: "",
    score: 0,
  }
) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post("/coach/risk/update/score", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    const payload = response.data.info;

    return {
      candidate_id: payload.coach_id,
      risk: {
        ...payload.updated_risk,
      },
    };
  } catch (error) {
    throw new Error(error);
  }
};


export const postCoachRiskComment = async (data) => {
  try {
    let headers = getAuthHeader();
    const response = await apiHandler.post("/coach/insight/add/risk", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    let commentId = response.data.info.content_id;
    return commentId;
  } catch (err) {
    throw new Error(err);
  }
};

export const deleteCoachRiskComment = async (data) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post("/coach/insight/delete/risk", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    let commentId = response.data.info.content_id;
    return commentId;
  } catch (err) {
    throw new Error(err);
  }
};

export const markAsReviewSubmitted = async (playerId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`coach/review-submitted/${playerId}`, {
      headers,
    });
    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const postCoachReviewCompleted = async (coachId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/coach/review-complete/${coachId}`, {
      headers,
    });
    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const markAsCoachReviewIncompleted = async (coachId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/coach/review-incomplete/${coachId}`, {
      headers,
    });
    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const postExecutiveSummeryComment = async (data) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post(
      "/coach/insight/add/executive-summary",
      data,
      {
        headers,
      }
    );

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    let commentId = response.data.info.content_id;
    return commentId;
  } catch (err) {
    throw new Error(err);
  }
};


export const postUpdateNotes = async (data) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.post("/coach/insight/update/notes", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const addCoachLink = async (data) => {
  try {
    const headers = getAuthHeader();

    const response = await apiHandler.post("/coach/insight/add/link", data, {
      headers,
    });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    const links = response.data.info.links;
    return links;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteCoachLink = async (data) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.post(`/coach/insight/delete/link`, data, {
      headers,
    });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const exportCoachFeed = async (coachId, source) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(`/export/coach/feed/${coachId}/${source}`, {
      headers,
    });

    return response.data;
  } catch (err) {
    throw new Error(err);
  }
};


export const deleteExecutiveSummeryContent = async (data) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post(
      "/coach/insight/delete/executive-summary",
      data,
      {
        headers,
      }
    );

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    let commentId = response.data.info.content_id;
    return commentId;
  } catch (err) {
    throw new Error(err);
  }
};

export const postStartAnalyze = async (coachId) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post(`/coach_start_analyze/${coachId}`, {}, {
      headers,
    });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const postAnalyzeResults = async (coachId) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(`/coach_analyzed_results/${coachId}` ,{
      headers,
    });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const postSentimateResults = async (coachId) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(`/coach_sentiment_results/${coachId}` ,{
      headers,
    });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export const coachTwitter = async (coachId,requestUserId) => {
  try {
    const headers = getAuthHeader();
    // const response = await apiHandler.get(`/user_tweets/twitter/${coachId}/coach`, {
    //   headers,
    // });

    const response = await apiHandler.get(`/player_tweets/twitter/${coachId}/coach/${requestUserId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }
  } catch (error) {
    throw new Error(error);
  }
};
export const coachInstagram = async (coachId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/player_posts/instagram/${coachId}/coach`, {
      headers,
    });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }
  } catch (error) {
    throw new Error(error);
  }
};
export const coachSentimentScore = async (coachId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/receptiviti_sentiment_score/${coachId}/coach`, {
      headers,
    });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }
  } catch (error) {
    throw new Error(error);
  }
};
export const coachPsychReviewUpdate = async (coachId, statusId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/update_coach_status/${coachId}/${statusId}`, {
      headers,
    });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }
  } catch (error) {
    throw new Error(error);
  }
};
export const coachBig5Info = async (coachId) => {
  try {
    const headers = getAuthHeader();
    const response = await apiHandler.get(`/receptiviti_big5_score/${coachId}/coach`, {
      headers,
    });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }
  } catch (error) {
    throw new Error(error);
  }
};
export const getCoachVideoTranscript = async (model) => {
  try {
    let headers = getAuthHeader()
    let response = await apiHandler.post('/save-coach-transcript', model, { headers })

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification)
    }

    return response.data

  }
  catch (err) {
    throw new Error(err)
  }
}

export const postRequestPlanReports = async (coach_id) => {
  try {
    let headers = getAuthHeader()
    let response = await apiHandler.post(`/coach/request-plus-report/${coach_id}`, '', { headers })

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification)
    }

    // return response.data
    return response.data.info

  }
  catch (err) {
    throw new Error(err)
  }
}
export const approveCoach = async (model) => {
  try {
    let headers = getAuthHeader()
    let response = await apiHandler.post('/approve-coach', model, { headers })

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification)
    }

    return response.data

  }
  catch (err) {
    throw new Error(err)
  }
}
export const coachPlusReport = async (coachId) => {
  try {
    let headers = getAuthHeader()
    let response = await apiHandler.post(`/coach/request-plus-report/${coachId}`, '', { headers });
    if (response.data.status !== "Success") {
      throw new Error(response.data.notification)
    }

    return response.data

  }
  catch (err) {
    throw new Error(err)
  }
}

export const removeCoachFeed = async (coachId) => {
  try {
    let headers = getAuthHeader()
    let response = await apiHandler.get(`/remove_rt_ad_feed/${coachId}`, { headers })

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification)
    }

    return response.data

  }
  catch (err) {
    throw new Error(err)
  }
}

export const deleteExecutiveSummeryComment = async (data) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.post(
      "/coach/insight/delete/executive-summary",
      data,
      {
        headers,
      }
    );

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    let commentId = response.data.info.content_id;
    return commentId;
  } catch (err) {
    throw new Error(err);
  }
};

export const getUserId = async (user_handle) => {
  try {
    let headers = getAuthHeader()
    const response = await apiHandler.get(`/twitter_user_id/${user_handle}`, {
      headers,
    })

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification)
    }

    return response.data.info.userId

  } catch (err) {
    throw new Error(err)
  }
}



const coach = {
  getCoaches,
  getFilterCoaches,
  getCoachDetails,
  markCoachAsPaid,
  markCoachAsUnpaid,
  toggleFullReportPaymentStatus,
  toggleExecutiveSummaryReportPaymentStatus,
  toggleActionPlanReportPaymentStatus,
  exportFullReport,
  exportExecutiveSummaryReport,
  exportActionPlanReport,
  reGenerateExecutiveSummaryReport,
  reGenerateActionPlanReport,
  reGenerateFullReport,
  postApproveCoachProfile,
  postRejectCoachProfile,
  getCoachFeed,
  updateCoachRiskScore,
  postCoachRiskComment,
  deleteCoachRiskComment,
  postCoachReviewCompleted,
  markAsCoachReviewIncompleted,
  postExecutiveSummeryComment,
  postUpdateNotes,
  addCoachLink,
  exportCoachFeed,
  markAsReviewSubmitted,
  deleteCoachLink,
  deleteExecutiveSummeryContent,
  postStartAnalyze,
  postAnalyzeResults,
  postSentimateResults,
  removeCoachFeed,
  coachPlusReport,
  approveCoach,
  coachTwitter,
  coachInstagram,
  coachSentimentScore,
  coachPsychReviewUpdate,
  coachBig5Info,
  getCoachVideoTranscript,
  postRequestPlanReports,
  deleteExecutiveSummeryComment,
  getUserId
};

export default coach;