import React from "react";
import MuiCircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";

let CircularProgress = ({
  size = 24,
  fadeIn = "700ms",
  color = "secondary",
  thickness = 4.5,
  ...rest
}) => {
  return (
    <Fade in={true} style={{ transitionDelay: fadeIn }}>
      <MuiCircularProgress
        color={color}
        size={size}
        thickness={thickness}
        {...rest}
      />
    </Fade>
  );
};

export default CircularProgress;
