import React, { useContext, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import sidebarImage from "../../../assets/files_and_folder.png";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import MuiTextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "../../shared/Button";
import Alert from "@material-ui/lab/Alert";
import { ActionContext } from "../../../contexts";
import { object, string } from "yup";
import { connect } from "react-redux";
import { Field, Formik, Form } from "formik";
import { Autocomplete } from "formik-material-ui-lab";
import { TextField, Select } from "formik-material-ui";
import { makeStyles } from "@material-ui/core/styles";
import TeamDetails from "modules/coach/components/CoachList/TeamDetails";

let useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    height: "100%",
    maxHeight: "100%",
    overflow: "hidden",
  },
  header: {
    position: "absolute",
    top: 0,
    display: "flex",
    width: "100%",
    minHeight: theme.spacing(12),
    paddingLeft: theme.spacing(9),
    paddingTop: theme.spacing(10),
    alignItems: "center",
  },
  header_button: {
    marginRight: theme.spacing(2),
  },
  page: {
    marginLeft: theme.spacing(3),
    left: 0,
    right: 0,
    flex: 1,
  },
  page_content: {
    display: "flex",
    paddingTop: theme.spacing(20),
  },
  page_main: {
    display: "flex",
    flexDirection: "column",
    minWidth: 782,
    paddingLeft: theme.spacing(15),
    maxHeight: 600,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: 7,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#fff",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  page_header: {
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  page_form: {
    paddingTop: theme.spacing(1),
  },
  sidebar: {
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
    width: 320,
    backgroundColor: theme.palette.primary.main,
    backgroundAttachment: "scroll",
    backgroundPosition: "right bottom",
    backgroundSize: "100% 100%",
    zIndex: theme.zIndex.appBar,
  },
  sidebar_image: {
    position: "fixed",
    right: 0,
    top: 240,
    pointerEvents: "none",
  },
  heading: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    maxWidth: 480,
    fontWeight: 550,
    lineHeight: "60px",
  },
  form: {
    paddingTop: theme.spacing(1),
    maxWidth: 600,
  },
  button: {
    display: "flex",
    flexDirection: "row-reverse",
    paddingTop: theme.spacing(5),
  },
}));

const Layout = (props) => {
  let classes = useStyles();
  let { children, onClose, disableClose } = props;

  let handleCloseDialog = () => onClose();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <IconButton
          disabled={disableClose}
          className={classes.header_button}
          onClick={handleCloseDialog}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" color="textSecondary">
          Account Managment
        </Typography>
      </div>
      <div className={classes.page}>
        <div className={classes.page_content}>
          <div className={classes.page_main}>
            <div>{children}</div>
            <Hidden mdDown>
              <div className={classes.sidebar}>
                <div className={classes.sidebar_image}>
                  <img src={sidebarImage} alt="sidebar" width={500} />
                </div>
              </div>
            </Hidden>
          </div>
        </div>
      </div>
    </div>
  );
};

const CreateAccount = (props) => {
  let classes = useStyles();
  let { closeDialog, teams, accountTypes, creatingAccount, error, sportsData, conferenceByType, teamByConference } = props;
  let { accountActions, snackbarActions, conferenceActions } = useContext(ActionContext);

  const conferenceTypes = {
    "Professional": "Professional",
    "Collegiate": "Collegiate"
  }

  let organizationTeam = teamByConference && teamByConference.reduce((obj, item) => {
    obj[item.name] = item.name;
    return obj;
  }, {});

  let finalTeams = JSON.parse(JSON.stringify(organizationTeam));

  let organizationConference = conferenceByType && conferenceByType.reduce((obj, item) => {
    obj[item.name] = item.name;
    return obj;
  }, {});

  let finalConferenceByType = JSON.parse(JSON.stringify(organizationConference));

  let handleFormSubmit = (values, helpers) => {
    let data = {
      firstName: values.firstName,
      lastName: values.lastName,
      full_name: values.firstName + ' ' + values.lastName,
      // full_name: values.name,
      email: values.email,
      password: "",
      team: values.team,
      account_type: values.accountType,
      sport: values.sport,
      conference_type: values.conference_type,
      conference: values.conference
    };

    let handleSuccess = () => {
      helpers.setSubmitting(false);
      helpers.resetForm();
      snackbarActions.showSnackbar(
        "Confirmation email has been sent with account details"
      );
    };

    let handleFail = () => {
      helpers.setSubmitting(false);
    };

    accountActions.signUp(data, handleSuccess, handleFail);
  };

  let defaultTeam =
    Object.keys(teams).length > 1 ? null : Object.keys(teams)[0];

  let handleErrorClose = () => {
    accountActions.resetAccountCreationError();
  };

  const handleConferenceType = (values, value) => {
    values.conference_type = value;
    conferenceActions.getConferenceByType(value);
  }

  useEffect(() => {
    return () => {
      accountActions.resetAccountCreationError();
    };
  }, []);

  useEffect(() => {
    if (error && error.length > 0) {
      setTimeout(() => {
        handleErrorClose();
      }, 3500);
    }
  }, [error]);

  return (
    <Layout onClose={closeDialog} disableClose={creatingAccount}>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          // name: "",
          email: "",
          team: defaultTeam,
          accountType: "",
          sport: "",
          conference_type: "",
          conference: ""
        }}
        validationSchema={object().shape({
          firstName: string()
            .trim()
            .matches(
              /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
              "Enter valid First name"
            )
            .required("Enter player first ame"),
          lastName: string()
            .trim()
            .matches(
              /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
              "Enter valid Last name"
            )
            .required("Enter player last name"),
          team: string().nullable().required("Select team"),
          email: string().email("Enter a valid email").required("Enter email"),
          accountType: string().required("Select Role"),
          sport: string().required("Select Sport"),
          conference_type: string().nullable().required("Select League"),
          conference: string().nullable().required("Select Conference"),
        })}
        onSubmit={handleFormSubmit}
      >
        {(formik) => {
          let { errors, touched, values } = formik;

          const handleChange = (value) => {
            values.conference_type = value;
            values.conference = "";
            values.team = "";
            conferenceActions.getConferenceByType(value);
          }

          const handleChangeConf = (value) => {
            values.conference = value;
            values.team = "";
            conferenceActions.getTeamsByConferenceId(value);
          }

          return (
            <Form autoComplete="off">
              <Typography variant="h4" className={classes.heading}>
                Create Account
              </Typography>

              <div className={classes.form}>
                <Grid container spacing={3}>
                  {error ? (
                    <Grid item>
                      <Alert severity="error" onClose={handleErrorClose}>
                        {error}
                      </Alert>
                    </Grid>
                  ) : null}


                  <Grid item lg={6} sm={12}>
                    <Field
                      name="firstName"
                      label="First Name"
                      placeholder="First Name"
                      component={TextField}
                      variant="outlined"
                      required
                      fullWidth
                      autoFocus
                    />
                  </Grid>
                  <Grid item lg={6} sm={12}>
                    <Field
                      name="lastName"
                      label="Last Name"
                      placeholder="Last Name"
                      component={TextField}
                      variant="outlined"
                      fullWidth
                      autoFocus
                      required
                    />
                  </Grid>

                  <Grid item md={12} sm={12}>
                    <Field
                      name="email"
                      label="Email"
                      placeholder="Email"
                      component={TextField}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>

                  <Grid item lg={6} sm={12}>
                    <Field
                      name="conference_type"
                      required
                      component={Autocomplete}
                      options={Object.keys(conferenceTypes)}
                      renderInput={(props) => {
                        return (
                          <MuiTextField
                            variant="outlined"
                            label="League"
                            placeholder="League"
                            error={touched["conference_type"] && !!errors["conference_type"]}
                            helperText={errors["conference_type"]}
                            required
                            {...props}
                          />
                        );
                      }}
                      onChange={(e, value) => {
                        // handleConferenceType(values,value)
                        handleChange(value)
                      }}
                      renderOption={(value, { inputValue }) => {
                        const matches = match(value, inputValue);
                        const parts = parse(value, matches);

                        return (
                          <div>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                style={{ fontWeight: part.highlight ? 700 : 400 }}
                              >
                                {part.text}
                              </span>
                            ))}
                          </div>
                        );
                      }}
                    />
                  </Grid>


                  {/* <Grid item lg={6} sm={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="conference-label">
                        Conference *
                      </InputLabel>
                      <Field
                        component={Select}
                        name="conference"
                        label="Conference *"
                        placeholder="Conference *"
                        labelId="conference-label"
                        inputProps={{
                          id: "conference",
                        }}
                        required
                        onChange={(e, value) => {
                          handleChangeConf(value)
                        }}
                      >
                        {
                          conferenceByType && conferenceByType.map(list =>
                            <MenuItem value={list.name}>{list.name}</MenuItem>
                          )
                        }
                      </Field>
                    </FormControl>
                  </Grid> */}

                  <Grid item md={12} sm={12} lg={6}>
                    <Field
                      name="conference"
                      component={Autocomplete}
                      options={Object.keys(finalConferenceByType)}
                      required
                      renderInput={(props) => {
                        return (
                          <MuiTextField
                            variant="outlined"
                            label="Conference"
                            placeholder="Conference"
                            error={touched["conference"] && !!errors["conference"]}
                            helperText={errors["conference"]}
                            {...props}
                          />
                        );
                      }}
                      onChange={(e, value) => {
                        handleChangeConf(value)
                      }}

                      renderOption={(value, { inputValue }) => {
                        const matches = match(value, inputValue);
                        const parts = parse(value, matches);

                        return (
                          <div>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                style={{
                                  fontWeight: part.highlight ? 700 : 400,
                                }}
                              >
                                {part.text}
                              </span>
                            ))}
                          </div>
                        );
                      }}
                    />
                  </Grid>





                  <Grid item md={12} sm={12} lg={6}>
                    <Field
                      name="team"
                      component={Autocomplete}
                      options={Object.keys(finalTeams)}
                      required
                      renderInput={(props) => {
                        return (
                          <MuiTextField
                            variant="outlined"
                            label="Select Team *"
                            placeholder="Team"
                            error={touched["team"] && !!errors["team"]}
                            helperText={errors["team"]}
                            {...props}
                          />
                        );
                      }}
                      renderOption={(value, { inputValue }) => {
                        const matches = match(value, inputValue);
                        const parts = parse(value, matches);

                        return (
                          <div>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                style={{
                                  fontWeight: part.highlight ? 700 : 400,
                                }}
                              >
                                {part.text}
                              </span>
                            ))}
                          </div>
                        );
                      }}
                    />
                  </Grid>

                  <Grid item lg={6} sm={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="sport-label">
                        Sport *
                      </InputLabel>
                      <Field
                        component={Select}
                        name="sport"
                        label="Sport *"
                        placeholder="Sport *"
                        labelId="sport-label"
                        required
                        inputProps={{
                          id: "sport",
                        }}
                      >
                        {
                          sportsData && sportsData.map(list =>
                            <MenuItem value={list.name}>{list.name}</MenuItem>
                          )
                        }
                      </Field>
                    </FormControl>
                  </Grid>

                  <Grid item md={12} sm={12} lg={6}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={touched["accountType"] && !!errors["accountType"]}
                    >
                      <InputLabel htmlFor="accountTypeLabel">
                        Role *
                      </InputLabel>
                      <Field
                        component={Select}
                        name="accountType"
                        label="Role *"
                        placeholder="Role *"
                        labelId="accountTypeLabel"
                        inputProps={{
                          id: "accountType",
                        }}
                        required
                      >
                        {Object.keys(accountTypes)
                          .filter((key) => {
                            if (
                              values.team === "BCA-DDI" &&
                              accountTypes[key]["code"] ===
                              accountTypes["ORG_ADMIN"]["code"]
                            ) {
                              return false;
                            }
                            return true;
                          })
                          .filter((key) => {
                            if (values?.conference_type === "Professional" && (key.slice(0, 3) === "COL")) {
                              return false;
                            } else if (values?.conference_type === "Collegiate" && (key.slice(0, 3) === "PRO")) {
                              return false;
                            } else {
                              return true;
                            }
                          })
                          .map((key, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={accountTypes[key]["code"]}
                              >
                                {accountTypes[key]["name"]}
                              </MenuItem>
                            );
                          })}
                      </Field>
                      {touched["accountType"] && !!errors["accountType"] ? (
                        <FormHelperText>{errors["accountType"]}</FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                </Grid>
                <div className={classes.button}>
                  <Button
                    type="submit"
                    color="primary"
                    size="large"
                    loading={creatingAccount}
                  >
                    Create
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Layout>
  );
};

let mapStateToProps = (state) => {
  return {
    teams: state.ui.player.organizationTeams,
    accountTypes: state.ui.account.userTypes,
    creatingAccount: state.ui.account.register.loading,
    error: state.ui.account.register.error,
    sportsData: state.sports.sportsListData,
    conferenceByType: state.conference.allConferenceByType,
    teamByConference: state.conference.teamByConference,
  };
};
export default connect(mapStateToProps)(CreateAccount);
