import React from "react";
import { Editor as ReactDraftWysiwygEditor } from "react-draft-wysiwyg";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

let useStyles = makeStyles((theme) => ({
  editor: {
    // minHeight: 200,
    fontSize: '13px',
    fontFamily: 'Roboto',
    fontWeight: 100,
    minHeight: 'auto',
  },
}));

const Editor = (props) => {
  let {
    onEditorStateChange,
    editorState,
    disabled,
    readOnly,
    classes = {},
    ...rest
  } = props;
  let defaultclasses = useStyles();

  let handleEditorStateChange = (state) => {
    onEditorStateChange(state);
  };

  return (
    <div>
      <ReactDraftWysiwygEditor
        readOnly={disabled || readOnly}
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName={clsx(defaultclasses.editor, classes.editor)}
        onEditorStateChange={handleEditorStateChange}
        toolbar={{
          options: [
            "fontFamily",
            "blockType",
            "fontSize",
            "inline",
            "link",
            "list",
            "textAlign",
            "colorPicker",
            "history",
          ],
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: [
              "bold",
              "italic",
              "underline",
              "strikethrough",
              "monospace",
            ],
          },
          fontFamily: {
            options: [
              "Arial",
              "Georgia",
              "Impact",
              "Tahoma",
              "Roboto",
              "Times New Roman",
              "Verdana",
            ],
          },
        }}
        {...rest}
      />
    </div>
  );
};

export default Editor;
