import { makeStyles } from "@material-ui/core/styles";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import WebIcon from "@material-ui/icons/Web";
import YoutubeIcon from "@material-ui/icons/YouTube";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Tabs } from "../../../shared/Tabs";
import { sourceTypes } from "modules/players/constants";
import FeedPanel from "./FeedPanel";
import GraphPanel from "./GraphPanel";
import { ActionContext } from "../../../../contexts";

const useStyles = makeStyles((theme) => ({
  tabs: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    maxHeight: 48,
  },
}));

const Feed = (props) => {
  let classes = useStyles();
  let { playerId, stats } = props;
  let { playerActions } = useContext(ActionContext);
  let [selectedIndex, setSelectedIndex] = useState(0);

  let handleTabChange = (e, index) => {
    setSelectedIndex(index);
  };


  return (
    <React.Fragment>
      <div style={{
        display: "flex",
        // alignItems: "center",
        flexDirection: "row",
        justifyContent: "center",
        width: "100%",
      }}>
        <Tabs
          labels={["Twitter", "Instagram", "Videos", "Articles"]}
          value={selectedIndex}
          onChange={handleTabChange}
          orientation="vertical"
          variant="scrollable"
        />

        <div style={{
          display: 'flex', flexDirection: 'column',
          background: 'rgb(255, 255, 255)',
          borderRadius: '0.7rem',
          boxShadow: 'rgb(205 205 205) 3px 3px 8px 3px',
          padding: '1rem',
          marginLeft: '1rem',
          width: '85%'
        }}>
          <GraphPanel
            playerId={playerId}
            config={{
              0: {
                source: sourceTypes.twitter,
                show: true,
              },
              1: {
                source: sourceTypes.instagram,
                show: true,
              },
              2: {
                source: sourceTypes.youtube,
                show: true,
              },
              3: {
                source: sourceTypes.newspaper,
                show: true,
              },
            }}
            selectedIndex={selectedIndex}
          />
          {/* </div>
      <div> */}
          <FeedPanel
            playerId={playerId}
            source="twitter"
            index={0}
            value={selectedIndex}
            icon={
              <TwitterIcon
                style={{ marginLeft: 8, marginRight: 8, color: "#1DA1F2" }}
              />
            }
          />

          <FeedPanel
            playerId={playerId}
            source="instagram"
            index={1}
            value={selectedIndex}
            icon={
              <InstagramIcon
                style={{ marginLeft: 8, marginRight: 8, color: "#C13584" }}
              />
            }
          />

          <FeedPanel
            playerId={playerId}
            source="youtube"
            index={2}
            value={selectedIndex}
            icon={
              <YoutubeIcon
                style={{ marginLeft: 8, marginRight: 8, color: "#ff2500" }}
              />
            }
          />

          <FeedPanel
            playerId={playerId}
            source="newspaper"
            index={3}
            value={selectedIndex}
            icon={
              <WebIcon
                style={{ marginLeft: 8, marginRight: 8, color: "#ffc107" }}
              />
            }
          />
        </div>
      </div>
      {/* </div> */}
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { playerId } = ownProps;
  return {
    stats: state.player.stats[playerId],
  };
};
export default connect(mapStateToProps)(Feed);
