import React from "react";
import MuiAppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Filters from "../../players/components/Filters";
import AccountPopover from "../AccountPopover";
import NotificationsPopover from "../NotificationsPopover";
import appBarLogo from "../../../assets/perc3pt/image2vector.svg";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useRouteMatch } from "react-router-dom";
import Drawer from "../components/Drawer";
import EqualizerIcon from '@material-ui/icons/Equalizer';

let useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: '#fafafd',
    borderBottom: '1px solid #fff',
    // background: "linear-gradient(to right, #ffffff, #007fdb)",
    // borderBottom: `1px solid ${theme.palette.divider}`,
  },
  logo: {
    display: "flex",
    alignItems: "center",
    width: 257,
    marginLeft: -8,
    // background: '#eeeef0',
    paddingLeft: '2rem',
  },
  image: {
    cursor: "pointer",
  },
  filters: {
    flexGrow: 1,
  },
}));

let AppBar = (props) => {
  let classes = useStyles();
  let history = useHistory();
  let isPlayersRoute = useRouteMatch({ path: "/players", strict: true });

  let handleHomeClick = () => {
    history.push("/");
  };

  let handleRedirectToUpdatePage = () => {
    history.push("/todays-update");
  };

  return (
    <div>
      <MuiAppBar position="fixed" className={classes.appBar} {...props}>
        <Drawer />
        <Toolbar variant="dense" >
          {/* <div className={classes.logo}>
            <img
              src={appBarLogo}
              className={classes.image}
              height={50}
              onClick={handleHomeClick}
              alt="logo"
            />
          </div> */}

          <div className={classes.filters}>
            <Hidden smDown>{isPlayersRoute ? <Filters /> : null}</Hidden>
          </div>

          <div>
            <Grid container spacing={2} direction="row-reverse">
              <Grid item>
                <AccountPopover />
              </Grid>
              <Grid item style={{ marginTop: '0.4rem' }}>
                <NotificationsPopover />
              </Grid>
              {
                (props.accountType === "SUPER_ADMIN" || props.accountType === "BCA_DDI_ADMIN") ?
                  <Grid item style={{ marginTop: '0.4rem' }}>
                    <span onClick={handleRedirectToUpdatePage}>
                      <EqualizerIcon style={{ color: '#000', cursor: 'pointer' }} />
                    </span>
                  </Grid>
                  :
                  ""
              }
            </Grid>
          </div>
        </Toolbar>
        <Hidden mdUp>
          {isPlayersRoute ? (
            <Toolbar variant="dense" style={{ padding: 0 }}>
              <Filters />
            </Toolbar>
          ) : null}
        </Hidden>
      </MuiAppBar>
      {/* <Toolbar variant="dense" /> */}
    </div>
  );
};

export default AppBar;
