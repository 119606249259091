import React, { useState, useContext } from "react";
import List from "@material-ui/core/List";
import ListLoading from "./ListLoading";
import { connect } from "react-redux";
import { makeStyles, useTheme, fade } from "@material-ui/core/styles";
import { Button, Card, Grid, InputBase, Slide } from "@material-ui/core"
import { ActionContext } from "../../../../contexts";

let useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    height: `calc(100vh - 50px)`,
    zIndex: theme.zIndex.appBar,
  },
  container: {
    padding: 16,
    marginRight: "auto",
    [theme.breakpoints.down("md")]: {
      maxWidth: 550,
    },
  },
  FullName: {
    fontWeight: '700',
    color: '#333333'
  },
  Team: {
    color: '#666666',
    fontSize: '12px'
  },
  Status: {
    fontSize: '11px',
    padding: '0.1rem 0.7rem',
    borderRadius: '0.9rem',
    fontWeight: 500,
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  secondaryAction: {
    display: "flex",
    flexDirection: "column",
  },
  badgeContent: {
    width: 18,
    height: 18,
    backgroundColor: theme.palette.background.paper,
    border: `2px solid #fff`,
  },
  card: {
    flexGrow: 0,
    padding: '14px',
    borderRadius: '9px',
    boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.06)',
    backgroundColor: '#fff',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  search: {
    justifyContent: 'space-between',
    display: 'flex',
    position: 'relative',
    marginBottom: '14px',
    marginRight: '0px',
    marginTop: '14px',
    borderRadius: '10px',
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
      color: '#0971ce',
      fontWeight: 'bold',
      fontSize: '18px',
      width: 'auto',
      borderBottom: '1px solid #fdb525',
      borderRadius: 'inherit',
      paddingBottom: '1rem',
      background: 'none'
    },
  },
  searchIcon: {
    padding: '0px 5px',
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.primary.main
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: '8px 15px',
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    paddingLeft: '10px',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
      border: '1px solid #0971ce',
    },
    borderRadius: '10px',
  },
  AvatarCellDesign: {
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    fontSize: '12px',
    height: '32px',
    width: '32px',
    boxShadow: '3px 3px 8px 0px #cdcdcd',
    color: '#fff',
    fontWeight: '700'
  },
}));

let SportList = (props) => {
  let classes = useStyles();
  let { sportsData, loading } = props;
  let actions = useContext(ActionContext);
  let { sportsActions } = actions;

  const [searchText, setSearchText] = useState(undefined);

  function handleSearchText(v) {
    setSearchText(v);
  }


  function handleCardClick(sportId, name) {
    sportsActions.getSportUnitDetails(name);
    sportsActions.selectSport(sportId);
    sportsActions.selectSportsPage("unit")
    sportsActions.selectSportsType(name);
  }

  const filteredData = searchText ? sportsData && sportsData.filter(v => v.name.toLowerCase().includes(searchText.toLowerCase())) : sportsData && sportsData


  return (
    <React.Fragment>
      <div className={classes.container}>
        <div className={classes.search}>
          <div>
            <label>{"Sports"} </label>
          </div>
          <div>
            <InputBase
              placeholder="Search Sport Name"
              value={searchText}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onChange={ev => handleSearchText(ev.target.value)}
            />
          </div>
        </div>
        <Grid container spacing={1}>
          {
            loading ?
              <ListLoading />
              :
              filteredData && filteredData.length === 0 ?
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', color: '#e61e21' }}>
                  {"No sports found"}
                </div>
                :
                filteredData && filteredData.map(list => (
                  <Grid item xs={12} sm={4}>
                    <Card className={classes.card} onClick={() => handleCardClick(list._id, list.name)}>
                      <div style={{ display: 'flex' }}>
                        <div style={{ width: '100%', marginLeft: '1rem' }}>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <span className={classes.FullName}>{list.name}</span>
                          </div>

                        </div>
                      </div>
                    </Card>
                  </Grid>
                ))
          }

        </Grid>
      </div>
    </React.Fragment>
  );
};

let mapStateToProps = (state) => {
  return {
    sportsData: state.sports.sportsListData,
    loading: state.ui.sports.sportsListLoading,
  };
};
export default connect(mapStateToProps)(SportList);
