import React from "react";
import List from "@material-ui/core/List";
import PlayerCard from "./PlayerCard";
import { statusTypes, riskTypes } from "../../constants";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

let useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    flex: 1,
    width: "100%",
    overflowY: "scroll",
    backgroundColor: theme.palette.background.paper,
    "&::-webkit-scrollbar": {
      width: 8,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.background.default,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[300],
      opacity: 0.5,
    },
  },
  list: {
    padding: 0,
  },
  item: {
    padding: 0,
  },
}));

let PlayerList = (props) => {
  let classes = useStyles();
  let {
    teamFilter,
    statusFilter,
    riskFilter,
    conferenceFilter,
    sportFilter,
    searchKey,
    playersById,
    allPlayerIds,
  } = props;

  let filteredByTeam = [];
  let filteredByStatus = [];
  let filteredByRisk = [];
  let filteredByConference = [];
  let filteredBySPort = [];
  let filteredBySearchKey = [];

  // filter by team
  allPlayerIds.forEach((id) => {
    if (teamFilter !== "team") {
      let player = playersById[id];
      if (player.team === teamFilter) {
        filteredByTeam.push(id);
      }
    } else {
      filteredByTeam.push(id);
    }
  });

  // filter by status
  filteredByTeam.forEach((id) => {
    if (statusFilter !== statusTypes.default) {
      let player = playersById[id];
      if (player.status === statusFilter) {
        filteredByStatus.push(id);
      }
    } else {
      filteredByStatus.push(id);
    }
  });

  // filter by risk
  filteredByStatus.forEach((id) => {
    if (riskTypes.default !== riskFilter) {
      let player = playersById[id];
      if (player.risk === riskFilter) {
        filteredByRisk.push(id);
      }
    } else {
      filteredByRisk.push(id);
    }
  });

  // filter by search param
  filteredByRisk.forEach((id) => {
    let player = playersById[id];
    if (player.name.toLowerCase().includes(searchKey)) {
      filteredBySearchKey.push(id);
    }

    // if (conferenceFilter !== "conference") {
    //   let player = playersById[id];
    //   if (player.conference === conferenceFilter) {
    //     filteredByConference.push(id);
    //   }
    // } else {
    //   filteredByConference.push(id);
    // }

  });

  // // filter by sport param
  // filteredByConference.forEach((id) => {
  //   if (sportFilter !== "sport") {
  //     let player = playersById[id];
  //     if (player.sport === sportFilter) {
  //       filteredBySPort.push(id);
  //     }
  //   } else {
  //     filteredBySPort.push(id);
  //   }
  // });

  // // filter by search param
  // filteredBySPort.forEach((id) => {
  //   let player = playersById[id];
  //   if (player.name.toLowerCase().includes(searchKey)) {
  //     filteredBySearchKey.push(id);
  //   }
  // });

  return (
    <div className={classes.root}>
      {filteredBySearchKey.length > 0 ? (
        <List className={classes.list}>
          {filteredBySearchKey.map((playerId) => (
            <PlayerCard id={playerId} key={playerId} />
          ))}
        </List>
      ) : null}
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    teamFilter: state.ui.player.teamFilter,
    statusFilter: state.ui.player.statusFilter,
    riskFilter: state.ui.player.riskFilter,
    consentFilter: state.ui.player.consentFilter,
    sportFilter: state.ui.player.sportFilter,
    conferenceFilter: state.ui.player.conferenceFilter,
    searchKey: state.ui.player.searchKey,
    playersById: state.player.players.byId,
    allPlayerIds: state.player.players.allIds,
  };
};
export default connect(mapStateToProps)(PlayerList);
