import React, { useContext, useEffect } from 'react'
import { Button, Box, FormControl, FormGroup, FormControlLabel, Checkbox, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select } from "@material-ui/core"
import { connect } from "react-redux";
import { ActionContext } from "../../../contexts";
import { makeStyles } from "@material-ui/core/styles";
import filterDashedLogo from "../../../assets/FilterDashedIcon.svg";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useSelector } from "react-redux";

let useStyles = makeStyles((theme) => ({
    btnPrimary: {
        color: '#fff',
        border: '1px solid',
        padding: '10px',
        background: '#007fdb',
        width: '13rem',
        marginRight: '10px',
        borderRadius: '20px',
        '&:hover': {
            background: '#007fdb',
            color: '#fff'
        },
    },
    btnSecondary: {
        color: theme.palette.secondary.main,
        border: '1px solid',
        width: '13rem',
        padding: '10px',
        marginRight: '10px',
        borderRadius: '20px',
        '&:hover': {
            background: theme.palette.secondary.main,
            color: '#fff'
        }
    },
}));

function Filter(props) {

    const { accountType, isFilteredApply, pageChange, conferenceData, sportsData, unitData, conferenceByType, loadingFilters, updateFilter } = props;
    let classes = useStyles();
    let actions = useContext(ActionContext);
    let { coachActions, sportsActions, conferenceActions, playerActions } = actions;
    let accountState = useSelector((state) => state.account);

    const [state, setState] = React.useState({
        conference: "",
        sport: "",
        unit: "",
        coachType: "All",
        conferenceType: "All",
    });

    useEffect(() => {
        if (updateFilter) {
            setState(updateFilter);
        }
    }, [updateFilter]);


    const handleCoachChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
        coachActions.updateCoachFilterValue(event.target.name, event.target.value)
    }

    const handleConferenceChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
        coachActions.updateCoachFilterValue(event.target.name, event.target.value)
        if (event.target.value === "All") {
            conferenceActions.getConferenceList();
        } else {
            conferenceActions.getConferenceByType(event.target.value);
            setState({
                ...state,
                "conference": "",
            });
            coachActions.updateCoachFilterValue("conference", "")
        }

    }
    useEffect(() => {
        if (accountType === "COL_HEAD_COACH" || accountType === "COL_POSITION_COACH" || accountType === "PRO_POSITION_COACH" || accountType === "PRO_GENERAL_MANAGER" || accountType === "PRO_HEAD_COACH" || accountType === "PRO_SECURITY" || accountType === "PRO_PSYCHOLOGIST") {

            if (accountState?.sport) {
                setState({
                    ...state,
                    sport: accountState?.sport ? accountState?.sport : ''

                })
                playerActions.updateFilterValue("sport", accountState?.sport)
                sportsActions.getSportUnitDetails(accountState?.sport);
            } else {
                playerActions.updateFilterValue("sport", "Football")
                sportsActions.getSportUnitDetails("Football");
            }

        }

    }, [accountType]);

    const handleSportChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
            "unit": "",
        });
        coachActions.updateCoachFilterValue(event.target.name, event.target.value)
        sportsActions.getSportUnitDetails(event.target.value);
        coachActions.updateCoachFilterValue("unit", "")
    }

    const handleCancel = () => {
        coachActions.resetCoachFilter()
    }
    const handleSave = () => {
        let model = {
            conference: state.conference ? [state.conference] : [],
            sport: state.sport ? [state.sport] : [],
            unit: state.unit ? [state.unit] : [],
            coachType: state.coachType === "All" ? [] : [state.coachType],
            conferenceType: state.conferenceType === "All" ? [] : [state.conferenceType]
        }
        // pageChange('list')
        // coachActions.isCoachFiltered(true)
        coachActions.getAllFilterCoaches(model)

    }

    const redirectToCoachPage = () => {
        pageChange('list')
    }

    useEffect(() => {
        conferenceActions.getConferenceList();
        sportsActions.getSportsList();

    }, []);

    useEffect(() => {
        if (loadingFilters === true) {
            pageChange('list')
            coachActions.isCoachFiltered(true)
            isFilteredApply(true)
        }

    }, [loadingFilters]);

    const finalConference = state.conferenceType !== "All" ? conferenceByType : conferenceData

    return (
        <React.Fragment>
            <div style={{
                display: 'flex', margin: '2rem 1rem 1rem 2rem', boxShadow: '3px 3px 10px 3px #cdcdcd',
                margin: '2rem 1rem',
                padding: '2rem',
                borderRadius: '0.5rem',
            }}>
                <Grid container spacing={1}>
                    <Grid item xs={7}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', fontWeight: 'bold', fontSize: "15px" }}><span style={{ display: 'flex', alignItems: 'center', fontSize: '18px' }}><ArrowBackIosIcon style={{ color: '#0971ce', fontSize: "20px" }} onClick={redirectToCoachPage} />Back</span></div>
                        <div>
                            <div style={{
                                display: 'flex', justifyContent: 'flex-start', fontWeight: 'bold', fontSize: "20px", fontSize: '20.5px',
                                margin: '1.5rem 0rem', fontFamily: 'Prometo',
                                color: '#0971ce',
                            }}>
                                Blue Chip Filter
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', fontWeight: 'bold', fontSize: "19px", margin: '2rem 0rem', color: '#000000' }}>
                                Select all that are necessary
                            </div>
                            <div>
                                {
                                    (accountType === "SUPER_ADMIN" || accountType === "BCA_DDI_ADMIN" || accountType === "BCA_PSYCHOLOGIST" || accountType === "BCA_CHIEF_PSYCHOLOGIST") ?
                                        <div style={{ margin: '1rem 0rem', alignItems: 'center', justifyContent: 'center' }}>
                                            <Box sx={{ display: 'flex' }}>
                                                <FormControl>
                                                    <FormLabel id="demo-radio-buttons-group-label" >League</FormLabel>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        defaultValue=""
                                                        row
                                                        name="conferenceType"
                                                        value={state.conferenceType}

                                                        onChange={handleConferenceChange}
                                                    >
                                                        <FormControlLabel value="All" control={<Radio />} label="All" />
                                                        <FormControlLabel value="Professional" control={<Radio />} label="Professional" />
                                                        <FormControlLabel value="Collegiate" control={<Radio />} label="Collegiate" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Box>
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    (accountType === "SUPER_ADMIN" || accountType === "BCA_DDI_ADMIN" || accountType === "BCA_PSYCHOLOGIST" || accountType === "BCA_CHIEF_PSYCHOLOGIST" || accountType === "COL_ATHLETIC_DIRECTOR" || accountType === "COL_PSYCHOLOGIST" || accountType === "COL_HEAD_COACH" || accountType === "COL_POSITION_COACH" ||
                                        accountType === "PRO_POSITION_COACH" || accountType === "PRO_GENERAL_MANAGER" || accountType === "PRO_HEAD_COACH" || accountType === "PRO_SECURITY" || accountType === "PRO_PSYCHOLOGIST"
                                    ) ?
                                        <div style={{ margin: '1rem 0rem', alignItems: 'center', justifyContent: 'center' }}>
                                            <FormControl>
                                                <FormLabel id="demo-radio-buttons-group-label">Coach Type</FormLabel>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue=""
                                                    row
                                                    name="coachType"
                                                    value={state.coachType}
                                                    onChange={handleCoachChange}
                                                >
                                                    <FormControlLabel value="All" control={<Radio />} label="All" />
                                                    <FormControlLabel value="Current Employee" control={<Radio />} label="Current Employee" />
                                                    <FormControlLabel value="Prospect Hire" control={<Radio />} label="Prospect Hire" />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                        :
                                        ""
                                }
                                {/* {
                                    (accountType === "COL_HEAD_COACH" || accountType === "COL_ATHLETIC_DIRECTOR" || accountType === "PRO_GENERAL_MANAGER" || accountType === "PRO_HEAD_COACH"
                                    ) ?
                                        <div style={{ margin: '1rem 0rem', alignItems: 'center', justifyContent: 'center' }}>
                                            <FormControl>
                                                <FormLabel id="demo-radio-buttons-group-label">Coach Type</FormLabel>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue=""
                                                    row
                                                    name="coachType"
                                                    value={state.coachType}
                                                    onChange={handleCoachChange}
                                                >
                                                    <FormControlLabel value="All" control={<Radio />} label="All" />
                                                    <FormControlLabel value="Contract Coach" control={<Radio />} label="Contract Coach" />
                                                    <FormControlLabel value="Recruit Coach" control={<Radio />} label="Recruit Coach" />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                        :
                                        ""
                                } */}
                            </div>


                            <div style={{ display: 'flex', marginBottom: '1rem', }}>

                                {
                                    (accountType === "SUPER_ADMIN" || accountType === "BCA_DDI_ADMIN" || accountType === "BCA_PSYCHOLOGIST" || accountType === "BCA_CHIEF_PSYCHOLOGIST") ?
                                        <div style={{ marginRight: '1rem' }}>
                                            <FormControl variant="filled" sx={{ m: 1, minWidth: 200 }}>
                                                <InputLabel id="demo-simple-select-autowidth-label">Conference</InputLabel>
                                                <Select
                                                    style={{ width: "200px" }}
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    value={state.conference}
                                                    onChange={handleCoachChange}
                                                    // multiple
                                                    label="Conference"
                                                    name="conference"
                                                >
                                                    {
                                                        finalConference && finalConference.map(list =>
                                                            <MenuItem key={list.name} value={list.name}>{list.name}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    (accountType === "SUPER_ADMIN" || accountType === "BCA_DDI_ADMIN" || accountType === "BCA_PSYCHOLOGIST" || accountType === "BCA_CHIEF_PSYCHOLOGIST" || accountType === "COL_ATHLETIC_DIRECTOR" || accountType === "COL_PSYCHOLOGIST") ?
                                        <div style={{ margin: '0rem' }}>

                                            <FormControl variant="filled">
                                                <InputLabel id="demo-simple-select-autowidth-label">Sport</InputLabel>
                                                <Select
                                                    style={{ width: "200px" }}
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    // multiple
                                                    value={state.sport}
                                                    onChange={handleSportChange}
                                                    label="Sport"
                                                    name="sport"
                                                >
                                                    {
                                                        sportsData && sportsData.map(list =>
                                                            <MenuItem value={list.name}>{list.name}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                        :
                                        ""
                                }
                            </div>
                            <div style={{ display: 'flex' }}>
                                {
                                    (accountType === "SUPER_ADMIN" || accountType === "BCA_DDI_ADMIN" || accountType === "BCA_PSYCHOLOGIST" || accountType === "BCA_CHIEF_PSYCHOLOGIST") ?
                                        <div style={{}}>
                                            <FormControl variant="filled" sx={{ m: 1, minWidth: 80 }}>
                                                <InputLabel id="demo-simple-select-autowidth-label">Unit</InputLabel>
                                                <Select
                                                    style={{ width: "200px" }}
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    value={state.unit}
                                                    onChange={handleCoachChange}
                                                    autoWidth
                                                    label="Unit"
                                                    name="unit"
                                                >
                                                    {
                                                        unitData && unitData.map(list =>
                                                            <MenuItem value={list.unit}>{list.unit}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                        :
                                        ""
                                }


                            </div>

                            <div style={{ marginTop: '2rem' }}>
                                <Button className={classes.btnSecondary} onClick={handleCancel}>
                                    Clear
                                </Button>
                                <Button className={classes.btnPrimary} onClick={handleSave}>
                                    Run Report
                                </Button>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={5}>
                        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                            <img
                                src={filterDashedLogo}
                                alt="logo"
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}


let mapStateToProps = (state) => {
    return {
        sportsData: state.sports.sportsListData,
        unitData: state.sports.unitSport,
        conferenceByType: state.conference.allConferenceByType,
        conferenceData: state.conference.allConferenceList.allData,
        filterCandidates: state.coach.filterCoaches,
        loadingFilters: state.ui.coach.loadingCoachFilters,
        updateFilter: state.coach.updateCoachFilter,
        isFiltered: state.coach.isFilterCoaches,
        accountType: state.account.accountType,
    };
};
export default connect(mapStateToProps)(Filter);