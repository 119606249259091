import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Form, Formik } from "formik";
import React from "react";
import Button from "../Button";

let useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

const Stepper = (props) => {
  let { children, onSubmit, loading, className, step, setStep, ...rest } =
    props;
  let classes = useStyles();

  let childrenArray = React.Children.toArray(children);
  let currentChild = childrenArray[step];

  let isLastStep = () => {
    return step === childrenArray.length - 1;
  };

  let handleSubmit = (values, helpers) => {
    if (isLastStep()) {
      onSubmit(values, helpers);
    } else {
      helpers.setTouched({});
      helpers.setSubmitting(false);
      setStep((s) => s + 1);
    }
  };

  return (
    <Formik
      validationSchema={currentChild.props.validationSchema}
      onSubmit={handleSubmit}
      // onChange={currentChild.props.onChange}
      {...rest}
    >
      {(formik) => {
        return (
          <Form autoComplete="off" noValidate>
            {React.cloneElement(currentChild, { formik })}
            <div className={clsx(classes.content, className)}>
              {step > 0 ? (
                <Button
                style={{
                  border: ' 1px solid #727272',
                  width: '30%',
                  color: '#727272',
                  borderRadius: '2rem',
                  marginBottom: '2rem'
                }}
                  size="large"
                  type="button"
                  variant="text"
                  disabled={loading}
                  onClick={() => setStep((s) => s - 1)}
                >
                  Previous
                </Button>
              ) : (
                <div />
              )}

              <Button
                style={{
                  background: '#326fc7',
                  width: '30%',
                  color: '#fff',
                  borderRadius: '2rem',
                  marginBottom: '2rem'
                }}
                disableElevation
                size="large"
                variant="contained"
                type="submit"
                loading={loading}
                // disabled = {isLastStep() ? true : false}
                color="primary"
              >
                {isLastStep() ? "Submit" : "Next"}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
export default Stepper;
