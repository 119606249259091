import React, { useState } from "react";
import ExpansionArticle from "../ExpansionArticle";
import ChartAndDetails from "../ChartAndDetails";
import { chartColors } from "../../constants";
import { connect } from "react-redux";
import { Tabs, TabPanel } from "../../../shared/Tabs";

const PersonalityTraits = (props) => {
  let [selectedIndex, setSelectedIndex] = useState(0);
  let { traits } = props;

  let agreeableness = traits["agreeableness"];
  let agreeablenessSubTraits = agreeableness["sub"];

  let conscientiousness = traits["conscientiousness"];
  let conscientiousnessSubTraits = conscientiousness["sub"];

  let emotionalStability = traits["emotional_range"];
  let emotionalStabilitySubTraits = emotionalStability["sub"];

  let extraversion = traits["extroversion"];
  let extraversionSubTraits = extraversion["sub"];

  let openness = traits["openness"];
  let opennessSubTraits = openness["sub"];

  let handleChange = (e, index) => {
    setSelectedIndex(index);
  };

  let handleColors = (score) => {
    if (0 <= score && score < 25) {
      return chartColors.red;
    } else if (25 <= score && score < 50) {
      return chartColors.orange;
    } else if (50 <= score && score < 75) {
      return chartColors.yellow;
    } else if (75 <= score && score < 100) {
      return chartColors.green;
    }
  };

  return (
    <React.Fragment>
      <div>
        <Tabs
          labels={[
            "Agreeableness",
            "Conscientiousness",
            "Emotional Stability",
            "Extraversion",
            "Openness",
          ]}
          value={selectedIndex}
          onChange={handleChange}
        />
      </div>

      <TabPanel value={selectedIndex} index={0} style={{ background: '#fff', marginLeft: '1rem', borderRadius: '0.7rem', boxShadow: '3px 3px 8px 3px #cdcdcd', padding: '1rem' }}>
        <ExpansionArticle
          title="Overall Score"
          details="Candidate's tendency to be compassionate and cooperative toward others"
        >
          <ChartAndDetails
            diagnosis={agreeableness["text"]}
            details="hey"
            score={agreeableness["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Altruism"
          details="Candidate feel fulfilled when helping others and will go out of his/her way to do so"
        >
          <ChartAndDetails
            diagnosis={agreeablenessSubTraits["altruism"]["text"]}
            details="hey"
            score={agreeablenessSubTraits["altruism"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Cooperation"
          details="Candidate is easy to please and try to avoid confrontation."
        >
          <ChartAndDetails
            diagnosis={agreeablenessSubTraits["cooperation"]["text"]}
            details="hey"
            score={agreeablenessSubTraits["cooperation"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Modesty"
          details="Candidate is uncomfortable being the center of attention"
        >
          <ChartAndDetails
            diagnosis={agreeablenessSubTraits["modesty"]["text"]}
            details="hey"
            score={agreeablenessSubTraits["modesty"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Sympathy"
          details="Candidate feels what others feel and are compassionate toward them."
        >
          <ChartAndDetails
            diagnosis={agreeablenessSubTraits["sympathy"]["text"]}
            details="hey"
            score={agreeablenessSubTraits["sympathy"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Trust"
          details="Candidate believes the best in others and trust people easily"
        >
          <ChartAndDetails
            diagnosis={agreeablenessSubTraits["trust"]["text"]}
            details="hey"
            score={agreeablenessSubTraits["trust"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Uncompromising"
          details="Candidate thinks it is wrong to take advantage of others to get ahead.Candidate thinks it is wrong to take advantage of others to get ahead."
        >
          <ChartAndDetails
            diagnosis={agreeablenessSubTraits["uncompromising"]["text"]}
            details="hey"
            score={agreeablenessSubTraits["uncompromising"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>
      </TabPanel>
      <TabPanel value={selectedIndex} index={1} style={{ background: '#fff', marginLeft: '1rem', borderRadius: '0.7rem', boxShadow: '3px 3px 8px 3px #cdcdcd', padding: '1rem' }}>
        <ExpansionArticle
          title="Overall Score"
          details="Candidate's tendency to act in an organized or thoughtful way"
        >
          <ChartAndDetails
            diagnosis={conscientiousness["text"]}
            details="hey"
            score={conscientiousness["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Achievement Driven"
          details="Candidate sets high goals and work hard to achieve them"
        >
          <ChartAndDetails
            diagnosis={
              conscientiousnessSubTraits["achievement_striving"]["text"]
            }
            details="hey"
            score={conscientiousnessSubTraits["achievement_striving"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Cautiousness"
          details="Candidate carefully thinks through decisions before making them"
        >
          <ChartAndDetails
            diagnosis={conscientiousnessSubTraits["cautiousness"]["text"]}
            details="hey"
            score={conscientiousnessSubTraits["cautiousness"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Dutifulness"
          details="Candidate takes rules and obligations seriously, even when they are inconvenient."
        >
          <ChartAndDetails
            diagnosis={conscientiousnessSubTraits["dutifulness"]["text"]}
            details="hey"
            score={conscientiousnessSubTraits["dutifulness"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Orderliness"
          details="Candidate feels a strong need for structure in life."
        >
          <ChartAndDetails
            diagnosis={conscientiousnessSubTraits["orderliness"]["text"]}
            details="hey"
            score={conscientiousnessSubTraits["orderliness"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Self Discipline"
          details="Candidate can tackle and stick with tough tasks"
        >
          <ChartAndDetails
            diagnosis={conscientiousnessSubTraits["self_discipline"]["text"]}
            details="hey"
            score={conscientiousnessSubTraits["self_discipline"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Self Efficacy"
          details="Candidate feels he/she have the ability to succeed in the tasks he/she set out to do.Candidate feels he/she have the ability to succeed in the tasks he/she set out to do."
        >
          <ChartAndDetails
            diagnosis={conscientiousnessSubTraits["self_efficacy"]["text"]}
            details="hey"
            score={conscientiousnessSubTraits["self_efficacy"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>
      </TabPanel>
      <TabPanel value={selectedIndex} index={2} style={{ background: '#fff', marginLeft: '1rem', borderRadius: '0.7rem', boxShadow: '3px 3px 8px 3px #cdcdcd', padding: '1rem' }}>
        <ExpansionArticle
          title="Overall Score"
          details="Candidate's emotional responses to his/her environment"
        >
          <ChartAndDetails
            diagnosis={emotionalStability["text"]}
            details="hey"
            score={emotionalStability["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Composure"
          details="Candidate is able to keep calm, even when things do not go his/her way."
        >
          <ChartAndDetails
            diagnosis={emotionalStabilitySubTraits["composure"]["text"]}
            details="hey"
            score={emotionalStabilitySubTraits["composure"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Self Control"
          details="Candidate feels desires less intensly and has proper control over them."
        >
          <ChartAndDetails
            diagnosis={emotionalStabilitySubTraits["self_control"]["text"]}
            details="hey"
            score={emotionalStabilitySubTraits["self_control"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Contentment"
          details="Candidate thinks quite often about the things he/she is happy about."
        >
          <ChartAndDetails
            diagnosis={emotionalStabilitySubTraits["contentment"]["text"]}
            details="hey"
            score={emotionalStabilitySubTraits["contentment"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Self Confidence"
          details="Candidate is mostly insensitive about what others might be thinking of him/her."
        >
          <ChartAndDetails
            diagnosis={emotionalStabilitySubTraits["self_confidence"]["text"]}
            details="hey"
            score={emotionalStabilitySubTraits["self_confidence"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Calmness in Pressure"
          details="Candidate is centered in the face of stress and is not easily overwhelmed in stressful situations."
        >
          <ChartAndDetails
            diagnosis={emotionalStabilitySubTraits["calm_in_pressure"]["text"]}
            details="hey"
            score={emotionalStabilitySubTraits["calm_in_pressure"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Self Assuredness"
          details="Candidate is sure about his/her capabilites in properly handling most of the situations in life."
        >
          <ChartAndDetails
            diagnosis={emotionalStabilitySubTraits["self_assuredness"]["text"]}
            details="hey"
            score={emotionalStabilitySubTraits["self_assuredness"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>
      </TabPanel>
      <TabPanel value={selectedIndex} index={3} style={{ background: '#fff', marginLeft: '1rem', borderRadius: '0.7rem', boxShadow: '3px 3px 8px 3px #cdcdcd', padding: '1rem' }}>
        <ExpansionArticle
          title="Overall Score"
          details="Candidate's tendency to seek stimulation in the company of others"
        >
          <ChartAndDetails
            diagnosis={extraversion["text"]}
            details="hey"
            score={extraversion["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Active"
          details="Candidate enjoys a fast-paced, busy schedule with many activities"
        >
          <ChartAndDetails
            diagnosis={extraversionSubTraits["activity_level"]["text"]}
            details="hey"
            score={extraversionSubTraits["activity_level"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Assertiveness"
          details="Candidate  tends to speak up and take charge of situations, and is comfortable leading groups"
        >
          <ChartAndDetails
            diagnosis={extraversionSubTraits["assertiveness"]["text"]}
            details="hey"
            score={extraversionSubTraits["assertiveness"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Cheerfulness"
          details="Candidate is a joyful person and share that joy with the world"
        >
          <ChartAndDetails
            diagnosis={extraversionSubTraits["cheerfulness"]["text"]}
            details="hey"
            score={extraversionSubTraits["cheerfulness"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Excitement Seeking"
          details="Candidate is excited by taking risks and feel bored without lots of action going on"
        >
          <ChartAndDetails
            diagnosis={extraversionSubTraits["excitement_seeking"]["text"]}
            details="hey"
            score={extraversionSubTraits["excitement_seeking"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Gregariousness"
          details="Candidate enjoys being in the company of others"
        >
          <ChartAndDetails
            diagnosis={extraversionSubTraits["gregariousness"]["text"]}
            details="hey"
            score={extraversionSubTraits["gregariousness"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Outgoing"
          details="Candidate makes friends easily and feel comfortable around other people.Candidate makes friends easily and feel comfortable around other people"
        >
          <ChartAndDetails
            diagnosis={extraversionSubTraits["outgoing"]["text"]}
            details="hey"
            score={extraversionSubTraits["outgoing"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>
      </TabPanel>
      <TabPanel value={selectedIndex} index={4} style={{ background: '#fff', marginLeft: '1rem', borderRadius: '0.7rem', boxShadow: '3px 3px 8px 3px #cdcdcd', padding: '1rem' }}>
        <ExpansionArticle
          title="Overall Score"
          details="Candidate's tedency to be open to experiencing different activities"
        >
          <ChartAndDetails
            diagnosis={openness["text"]}
            details="hey"
            score={openness["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Adventurousness"
          details="Candidate is eager to experience new things"
        >
          <ChartAndDetails
            diagnosis={opennessSubTraits["adventurousness"]["text"]}
            details="hey"
            score={opennessSubTraits["adventurousness"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Artistic interests"
          details="Candidate enjoys beauty and seek out creative experiences"
        >
          <ChartAndDetails
            diagnosis={opennessSubTraits["artistic_interests"]["text"]}
            details="hey"
            score={opennessSubTraits["artistic_interests"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Authority-Challenging"
          details="Candidate prefers to challenge authority and traditional values to effect change."
        >
          <ChartAndDetails
            diagnosis={opennessSubTraits["authority_challenging"]["text"]}
            details="hey"
            score={opennessSubTraits["authority_challenging"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Emotionality"
          details="Candidate is aware of his/her feelings and how to express them."
        >
          <ChartAndDetails
            diagnosis={opennessSubTraits["emotionality"]["text"]}
            details="hey"
            score={opennessSubTraits["emotionality"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Imagination"
          details="Candidate has a wild imagination"
        >
          <ChartAndDetails
            diagnosis={opennessSubTraits["imagination"]["text"]}
            details="hey"
            score={opennessSubTraits["imagination"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>

        <ExpansionArticle
          title="Intellect"
          details="Candidate is open to and intrigued by new ideas and love to explore them Candidate is open to and intrigued by new ideas and love to explore them. Candidate is open to and intrigued by new ideas and love to explore them."
        >
          <ChartAndDetails
            diagnosis={opennessSubTraits["intellect"]["text"]}
            details="hey"
            score={opennessSubTraits["intellect"]["score"]}
            handleColors={handleColors}
          />
        </ExpansionArticle>
      </TabPanel>
    </React.Fragment>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { playerId } = ownProps;
  return {
    traits: state.player.traits[playerId],
  };
};

// higher order component for checking payment
export default connect(mapStateToProps)(PersonalityTraits);
