import { combineReducers } from "redux";
import { accountReducer, accountUiReducer } from "../modules/account";
import { playerReducer, playerUiReducer } from "../modules/players";
import { consentUiReducer, consentReducer } from "../modules/consent";
import { dashboardReducer, dashboardUIReducer } from "../modules/dashboard";
import { snackbarReducer } from "../modules/shared/Snackbar";
import { dialogReducer } from "../modules/shared/Dialog";
import { appUIReducer } from "../modules/app";
import { conferenceReducer, conferenceUIReducer } from "modules/conference";
import { sportsReducer, sportsUIReducer } from "modules/sports"
import { coachReducer, coachUIReducer } from "modules/coach"

let uiReducer = combineReducers({
  account: accountUiReducer,
  app: appUIReducer,
  player: playerUiReducer,
  consent: consentUiReducer,
  snackbar: snackbarReducer,
  dashboard: dashboardUIReducer,
  conference: conferenceUIReducer,
  sports: sportsUIReducer,
  coach: coachUIReducer
});

let appReducer = combineReducers({
  ui: uiReducer,
  dialogs: dialogReducer,
  account: accountReducer,
  player: playerReducer,
  consent: consentReducer,
  dashboard: dashboardReducer,
  conference: conferenceReducer,
  sports: sportsReducer,
  coach: coachReducer
});

let rootReducer = (state, action) => {
  if (action.type == "APP_SIGNOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
