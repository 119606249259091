import React, { useContext } from "react";
import Button from "../../../shared/Button";
import SaveIcon from "@material-ui/icons/Save";
import fileDownload from "js-file-download";
import { accountTypes } from "modules/players/constants";
import { ActionContext } from "../../../../contexts";

const FeedExport = (props) => {
  let {
    coachId,
    source,
    accountType,
    isDisabled,
    loading,
    playerName,
  } = props;
  let { coachActions } = useContext(ActionContext);

  let allowedTypes = [
    accountTypes.superAdmin,
    accountTypes.bcaDdiAdmin,
    accountTypes.bcaPsychologist,
    accountTypes.bcaChiefPsychologist
  ];

  let hasAccessToDownload = allowedTypes.includes(accountType);

  let handleFeedExport = () => {
    coachActions.exportFeed(coachId, playerName, source, (data, name) => {
      return () => {
        fileDownload(data, name);
      };
    });
  };

  return (
    <div>
      {hasAccessToDownload && (
        <div style={{ alignSelf: "flex-start", marginLeft: 8 }}>
          <Button
            color="primary"
            variant="text"
            disabled={isDisabled}
            disableElevation
            size="small"
            startIcon={<SaveIcon />}
            onClick={handleFeedExport}
            loading={loading}
            style={{ minWidth: 98 }}
          >
            Export Feed
          </Button>
        </div>
      )}
    </div>
  );
};

export default FeedExport;
