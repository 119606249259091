
import * as types from "./conferenceType";
import { conferenceApi } from "../../../api";

import {
    showSuccessMessage,
    showErrorMessage,
    showInfoMessage,
} from "../../shared/Snackbar";


const getConferenceList = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.REQUEST_GET_CONFERENCES,
                payload: []
            });

            let response = await conferenceApi.getConference();

            dispatch({
                type: types.SET_CONFERENCES_SUCCESS,
                payload: response
            });

        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.GET_CONFERENCES_FAILED,
                payload: "Failed to fetch conference",
            });
        }
    };
};

const searchConference = (key) => {
    return {
        type: types.SEARCH_CONFERENCE,
        payload: {
            key,
        },
    };
};

const resetSearch = () => {
    return {
        type: types.RESET_SEARCH,
    };
};

const selectConference = (conferenceId) => {
    return {
        type: types.SELECT_CONFERENCE,
        payload: {
            conferenceId,
        },
    };
};

const deselectConference = (conferenceId) => {
    return {
        type: types.DESELECT_CONFERENCE,
        payload: {},
    };
};

const getTeamsByConferenceId = (conferenceId) => {

    return async (dispatch) => {
        try {
            dispatch({
                type: types.GET_TEAMS_BY_CONFERENCE_ID,
                payload: [],
            });

            let response = await conferenceApi.getTeamDetails(conferenceId);

            dispatch({
                type: types.GET_TEAMS_BY_CONFERENCE_ID_SUCCESS,
                payload: response
            });
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.GET_TEAMS_BY_CONFERENCE_ID_FAILED,
                payload: {
                    error: "Fetch conference failed",
                },
            });
        }
    };
};

const resetTeamByConferenceId = () => {
    return {
        type: types.RESET_TEAMS_BY_CONFERENCE_ID,
        payload: []
    }
}

const getSportsByConferenceId = (conferenceId) => {

    return async (dispatch) => {
        try {
            dispatch({
                type: types.GET_SPORTS_BY_CONFERENCE_ID,
                payload: [],
            });

            let response = await conferenceApi.getSportDetails(conferenceId);

            dispatch({
                type: types.GET_SPORTS_BY_CONFERENCE_ID_SUCCESS,
                payload: response
            });
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.GET_SPORTS_BY_CONFERENCE_ID_FAILED,
                payload: {
                    error: "Fetch sports failed",
                },
            });
        }
    };
};

const resetSportsByConferenceId = () => {
    return {
        type: types.RESET_SPORTS_BY_CONFERENCE_ID,
        payload: []
    }
}

const createConference = (data, successCb = (f) => f, failCb = (f) => f) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.CREATE_NEW_CONFERENCE_REQUEST,
            });

            const response = await conferenceApi.addNewConference(data);

            if (response.status === "Error") {
                dispatch({
                    type: types.CREATE_NEW_CONFERENCE_FAILED,
                    payload: {
                        error: response.notification,
                    },
                });
                failCb();
            } else {
                dispatch({
                    type: types.CREATE_NEW_CONFERENCE_SUCCESS,
                });

                successCb();
            }
        } catch (err) {
            dispatch({
                type: types.CREATE_NEW_CONFERENCE_FAILED,
            });
        }
    };
};

const resetConferenceCreationError = () => {
    return {
        type: types.RESET_CREATE_NEW_CONFERENCE_ERROR,
    };
};

const conferenceDelete = (conferenceId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.DELETE_CONFERENCE_REQUEST,
            });

            const response = await conferenceApi.deleteConference(conferenceId);

            dispatch({
                type: types.DELETE_CONFERENCE_SUCCESS,
                payload: response
            });
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.DELETE_CONFERENCE_FAILED,
            });
        }
    };
};

const getCountries = () => {
    return async (dispatch) => {
        try {

            dispatch({
                type: types.GET_COUNTRIES_REQUEST
            })

            let response = await conferenceApi.getAllCountries()

            dispatch({
                type: types.GET_COUNTRIES_SUCCESS,
                payload: {
                    country: response.candidateCountry
                }
            });

        } catch (err) {
            dispatch(showErrorMessage(err.message))
            dispatch({
                type: types.GET_COUNTRIES_FAILED
            })
        }

    }
}

const getStatesByCountryName = (model) => {
    return async (dispatch) => {
        try {

            dispatch({
                type: types.GET_COUNTRIES_STATES_REQUEST
            })

            let response = await conferenceApi.getStates(model)

            dispatch({
                type: types.GET_COUNTRIES_STATES_SUCCESS,
                // payload : response
                payload: {
                    state: response.candidateState,
                },
            })

        } catch (err) {
            dispatch(showErrorMessage(err.message))
            dispatch({
                type: types.GET_COUNTRIES_STATES_FAILED
            })
        }
    }
}

const getConferenceByType = (conferenceType) => {
    return async (dispatch) => {
        try {

            dispatch({
                type: types.GET_CONFERENCE_BY_TYPE_REQUEST
            })

            let response = await conferenceApi.getConferenceByType(conferenceType)

            dispatch({
                type: types.GET_CONFERENCE_BY_TYPE_SUCCESS,
                payload: response
            })

        } catch (err) {
            dispatch(showErrorMessage(err.message))
            dispatch({
                type: types.GET_CONFERENCE_BY_TYPE_FAILED
            })
        }
    }
}

const selectConferencePage = (page) => {
    return {
        type: types.SELECTED_PAGE_TYPE,
        payload: page
    };
};

const selectConferenceType = (type) => {
    return {
        type: types.SELECTED_CONFERENCE_TYPE,
        payload: type
    };
};


const postCreateTeam = (model) => {
    return async (dispatch) => {
        try {

            dispatch({
                type: types.CREATE_NEW_TEAM
            })

            let response = await conferenceApi.createNewTeam(model)

            dispatch({
                type: types.CREATE_NEW_TEAM_SUCCESS,
                payload: response
            })

        }
        catch (err) {
            dispatch(showErrorMessage(err.message))
            dispatch({
                type: types.CREATE_NEW_TEAM_FAILED
            })
        }
    }
}

const resetCreateTeam = () => {
    return {
        type: types.RESET_CREATE_NEW_TEAM,
    };
}

const conferenceActions = {
    getTeamsByConferenceId,
    getConferenceList,
    searchConference,
    resetSearch,
    selectConference,
    deselectConference,
    createConference,
    resetConferenceCreationError,
    conferenceDelete,
    getCountries,
    getStatesByCountryName,
    getConferenceByType,
    selectConferencePage,
    selectConferenceType,
    getSportsByConferenceId,
    resetSportsByConferenceId,
    postCreateTeam,
    resetCreateTeam,
    resetTeamByConferenceId
};

export default conferenceActions;