import React from "react";
import { Article } from "../../../shared/Article";
import BasicInfo from "./BasicInfo";
import ContactInfo from "./ContactInfo";
import { Box, Tabs, Tab, Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from "@material-ui/core/styles";

let useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Prometo',
    fontSize: '14px',
    color: '#fcb643',
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const PersonalInfo = ({ coachId }) => {
  let classes = useStyles();

  const [selectedPage, setSelectedPage] = React.useState(0)

  const handleChange = (event, newValue) => {
    setSelectedPage(newValue);
  };

  return (
    <React.Fragment>
      <Box
        sx={{ flexGrow: 1, bgcolor: 'background.paper', padding: '1rem' }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Tabs
            orientation="horizontal"
            variant="scrollable"
            value={selectedPage}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab className={selectedPage === 0 ? classes.root : ''} label="Basic Info" {...a11yProps(0)} />
            <Tab className={selectedPage === 1 ? classes.root : ''} label="Contact Info" {...a11yProps(1)} />
          </Tabs>
        </div>
        <TabPanel value={selectedPage} index={0}>
          <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}></Grid>
              <Grid item xs={12} sm={6}>
                <Article title="Basic Info">
                  <BasicInfo coachId={coachId} />
                </Article>
              </Grid>
              <Grid item xs={12} sm={3}></Grid>
            </Grid>
          </div>
        </TabPanel>
        <TabPanel value={selectedPage} index={1}>
          <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}></Grid>
              <Grid item xs={12} sm={6}>
                <Article title="Contact Info">
                  <ContactInfo coachId={coachId} />
                </Article>
              </Grid>
              <Grid item xs={12} sm={3}></Grid>
            </Grid>
          </div>
        </TabPanel>
      </Box>

    </React.Fragment>
  );
};

export default PersonalInfo;
