import React from "react";
import MuiSnackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CloseAction from "./CloseAction";

let Snackbar = ({
  isOpen,
  onClose,
  action,
  severity = "success",
  anchorOrigin = {
    vertical: "top",
    horizontal: "right",
  },
  autoHideDuration = 2500,
  message,
  ...rest
}) => {
  if (message && message.length < 1) {
    return null;
  }
  return (
    <MuiSnackbar
      onClose={onClose}
      open={isOpen}
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      action={[<CloseAction key="1" onClick={onClose} />]}
      {...rest}
    >
      <MuiAlert elevation={6} variant="filled" severity={severity}>
        {message}
      </MuiAlert>
    </MuiSnackbar>
  );
};

export default Snackbar;
