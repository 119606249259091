import React, { useContext, useEffect , useState } from "react";
import { connect, useDispatch } from "react-redux";
import { ActionContext } from "../../../contexts";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import appConfig from "../../../config";
import { Button, Grid } from "@material-ui/core";
import CircularProgress from "modules/shared/CircularProgress";
import Slide from "@material-ui/core/Slide";
import { useConfirm } from "material-ui-confirm";
import { showSuccessMessage } from "modules/shared/Snackbar";
import Filter from "./Filter";
import List from "./List"

const Transition = React.forwardRef(function Transition(props, ref) {
    return (
        <Slide
            direction="left"
            ref={ref}
            timeout={{ enter: 1000, exit: 1000 }}
            {...props}
        />
    );
});

let useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        display: "flex",
        height: `calc(100vh - 50px)`,
        zIndex: theme.zIndex.appBar,
    },
    container: {
        width:'100%',
        // padding: 16,
        // paddingTop: 48,
        // marginLeft: "auto",
        // marginRight: "auto",
        // flexWrap: "wrap",
        [theme.breakpoints.down("md")]: {
            maxWidth: 550,
        },
    },
    toolbar: {
        display: "flex",
        backgroundColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

function Main(props) {
    let classes = useStyles();
    let confirm = useConfirm();
    let dispatch = useDispatch();
    let { isDrawerOpen , updateFilter } = props;

    let drawerWidth = isDrawerOpen
        ? appConfig.drawer.maxWidth
        : appConfig.drawer.minWidth;

    let actions = useContext(ActionContext);
    let { playerActions, sportsActions } = actions;

    const [selectedPage, setSelectedPage] = useState('list')
    const [filterValue, setFilterValue] = useState(false)

    useEffect(() => {
        // playerActions.getAllCandidates();
        let model = {
            conference: updateFilter.conference ? [updateFilter.conference] : [],
            sport: updateFilter.sport ? [updateFilter.sport] : [],
            unit: updateFilter.unit ? [updateFilter.unit] : [],
            position: updateFilter.position ? [updateFilter.position] : [],
            athleteType: updateFilter.athleteType === "All" ? [] : [updateFilter.athleteType],
            conferenceType: updateFilter.conferenceType === "All" ? [] : [updateFilter.conferenceType],
            team : updateFilter.team ? [updateFilter.team] : []
            // attribute: updateFilter.attribute ? [updateFilter.attribute] : [],
            // behavior: updateFilter.behavior ? [updateFilter.behavior] : [],
            // attributeSort: updateFilter.attributeSort ? [updateFilter.attributeSort] : [],
            // behaviorSort: updateFilter.behaviorSort ? [updateFilter.behaviorSort] : [],
        }

        playerActions.getAllFiltersCandidates(model)
    }, []);

    useEffect(() => {
        return () => {
            let model = {
                conference: [],
                sport: [],
                unit: [],
                position: [],
                athleteType: [],
                conferenceType: []
            }
            // playerActions.getAllFiltersCandidates(model)
            playerActions.isFilteredData(false)
            setFilterValue(false)
            playerActions.resetFilterValue()
            sportsActions.resetUnitsApi()
            sportsActions.resetSportsApi()
        }
    },[])

    const handlePageChange = (value) => {
        setSelectedPage(value)
    }

    const handleFilterApply = (value) => {
        setFilterValue(value)
    }

    return (
        <React.Fragment>
            <div className={classes.root} style={{ paddingLeft: drawerWidth }}>
                <div className={classes.container}>
                    {
                        selectedPage === 'list' ?
                        <List pageChange = {handlePageChange} filterValue = {filterValue} isFilteredApply = {handleFilterApply}/>
                        :
                        <Filter pageChange = {handlePageChange} isFilteredApply = {handleFilterApply}/>
                    }
                </div>

            </div>
        </React.Fragment>
    )
}

let mapStateToProps = (state) => {

    return {
        updateFilter : state.player.updateFilter
    };
};
export default connect(mapStateToProps)(Main);