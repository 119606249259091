

import * as types from "./sportsType";
import { sportsApi } from "../../../api";

import {
    showSuccessMessage,
    showErrorMessage,
    showInfoMessage,
} from "../../shared/Snackbar";


const getSportsList = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.GET_ALL_SPORTS_REQUEST,
                payload: []
            });

            let response = await sportsApi.getAllSports();

            dispatch({
                type: types.GET_ALL_SPORTS_SUCCESS,
                payload: response
            });

        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.GET_ALL_SPORTS_FAILED,
                payload: "Failed to fetch sports",
            });
        }
    };
};

const searchSport = (key) => {
    return {
        type: types.SEARCH_SPORT,
        payload: {
            key,
        },
    };
};

const resetSearch = () => {
    return {
        type: types.RESET_SEARCH,
    };
};

const selectSport = (sportId) => {
    return {
        type: types.SELECT_SPORT,
        payload: {
            sportId,
        },
    };
};

const deselectSport = () => {
    return {
        type: types.DESELECT_SPORT,
        payload: {},
    };
};

const getSportUnitDetails = (sportId) => {

    return async (dispatch) => {
        try {
            dispatch({
                type: types.GET_SPORT_UNIT_BY_SPORT_ID_REQUEST,
                payload: [],
            });

            let response = await sportsApi.getUnitDetails(sportId);

            dispatch({
                type: types.GET_SPORT_UNIT_BY_SPORT_ID_SUCCESS,
                payload: response
            });
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.GET_SPORT_UNIT_BY_SPORT_ID_FAILED,
                payload: {
                    error: "Fetch sport unit failed",
                },
            });
        }
    };
};

const resetUnitsApi = () => {
    return {
        type: types.RESET_SPORT_UNIT_BY_SPORT_ID,
        payload: []
    }
}

const getSportPositionDetails = (unitId, sportId) => {

    return async (dispatch) => {
        try {
            dispatch({
                type: types.GET_SPORT_POSITION_BY_UNIT_ID_REQUEST,
                payload: [],
            });

            let response = await sportsApi.getPositionDetails(unitId, sportId);

            dispatch({
                type: types.GET_SPORT_POSITION_BY_UNIT_ID_SUCCESS,
                payload: response
            });
        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.GET_SPORT_POSITION_BY_UNIT_ID_FAILED,
                payload: {
                    error: "Fetch sport POSITION failed",
                },
            });
        }
    };
};

const resetSportsApi = () => {
    return {
        type: types.RESET_SPORT_POSITION_BY_UNIT_ID,
        payload: []
    }
}

const selectSportsPage = (page) => {
    return {
        type: types.SELECTED_PAGE_TYPE,
        payload: page
    };
};

const selectSportsType = (type) => {
    return {
        type: types.SELECTED_SPORTS_TYPE,
        payload: type
    };
};

const getTeamUsersList = (teamId) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: types.GET_TEAM_USERS_REQUEST,
                payload: []
            });

            let response = await sportsApi.getTeamUsers(teamId);

            dispatch({
                type: types.GET_TEAM_USERS_SUCCESS,
                payload: response
            });

        } catch (err) {
            dispatch(showErrorMessage(err.message));
            dispatch({
                type: types.GET_TEAM_USERS_FAILED,
                payload: "Failed to fetch Team Users",
            });
        }
    };
};

const sportsActions = {
    getSportsList,
    searchSport,
    resetSearch,
    selectSport,
    deselectSport,
    getSportUnitDetails,
    getSportPositionDetails,
    selectSportsPage,
    selectSportsType,
    getTeamUsersList,
    resetUnitsApi,
    resetSportsApi
};

export default sportsActions;