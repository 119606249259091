import * as types from "./accountTypes";
import { combineReducers } from "redux";

let accessToken = localStorage.getItem("@accessToken");
let accountType = localStorage.getItem("@accountType");

// had to use these small reducers when converting to combine reducer
let isAuthenticatedReducer = (state = Boolean(accessToken), action) => {
  switch (action.type) {
    case types.SIGNIN_SUCCESS: {
      return true;
    }

    default:
      return state;
  }
};

let fullNameReducer = (state = "", action) => {
  let { payload } = action;
  switch (action.type) {
    case types.SIGNIN_SUCCESS: {
      const { user } = payload;
      return user.full_name;
    }

    case types.GET_PROFILE_SUCCESS: {
      return payload?.fullName;
    }

    default:
      return state;
  }
};

let teamReducer = (state = "", action) => {
  let { payload } = action;
  switch (action.type) {
      case types.SIGNIN_SUCCESS: {
    	const { user } = payload;
    	return user.team;
      }

    case types.GET_PROFILE_SUCCESS: {
      return payload?.team;
    }

    default:
      return state;
  }
};

let emailReducer = (state = "", action) => {
  let { payload } = action;
  switch (action.type) {
    case types.SIGNIN_SUCCESS: {
      const { user } = payload;
      return user.email;
    }

    case types.GET_PROFILE_SUCCESS: {
      return payload?.email;
    }

    default:
      return state;
  }
};

let sportReducer = (state = "", action) => {
  let { payload } = action;
  switch (action.type) {
    case types.SIGNIN_SUCCESS: {
      const { user } = payload;
      return user.sport;
    }

    case types.GET_PROFILE_SUCCESS: {
      return payload?.sport ? payload?.sport : "";
    }

    default:
      return state;
  }
};

let accountTypeReducer = (state = accountType || null, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.SIGNIN_SUCCESS: {
      const { user } = payload;
      return user.account_type;
    }

    case types.GET_PROFILE_SUCCESS: {
      return payload?.account_type 
    }


    default:
      return state;
  }
};

let notificationsById = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_NOTIFICATIONS_SUCCESS: {
      return {
        ...payload.notifications,
      };
    }

    case types.MARK_NOTIFICATIONS_AS_READ_SUCCESS: {
      const newState = {};

      payload.unreadIds.forEach((id) => {
        let notification = state[id];
        Object.assign(notification, { ...notification, status: "Read" });
        newState[id] = notification;
      });

      return {
        ...state,
        ...newState,
      };
    }

    default:
      return state;
  }
};

let allNotifications = (state = [], action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_NOTIFICATIONS_SUCCESS: {
      return [...Object.keys(payload.notifications)];
    }

    default:
      return state;
  }
};

let unreadIds = (state = [], action) => {
  let { payload } = action;
  switch (action.type) {
    case types.GET_NOTIFICATIONS_SUCCESS: {
      return [...payload.unread];
    }

    case types.MARK_NOTIFICATIONS_AS_READ_SUCCESS: {
      return [];
    }

    default:
      return state;
  }
};

let notificationsReducer = combineReducers({
  byId: notificationsById,
  allIds: allNotifications,
  unreadIds,
});

let accountReducer = combineReducers({
  isAuthenticated: isAuthenticatedReducer,
  fullName: fullNameReducer,
  team: teamReducer,
  email: emailReducer,
  accountType: accountTypeReducer,
  notifications: notificationsReducer,
  sport: sportReducer
});
export default accountReducer;
