import { accountApi, userApi } from "../../../api";
import { showErrorMessage } from "../../shared/Snackbar";
import * as types from "./accountTypes";

const getNotifications = (handleSuccess = (f) => f, handleFail = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_GET_NOTIFICATIONS,
      });

      const { notifications, unread } = await accountApi.getNotifications();

      dispatch({
        type: types.GET_NOTIFICATIONS_SUCCESS,
        payload: {
          notifications,
          unread,
        },
      });

      handleSuccess();
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.GET_NOTIFICATIONS_FAILED,
      });

      handleFail();
    }
  };
};

const markNotificationsAsRead = (handleSuccess = (f) => f) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: types.REQUEST_MARK_NOTIFICATIONS_AS_READ,
      });

      await accountApi.markNotificationsAsRead();

      let state = getState();

      dispatch({
        type: types.MARK_NOTIFICATIONS_AS_READ_SUCCESS,
        payload: {
          unreadIds: state.account.notifications.unreadIds,
        },
      });
      handleSuccess();
      //dispatch(showSuccessMessage("Notifications Marked as Read"));
    } catch (err) {
      dispatch(showErrorMessage(err.message));
      dispatch({
        type: types.MARK_NOTIFICATIONS_AS_READ_FAILED,
      });
    }
  };
};

const signIn = (data, successCb = (f) => f, failCb = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_SIGNIN,
      });

      const response = await accountApi.login(data);

      dispatch({
        type: types.SIGNIN_SUCCESS,
        payload: response,
      });

      const { accessToken, refreshToken, user } = response;
      successCb(accessToken, refreshToken, user.account_type);
    } catch (err) {
      dispatch({
        type: types.SIGNIN_FAILED,
        payload: {
          error: err.message,
        },
      });
      failCb();
    }
  };
};

const signUp = (data, successCb = (f) => f, failCb = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_SIGNUP,
      });

      const response = await userApi.createUser(data);

      if (response.status === "Error") {
        dispatch({
          type: types.SIGNUP_FAILED,
          payload: {
            error: response.notification,
          },
        });
        failCb();
      } else {
        dispatch({
          type: types.SIGNUP_SUCCESS,
        });

        dispatch({
          type: types.SET_VERIFY_EMAIL,
          payload: {
            email: data.email,
          },
        });
        successCb();
      }
    } catch (err) {
      dispatch({
        type: types.SIGNUP_FAILED,
      });
    }
  };
};

const forgotPassword = (data, successCb = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_RESET_PASSWORD,
      });

      const response = await userApi.resetPassword(data);

      // dispatch({
      //   type: types.RESET_PASSWORD_SUCCESS,
      //   payload: response,
      // });

      if (response.status === "Error") {
        dispatch({
          type: types.RESET_PASSWORD_FAILED,
          payload: {
            error: response.notification,
          },
        });
      } else {
        dispatch({
          type: types.RESET_PASSWORD_SUCCESS,
          payload: response,
        });
        successCb();
      }
    } catch (err) {
      dispatch({
        type: types.RESET_PASSWORD_FAILED,
        payload: {
          error: err.message,
        },
      });
    }
  };
};

const verify = (data, successCb = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_VERIFY_ACCOUNT,
      });

      const response = await userApi.confirmEmail(data);

      if (response.status === "Error") {
        dispatch({
          type: types.VERIFY_ACCOUNT_FAILED,
          payload: {
            error: response.notification,
          },
        });
      } else {
        dispatch({
          type: types.VERIFY_ACCOUNT_SUCCESS,
          payload: {},
        });
        successCb();
        localStorage.setItem("@acccessToken", response.info.access_token);
      }
    } catch (err) {
      dispatch({
        type: types.VERIFY_ACCOUNT_FAILED,
      });
    }
  };
};

const resendVerification = (data, successCb = (f) => f) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.SET_VERIFY_EMAIL,
        payload: {
          email: data.email,
        },
      });

      const response = await userApi.resendVerification(data);

      // dispatch({
      //   type: types.RESET_PASSWORD_SUCCESS,
      //   payload: response,
      // });

      if (response.status === "Error") {
        dispatch({
          type: types.RESEND_VERIFICATION_FAILED,
          payload: {
            error: response.notification,
          },
        });
      } else {
        dispatch({
          type: types.RESEND_VERIFICATION_SUCCESS,
          payload: response,
        });
        // successCb();  //TODO Aruna. Show Green Message Saying Something Like "Verification Code Sent to Email"
      }
    } catch (err) {
      dispatch({
        type: types.RESEND_VERIFICATION_FAILED,
        payload: {
          error: err.message,
        },
      });
    }
  };
};

const changePassword = (data, successCb) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_CHANGE_PASSWORD,
      });

      const response = await userApi.changePassword(data);
      // const response = {};
      if (response.status === "Error") {
        dispatch({
          type: types.CHANGE_PASSWORD_FAILED,
          payload: {
            error: response.notification,
          },
        });
      } else {
        dispatch({
          type: types.CHANGE_PASSWORD_SUCCESS,
        });

        successCb();
      }
    } catch (err) {
      dispatch({
        type: types.CHANGE_PASSWORD_FAILED,
        payload: {
          error: "Request Failed",
        },
      });
    }
  };
};

const getUserProfile = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_GET_PROFILE,
      });
      const response = await userApi.getProfile();
      dispatch({
        type: types.GET_PROFILE_SUCCESS,
        payload: response,
      });
    } catch (err) {
      dispatch({
        type: types.GET_PROFILE_FAILED,
        payload: err,
      });
    }
  };
};

const appSignOut = () => {
  return {
    type: types.APP_SIGNOUT,
  };
};

const getUserTypes = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: types.REQUEST_GET_USER_TYPES,
      });

      const response = await userApi.getUserTypes();

      dispatch({
        type: types.GET_USER_TYPES_SUCCESS,
        payload: {
          userTypes: response,
        },
      });
    } catch (err) {
      dispatch({
        type: types.GET_USER_TYPES_FAILED,
      });
    }
  };
};

const resetAccountCreationError = () => {
  return {
    type: types.RESET_ACCOUNT_CREATION_ERROR,
  };
};

const setAppAlert = (message) => {
  return {
    type: types.SET_APP_ALERT,
    payload: {
      message,
    },
  };
};

const resetAppAlert = () => {
  return {
    type: types.RESET_APP_ALERT,
  };
};

const resetLoginError = () => {
  return {
    type: types.RESET_LOGIN_ERROR,
  };
};

const accountActions = {
  signIn,
  signUp,
  forgotPassword,
  verify,
  resendVerification,
  changePassword,
  getUserProfile,
  appSignOut,
  getUserTypes,
  resetAccountCreationError,
  setAppAlert,
  resetAppAlert,
  resetLoginError,
  getNotifications,
  markNotificationsAsRead,
};

export default accountActions;
