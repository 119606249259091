import React, { useContext } from "react";
import MenuItem from "@material-ui/core/MenuItem";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { ActionContext } from "../../../../contexts";
import { object, string } from "yup";
import { Button } from "../../../shared";
import { Field, Formik, Form } from "formik";
import { TextField, Select } from "formik-material-ui";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  submit: {
    display: "flex",
    flexDirection: "row-reverse",
    paddingTop: theme.spacing(3),
  },
}));

const LinksDialog = (props) => {
  const classes = useStyles();
  const { coachActions } = useContext(ActionContext);
  const { closeDialog, coachId, loading } = props;

  const handleCloseDialog = () => {
    closeDialog();
  };

  const handleFormSubmit = (values, actions) => {
    const data = {
      coach_id: coachId,
      url: values.link,
      category: values.category,
      source_ln: values.sourceLanguage,
    };

    const handleSuccess = () => {
      actions.setSubmitting(false);
      handleCloseDialog();
    };

    const handleFail = () => {
      actions.setSubmitting(false);
    };

    coachActions.addCoachLink(data, handleSuccess, handleFail);
  };

  return (
    <div>
      <div style={{ display: "flex", width: "100%" }}>
        <DialogTitle id="simple-dialog-title" style={{ flex: 1 }}>
          Add Link
        </DialogTitle>
        <div style={{ margin: 8 }}>
          <IconButton onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div style={{ padding: 24, width: "100%" }}>
        <Formik
          initialValues={{ category: "", link: "", sourceLanguage: "en" }}
          onSubmit={handleFormSubmit}
          validationSchema={object().shape({
            link: string().url().required("Enter the Link"),
            category: string().required("Select Link Type"),
            sourceLanguage: string().required("Selecr Source Language"),
          })}
        >
          {(formik) => {
            let { errors, touched } = formik;
            return (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={touched["category"] && !!errors["category"]}
                    >
                      <InputLabel htmlFor="accountTypeLabel">
                        Category
                      </InputLabel>
                      <Field
                        component={Select}
                        name="category"
                        label="Category"
                        placeholder="Category"
                        labelId="categoryLabel"
                        inputProps={{
                          id: "category",
                        }}
                      >
                        <MenuItem value={""}>None</MenuItem>
                        <MenuItem value={"youtube"}>YouTube</MenuItem>
                        <MenuItem value={"video"}>Video</MenuItem>
                        <MenuItem value={"newspaper"}>Newspaper</MenuItem>
                      </Field>
                      {touched["category"] && !!errors["category"] ? (
                        <FormHelperText>{errors["category"]}</FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="link"
                      label="Link"
                      placeholder="Link"
                      component={TextField}
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={
                        touched["sourceLanguage"] && !!errors["sourceLanguage"]
                      }
                    >
                      <InputLabel htmlFor="sourceLanguage">
                        Source Language
                      </InputLabel>
                      <Field
                        component={Select}
                        name="sourceLanguage"
                        label="Source Language"
                        placeholder="Source Language"
                        labelId="sourceLanguage"
                        inputProps={{
                          id: "sourceLanguage",
                        }}
                      >
                        <MenuItem value="en">English</MenuItem>
                        <MenuItem value="es">Spanish</MenuItem>
                        <MenuItem value="fr">French</MenuItem>
                      </Field>
                      {touched["sourceLanguage"] &&
                      !!errors["sourceLanguage"] ? (
                        <FormHelperText>
                          {errors["sourceLanguage"]}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                </Grid>
                <div className={classes.submit}>
                  <Button type="submit" color="primary" loading={loading}>
                    Save
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { coachId } = ownProps;

  return {
    loading: state.ui.coach.addingCoachLink[coachId] || false,
  };
};

export default connect(mapStateToProps)(LinksDialog);
