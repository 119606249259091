import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { object, string } from "yup";
import { ActionContext } from "../../../contexts";
import { Step, Stepper } from "../../shared/Stepper";
import BackgorundStep from "./BackGroundStepper";
import BasicInfoStep from "./BasicInfoStep";
import CreateCandidateDialogLayout from "./CreateCandidateDialogLayout";
import NotesStep from "./NotesStep";
import SocialMediaStep from "./SocialMediaStep";
import runDashedLogo from "../../../assets/RunDashedIcon.svg";
import { Box, Grid } from '@material-ui/core';
import MuiStepper from '@material-ui/core/Stepper';
import MuiStep from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

let useStyles = makeStyles((theme) => ({
  stepper: {
    paddingTop: theme.spacing(5),
    // maxWidth: 600,
  },
}));

const CreatePlayerStepper = (props) => {
  let { closeDialog, submittingPlayer, teams, updateFilter } = props;
  let classes = useStyles();
  let history = useHistory();
  let [step, setStep] = useState(0);

  let defaultTeam =
    Object.keys(teams).length > 1 ? null : Object.keys(teams)[0];
  let { snackbarActions, playerActions, conferenceActions, sportsActions } = useContext(ActionContext);

  let getValue = (value) => (value && value.length > 0 ? value : "N/A");

  let handleFormSubmit = (values) => {
    let data = {
      // full_name: values.name,
      firstName: values.firstName,
      lastName: values.lastName,
      full_name: values.firstName + ' ' + values.lastName,
      team: values.team,
      email: values.email,
      hometown: getValue(values.hometown),
      school: getValue(values.school),
      dob: values.birthday.format("L"),
      race: getValue(values.race),
      relationship_status: getValue(values.relationship),
      whatsapp: getValue(values.whatsapp),
      twitter: getValue(values.twitter),
      twitter_source_ln: values.twitterSourceLn,
      instagram: getValue(values.instagram),
      instagram_source_ln: values.instagramSourceLn,
      linkedin: getValue(values.linkedin),
      linkedin_source_ln: values.linkedinSourceLn,
      youtube: getValue(values.youtube),
      youtube_source_ln: values.youtubeSourceLn,
      printNews: getValue(values.printNews),
      printNews_source_ln: values.printNewsSourceLn,
      notes: values.notes,

      sport: values.sport,
      conference_type: values.conference_type,
      unit: values.unit,

      homeCountry: values.homeCountry,
      homeState: values.homeState,
      gender: values.gender,
      conference: values.conference,
      athleteType: values.athleteType,
      positions: values.positions
    };

    let success = () => {
      onSuccessApi()
      playerActions.individualAthleteFormSubmited()
      snackbarActions.showSnackbar("Player added successfully");
    };
    playerActions.createPlayer(data, success);
  };

  const onSuccessApi = () => {
    let model = {
      conference: updateFilter.conference ? [updateFilter.conference] : [],
      sport: updateFilter.sport ? [updateFilter.sport] : [],
      unit: updateFilter.unit ? [updateFilter.unit] : [],
      position: updateFilter.position ? [updateFilter.position] : [],
      athleteType: updateFilter.athleteType === "All" ? [] : [updateFilter.athleteType],
      conferenceType: updateFilter.conferenceType === "All" ? [] : [updateFilter.conferenceType],
      team: updateFilter.team ? [updateFilter.team] : []
    }

    playerActions.getAllFiltersCandidates(model)
  }

  useEffect(() => {
    playerActions.getTeamList();
    conferenceActions.getCountries();
    sportsActions.getSportsList();

  }, []);

  const steps = [1, 2, 3, 4];

  const validateMonth = (value) => {
    const currentDate = new Date();
    const selectedDate = new Date(value);
    const currentMonth = currentDate.getMonth() + 1;
    const selectedMonth = selectedDate.getMonth() + 1;
    return selectedMonth >= 1 && selectedMonth <= 12 ;
  };


  return (
    // <CreateCandidateDialogLayout
    //   onClose={closeDialog}
    //   disableClose={submittingPlayer}
    //   step={step}
    // >
    <React.Fragment>
      <Box sx={{ maxWidth: 750 }}>
        <MuiStepper activeStep={step} orientation="horizontal" style={{ background: 'none' }}>
          {steps.map((step, index) => (
            <MuiStep key={step.label}>
              <StepLabel>
              </StepLabel>
            </MuiStep>
          ))}
        </MuiStepper>
      </Box>

      <Grid style={{ display: 'flex', margin: '0rem 2rem' }}>
        <Grid item xs={7}>
          <Stepper
            className={classes.stepper}
            step={step}
            setStep={setStep}
            initialValues={{
              notes: "",
              firstName: "",
              lastName: "",
              team: defaultTeam,
              email: "",
              birthday: null,
              hometown: "",
              school: "",
              race: "",
              relationship: "",
              whatsApp: "",
              twitter: "",
              twitterSourceLn: "en",
              instagram: "",
              instagramSourceLn: "en",
              linkedin: "",
              linkedinSourceLn: "en",
              youtube: "",
              youtubeSourceLn: "en",
              printNews: "",
              printNewsSourceLn: "en",
              sport: "",
              conference_type: "",
              unit: "",
              homeCountry: "",
              homeState: "",
              gender: "",
              conference: "",
              athleteType: "",
              positions: ""
            }}
            onSubmit={handleFormSubmit}
            loading={submittingPlayer}
          // onChange={handleChange}
          >
            <Step
              validationSchema={object().shape({
                firstName: string()
                  .trim()
                  .matches(
                    /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
                    "Enter valid First name"
                  )
                  .required("Enter athlete first name"),
                lastName: string()
                  .trim()
                  .matches(
                    /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
                    "Enter valid Last name"
                  )
                  .required("Enter athlete last name"),
                team: string().nullable().required("Select team"),
                email: string()
                  .email("Enter a valid email"),
                // .required("Enter email"),
                birthday: string().nullable().required("Select birthday").test("valid-month", "Invalid month", (value) => validateMonth(new Date(value))),
              })}
              render={(props) => <BasicInfoStep {...props} />}
            />

            <Step
              validationSchema={object().shape({
                homeCountry: string().nullable().required("Select Home Country"),
                // homeState: string().nullable().required("Select Home state"),
                race: string().nullable().required("Select Race/Ethnicity"),
                gender: string().nullable().required("Select Gender"),
                conference: string().nullable().required("Select Conference"),
                sport: string().nullable().required("Select Sport"),
                unit: string().nullable().required("Select Unit"),
                conference_type: string().nullable().required("Select League"),
                positions: string().nullable().required("Select Positions"),
                athleteType: string().nullable().required("Select Athlete Type"),
              })}
              render={(props) => <BackgorundStep {...props} />} />

            <Step
              validationSchema={object().shape(
                {
                  twitter: string().when("instagram", {
                    is: (b) => !b,
                    then: string().required("Enter at least one"),
                  }),
                  instagram: string().when("twitter", {
                    is: (a) => !a,
                    then: string().required("Enter at least one"),
                  }),
                },
                ["twitter", "instagram"]
              )}
              render={(props) => <SocialMediaStep {...props} />}
            />
            <Step render={(props) => <NotesStep {...props} />} />
          </Stepper>
        </Grid>
        <Grid item xs={5} style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
            <img
              style={{ width: '70%' }}
              src={runDashedLogo}
              alt="logo"
            />
          </div>
        </Grid>
      </Grid>
      {/* // </CreateCandidateDialogLayout> */}
    </React.Fragment>
  );
};

let mapStateToProps = (state) => {
  return {
    submittingPlayer: state.ui.player.submittingPlayer,
    teams: state.ui.player.candidateTeams,
    updateFilter: state.player.updateFilter
  };
};
export default connect(mapStateToProps)(CreatePlayerStepper);
