import ListItem from "@material-ui/core/ListItem";
import { alpha, withStyles } from "@material-ui/core/styles";
import '../../../index.css';

const DrawerItem = withStyles((theme) => ({
 
  root: {
    color: '#fff',
    fontFamily: 'Prometo',
    // marginBottom: '1rem',
    // marginTop: '1rem',
    borderLeft: '5px solid #181957',
    "&.Mui-selected": {
      backgroundColor: alpha(theme.palette.primary.light, 0.1),
      borderLeft: '5px solid #fcb643',
      // marginBottom: '1rem',
      // marginTop: '1rem'
    },
  },
}))(ListItem);

export default DrawerItem;
