import React, { useContext, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import sidebarImage from "../../../../assets/files_and_folder.png";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import Grid from "@material-ui/core/Grid";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import MuiTextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "../../../shared/Button";
import Alert from "@material-ui/lab/Alert";
import { ActionContext } from "../../../../contexts";
import { object, string } from "yup";
import { connect } from "react-redux";
import { Field, Formik, Form } from "formik";
import { Autocomplete } from "formik-material-ui-lab";
import { TextField, Select } from "formik-material-ui";
import { makeStyles } from "@material-ui/core/styles";

let useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    height: "100%",
    maxHeight: "100%",
    overflow: "hidden",
  },
  header: {
    position: "absolute",
    top: 0,
    display: "flex",
    width: "100%",
    minHeight: theme.spacing(12),
    paddingLeft: theme.spacing(9),
    paddingTop: theme.spacing(10),
    alignItems: "center",
  },
  header_button: {
    marginRight: theme.spacing(2),
  },
  page: {
    marginLeft: theme.spacing(3),
    left: 0,
    right: 0,
    flex: 1,
  },
  page_content: {
    display: "flex",
    paddingTop: theme.spacing(20),
  },
  page_main: {
    display: "flex",
    flexDirection: "column",
    minWidth: 782,
    paddingLeft: theme.spacing(15),
    maxHeight: 600,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: 7,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#fff",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  page_header: {
    // marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  page_form: {
    paddingTop: theme.spacing(1),
  },
  sidebar: {
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
    width: 320,
    backgroundColor: theme.palette.primary.main,
    backgroundAttachment: "scroll",
    backgroundPosition: "right bottom",
    backgroundSize: "100% 100%",
    zIndex: theme.zIndex.appBar,
  },
  sidebar_image: {
    position: "fixed",
    right: 0,
    top: 240,
    pointerEvents: "none",
  },
  heading: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    maxWidth: 480,
    fontWeight: 550,
    lineHeight: "60px",
  },
  form: {
    paddingTop: theme.spacing(1),
    maxWidth: 600,
  },
  button: {
    display: "flex",
    flexDirection: "row-reverse",
    paddingTop: theme.spacing(5),
  },
}));

const Layout = (props) => {
  let classes = useStyles();
  let { children, onClose, disableClose } = props;

  let handleCloseDialog = () => onClose();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <IconButton
          disabled={disableClose}
          className={classes.header_button}
          onClick={handleCloseDialog}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" color="textSecondary">
          Conference Managment
        </Typography>
      </div>
      <div className={classes.page}>
        <div className={classes.page_content}>
          <div className={classes.page_main}>
            <div>{children}</div>
            <Hidden mdDown>
              <div className={classes.sidebar}>
                <div className={classes.sidebar_image}>
                  <img src={sidebarImage} alt="sidebar" width={500} />
                </div>
              </div>
            </Hidden>
          </div>
        </div>
      </div>
    </div>
  );
};

const CreateConference = (props) => {
  let classes = useStyles();
  let { closeDialog, creatingConference, error, conferenceData } = props;
  let { accountActions, snackbarActions, conferenceActions } = useContext(ActionContext);

  const allNames = conferenceData && conferenceData.map(v => v.name)

  let proCodeOptions = [
    {
      "key": "Professional",
      "value": "Professional"
    },
    {
      "key": "Collegiate",
      "value": "Collegiate"
    }
  ]

  let handleFormSubmit = (values, helpers) => {
    let data = {
      name: values.name,
      procode: values.procode,
    };

    console.log("...........data", data, values)

    let handleSuccess = () => {
      helpers.setSubmitting(false);
      helpers.resetForm();
      snackbarActions.showSnackbar(
        "Successfully Created Conference"
      );
      closeDialog()
      conferenceActions.getConferenceList();
    };

    let handleFail = () => {
      helpers.setSubmitting(false);
    };

    conferenceActions.createConference(data, handleSuccess, handleFail);
  };


  let handleErrorClose = () => {
    conferenceActions.resetConferenceCreationError();
  };

  let uniqueNameValidation = (values) =>  {
    let error
    if (allNames.includes(values.name)) {
      error.name = 'Enter new name';
    }
    return error;
  }

  useEffect(() => {
    return () => {
      conferenceActions.resetConferenceCreationError();
    };
  }, []);

  useEffect(() => {
    if (error && error.length > 0) {
      setTimeout(() => {
        handleErrorClose();
      }, 3500);
    }
  }, [error]);

  return (
    <Layout onClose={closeDialog} disableClose={creatingConference}>
      <Formik
        initialValues={{
          name: "",
          procodeType: "",
        }}
        validationSchema={object().shape({
          name: string()
            .trim()
            .matches(
            // /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/,
            // "Enter valid name"
            // allNames.includes(values.name)
          )
            .required("Enter conference name"),
          // team: string().nullable().required("Select team"),
          // email: string().email("Enter a valid email").required("Enter email"),
          procode: string().required("Select Conference Type"),
        })}
        onSubmit={handleFormSubmit}
      >
        {(formik) => {
          let { errors, touched, values } = formik;

          return (
            <Form autoComplete="off">
              <Typography variant="h4" className={classes.heading}>
                Create Conference
              </Typography>

              <div className={classes.form}>
                <Grid container spacing={3}>
                  {error ? (
                    <Grid item>
                      <Alert severity="error" onClose={handleErrorClose}>
                        {error}
                      </Alert>
                    </Grid>
                  ) : null}

                  <Grid item md={12} sm={12}>
                    <Field
                      name="name"
                      label="Enter Name"
                      placeholder="Enter Name"
                      component={TextField}
                      variant="outlined"
                      fullWidth
                      autoFocus
                      validate={uniqueNameValidation}
                    />
                    {/* {errors.name && touched.name && <div>{errors.name}</div>} */}
                  </Grid>

                  <Grid item md={12} sm={12} lg={6}>
                    <FormControl
                      variant="outlined"
                      fullWidth
                      error={touched["procode"] && !!errors["procode"]}
                    >
                      <InputLabel htmlFor="procodeLabel">
                        Conference Type
                      </InputLabel>
                      <Field
                        component={Select}
                        name="procode"
                        label="Conference Type"
                        placeholder="Conference Type"
                        labelId="procodeLabel"
                        inputProps={{
                          id: "procode",
                        }}
                      >
                        {proCodeOptions

                          .map((list, index) => {
                            return (
                              <MenuItem
                                key={index}
                                value={list.value}
                              >
                                {list.key}
                              </MenuItem>
                            );
                          })}
                      </Field>
                      {touched["procode"] && !!errors["procode"] ? (
                        <FormHelperText>{errors["procode"]}</FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                </Grid>
                <div className={classes.button}>
                  <Button
                    type="submit"
                    color="primary"
                    size="large"
                    loading={creatingConference}
                  >
                    Create
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Layout>
  );
};

let mapStateToProps = (state) => {
  return {

    creatingConference: state.ui.conference.createConferenceReducer.loading,
    error: state.ui.conference.createConferenceReducer.error,

    conferenceData: state.conference.allConferenceList.allData,
  };
};
export default connect(mapStateToProps)(CreateConference);
