import React, { forwardRef } from "react";
import MuiMenuItem from "@material-ui/core/MenuItem";

const MenuItem = forwardRef((props, ref) => {
  let { handleClose, onClick, ...rest } = props;
  let handleClick = (e) => {
    onClick(e);
    handleClose();
  };
  return <MuiMenuItem ref={ref} onClick={handleClick} {...rest} />;
});

export default MenuItem;
