import React, { useState } from 'react'
import { Button, Tooltip, FormControl, InputLabel, MenuItem, Select, Box, Tabs, Tab, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles";
import { connect, useDispatch } from "react-redux";
import appConfig from "../../config";
import ComingSoonImg from '../coach/CommingSoonImg.js';
import '../../index.css';

let useStyles = makeStyles((theme) => ({

    root: {
        // backgroundColor: theme.palette.background.default,
        // display: "flex",
        // height: `calc(100vh - 50px)`,
        // zIndex: theme.zIndex.appBar,
    },
    container: {
        padding: 16,
        paddingTop: 48,
        marginLeft: "auto",
        marginRight: "auto",
        flexWrap: "wrap",
        [theme.breakpoints.down("md")]: {
            maxWidth: 550,
        },
    },

    paragraph: {
        padding: theme.spacing(1, 2),
        width: "100%",
        border: '1px solid #cdcdcd',
        borderRadius: '8px'
    },
    head: {
        display: "flex",
        // justifyContent: 'center',
        alignItems: 'center',
        // paddingLeft: '200px'
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function Info(props) {

    let classes = useStyles();


    let { isDrawerOpen } = props;

    let drawerWidth = isDrawerOpen
        ? appConfig.drawer.maxWidth
        : appConfig.drawer.minWidth;

    const [selectedPage, setSelectedPage] = useState(0)

    const handleChange = (event, newValue) => {
        setSelectedPage(newValue);
    };

    return (
        <React.Fragment>
            <div className={classes.root} style={{ paddingLeft: drawerWidth }}>


                <div className={classes.container}>

                    <div className={classes.head}>
                        <h1 style={{ color: '#326fc7',fontFamily: 'Prometo', margin: '0' }}>More Info</h1>
                    </div>
                    <div className={classes.head}>
                        <h4>FitInsights Platform's Confidentiality, Process of Analysis and Limitations</h4>
                    </div>
                    <div className={classes.head}>

                        <Box
                            sx={{ flexGrow: 1, }}
                        >
                            <Tabs
                                orientation="horizontal"
                                variant="scrollable"
                                value={selectedPage}
                                style={{ borderBottom: '1px solid #cdcdcd' }}
                                onChange={handleChange}
                                aria-label="Vertical tabs example"
                                sx={{ borderRight: 1, borderColor: 'divider' }}
                            >
                                <Tab label="Confidentiality" {...a11yProps(0)} />
                                <Tab label="Process of Analysis" {...a11yProps(1)} />
                                <Tab label="Limitations" {...a11yProps(2)} />
                                <Tab label="Glossary" {...a11yProps(3)} />
                            </Tabs>
                            <TabPanel value={selectedPage} index={0}>
                                <div className={classes.paragraph}>
                                    <p>
                                        We conducted an online search using a range of specialized and
                                        proprietary search tools to identify relevant sources of
                                        information. Our search approaches included keywords, locations,
                                        and limited reviews of the relevant social network connections
                                        including:
                                    </p>
                                    <ul>
                                        <li>Keyword and search-engine search.</li>
                                        <li>Account-based social media account searches.</li>
                                        <li>
                                            Our methodology ensures compliance with legal and statutory
                                            frameworks.
                                        </li>
                                        <li>
                                            Our tools and techniques collect publicly available data. We
                                            collect open source and social site data without recourse to any
                                            form of friending, linking or hacking. The method of collection
                                            and technology used depends on the social networking site or
                                            platform. Data availability is influenced by a complex set of
                                            variables such as site or social media platform function and
                                            design, caching and a complex matrix of user permissions which
                                            apply at artefact, post type and account levels.
                                        </li>
                                        <li>
                                            Collection credentials and authentication methods are logged
                                            with case data collected.
                                        </li>
                                        <li>
                                            We did not engage with the accounts of concern. To date our
                                            activity has been passive in that we have not engaged online
                                            with any accounts to test any key questions or provoke changes
                                            in behavior that may be illuminating.
                                        </li>
                                        <li>
                                            Our collections are passive and do not announce “follows” to
                                            target accounts as is the case when less technically
                                            sophisticated approaches are employed. We did not
                                            “false-friend”, create identities or “pretext” to collect any
                                            data. Our passive data collection activities are not evident to
                                            the operators of social media accounts.
                                        </li>
                                        <li>
                                            We did not breach any privacy legislation in our data collection
                                            activity.
                                        </li>
                                        <li>
                                            All operations are carried out using secure technology
                                            infrastructure hosted within a 'tier one' data center.
                                        </li>
                                        <li>
                                            Data is stored in a form which is compliant with leading data
                                            security and privacy standards. All data collected and stored is
                                            encrypted and we can provide a full account and production of
                                            all data collected if this is required but note that such data
                                            requests will incur additional costs.
                                        </li>
                                    </ul>
                                </div>
                            </TabPanel>
                            <TabPanel value={selectedPage} index={1}>
                                <div className={classes.paragraph}>
                                    <p>
                                        Executive Summaries, Personality Insights, Risks, and Other
                                        Insights are generated solely based on what a given athlete has
                                        posted or uttered.
                                    </p>
                                    <Typography variant="subtitle2">Text</Typography>
                                    <p>
                                        We take into account the things an athlete has posted on his/her
                                        social media since the beginning of each account. We do not give
                                        weight to the point in time in which the post was made, in other
                                        words, we consider all posts as equal.
                                    </p>
                                    <Typography variant="subtitle2">Audio/Video</Typography>
                                    <p>
                                        As of 2020/11, the system supports audio and video extraction and
                                        manual tagging. We utilize speech to text models to convert the
                                        Audio and Video into text, which is then sent through a manual
                                        review/tagging process. During this process, each sentence in the
                                        audio/video is tagged as 'Athlete' or 'Other'. The tagged content
                                        is stored in the same chronological order, which is then displayed
                                        in the videos tab of the Feed section. For insight/risk/etc.
                                        generation, only the text portion which is tagged as 'Athlete' is
                                        taken into account.
                                    </p>
                                    <Typography variant="subtitle2">News Articles</Typography>
                                    <p>
                                        News articles on a given athlete are automatically extracted and
                                        sentences are tagged using machine learning algorithms for
                                        relevancy and sent through Sentiment Analysis. Note that News
                                        Articles content is not considered when generating any
                                        insights/risk/etc. related to an Athlete. Article relevancy
                                        tagging is only for the use of the Psychologists who are utilizing
                                        the FitInsights platform.
                                    </p>
                                    <Typography variant="subtitle2">Machine Learning</Typography>
                                    <p>
                                        The text taken from above to methods are sent through several
                                        formatters & machine learning models including
                                    </p>
                                    <ul>
                                        <li>
                                            Formatters to clean the text, transform elements such as emojis,
                                            etc.
                                        </li>
                                        <li>A model for Sentiment Analysis</li>
                                        <li>A series of models for Personality Insights</li>
                                        <li>A series of models for Risks</li>
                                        <li>A series of models for Potential Psychological Disorders</li>
                                    </ul>
                                    <p>
                                        Once the insights are generated, they are timestamped and stored
                                        and are not allowed to be changed. This is due to the fact that
                                        the analysis is in fact a snapshot at a given point in time. When
                                        an update with new posts/videos/audio, is requested for the same
                                        athlete, the above-describedflow will execute independent of the
                                        previous, and the results will replace the old snapshot.
                                    </p>
                                </div>
                            </TabPanel>
                            <TabPanel value={selectedPage} index={2}>
                                <div className={classes.paragraph}>
                                    <p>
                                        A range of factors limited our activities and the value of our
                                        findings including:
                                    </p>
                                    <Typography variant="subtitle2">
                                        Nature of information collected
                                    </Typography>
                                    <p>
                                        Information collected from online sources is circumstantial. Key
                                        questions will always exist regarding the true authors of online
                                        activities. Notwithstanding that, a long-term view of online
                                        behavior, focusing on consistency, patterns and style typically
                                        eliminates many doubts as to the author of online content and
                                        activity of the subject of interest. We endeavor to collect posts
                                        from multiple authors about the same subject matter to add
                                        certainty to conclusions drawn where the data is relied upon.
                                    </p>
                                    <Typography variant="subtitle2">Surveillance awareness</Typography>
                                    <p>
                                        Any communication and interaction with the subject of our
                                        investigation prior to our investigation may have changed
                                        behaviors including increasing privacy settings, adopting alias’,
                                        amendment and deletion of accounts. This may have limited the
                                        volume and probity of material available for our collection.
                                    </p>
                                    <Typography variant="subtitle2">Technical issues</Typography>
                                    <p>
                                        Periodically our technology is disrupted by the way that the
                                        social sites publish their data. Notwithstanding that, we have
                                        collected a significant volume of material.
                                    </p>
                                    <Typography variant="subtitle2">Volume of material</Typography>
                                    <p>
                                        We typically collect a significant amount of material that
                                        requires time to search and analyze. Our search and analysis tools
                                        accelerate productive assessment of the collected data, but we
                                        note the task is significant.
                                    </p>
                                    <Typography variant="subtitle2">Misattribution risk</Typography>
                                    <p>
                                        Any collection of online data includes inherent risks of data
                                        being incorrectly attributed to a subject or author. We use proven
                                        methods for linking online data to the subject of an investigation
                                        but the recipients and users of our reports need to satisfy
                                        themselves that data in our report is correctly related to the
                                        subject of the data collection.
                                    </p>
                                    <p>
                                        The comments made and conclusions drawn in this report are based
                                        on information obtained in the course of our investigation. We do
                                        not profess to advise you in any way on liability or the conduct
                                        of this matter.
                                    </p>
                                    <p>
                                        Material collected in online research or investigations is
                                        circumstantial and needs to be corroborated by other forms of
                                        evidence.
                                    </p>
                                </div>
                            </TabPanel>
                            <TabPanel value={selectedPage} index={3}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',height: '60vh' }}>

                                    <ComingSoonImg />

                                </div>
                            </TabPanel>
                        </Box>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

let mapStateToProps = (state) => {
    return {
    }
}

export default connect(mapStateToProps)(Info)