import React, { useContext, useEffect } from 'react'
import { Button, Box, FormControl, FormGroup, FormControlLabel, InputAdornment, Checkbox, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select } from "@material-ui/core"
import { connect } from "react-redux";
import { ActionContext } from "../../../contexts";
import { makeStyles } from "@material-ui/core/styles";
import filterDashedLogo from "../../../assets/FilterDashedIcon.svg";
import ClearIcon from "@material-ui/icons/ClearOutlined";
import IconButton from "@material-ui/core/IconButton";
import { useSelector } from "react-redux";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import '../../../index.css';

let useStyles = makeStyles((theme) => ({
    btnPrimary: {
        color: '#fff',
        border: '1px solid',
        padding: '10px',
        background: '#007fdb',
        width: '13rem',
        marginRight: '10px',
        borderRadius: '20px',
        '&:hover': {
            background: '#007fdb',
            color: '#fff'
        },
    },
    btnSecondary: {
        color: theme.palette.secondary.main,
        border: '1px solid',
        width: '13rem',
        padding: '10px',
        marginRight: '10px',
        borderRadius: '20px',
        '&:hover': {
            background: theme.palette.secondary.main,
            color: '#fff'
        }
    },
}));

function Filter(props) {

    const { accountType, isFilteredApply, pageChange, conferenceData, sportsData, unitData, sportPosition, conferenceByType, loadingFilters, filterCandidates, updateFilter, teamByConference, sportInConference,teams } = props;
    let classes = useStyles();
    let actions = useContext(ActionContext);
    let { playerActions, sportsActions, conferenceActions } = actions;

    let accountState = useSelector((state) => state.account);

    const [state, setState] = React.useState({
        conference: "",
        sport: "",
        unit: "",
        position: "",
        athleteType: "All",
        conferenceType: "All",
        attribute: "",
        attributeSort: "",
        team: ""
    });
    const [behavior, setBehavior] = React.useState('');
    const [behaviorSort, setBehaviorSort] = React.useState('');
    useEffect(() => {
        if (updateFilter) {
            setState(updateFilter);
            setBehavior(updateFilter.behaviorRisk);
            setBehaviorSort(updateFilter.behaviorRiskSort);
        }
    }, [updateFilter]);

    const handlePlayerChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
        playerActions.updateFilterValue(event.target.name, event.target.value)
        // conferenceActions.getTeamsByConferenceId(event.target.value);
    }

    // const handleChange = (event) => {
    //     const {
    //       target: { name , value }
    //     } = event;

    //     setState({
    //         ...state,
    //         [name]: typeof value === "string" ? value.split(",") : value,
    //     });

    //   };

    const handleConferenceChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
        playerActions.updateFilterValue(event.target.name, event.target.value)
        if (event.target.value === "All") {
            conferenceActions.getConferenceList();
        } else {
            conferenceActions.getConferenceByType(event.target.value);
            setState({
                ...state,
                "conference": "",
                // "sport": "",
                // "team": "",
            });
            playerActions.updateFilterValue("conference", "")
            // playerActions.updateFilterValue("sport", "")
            // playerActions.updateFilterValue("team", "")
            // conferenceActions.resetTeamByConferenceId()
        }

    }


    const handleSportChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
            "unit": "",
            "position": ""
        });
        playerActions.updateFilterValue(event.target.name, event.target.value)
        sportsActions.getSportUnitDetails(event.target.value);
        playerActions.updateFilterValue("unit", "")
        playerActions.updateFilterValue("position", "")
        sportsActions.resetSportsApi()
    }

    const handleTeamChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
        playerActions.updateFilterValue(event.target.name, event.target.value)

    }

    const handleUnitChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
        playerActions.updateFilterValue(event.target.name, event.target.value)
        sportsActions.getSportPositionDetails(event.target.value, state.sport);
    }

    const handleClearClick = (name) => {
        if (name === "unit") {
            setState({
                ...state,
                "unit": "",
                "position": ""
            });
            playerActions.updateFilterValue("unit", "")
            playerActions.updateFilterValue("position", "")
        } else if (name === "sport") {
            setState({
                ...state,
                "sport": "",
                "unit": "",
                "position": ""
            });
            playerActions.updateFilterValue("sport", "")
            playerActions.updateFilterValue("unit", "")
            playerActions.updateFilterValue("position", "")
        }
        else if (name === "conference") {
            setState({
                ...state,
                "conference": "",
                // "sport": "",
                // "team": "",
            });
            // playerActions.updateFilterValue("sport", "")
            // playerActions.updateFilterValue("team", "")
            playerActions.updateFilterValue("conference", "")
        }
        else if (name === "behaviorRisk") {
            setBehavior("")
            setBehaviorSort("")
            playerActions.updateFilterValue("behaviorRisk", "")
            playerActions.updateFilterValue("behaviorRiskSort", "")

        }
        else if (name === "attribute") {
            setState({
                ...state,
                "attribute": "",
                "attributeSort": "",

            });
            playerActions.updateFilterValue("attribute", "")
            playerActions.updateFilterValue("attributeSort", "")

        }
        else if (name === "behaviorRiskSort") {
            setBehaviorSort("")
            playerActions.updateFilterValue("behaviorRiskSort", "")
        }
        else {
            setState({
                ...state,
                [name]: "",
            });
            playerActions.updateFilterValue(name, "")
        }

        if (accountType === "COL_HEAD_COACH" || accountType === "COL_POSITION_COACH" || accountType === "PRO_POSITION_COACH" || accountType === "PRO_GENERAL_MANAGER" || accountType === "PRO_HEAD_COACH" || accountType === "PRO_SECURITY") {
            if (name === "unit") {
                sportsActions.resetSportsApi()
            }
        } else {
            if (name === "sport") {
                sportsActions.resetUnitsApi()
                sportsActions.resetSportsApi()
            } else if (name === "unit") {
                sportsActions.resetSportsApi()
            } 
            // else if (name === "conference") {
            //     conferenceActions.resetTeamByConferenceId()
            // }
        }
    }

    const handleCancel = () => {
        playerActions.resetFilterValue()
        if (accountType === "COL_HEAD_COACH" || accountType === "COL_POSITION_COACH" || accountType === "PRO_POSITION_COACH" || accountType === "PRO_GENERAL_MANAGER" || accountType === "PRO_HEAD_COACH" || accountType === "PRO_SECURITY") {
            sportsActions.resetSportsApi()
        } else {
            sportsActions.resetUnitsApi()
            sportsActions.resetSportsApi()
            // conferenceActions.resetTeamByConferenceId()
        }

    }

    const handleSave = () => {
        let model = {
            conference: state.conference ? [state.conference] : [],
            sport: state.sport ? [state.sport] : [],
            unit: state.unit ? [state.unit] : [],
            position: state.position ? [state.position] : [],
            athleteType: state.athleteType === "All" ? [] : [state.athleteType],
            conferenceType: state.conferenceType === "All" ? [] : [state.conferenceType],
            attribute: state.attribute ? [state.attribute] : [],
            behavior: behavior ? [behavior] : [],
            attributeSort: state.attributeSort ? [state.attributeSort] : [],
            behaviorSort: behaviorSort ? [behaviorSort] : [],
            team : state.team ? [state.team] : []
        }
        // pageChange('list')
        // isFilteredApply(true)
        // playerActions.isFilteredData(true)
        playerActions.getAllFiltersCandidates(model)

    }
    useEffect(() => {
        conferenceActions.getConferenceList();
        sportsActions.getSportsList();
        playerActions.getTeamList();
    }, []);

    useEffect(() => {
        if (loadingFilters === true) {
            pageChange('list')
            isFilteredApply(true)
            playerActions.isFilteredData(true)
        }

    }, [loadingFilters]);

    useEffect(() => {
        if (accountType === "COL_HEAD_COACH" || accountType === "COL_POSITION_COACH" || accountType === "PRO_POSITION_COACH" || accountType === "PRO_GENERAL_MANAGER" || accountType === "PRO_HEAD_COACH" || accountType === "PRO_SECURITY") {

            if (accountState?.sport) {
                playerActions.updateFilterValue("sport", accountState?.sport)
                sportsActions.getSportUnitDetails(accountState?.sport);
            } else {
                playerActions.updateFilterValue("sport", "Football")
                sportsActions.getSportUnitDetails("Football");
            }

        }

    }, [accountType]);


    const finalConference = state.conferenceType !== "All" ? conferenceByType : conferenceData

    const handleAttributeChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        });
        playerActions.updateFilterValue(event.target.name, event.target.value);

    }

    const handleBehaviorChange = (event) => {
        setBehavior(event.target.value)
        playerActions.updateFilterValue(event.target.name, event.target.value);
    }
    const handleBehaviorSortChange = (event) => {
        setBehaviorSort(event.target.value)
        playerActions.updateFilterValue(event.target.name, event.target.value);
    }
    // const handleClearSport = () => {
    //      setState({
    //         ...state,
    //         sport: "",
    //         unit: "",
    //         position: ""
    //     });
    //     // playerActions.resetSelectdFilterValue()

    // };
    const redirectToAthletPage = () => {
        pageChange('list')
    }
    return (
        <React.Fragment>
            <div style={{
                display: 'flex', margin: '2rem 1rem 1rem 2rem', boxShadow: '3px 3px 10px 3px #cdcdcd',
                margin: '2rem 1rem',
                padding: '2rem',
                borderRadius: '0.5rem',
            }}>
                <Grid container spacing={1}>
                    <Grid item xs={7}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', fontWeight: 'bold', fontSize: "15px" }}><span style={{ display: 'flex', alignItems: 'center', fontSize: '18px' }}><ArrowBackIosIcon style={{ color: '#0971ce', fontSize: "20px" }} onClick={redirectToAthletPage} />Back</span></div>
                        <div>
                            <div style={{
                                display: 'flex', justifyContent: 'flex-start', fontWeight: 'bold', fontSize: "20px", fontSize: '20.5px',
                                margin: '1.5rem 0rem', fontFamily: 'Prometo',
                                color: '#0971ce',
                            }}>
                                Blue Chip Filter
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', fontWeight: 'bold', fontSize: "19px", margin: '2rem 0rem', color: '#000000' }}>
                                Select all that are necessary
                            </div>
                            <div >
                                {
                                    (accountType === "SUPER_ADMIN" || accountType === "BCA_DDI_ADMIN" || accountType === "BCA_PSYCHOLOGIST" || accountType === "PRO_GENERAL_MANAGER" || accountType === "PRO_HEAD_COACH" || accountType === "BCA_CHIEF_PSYCHOLOGIST") ?
                                        <div style={{ margin: '1rem 0rem', alignItems: 'center', justifyContent: 'center' }}>
                                            <Box sx={{ display: 'flex' }}>
                                                <FormControl>
                                                    <FormLabel id="demo-radio-buttons-group-label">League</FormLabel>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        defaultValue=""
                                                        row
                                                        name="conferenceType"
                                                        value={state.conferenceType}

                                                        onChange={handleConferenceChange}
                                                    >
                                                        <FormControlLabel value="All" control={<Radio />} label="All" />
                                                        <FormControlLabel value="Professional" control={<Radio />} label="Professional" />
                                                        <FormControlLabel value="Collegiate" control={<Radio />} label="Collegiate" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Box>
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    (accountType === "SUPER_ADMIN" || accountType === "BCA_DDI_ADMIN" || accountType === "BCA_PSYCHOLOGIST" || accountType === "COL_ATHLETIC_DIRECTOR" || accountType === "COL_HEAD_COACH" ||
                                        accountType === "COL_POSITION_COACH" || accountType === "PRO_POSITION_COACH" || accountType === "PRO_GENERAL_MANAGER" || accountType === "PRO_HEAD_COACH" || accountType === "COL_PSYCHOLOGIST" || accountType === "PRO_PSYCHOLOGIST" || accountType === "BCA_CHIEF_PSYCHOLOGIST" || accountType === "PRO_SECURITY"
                                    ) ?
                                        <div style={{ margin: '1rem 0rem', alignItems: 'center', justifyContent: 'center' }}>
                                            <FormControl>
                                                <FormLabel id="demo-radio-buttons-group-label">Athlete Type</FormLabel>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue=""
                                                    row
                                                    name="athleteType"
                                                    value={state.athleteType}
                                                    onChange={handlePlayerChange}
                                                >
                                                    <FormControlLabel value="All" control={<Radio />} label="All" />
                                                    <FormControlLabel value="Roster" control={<Radio />} label="Roster" />
                                                    <FormControlLabel value="Recruit" control={<Radio />} label="Recruit" />
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                        :
                                        ""
                                }
                            </div>


                            <div style={{ display: 'flex', marginBottom: '1rem', }}>

                                {
                                    (accountType === "SUPER_ADMIN" || accountType === "BCA_DDI_ADMIN" || accountType === "BCA_PSYCHOLOGIST" || accountType === "BCA_CHIEF_PSYCHOLOGIST") ?
                                        <div style={{ marginRight: '1rem' }}>
                                            <FormControl variant="filled" sx={{ m: 1, minWidth: 200 }}>
                                                <InputLabel id="demo-simple-select-autowidth-label">Conference</InputLabel>
                                                <Select
                                                    style={{ width: "200px" }}
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    value={state.conference}
                                                    onChange={handlePlayerChange}
                                                    // multiple
                                                    label="Conference"
                                                    name="conference"
                                                    endAdornment={
                                                        state.conference !== "" ?
                                                            <IconButton sx={{ visibility: state.conference !== "" ? "visible" : "hidden" }}
                                                                onClick={() => handleClearClick("conference")}><ClearIcon />
                                                            </IconButton>
                                                            :
                                                            ""
                                                    }
                                                >
                                                    {
                                                        finalConference && finalConference.map(list =>
                                                            <MenuItem key={list.name} value={list.name}>{list.name}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    (accountType === "SUPER_ADMIN" || accountType === "BCA_DDI_ADMIN" || accountType === "BCA_PSYCHOLOGIST" || accountType === "COL_ATHLETIC_DIRECTOR" || accountType === "COL_PSYCHOLOGIST" || accountType === "PRO_PSYCHOLOGIST" || accountType === "BCA_CHIEF_PSYCHOLOGIST") ?

                                        <div style={{ margin: '0rem' }}>

                                            <FormControl variant="filled">
                                                <InputLabel id="demo-simple-select-autowidth-label">Sport</InputLabel>
                                                <Select
                                                    style={{ width: "200px" }}
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    // multiple
                                                    value={state.sport}
                                                    onChange={handleSportChange}
                                                    label="Sport"
                                                    name="sport"
                                                    endAdornment={
                                                        state.sport !== "" ?
                                                            <IconButton sx={{ visibility: state.sport !== "" ? "visible" : "hidden" }}
                                                                onClick={() => handleClearClick("sport")}><ClearIcon />
                                                            </IconButton>
                                                            :
                                                            ""
                                                    }
                                                >
                                                    {
                                                        sportsData && sportsData.map(list =>
                                                            <MenuItem value={list.name}>{list.name}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                        :
                                        ""
                                }
                            </div>
                            <div style={{ display: 'flex', marginBottom: '1rem'}}>
                                {
                                    (accountType === "SUPER_ADMIN" || accountType === "BCA_DDI_ADMIN" || accountType === "BCA_PSYCHOLOGIST" || accountType === "BCA_CHIEF_PSYCHOLOGIST") ?
                                        <div style={{ marginRight: '1rem' }}>
                                            <FormControl variant="filled" sx={{ m: 1, minWidth: 80 }}>
                                                <InputLabel id="demo-simple-select-autowidth-label">Team</InputLabel>
                                                <Select
                                                    style={{ width: "200px" }}
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    value={state.team}
                                                    onChange={handleTeamChange}
                                                    autoWidth
                                                    label="Teams"
                                                    name="team"
                                                    endAdornment={
                                                        state.team !== "" ?
                                                            <IconButton sx={{ visibility: state.team !== "" ? "visible" : "hidden" }}
                                                                onClick={() => handleClearClick("team")}><ClearIcon />
                                                            </IconButton>
                                                            :
                                                            ""
                                                    }
                                                >
                                                    {
                                                        teams && Object.keys(teams).map(list =>
                                                            <MenuItem value={list}>{list}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                        :
                                        ""
                                }

                                {
                                    (accountType === "SUPER_ADMIN" || accountType === "BCA_DDI_ADMIN" || accountType === "BCA_PSYCHOLOGIST" || accountType === "COL_ATHLETIC_DIRECTOR" || accountType === "COL_HEAD_COACH" ||
                                        accountType === "COL_POSITION_COACH" || accountType === "PRO_POSITION_COACH" || accountType === "PRO_GENERAL_MANAGER" || accountType === "PRO_HEAD_COACH" || accountType === "BCA_CHIEF_PSYCHOLOGIST"
                                        || accountType === "PRO_SECURITY"
                                    ) ?
                                        <div style={{ margin: '0rem' }}>
                                            <FormControl variant="filled" sx={{ m: 1, minWidth: 80 }}>
                                                <InputLabel id="demo-simple-select-autowidth-label">Unit</InputLabel>
                                                <Select
                                                    style={{ width: "200px" }}
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    value={state.unit}
                                                    onChange={handleUnitChange}
                                                    autoWidth
                                                    label="Unit"
                                                    name="unit"
                                                    endAdornment={
                                                        state.unit !== "" ?
                                                            <IconButton sx={{ visibility: state.unit !== "" ? "visible" : "hidden" }}
                                                                onClick={() => handleClearClick("unit")}><ClearIcon />
                                                            </IconButton>
                                                            :
                                                            ""
                                                    }
                                                >
                                                    {
                                                        unitData && unitData.map(list =>
                                                            <MenuItem value={list.unit}>{list.unit}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                        :
                                        ""
                                }
                            </div>
                            <div style={{ display: 'flex', }}>
                                {/* {
                                    (accountType === "SUPER_ADMIN" || accountType === "BCA_DDI_ADMIN" || accountType === "BCA_PSYCHOLOGIST" || accountType === "COL_ATHLETIC_DIRECTOR" || accountType === "COL_HEAD_COACH" ||
                                        accountType === "COL_POSITION_COACH" || accountType === "PRO_POSITION_COACH" || accountType === "PRO_GENERAL_MANAGER" || accountType === "PRO_HEAD_COACH" || accountType === "BCA_CHIEF_PSYCHOLOGIST"
                                        || accountType === "PRO_SECURITY"
                                    ) ?
                                        <div>
                                            <FormControl variant="filled" sx={{ m: 1, minWidth: 80 }}>
                                                <InputLabel id="demo-simple-select-autowidth-label">Unit</InputLabel>
                                                <Select
                                                    style={{ width: "200px" }}
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    value={state.unit}
                                                    onChange={handleUnitChange}
                                                    autoWidth
                                                    label="Unit"
                                                    name="unit"
                                                    endAdornment={
                                                        state.unit !== "" ?
                                                            <IconButton sx={{ visibility: state.unit !== "" ? "visible" : "hidden" }}
                                                                onClick={() => handleClearClick("unit")}><ClearIcon />
                                                            </IconButton>
                                                            :
                                                            ""
                                                    }
                                                >
                                                    {
                                                        unitData && unitData.map(list =>
                                                            <MenuItem value={list.unit}>{list.unit}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                        :
                                        ""
                                } */}
                                {
                                    (accountType === "SUPER_ADMIN" || accountType === "BCA_DDI_ADMIN" || accountType === "BCA_PSYCHOLOGIST" || accountType === "COL_ATHLETIC_DIRECTOR" || accountType === "COL_HEAD_COACH" ||
                                        accountType === "COL_POSITION_COACH" || accountType === "PRO_POSITION_COACH" || accountType === "PRO_GENERAL_MANAGER" || accountType === "PRO_HEAD_COACH" || accountType === "BCA_CHIEF_PSYCHOLOGIST"
                                        || accountType === "PRO_SECURITY"
                                    ) ?

                                        <div style={{ margin: '0rem' }}>
                                            <FormControl variant="filled" sx={{ m: 1, minWidth: 80 }}>
                                                <InputLabel id="demo-simple-select-autowidth-label">Position</InputLabel>
                                                <Select
                                                    style={{ width: "200px" }}
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    value={state.position}
                                                    onChange={handlePlayerChange}
                                                    autoWidth
                                                    label="Position"
                                                    name="position"
                                                    endAdornment={
                                                        state.position !== "" ?
                                                            <IconButton sx={{ visibility: state.position !== "" ? "visible" : "hidden" }}
                                                                onClick={() => handleClearClick("position")}><ClearIcon />
                                                            </IconButton>
                                                            :
                                                            ""
                                                    }
                                                >
                                                    {
                                                        sportPosition && sportPosition.map(list =>
                                                            <MenuItem value={list.position}>{list.position}</MenuItem>
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                        :
                                        ""
                                }
                            </div>
                            <div style={{ display: 'flex' }}>

                                {
                                    (accountType === "COL_PSYCHOLOGIST" || accountType === "PRO_PSYCHOLOGIST") ?
                                        <div style={{ marginBottom: '1rem', marginRight: '1rem' }}>
                                            <FormControl variant="filled" sx={{ m: 1, minWidth: 80 }}>
                                                <InputLabel id="demo-simple-select-autowidth-label">Attribute</InputLabel>
                                                <Select
                                                    style={{ width: "200px" }}
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    value={state.attribute}
                                                    onChange={handleAttributeChange}
                                                    autoWidth
                                                    label="Attribute"
                                                    name="attribute"
                                                    endAdornment={
                                                        state.attribute !== "" ?
                                                            <IconButton sx={{ visibility: state.attribute !== "" ? "visible" : "hidden" }}
                                                                onClick={() => handleClearClick("attribute")}><ClearIcon />
                                                            </IconButton>
                                                            :
                                                            ""
                                                    }
                                                >
                                                    <MenuItem value='Character'>Character</MenuItem>
                                                    <MenuItem value='Coachability'>Coachability</MenuItem>
                                                    <MenuItem value='Composure'>Composure</MenuItem>
                                                    <MenuItem value='Leadership'>Leadership</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    (accountType === "COL_PSYCHOLOGIST" || accountType === "PRO_PSYCHOLOGIST") ?

                                        <div style={{}}>
                                            <FormControl variant="filled" sx={{ m: 1, minWidth: 80 }}>
                                                <InputLabel id="demo-simple-select-autowidth-label">Attribute Sort</InputLabel>
                                                <Select
                                                    style={{ width: "200px" }}
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    value={state.attributeSort}
                                                    onChange={handleAttributeChange}
                                                    autoWidth
                                                    label="Attribute"
                                                    name="attributeSort"
                                                    endAdornment={
                                                        state.attributeSort !== "" ?
                                                            <IconButton sx={{ visibility: state.attributeSort !== "" ? "visible" : "hidden" }}
                                                                onClick={() => handleClearClick("attributeSort")}><ClearIcon />
                                                            </IconButton>
                                                            :
                                                            ""
                                                    }
                                                >
                                                    <MenuItem value='High to Low'>High to Low</MenuItem>
                                                    <MenuItem value='Low to High' >Low to High</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        :
                                        ""
                                }
                            </div>
                            <div style={{ display: 'flex' }}>

                                {
                                    (accountType === "COL_PSYCHOLOGIST" || accountType === "PRO_PSYCHOLOGIST") ?

                                        <div style={{ marginBottom: '1rem', marginRight: '1rem' }}>
                                            <FormControl variant="filled" sx={{ m: 1, minWidth: 80 }}>
                                                <InputLabel id="demo-simple-select-autowidth-label">Behavior Risk</InputLabel>
                                                <Select
                                                    style={{ width: "200px" }}
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    value={behavior}
                                                    onChange={handleBehaviorChange}
                                                    autoWidth
                                                    label="BehaviorRisk"
                                                    name="behaviorRisk"
                                                    endAdornment={
                                                        behavior !== "" ?
                                                            <IconButton sx={{ visibility: behavior !== "" ? "visible" : "hidden" }}
                                                                onClick={() => handleClearClick("behaviorRisk")}><ClearIcon />
                                                            </IconButton>
                                                            :
                                                            ""
                                                    }
                                                >
                                                    <MenuItem value='Aggression/Violence'>Aggression/Violence</MenuItem>
                                                    <MenuItem value='Delinquent Behavior'>Delinquent Behavior</MenuItem>
                                                    <MenuItem value='Drug/Alcohol'>Drug & Alcohol</MenuItem>
                                                    <MenuItem value='Emotional Distress'>Emotional Distress</MenuItem>
                                                    <MenuItem value='Sexual Behavior'>Sexual Behavior</MenuItem>

                                                </Select>
                                            </FormControl>
                                        </div>

                                        :
                                        ""
                                }
                                {
                                    (accountType === "COL_PSYCHOLOGIST" || accountType === "PRO_PSYCHOLOGIST") ?

                                        <div style={{}}>
                                            <FormControl variant="filled" sx={{ m: 1, minWidth: 80 }}>
                                                <InputLabel id="demo-simple-select-autowidth-label">Behavior Risk Sort</InputLabel>
                                                <Select
                                                    style={{ width: "200px" }}
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    value={behaviorSort}
                                                    onChange={handleBehaviorSortChange}
                                                    autoWidth
                                                    label="BehaviorRisk"
                                                    name="behaviorRiskSort"
                                                    endAdornment={
                                                        behaviorSort !== "" ?
                                                            <IconButton sx={{ visibility: behaviorSort !== "" ? "visible" : "hidden" }}
                                                                onClick={() => handleClearClick("behaviorRiskSort")}><ClearIcon />
                                                            </IconButton>
                                                            :
                                                            ""
                                                    }
                                                >
                                                    <MenuItem value='High to Low'>High to Low</MenuItem>
                                                    <MenuItem value='Low to High' >Low to High</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </div>
                                        :
                                        ""
                                }
                            </div>

                            <div style={{ marginTop: '2rem' }}>
                                <Button className={classes.btnSecondary} onClick={handleCancel}>
                                    Clear
                                </Button>
                                <Button className={classes.btnPrimary} onClick={handleSave}>
                                    Run Report
                                </Button>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={5}>
                        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                            <img
                                src={filterDashedLogo}
                                alt="logo"
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}


let mapStateToProps = (state) => {
    return {
        sportsData: state.sports.sportsListData,
        // countriesData: state.conference.allCountries,
        stateData: state.conference.allStateByCountry,
        unitData: state.sports.unitSport,
        sportPosition: state.sports.sportPosition,
        conferenceByType: state.conference.allConferenceByType,
        conferenceData: state.conference.allConferenceList.allData,
        filterCandidates: state.player.filterCandidates,
        loadingFilters: state.ui.player.loadingFilters,
        updateFilter: state.player.updateFilter,
        isFiltered: state.player.isFiltered,
        accountType: state.account.accountType,
        teamByConference: state.conference.teamByConference,
        sportInConference: state.conference.sportInConference,
        teams: state.ui.player.candidateTeams,
    };
};
export default connect(mapStateToProps)(Filter);