import React, { useEffect } from "react";
import MuiDialog from "@material-ui/core/Dialog";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

const Dialog = ({
  id,
  open,
  closeDialog,
  disableBackdropClick = true,
  dialog,
}) => {
  const { component, props } = dialog;
  const history = useHistory();

  const handleClose = () => {
    closeDialog(id);
  };

  useEffect(() => {
    history.listen((newLocation, action) => {
      if (action === "POP") {
        handleClose();
      }
    });
  }, []);

  return (
    <MuiDialog
      fullWidth
      open={open}
      onClose={handleClose}
      disableBackdropClick={disableBackdropClick}
      {...props}
    >
      {React.cloneElement(component, { closeDialog: handleClose })}
    </MuiDialog>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    dialog: state.dialogs.byId[ownProps.id],
  };
};

export default connect(mapStateToProps)(Dialog);
