import React, { useState, useContext } from "react";
import ExpansionArticle from "../PlayerAttributes/ExpansionArticle";
import ChartAndDetails from "../PlayerAttributes/ChartAndDetails";
import { connect } from "react-redux";
import { Tabs, TabPanel } from "../../../shared/Tabs";
import CircleIcon from '@material-ui/icons/Brightness1';
import PlayerDetails from "../../../athlet-management/components/PlayerDetails";
import { ActionContext } from "../../../../../src/contexts";
import { Slide } from "@material-ui/core"
import { round } from "utils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="left"
      ref={ref}
      timeout={{ enter: 1000, exit: 1000 }}
      {...props}
    />
  );
});

const chartColors = {
  red: "#f44336",
  orange: "#ff9800",
  yellow: "#fdd835",
  green: "#4caf50",
};

const PersonalityTraits = (props) => {
  let [selectedIndex, setSelectedIndex] = useState(0);
  // let { traits } = props;
  const { teamPlayers, loadingPlayerFilters } = props

  let actions = useContext(ActionContext);

  let { playerActions, dialogActions, coachActions } = actions;


  const handleredirect = (playerId) => {
    playerActions.getPlayer(playerId);
    playerActions.selectPlayer(playerId);

    dialogActions.openDialog(<PlayerDetails playerId={playerId} fromTeam={true} />, {
      fullScreen: true,
      TransitionComponent: Transition,
    });
  }

  let traits = {
    aggressionViolence: {
      description: "Explores one’s level of frustration, anger, and violent behavioral response. Aggression is associated with anger and violent feelings stemming from high levels of irritability, frustration, and negative emotional states that impact behavior. Violence is identified as one using physical force with the intent/desire to cause injury or harm to another individual or destruction of property.",
      label: "Aggression/Violence",
      type: "Aggression/Violence"
    },
    delinquentBehavior : {
      description: "Explores how an individual’s behavior fits within normative range. Delinquency indicates behavior that does not conform to a normed standard or expectation, not adhering to rules, standards of practice, laws, or moral standards of society.",
      label: "Delinquent Behavior",
      type: "Delinquent Behavior"
    },
    drugAlcohol: {
      description: "Explores all activities associated with drug, alcohol, and/or substance use. This may be indicated as the use of substances for recreational activities to engaging in substance use as a maladaptive coping tool. It should be noted that individuals who drink prior to the legal age are at greater probability of participation in other risky behaviors (e.g., multiple sexual partners, unprotected intercourse, deviant behaviors) which may negatively alter decision making and/or negatively impact one's physical, mental, and emotional state.",
      label: "Drug/Alcohol",
      type: "Drug/Alcohol"
    },
    emotionalDistress: {
      description: "Explores one’s ability to manage emotions and cope in high stress scenarios. This can range from managing situational stressors and coping effectively to having high psychological distress warranting a need for mental health interventions.",
      label: "Emotional Distress",
      type: "Emotional Distress"
    },
    sexualBehavior: {
      description: "Explores all activities which fulfill an individual’s sexual needs. Sexual behavior is explored through the context of language, behaviors, practices, relationships, and reproductive health. The expression and evolution of one’s sexual behavior is a natural phenomenon; however, the context in which one’s sexual behavior is expressed may make the behavior abnormal or high risk. It is shown that interpersonal, social, and economic factors can influence the prevalence of risky sexual behaviors in different situations.",
      label: "Sexual Behavior",
      type: "Sexual Behavior"
    },
  }

  let agreeableness = traits["agreeableness"];
  let conscientiousness = traits["conscientiousness"];
  let emotionalStability = traits["emotional_range"];
  let extraversion = traits["extroversion"];
  let openness = traits["openness"];


  let handleChange = (e, index) => {
    setSelectedIndex(index);
  };

  let handleColors = (score) => {
    if (0 <= score && score < 25) {
      return chartColors.red;
    } else if (25 <= score && score < 50) {
      return chartColors.orange;
    } else if (50 <= score && score < 75) {
      return chartColors.yellow;
    } else if (75 <= score && score < 100) {
      return chartColors.green;
    }
  };

  let customTrait = Object.values(traits)[selectedIndex];

  let players = [
    {
      "name": "John Pope",
      "id": "0ecf5091-51ac-4112-8b49-82301c0429f3",
      "score": "82",
      "conference": "NFL",
      "conference_type": "Professional",
      "team": "Blu Chip Analytics",
      "sport": "Football",
      "position": "",
      "unit": "Offense",
    },
    {
      "name": "Kyle James",
      "id": "1010339d-b220-469f-a9d8-86e0d2872a0b",
      "score": "75",
      "conference": "NFL",
      "conference_type": "Professional",
      "team": "Tennessee Titans",
      "sport": "",
      "position": "",
      "unit": "Offense",
    },
    {
      "name": "Han Solo",
      "id": "9cf009f4-f1ad-41ee-a406-a591e2a56f35",
      "score": "68",
      "conference": "NFL",
      "conference_type": "Professional",
      "team": "Blu Chip Analytics",
      "sport": "Football",
      "position": "",
      "unit": "Offense",
    }
  ]

  let risks = [
    {
      "name": "Ben Bamford",
      "id": "ba252c42-ed93-481e-8b51-7cce376f9d85",
      "score": "29",
      "team": "Blu Chip Analytics",
      "conference": "",
      "conference_type": "",
      "sport": "",
      "position": "",
      "unit": "",
    },
    {
      "name": "Peter Paul",
      "id": "0677fe11-0639-405c-80b4-8100f63aad6a",
      "score": "42",
      "team": "Blu Chip Analytics",
      "conference": "",
      "conference_type": "",
      "sport": "Football",
      "position": "",
      "unit": "",
    },
    {
      "name": "Sam Bowen",
      "id": "a1664bf0-30a5-4977-a56a-6cd769c268e8",
      "score": "50",
      "team": "Blu Chip Analytics",
      "conference": "",
      "conference_type": "",
      "sport": "",
      "position": "",
      "unit": "",
    }
  ]


  const customPositions = [];
  const allScores = []

  teamPlayers?.forEach((list) => {
    if (selectedIndex === 0) {
      customPositions.push({
        id: list["_id"],
        name: list["full_name"],
        score: Number(list["Aggression/Violence"]),
      });
    } else if (selectedIndex === 1){
      customPositions.push({
        id: list["_id"],
        name: list["full_name"],
        score: Number(list["Delinquent Behavior"]),
      });
    } else if (selectedIndex === 2){
      customPositions.push({
        id: list["_id"],
        name: list["full_name"],
        score: Number(list["Drug/Alcohol"]),
      });
    }  else if (selectedIndex === 3){
      customPositions.push({
        id: list["_id"],
        name: list["full_name"],
        score: Number(list["Emotional Distress"]),
      });
    } else {
      customPositions.push({
        id: list["_id"],
        name: list["full_name"],
        score: Number(list["Sexual Behavior"]),
      });
    }
  }
  )


  const sample = customPositions.sort((a, b) => b.score - a.score)

  const totalScore = customPositions.reduce((a, v) => a = a + v.score, 0)

  let roundTo1PrecisionPoint = (value) => {
    return round(value, 0);
  };

  const finalScore = roundTo1PrecisionPoint(isNaN((totalScore / customPositions.length)) ? 0 : (totalScore / customPositions.length))


  const RISK_REVIEWS = {
    // "conscientiousness": {
    //   "low": "The individual is highly unorganized. He/she has limited self-control and tends to leave tasks incomplete. Individuals in this range may demonstrate impulsivity.",
    //   "mid-low": "The individual is moderately unorganized. He/she has tendency to procrastinate and be unstructured. Individuals in this range have a tendency for spontaneity.",
    //   "mid-high": "The individual is moderately organized. He/she has an inclination of preferring planning ahead and being mindful of deadlines and interim steps necessary to complete a task.",
    //   "high": "The individual is highly organized. He/she has a tendency to pay close attention to detail and is highly structured in how they conduct themselves. Individuals in this range have a tendency toward perfectionism."
    // },
    // "openness": {
    //   "low": "The individual has a very low level of creativity and sense of adventure. He/she has a high tendency to be obedient and rooted in facts, traditions, and conventional thinking.",
    //   "mid-low": "The individual has a low level of creativity and curiosity for new experiences. He/she has an inclination of accepting tradition and status quo.",
    //   "mid-high": "The individual has a moderate level of creativity and curiousity for new experiences. He/she has an inclination of having a wide range of interest and can visualize and appreciate the abstract or imagined.",
    //   "high": "The individual has a high level of creativity and curiosity for new experiences. He/she has a high prefernce for originality and learning and experiencing new things."
    // },
    // "extroversion": {
    //   "low": "The individual is a highly introverted person. He/she has a tendency to be away from social interaction and values alone time to feel at ease.",
    //   "mid-low": "The individual is a moderately introverted person. He/she has an inclination to avoid superficial social activities.",
    //   "mid-high": "The individual is a moderately extroverted person. He/she has an inclination of having the need of company to feel fulfilled in life.",
    //   "high": "The individual is a highly extroverted person. He/she has a high tendency to prefer being with people and gets energized by social interactions."
    // },
    // "agreeableness": {
    //   "low": "The individual has a tendency for being highly competitive. He/she may be perceived as being manipulative, self-serving, or uncooperative. While individuals with lower levels of agreeableness may not be the most beloved members of an organization, these traits may be adventageous for certain roles/responsibilities.",
    //   "mid-low": "The individual is moderately competitive. He/she has an inclination of choosing to put him/herself first.",
    //   "mid-high": "The individual is moderately cooperative. He/she has an inclination of choosing to help others.",
    //   "high": "The individual is highly cooperative. He/she has a high tendency of being trusting, compassionate, and helpful. While the individual may be well-liked, they may not have a focus or tendency for competition."
    // },
    // "emotional_range": {
    //   "low": "The individual has very low emotional stability. He/she tends to be irritable, aggressive, easily distressed, has difficulties managing emotions, and is sensitive to criticism.",
    //   "mid-low": "The individual has a low emotional stability. He/she has an inclination of looking at life in a negative light and experience negative moods.",
    //   "mid-high": "The individual has a moderate emotional stability. He/she has an inclination of looking at life in a positive light.",
    //   "high": "The individual is  emotional stabile. He/she may have difficulties giving and/or receiving feedback as they are often optimistic and positive in nature and calm. Being highly empathetic, they may be overwhelmed by other emotions. Individuals in this range may be highly risk-averse and demonstrate a need to be in control. Individuals in this range may have difficulties responding to and coping with challenging scenarios."
    // },
    "Emotional Distress": {
      "(1-30)": "Individuals in this range demonstrate minimal levels of emotional distress. Individuals can manage situational stressors. They are likely able to cope with a high level of stress, manage emotions effectively, and function well in situations that require adaptability. Individuals will most likely have positive social support systems, effective communication tools, and be highly self-aware.",
      "(31-60)": "Individuals in this range demonstrate some difficulties coping with moderate levels of emotional, physical, and mental stress. Individual's ability to utilize coping skills to manage emotions effectively will vary from situation to situation. Individuals may show the following reactions to stressors indicating some levels of emotional distress: difficulty sleeping, change in moods, irritability, change in dietary habits.",
      "(61-100)": "Individuals in this range have difficulty managing emotions, balancing stressors, and implementing coping skills effectively. Individuals may experience distress as an averse, negative emotional state demonstrating symptoms of mental illness (e.g. trauma, anxiety, depression)."
    },
    "Aggression/Violence": {
      "(1-30)": "Individuals in this range demonstrate minimal risk of aggression/violence. The individual demonstrates the ability to manage emotions (e.g., irritability, frustration, annoyances) and may utilize levels of anger as a motivating source for success.",
      "(31-60)": "Individuals in this range demonstrate moderate levels of aggression/violence. They may demonstrate some level of aggression and/or violence during gametime or through social media posts. Individuals in this range may utilize levels of anger as a motivating source for success but could have difficulty managing emotions, potentially resulting in a negative outcome (e.g. verbal or physical outbursts). Individuals may have had negative consequences due to aggression/violence (e.g. verbal/physical fights, suspensions).",
      "(61-100)": "Individuals in this range demonstrate high risk of aggression/violence. Individuals have difficulty managing emotions and/or tend to react with physical or verbal aggression in high-stress situations. Individuals in this range have demonstrated some verbal or physical outbursts and have experienced negative consequences/repercussions due to aggression/violence (e.g. verbal/physical fights, suspensions, arrests, assaults)."
    },
    "Drug/Alcohol": {
      "(1-30)": "Individuals in this range demonstrate minimal risky behavior with regard to drug/alcohol use. Individuals either demonstrate a range of drug/alcohol use that falls within an acceptable/normal range or do not indicate substance use per analysis. It should be noted individuals may be partaking in risky behavior but minimizing their full expression via social media/public expression.",
      "(31-60)": "Individuals in this range demonstrate a moderate level of drug/alcohol use. Individuals may be engaging in substance use and/or social engagements in which drugs, alcohol, and/or substance use/abuse is prevalent. Individuals in this range may indicate a failed organizational drug test. It should be noted individuals may be partaking in risky behavior but minimizing their full expression via social media/public expression.",
      "(61-100)": "Individuals in this range demonstrate a high risk for drug/alcohol and/or substance use. Individuals fall outside the normative range of substance use engagement. Indicators of early substance use may be prevalent. Individuals in this range may reflect an individual who has failed an organizational drug test, had legal trouble relating to drugs/alcohol/substances, or been charged with a DUI/DWI. It should be noted individuals may be partaking in risky behavior but minimizing their full expression via social media/ public expression."
    },
    "Delinquent Behavior": {
      "(1-30)": "Individuals in this range demonstrate low expression of delinquent behavior. Individuals in this range adhere to societal and cultural norms. Generally, they follow standard procedures and expectations outlined by authority figures.",
      "(31-60)": "Individuals in this range demonstrate some expression of delinquent behavior. Individuals may have difficulties following rules and procedures or have difficulties with authority figures. Individuals may demonstrate involvement or expression in one or more of the following delinquent behaviors: poor school attendance, late/missed meetings, susceptible to peer pressures, engage in other risky behaviors.",
      "(61-100)": "Individuals in this range are at risk for high levels of delinquent behaviors. Individuals may have difficulties following rules and procedures and have difficulties with authority figures. Individuals may be surrounded by high delinquent culture. Individuals may demonstrate involvement or expression in one or more of the following delinquent behaviors: poor school attendance, late/missed meetings, susceptible to peer pressures, engage in other risky behaviors. Individuals at a high risk for delinquent behavior may be involved or surrounded by illegal criminal activity. Individuals in this range are likely at risk for engagement in other risky behaviors including emotional distress, sexual behavior, aggression/violence, and/or risky behavior relating to drug/alcohol use."
    },
    "Sexual Behavior": {
      "(1-30)": "Individuals in this range demonstrate minimal sexual expression thus minimal indication of sexual behavioral risk. It should be noted individuals may be partaking in risky behavior but minimizing their expression via social media/public expression.",
      "(31-60)": "Individuals in this range indicate a level of identified sexual behavior expressed through social media accounts. Behaviors may include participation in discussions/making statements regarding sex, exploring/posting sexual content, discussing relationships, seeking a potential partner, indicating they are in a significant romantic relationship, and/or indication they are a parent. It should be noted individuals may be partaking in risky behavior but minimizing their full expression via social media/public expression.",
      "(61-100)": "Individuals in this range indicate a high level of identified sexual behavior expressed through social media accounts. Individuals in this range indicate behavior outside the normative range of sexual development. Demonstrated behaviors may include participation in discussions/making statements regarding sex, exploring/posting sexual content, using explicit sexual language, making derogatory comments toward others, discussing romantic/sexual relationships, seeking a potential partner, demonstrating a significant number of relationships, and/or indication they are a parent. Individuals in this range may also be associated with/indicated in assault and rape, hazing, and/or contracting sexually transmitted infections. It should be noted individuals may be partaking in risky behavior but minimizing their full expression via social media/ public expression."
    }

  }

  function get_text_by_score(category, score) {
    console.log("...........category, score.",category, score)
    let text = ""
    if (score == 0){
      text = "Analysis Pending.."
    }
    else if (score <= 30) {
      text = RISK_REVIEWS[category]["(1-30)"]
    }
    else if (score <= 60) {
      text = RISK_REVIEWS[category]["(31-60)"]
    }
    else if (score <= 100) {
      text = RISK_REVIEWS[category]["(61-100)"]
    }
    else  {
      text = "Risk score invalid to generate analysis text"
    }

    return text
  }


  return (
    <React.Fragment>
      <div style={{ display: "flex", justifyContent: 'center', color: '#326fc7',fontFamily: 'Prometo'}}><h3>Risk Factors</h3></div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Tabs
          labels={[
            "Aggression/Violence",
            "Delinquent Behavior",
            "Drug/Alcohol",
            "Emotional Distress",
            "Sexual Behavior",
          ]}
          value={selectedIndex}
          onChange={handleChange}
        />
        <ExpansionArticle
          title={customTrait.label}
          details={customTrait.description}
        >
          <div>
            <ChartAndDetails
              diagnosis={get_text_by_score(customTrait.type, finalScore)}//customTrait["text"]}
              // score={customTrait["score"]}
              score={isNaN((totalScore / customPositions.length)) ? 0 : (totalScore / customPositions.length)}
              handleColors={handleColors}
              tabName='Risk Factors'
            />
            <>
              <div style={{ display: 'flex' }}>
                <div>
                  <div style={{
                    margin: '1rem 0rem 1rem 1rem',
                    backgroundColor: 'rgb(0, 112, 185)',
                    fontSize: '15px',
                    padding: '12px 118px 12px 119px',
                    opacity: '0.8',
                    borderRadius: '9px',
                    fontWeight: 'bold',
                    color: '#fff',fontFamily: 'Prometo'
                  }}>
                    <span> Key Performers</span>
                  </div>
                  <div style={{
                    margin: '0rem 0rem 1rem 1rem',
                    backgroundColor: '#8ac3e8',
                    padding: '1rem 30px',
                    borderRadius: '10px'
                  }}>
                    {/* {
                      players && players.map((value) => (
                        <div style={{ display: 'flex', justifyContent: 'space-between', opacity: '1', alignItems: 'center' }}>
                          <span>
                            <span><CircleIcon style={{ height: 10 }} /></span>
                            <a style={{ textDecoration: 'underline', }} onClick={() => handleredirect(value.id)}>{value.name}</a>
                          </span>
                          <span>{value.score} %</span>
                        </div>
                      ))
                    } */}
                    {
                      customPositions && customPositions.slice(0, 3).map((value) => (
                        <div style={{ display: 'flex', justifyContent: 'space-between', opacity: '1', alignItems: 'center' }}>
                          <span>
                            <a style={{ textDecoration: 'underline', }} onClick={() => handleredirect(value.id)}>{value.name}</a>
                          </span>
                          <span>{Math.round(value.score)}%</span>
                        </div>
                      ))
                    }
                  </div>
                </div>


                <div>
                  <div style={{
                    margin: '1rem 0rem 1rem 1rem',
                    backgroundColor: 'rgb(153, 153, 153)',
                    fontSize: '15px',
                    padding: '12px 118px 12px 119px',
                    opacity: '0.8',
                    borderRadius: '9px',
                    fontWeight: 'bold',
                    color: '#fff',fontFamily: 'Prometo'
                  }}>
                    Possible Improvements
                  </div>
                  <div style={{
                    margin: '0rem 0rem 1rem 1rem',
                    backgroundColor: '#cccccc',
                    padding: '1rem 30px',
                    borderRadius: '10px'
                  }}>
                    {/* {
                      risks && risks.map((value) => (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <span>
                            <span><CircleIcon style={{ height: 10 }} /></span>
                            <a style={{ textDecoration: 'underline', }} onClick={() => handleredirect(value.id)}>{value.name}</a>
                          </span>
                          <span>{value.score} %</span>
                        </div>
                      ))
                    } */}
                    {
                      customPositions && customPositions.slice(Math.max(customPositions.length - 3, 1)).map((value) => (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <span>
                            <a style={{ textDecoration: 'underline', }} onClick={() => handleredirect(value.id)}>{value.name}</a>
                          </span>
                          <span>{Math.round(value.score)}%</span>
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
              {/* <div style={{ display : 'flex', justifyContent : 'center', margin : "2rem 0rem 2rem 0rem", fontWeight : 'bold'}}>No Team player found</div> */}



            </>

          </div>
        </ExpansionArticle>
      </div >
    </React.Fragment >
  );
};

let mapStateToProps = (state) => {

  return {
    teamPlayers: state.player.filterPlayers,
    loadingPlayerFilters: state.ui.player.loadingPlayerFilters
  };
};

// higher order component for checking payment
export default connect(mapStateToProps)(PersonalityTraits);
