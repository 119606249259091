import React, { useContext, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { ActionContext } from "../../../contexts";
import { makeStyles } from "@material-ui/core/styles";
import SportsList from "./SportsList";
import appConfig from "../../../config";
import UnitDetails from "./SportsList/UnitDetails";

let useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        display: "flex",
        height: `calc(100vh - 50px)`,
        zIndex: theme.zIndex.appBar,
    },
    container: {
        width: '100%',
        [theme.breakpoints.down("md")]: {
            maxWidth: 550,
        },
    },
    list: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
        borderRight: `1px solid ${theme.palette.divider}`,
        [theme.breakpoints.between("lg", "xl")]: {
            maxWidth: 350,
        },
    },
    details: {
        display: "flex",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(1),
    },
    detailsText: {
        alignSelf: "center",
    },
    toolbar: {
        display: "flex",
        backgroundColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
}));

function SportsMain(props) {
    let classes = useStyles();

    let { isDrawerOpen, selectedSportsPageId, selectedId, sportsData } = props;

    let actions = useContext(ActionContext);
    let { sportsActions } = actions;

    let drawerWidth = isDrawerOpen
        ? appConfig.drawer.maxWidth
        : appConfig.drawer.minWidth;


    useEffect(() => {
        sportsActions.getSportsList();

    }, []);

    return (
        <React.Fragment>
            <div className={classes.root} style={{ paddingLeft: '3rem' }}>
                <div className={classes.container}>
                    {
                        selectedSportsPageId === "list" ?
                            <SportsList />
                            :
                            <UnitDetails />
                    }
                </div>

            </div>
        </React.Fragment>
    )
}

let mapStateToProps = (state) => {
    return {
        isDrawerOpen: state.ui.app.isDrawerOpen,
        loadingUnitSport: state.ui.sports.loadingUnitSport,
        unitSportData: state.sports.unitSport,
        selectedId: state.ui.sports.selectedSportId,
        sportsData: state.sports.sportsListData,
        selectedSportsPageId: state.ui.sports.selectedSportsPageId,
    };
};
export default connect(mapStateToProps)(SportsMain);