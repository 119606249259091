import { combineReducers } from "redux";
import * as types from "./conferenceType";

const loadingStats = (state = false, action) => {
  switch (action.type) {
    case types.REQUEST_GET_CONFERENCES: {
      return true;
    }

    case types.SET_CONFERENCES_SUCCESS:
    case types.GET_CONFERENCES_FAILED: {
      return false;
    }

    default:
      return state;
  }
};

const searchKey = (state = "", action) => {
  switch (action.type) {
    case types.SEARCH_CONFERENCE: {
      return action.payload.key;
    }

    case types.RESET_SEARCH: {
      return "";
    }

    default:
      return state;
  }
};

const selectedConferenceId = (state = null, action) => {
  switch (action.type) {
    case types.SELECT_CONFERENCE: {
      return action.payload.conferenceId;
    }

    case types.DESELECT_CONFERENCE: {
      return null;
    }

    default:
      return state;
  }
};

const loadingTeamByConference = (state = {}, action) => {
  const { payload } = action;


  switch (action.type) {
    case types.GET_TEAMS_BY_CONFERENCE_ID: {
      const { conferenceId } = payload;
      return true;
    }

    case types.GET_TEAMS_BY_CONFERENCE_ID_SUCCESS:
    case types.GET_TEAMS_BY_CONFERENCE_ID_FAILED: {
      const { conferenceId } = payload;
      return  false
    }

    default:
      return state;
  }
};

const loadingSportsByConference = (state = {}, action) => {
  const { payload } = action;


  switch (action.type) {
    case types.GET_SPORTS_BY_CONFERENCE_ID: {
      const { conferenceId } = payload;
      return true;
    }

    case types.GET_SPORTS_BY_CONFERENCE_ID_SUCCESS:
    case types.GET_SPORTS_BY_CONFERENCE_ID_FAILED: {
      const { conferenceId } = payload;
      return  false
    }

    default:
      return state;
  }
};

const createConferenceInitialState = {
  loading: false,
  error: null,
};

const createConferenceReducer = (state = createConferenceInitialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.CREATE_NEW_CONFERENCE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case types.CREATE_NEW_CONFERENCE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case types.CREATE_NEW_CONFERENCE_FAILED: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }

    case types.RESET_CREATE_NEW_CONFERENCE_ERROR: {
      return {
        ...state,
        error: null,
      };
    }

    default:
      return state;
  }
};

const deleteConferenceReducer = (state = createConferenceInitialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case types.DELETE_CONFERENCE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case types.DELETE_CONFERENCE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case types.DELETE_CONFERENCE_FAILED: {
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    }

    default:
      return state;
  }
};

const selectedConferencePageId = (state = 'list', action) => {
  switch (action.type) {
    
    case types.SELECTED_PAGE_TYPE: {
      return action.payload;
    }

    default:
      return state;
  }
};

const selectedConferenceTypeId = (state = null, action) => {
  switch (action.type) {
    
    case types.SELECTED_CONFERENCE_TYPE: {
      return action.payload;
    }

    default:
      return state;
  }
};

const loadingCreateTeam = (state = false, action) => {
  switch (action.type) {
    case types.CREATE_NEW_TEAM: {
      return true
    }

    case types.CREATE_NEW_TEAM_SUCCESS: {
      return false
    }
    case types.CREATE_NEW_TEAM_FAILED: {
      return false
    }

    default:
      return state
  }

}

export default combineReducers({
  loadingStats,
  searchKey,
  selectedConferenceId,
  loadingTeamByConference,
  createConferenceReducer,
  deleteConferenceReducer,
  selectedConferencePageId,
  selectedConferenceTypeId,
  loadingSportsByConference,
  loadingCreateTeam
});
