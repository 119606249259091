export const eachPlayer = {

    "0446e560-5a37-4d33-8bf9-7485d36622d0": {
        "_id": "0446e560-5a37-4d33-8bf9-7485d36622d0",
        "analysed_on": "11/19/2020, 10:20:14",
        "conference": "NFL",
        "conference_type": "Professional",
        "consent": "Received",
        "consent_form_id": "78b50f41-0c57-4746-a402-76293d42e736",
        "created_on": "08/10/2020, 13:39:37",
        "dob": "9/30/98",
        "email": "info@bluchipanalytics.com",
        "formatted_record": {
            "dob": "9/30/98",
            "email": "info@bluchipanalytics.com",
            "full_name": "Luke Coutinho",
            "hometown": "Miami, FL",
            "instagram": "luke_coutinho",
            "linkedin": "",
            "notes": {

            },
            "race": "Black",
            "relationship_status": "",
            "school": "Florida",
            "team": "Blu Chip Analytics",
            "twitter": "luke_coutinho",
            "whatsapp": ""
        },
        "full_name": "Luke Coutinho",
        "hometown": "Miami, FL",
        "image_url": "http://127.0.0.1:3434/export/default_profile_pic",
        "insights": {
            "action_plan": {
                "Connection is Key": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Connection is Key",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Establish the Environment": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Establish the Environment",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Mental Mindset": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Mental Mindset",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                }
            },
            "custom_traits": {
                "character": {
                    "description": "An individual\u2019s emotional and mental qualities based on their morals, virtues, and humility that aid in executing tasks.",
                    "label": "Character",
                    "score": 36.596562500000005,
                    "sub": {
                        "hard_working": {
                            "left_label": "Hard-Working",
                            "right_label": "Lazy",
                            "score": 38.989875,
                            "text": ""
                        },
                        "high_integrity": {
                            "left_label": "High Integrity",
                            "right_label": "Low Integrity",
                            "score": 39.79575,
                            "text": ""
                        },
                        "humble": {
                            "left_label": "Humility",
                            "right_label": "Arrogant",
                            "score": 31.0040625,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 0-30% demonstrate a minimal level of character. Individuals may lack drive and are lazy in their efforts to meet performance outcomes. Individuals may have low integrity, question or challenge authority, break team rules/norms. Individuals low in character may be arrogant and lack humility."
                },
                "coachability": {
                    "description": "An individual\u2019s ability to listen, learn, be open to feedback, manage criticism, adapt their skill set, and perform at their optimal level.",
                    "label": "Coachability",
                    "score": 44.279296875,
                    "sub": {
                        "dependable": {
                            "left_label": "Dependable",
                            "right_label": "Unreliable",
                            "score": 41.921718750000004,
                            "text": ""
                        },
                        "willingness_to_learn ": {
                            "left_label": "Willingness to Learn",
                            "right_label": "Closed Minded",
                            "score": 46.636874999999996,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of coachability. Individuals may be moderately reliable, and adhere to meeting times, complete tasks, and perform as expected at times. Some may tend to be open to feedback, but could demonstrate difficulties managing criticism, or have difficulties adapting behavioral change to specific coaching feedback. Individuals may take time to learn and adjust performance goals."
                },
                "composure": {
                    "description": "An individual\u2019s ability to remain calm, persistent, and in control under pressure situations or after mistakes. Individuals\u2019 thrive in high pressure situations and demonstrate enhanced performance outcomes.",
                    "label": "Composure",
                    "score": 46.480453125,
                    "sub": {
                        "calm": {
                            "left_label": "Calm",
                            "right_label": "Susceptible to Stress",
                            "score": 48.80765625,
                            "text": ""
                        },
                        "driven": {
                            "left_label": "Driven",
                            "right_label": "Unambitious",
                            "score": 44.15325,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of composure. Individuals may have the capacity to remain calm and manage stressful situations to a degree. They have a moderate drive and purpose to strive for. In high pressure situations they may react and have difficulties managing their emotions on a consistent basis."
                },
                "leadership": {
                    "description": "An individual\u2019s ability to influence, inspire, and communicate a common goal to others.",
                    "label": "Leadership",
                    "score": 57.471000000000004,
                    "sub": {
                        "accountable": {
                            "left_label": "Accountable",
                            "right_label": "Unaccountable",
                            "score": 43.14375,
                            "text": ""
                        },
                        "confident": {
                            "left_label": "Confident",
                            "right_label": "Insecure",
                            "score": 53.533500000000004,
                            "text": ""
                        },
                        "vocal": {
                            "left_label": "Vocal",
                            "right_label": "Unspoken",
                            "score": 75.73575,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of leadership capacity. Individuals may work hard toward goals and can inspire small groups or a few individuals. Individuals may lack the ability to be vocal and speak to larger members to inspire a large group to meet cultural goals. Individuals may have the capacity to make small changes and influence members around them. Individuals may have moderate level of confidence, but poor performances or influence may negatively impact self-worth and ability to perform at optimal level."
                }
            },
            "executive_summary": {
                "Significant Background Information": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Significant Background Information",
                            "type": "header-three"
                        },
                        {
                            "data": {
                                "text-align": "start"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "family oriented",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "4egno",
                            "text": "reserved player, hard working",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "d3ogm",
                            "text": "Florida is his comfort zone, close to family, hometown, college",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Symptoms and Information Captured": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Symptoms and Information Captured",
                            "type": "header-three"
                        },
                        {
                            "data": {
                                "text-align": "start"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 15,
                                    "offset": 0,
                                    "style": "color-rgba(0,0,0,0.87)"
                                },
                                {
                                    "length": 15,
                                    "offset": 0,
                                    "style": "bgcolor-rgb(255,255,255)"
                                },
                                {
                                    "length": 15,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                },
                                {
                                    "length": 15,
                                    "offset": 0,
                                    "style": "fontfamily-Roboto"
                                },
                                {
                                    "length": 15,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "5ssk7",
                            "text": "Overall Risk: 4",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "text-align": "start"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 198,
                                    "offset": 0,
                                    "style": "color-rgba(0,0,0,0.87)"
                                },
                                {
                                    "length": 198,
                                    "offset": 0,
                                    "style": "bgcolor-rgb(255,255,255)"
                                },
                                {
                                    "length": 198,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                },
                                {
                                    "length": 198,
                                    "offset": 0,
                                    "style": "fontfamily-Roboto"
                                },
                                {
                                    "length": 11,
                                    "offset": 0,
                                    "style": "color-rgb(0,0,0)"
                                },
                                {
                                    "length": 11,
                                    "offset": 0,
                                    "style": "fontfamily-Calibri, sans-serif"
                                },
                                {
                                    "length": 11,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "7cuup",
                            "text": "Key Traits: moderatly low Character traits, coachability, leadership, and composure. Partly due to his competitive nature, his outwardly reserved appearance, and mindset to focus on his task at hand",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "text-align": "start"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 170,
                                    "offset": 0,
                                    "style": "color-rgba(0,0,0,0.87)"
                                },
                                {
                                    "length": 170,
                                    "offset": 0,
                                    "style": "bgcolor-rgb(255,255,255)"
                                },
                                {
                                    "length": 170,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                },
                                {
                                    "length": 170,
                                    "offset": 0,
                                    "style": "fontfamily-Roboto"
                                },
                                {
                                    "length": 10,
                                    "offset": 0,
                                    "style": "color-rgb(0,0,0)"
                                },
                                {
                                    "length": 10,
                                    "offset": 0,
                                    "style": "fontfamily-Calibri, sans-serif"
                                },
                                {
                                    "length": 10,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "3hj38",
                            "text": "Strengths: Confident, driven individual, rule following, adheres to structure and organized plans, will be dilignet and organized, creativity, and outside the box thinker",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "text-align": "start"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 282,
                                    "offset": 0,
                                    "style": "color-rgba(0,0,0,0.87)"
                                },
                                {
                                    "length": 282,
                                    "offset": 0,
                                    "style": "bgcolor-rgb(255,255,255)"
                                },
                                {
                                    "length": 282,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                },
                                {
                                    "length": 282,
                                    "offset": 0,
                                    "style": "fontfamily-Roboto"
                                },
                                {
                                    "length": 282,
                                    "offset": 0,
                                    "style": "color-rgb(0,0,0)"
                                },
                                {
                                    "length": 152,
                                    "offset": 0,
                                    "style": "fontfamily-Calibri, sans-serif"
                                },
                                {
                                    "length": 16,
                                    "offset": 0,
                                    "style": "BOLD"
                                },
                                {
                                    "length": 130,
                                    "offset": 152,
                                    "style": "fontfamily-Poppins"
                                }
                            ],
                            "key": "74hve",
                            "text": "Areas of Growth: building core relationships, trusting others, managing emotions, facilitate grounding to assist in positive decisive decision making, tunes into environment and stress levels, may lose focus, get caught up in emotional turmoil. Could benefit from core coping skills ",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "text-align": "start"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 321,
                                    "offset": 0,
                                    "style": "color-rgba(0,0,0,0.87)"
                                },
                                {
                                    "length": 321,
                                    "offset": 0,
                                    "style": "bgcolor-rgb(255,255,255)"
                                },
                                {
                                    "length": 321,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                },
                                {
                                    "length": 321,
                                    "offset": 0,
                                    "style": "fontfamily-Roboto"
                                },
                                {
                                    "length": 15,
                                    "offset": 0,
                                    "style": "color-rgb(0,0,0)"
                                },
                                {
                                    "length": 15,
                                    "offset": 0,
                                    "style": "fontfamily-Calibri, sans-serif"
                                },
                                {
                                    "length": 15,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "9g4vp",
                            "text": "Risks/Concerns: may be selfish, focus on personal needs over others, anger and reactivity may fuel his performance, but not always demonstrated outwardly. may be slow to warm up, guarded, connect to others, may focus on negative and  impact focus and performances. May be reactive, impuslive, and limited trust in others.",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "text-align": "start"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 219,
                                    "offset": 0,
                                    "style": "color-rgba(0,0,0,0.87)"
                                },
                                {
                                    "length": 219,
                                    "offset": 0,
                                    "style": "bgcolor-rgb(255,255,255)"
                                },
                                {
                                    "length": 219,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                },
                                {
                                    "length": 219,
                                    "offset": 0,
                                    "style": "fontfamily-Roboto"
                                },
                                {
                                    "length": 18,
                                    "offset": 0,
                                    "style": "color-rgb(0,0,0)"
                                },
                                {
                                    "length": 18,
                                    "offset": 0,
                                    "style": "fontfamily-Calibri, sans-serif"
                                },
                                {
                                    "length": 17,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "1o0dt",
                            "text": "Themes from Posts: emotional broadcaster, range of emotions, anger, positivity, motivation, out to prove others, range of emotions displayed in emoji use, grief, loss, broken heart, wishing he went or was somewhere else",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 1,
                                    "offset": 35,
                                    "style": "fontfamily-Segoe UI Emoji\", sans-serif"
                                }
                            ],
                            "key": "e848a",
                            "text": " Regular niggas make regular moves \u2757\ufe0f  ",
                            "type": "unordered-list-item"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 1,
                                    "offset": 44,
                                    "style": "fontfamily-Segoe UI Emoji\", sans-serif"
                                }
                            ],
                            "key": "ekgtr",
                            "text": " Say that it\u2019s love out they mouth but that \ud83d\udca9\nbe fake..  ",
                            "type": "unordered-list-item"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 1,
                                    "offset": 14,
                                    "style": "fontfamily-Segoe UI Emoji\", sans-serif"
                                }
                            ],
                            "key": "9mslu",
                            "text": " Misunderstood\ud83d\udc7d  ",
                            "type": "unordered-list-item"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "crlvg",
                            "text": "",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                }
            },
            "personality traits": {
                "agreeableness": {
                    "score": 20.4075,
                    "sub": {
                        "altruism": {
                            "score": 21.0225,
                            "text": "The individual is highly self-focused. He/she has a high tendency to work for his/her own well-being. The individual has a tendency to help others only when he/she also stands to benefit."
                        },
                        "cooperation": {
                            "score": 15.12,
                            "text": "The individual is highly confrontational. He/she has a high tendency to challenge or intimidate others to get what he/she wants."
                        },
                        "modesty": {
                            "score": 20.025,
                            "text": "The individual is highly comfortable with attention. He/she has a tendency to need to be the center of attention and welcomes, or may even seek, situations in which he/she is the center of attention. Individuals may be perceived as arrogant. "
                        },
                        "sympathy": {
                            "score": 32.9025,
                            "text": "The individual tends not to show compassion toward others. He/she has an inclination not to help or feel for others."
                        },
                        "trust": {
                            "score": 15.57,
                            "text": "The individual is a very cautious person. He/she tends to believe people are inherently bad, assumes others are scheming, and approaches people with skepticim and caution. "
                        },
                        "uncompromising": {
                            "score": 17.7975,
                            "text": "The individual is not beholden to his/her own moral standards. He/she has a high tendency to bend or break his/her own moral standards for personal gain. This does not mean this individual is amoral per se, but does reflect a tendency to be guarded with regard to the truth. "
                        }
                    },
                    "text": "Agreeableness refers to an individual's general congeniality and ability to put other people's needs before their own."
                },
                "conscientiousness": {
                    "score": 35.205,
                    "sub": {
                        "achievement_striving": {
                            "score": 18.802500000000002,
                            "text": "The individual is content with a 'good enough' attitude. Individuals in this range will not overexert themselves to accompish a goal. He/she is less likely to have or pursue higher goals."
                        },
                        "cautiousness": {
                            "score": 17.31,
                            "text": "The individual favors spontaneity with limited consideration of the consequences before acting. This person has a tendency to make decisions quickly and spends little time worring if it was the right/wrong decision to make. "
                        },
                        "dutifulness": {
                            "score": 15.21,
                            "text": "The individual is highly carefree. He/she has a high tendency to prefer disregarding duties and obligations. These individuals may not see prior commitments as binding."
                        },
                        "orderliness": {
                            "score": 72.63749999999999,
                            "text": "The individual is moderately organized. He/she has an inclination of needing to strucutre and plan events and surroundings. "
                        },
                        "self_discipline": {
                            "score": 27.6,
                            "text": "The individual may have an inclination give up in the face of difficulty or when he/she does not see the value of the task at hand. "
                        },
                        "self_efficacy": {
                            "score": 59.64,
                            "text": "The individual is a moderately self-assured person. He/she has an inclination on putting faith in his/her abilities and skills."
                        }
                    },
                    "text": "Conscientiousness refers to an Indvidual's propensity for orderliness and a need to plan. "
                },
                "emotional_range": {
                    "score": 53.535,
                    "sub": {
                        "calm_in_pressure": {
                            "score": 56.400000000000006,
                            "text": "The individual is moderately prone to stress. He/she has an inclination to get exited and anxious in stressful situations."
                        },
                        "composure": {
                            "score": 17.4375,
                            "text": "The individual is a highly even-tempered person. He/she has a high tendency to be calm and with a level head and less likely to get angry."
                        },
                        "contentment": {
                            "score": 48.4575,
                            "text": "The individual is a moderately contented person. He/she has an inclination of having a positive outlook on life."
                        },
                        "self_assuredness": {
                            "score": 46.83,
                            "text": "The individual is moderately calm. He/she has an inclination of being self-assured and positive."
                        },
                        "self_confidence": {
                            "score": 76.58250000000001,
                            "text": "The individual is a highly self-conscious person. He/she has a high tendency to be strongly affected (mostly negatively) by others perception of one self."
                        },
                        "self_control": {
                            "score": 75.49499999999999,
                            "text": "The individual is moderately self-indulgent. He/she has an inclination of being easily tempted."
                        }
                    },
                    "text": "Emotional Stability refers to how an an individual tends to respond to his/her environment or experience a negative state of mind. "
                },
                "extroversion": {
                    "score": 74.76,
                    "sub": {
                        "activity_level": {
                            "score": 46.245,
                            "text": "The individual moderately prefers to take fewer tasks at a slower pace and allow for deeper focus. "
                        },
                        "assertiveness": {
                            "score": 73.10249999999999,
                            "text": "The individual is a moderately assertive person. He/she has an inclination of speaking up in group environments and wil take charge of situations/events. "
                        },
                        "cheerfulness": {
                            "score": 85.4025,
                            "text": "The individual displayes feelings of positivity, joy, enthusiasm, and optimism outwardly. "
                        },
                        "excitement_seeking": {
                            "score": 79.3725,
                            "text": "The individual has a strong preference for excitement. The individual may be so driven for excitement they may seek people and experiences that are risky simply to avoid boredom. "
                        },
                        "gregariousness": {
                            "score": 84.52499999999999,
                            "text": "The individual is highly sociable. He/she has a strong preference/tendency to enjoy large social gatherings regularly. "
                        },
                        "outgoing": {
                            "score": 79.905,
                            "text": "The individual is a highly outgoing person. He/she has a high tendency of being friendly toward new people and has a preference for being around others. "
                        }
                    },
                    "text": "Extroversion refers to an individual's tendency to seek stimulation via the company of others. Extroversion/Introversion does not imply interest in others; it refers to the energy one feels as a result of interactions with others."
                },
                "openness": {
                    "score": 32.9475,
                    "sub": {
                        "adventurousness": {
                            "score": 40.7475,
                            "text": "The individual has a low level of creativity and curiosity for new experiences. He/she has an inclination of accepting tradition and status quo."
                        },
                        "artistic_interests": {
                            "score": 26.85,
                            "text": "The individual has a low level of enthusiasm about creativity or seeking beautiful experiences. He/she has an inclination of being indifferent about creative or beautiful experiences."
                        },
                        "authority_challenging": {
                            "score": 18.03,
                            "text": "The individual is complient, traditional, and obedient. He/she has a high tendency to obey authority, follow rules, and be closed to 'unconventional' lifesyles and values/beliefs."
                        },
                        "emotionality": {
                            "score": 23.3925,
                            "text": "The individual demonstrates limited emotional awareness. He/she has a high tendency of being guarded about his/her feelings and is very unlikely to openly express such feelings. With limited emotional awareness, the individual may regard feelings as irrelevant or inconvenient. "
                        },
                        "imagination": {
                            "score": 72.3375,
                            "text": "The individual is a moderately imaginative person. He/she has an inclination of being comfortable with letting his/her imagination do the thinking while still keeping an eye on the facts. "
                        },
                        "intellect": {
                            "score": 16.35,
                            "text": "The individual is highly practical. He/she has a high tendency of accepting facts with limited-to-no appreciation for theory. "
                        }
                    },
                    "text": "Openness refers to an individual's general willingness to engage in new experiences and learn new things. Individuals that score high in Openness are more imaginative and creative. Those low in Openness tend to be more analytical and resistant to change and prefer tradition. "
                }
            },
            "psychological disorders": {
                "antisocial": {
                    "score": 49.3943979107093,
                    "text": "A moderate possibility of behaving irresponsibly and show disregard for normal social behaviour."
                },
                "avoidant": {
                    "score": 26.489747603178063,
                    "text": "Possibility of existing level of Avoidant Personality Disorder is very low or none."
                },
                "borderline": {
                    "score": 39.988399855441685,
                    "text": "A moderate possibility of showing self harming behaviour and having a distorted and unstable self-image or sense of self."
                },
                "dependent": {
                    "score": 31.333263063411092,
                    "text": "Possibility of existing level of Dependent Personality Disorder is very low or none."
                },
                "histrionic": {
                    "score": 83.79408744434888,
                    "text": "A considerable possibility of individual being self-centered and with less or no concern for others, rash decision making, constantly seeking approval and tendency to act with exaggerated actions and emotions. A closer look at the individual is recommended."
                },
                "narcissistic": {
                    "score": 64.65123915828883,
                    "text": "A moderate possibility of believing that he or she is special and unique while expecting admiration from others."
                },
                "obsessive_compulsive": {
                    "score": 57.0553473581235,
                    "text": "A moderate possibility of the individual showing compulsive and obsessive behaviours in some areas of his/her life."
                },
                "paranoid": {
                    "score": 41.82834384033876,
                    "text": "A considerable possibility of individual being reluctant to confide in others and suspects others of being exploitative or deceitful."
                },
                "schizotypal": {
                    "score": 38.38413776493836,
                    "text": "A moderate possibility of having cognitive or perceptual distortions as well as eccentricities in everyday behavior."
                }
            },
            "risk": {
                "Aggression/Violence": {
                    "comments": [

                    ],
                    "score": 10,
                    "text": "Individuals low (1-3) in this range demonstrates minimal risk in aggression/violence. The individual demonstrates the ability to manage emotions (e.g. irritability, frustration, annoyances) and may utilize levels of anger as a motivating source for success."
                },
                "Delinquent Behavior": {
                    "comments": [

                    ],
                    "score": 30,
                    "text": "Individuals low (1-3) in this range demonstrate low expression of delinquent behavior. Individuals in this range adhere to societal, cultural norms. Generally, following standard procedures and expectations from authority figures. "
                },
                "Drug/Alcohol": {
                    "comments": [

                    ],
                    "score": 20,
                    "text": "Individuals low (1-3) in this range demonstrate minimal risky behavior in drug/alcohol use. Individuals either demonstrate normative minimal engagement in acceptable use that falls within normative range or does not indicate substance use per analysis. Should be noted individuals may be partaking in higher risk behavior but minimizing their full expression via social media/ public expression"
                },
                "Emotional Distress": {
                    "comments": [

                    ],
                    "score": 10,
                    "text": "Individuals low (1-3) in this range demonstrate minimal levels of emotional distress. Individuals can manage situational stressors. They are likely able to cope with a high stress, manage emotions effectively, and function in high adaptable scenarios. Individuals will most likely have positive social support, effective communication tools, and high awareness in one\u2019s self."
                },
                "Overall": {
                    "comments": [

                    ],
                    "score": 18.0,
                    "text": "Risk score invalid to generate analysis text"
                },
                "Sexual Behavior": {
                    "comments": [

                    ],
                    "score": 20,
                    "text": "Individuals low (1-3) in this range demonstrate minimal sexual expression, minimal indication of sexual behavioral risk. Should be noted individuals may be partaking in risky behavior but minimizing their expression via social media/public expression."
                }
            }
        },
        "instagram": "luke_coutinho",
        "is_paid": true,
        "is_valid_format": true,
        "linkedin": "",
        "links": [
            {
                "category": "youtube",
                "link_id": "08a2a551-9bbc-43b7-8f22-ecdf245b2b02",
                "url": "https://www.youtube.com/watch?v=H0krChRh-9s"
            },
            {
                "category": "youtube",
                "link_id": "1c827cce-f119-4259-b43f-55d77f0fdb46",
                "url": "https://www.youtube.com/watch?v=WuPxUOH2E9U"
            }
        ],
        "message": {
            "button_label": "",
            "content": "",
            "severity": "info",
            "show": false,
            "title": ""
        },
        "possible_duplicate_info": {
            "duplicate_candidate": {

            },
            "duplicate_candidate_id": "",
            "is_possible_duplicate": false
        },
        "race": "Black",
        "relationship_status": "",
        "report_settings": {
            "_id": "0446e560-5a37-4d33-8bf9-7485d36622d0",
            "action_plan": {
                "allowed": true
            },
            "executive_summary": {
                "allowed": true
            },
            "full_report": {
                "allowed": true
            }
        },
        "reports": {
            "action_plan_report": false,
            "executive_summary_report": false,
            "full_report": false
        },
        "reviewed_on": "11/21/2020, 20:58:20",
        "risk": "low",
        "school": "Florida",
        "sport": "Football",
        "stats": {
            "instagram": {
                "negative_percentage": 18.18,
                "neutral_percentage": 27.27,
                "positive_percentage": 54.55,
                "total": 5613,
                "total_negative": 2,
                "total_neutral": 3,
                "total_positive": 6
            },
            "negative_percentage": 19.7,
            "neutral_percentage": 26.83,
            "positive_percentage": 53.47,
            "total": 5624,
            "total_negative": 1108,
            "total_neutral": 1509,
            "total_positive": 3007,
            "tweets": {
                "negative_percentage": 19.7,
                "neutral_percentage": 26.83,
                "positive_percentage": 53.47,
                "total": 5613,
                "total_negative": 1106,
                "total_neutral": 1506,
                "total_positive": 3001
            },
            "videos": {
                "total": 2
            }
        },
        "status": "Completed",
        "team": "Blu Chip Analytics",
        "twitter": "luke_coutinho",
        "whatsapp": ""
    },
    "0a17814d-b3ec-4a4e-ac05-bfeae9e890f5": {
        "_id": "0a17814d-b3ec-4a4e-ac05-bfeae9e890f5",
        "conference": "NFL",
        "conference_type": "Professional",
        "consent": "Received",
        "consent_form_id": "6e6cb15b-d04b-4faf-a1d8-34f0282a97b1",
        "created_on": "03/17/2021, 20:21:19",
        "dob": "1/22/00",
        "email": "info@bluchipanalytics.com",
        "full_name": "John Doe",
        "hometown": "Fairfield, OH",
        "image_url": "http://127.0.0.1:3434/export/default_profile_pic",
        "insights": {
            "action_plan": {
                "Connection is Key": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Connection is Key",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Establish the Environment": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Establish the Environment",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Mental Mindset": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Mental Mindset",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                }
            },
            "custom_traits": {
                "character": {
                    "description": "An individual\u2019s emotional and mental qualities based on their morals, virtues, and humility that aid in executing tasks.",
                    "label": "Character",
                    "score": 53.39,
                    "sub": {
                        "hard_working": {
                            "left_label": "Hard-Working",
                            "right_label": "Lazy",
                            "score": 56.611875000000005,
                            "text": ""
                        },
                        "high_integrity": {
                            "left_label": "High Integrity",
                            "right_label": "Low Integrity",
                            "score": 56.53125,
                            "text": ""
                        },
                        "humble": {
                            "left_label": "Humility",
                            "right_label": "Arrogant",
                            "score": 47.02687499999999,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of character. Individuals may work hard in certain performance domains. They may challenge others and express some levels of gratitude, humility, and integrity. Individuals may not be consistent in their ability to meet expectations, respect, and respond in positive manner in all situations."
                },
                "coachability": {
                    "description": "An individual\u2019s ability to listen, learn, be open to feedback, manage criticism, adapt their skill set, and perform at their optimal level.",
                    "label": "Coachability",
                    "score": 56.2209375,
                    "sub": {
                        "dependable": {
                            "left_label": "Dependable",
                            "right_label": "Unreliable",
                            "score": 55.040625,
                            "text": ""
                        },
                        "willingness_to_learn ": {
                            "left_label": "Willingness to Learn",
                            "right_label": "Closed Minded",
                            "score": 57.40125,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of coachability. Individuals may be moderately reliable, and adhere to meeting times, complete tasks, and perform as expected at times. Some may tend to be open to feedback, but could demonstrate difficulties managing criticism, or have difficulties adapting behavioral change to specific coaching feedback. Individuals may take time to learn and adjust performance goals."
                },
                "composure": {
                    "description": "An individual\u2019s ability to remain calm, persistent, and in control under pressure situations or after mistakes. Individuals\u2019 thrive in high pressure situations and demonstrate enhanced performance outcomes.",
                    "label": "Composure",
                    "score": 58.542,
                    "sub": {
                        "calm": {
                            "left_label": "Calm",
                            "right_label": "Susceptible to Stress",
                            "score": 61.45125,
                            "text": ""
                        },
                        "driven": {
                            "left_label": "Driven",
                            "right_label": "Unambitious",
                            "score": 55.63275,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of composure. Individuals may have the capacity to remain calm and manage stressful situations to a degree. They have a moderate drive and purpose to strive for. In high pressure situations they may react and have difficulties managing their emotions on a consistent basis."
                },
                "leadership": {
                    "description": "An individual\u2019s ability to influence, inspire, and communicate a common goal to others.",
                    "label": "Leadership",
                    "score": 61.85775,
                    "sub": {
                        "accountable": {
                            "left_label": "Accountable",
                            "right_label": "Unaccountable",
                            "score": 56.193749999999994,
                            "text": ""
                        },
                        "confident": {
                            "left_label": "Confident",
                            "right_label": "Insecure",
                            "score": 62.42325000000001,
                            "text": ""
                        },
                        "vocal": {
                            "left_label": "Vocal",
                            "right_label": "Unspoken",
                            "score": 66.95625000000001,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of leadership capacity. Individuals may work hard toward goals and can inspire small groups or a few individuals. Individuals may lack the ability to be vocal and speak to larger members to inspire a large group to meet cultural goals. Individuals may have the capacity to make small changes and influence members around them. Individuals may have moderate level of confidence, but poor performances or influence may negatively impact self-worth and ability to perform at optimal level."
                }
            },
            "executive_summary": {
                "Significant Background Information": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Significant Background Information",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Lost childhood coach in November 2020. Honors him in instagram bio.",
                            "type": "unordered-list-item"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "6cgn4",
                            "text": "Majored in Sociology at Clemson University",
                            "type": "unordered-list-item"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "4jvkv",
                            "text": "Experienced some issues with his racial identity, as his father is white and is mother is black. He grew up in low-income neighborhoods, then moved to the suburbs where he experienced even more internal conflict surrounding his identity. Refers to himself as \"a bridge between people\" in regard to his mixed-race identity.",
                            "type": "unordered-list-item"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "3dctj",
                            "text": "Likes food and loves to cook. His first word was \"eat\"",
                            "type": "unordered-list-item"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "bto1p",
                            "text": "Parents divorced when he was 7",
                            "type": "unordered-list-item"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Symptoms and Information Captured": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Symptoms and Information Captured",
                            "type": "header-three"
                        },
                        {
                            "data": {
                                "text-align": "start"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Overall Risk: 3",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 385,
                                    "offset": 0,
                                    "style": "color-rgb(0,0,0)"
                                },
                                {
                                    "length": 385,
                                    "offset": 0,
                                    "style": "bgcolor-white"
                                },
                                {
                                    "length": 385,
                                    "offset": 0,
                                    "style": "fontsize-medium"
                                },
                                {
                                    "length": 385,
                                    "offset": 0,
                                    "style": "fontfamily-Calibri, sans-serif"
                                },
                                {
                                    "length": 385,
                                    "offset": 0,
                                    "style": "color-black"
                                },
                                {
                                    "length": 385,
                                    "offset": 0,
                                    "style": "fontsize-10pt"
                                },
                                {
                                    "length": 385,
                                    "offset": 0,
                                    "style": "fontfamily-Tahoma, sans-serif"
                                },
                                {
                                    "length": 11,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "esc7e",
                            "text": "Key Traits:\u00a0Has a moderate level of composure comprised of his ability to be calm and driven in high-pressure situations. Demonstrates a highly moderate level of leadership, especially through his confidence and ability to communicate vocally. Appears to possess a high level of emotional stability and moderate levels of agreeableness, conscientiousness, extroversion, and creativity.",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "text-align": "start"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 399,
                                    "offset": 0,
                                    "style": "color-rgb(0,0,0)"
                                },
                                {
                                    "length": 399,
                                    "offset": 0,
                                    "style": "bgcolor-white"
                                },
                                {
                                    "length": 399,
                                    "offset": 0,
                                    "style": "fontsize-medium"
                                },
                                {
                                    "length": 399,
                                    "offset": 0,
                                    "style": "fontfamily-Calibri, sans-serif"
                                },
                                {
                                    "length": 399,
                                    "offset": 0,
                                    "style": "color-black"
                                },
                                {
                                    "length": 399,
                                    "offset": 0,
                                    "style": "fontsize-10pt"
                                },
                                {
                                    "length": 399,
                                    "offset": 0,
                                    "style": "fontfamily-Tahoma, sans-serif"
                                },
                                {
                                    "length": 11,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "6a4s6",
                            "text": "Strengths:\u00a0Appears to be open to new ideas and tends to explore what could be. Appears to be inclined to speak up in group environments. Appears to demonstrate a high level of emotional stability in being calm, positive, and emotionally well-balanced. Appears to work through challenges without quitting. Prefers to plan ahead and be organized. May choose to pause and think before making decisions.",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "text-align": "start"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 272,
                                    "offset": 0,
                                    "style": "color-rgb(0,0,0)"
                                },
                                {
                                    "length": 272,
                                    "offset": 0,
                                    "style": "bgcolor-white"
                                },
                                {
                                    "length": 272,
                                    "offset": 0,
                                    "style": "fontsize-medium"
                                },
                                {
                                    "length": 272,
                                    "offset": 0,
                                    "style": "fontfamily-Calibri, sans-serif"
                                },
                                {
                                    "length": 272,
                                    "offset": 0,
                                    "style": "color-black"
                                },
                                {
                                    "length": 272,
                                    "offset": 0,
                                    "style": "fontsize-10pt"
                                },
                                {
                                    "length": 272,
                                    "offset": 0,
                                    "style": "fontfamily-Tahoma, sans-serif"
                                },
                                {
                                    "length": 17,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "1q4sk",
                            "text": "Areas for Growth:\u00a0May be mistrusting of the motives of those around him, which could make it difficult for him to create relationships. May be unaware or may be distant of his own feelings, so building awareness and a working relationship with his feelings may be helpful.",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "text-align": "start"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 212,
                                    "offset": 0,
                                    "style": "color-rgb(0,0,0)"
                                },
                                {
                                    "length": 212,
                                    "offset": 0,
                                    "style": "bgcolor-white"
                                },
                                {
                                    "length": 212,
                                    "offset": 0,
                                    "style": "fontsize-medium"
                                },
                                {
                                    "length": 212,
                                    "offset": 0,
                                    "style": "fontfamily-Calibri, sans-serif"
                                },
                                {
                                    "length": 212,
                                    "offset": 0,
                                    "style": "color-black"
                                },
                                {
                                    "length": 212,
                                    "offset": 0,
                                    "style": "fontsize-10pt"
                                },
                                {
                                    "length": 212,
                                    "offset": 0,
                                    "style": "fontfamily-Tahoma, sans-serif"
                                },
                                {
                                    "length": 15,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "660ib",
                            "text": "Risks/Concerns:\u00a0May behave irresponsibly and disregard social norms and behavior. May act impulsively. May expect admiration from others and be uncomfortable in situations where he is not the center of attention. ",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "margin-left": "0px"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 575,
                                    "offset": 0,
                                    "style": "fontfamily-Tahoma, sans-serif"
                                },
                                {
                                    "length": 575,
                                    "offset": 0,
                                    "style": "fontsize-10pt"
                                },
                                {
                                    "length": 79,
                                    "offset": 581,
                                    "style": "fontsize-16"
                                },
                                {
                                    "length": 6,
                                    "offset": 575,
                                    "style": "fontfamily-.Apple Color Emoji UI"
                                },
                                {
                                    "length": 19,
                                    "offset": 0,
                                    "style": "BOLD"
                                },
                                {
                                    "length": 575,
                                    "offset": 0,
                                    "style": "fontsize-7.5pt"
                                },
                                {
                                    "length": 6,
                                    "offset": 575,
                                    "style": "color-rgba(0,0,0,0.85)"
                                },
                                {
                                    "length": 575,
                                    "offset": 0,
                                    "style": "fontfamily-Times New Roman\", serif"
                                },
                                {
                                    "length": 575,
                                    "offset": 0,
                                    "style": "color-rgb(0,0,0)"
                                },
                                {
                                    "length": 4,
                                    "offset": 577,
                                    "style": "fontfamily-.SF NS"
                                },
                                {
                                    "length": 6,
                                    "offset": 575,
                                    "style": "fontsize-13"
                                }
                            ],
                            "key": "9i95t",
                            "text": "Themes from posts:\u00a0Many references to God. Refers to himself and his friends, teammates as \"kings\". Emotional broadcaster (\"*giant exhale*\" tweet, uses heart eyes with women; \"I swear my future wife is gonna be the luckiest girl on the planet\"). Uses heart eyes or various heart emojis when speaking to a female friend or talking about a female celebrity who he finds attractive. Very active with social justice movement (Blackout Tuesday post, picture of he and teammates at a Black Lives Matter protest, put \"Matter\" on the back of his jersey for the 2020 season, tweeted \"\u270a\ud83c\udffe\u2665\ufe0e\" in response to teammate Trevor Lawrence speaking out against racial injustice).",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                }
            },
            "personality traits": {
                "agreeableness": {
                    "score": 43.417500000000004,
                    "sub": {
                        "altruism": {
                            "score": 40.815,
                            "text": "The individual is moderately self-focused. He/she has an inclination to think of his/her own well-being rather than helping others. While the individual may be willing to help others, that willingness may not be immediate."
                        },
                        "cooperation": {
                            "score": 39.9075,
                            "text": "The individual is moderately confrontational. He/she has an inclination to speak his/her mind even if it goes against the general consensus."
                        },
                        "modesty": {
                            "score": 35.82,
                            "text": "The individual is moderately comfortable with attention. He/she has an inclination to see him/herself in a positive manner and does not actively avoid attention."
                        },
                        "sympathy": {
                            "score": 46.754999999999995,
                            "text": "The individual tends not to show compassion toward others. He/she has an inclination not to help or feel for others."
                        },
                        "trust": {
                            "score": 47.7675,
                            "text": "The individual is a moderately cautious person. He/she has an inclination to see others as untrustworthy. He/she may have a tendency to be skeptical of others."
                        },
                        "uncompromising": {
                            "score": 49.47,
                            "text": "The individual is willing to break or compromise his/her own moral standards if doing so is favorable to the individual."
                        }
                    },
                    "text": "Agreeableness refers to an individual's general congeniality and ability to put other people's needs before their own."
                },
                "conscientiousness": {
                    "score": 52.815,
                    "sub": {
                        "achievement_striving": {
                            "score": 47.4375,
                            "text": "The individual is a moderately content with a 'good enough' attitude and has an inclination to be content with current achievements. Higher goals are not likely to be set or pursued from an intrinsic motivation."
                        },
                        "cautiousness": {
                            "score": 57.9825,
                            "text": "The individual is a moderately cautious thinker. He/she has an inclination to pause to think and consider before making decisions and/or taking action. "
                        },
                        "dutifulness": {
                            "score": 46.980000000000004,
                            "text": "The individual is moderately carefree. He/she has an inclination to disregard obligations if it makes things easier for the individual."
                        },
                        "orderliness": {
                            "score": 56.497499999999995,
                            "text": "The individual is moderately organized. He/she has an inclination of needing to strucutre and plan events and surroundings. "
                        },
                        "self_discipline": {
                            "score": 60.690000000000005,
                            "text": "The individual is persistent. He/she has an inclination of working through challenges without quitting."
                        },
                        "self_efficacy": {
                            "score": 47.3025,
                            "text": "The individual has a moderate tendancy to experience self-doubt and question his/her own abilities and capabilities. "
                        }
                    },
                    "text": "Conscientiousness refers to an Indvidual's propensity for orderliness and a need to plan. "
                },
                "emotional_range": {
                    "score": 66.2325,
                    "sub": {
                        "calm_in_pressure": {
                            "score": 66.6225,
                            "text": "The individual is moderately prone to stress. He/she has an inclination to get exited and anxious in stressful situations."
                        },
                        "composure": {
                            "score": 58.8075,
                            "text": "The individual is a moderately bad-tempered person. He/she has an inclination of resorting to anger in irritable/unfavorable situations."
                        },
                        "contentment": {
                            "score": 73.9575,
                            "text": "The individual is a moderately negative person. He/she has an inclination of thinking about the negative aspects of one's life."
                        },
                        "self_assuredness": {
                            "score": 75.00750000000001,
                            "text": "The individual is moderately anxious. He/she has an inclination of thinking about things in a negative/unfavorable manner."
                        },
                        "self_confidence": {
                            "score": 68.41499999999999,
                            "text": "The individual is a moderately self-conscious person. He/she has an inclination of paying attention to others opinions of one self."
                        },
                        "self_control": {
                            "score": 54.6075,
                            "text": "The individual is moderately self-indulgent. He/she has an inclination of being easily tempted."
                        }
                    },
                    "text": "Emotional Stability refers to how an an individual tends to respond to his/her environment or experience a negative state of mind. "
                },
                "extroversion": {
                    "score": 64.02,
                    "sub": {
                        "activity_level": {
                            "score": 46.4925,
                            "text": "The individual moderately prefers to take fewer tasks at a slower pace and allow for deeper focus. "
                        },
                        "assertiveness": {
                            "score": 68.475,
                            "text": "The individual is a moderately assertive person. He/she has an inclination of speaking up in group environments and wil take charge of situations/events. "
                        },
                        "cheerfulness": {
                            "score": 76.2075,
                            "text": "The individual displayes feelings of positivity, joy, enthusiasm, and optimism outwardly. "
                        },
                        "excitement_seeking": {
                            "score": 49.71,
                            "text": "The individual has a moderate preference for calmness. He/she has an inclination to actively avoid excitement and search for calmness."
                        },
                        "gregariousness": {
                            "score": 70.095,
                            "text": "The individual is moderately sociable. He/she has an inclination to prefer spending time with others over alone time. "
                        },
                        "outgoing": {
                            "score": 73.155,
                            "text": "The individual is a moderately outgoing person. He/she has an inclination to be comfortable around people and engaging with others."
                        }
                    },
                    "text": "Extroversion refers to an individual's tendency to seek stimulation via the company of others. Extroversion/Introversion does not imply interest in others; it refers to the energy one feels as a result of interactions with others."
                },
                "openness": {
                    "score": 46.575,
                    "sub": {
                        "adventurousness": {
                            "score": 38.31,
                            "text": "The individual has a low level of creativity and curiosity for new experiences. He/she has an inclination of accepting tradition and status quo."
                        },
                        "artistic_interests": {
                            "score": 46.11,
                            "text": "The individual has a low level of enthusiasm about creativity or seeking beautiful experiences. He/she has an inclination of being indifferent about creative or beautiful experiences."
                        },
                        "authority_challenging": {
                            "score": 41.5575,
                            "text": "The individual is a moderate rule follower. He/she has an inclination toward obedience, following authority, and respecting rules and traditions. "
                        },
                        "emotionality": {
                            "score": 45.150000000000006,
                            "text": "The individual demonstrates some emotional awareness. He/she has an inclination of being unaware or distant from his/her feelings."
                        },
                        "imagination": {
                            "score": 44.4525,
                            "text": "The individual has an inclination of being grounded on facts when thinking, but may be able to appreciate or visualize new concepts when presented by others. "
                        },
                        "intellect": {
                            "score": 63.862500000000004,
                            "text": "The individual is moderately philosophical. He/she has an inclination of being unsatisfied of what is and tends to prefer exploring new possibilities and what could be."
                        }
                    },
                    "text": "Openness refers to an individual's general willingness to engage in new experiences and learn new things. Individuals that score high in Openness are more imaginative and creative. Those low in Openness tend to be more analytical and resistant to change and prefer tradition. "
                }
            },
            "psychological disorders": {
                "antisocial": {
                    "score": 45.06716601110114,
                    "text": "A moderate possibility of behaving irresponsibly and show disregard for normal social behaviour."
                },
                "avoidant": {
                    "score": 36.642180814156404,
                    "text": "Possibility of existing level of Avoidant Personality Disorder is very low or none."
                },
                "borderline": {
                    "score": 37.78853359066194,
                    "text": "A moderate possibility of showing self harming behaviour and having a distorted and unstable self-image or sense of self."
                },
                "dependent": {
                    "score": 31.182173556948797,
                    "text": "Possibility of existing level of Dependent Personality Disorder is very low or none."
                },
                "histrionic": {
                    "score": 62.4014476325262,
                    "text": "A moderate possibility of being easily influenced by others or circumstances and may be uncomfortable in situations in which the individual is not the center of attention."
                },
                "narcissistic": {
                    "score": 53.24477435665129,
                    "text": "A moderate possibility of believing that he or she is special and unique while expecting admiration from others."
                },
                "obsessive_compulsive": {
                    "score": 61.81056702797644,
                    "text": "A moderate possibility of the individual showing compulsive and obsessive behaviours in some areas of his/her life."
                },
                "paranoid": {
                    "score": 39.56146250947684,
                    "text": "A considerable possibility of individual being reluctant to confide in others and suspects others of being exploitative or deceitful."
                },
                "schizotypal": {
                    "score": 36.81160297706826,
                    "text": "Possibility of existing level of Schizotypal Personality Disorder is very low or none."
                }
            },
            "risk": {
                "Aggression/Violence": {
                    "comments": [

                    ],
                    "score": 20,
                    "text": "Individuals low (1-3) in this range demonstrates minimal risk in aggression/violence. The individual demonstrates the ability to manage emotions (e.g. irritability, frustration, annoyances) and may utilize levels of anger as a motivating source for success."
                },
                "Delinquent Behavior": {
                    "comments": [

                    ],
                    "score": 30,
                    "text": "Individuals low (1-3) in this range demonstrate low expression of delinquent behavior. Individuals in this range adhere to societal, cultural norms. Generally, following standard procedures and expectations from authority figures. "
                },
                "Drug/Alcohol": {
                    "comments": [

                    ],
                    "score": 30,
                    "text": "Individuals low (1-3) in this range demonstrate minimal risky behavior in drug/alcohol use. Individuals either demonstrate normative minimal engagement in acceptable use that falls within normative range or does not indicate substance use per analysis. Should be noted individuals may be partaking in higher risk behavior but minimizing their full expression via social media/ public expression"
                },
                "Emotional Distress": {
                    "comments": [

                    ],
                    "score": 40.0,
                    "text": "Individuals moderate (4-6) in this range demonstrate some difficulties coping with moderate levels of emotional, physical, and mental stress. Individuals may range in their ability to utilize coping skills to manage emotions effectively. Individuals may show following reactions to stressors indicating some levels of emotional distress (e.g. difficulties sleeping, change in moods, irritability, change in dietary habits). "
                },
                "Overall": {
                    "comments": [

                    ],
                    "score": 32.0,
                    "text": "Risk score invalid to generate analysis text"
                },
                "Sexual Behavior": {
                    "comments": [

                    ],
                    "score": 40,
                    "text": "Individuals moderate (4-6) in this range indicate a level of identified sexual behavior expressed through social media accounts. Behaviors may indicate discussions/statements exploring/posting sexual content, discussing relationships, seeking potential partner, demonstrating significant romantic relationships, and/or indication they are a parent. Should be noted individuals may be partaking in higher risk behavior but minimizing their full expression via social media/public expression."
                }
            }
        },
        "instagram": "john_doe",
        "is_paid": true,
        "is_valid_format": true,
        "linkedin": "",
        "links": [],
        "message": {
            "button_label": "",
            "content": "",
            "severity": "info",
            "show": false,
            "title": ""
        },
        "possible_duplicate_info": {
            "duplicate_candidate": {

            },
            "duplicate_candidate_id": "071bcbcb-17ab-4893-b884-6479cf975a88",
            "is_possible_duplicate": true
        },
        "race": "",
        "relationship_status": "",
        "report_settings": {
            "_id": "0a17814d-b3ec-4a4e-ac05-bfeae9e890f5",
            "action_plan": {
                "allowed": true
            },
            "executive_summary": {
                "allowed": true
            },
            "full_report": {
                "allowed": true
            }
        },
        "reports": {
            "action_plan_report": false,
            "executive_summary_report": false,
            "full_report": false
        },
        "reviewed_on": "03/18/2021, 20:03:35",
        "risk": "low",
        "school": "Clemson",
        "sport": "Football",
        "stats": {
            "instagram": {
                "negative_percentage": 18.37,
                "neutral_percentage": 28.57,
                "positive_percentage": 53.06,
                "total": 2092,
                "total_negative": 9,
                "total_neutral": 14,
                "total_positive": 26
            },
            "negative_percentage": 19.29,
            "neutral_percentage": 23.73,
            "positive_percentage": 56.98,
            "total": 2141,
            "total_negative": 413,
            "total_neutral": 508,
            "total_positive": 1220,
            "tweets": {
                "negative_percentage": 19.31,
                "neutral_percentage": 23.61,
                "positive_percentage": 57.07,
                "total": 2092,
                "total_negative": 404,
                "total_neutral": 494,
                "total_positive": 1194
            },
            "videos": {
                "total": 2
            }
        },
        "status": "Completed",
        "team": "Blu Chip Analytics",
        "twitter": "John.Doe",
        "whatsapp": ""
    },
    "9cf009f4-f1ad-41ee-a406-a591e2a56f35": {
        "_id": "9cf009f4-f1ad-41ee-a406-a591e2a56f35",
        "athleteType": "Roster",
        "conference": "NFL",
        "conference_type": "Professional",
        "consent": "Pending",
        "consent_form_id": "N/A",
        "created_on": "08/18/2022, 06:05:39",
        "dob": "11/12/1985",
        "email": "han@skywalker.com",
        "first_name": "Han",
        "full_name": "Han Solo",
        "gender": "Men's Sports",
        "homeCountry": "United States",
        "homeState": "Alaska",
        "hometown": "N/A",
        "image_url": "http://127.0.0.1:3434/export/default_profile_pic",
        "insights": {
            "action_plan": {
                "Connection is Key": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Connection is Key",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Psychologist comments pending...",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Establish the Environment": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Establish the Environment",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Psychologist comments pending...",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Mental Mindset": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Mental Mindset",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Psychologist comments pending...",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                }
            },
            "custom_traits": {
                "character": {
                    "description": "An individual\u2019s emotional and mental qualities based on their morals, virtues, and humility that aid in executing tasks.",
                    "label": "Character",
                    "score": 30.0,
                    "sub": {
                        "hard_working": {
                            "left_label": "Hard-Working",
                            "right_label": "Lazy",
                            "score": 30.0,
                            "text": ""
                        },
                        "high_integrity": {
                            "left_label": "High Integrity",
                            "right_label": "Low Integrity",
                            "score": 45.0,
                            "text": ""
                        },
                        "humble": {
                            "left_label": "Humility",
                            "right_label": "Arrogant",
                            "score": 0,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 0-30% demonstrate a minimal level of character. Individuals may lack drive and are lazy in their efforts to meet performance outcomes. Individuals may have low integrity, question or challenge authority, break team rules/norms. Individuals low in character may be arrogant and lack humility."
                },
                "coachability": {
                    "description": "An individual\u2019s ability to listen, learn, be open to feedback, manage criticism, adapt their skill set, and perform at their optimal level.",
                    "label": "Coachability",
                    "score": 24.375,
                    "sub": {
                        "dependable": {
                            "left_label": "Dependable",
                            "right_label": "Unreliable",
                            "score": 33.75,
                            "text": ""
                        },
                        "willingness_to_learn ": {
                            "left_label": "Willingness to Learn",
                            "right_label": "Closed Minded",
                            "score": 0,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 0-30% demonstrate a minimal level of coachability. The individual may tend to be unreliable, closed minded, or have difficulty managing feedback and criticism. They may display a negative mindset, defensiveness, and not adhere to cultural standards to assist in growth in their performance."
                },
                "composure": {
                    "description": "An individual\u2019s ability to remain calm, persistent, and in control under pressure situations or after mistakes. Individuals\u2019 thrive in high pressure situations and demonstrate enhanced performance outcomes.",
                    "label": "Composure",
                    "score": 40.3125,
                    "sub": {
                        "calm": {
                            "left_label": "Calm",
                            "right_label": "Susceptible to Stress",
                            "score": 65.625,
                            "text": ""
                        },
                        "driven": {
                            "left_label": "Driven",
                            "right_label": "Unambitious",
                            "score": 0,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of composure. Individuals may have the capacity to remain calm and manage stressful situations to a degree. They have a moderate drive and purpose to strive for. In high pressure situations they may react and have difficulties managing their emotions on a consistent basis."
                },
                "leadership": {
                    "description": "An individual\u2019s ability to influence, inspire, and communicate a common goal to others.",
                    "label": "Leadership",
                    "score": 38.0,
                    "sub": {
                        "accountable": {
                            "left_label": "Accountable",
                            "right_label": "Unaccountable",
                            "score": 0,
                            "text": ""
                        },
                        "confident": {
                            "left_label": "Confident",
                            "right_label": "Insecure",
                            "score": 69.0,
                            "text": ""
                        },
                        "vocal": {
                            "left_label": "Vocal",
                            "right_label": "Unspoken",
                            "score": 30.0,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of leadership capacity. Individuals may work hard toward goals and can inspire small groups or a few individuals. Individuals may lack the ability to be vocal and speak to larger members to inspire a large group to meet cultural goals. Individuals may have the capacity to make small changes and influence members around them. Individuals may have moderate level of confidence, but poor performances or influence may negatively impact self-worth and ability to perform at optimal level."
                }
            },
            "executive_summary": {
                "Significant Background Information": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Significant Background Information",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Psychologist comments pending...",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Symptoms and Information Captured": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Symptoms and Information Captured",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Psychologist comments pending...",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                }
            },
            "personality traits": {
                "agreeableness": {
                    "score": 60.025,
                    "sub": {
                        "altruism": {
                            "score": 25.12,
                            "text": "The individual is highly self-focused. He/she has a high tendency to work for his/her own well-being. The individual has a tendency to help others only when he/she also stands to benefit."
                        },
                        "cooperation": {
                            "score": 29.15,
                            "text": "The individual is moderately confrontational. He/she has an inclination to speak his/her mind even if it goes against the general consensus."
                        },
                        "modesty": {
                            "score": 46.58,
                            "text": "The individual is moderately comfortable with attention. He/she has an inclination to see him/herself in a positive manner and does not actively avoid attention."
                        },
                        "sympathy": {
                            "score": 58.63,
                            "text": "The individual is empathetic. When an opportunity presents, he/she is able to put him/herself in others' shoes and has an inclination to show compassion toward others. "
                        },
                        "trust": {
                            "score": 15.53,
                            "text": "The individual is a very cautious person. He/she tends to believe people are inherently bad, assumes others are scheming, and approaches people with skepticim and caution. "
                        },
                        "uncompromising": {
                            "score": 41.2,
                            "text": "The individual is willing to break or compromise his/her own moral standards if doing so is favorable to the individual."
                        }
                    },
                    "text": "Agreeableness refers to an individual's general congeniality and ability to put other people's needs before their own."
                },
                "conscientiousness": {
                    "score": 44,
                    "sub": {
                        "achievement_striving": {
                            "score": 56.5,
                            "text": "The individual is a moderately driven person. He/she has an inclination to set high goals and work hard to reach them them."
                        },
                        "cautiousness": {
                            "score": 67.25,
                            "text": "The individual is a moderately cautious thinker. He/she has an inclination to pause to think and consider before making decisions and/or taking action. "
                        },
                        "dutifulness": {
                            "score": 25.3,
                            "text": "The individual is highly carefree. He/she has a high tendency to prefer disregarding duties and obligations. These individuals may not see prior commitments as binding."
                        },
                        "orderliness": {
                            "score": 33.56,
                            "text": "The individual is moderately unorganized. He/she has an inclination to 'go with the flow' versus planning and organizing."
                        },
                        "self_discipline": {
                            "score": 39.53,
                            "text": "The individual may have an inclination give up in the face of difficulty or when he/she does not see the value of the task at hand. "
                        },
                        "self_efficacy": {
                            "score": 18.56,
                            "text": "The individual experiences a lot of self-doubt in his/her own capabilities and may lack agency. This may impact their ability or desire to work toward a goal or task to completion. "
                        }
                    },
                    "text": "Conscientiousness refers to an Indvidual's propensity for orderliness and a need to plan. "
                },
                "emotional_range": {
                    "score": 90.0,
                    "sub": {
                        "calm_in_pressure": {
                            "score": 90.0,
                            "text": "The individual is highly prone to stress. He/she has a high tendency to become anxious and overwhelmed in the face of stress."
                        },
                        "composure": {
                            "score": 90.0,
                            "text": "The individual is a highly bad-tempered person. He/she has a high tendency to resort to anger quickly."
                        },
                        "contentment": {
                            "score": 90.0,
                            "text": "The individual is a highly negative person. He/she has a high tendency to have a negative outlook on life and perceive the unfavourable side of situations."
                        },
                        "self_assuredness": {
                            "score": 90.0,
                            "text": "The individual is highly anxious. He/she has a high tendency of worrying about necessary and unnecessary future scenarios."
                        },
                        "self_confidence": {
                            "score": 90.0,
                            "text": "The individual is a highly self-conscious person. He/she has a high tendency to be strongly affected (mostly negatively) by others perception of one self."
                        },
                        "self_control": {
                            "score": 90.0,
                            "text": "The individual is highly self-indulgent. He/she has a high tendency of being tempted by desires."
                        }
                    },
                    "text": "Emotional Stability refers to how an an individual tends to respond to his/her environment or experience a negative state of mind. "
                },
                "extroversion": {
                    "score": 55.86,
                    "sub": {
                        "activity_level": {
                            "score": 76.5,
                            "text": "The individual has a strong preference for more tasks at a faster pace. He/she will be engaged with several tasks at once. While he/she may be involved in many activities, busyness does not necessarily equate to productivity. "
                        },
                        "assertiveness": {
                            "score": 25.65,
                            "text": "The individual is a moderately assertive person. He/she has an inclination of listening rather than speaking in group environments."
                        },
                        "cheerfulness": {
                            "score": 35.55,
                            "text": "The individual is moderately unexpressive with regard to joy, excitement, or enthusiasm. This does not mean they are unhappy, rather they demonstrate these feelings in a more subdued manner. "
                        },
                        "excitement_seeking": {
                            "score": 65.5,
                            "text": "The individual moderately prefers excitement. He/she has an inclination to seek out people and situations that are exciting or thrilling. "
                        },
                        "gregariousness": {
                            "score": 19.5,
                            "text": "The individual is highly independent. He/she has a high tendency to value alone time away from people and may shy away from social gatherings."
                        },
                        "outgoing": {
                            "score": 33.3,
                            "text": "The individual is a moderately reserved person. He/she has an inclination to be guarded and private, and may appear to be reticent in new environments. "
                        }
                    },
                    "text": "Extroversion refers to an individual's tendency to seek stimulation via the company of others. Extroversion/Introversion does not imply interest in others; it refers to the energy one feels as a result of interactions with others."
                },
                "openness": {
                    "score": 85.56,
                    "sub": {
                        "adventurousness": {
                            "score": 79.89,
                            "text": "The individual has a high level of creativity and curiosity for new experiences. He/she has a high prefernce for originality and learning and experiencing new things."
                        },
                        "artistic_interests": {
                            "score": 65.68,
                            "text": "The individual has a moderate level of enthusiasm about creativity or seeking beautiful experiences. He/she has an inclination of searching for creative experiences."
                        },
                        "authority_challenging": {
                            "score": 45.98,
                            "text": "The individual is a moderate rule follower. He/she has an inclination toward obedience, following authority, and respecting rules and traditions. "
                        },
                        "emotionality": {
                            "score": 79.56,
                            "text": "The individual is highly emotionally aware and has a tendency of seeing emotions as being central to his/her life. This person is highly comfortable expressing feelings and will dedicate time specifically to experiencing those feelings. "
                        },
                        "imagination": {
                            "score": 82.86,
                            "text": "The individual is a highly imaginative person. He/she has a high tendency of thinking through imagination and fantasy and may be bored with routine and mundanity. "
                        },
                        "intellect": {
                            "score": 24.56,
                            "text": "The individual is highly practical. He/she has a high tendency of accepting facts with limited-to-no appreciation for theory. "
                        }
                    },
                    "text": "Openness refers to an individual's general willingness to engage in new experiences and learn new things. Individuals that score high in Openness are more imaginative and creative. Those low in Openness tend to be more analytical and resistant to change and prefer tradition."
                }
            },
            "psychological disorders": {
                "antisocial": {
                    "score": 1258.4131025165716,
                    "text": "Possibility of existing level of Antisocial Personality Disorder is very low or none."
                },
                "avoidant": {
                    "score": 1524.646932693734,
                    "text": "Possibility of existing level of Avoidant Personality Disorder is very low or none."
                },
                "borderline": {
                    "score": 1431.4067534281378,
                    "text": "Possibility of existing level of Borderline Personality Disorder is very low or none."
                },
                "dependent": {
                    "score": 1587.8637089422405,
                    "text": "Possibility of existing level of Dependent Personality Disorder is very low or none."
                },
                "histrionic": {
                    "score": 6449.316823213728,
                    "text": "A considerable possibility of individual being self-centered and with less or no concern for others, rash decision making, constantly seeking approval and tendency to act with exaggerated actions and emotions. A closer look at the individual is recommended."
                },
                "narcissistic": {
                    "score": 3446.288195971606,
                    "text": "A moderate possibility of believing that he or she is special and unique while expecting admiration from others."
                },
                "obsessive_compulsive": {
                    "score": 6597.330379314656,
                    "text": "A considerable possibility of the individual showing compulsive and obsessive behaviours and a closer look at the individual is recommended."
                },
                "paranoid": {
                    "score": 1387.1314583856781,
                    "text": "Possibility of existing level of Paranoid Personality Disorder is very low or none."
                },
                "schizotypal": {
                    "score": 1453.0064384444884,
                    "text": "Possibility of existing level of Schizotypal Personality Disorder is very low or none."
                }
            },
            "risk": {
                "Aggression/Violence": {
                    "comments": [

                    ],
                    "score": 100,
                    "text": "Individuals high (7-10) in this range demonstrate high risk of aggression/violence. Individuals have difficulty managing emotions, react in physical or verbal aggressive tendencies in high stress situations. Individuals demonstrated some verbal or physical outbursts. Individuals have had negative consequences due to aggression/violence (e.g. verbal/physical fights, suspensions, arrests, assaults). "
                },
                "Delinquent Behavior": {
                    "comments": [

                    ],
                    "score": 300,
                    "text": "Risk score invalid to generate analysis text"
                },
                "Drug/Alcohol": {
                    "comments": [

                    ],
                    "score": 200,
                    "text": "Risk score invalid to generate analysis text"
                },
                "Emotional Distress": {
                    "comments": [

                    ],
                    "score": 100,
                    "text": "Individuals high (7-10) in this range have difficulty managing emotions, balancing with stressors, and implementing coping skills effectively. Individuals may experience distress as an averse, negative emotional state demonstrating symptoms of mental illness (e.g. trauma, anxiety, depression)"
                },
                "Overall": {
                    "comments": [

                    ],
                    "score": 220.0,
                    "text": "Risk score invalid to generate analysis text"
                },
                "Sexual Behavior": {
                    "comments": [

                    ],
                    "score": 400,
                    "text": "Risk score invalid to generate analysis text"
                }
            }
        },
        "instagram": "hansolo",
        "instagram_source_ln": "en",
        "is_paid": false,
        "last_name": "Solo",
        "linkedin": "hansolo",
        "linkedin_source_ln": "en",
        "links": [

        ],
        "message": {
            "button_label": "Okay, Got it",
            "content": "Proceed with caution when reviewing the system generated scores",
            "severity": "info",
            "show": true,
            "title": "Insufficient Data for a proper analysis"
        },
        "notes": {
            "blocks": [
                {
                    "data": {

                    },
                    "depth": 0,
                    "entityRanges": [

                    ],
                    "inlineStyleRanges": [

                    ],
                    "key": "9sqau",
                    "text": "",
                    "type": "unstyled"
                }
            ],
            "entityMap": {

            }
        },
        "position": "",
        "possible_duplicate_info": {
            "duplicate_candidate": {

            },
            "duplicate_candidate_id": "",
            "is_possible_duplicate": false
        },
        "race": "Asian",
        "relationship_status": "Married",
        "report_settings": {
            "_id": "1358e62e-cafb-4ee4-9023-6f8001fb9d3a",
            "action_plan": {
                "allowed": false
            },
            "executive_summary": {
                "allowed": false
            },
            "full_report": {
                "allowed": false
            }
        },
        "reports": {
            "action_plan_report": false,
            "executive_summary_report": false,
            "full_report": false
        },
        "reviewed_on": "",
        "risk": "low",
        "school": "N/A",
        "sport": "Football",
        "stats": {
            "instagram": {
                "negative_percentage": 0,
                "neutral_percentage": 0,
                "positive_percentage": 0,
                "total": 0,
                "total_negative": 0,
                "total_neutral": 0,
                "total_positive": 0
            },
            "negative_percentage": 0,
            "neutral_percentage": 0,
            "positive_percentage": 0,
            "total": 0,
            "total_negative": 0,
            "total_neutral": 0,
            "total_positive": 0,
            "tweets": {
                "negative_percentage": 0,
                "neutral_percentage": 0,
                "positive_percentage": 0,
                "total": 0,
                "total_negative": 0,
                "total_neutral": 0,
                "total_positive": 0
            },
            "videos": {
                "total": 0
            }
        },
        "status": "Approval Pending",
        "team": "Millennium Falcon",
        "twitter": "hansolo",
        "twitter_source_ln": "en",
        "unit": "Offense",
        "whatsapp": "N/A"

    },
    "0cd39312-922c-4595-aced-85a8f4a64efb": {
        "Report Completed": "",
        "Unnamed: 12": "",
        "Unnamed: 13": "",
        "Unnamed: 14": "",
        "Unnamed: 15": "",
        "_id": "0cd39312-922c-4595-aced-85a8f4a64efb",
        "consent": "Pending",
        "consent_form_id": "N/A",
        "created_on": "11/02/2021, 15:31:08",
        "dob": "3/31/98",
        "email": "aborder@yahoo.com",
        "full_name": "Allen Border",
        "hometown": "Yuba City, CA",
        "image_url": "http://127.0.0.1:3434/export/default_profile_pic",
        "insights": {
            "action_plan": {
                "Connection is Key": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Connection is Key",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Psychologist comments pending...",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Establish the Environment": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Establish the Environment",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Psychologist comments pending...",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Mental Mindset": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Mental Mindset",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Psychologist comments pending...",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                }
            },
            "custom_traits": {
                "character": {
                    "description": "An individual\u2019s emotional and mental qualities based on their morals, virtues, and humility that aid in executing tasks.",
                    "label": "Character",
                    "score": 30.0,
                    "sub": {
                        "hard_working": {
                            "left_label": "Hard-Working",
                            "right_label": "Lazy",
                            "score": 30.0,
                            "text": ""
                        },
                        "high_integrity": {
                            "left_label": "High Integrity",
                            "right_label": "Low Integrity",
                            "score": 45.0,
                            "text": ""
                        },
                        "humble": {
                            "left_label": "Humility",
                            "right_label": "Arrogant",
                            "score": 0,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 0-30% demonstrate a minimal level of character. Individuals may lack drive and are lazy in their efforts to meet performance outcomes. Individuals may have low integrity, question or challenge authority, break team rules/norms. Individuals low in character may be arrogant and lack humility."
                },
                "coachability": {
                    "description": "An individual\u2019s ability to listen, learn, be open to feedback, manage criticism, adapt their skill set, and perform at their optimal level.",
                    "label": "Coachability",
                    "score": 24.375,
                    "sub": {
                        "dependable": {
                            "left_label": "Dependable",
                            "right_label": "Unreliable",
                            "score": 33.75,
                            "text": ""
                        },
                        "willingness_to_learn ": {
                            "left_label": "Willingness to Learn",
                            "right_label": "Closed Minded",
                            "score": 0,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 0-30% demonstrate a minimal level of coachability. The individual may tend to be unreliable, closed minded, or have difficulty managing feedback and criticism. They may display a negative mindset, defensiveness, and not adhere to cultural standards to assist in growth in their performance."
                },
                "composure": {
                    "description": "An individual\u2019s ability to remain calm, persistent, and in control under pressure situations or after mistakes. Individuals\u2019 thrive in high pressure situations and demonstrate enhanced performance outcomes.",
                    "label": "Composure",
                    "score": 40.3125,
                    "sub": {
                        "calm": {
                            "left_label": "Calm",
                            "right_label": "Susceptible to Stress",
                            "score": 65.625,
                            "text": ""
                        },
                        "driven": {
                            "left_label": "Driven",
                            "right_label": "Unambitious",
                            "score": 0,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of composure. Individuals may have the capacity to remain calm and manage stressful situations to a degree. They have a moderate drive and purpose to strive for. In high pressure situations they may react and have difficulties managing their emotions on a consistent basis."
                },
                "leadership": {
                    "description": "An individual\u2019s ability to influence, inspire, and communicate a common goal to others.",
                    "label": "Leadership",
                    "score": 38.0,
                    "sub": {
                        "accountable": {
                            "left_label": "Accountable",
                            "right_label": "Unaccountable",
                            "score": 0,
                            "text": ""
                        },
                        "confident": {
                            "left_label": "Confident",
                            "right_label": "Insecure",
                            "score": 69.0,
                            "text": ""
                        },
                        "vocal": {
                            "left_label": "Vocal",
                            "right_label": "Unspoken",
                            "score": 30.0,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of leadership capacity. Individuals may work hard toward goals and can inspire small groups or a few individuals. Individuals may lack the ability to be vocal and speak to larger members to inspire a large group to meet cultural goals. Individuals may have the capacity to make small changes and influence members around them. Individuals may have moderate level of confidence, but poor performances or influence may negatively impact self-worth and ability to perform at optimal level."
                }
            },
            "executive_summary": {
                "Significant Background Information": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Significant Background Information",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Psychologist comments pending...",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Symptoms and Information Captured": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Symptoms and Information Captured",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Psychologist comments pending...",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                }
            },
            "personality traits": {
                "agreeableness": {
                    "score": 72.65,
                    "sub": {
                        "altruism": {
                            "score": 45.68,
                            "text": "The individual is moderately self-focused. He/she has an inclination to think of his/her own well-being rather than helping others. While the individual may be willing to help others, that willingness may not be immediate."
                        },
                        "cooperation": {
                            "score": 59.86,
                            "text": "The individual is moderately accommodating. He/she has an inclination to compromise and/or please others. "
                        },
                        "modesty": {
                            "score": 76.85,
                            "text": "The individual dislikes attention. He/she has a high tendency to avoid being the center of attention and may be self-effacing. Individual's tendency for humility may not refect abilities, competencies, or superiority. "
                        },
                        "sympathy": {
                            "score": 70.85,
                            "text": "The individual is empathetic. When an opportunity presents, he/she is able to put him/herself in others' shoes and has an inclination to show compassion toward others. "
                        },
                        "trust": {
                            "score": 68.4,
                            "text": "The individual is a trusting person. He/she has an inclination to see the best in others and may be highly charitable."
                        },
                        "uncompromising": {
                            "score": 61.25,
                            "text": "The individual is unwilling to break his/her own moral standards and has an inclination to be uncompromising in his/her values and beliefs."
                        }
                    },
                    "text": "Agreeableness refers to an individual's general congeniality and ability to put other people's needs before their own."
                },
                "conscientiousness": {
                    "score": 55.23,
                    "sub": {
                        "achievement_striving": {
                            "score": 55.89,
                            "text": "The individual is a moderately driven person. He/she has an inclination to set high goals and work hard to reach them them."
                        },
                        "cautiousness": {
                            "score": 51.89,
                            "text": "The individual is a moderately cautious thinker. He/she has an inclination to pause to think and consider before making decisions and/or taking action. "
                        },
                        "dutifulness": {
                            "score": 43.75,
                            "text": "The individual is moderately carefree. He/she has an inclination to disregard obligations if it makes things easier for the individual."
                        },
                        "orderliness": {
                            "score": 71.25,
                            "text": "The individual is moderately organized. He/she has an inclination of needing to strucutre and plan events and surroundings. "
                        },
                        "self_discipline": {
                            "score": 39,
                            "text": "The individual may have an inclination give up in the face of difficulty or when he/she does not see the value of the task at hand. "
                        },
                        "self_efficacy": {
                            "score": 33,
                            "text": "The individual has a moderate tendancy to experience self-doubt and question his/her own abilities and capabilities. "
                        }
                    },
                    "text": "Conscientiousness refers to an Indvidual's propensity for orderliness and a need to plan. "
                },
                "emotional_range": {
                    "score": 90.0,
                    "sub": {
                        "calm_in_pressure": {
                            "score": 90.0,
                            "text": "The individual is highly prone to stress. He/she has a high tendency to become anxious and overwhelmed in the face of stress."
                        },
                        "composure": {
                            "score": 90.0,
                            "text": "The individual is a highly bad-tempered person. He/she has a high tendency to resort to anger quickly."
                        },
                        "contentment": {
                            "score": 90.0,
                            "text": "The individual is a highly negative person. He/she has a high tendency to have a negative outlook on life and perceive the unfavourable side of situations."
                        },
                        "self_assuredness": {
                            "score": 90.0,
                            "text": "The individual is highly anxious. He/she has a high tendency of worrying about necessary and unnecessary future scenarios."
                        },
                        "self_confidence": {
                            "score": 90.0,
                            "text": "The individual is a highly self-conscious person. He/she has a high tendency to be strongly affected (mostly negatively) by others perception of one self."
                        },
                        "self_control": {
                            "score": 90.0,
                            "text": "The individual is highly self-indulgent. He/she has a high tendency of being tempted by desires."
                        }
                    },
                    "text": "Emotional Stability refers to how an an individual tends to respond to his/her environment or experience a negative state of mind. "
                },
                "extroversion": {
                    "score": 66,
                    "sub": {
                        "activity_level": {
                            "score": 62,
                            "text": "The individual moderately prefers more tasks at a faster pace. He/she has an inclination to focus on more than one project or task at at time. "
                        },
                        "assertiveness": {
                            "score": 55,
                            "text": "The individual is a moderately assertive person. He/she has an inclination of speaking up in group environments and wil take charge of situations/events. "
                        },
                        "cheerfulness": {
                            "score": 45,
                            "text": "The individual is moderately unexpressive with regard to joy, excitement, or enthusiasm. This does not mean they are unhappy, rather they demonstrate these feelings in a more subdued manner. "
                        },
                        "excitement_seeking": {
                            "score": 75,
                            "text": "The individual moderately prefers excitement. He/she has an inclination to seek out people and situations that are exciting or thrilling. "
                        },
                        "gregariousness": {
                            "score": 39,
                            "text": "The individual is moderately independent. He/she has an inclination to prefer having time for oneself over larger social gatherings."
                        },
                        "outgoing": {
                            "score": 69,
                            "text": "The individual is a moderately outgoing person. He/she has an inclination to be comfortable around people and engaging with others."
                        }
                    },
                    "text": "Extroversion refers to an individual's tendency to seek stimulation via the company of others. Extroversion/Introversion does not imply interest in others; it refers to the energy one feels as a result of interactions with others."
                },
                "openness": {
                    "score": 45,
                    "sub": {
                        "adventurousness": {
                            "score": 33.56,
                            "text": "The individual has a low level of creativity and curiosity for new experiences. He/she has an inclination of accepting tradition and status quo."
                        },
                        "artistic_interests": {
                            "score": 24,
                            "text": "The individual has a very low level of enthusiasm about creativity or seeking beautiful experiences. He/she has a very low-to-no need or preference to seek experiences that are aesthetically pleasing. "
                        },
                        "authority_challenging": {
                            "score": 76,
                            "text": "The individual regularly challenges authority, norms, and traditions. He/she has a tendency to be accepting of values/lifestyles that are different from him/herself. "
                        },
                        "emotionality": {
                            "score": 16.5,
                            "text": "The individual demonstrates limited emotional awareness. He/she has a high tendency of being guarded about his/her feelings and is very unlikely to openly express such feelings. With limited emotional awareness, the individual may regard feelings as irrelevant or inconvenient. "
                        },
                        "imagination": {
                            "score": 71.85,
                            "text": "The individual is a moderately imaginative person. He/she has an inclination of being comfortable with letting his/her imagination do the thinking while still keeping an eye on the facts. "
                        },
                        "intellect": {
                            "score": 65,
                            "text": "The individual is moderately philosophical. He/she has an inclination of being unsatisfied of what is and tends to prefer exploring new possibilities and what could be."
                        }
                    },
                    "text": "Openness refers to an individual's general willingness to engage in new experiences and learn new things. Individuals that score high in Openness are more imaginative and creative. Those low in Openness tend to be more analytical and resistant to change and prefer tradition."
                }
            },
            "psychological disorders": {
                "antisocial": {
                    "score": 0,
                    "text": "Analysis Pending..."
                },
                "avoidant": {
                    "score": 0,
                    "text": "Analysis Pending..."
                },
                "borderline": {
                    "score": 0,
                    "text": "Analysis Pending..."
                },
                "dependent": {
                    "score": 0,
                    "text": "Analysis Pending..."
                },
                "histrionic": {
                    "score": 0,
                    "text": "Analysis Pending..."
                },
                "narcissistic": {
                    "score": 0,
                    "text": "Analysis Pending..."
                },
                "obsessive_compulsive": {
                    "score": 0,
                    "text": "Analysis Pending..."
                },
                "paranoid": {
                    "score": 0,
                    "text": "Analysis Pending..."
                },
                "schizotypal": {
                    "score": 0,
                    "text": "Analysis Pending..."
                }
            },
            "risk": {
                "Aggression/Violence": {
                    "comments": [

                    ],
                    "score": 0,
                    "text": "Analysis Pending.."
                },
                "Delinquent Behavior": {
                    "comments": [

                    ],
                    "score": 0,
                    "text": "Analysis Pending.."
                },
                "Drug/Alcohol": {
                    "comments": [

                    ],
                    "score": 0,
                    "text": "Analysis Pending.."
                },
                "Emotional Distress": {
                    "comments": [

                    ],
                    "score": 0,
                    "text": "Analysis Pending.."
                },
                "Overall": {
                    "comments": [

                    ],
                    "score": 0.0,
                    "text": "Analysis Pending.."
                },
                "Sexual Behavior": {
                    "comments": [

                    ],
                    "score": 0,
                    "text": "Analysis Pending.."
                }
            }
        },
        "instagram": "aborder",
        "is_paid": false,
        "is_valid_format": true,
        "linkedin": "aborder_99",
        "links": [],
        "message": {
            "button_label": "Okay, Got it",
            "content": "Proceed with caution when reviewing the system generated scores",
            "severity": "info",
            "show": true,
            "title": "Insufficient Data for a proper analysis"
        },
        "possible_duplicate_info": {
            "duplicate_candidate": {

            },
            "duplicate_candidate_id": "de539e94-f758-4677-ba3a-1d29412cd4fa",
            "is_possible_duplicate": true
        },
        "race": "",
        "relationship_status": "",
        "report_settings": {
            "_id": "35be845e-a670-41c3-8a4d-236dc6f24f56",
            "action_plan": {
                "allowed": false
            },
            "executive_summary": {
                "allowed": false
            },
            "full_report": {
                "allowed": false
            }
        },
        "reports": {
            "action_plan_report": false,
            "executive_summary_report": false,
            "full_report": false
        },
        "reviewed_on": "",
        "risk": "low",
        "school": "UC Berkeley",
        "stats": {
            "instagram": {
                "negative_percentage": 10.26,
                "neutral_percentage": 32.05,
                "positive_percentage": 57.69,
                "total": 0,
                "total_negative": 8,
                "total_neutral": 25,
                "total_positive": 45
            },
            "negative_percentage": 10.26,
            "neutral_percentage": 32.05,
            "positive_percentage": 57.69,
            "total": 78,
            "total_negative": 8,
            "total_neutral": 25,
            "total_positive": 45,
            "tweets": {
                "negative_percentage": 0,
                "neutral_percentage": 0,
                "positive_percentage": 0,
                "total": 0,
                "total_negative": 0,
                "total_neutral": 0,
                "total_positive": 0
            },
            "videos": {
                "total": 0
            }
        },
        "status": "Processing",
        "team": "Blu Chip Analytics",
        "twitter": "",
        "whatsapp": "",
        "sport": "Football",
    },
    "00a6dd5c-8fe9-4a29-8478-f736bbf817b9": {
        "_id": "00a6dd5c-8fe9-4a29-8478-f736bbf817b9",
        "athleteType": "Roster",
        "conference": "NFL",
        "conferenceType": "Professional",
        "conference_type": "Professional",
        "consent": "Received",
        "consent_form_id": "288f23b3-4b28-43fb-9cfb-99021f56eb0d",
        "created_on": "03/17/2021, 20:21:19",
        "dob": "",
        "email": "info@bluchipanalytics.com",
        "full_name": "Tommy Pegus",
        "hometown": "Johns Creek, GA",
        "image_url": "http://127.0.0.1:3434/export/default_profile_pic",
        "insights": {
            "action_plan": {
                "Connection is Key": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Connection is Key",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Establish the Environment": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Establish the Environment",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Mental Mindset": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Mental Mindset",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                }
            },
            "custom_traits": {
                "character": {
                    "description": "An individual\u2019s emotional and mental qualities based on their morals, virtues, and humility that aid in executing tasks.",
                    "label": "Character",
                    "score": 66.543875,
                    "sub": {
                        "hard_working": {
                            "left_label": "Hard-Working",
                            "right_label": "Lazy",
                            "score": 67.1565,
                            "text": ""
                        },
                        "high_integrity": {
                            "left_label": "High Integrity",
                            "right_label": "Low Integrity",
                            "score": 71.20575,
                            "text": ""
                        },
                        "humble": {
                            "left_label": "Humility",
                            "right_label": "Arrogant",
                            "score": 61.269375,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of character. Individuals may work hard in certain performance domains. They may challenge others and express some levels of gratitude, humility, and integrity. Individuals may not be consistent in their ability to meet expectations, respect, and respond in positive manner in all situations."
                },
                "coachability": {
                    "description": "An individual\u2019s ability to listen, learn, be open to feedback, manage criticism, adapt their skill set, and perform at their optimal level.",
                    "label": "Coachability",
                    "score": 68.93414062500001,
                    "sub": {
                        "dependable": {
                            "left_label": "Dependable",
                            "right_label": "Unreliable",
                            "score": 75.32578125,
                            "text": ""
                        },
                        "willingness_to_learn ": {
                            "left_label": "Willingness to Learn",
                            "right_label": "Closed Minded",
                            "score": 62.54250000000001,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 70-100% demonstrate a high level of coachability. The individual will be highly dependable, arrive on time to meetings, complete performance goals, meet deadlines, and adapt to new training plans. Individuals are open to feedback, have a high tendency and desire to learn and grow from others. Individuals will respond well and adapt training goals to optimize their performance."
                },
                "composure": {
                    "description": "An individual\u2019s ability to remain calm, persistent, and in control under pressure situations or after mistakes. Individuals\u2019 thrive in high pressure situations and demonstrate enhanced performance outcomes.",
                    "label": "Composure",
                    "score": 72.642890625,
                    "sub": {
                        "calm": {
                            "left_label": "Calm",
                            "right_label": "Susceptible to Stress",
                            "score": 71.62828125000001,
                            "text": ""
                        },
                        "driven": {
                            "left_label": "Driven",
                            "right_label": "Unambitious",
                            "score": 73.6575,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 70-100% demonstrate a high level of composure. Individuals have the ability to manage emotions, stay calm during high stress situations, think clearly, and perform optimally in high pressure situations. Some individuals may strive in high pressure situations and perform at optimal level. Individuals have high drive and passion to meet performance outcomes and remain calm in pursuit of excellence."
                },
                "leadership": {
                    "description": "An individual\u2019s ability to influence, inspire, and communicate a common goal to others.",
                    "label": "Leadership",
                    "score": 75.044625,
                    "sub": {
                        "accountable": {
                            "left_label": "Accountable",
                            "right_label": "Unaccountable",
                            "score": 71.595,
                            "text": ""
                        },
                        "confident": {
                            "left_label": "Confident",
                            "right_label": "Insecure",
                            "score": 73.56975,
                            "text": ""
                        },
                        "vocal": {
                            "left_label": "Vocal",
                            "right_label": "Unspoken",
                            "score": 79.96912499999999,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 70-100% demonstrate a high level of leadership capacity. Individuals will be accountable, inspire others to work toward a common goal, assist in communicating goals, and cultural message to others. Individuals are highly self-confident and display their belief in themselves and others through behavior and communicating vocally."
                }
            },
            "executive_summary": {
                "Significant Background Information": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Significant Background Information",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Father played one season in NFL ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "f8a74",
                            "text": "Foot injury senior year of high school that it took him 6 months to be able to run again - required emergency surgery",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Symptoms and Information Captured": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Symptoms and Information Captured",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 16,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "5ssk7",
                            "text": "Overall score: 1",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 12,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "8pi69",
                            "text": "Key Traits: Appears to demonstrate good character through integrity and working hard. Demonstrates coachability through being dependable and having an openness to learning. Appears to be able to maintain composure in stressful situations and may demonstrate leadership qualities by being vocal, confident, and accountable.",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 11,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "2530l",
                            "text": "Strengths: Appears to be cooperative with others and is trusting of others. May demonstrate an ability to keep his moral standards. Appears to be driven and disciplined to completes tasks to the best of his ability. Appears to be confident in self and abilities and can appropriately respond in stressful environments. May demonstrate a strong desire to be sociable and connect with others.",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 18,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "3e1o1",
                            "text": "Areas for Growth: May demonstrate turning the attention on himself and seeking the approval of others. Appears to be a strict follower of rules and authority and might do without questioning. Appears to be practical in following traditions and approaches that are familiar. May demonstrate a reservation about taking chances or risks.",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 15,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "2ab6k",
                            "text": "Risks/Concerns: May demonstrate being too rigid to new and creative ways of accomplishing tasks by requiring hard facts. ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 19,
                                    "offset": 0,
                                    "style": "BOLD"
                                },
                                {
                                    "length": 181,
                                    "offset": 387,
                                    "style": "color-rgba(0,0,0,0.87)"
                                },
                                {
                                    "length": 181,
                                    "offset": 387,
                                    "style": "bgcolor-rgb(255,255,255)"
                                },
                                {
                                    "length": 181,
                                    "offset": 387,
                                    "style": "fontsize-16"
                                },
                                {
                                    "length": 181,
                                    "offset": 387,
                                    "style": "fontfamily-Roboto"
                                }
                            ],
                            "key": "c42gl",
                            "text": "Themes from posts: Emoji use (prayer hands, 100, ); \u201cgrateful and blessed\u201d used often - religious posts (\u201cDear God, Today I woke up. I am healthy. I am alive. Thank you\u201d, \u201cMan I\u2019m so excited for what Gods got planned for me in the future\u201d); gratitude and shoutouts to others; many references to family and team: \u201cSo glad I joined the Irish, it\u2019s the best football family in college!!!\u201d, \u201cI\u2019m just hoping to make my family and future kids proud one day\u201d, \u201cI love my team man, I would die for each and every one of them. Now it\u2019s back to all we\u2019ve ever known. MF work\u201d; \u201cjust tryin to be great\u201d; emotional posts in positive aspect - \u201ccan\u2019t sleep I\u2019m so excited for tomorrow!!\u201d - did not see negative expressions; \u201cThese kind of comments just motivate me more\u201d - posts from haters; Blackout Tuesday post for BLM",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                }
            },
            "personality traits": {
                "agreeableness": {
                    "score": 65.0625,
                    "sub": {
                        "altruism": {
                            "score": 67.17,
                            "text": "The individual is moderately altruistic. He/she has an inclination to help others with 'no strings attached' and feel fulfilled doing so."
                        },
                        "cooperation": {
                            "score": 66.135,
                            "text": "The individual is moderately accommodating. He/she has an inclination to compromise and/or please others. "
                        },
                        "modesty": {
                            "score": 52.2675,
                            "text": "The individual moderately avoids attention. He/she has an inclination to shy away from being the focus of attention if possible."
                        },
                        "sympathy": {
                            "score": 51.5625,
                            "text": "The individual is empathetic. When an opportunity presents, he/she is able to put him/herself in others' shoes and has an inclination to show compassion toward others. "
                        },
                        "trust": {
                            "score": 79.4025,
                            "text": "The individual is a highly trusting person. He/she has a high tendency to believe people are inherently good and trust them easily, regardless of their actions/inactions that substantiate or refute this disposition."
                        },
                        "uncompromising": {
                            "score": 73.845,
                            "text": "The individual is unwilling to break his/her own moral standards and has an inclination to be uncompromising in his/her values and beliefs."
                        }
                    },
                    "text": "Agreeableness refers to an individual's general congeniality and ability to put other people's needs before their own."
                },
                "conscientiousness": {
                    "score": 73.14750000000001,
                    "sub": {
                        "achievement_striving": {
                            "score": 79.1025,
                            "text": "The individual is a highly driven person. He/she has a tendency to set high goals for him/herself and work hard to achieve them. Individuals in this range may appear to be compulsive or overly focused on achieving lofty goals."
                        },
                        "cautiousness": {
                            "score": 71.08500000000001,
                            "text": "The individual is a moderately cautious thinker. He/she has an inclination to pause to think and consider before making decisions and/or taking action. "
                        },
                        "dutifulness": {
                            "score": 77.49,
                            "text": "The individual is highly dutiful. He/she has a high tendency to take his/her work and responsibilities seriously and work towards completing them even if it is inopportune or unhealthy to do so. "
                        },
                        "orderliness": {
                            "score": 58.665,
                            "text": "The individual is moderately organized. He/she has an inclination of needing to strucutre and plan events and surroundings. "
                        },
                        "self_discipline": {
                            "score": 81.75,
                            "text": "The individual is highly persistent. He/she is tenacious and will persevere through difficult and challenging tasks."
                        },
                        "self_efficacy": {
                            "score": 70.80000000000001,
                            "text": "The individual is a moderately self-assured person. He/she has an inclination on putting faith in his/her abilities and skills."
                        }
                    },
                    "text": "Conscientiousness refers to an Indvidual's propensity for orderliness and a need to plan. "
                },
                "emotional_range": {
                    "score": 78.85499999999999,
                    "sub": {
                        "calm_in_pressure": {
                            "score": 75.87000000000001,
                            "text": "The individual is highly prone to stress. He/she has a high tendency to become anxious and overwhelmed in the face of stress."
                        },
                        "composure": {
                            "score": 73.275,
                            "text": "The individual is a moderately bad-tempered person. He/she has an inclination of resorting to anger in irritable/unfavorable situations."
                        },
                        "contentment": {
                            "score": 84.7125,
                            "text": "The individual is a highly negative person. He/she has a high tendency to have a negative outlook on life and perceive the unfavourable side of situations."
                        },
                        "self_assuredness": {
                            "score": 82.3725,
                            "text": "The individual is highly anxious. He/she has a high tendency of worrying about necessary and unnecessary future scenarios."
                        },
                        "self_confidence": {
                            "score": 88.4475,
                            "text": "The individual is a highly self-conscious person. He/she has a high tendency to be strongly affected (mostly negatively) by others perception of one self."
                        },
                        "self_control": {
                            "score": 68.7525,
                            "text": "The individual is moderately self-indulgent. He/she has an inclination of being easily tempted."
                        }
                    },
                    "text": "Emotional Stability refers to how an an individual tends to respond to his/her environment or experience a negative state of mind."
                },
                "extroversion": {
                    "score": 77.1075,
                    "sub": {
                        "activity_level": {
                            "score": 77.55,
                            "text": "The individual has a strong preference for more tasks at a faster pace. He/she will be engaged with several tasks at once. While he/she may be involved in many activities, busyness does not necessarily equate to productivity. "
                        },
                        "assertiveness": {
                            "score": 81.9075,
                            "text": "The individual is a highly assertive person. He/she has a tendency to take charge in group environments. This person may appear as a natural leader, although he/she may be seen as 'bossy' or be perceived as micomanaging others."
                        },
                        "cheerfulness": {
                            "score": 89.415,
                            "text": "The individual displayes feelings of positivity, joy, enthusiasm, and optimism outwardly. "
                        },
                        "excitement_seeking": {
                            "score": 35.370000000000005,
                            "text": "The individual has a moderate preference for calmness. He/she has an inclination to actively avoid excitement and search for calmness."
                        },
                        "gregariousness": {
                            "score": 88.92,
                            "text": "The individual is highly sociable. He/she has a strong preference/tendency to enjoy large social gatherings regularly. "
                        },
                        "outgoing": {
                            "score": 89.49,
                            "text": "The individual is a highly outgoing person. He/she has a high tendency of being friendly toward new people and has a preference for being around others. "
                        }
                    },
                    "text": "Extroversion refers to an individual's tendency to seek stimulation via the company of others. Extroversion/Introversion does not imply interest in others; it refers to the energy one feels as a result of interactions with others."
                },
                "openness": {
                    "score": 28.1325,
                    "sub": {
                        "adventurousness": {
                            "score": 29.924999999999997,
                            "text": "The individual has a low level of creativity and curiosity for new experiences. He/she has an inclination of accepting tradition and status quo."
                        },
                        "artistic_interests": {
                            "score": 23.8275,
                            "text": "The individual has a very low level of enthusiasm about creativity or seeking beautiful experiences. He/she has a very low-to-no need or preference to seek experiences that are aesthetically pleasing. "
                        },
                        "authority_challenging": {
                            "score": 16.605,
                            "text": "The individual is complient, traditional, and obedient. He/she has a high tendency to obey authority, follow rules, and be closed to 'unconventional' lifesyles and values/beliefs."
                        },
                        "emotionality": {
                            "score": 53.6175,
                            "text": "The individual is moderately emotionally aware. He/she has an inclination of being in tune with his/her feelings and being able to express them."
                        },
                        "imagination": {
                            "score": 18.855,
                            "text": "The individual has a high tendency of focusing on hard facts rather than imagination. "
                        },
                        "intellect": {
                            "score": 25.9725,
                            "text": "The individual is moderately practical. He/she has an inclination of dealing with what is rather than what could be."
                        }
                    },
                    "text": "Openness refers to an individual's general willingness to engage in new experiences and learn new things. Individuals that score high in Openness are more imaginative and creative. Those low in Openness tend to be more analytical and resistant to change and prefer tradition."
                }
            },
            "psychological disorders": {
                "antisocial": {
                    "score": 16.578841366887623,
                    "text": "Possibility of existing level of Antisocial Personality Disorder is very low or none."
                },
                "avoidant": {
                    "score": 20.128625769249787,
                    "text": "Possibility of existing level of Avoidant Personality Disorder is very low or none."
                },
                "borderline": {
                    "score": 18.885423379041836,
                    "text": "Possibility of existing level of Borderline Personality Disorder is very low or none."
                },
                "dependent": {
                    "score": 20.971516119229875,
                    "text": "Possibility of existing level of Dependent Personality Disorder is very low or none."
                },
                "histrionic": {
                    "score": 85.79089097618304,
                    "text": "A considerable possibility of individual being self-centered and with less or no concern for others, rash decision making, constantly seeking approval and tendency to act with exaggerated actions and emotions. A closer look at the individual is recommended."
                },
                "narcissistic": {
                    "score": 45.75050927962141,
                    "text": "A moderate possibility of believing that he or she is special and unique while expecting admiration from others."
                },
                "obsessive_compulsive": {
                    "score": 87.76440505752875,
                    "text": "A considerable possibility of the individual showing compulsive and obsessive behaviours and a closer look at the individual is recommended."
                },
                "paranoid": {
                    "score": 18.29508611180904,
                    "text": "Possibility of existing level of Paranoid Personality Disorder is very low or none."
                },
                "schizotypal": {
                    "score": 19.173419179259845,
                    "text": "Possibility of existing level of Schizotypal Personality Disorder is very low or none."
                }
            },
            "risk": {
                "Aggression/Violence": {
                    "comments": [

                    ],
                    "score": 10,
                    "text": "Individuals low (1-3) in this range demonstrates minimal risk in aggression/violence. The individual demonstrates the ability to manage emotions (e.g. irritability, frustration, annoyances) and may utilize levels of anger as a motivating source for success."
                },
                "Delinquent Behavior": {
                    "comments": [

                    ],
                    "score": 30,
                    "text": "Individuals low (1-3) in this range demonstrate low expression of delinquent behavior. Individuals in this range adhere to societal, cultural norms. Generally, following standard procedures and expectations from authority figures. "
                },
                "Drug/Alcohol": {
                    "comments": [

                    ],
                    "score": 20,
                    "text": "Individuals low (1-3) in this range demonstrate minimal risky behavior in drug/alcohol use. Individuals either demonstrate normative minimal engagement in acceptable use that falls within normative range or does not indicate substance use per analysis. Should be noted individuals may be partaking in higher risk behavior but minimizing their full expression via social media/ public expression"
                },
                "Emotional Distress": {
                    "comments": [

                    ],
                    "score": 10,
                    "text": "Individuals low (1-3) in this range demonstrate minimal levels of emotional distress. Individuals can manage situational stressors. They are likely able to cope with a high stress, manage emotions effectively, and function in high adaptable scenarios. Individuals will most likely have positive social support, effective communication tools, and high awareness in one\u2019s self."
                },
                "Overall": {
                    "comments": [

                    ],
                    "score": 22.0,
                    "text": "Risk score invalid to generate analysis text"
                },
                "Sexual Behavior": {
                    "comments": [

                    ],
                    "score": 40,
                    "text": "Individuals moderate (4-6) in this range indicate a level of identified sexual behavior expressed through social media accounts. Behaviors may indicate discussions/statements exploring/posting sexual content, discussing relationships, seeking potential partner, demonstrating significant romantic relationships, and/or indication they are a parent. Should be noted individuals may be partaking in higher risk behavior but minimizing their full expression via social media/public expression."
                }
            }
        },
        "instagram": "tommypegus",
        "is_paid": true,
        "is_valid_format": true,
        "linkedin": "",
        "links": [
            {
                "category": "youtube",
                "link_id": "ae4f4b6c-d600-47e0-a607-44d8940e4f4e",
                "source_ln": "en",
                "url": "https://www.youtube.com/"
            },
            {
                "category": "video",
                "link_id": "6259b781-4a40-4a15-9370-1e0006ddb206",
                "source_ln": "en",
                "url": "https://genemssystems-my.sharepoint.com/personal/madhu_genems_com/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fmadhu%5Fgenems%5Fcom%2FDocuments%2FRecordings%2FFitInsights%20%2D%20UX%20review%2D20220817%5F203641%2DMeeting%20Recording%2Emp4&parent=%2Fpersonal%2Fmadhu%5Fgenems%5Fcom%2FDocuments%2FRecordings&ga=1"
            }
        ],
        "message": {
            "button_label": "",
            "content": "",
            "severity": "info",
            "show": false,
            "title": ""
        },
        "possible_duplicate_info": {
            "duplicate_candidate": {

            },
            "duplicate_candidate_id": "e83e2b3a-4413-467f-8d1a-923b7365c2e1",
            "is_possible_duplicate": true
        },
        "race": "",
        "relationship_status": "",
        "report_settings": {
            "_id": "00a6dd5c-8fe9-4a29-8478-f736bbf817b9",
            "action_plan": {
                "allowed": true
            },
            "executive_summary": {
                "allowed": true
            },
            "full_report": {
                "allowed": true
            }
        },
        "reports": {
            "action_plan_report": false,
            "executive_summary_report": false,
            "full_report": false
        },
        "reviewed_on": "03/18/2021, 20:05:12",
        "risk": "low",
        "school": "Notre Dame",
        "stats": {
            "instagram": {
                "negative_percentage": 19.51,
                "neutral_percentage": 31.71,
                "positive_percentage": 48.78,
                "total": 376,
                "total_negative": 8,
                "total_neutral": 13,
                "total_positive": 20
            },
            "negative_percentage": 8.63,
            "neutral_percentage": 19.18,
            "positive_percentage": 72.18,
            "total": 417,
            "total_negative": 36,
            "total_neutral": 80,
            "total_positive": 301,
            "tweets": {
                "negative_percentage": 7.45,
                "neutral_percentage": 17.82,
                "positive_percentage": 74.73,
                "total": 376,
                "total_negative": 28,
                "total_neutral": 67,
                "total_positive": 281
            },
            "videos": {
                "total": 2
            }
        },
        "status": "Completed",
        "team": "Blu Chip Analytics",
        "twitter": "tommypegus",
        "whatsapp": "",
        "sport": "Football",
    },
    "1105c25c-a3fa-49e7-bad8-413fd66a85ee": {
        "_id": "1105c25c-a3fa-49e7-bad8-413fd66a85ee",
        "analysed_on": "02/10/2021, 15:20:14",
        "consent": "Received",
        "consent_form_id": "56b2f6cc-83b3-4897-b6f1-81f1a2d7cb02",
        "created_on": "02/08/2021, 13:51:16",
        "dob": "",
        "email": "info@bluchipanalytics.com",
        "full_name": "Harry Mount",
        "hometown": "Cibolo, TX",
        "image_url": "http://127.0.0.1:3434/export/default_profile_pic",
        "insights": {
            "action_plan": {
                "Connection is Key": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Connection is Key",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Establish the Environment": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Establish the Environment",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Mental Mindset": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Mental Mindset",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                }
            },
            "custom_traits": {
                "character": {
                    "description": "An individual\u2019s emotional and mental qualities based on their morals, virtues, and humility that aid in executing tasks.",
                    "label": "Character",
                    "score": 59.8059375,
                    "sub": {
                        "hard_working": {
                            "left_label": "Hard-Working",
                            "right_label": "Lazy",
                            "score": 60.67199999999999,
                            "text": ""
                        },
                        "high_integrity": {
                            "left_label": "High Integrity",
                            "right_label": "Low Integrity",
                            "score": 62.202375,
                            "text": ""
                        },
                        "humble": {
                            "left_label": "Humility",
                            "right_label": "Arrogant",
                            "score": 56.5434375,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of character. Individuals may work hard in certain performance domains. They may challenge others and express some levels of gratitude, humility, and integrity. Individuals may not be consistent in their ability to meet expectations, respect, and respond in positive manner in all situations."
                },
                "coachability": {
                    "description": "An individual\u2019s ability to listen, learn, be open to feedback, manage criticism, adapt their skill set, and perform at their optimal level.",
                    "label": "Coachability",
                    "score": 62.770078125000005,
                    "sub": {
                        "dependable": {
                            "left_label": "Dependable",
                            "right_label": "Unreliable",
                            "score": 60.29578125,
                            "text": ""
                        },
                        "willingness_to_learn ": {
                            "left_label": "Willingness to Learn",
                            "right_label": "Closed Minded",
                            "score": 65.244375,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of coachability. Individuals may be moderately reliable, and adhere to meeting times, complete tasks, and perform as expected at times. Some may tend to be open to feedback, but could demonstrate difficulties managing criticism, or have difficulties adapting behavioral change to specific coaching feedback. Individuals may take time to learn and adjust performance goals."
                },
                "composure": {
                    "description": "An individual\u2019s ability to remain calm, persistent, and in control under pressure situations or after mistakes. Individuals\u2019 thrive in high pressure situations and demonstrate enhanced performance outcomes.",
                    "label": "Composure",
                    "score": 65.36034375,
                    "sub": {
                        "calm": {
                            "left_label": "Calm",
                            "right_label": "Susceptible to Stress",
                            "score": 68.8678125,
                            "text": ""
                        },
                        "driven": {
                            "left_label": "Driven",
                            "right_label": "Unambitious",
                            "score": 61.852875,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of composure. Individuals may have the capacity to remain calm and manage stressful situations to a degree. They have a moderate drive and purpose to strive for. In high pressure situations they may react and have difficulties managing their emotions on a consistent basis."
                },
                "leadership": {
                    "description": "An individual\u2019s ability to influence, inspire, and communicate a common goal to others.",
                    "label": "Leadership",
                    "score": 69.68525,
                    "sub": {
                        "accountable": {
                            "left_label": "Accountable",
                            "right_label": "Unaccountable",
                            "score": 65.38874999999999,
                            "text": ""
                        },
                        "confident": {
                            "left_label": "Confident",
                            "right_label": "Insecure",
                            "score": 70.195875,
                            "text": ""
                        },
                        "vocal": {
                            "left_label": "Vocal",
                            "right_label": "Unspoken",
                            "score": 73.471125,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 70-100% demonstrate a high level of leadership capacity. Individuals will be accountable, inspire others to work toward a common goal, assist in communicating goals, and cultural message to others. Individuals are highly self-confident and display their belief in themselves and others through behavior and communicating vocally."
                }
            },
            "executive_summary": {
                "Significant Background Information": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Significant Background Information",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Flipped from LSU to Texas. Mother was influential in getting him to visit UT.  ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "einu3",
                            "text": "Brother played football for Oklahoma State in 2013-2016 and was a Kansas City Chief. Younger brothers play football too (Texas Tech). ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "f0ver",
                            "text": "Is mixed race. ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "4jh5",
                            "text": "Wolf tattoo on arm represents \u201cloyalty but also they are savage\u201d ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "58ta8",
                            "text": "Injury issues in 2019 both in spring ball, required surgery, and in actual season; Midseason optout in 2020 ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "1sde1",
                            "text": "Beginning to understand that NFL is a business. ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "7qd5t",
                            "text": "Outspoken against racial injustice, took on entire leadership structure at U-T requesting renaming buildings, more diversity in school hall of fame. Spoke during school march.  ",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Symptoms and Information Captured": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Symptoms and Information Captured",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Overall Risk: 2",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 332,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                },
                                {
                                    "length": 11,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "4ael3",
                            "text": "Key Traits: High leadership (vocal, confident, accountable), high end of moderate composure (calm, driven), high end of moderate coachability (willingness to learn, dependable), moderate character (high integrity, hard working, humility). High agreeableness, conscientiousness, emotional stability, extraversion. Moderate openness. ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 231,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                },
                                {
                                    "length": 10,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "7pb18",
                            "text": "Strengths: Cooperative, tends to be trusting, compassionate, helpful, moderately altruistic. Moderately comfortable with attention and does not actively avoid it. Moderately empathetic, trusting and inclined to see best in others. ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 124,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                },
                                {
                                    "length": 17,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "av72i",
                            "text": "Areas of growth: May contradict others if needed. Moderately carefree and may shirk obligations for sake of personal ease.  ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 315,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                },
                                {
                                    "length": 15,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "2om7v",
                            "text": "Risks/concerns: Desire to be a well-rounded, successful, opinonated, assertive man could manifest through a belief that he bases self-esteem on approval of others, being self-centered or desires to be noticed, and will make rash decisions, act in exaggerated fashion or display such emotions, to get such attention.",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 31,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                },
                                {
                                    "length": 7,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "5b3qf",
                            "text": "Themes: All media is 2019-2021 ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 112,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "14ge",
                            "text": "-Frequent black heart emoji, cryface laugh usage, \u201clove\u201d \u201cWhen all else fails, make sure the love there\u201d (2020) ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 132,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "atfa3",
                            "text": "-Appreciates and retweets praise he\u2019s gotten for being a well-rounded, socially aware, student athlete. Wants his work to be seen.  ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 87,
                                    "offset": 0,
                                    "style": "color-rgba(0,0,0,0.87)"
                                },
                                {
                                    "length": 87,
                                    "offset": 0,
                                    "style": "bgcolor-rgb(255,255,255)"
                                },
                                {
                                    "length": 87,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                },
                                {
                                    "length": 87,
                                    "offset": 0,
                                    "style": "fontfamily-Roboto"
                                },
                                {
                                    "length": 87,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "a73r8",
                            "text": "-Enthusiastic, at times serious/intense/energetic/high emotion in his phrasing content ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 112,
                                    "offset": 0,
                                    "style": "color-rgba(0,0,0,0.87)"
                                },
                                {
                                    "length": 112,
                                    "offset": 0,
                                    "style": "bgcolor-rgb(255,255,255)"
                                },
                                {
                                    "length": 112,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                },
                                {
                                    "length": 112,
                                    "offset": 0,
                                    "style": "fontfamily-Roboto"
                                },
                                {
                                    "length": 112,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "enjkg",
                            "text": "-Self-motivated \u201cYour competition in the mirror\u201d (2020) \u201cMe vs me regardless who line up across against\u201d (2020) ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 16,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "1u5ba",
                            "text": "-Social justice ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 60,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "cvh1v",
                            "text": "-Displays empathy/sympathy, advocates for others. Sends RIP ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 88,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "9efqq",
                            "text": "-Speaks with high school athletes; works for a foodbank, displays charitable mentality  ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 195,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "9n1gj",
                            "text": "-Is opinionated on social and systemic issues; does express emotion when things happen he doesn\u2019t agree with, challenges others views. Call others morals \u201cdistorted\u201d but says who is he to judge. ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 78,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "3rvc",
                            "text": "-\u201cCan\u2019t be so attached to traditions you\u2019re afraid to create new ones\u201d (2020) ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 230,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                },
                                {
                                    "length": 220,
                                    "offset": 1,
                                    "style": "color-rgb(15,20,25)"
                                },
                                {
                                    "length": 220,
                                    "offset": 1,
                                    "style": "bgcolor-white"
                                },
                                {
                                    "length": 1,
                                    "offset": 1,
                                    "style": "fontsize-17.5pt"
                                },
                                {
                                    "length": 1,
                                    "offset": 1,
                                    "style": "fontfamily-Segoe UI\", sans-serif"
                                }
                            ],
                            "key": "f16ks",
                            "text": "-\u201cI\u2019m not here to influence or persuade. Just to gain and give perspective, but still understand and love at the end of the day regardless. Y\u2019all can keep twisting the narrative though, we good on this side still. Hook\u2019Em\u201d (2020) ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 59,
                                    "offset": 0,
                                    "style": "color-rgba(0,0,0,0.87)"
                                },
                                {
                                    "length": 59,
                                    "offset": 0,
                                    "style": "bgcolor-rgb(255,255,255)"
                                },
                                {
                                    "length": 59,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                },
                                {
                                    "length": 59,
                                    "offset": 0,
                                    "style": "fontfamily-Roboto"
                                },
                                {
                                    "length": 59,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "ei4ra",
                            "text": "-Praises/supports others hard work and football decisions. ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 57,
                                    "offset": 0,
                                    "style": "color-rgba(0,0,0,0.87)"
                                },
                                {
                                    "length": 57,
                                    "offset": 0,
                                    "style": "bgcolor-rgb(255,255,255)"
                                },
                                {
                                    "length": 57,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                },
                                {
                                    "length": 57,
                                    "offset": 0,
                                    "style": "fontfamily-Roboto"
                                },
                                {
                                    "length": 57,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "daqdh",
                            "text": "-Shouts out coaches who have supported and work with him ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 48,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "av0s4",
                            "text": "-Highlights his mother; interacts with brothers ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 32,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "5jv2m",
                            "text": "-Interactive with other players ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 34,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "f1ej8",
                            "text": "-Responds to retweets with humor  ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 37,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "acft0",
                            "text": "-Gameday motivation/mindset tweeting ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 84,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "62tm2",
                            "text": "-\u201cQuality over quantity. Just because you doing a lot, doesn\u2019t mean it\u2019s good work\u201d ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 29,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "7aps1",
                            "text": "-Music, basketball interests ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 73,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "eafrj",
                            "text": "-\u201cNever too high or low when adversity hit, just finish. #hookem\u201d (2020) ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 137,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "dik67",
                            "text": "-\u201cWanna test someone\u2019s manhood, have em work out in this midday Texas heat\u201d \u201cworked way too hard for this season to be cancelled\u201d (2020) ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 8,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "i4dk",
                            "text": "-\u201cOnLY\u201d ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 28,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "1lptn",
                            "text": "-Love and fun for the game. ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 15,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "1lmce",
                            "text": "***Interviews: ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 53,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "6codp",
                            "text": "-Honest, open, expressive nonverbal/facial demeanor. ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 172,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "bd3bm",
                            "text": "-*****\u201cHard on myself in general\u201d \u201cAt times, too hard on myself.\u201d Brother, others that played game have helped him understand to have fun, be present, treat it as a game.\u201d ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 81,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "p8cf",
                            "text": "-\u201cLead by example\u201d, create vibes through behavior. Expresses being accountable.  ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 16,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "2b8o5",
                            "text": "-Growth mindset ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 3,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "7lkbk",
                            "text": "   ",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                }
            },
            "personality traits": {
                "agreeableness": {
                    "score": 57.3675,
                    "sub": {
                        "altruism": {
                            "score": 56.3325,
                            "text": "The individual is moderately altruistic. He/she has an inclination to help others with 'no strings attached' and feel fulfilled doing so."
                        },
                        "cooperation": {
                            "score": 51.945,
                            "text": "The individual is moderately accommodating. He/she has an inclination to compromise and/or please others. "
                        },
                        "modesty": {
                            "score": 39.9225,
                            "text": "The individual is moderately comfortable with attention. He/she has an inclination to see him/herself in a positive manner and does not actively avoid attention."
                        },
                        "sympathy": {
                            "score": 70.125,
                            "text": "The individual is empathetic. When an opportunity presents, he/she is able to put him/herself in others' shoes and has an inclination to show compassion toward others. "
                        },
                        "trust": {
                            "score": 54.300000000000004,
                            "text": "The individual is a trusting person. He/she has an inclination to see the best in others and may be highly charitable."
                        },
                        "uncompromising": {
                            "score": 71.595,
                            "text": "The individual is unwilling to break his/her own moral standards and has an inclination to be uncompromising in his/her values and beliefs."
                        }
                    },
                    "text": "Agreeableness refers to an individual's general congeniality and ability to put other people's needs before their own."
                },
                "conscientiousness": {
                    "score": 61.4775,
                    "sub": {
                        "achievement_striving": {
                            "score": 64.38,
                            "text": "The individual is a moderately driven person. He/she has an inclination to set high goals and work hard to reach them them."
                        },
                        "cautiousness": {
                            "score": 61.02,
                            "text": "The individual is a moderately cautious thinker. He/she has an inclination to pause to think and consider before making decisions and/or taking action. "
                        },
                        "dutifulness": {
                            "score": 45.1725,
                            "text": "The individual is moderately carefree. He/she has an inclination to disregard obligations if it makes things easier for the individual."
                        },
                        "orderliness": {
                            "score": 72.57,
                            "text": "The individual is moderately organized. He/she has an inclination of needing to strucutre and plan events and surroundings. "
                        },
                        "self_discipline": {
                            "score": 72.3225,
                            "text": "The individual is persistent. He/she has an inclination of working through challenges without quitting."
                        },
                        "self_efficacy": {
                            "score": 53.407500000000006,
                            "text": "The individual is a moderately self-assured person. He/she has an inclination on putting faith in his/her abilities and skills."
                        }
                    },
                    "text": "Conscientiousness refers to an Indvidual's propensity for orderliness and a need to plan. "
                },
                "emotional_range": {
                    "score": 78.615,
                    "sub": {
                        "calm_in_pressure": {
                            "score": 76.485,
                            "text": "The individual is highly prone to stress. He/she has a high tendency to become anxious and overwhelmed in the face of stress."
                        },
                        "composure": {
                            "score": 73.97999999999999,
                            "text": "The individual is a moderately bad-tempered person. He/she has an inclination of resorting to anger in irritable/unfavorable situations."
                        },
                        "contentment": {
                            "score": 84.1575,
                            "text": "The individual is a highly negative person. He/she has a high tendency to have a negative outlook on life and perceive the unfavourable side of situations."
                        },
                        "self_assuredness": {
                            "score": 83.42250000000001,
                            "text": "The individual is highly anxious. He/she has a high tendency of worrying about necessary and unnecessary future scenarios."
                        },
                        "self_confidence": {
                            "score": 80.6775,
                            "text": "The individual is a highly self-conscious person. He/she has a high tendency to be strongly affected (mostly negatively) by others perception of one self."
                        },
                        "self_control": {
                            "score": 72.99,
                            "text": "The individual is moderately self-indulgent. He/she has an inclination of being easily tempted."
                        }
                    },
                    "text": "Emotional Stability refers to how an an individual tends to respond to his/her environment or experience a negative state of mind."
                },
                "extroversion": {
                    "score": 74.25750000000001,
                    "sub": {
                        "activity_level": {
                            "score": 65.46000000000001,
                            "text": "The individual moderately prefers more tasks at a faster pace. He/she has an inclination to focus on more than one project or task at at time. "
                        },
                        "assertiveness": {
                            "score": 77.07,
                            "text": "The individual is a highly assertive person. He/she has a tendency to take charge in group environments. This person may appear as a natural leader, although he/she may be seen as 'bossy' or be perceived as micomanaging others."
                        },
                        "cheerfulness": {
                            "score": 88.0275,
                            "text": "The individual displayes feelings of positivity, joy, enthusiasm, and optimism outwardly. "
                        },
                        "excitement_seeking": {
                            "score": 49.957499999999996,
                            "text": "The individual has a moderate preference for calmness. He/she has an inclination to actively avoid excitement and search for calmness."
                        },
                        "gregariousness": {
                            "score": 82.155,
                            "text": "The individual is highly sociable. He/she has a strong preference/tendency to enjoy large social gatherings regularly. "
                        },
                        "outgoing": {
                            "score": 82.8525,
                            "text": "The individual is a highly outgoing person. He/she has a high tendency of being friendly toward new people and has a preference for being around others. "
                        }
                    },
                    "text": "Extroversion refers to an individual's tendency to seek stimulation via the company of others. Extroversion/Introversion does not imply interest in others; it refers to the energy one feels as a result of interactions with others."
                },
                "openness": {
                    "score": 56.0775,
                    "sub": {
                        "adventurousness": {
                            "score": 61.927499999999995,
                            "text": "The individual has a moderate level of creativity and curiousity for new experiences. He/she has an inclination of having a wide range of interest and can visualize and appreciate the abstract or imagined. "
                        },
                        "artistic_interests": {
                            "score": 63.63,
                            "text": "The individual has a moderate level of enthusiasm about creativity or seeking beautiful experiences. He/she has an inclination of searching for creative experiences."
                        },
                        "authority_challenging": {
                            "score": 40.2975,
                            "text": "The individual is a moderate rule follower. He/she has an inclination toward obedience, following authority, and respecting rules and traditions. "
                        },
                        "emotionality": {
                            "score": 59.722500000000004,
                            "text": "The individual is moderately emotionally aware. He/she has an inclination of being in tune with his/her feelings and being able to express them."
                        },
                        "imagination": {
                            "score": 47.9775,
                            "text": "The individual has an inclination of being grounded on facts when thinking, but may be able to appreciate or visualize new concepts when presented by others. "
                        },
                        "intellect": {
                            "score": 62.902499999999996,
                            "text": "The individual is moderately philosophical. He/she has an inclination of being unsatisfied of what is and tends to prefer exploring new possibilities and what could be."
                        }
                    },
                    "text": "Openness refers to an individual's general willingness to engage in new experiences and learn new things. Individuals that score high in Openness are more imaginative and creative. Those low in Openness tend to be more analytical and resistant to change and prefer tradition."
                }
            },
            "psychological disorders": {
                "antisocial": {
                    "score": 24.098799102933054,
                    "text": "Possibility of existing level of Antisocial Personality Disorder is very low or none."
                },
                "avoidant": {
                    "score": 19.750668736976078,
                    "text": "Possibility of existing level of Avoidant Personality Disorder is very low or none."
                },
                "borderline": {
                    "score": 21.861446174007227,
                    "text": "Possibility of existing level of Borderline Personality Disorder is very low or none."
                },
                "dependent": {
                    "score": 19.564345912517663,
                    "text": "Possibility of existing level of Dependent Personality Disorder is very low or none."
                },
                "histrionic": {
                    "score": 85.04606999997837,
                    "text": "A considerable possibility of individual being self-centered and with less or no concern for others, rash decision making, constantly seeking approval and tendency to act with exaggerated actions and emotions. A closer look at the individual is recommended."
                },
                "narcissistic": {
                    "score": 49.338253055026975,
                    "text": "A moderate possibility of believing that he or she is special and unique while expecting admiration from others."
                },
                "obsessive_compulsive": {
                    "score": 79.27130363675755,
                    "text": "A considerable possibility of the individual showing compulsive and obsessive behaviours and a closer look at the individual is recommended."
                },
                "paranoid": {
                    "score": 21.387004953292216,
                    "text": "Possibility of existing level of Paranoid Personality Disorder is very low or none."
                },
                "schizotypal": {
                    "score": 20.78885678511233,
                    "text": "Possibility of existing level of Schizotypal Personality Disorder is very low or none."
                }
            },
            "risk": {
                "Aggression/Violence": {
                    "comments": [

                    ],
                    "score": 10,
                    "text": "Individuals low (1-3) in this range demonstrates minimal risk in aggression/violence. The individual demonstrates the ability to manage emotions (e.g. irritability, frustration, annoyances) and may utilize levels of anger as a motivating source for success."
                },
                "Delinquent Behavior": {
                    "comments": [

                    ],
                    "score": 30,
                    "text": "Individuals low (1-3) in this range demonstrate low expression of delinquent behavior. Individuals in this range adhere to societal, cultural norms. Generally, following standard procedures and expectations from authority figures. "
                },
                "Drug/Alcohol": {
                    "comments": [

                    ],
                    "score": 30,
                    "text": "Individuals low (1-3) in this range demonstrate minimal risky behavior in drug/alcohol use. Individuals either demonstrate normative minimal engagement in acceptable use that falls within normative range or does not indicate substance use per analysis. Should be noted individuals may be partaking in higher risk behavior but minimizing their full expression via social media/ public expression"
                },
                "Emotional Distress": {
                    "comments": [

                    ],
                    "score": 10,
                    "text": "Individuals low (1-3) in this range demonstrate minimal levels of emotional distress. Individuals can manage situational stressors. They are likely able to cope with a high stress, manage emotions effectively, and function in high adaptable scenarios. Individuals will most likely have positive social support, effective communication tools, and high awareness in one\u2019s self."
                },
                "Overall": {
                    "comments": [

                    ],
                    "score": 22.0,
                    "text": "Risk score invalid to generate analysis text"
                },
                "Sexual Behavior": {
                    "comments": [

                    ],
                    "score": 30,
                    "text": "Individuals low (1-3) in this range demonstrate minimal sexual expression, minimal indication of sexual behavioral risk. Should be noted individuals may be partaking in risky behavior but minimizing their expression via social media/public expression."
                }
            }
        },
        "instagram": "hmount_7",
        "is_paid": true,
        "is_valid_format": true,
        "linkedin": "",
        "links": [
            {
                "category": "youtube",
                "link_id": "5b984abc-6291-4773-af35-bebfa2abbf50",
                "url": "https://www.youtube.com/watch?v=cggQPZOBW2Q"
            }
        ],
        "message": {
            "button_label": "",
            "content": "",
            "severity": "info",
            "show": false,
            "title": ""
        },
        "possible_duplicate_info": {
            "duplicate_candidate": {

            },
            "duplicate_candidate_id": "",
            "is_possible_duplicate": false
        },
        "race": "",
        "relationship_status": "",
        "report_settings": {
            "_id": "1105c25c-a3fa-49e7-bad8-413fd66a85ee",
            "action_plan": {
                "allowed": true
            },
            "executive_summary": {
                "allowed": true
            },
            "full_report": {
                "allowed": true
            }
        },
        "reports": {
            "action_plan_report": false,
            "executive_summary_report": false,
            "full_report": false
        },
        "reviewed_on": "03/05/2021, 21:47:29",
        "risk": "low",
        "school": "Texas",
        "stats": {
            "instagram": {
                "negative_percentage": 0.0,
                "neutral_percentage": 40.0,
                "positive_percentage": 60.0,
                "total": 473,
                "total_negative": 0,
                "total_neutral": 2,
                "total_positive": 3
            },
            "negative_percentage": 10.67,
            "neutral_percentage": 21.55,
            "positive_percentage": 67.78,
            "total": 478,
            "total_negative": 51,
            "total_neutral": 103,
            "total_positive": 324,
            "tweets": {
                "negative_percentage": 10.78,
                "neutral_percentage": 21.35,
                "positive_percentage": 67.86,
                "total": 473,
                "total_negative": 51,
                "total_neutral": 101,
                "total_positive": 321
            },
            "videos": {
                "total": 1
            }
        },
        "status": "Completed",
        "team": "Blu Chip Analytics",
        "twitter": "hmount_7",
        "whatsapp": "",
        "sport": "Football",

    },
    "1010339d-b220-469f-a9d8-86e0d2872a0b": {
        "_id": "1010339d-b220-469f-a9d8-86e0d2872a0b",
        "analysed_on": "04/01/2022, 11:06:35",
        "consent": "Received",
        "consent_form_id": "33acf7e1-1724-456d-b86e-e59dcdc7a365",
        "created_on": "03/30/2022, 15:45:34",
        "dob": "3/26/2001",
        "email": "kyle.james@bluchipanalytics.com",
        "full_name": "Kyle James",
        "hometown": "",
        "image_url": "http://127.0.0.1:3434/export/default_profile_pic",
        "insights": {
            "action_plan": {
                "Connection is Key": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Connection is Key",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Establish the Environment": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Establish the Environment",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Mental Mindset": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Mental Mindset",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                }
            },
            "custom_traits": {
                "character": {
                    "description": "An individual\u2019s emotional and mental qualities based on their morals, virtues, and humility that aid in executing tasks.",
                    "label": "Character",
                    "score": 55.4715625,
                    "sub": {
                        "hard_working": {
                            "left_label": "Hard-Working",
                            "right_label": "Lazy",
                            "score": 54.12675000000001,
                            "text": ""
                        },
                        "high_integrity": {
                            "left_label": "High Integrity",
                            "right_label": "Low Integrity",
                            "score": 56.755125,
                            "text": ""
                        },
                        "humble": {
                            "left_label": "Humility",
                            "right_label": "Arrogant",
                            "score": 55.5328125,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of character. Individuals may work hard in certain performance domains. They may challenge others and express some levels of gratitude, humility, and integrity. Individuals may not be consistent in their ability to meet expectations, respect, and respond in positive manner in all situations."
                },
                "coachability": {
                    "description": "An individual\u2019s ability to listen, learn, be open to feedback, manage criticism, adapt their skill set, and perform at their optimal level.",
                    "label": "Coachability",
                    "score": 52.66171875,
                    "sub": {
                        "dependable": {
                            "left_label": "Dependable",
                            "right_label": "Unreliable",
                            "score": 49.381875,
                            "text": ""
                        },
                        "willingness_to_learn ": {
                            "left_label": "Willingness to Learn",
                            "right_label": "Closed Minded",
                            "score": 55.9415625,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of coachability. Individuals may be moderately reliable, and adhere to meeting times, complete tasks, and perform as expected at times. Some may tend to be open to feedback, but could demonstrate difficulties managing criticism, or have difficulties adapting behavioral change to specific coaching feedback. Individuals may take time to learn and adjust performance goals."
                },
                "composure": {
                    "description": "An individual\u2019s ability to remain calm, persistent, and in control under pressure situations or after mistakes. Individuals\u2019 thrive in high pressure situations and demonstrate enhanced performance outcomes.",
                    "label": "Composure",
                    "score": 55.48396875,
                    "sub": {
                        "calm": {
                            "left_label": "Calm",
                            "right_label": "Susceptible to Stress",
                            "score": 59.3671875,
                            "text": ""
                        },
                        "driven": {
                            "left_label": "Driven",
                            "right_label": "Unambitious",
                            "score": 51.600750000000005,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of composure. Individuals may have the capacity to remain calm and manage stressful situations to a degree. They have a moderate drive and purpose to strive for. In high pressure situations they may react and have difficulties managing their emotions on a consistent basis."
                },
                "leadership": {
                    "description": "An individual\u2019s ability to influence, inspire, and communicate a common goal to others.",
                    "label": "Leadership",
                    "score": 52.30225,
                    "sub": {
                        "accountable": {
                            "left_label": "Accountable",
                            "right_label": "Unaccountable",
                            "score": 52.2525,
                            "text": ""
                        },
                        "confident": {
                            "left_label": "Confident",
                            "right_label": "Insecure",
                            "score": 58.517250000000004,
                            "text": ""
                        },
                        "vocal": {
                            "left_label": "Vocal",
                            "right_label": "Unspoken",
                            "score": 46.137,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of leadership capacity. Individuals may work hard toward goals and can inspire small groups or a few individuals. Individuals may lack the ability to be vocal and speak to larger members to inspire a large group to meet cultural goals. Individuals may have the capacity to make small changes and influence members around them. Individuals may have moderate level of confidence, but poor performances or influence may negatively impact self-worth and ability to perform at optimal level."
                }
            },
            "executive_summary": {
                "Significant Background Information": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Significant Background Information",
                            "type": "header-three"
                        },
                        {
                            "data": {
                                "margin-left": "0px"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 152,
                                    "offset": 0,
                                    "style": "fontfamily-Helvetica Neue"
                                },
                                {
                                    "length": 152,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "7ta09",
                            "text": "-Tore ACL in CFP title game; says he has not been focusing on his draft position, is \u201ctrying to tune it all out, get right and back to playing football\u201d",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "margin-left": "0px"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 56,
                                    "offset": 0,
                                    "style": "fontfamily-Helvetica Neue"
                                },
                                {
                                    "length": 56,
                                    "offset": 0,
                                    "style": "fontsize-14"
                                }
                            ],
                            "key": "bnaa8",
                            "text": "-In interview says, \u201cI use any tiny thing as motivation\u201d\n",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Symptoms and Information Captured": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Symptoms and Information Captured",
                            "type": "header-three"
                        },
                        {
                            "data": {
                                "margin-left": "0px"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 88,
                                    "offset": 0,
                                    "style": "fontfamily-Helvetica Neue"
                                },
                                {
                                    "length": 88,
                                    "offset": 0,
                                    "style": "BOLD"
                                },
                                {
                                    "length": 88,
                                    "offset": 0,
                                    "style": "fontsize-18"
                                }
                            ],
                            "key": "5ssk7",
                            "text": "***Not enough social media content for typical analysis, interpret profile with caution.",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 9,
                                    "offset": 0,
                                    "style": "fontfamily-Helvetica Neue"
                                },
                                {
                                    "length": 9,
                                    "offset": 0,
                                    "style": "BOLD"
                                },
                                {
                                    "length": 9,
                                    "offset": 0,
                                    "style": "fontsize-18"
                                }
                            ],
                            "key": "1fadh",
                            "text": "Overall 4",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "margin-left": "0px"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 202,
                                    "offset": 0,
                                    "style": "fontfamily-Helvetica Neue"
                                },
                                {
                                    "length": 11,
                                    "offset": 0,
                                    "style": "BOLD"
                                },
                                {
                                    "length": 202,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                }
                            ],
                            "key": "2n3qo",
                            "text": "Key Traits: Demonstrates average character, coachability, composure, leadership traits. Tends to be cooperative, unorganized, see life in positive light, introverted, and have a wide range of interests.",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "margin-left": "0px"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 387,
                                    "offset": 0,
                                    "style": "fontfamily-Helvetica Neue"
                                },
                                {
                                    "length": 10,
                                    "offset": 0,
                                    "style": "BOLD"
                                },
                                {
                                    "length": 388,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                }
                            ],
                            "key": "cq5fp",
                            "text": "Strengths: Balance of self-focused on own well being and need to accommodate or please others. Can be empathetic and tends to see the best in others. Tends to stick to morals/values and uphold responsibilities. Highly cautious decision maker. Highly even tempered and very calm in handling unfavorable situations.  Prefers calmness. Highly philosophical and willing to explore new ideas. ",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "margin-left": "0px"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 441,
                                    "offset": 0,
                                    "style": "fontfamily-Helvetica Neue"
                                },
                                {
                                    "length": 16,
                                    "offset": 0,
                                    "style": "BOLD"
                                },
                                {
                                    "length": 442,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                }
                            ],
                            "key": "7oh77",
                            "text": "Areas of growth: May need to be pushed towards goals and be given structure. Experiences self-doubtful and may give up if patterns of poor performances. May be easily tempted or impulsive. Tends to keep to himself and value independence, be reserved or guarded. High tendency to challenge rules or authority. May be unaware of emotions and how to express them. Low level of imagination, creativity, and may have difficulty trying new things. ",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "margin-left": "0px"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 439,
                                    "offset": 0,
                                    "style": "fontfamily-Helvetica Neue"
                                },
                                {
                                    "length": 18,
                                    "offset": 0,
                                    "style": "BOLD"
                                },
                                {
                                    "length": 440,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                }
                            ],
                            "key": "f4e3n",
                            "text": "Areas of concern: Considerable possibility of guardedness and and reluctance to confide in others, may have trouble believing others\u2019 intentions. May experience anxiety or tendency to overthink, and display sensitivity to critique that could impact his confidence or self-esteem. May display behaviors or beliefs that go against norms in certain contexts. Could need support in building tools to communicate and express emotions and needs. ",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "margin-left": "0px"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 6,
                                    "offset": 0,
                                    "style": "fontfamily-Helvetica Neue"
                                },
                                {
                                    "length": 6,
                                    "offset": 0,
                                    "style": "BOLD"
                                },
                                {
                                    "length": 6,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                }
                            ],
                            "key": "dd45u",
                            "text": "Themes",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "margin-left": "0px"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 127,
                                    "offset": 0,
                                    "style": "fontfamily-Helvetica Neue"
                                },
                                {
                                    "length": 127,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                }
                            ],
                            "key": "77ekb",
                            "text": "-Linguistics very shorthand and at times lack context; some content may be misunderstood by viewers or come across as cryptic. ",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "margin-left": "0px"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 106,
                                    "offset": 0,
                                    "style": "fontfamily-Helvetica Neue"
                                },
                                {
                                    "length": 106,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                }
                            ],
                            "key": "c1igl",
                            "text": "-Location tags for instagram posts are often random \u201cwaffle house\u201d \u201cbackstreet\u201d \u201cCatch a taste\u201d \u201ctrenches\u201d",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "margin-left": "0px"
                            },
                            "depth": 0,
                            "entityRanges": [
                                {
                                    "key": 0,
                                    "length": 5,
                                    "offset": 33
                                }
                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 39,
                                    "offset": 0,
                                    "style": "fontfamily-Helvetica Neue"
                                },
                                {
                                    "length": 39,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                },
                                {
                                    "length": 5,
                                    "offset": 33,
                                    "style": "color-rgb(220,161,13)"
                                }
                            ],
                            "key": "9ppp9",
                            "text": "-I b wit demons n yea i admit it #sgmt\u201d",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "margin-left": "0px"
                            },
                            "depth": 0,
                            "entityRanges": [
                                {
                                    "key": 1,
                                    "length": 3,
                                    "offset": 58
                                }
                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 62,
                                    "offset": 0,
                                    "style": "fontfamily-Helvetica Neue"
                                },
                                {
                                    "length": 62,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                },
                                {
                                    "length": 3,
                                    "offset": 58,
                                    "style": "color-rgb(220,161,13)"
                                }
                            ],
                            "key": "99ill",
                            "text": "-\u201cPlay receiver but i can teach u how to get that sack fr #Sg\u201d",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "margin-left": "0px"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 20,
                                    "offset": 0,
                                    "style": "fontfamily-Helvetica Neue"
                                },
                                {
                                    "length": 21,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                }
                            ],
                            "key": "14c0e",
                            "text": "-Posing with friends ",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "margin-left": "0px"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 82,
                                    "offset": 0,
                                    "style": "fontfamily-Helvetica Neue"
                                },
                                {
                                    "length": 82,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                }
                            ],
                            "key": "kbbe",
                            "text": "-Displays performance, lot of football content, intensity and achievement on field",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "margin-left": "0px"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 190,
                                    "offset": 0,
                                    "style": "fontfamily-Helvetica Neue"
                                },
                                {
                                    "length": 191,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                }
                            ],
                            "key": "f04o",
                            "text": "-Appears to be emotionally expressive/broadcasting and or impulsive/aggressive; \u201cIon give a fuck who did it first its bout who did it right so i a still b a fuckin legend if i died tonight!\u201d ",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "margin-left": "0px"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 17,
                                    "offset": 0,
                                    "style": "fontfamily-Helvetica Neue"
                                },
                                {
                                    "length": 18,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                }
                            ],
                            "key": "bv39j",
                            "text": "-Uses ninja emoji ",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "margin-left": "0px"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 144,
                                    "offset": 0,
                                    "style": "fontfamily-Helvetica Neue"
                                },
                                {
                                    "length": 146,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                }
                            ],
                            "key": "an6pm",
                            "text": "In interviews (interpretation of this viewer): Appears forthcoming, smiles, engaged. May be uncomfortable at times but tires to persist, polite. \n",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {
                        "0": {
                            "data": {
                                "_map": {
                                    "data": {
                                        "targetOption": "",
                                        "title": "<span style=\"color: #dca10d\">#sgmt</span>",
                                        "url": "https://www.instagram.com/explore/tags/sgmt/"
                                    },
                                    "mutability": "MUTABLE",
                                    "type": "LINK"
                                },
                                "targetOption": "",
                                "title": "<span style=\"color: #dca10d\">#sgmt</span>",
                                "url": "https://www.instagram.com/explore/tags/sgmt/"
                            },
                            "mutability": "MUTABLE",
                            "type": "LINK"
                        },
                        "1": {
                            "data": {
                                "_map": {
                                    "data": {
                                        "targetOption": "",
                                        "title": "<span style=\"color: #dca10d\">#Sg</span>",
                                        "url": "https://www.instagram.com/explore/tags/sg/"
                                    },
                                    "mutability": "MUTABLE",
                                    "type": "LINK"
                                },
                                "targetOption": "",
                                "title": "<span style=\"color: #dca10d\">#Sg</span>",
                                "url": "https://www.instagram.com/explore/tags/sg/"
                            },
                            "mutability": "MUTABLE",
                            "type": "LINK"
                        }
                    }
                }
            },
            "personality traits": {
                "agreeableness": {
                    "score": 56.505,
                    "sub": {
                        "altruism": {
                            "score": 43.957499999999996,
                            "text": "The individual is moderately self-focused. He/she has an inclination to think of his/her own well-being rather than helping others. While the individual may be willing to help others, that willingness may not be immediate."
                        },
                        "cooperation": {
                            "score": 62.895,
                            "text": "The individual is moderately accommodating. He/she has an inclination to compromise and/or please others. "
                        },
                        "modesty": {
                            "score": 61.8075,
                            "text": "The individual moderately avoids attention. He/she has an inclination to shy away from being the focus of attention if possible."
                        },
                        "sympathy": {
                            "score": 53.2425,
                            "text": "The individual is empathetic. When an opportunity presents, he/she is able to put him/herself in others' shoes and has an inclination to show compassion toward others. "
                        },
                        "trust": {
                            "score": 57.3975,
                            "text": "The individual is a trusting person. He/she has an inclination to see the best in others and may be highly charitable."
                        },
                        "uncompromising": {
                            "score": 59.7075,
                            "text": "The individual is unwilling to break his/her own moral standards and has an inclination to be uncompromising in his/her values and beliefs."
                        }
                    },
                    "text": "Agreeableness refers to an individual's general congeniality and ability to put other people's needs before their own."
                },
                "conscientiousness": {
                    "score": 48.75749999999999,
                    "sub": {
                        "achievement_striving": {
                            "score": 47.13,
                            "text": "The individual is a moderately content with a 'good enough' attitude and has an inclination to be content with current achievements. Higher goals are not likely to be set or pursued from an intrinsic motivation."
                        },
                        "cautiousness": {
                            "score": 75.36,
                            "text": "The individual is a moderately cautious thinker. He/she has an inclination to pause to think and consider before making decisions and/or taking action. "
                        },
                        "dutifulness": {
                            "score": 63.7875,
                            "text": "The individual is moderately dutiful. He/she has an inclination to approach his/her obligations responsibly. These individuals are likely to fulfill their obligations and keep their promises. "
                        },
                        "orderliness": {
                            "score": 27.12,
                            "text": "The individual is moderately unorganized. He/she has an inclination to 'go with the flow' versus planning and organizing."
                        },
                        "self_discipline": {
                            "score": 46.3125,
                            "text": "The individual may have an inclination give up in the face of difficulty or when he/she does not see the value of the task at hand. "
                        },
                        "self_efficacy": {
                            "score": 32.8125,
                            "text": "The individual has a moderate tendancy to experience self-doubt and question his/her own abilities and capabilities. "
                        }
                    },
                    "text": "Conscientiousness refers to an Indvidual's propensity for orderliness and a need to plan. "
                },
                "emotional_range": {
                    "score": 59.1075,
                    "sub": {
                        "calm_in_pressure": {
                            "score": 59.309999999999995,
                            "text": "The individual is moderately prone to stress. He/she has an inclination to get exited and anxious in stressful situations."
                        },
                        "composure": {
                            "score": 72.51,
                            "text": "The individual is a moderately bad-tempered person. He/she has an inclination of resorting to anger in irritable/unfavorable situations."
                        },
                        "contentment": {
                            "score": 52.9275,
                            "text": "The individual is a moderately negative person. He/she has an inclination of thinking about the negative aspects of one's life."
                        },
                        "self_assuredness": {
                            "score": 71.99249999999999,
                            "text": "The individual is moderately anxious. He/she has an inclination of thinking about things in a negative/unfavorable manner."
                        },
                        "self_confidence": {
                            "score": 53.489999999999995,
                            "text": "The individual is a moderately self-conscious person. He/she has an inclination of paying attention to others opinions of one self."
                        },
                        "self_control": {
                            "score": 44.400000000000006,
                            "text": "The individual is a moderately self-controlled. He/she has an inclination of being resistive to temptation."
                        }
                    },
                    "text": "Emotional Stability refers to how an an individual tends to respond to his/her environment or experience a negative state of mind."
                },
                "extroversion": {
                    "score": 42.697500000000005,
                    "sub": {
                        "activity_level": {
                            "score": 45.6,
                            "text": "The individual moderately prefers to take fewer tasks at a slower pace and allow for deeper focus. "
                        },
                        "assertiveness": {
                            "score": 53.962500000000006,
                            "text": "The individual is a moderately assertive person. He/she has an inclination of speaking up in group environments and wil take charge of situations/events. "
                        },
                        "cheerfulness": {
                            "score": 52.05,
                            "text": "The individual displays feelings of positivity outwardly. "
                        },
                        "excitement_seeking": {
                            "score": 29.1525,
                            "text": "The individual has a moderate preference for calmness. He/she has an inclination to actively avoid excitement and search for calmness."
                        },
                        "gregariousness": {
                            "score": 40.62,
                            "text": "The individual is moderately independent. He/she has an inclination to prefer having time for oneself over larger social gatherings."
                        },
                        "outgoing": {
                            "score": 34.807500000000005,
                            "text": "The individual is a moderately reserved person. He/she has an inclination to be guarded and private, and may appear to be reticent in new environments. "
                        }
                    },
                    "text": "Extroversion refers to an individual's tendency to seek stimulation via the company of others. Extroversion/Introversion does not imply interest in others; it refers to the energy one feels as a result of interactions with others."
                },
                "openness": {
                    "score": 52.995,
                    "sub": {
                        "adventurousness": {
                            "score": 42.9075,
                            "text": "The individual has a low level of creativity and curiosity for new experiences. He/she has an inclination of accepting tradition and status quo."
                        },
                        "artistic_interests": {
                            "score": 51.847500000000004,
                            "text": "The individual has a moderate level of enthusiasm about creativity or seeking beautiful experiences. He/she has an inclination of searching for creative experiences."
                        },
                        "authority_challenging": {
                            "score": 73.86000000000001,
                            "text": "The individual is a moderate authority challenger. He/she demonstrates some inclination to question social norms, go against tradition, and challenge authority.  He/she is open to individuals with different values and/or lifestyles. "
                        },
                        "emotionality": {
                            "score": 44.962500000000006,
                            "text": "The individual demonstrates some emotional awareness. He/she has an inclination of being unaware or distant from his/her feelings."
                        },
                        "imagination": {
                            "score": 26.895,
                            "text": "The individual has an inclination of being grounded on facts when thinking, but may be able to appreciate or visualize new concepts when presented by others. "
                        },
                        "intellect": {
                            "score": 77.475,
                            "text": "The individual is highly philosophical. He/she has a high tendency of being intrigued by new ideas and has a preference to ponder theories and new, unexplored concepts and thoughts. "
                        }
                    },
                    "text": "Openness refers to an individual's general willingness to engage in new experiences and learn new things. Individuals that score high in Openness are more imaginative and creative. Those low in Openness tend to be more analytical and resistant to change and prefer tradition."
                }
            },
            "psychological disorders": {
                "antisocial": {
                    "score": 59.91488595233589,
                    "text": "A moderate possibility of behaving irresponsibly and show disregard for normal social behaviour."
                },
                "avoidant": {
                    "score": 51.99671010608259,
                    "text": "A moderate possibility of having above average shyness and sensitivity to criticism from others."
                },
                "borderline": {
                    "score": 46.99555174300545,
                    "text": "A moderate possibility of showing self harming behaviour and having a distorted and unstable self-image or sense of self."
                },
                "dependent": {
                    "score": 35.373811572790146,
                    "text": "Possibility of existing level of Dependent Personality Disorder is very low or none."
                },
                "histrionic": {
                    "score": 34.8692187666893,
                    "text": "Possibility of existing level of Histrionic Personality Disorder is very low or none."
                },
                "narcissistic": {
                    "score": 51.04834877113079,
                    "text": "A moderate possibility of believing that he or she is special and unique while expecting admiration from others."
                },
                "obsessive_compulsive": {
                    "score": 49.28242653608322,
                    "text": "A moderate possibility of the individual showing compulsive and obsessive behaviours in some areas of his/her life."
                },
                "paranoid": {
                    "score": 50.541693025058315,
                    "text": "A considerable possibility of individual being reluctant to confide in others and suspects others of being exploitative or deceitful."
                },
                "schizotypal": {
                    "score": 45.56399818313749,
                    "text": "A moderate possibility of having cognitive or perceptual distortions as well as eccentricities in everyday behavior."
                }
            },
            "risk": {
                "Aggression/Violence": {
                    "comments": [

                    ],
                    "score": 30,
                    "text": "Individuals low (1-3) in this range demonstrates minimal risk in aggression/violence. The individual demonstrates the ability to manage emotions (e.g. irritability, frustration, annoyances) and may utilize levels of anger as a motivating source for success."
                },
                "Delinquent Behavior": {
                    "comments": [

                    ],
                    "score": 30,
                    "text": "Individuals low (1-3) in this range demonstrate low expression of delinquent behavior. Individuals in this range adhere to societal, cultural norms. Generally, following standard procedures and expectations from authority figures. "
                },
                "Drug/Alcohol": {
                    "comments": [

                    ],
                    "score": 20,
                    "text": "Individuals low (1-3) in this range demonstrate minimal risky behavior in drug/alcohol use. Individuals either demonstrate normative minimal engagement in acceptable use that falls within normative range or does not indicate substance use per analysis. Should be noted individuals may be partaking in higher risk behavior but minimizing their full expression via social media/ public expression"
                },
                "Emotional Distress": {
                    "comments": [

                    ],
                    "score": 40,
                    "text": "Individuals moderate (4-6) in this range demonstrate some difficulties coping with moderate levels of emotional, physical, and mental stress. Individuals may range in their ability to utilize coping skills to manage emotions effectively. Individuals may show following reactions to stressors indicating some levels of emotional distress (e.g. difficulties sleeping, change in moods, irritability, change in dietary habits). "
                },
                "Overall": {
                    "comments": [

                    ],
                    "score": 30.0,
                    "text": "Risk score invalid to generate analysis text"
                },
                "Sexual Behavior": {
                    "comments": [

                    ],
                    "score": 30,
                    "text": "Individuals low (1-3) in this range demonstrate minimal sexual expression, minimal indication of sexual behavioral risk. Should be noted individuals may be partaking in risky behavior but minimizing their expression via social media/public expression."
                }
            }
        },
        "instagram": "kylejames",
        "is_paid": true,
        "is_valid_format": true,
        "linkedin": "",
        "message": {
            "button_label": "Okay, Got it",
            "content": "Proceed with caution when reviewing the system generated scores",
            "severity": "info",
            "show": true,
            "title": "Insufficient Data for a proper analysis"
        },
        "newspaper": "",
        "possible_duplicate_info": {
            "duplicate_candidate": {

            },
            "duplicate_candidate_id": "",
            "is_possible_duplicate": false
        },
        "race": "",
        "relationship_status": "",
        "report_settings": {
            "_id": "134766b2-3624-4447-ace0-7784a7fad9ac",
            "action_plan": {
                "allowed": false
            },
            "executive_summary": {
                "allowed": false
            },
            "full_report": {
                "allowed": false
            }
        },
        "reports": {
            "action_plan_report": false,
            "executive_summary_report": false,
            "full_report": false
        },
        "reviewed_on": "04/13/2022, 11:52:49",
        "risk": "low",
        "school": "ALABAMA",
        "stats": {
            "instagram": {
                "negative_percentage": 18.33,
                "neutral_percentage": 60.0,
                "positive_percentage": 21.67,
                "total": 0,
                "total_negative": 11,
                "total_neutral": 36,
                "total_positive": 13
            },
            "negative_percentage": 18.33,
            "neutral_percentage": 60.0,
            "positive_percentage": 21.67,
            "total": 60,
            "total_negative": 11,
            "total_neutral": 36,
            "total_positive": 13,
            "tweets": {
                "negative_percentage": 0,
                "neutral_percentage": 0,
                "positive_percentage": 0,
                "total": 0,
                "total_negative": 0,
                "total_neutral": 0,
                "total_positive": 0
            },
            "videos": {
                "total": 0
            }
        },
        "status": "Completed",
        "team": "Blu Chip Analytics",
        "twitter": "",
        "whatsapp": "",
        "youtube": "",
        "sport": "Football",
    },
    "0ecf5091-51ac-4112-8b49-82301c0429f3": {
        "_id": "0ecf5091-51ac-4112-8b49-82301c0429f3",
        "analysed_on": "01/21/2022, 13:12:39",
        "consent": "Received",
        "consent_form_id": "a8a4f635-41dc-4a86-8a6c-404b9cd32c16",
        "created_on": "01/19/2022, 17:22:20",
        "dob": "07/30/1992",
        "email": "john.pope@bluchipanalytics.com",
        "full_name": "John Pope",
        "hometown": "Marktoberdorf, Germany",
        "image_url": "http://127.0.0.1:3434/export/default_profile_pic",
        "insights": {
            "action_plan": {
                "Connection is Key": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Connection is Key",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Establish the Environment": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Establish the Environment",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Mental Mindset": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Mental Mindset",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                }
            },
            "custom_traits": {
                "character": {
                    "description": "An individual\u2019s emotional and mental qualities based on their morals, virtues, and humility that aid in executing tasks.",
                    "label": "Character",
                    "score": 62.530437500000005,
                    "sub": {
                        "hard_working": {
                            "left_label": "Hard-Working",
                            "right_label": "Lazy",
                            "score": 61.093875000000004,
                            "text": ""
                        },
                        "high_integrity": {
                            "left_label": "High Integrity",
                            "right_label": "Low Integrity",
                            "score": 67.58212499999999,
                            "text": ""
                        },
                        "humble": {
                            "left_label": "Humility",
                            "right_label": "Arrogant",
                            "score": 58.915312500000006,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of character. Individuals may work hard in certain performance domains. They may challenge others and express some levels of gratitude, humility, and integrity. Individuals may not be consistent in their ability to meet expectations, respect, and respond in positive manner in all situations."
                },
                "coachability": {
                    "description": "An individual\u2019s ability to listen, learn, be open to feedback, manage criticism, adapt their skill set, and perform at their optimal level.",
                    "label": "Coachability",
                    "score": 63.24351562500001,
                    "sub": {
                        "dependable": {
                            "left_label": "Dependable",
                            "right_label": "Unreliable",
                            "score": 63.727031249999996,
                            "text": ""
                        },
                        "willingness_to_learn ": {
                            "left_label": "Willingness to Learn",
                            "right_label": "Closed Minded",
                            "score": 62.760000000000005,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of coachability. Individuals may be moderately reliable, and adhere to meeting times, complete tasks, and perform as expected at times. Some may tend to be open to feedback, but could demonstrate difficulties managing criticism, or have difficulties adapting behavioral change to specific coaching feedback. Individuals may take time to learn and adjust performance goals."
                },
                "composure": {
                    "description": "An individual\u2019s ability to remain calm, persistent, and in control under pressure situations or after mistakes. Individuals\u2019 thrive in high pressure situations and demonstrate enhanced performance outcomes.",
                    "label": "Composure",
                    "score": 68.28815625000001,
                    "sub": {
                        "calm": {
                            "left_label": "Calm",
                            "right_label": "Susceptible to Stress",
                            "score": 71.56218750000001,
                            "text": ""
                        },
                        "driven": {
                            "left_label": "Driven",
                            "right_label": "Unambitious",
                            "score": 65.014125,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 70-100% demonstrate a high level of composure. Individuals have the ability to manage emotions, stay calm during high stress situations, think clearly, and perform optimally in high pressure situations. Some individuals may strive in high pressure situations and perform at optimal level. Individuals have high drive and passion to meet performance outcomes and remain calm in pursuit of excellence."
                },
                "leadership": {
                    "description": "An individual\u2019s ability to influence, inspire, and communicate a common goal to others.",
                    "label": "Leadership",
                    "score": 71.07843749999999,
                    "sub": {
                        "accountable": {
                            "left_label": "Accountable",
                            "right_label": "Unaccountable",
                            "score": 68.1215625,
                            "text": ""
                        },
                        "confident": {
                            "left_label": "Confident",
                            "right_label": "Insecure",
                            "score": 72.551625,
                            "text": ""
                        },
                        "vocal": {
                            "left_label": "Vocal",
                            "right_label": "Unspoken",
                            "score": 72.56212499999998,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 70-100% demonstrate a high level of leadership capacity. Individuals will be accountable, inspire others to work toward a common goal, assist in communicating goals, and cultural message to others. Individuals are highly self-confident and display their belief in themselves and others through behavior and communicating vocally."
                }
            },
            "executive_summary": {
                "Significant Background Information": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Significant Background Information",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "History of injuries (ankle, leg, toe, etc.) and recently had surgery on ankle",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "1mskb",
                            "text": "Has been suspended a few times for red cards and yellow cards",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "rudt",
                            "text": "Married to longtime girlfriend and has a daughter",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "bd1td",
                            "text": "",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Symptoms and Information Captured": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Symptoms and Information Captured",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 13,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "5ssk7",
                            "text": "Overall score:2",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 10,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "19kb6",
                            "text": "Key Traits: He may demonstrate high character and coachability through his dependability, integrity, and willingness to learn. He appears to maintain his composure in high-stress situations and may show leadership qualities through his vocalness and accountability.",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 9,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "bhq2t",
                            "text": "Strengths: He appears to be uncompromising in his beliefs and may demonstrate the ability to cooperate well with others. He appears to be organized and disciplined, and may demonstrate cautious thinking and decision-making. He may display high confidence in himself and the ability to regulate his emotions appropriately. He may demonstrate a gregarious and cheerful demeanor and may lean towards following rules and regulations.",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 16,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "1tu7f",
                            "text": "Areas for Growth: He may demonstrate a tendency to claim the spotlight. He appears to stick with what is practical and what is working versus exploring new or other options. He may struggle in being able to empathize with others.",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 14,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "333v1",
                            "text": "Risks/Concerns: There is the possibility is cautious thinking and decision-making style may create hesitation and a refusal to take necessary risks. He may demonstrate being a recluse as a way to maintain calmness.",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 17,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "67mso",
                            "text": "Themes from posts: Excitement and joy (happy with an important victory; what an incredible day); high emoji use (black/red colors, crying laughing, peace hand sign, lots of various); connection with family is important; recognizes team performance; general shoutouts and well wishes to fans; self-care (relaxation, vacation, activities); face new challenges - commit to training; positivity; mentions the \u201cunfortunates\u201d of play and outcomes of games ",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                }
            },
            "personality traits": {
                "agreeableness": {
                    "score": 61.162499999999994,
                    "sub": {
                        "altruism": {
                            "score": 59.37,
                            "text": "The individual is moderately altruistic. He/she has an inclination to help others with 'no strings attached' and feel fulfilled doing so."
                        },
                        "cooperation": {
                            "score": 72.78,
                            "text": "The individual is moderately accommodating. He/she has an inclination to compromise and/or please others. "
                        },
                        "modesty": {
                            "score": 42.4875,
                            "text": "The individual is moderately comfortable with attention. He/she has an inclination to see him/herself in a positive manner and does not actively avoid attention."
                        },
                        "sympathy": {
                            "score": 54.105000000000004,
                            "text": "The individual is empathetic. When an opportunity presents, he/she is able to put him/herself in others' shoes and has an inclination to show compassion toward others. "
                        },
                        "trust": {
                            "score": 60.96,
                            "text": "The individual is a trusting person. He/she has an inclination to see the best in others and may be highly charitable."
                        },
                        "uncompromising": {
                            "score": 77.2875,
                            "text": "The individual is unwilling to break his/her own moral standards. He/she unlikely to compromise such values in the face of difficulties or confrontation. The individual may be perceived as frank or struggle to see nuance when comparing one's own morals and values to others' morals and values. "
                        }
                    },
                    "text": "Agreeableness refers to an individual's general congeniality and ability to put other people's needs before their own."
                },
                "conscientiousness": {
                    "score": 67.485,
                    "sub": {
                        "achievement_striving": {
                            "score": 53.82,
                            "text": "The individual is a moderately driven person. He/she has an inclination to set high goals and work hard to reach them them."
                        },
                        "cautiousness": {
                            "score": 76.05000000000001,
                            "text": "The individual is a highly cautious thinker. He/she has a high tendency to think long and hard before making important decisions. While thorough, this individual may have a tendency to get stuck in 'analysis paralysis'. "
                        },
                        "dutifulness": {
                            "score": 70.89750000000001,
                            "text": "The individual is moderately dutiful. He/she has an inclination to approach his/her obligations responsibly. These individuals are likely to fulfill their obligations and keep their promises. "
                        },
                        "orderliness": {
                            "score": 74.61,
                            "text": "The individual is moderately organized. He/she has an inclination of needing to strucutre and plan events and surroundings. "
                        },
                        "self_discipline": {
                            "score": 69.1875,
                            "text": "The individual is persistent. He/she has an inclination of working through challenges without quitting."
                        },
                        "self_efficacy": {
                            "score": 60.3525,
                            "text": "The individual is a moderately self-assured person. He/she has an inclination on putting faith in his/her abilities and skills."
                        }
                    },
                    "text": "Conscientiousness refers to an Indvidual's propensity for orderliness and a need to plan. "
                },
                "emotional_range": {
                    "score": 78.32999999999998,
                    "sub": {
                        "calm_in_pressure": {
                            "score": 79.62750000000001,
                            "text": "The individual is highly prone to stress. He/she has a high tendency to become anxious and overwhelmed in the face of stress."
                        },
                        "composure": {
                            "score": 81.4275,
                            "text": "The individual is a highly bad-tempered person. He/she has a high tendency to resort to anger quickly."
                        },
                        "contentment": {
                            "score": 75.92999999999999,
                            "text": "The individual is a highly negative person. He/she has a high tendency to have a negative outlook on life and perceive the unfavourable side of situations."
                        },
                        "self_assuredness": {
                            "score": 80.25750000000001,
                            "text": "The individual is highly anxious. He/she has a high tendency of worrying about necessary and unnecessary future scenarios."
                        },
                        "self_confidence": {
                            "score": 82.01249999999999,
                            "text": "The individual is a highly self-conscious person. He/she has a high tendency to be strongly affected (mostly negatively) by others perception of one self."
                        },
                        "self_control": {
                            "score": 70.71000000000001,
                            "text": "The individual is moderately self-indulgent. He/she has an inclination of being easily tempted."
                        }
                    },
                    "text": "Emotional Stability refers to how an an individual tends to respond to his/her environment or experience a negative state of mind."
                },
                "extroversion": {
                    "score": 69.885,
                    "sub": {
                        "activity_level": {
                            "score": 63.585,
                            "text": "The individual moderately prefers more tasks at a faster pace. He/she has an inclination to focus on more than one project or task at at time. "
                        },
                        "assertiveness": {
                            "score": 79.17,
                            "text": "The individual is a highly assertive person. He/she has a tendency to take charge in group environments. This person may appear as a natural leader, although he/she may be seen as 'bossy' or be perceived as micomanaging others."
                        },
                        "cheerfulness": {
                            "score": 80.1975,
                            "text": "The individual displayes feelings of positivity, joy, enthusiasm, and optimism outwardly. "
                        },
                        "excitement_seeking": {
                            "score": 37.95,
                            "text": "The individual has a moderate preference for calmness. He/she has an inclination to actively avoid excitement and search for calmness."
                        },
                        "gregariousness": {
                            "score": 81.66749999999999,
                            "text": "The individual is highly sociable. He/she has a strong preference/tendency to enjoy large social gatherings regularly. "
                        },
                        "outgoing": {
                            "score": 76.74,
                            "text": "The individual is a highly outgoing person. He/she has a high tendency of being friendly toward new people and has a preference for being around others. "
                        }
                    },
                    "text": "Extroversion refers to an individual's tendency to seek stimulation via the company of others. Extroversion/Introversion does not imply interest in others; it refers to the energy one feels as a result of interactions with others."
                },
                "openness": {
                    "score": 40.4475,
                    "sub": {
                        "adventurousness": {
                            "score": 43.9875,
                            "text": "The individual has a low level of creativity and curiosity for new experiences. He/she has an inclination of accepting tradition and status quo."
                        },
                        "artistic_interests": {
                            "score": 40.6125,
                            "text": "The individual has a low level of enthusiasm about creativity or seeking beautiful experiences. He/she has an inclination of being indifferent about creative or beautiful experiences."
                        },
                        "authority_challenging": {
                            "score": 34.0275,
                            "text": "The individual is a moderate rule follower. He/she has an inclination toward obedience, following authority, and respecting rules and traditions. "
                        },
                        "emotionality": {
                            "score": 61.62,
                            "text": "The individual is moderately emotionally aware. He/she has an inclination of being in tune with his/her feelings and being able to express them."
                        },
                        "imagination": {
                            "score": 79,
                            "text": "The individual is a highly imaginative person. He/she has a high tendency of thinking through imagination and fantasy and may be bored with routine and mundanity. "
                        },
                        "intellect": {
                            "score": 47.415,
                            "text": "The individual is moderately practical. He/she has an inclination of dealing with what is rather than what could be."
                        }
                    },
                    "text": "Openness refers to an individual's general willingness to engage in new experiences and learn new things. Individuals that score high in Openness are more imaginative and creative. Those low in Openness tend to be more analytical and resistant to change and prefer tradition."
                }
            },
            "psychological disorders": {
                "antisocial": {
                    "score": 32.52878635144624,
                    "text": "Possibility of existing level of Antisocial Personality Disorder is very low or none."
                },
                "avoidant": {
                    "score": 29.637296031350676,
                    "text": "Possibility of existing level of Avoidant Personality Disorder is very low or none."
                },
                "borderline": {
                    "score": 29.592199694169196,
                    "text": "Possibility of existing level of Borderline Personality Disorder is very low or none."
                },
                "dependent": {
                    "score": 25.69718822836876,
                    "text": "Possibility of existing level of Dependent Personality Disorder is very low or none."
                },
                "histrionic": {
                    "score": 71.06440454721451,
                    "text": "A moderate possibility of being easily influenced by others or circumstances and may be uncomfortable in situations in which the individual is not the center of attention."
                },
                "narcissistic": {
                    "score": 44.90262860313375,
                    "text": "A moderate possibility of believing that he or she is special and unique while expecting admiration from others."
                },
                "obsessive_compulsive": {
                    "score": 63.04106801748276,
                    "text": "A moderate possibility of the individual showing compulsive and obsessive behaviours in some areas of his/her life."
                },
                "paranoid": {
                    "score": 25.601959209288317,
                    "text": "Possibility of existing level of Paranoid Personality Disorder is very low or none."
                },
                "schizotypal": {
                    "score": 25.633210837841034,
                    "text": "Possibility of existing level of Schizotypal Personality Disorder is very low or none."
                }
            },
            "risk": {
                "Aggression/Violence": {
                    "comments": [

                    ],
                    "score": 20,
                    "text": "Individuals low (1-3) in this range demonstrates minimal risk in aggression/violence. The individual demonstrates the ability to manage emotions (e.g. irritability, frustration, annoyances) and may utilize levels of anger as a motivating source for success."
                },
                "Delinquent Behavior": {
                    "comments": [

                    ],
                    "score": 30,
                    "text": "Individuals low (1-3) in this range demonstrate low expression of delinquent behavior. Individuals in this range adhere to societal, cultural norms. Generally, following standard procedures and expectations from authority figures. "
                },
                "Drug/Alcohol": {
                    "comments": [

                    ],
                    "score": 20,
                    "text": "Individuals low (1-3) in this range demonstrate minimal risky behavior in drug/alcohol use. Individuals either demonstrate normative minimal engagement in acceptable use that falls within normative range or does not indicate substance use per analysis. Should be noted individuals may be partaking in higher risk behavior but minimizing their full expression via social media/ public expression"
                },
                "Emotional Distress": {
                    "comments": [

                    ],
                    "score": 20,
                    "text": "Individuals low (1-3) in this range demonstrate minimal levels of emotional distress. Individuals can manage situational stressors. They are likely able to cope with a high stress, manage emotions effectively, and function in high adaptable scenarios. Individuals will most likely have positive social support, effective communication tools, and high awareness in one\u2019s self."
                },
                "Overall": {
                    "comments": [

                    ],
                    "score": 22.0,
                    "text": "Risk score invalid to generate analysis text"
                },
                "Sexual Behavior": {
                    "comments": [

                    ],
                    "score": 20,
                    "text": "Individuals low (1-3) in this range demonstrate minimal sexual expression, minimal indication of sexual behavioral risk. Should be noted individuals may be partaking in risky behavior but minimizing their expression via social media/public expression."
                }
            }
        },
        "instagram": "john_pope",
        "instagram_source_ln": "en",
        "is_paid": true,
        "linkedin": "na",
        "linkedin_source_ln": "en",
        "links": [

        ],
        "message": {
            "button_label": "",
            "content": "",
            "severity": "info",
            "show": false,
            "title": ""
        },
        "notes": "",
        "possible_duplicate_info": {
            "duplicate_candidate": {

            },
            "duplicate_candidate_id": "",
            "is_possible_duplicate": false
        },
        "race": "Germany",
        "relationship_status": "Single",
        "report_settings": {
            "_id": "a60b46e8-5039-49e6-a15e-096eb26465b0",
            "action_plan": {
                "allowed": false
            },
            "executive_summary": {
                "allowed": false
            },
            "full_report": {
                "allowed": false
            }
        },
        "reports": {
            "action_plan_report": false,
            "executive_summary_report": false,
            "full_report": false
        },
        "reviewed_on": "02/04/2022, 15:41:37",
        "risk": "low",
        "school": "Monaco",
        "stats": {
            "instagram": {
                "negative_percentage": 12.6,
                "neutral_percentage": 32.0,
                "positive_percentage": 55.4,
                "total": 473,
                "total_negative": 63,
                "total_neutral": 160,
                "total_positive": 277
            },
            "negative_percentage": 11.31,
            "neutral_percentage": 30.73,
            "positive_percentage": 57.97,
            "total": 973,
            "total_negative": 110,
            "total_neutral": 299,
            "total_positive": 564,
            "tweets": {
                "negative_percentage": 9.94,
                "neutral_percentage": 29.39,
                "positive_percentage": 60.68,
                "total": 473,
                "total_negative": 47,
                "total_neutral": 139,
                "total_positive": 287
            },
            "videos": {
                "total": 0
            }
        },
        "status": "Completed",
        "team": "Blu Chip Analytics",
        "twitter": "johnpope",
        "twitter_source_ln": "en",
        "whatsapp": "N/A",
        "sport": "Football",
    },
    "ba252c42-ed93-481e-8b51-7cce376f9d85": {
        "_id": "ba252c42-ed93-481e-8b51-7cce376f9d85",
        "consent": "Pending",
        "consent_form_id": "N/A",
        "created_on": "10/04/2021, 10:46:00",
        "dob": "02/02/2005",
        "email": "bbamford@bluchipanalytics.com",
        "full_name": "Ben Bamford",
        "hometown": "N/A",
        "image_url": "http://127.0.0.1:3434/export/default_profile_pic",
        "insights": {
            "action_plan": {
                "Connection is Key": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Connection is Key",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Psychologist comments pending...",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Establish the Environment": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Establish the Environment",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Psychologist comments pending...",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Mental Mindset": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Mental Mindset",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Psychologist comments pending...",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                }
            },
            "custom_traits": {
                "character": {
                    "description": "An individual\u2019s emotional and mental qualities based on their morals, virtues, and humility that aid in executing tasks.",
                    "label": "Character",
                    "score": 30.0,
                    "sub": {
                        "hard_working": {
                            "left_label": "Hard-Working",
                            "right_label": "Lazy",
                            "score": 30.0,
                            "text": ""
                        },
                        "high_integrity": {
                            "left_label": "High Integrity",
                            "right_label": "Low Integrity",
                            "score": 45.0,
                            "text": ""
                        },
                        "humble": {
                            "left_label": "Humility",
                            "right_label": "Arrogant",
                            "score": 0,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 0-30% demonstrate a minimal level of character. Individuals may lack drive and are lazy in their efforts to meet performance outcomes. Individuals may have low integrity, question or challenge authority, break team rules/norms. Individuals low in character may be arrogant and lack humility."
                },
                "coachability": {
                    "description": "An individual\u2019s ability to listen, learn, be open to feedback, manage criticism, adapt their skill set, and perform at their optimal level.",
                    "label": "Coachability",
                    "score": 24.375,
                    "sub": {
                        "dependable": {
                            "left_label": "Dependable",
                            "right_label": "Unreliable",
                            "score": 33.75,
                            "text": ""
                        },
                        "willingness_to_learn ": {
                            "left_label": "Willingness to Learn",
                            "right_label": "Closed Minded",
                            "score": 0,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 0-30% demonstrate a minimal level of coachability. The individual may tend to be unreliable, closed minded, or have difficulty managing feedback and criticism. They may display a negative mindset, defensiveness, and not adhere to cultural standards to assist in growth in their performance."
                },
                "composure": {
                    "description": "An individual\u2019s ability to remain calm, persistent, and in control under pressure situations or after mistakes. Individuals\u2019 thrive in high pressure situations and demonstrate enhanced performance outcomes.",
                    "label": "Composure",
                    "score": 40.3125,
                    "sub": {
                        "calm": {
                            "left_label": "Calm",
                            "right_label": "Susceptible to Stress",
                            "score": 65.625,
                            "text": ""
                        },
                        "driven": {
                            "left_label": "Driven",
                            "right_label": "Unambitious",
                            "score": 0,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of composure. Individuals may have the capacity to remain calm and manage stressful situations to a degree. They have a moderate drive and purpose to strive for. In high pressure situations they may react and have difficulties managing their emotions on a consistent basis."
                },
                "leadership": {
                    "description": "An individual\u2019s ability to influence, inspire, and communicate a common goal to others.",
                    "label": "Leadership",
                    "score": 38.0,
                    "sub": {
                        "accountable": {
                            "left_label": "Accountable",
                            "right_label": "Unaccountable",
                            "score": 0,
                            "text": ""
                        },
                        "confident": {
                            "left_label": "Confident",
                            "right_label": "Insecure",
                            "score": 69.0,
                            "text": ""
                        },
                        "vocal": {
                            "left_label": "Vocal",
                            "right_label": "Unspoken",
                            "score": 30.0,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of leadership capacity. Individuals may work hard toward goals and can inspire small groups or a few individuals. Individuals may lack the ability to be vocal and speak to larger members to inspire a large group to meet cultural goals. Individuals may have the capacity to make small changes and influence members around them. Individuals may have moderate level of confidence, but poor performances or influence may negatively impact self-worth and ability to perform at optimal level."
                }
            },
            "executive_summary": {
                "Significant Background Information": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Significant Background Information",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Psychologist comments pending...",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Symptoms and Information Captured": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Symptoms and Information Captured",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Psychologist comments pending...",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                }
            },
            "personality traits": {
                "agreeableness": {
                    "score": 88,
                    "sub": {
                        "altruism": {
                            "score": 87.55555555,
                            "text": "The individual is very altruistic. He/she has a high tendency to go out of his/her way to help others. The individual sees helping others as natural and rewarding. "
                        },
                        "cooperation": {
                            "score": 78.6555,
                            "text": "The individual is very accommodating . He/she has a high tendency to avoid conflicts, even setting aside his/her own needs in order to be on the good side of others."
                        },
                        "modesty": {
                            "score": 95.8,
                            "text": "The individual dislikes attention. He/she has a high tendency to avoid being the center of attention and may be self-effacing. Individual's tendency for humility may not refect abilities, competencies, or superiority. "
                        },
                        "sympathy": {
                            "score": 77.6,
                            "text": "The individual is highly empathetic. He/she tends to be compassionate and refrain from judging others. Individual may be perceived as 'soft' or too kind for his/her own benefit. "
                        },
                        "trust": {
                            "score": 83,
                            "text": "The individual is a highly trusting person. He/she has a high tendency to believe people are inherently good and trust them easily, regardless of their actions/inactions that substantiate or refute this disposition."
                        },
                        "uncompromising": {
                            "score": 69,
                            "text": "The individual is unwilling to break his/her own moral standards and has an inclination to be uncompromising in his/her values and beliefs."
                        }
                    },
                    "text": "Agreeableness refers to an individual's general congeniality and ability to put other people's needs before their own."
                },
                "conscientiousness": {
                    "score": 35,
                    "sub": {
                        "achievement_striving": {
                            "score": 18.55,
                            "text": "The individual is content with a 'good enough' attitude. Individuals in this range will not overexert themselves to accompish a goal. He/she is less likely to have or pursue higher goals."
                        },
                        "cautiousness": {
                            "score": 33.56,
                            "text": "The individual is a moderately bold thinker. He/she has an inclination to take bold action with limited forethought. "
                        },
                        "dutifulness": {
                            "score": 45.86,
                            "text": "The individual is moderately carefree. He/she has an inclination to disregard obligations if it makes things easier for the individual."
                        },
                        "orderliness": {
                            "score": 28.6,
                            "text": "The individual is moderately unorganized. He/she has an inclination to 'go with the flow' versus planning and organizing."
                        },
                        "self_discipline": {
                            "score": 29.5,
                            "text": "The individual may have an inclination give up in the face of difficulty or when he/she does not see the value of the task at hand. "
                        },
                        "self_efficacy": {
                            "score": 49.86,
                            "text": "The individual has a moderate tendancy to experience self-doubt and question his/her own abilities and capabilities. "
                        }
                    },
                    "text": "Conscientiousness refers to an Indvidual's propensity for orderliness and a need to plan. "
                },
                "emotional_range": {
                    "score": 90.0,
                    "sub": {
                        "calm_in_pressure": {
                            "score": 90.0,
                            "text": "The individual is highly prone to stress. He/she has a high tendency to become anxious and overwhelmed in the face of stress."
                        },
                        "composure": {
                            "score": 90.0,
                            "text": "The individual is a highly bad-tempered person. He/she has a high tendency to resort to anger quickly."
                        },
                        "contentment": {
                            "score": 90.0,
                            "text": "The individual is a highly negative person. He/she has a high tendency to have a negative outlook on life and perceive the unfavourable side of situations."
                        },
                        "self_assuredness": {
                            "score": 90.0,
                            "text": "The individual is highly anxious. He/she has a high tendency of worrying about necessary and unnecessary future scenarios."
                        },
                        "self_confidence": {
                            "score": 90.0,
                            "text": "The individual is a highly self-conscious person. He/she has a high tendency to be strongly affected (mostly negatively) by others perception of one self."
                        },
                        "self_control": {
                            "score": 90.0,
                            "text": "The individual is highly self-indulgent. He/she has a high tendency of being tempted by desires."
                        }
                    },
                    "text": "Emotional Stability refers to how an an individual tends to respond to his/her environment or experience a negative state of mind."
                },
                "extroversion": {
                    "score": 59,
                    "sub": {
                        "activity_level": {
                            "score": 55.68,
                            "text": "The individual moderately prefers more tasks at a faster pace. He/she has an inclination to focus on more than one project or task at at time. "
                        },
                        "assertiveness": {
                            "score": 75.69,
                            "text": "The individual is a highly assertive person. He/she has a tendency to take charge in group environments. This person may appear as a natural leader, although he/she may be seen as 'bossy' or be perceived as micomanaging others."
                        },
                        "cheerfulness": {
                            "score": 68.69,
                            "text": "The individual displays feelings of positivity outwardly. "
                        },
                        "excitement_seeking": {
                            "score": 45.89,
                            "text": "The individual has a moderate preference for calmness. He/she has an inclination to actively avoid excitement and search for calmness."
                        },
                        "gregariousness": {
                            "score": 49.56,
                            "text": "The individual is moderately independent. He/she has an inclination to prefer having time for oneself over larger social gatherings."
                        },
                        "outgoing": {
                            "score": 18.5,
                            "text": "The individual is a highly reserved person. He/she has a high tendency to prefer privacy and prefers to have a selected group of friends. Individuals in this range may have difficulties engaging in conversation with people they do not yet know.  This does not mean they are hostile toward others, but subdued around new people."
                        }
                    },
                    "text": "Extroversion refers to an individual's tendency to seek stimulation via the company of others. Extroversion/Introversion does not imply interest in others; it refers to the energy one feels as a result of interactions with others."
                },
                "openness": {
                    "score": 25,
                    "sub": {
                        "adventurousness": {
                            "score": 12.8,
                            "text": "The individual has a very low level of creativity and sense of adventure. He/she has a high tendency to be obedient and rooted in facts, traditions, and conventional thinking. "
                        },
                        "artistic_interests": {
                            "score": 29.5,
                            "text": "The individual has a low level of enthusiasm about creativity or seeking beautiful experiences. He/she has an inclination of being indifferent about creative or beautiful experiences."
                        },
                        "authority_challenging": {
                            "score": 55.6,
                            "text": "The individual is a moderate authority challenger. He/she demonstrates some inclination to question social norms, go against tradition, and challenge authority.  He/she is open to individuals with different values and/or lifestyles. "
                        },
                        "emotionality": {
                            "score": 25.6,
                            "text": "The individual demonstrates some emotional awareness. He/she has an inclination of being unaware or distant from his/her feelings."
                        },
                        "imagination": {
                            "score": 19,
                            "text": "The individual has a high tendency of focusing on hard facts rather than imagination. "
                        },
                        "intellect": {
                            "score": 11.89,
                            "text": "The individual is highly practical. He/she has a high tendency of accepting facts with limited-to-no appreciation for theory. "
                        }
                    },
                    "text": "Openness refers to an individual's general willingness to engage in new experiences and learn new things. Individuals that score high in Openness are more imaginative and creative. Those low in Openness tend to be more analytical and resistant to change and prefer tradition."
                }
            },
            "psychological disorders": {
                "antisocial": {
                    "score": 0,
                    "text": "Analysis Pending..."
                },
                "avoidant": {
                    "score": 0,
                    "text": "Analysis Pending..."
                },
                "borderline": {
                    "score": 0,
                    "text": "Analysis Pending..."
                },
                "dependent": {
                    "score": 0,
                    "text": "Analysis Pending..."
                },
                "histrionic": {
                    "score": 0,
                    "text": "Analysis Pending..."
                },
                "narcissistic": {
                    "score": 0,
                    "text": "Analysis Pending..."
                },
                "obsessive_compulsive": {
                    "score": 0,
                    "text": "Analysis Pending..."
                },
                "paranoid": {
                    "score": 0,
                    "text": "Analysis Pending..."
                },
                "schizotypal": {
                    "score": 0,
                    "text": "Analysis Pending..."
                }
            },
            "risk": {
                "Aggression/Violence": {
                    "comments": [

                    ],
                    "score": 0,
                    "text": "Analysis Pending.."
                },
                "Delinquent Behavior": {
                    "comments": [

                    ],
                    "score": 0,
                    "text": "Analysis Pending.."
                },
                "Drug/Alcohol": {
                    "comments": [

                    ],
                    "score": 0,
                    "text": "Analysis Pending.."
                },
                "Emotional Distress": {
                    "comments": [

                    ],
                    "score": 0,
                    "text": "Analysis Pending.."
                },
                "Overall": {
                    "comments": [

                    ],
                    "score": 0.0,
                    "text": "Analysis Pending.."
                },
                "Sexual Behavior": {
                    "comments": [

                    ],
                    "score": 0,
                    "text": "Analysis Pending.."
                }
            }
        },
        "instagram": "na",
        "instagram_source_ln": "en",
        "is_paid": false,
        "linkedin": "na",
        "linkedin_source_ln": "en",
        "links": [

        ],
        "message": {
            "button_label": "Okay, Got it",
            "content": "Proceed with caution when reviewing the system generated scores",
            "severity": "info",
            "show": true,
            "title": "Insufficient Data for a proper analysis"
        },
        "notes": "",
        "possible_duplicate_info": {
            "duplicate_candidate": {

            },
            "duplicate_candidate_id": "",
            "is_possible_duplicate": false
        },
        "race": "N/A",
        "relationship_status": "N/A",
        "report_settings": {
            "_id": "70c5dd4b-22dc-455d-8f3c-dc96a4d0c50f",
            "action_plan": {
                "allowed": false
            },
            "executive_summary": {
                "allowed": false
            },
            "full_report": {
                "allowed": false
            }
        },
        "reports": {
            "action_plan_report": false,
            "executive_summary_report": false,
            "full_report": false
        },
        "reviewed_on": "",
        "risk": "low",
        "school": "N/A",
        "stats": {
            "instagram": {
                "negative_percentage": 0,
                "neutral_percentage": 0,
                "positive_percentage": 0,
                "total": 0,
                "total_negative": 0,
                "total_neutral": 0,
                "total_positive": 0
            },
            "negative_percentage": 0,
            "neutral_percentage": 0,
            "positive_percentage": 0,
            "total": 0,
            "total_negative": 0,
            "total_neutral": 0,
            "total_positive": 0,
            "tweets": {
                "negative_percentage": 0,
                "neutral_percentage": 0,
                "positive_percentage": 0,
                "total": 0,
                "total_negative": 0,
                "total_neutral": 0,
                "total_positive": 0
            },
            "videos": {
                "total": 0
            }
        },
        "status": "Approval Pending",
        "team": "Blu Chip Analytics",
        "twitter": "benbamford",
        "twitter_source_ln": "en",
        "whatsapp": "N/A",
        "sport": "Football",
    },
    "a1664bf0-30a5-4977-a56a-6cd769c268e8": {
        "_id": "a1664bf0-30a5-4977-a56a-6cd769c268e8",
        "consent": "Pending",
        "consent_form_id": "N/A",
        "created_on": "01/31/2022, 16:31:38",
        "dob": "07/25/1998",
        "email": "sam.bowen@bluchipanalytics.com",
        "full_name": "Sam Bowen",
        "hometown": "Sweden",
        "image_url": "http://127.0.0.1:3434/export/default_profile_pic",
        "insights": {
            "action_plan": {
                "Connection is Key": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Connection is Key",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Psychologist comments pending...",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Establish the Environment": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Establish the Environment",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Psychologist comments pending...",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Mental Mindset": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Mental Mindset",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Psychologist comments pending...",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                }
            },
            "custom_traits": {
                "character": {
                    "description": "An individual\u2019s emotional and mental qualities based on their morals, virtues, and humility that aid in executing tasks.",
                    "label": "Character",
                    "score": 30.0,
                    "sub": {
                        "hard_working": {
                            "left_label": "Hard-Working",
                            "right_label": "Lazy",
                            "score": 30.0,
                            "text": ""
                        },
                        "high_integrity": {
                            "left_label": "High Integrity",
                            "right_label": "Low Integrity",
                            "score": 45.0,
                            "text": ""
                        },
                        "humble": {
                            "left_label": "Humility",
                            "right_label": "Arrogant",
                            "score": 0,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 0-30% demonstrate a minimal level of character. Individuals may lack drive and are lazy in their efforts to meet performance outcomes. Individuals may have low integrity, question or challenge authority, break team rules/norms. Individuals low in character may be arrogant and lack humility."
                },
                "coachability": {
                    "description": "An individual\u2019s ability to listen, learn, be open to feedback, manage criticism, adapt their skill set, and perform at their optimal level.",
                    "label": "Coachability",
                    "score": 24.375,
                    "sub": {
                        "dependable": {
                            "left_label": "Dependable",
                            "right_label": "Unreliable",
                            "score": 33.75,
                            "text": ""
                        },
                        "willingness_to_learn ": {
                            "left_label": "Willingness to Learn",
                            "right_label": "Closed Minded",
                            "score": 0,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 0-30% demonstrate a minimal level of coachability. The individual may tend to be unreliable, closed minded, or have difficulty managing feedback and criticism. They may display a negative mindset, defensiveness, and not adhere to cultural standards to assist in growth in their performance."
                },
                "composure": {
                    "description": "An individual\u2019s ability to remain calm, persistent, and in control under pressure situations or after mistakes. Individuals\u2019 thrive in high pressure situations and demonstrate enhanced performance outcomes.",
                    "label": "Composure",
                    "score": 40.3125,
                    "sub": {
                        "calm": {
                            "left_label": "Calm",
                            "right_label": "Susceptible to Stress",
                            "score": 65.625,
                            "text": ""
                        },
                        "driven": {
                            "left_label": "Driven",
                            "right_label": "Unambitious",
                            "score": 0,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of composure. Individuals may have the capacity to remain calm and manage stressful situations to a degree. They have a moderate drive and purpose to strive for. In high pressure situations they may react and have difficulties managing their emotions on a consistent basis."
                },
                "leadership": {
                    "description": "An individual\u2019s ability to influence, inspire, and communicate a common goal to others.",
                    "label": "Leadership",
                    "score": 38.0,
                    "sub": {
                        "accountable": {
                            "left_label": "Accountable",
                            "right_label": "Unaccountable",
                            "score": 0,
                            "text": ""
                        },
                        "confident": {
                            "left_label": "Confident",
                            "right_label": "Insecure",
                            "score": 69.0,
                            "text": ""
                        },
                        "vocal": {
                            "left_label": "Vocal",
                            "right_label": "Unspoken",
                            "score": 30.0,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of leadership capacity. Individuals may work hard toward goals and can inspire small groups or a few individuals. Individuals may lack the ability to be vocal and speak to larger members to inspire a large group to meet cultural goals. Individuals may have the capacity to make small changes and influence members around them. Individuals may have moderate level of confidence, but poor performances or influence may negatively impact self-worth and ability to perform at optimal level."
                }
            },
            "executive_summary": {
                "Significant Background Information": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Significant Background Information",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Psychologist comments pending...",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Symptoms and Information Captured": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Symptoms and Information Captured",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Psychologist comments pending...",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                }
            },
            "personality traits": {
                "agreeableness": {
                    "score": 89,
                    "sub": {
                        "altruism": {
                            "score": 78.69,
                            "text": "The individual is very altruistic. He/she has a high tendency to go out of his/her way to help others. The individual sees helping others as natural and rewarding. "
                        },
                        "cooperation": {
                            "score": 89.63,
                            "text": "The individual is very accommodating . He/she has a high tendency to avoid conflicts, even setting aside his/her own needs in order to be on the good side of others."
                        },
                        "modesty": {
                            "score": 95.2,
                            "text": "The individual dislikes attention. He/she has a high tendency to avoid being the center of attention and may be self-effacing. Individual's tendency for humility may not refect abilities, competencies, or superiority. "
                        },
                        "sympathy": {
                            "score": 65.86,
                            "text": "The individual is empathetic. When an opportunity presents, he/she is able to put him/herself in others' shoes and has an inclination to show compassion toward others. "
                        },
                        "trust": {
                            "score": 59.56,
                            "text": "The individual is a trusting person. He/she has an inclination to see the best in others and may be highly charitable."
                        },
                        "uncompromising": {
                            "score": 79.26,
                            "text": "The individual is unwilling to break his/her own moral standards. He/she unlikely to compromise such values in the face of difficulties or confrontation. The individual may be perceived as frank or struggle to see nuance when comparing one's own morals and values to others' morals and values. "
                        }
                    },
                    "text": "Agreeableness refers to an individual's general congeniality and ability to put other people's needs before their own."
                },
                "conscientiousness": {
                    "score": 79,
                    "sub": {
                        "achievement_striving": {
                            "score": 83.56,
                            "text": "The individual is a highly driven person. He/she has a tendency to set high goals for him/herself and work hard to achieve them. Individuals in this range may appear to be compulsive or overly focused on achieving lofty goals."
                        },
                        "cautiousness": {
                            "score": 72.56,
                            "text": "The individual is a moderately cautious thinker. He/she has an inclination to pause to think and consider before making decisions and/or taking action. "
                        },
                        "dutifulness": {
                            "score": 41.56,
                            "text": "The individual is moderately carefree. He/she has an inclination to disregard obligations if it makes things easier for the individual."
                        },
                        "orderliness": {
                            "score": 68.9,
                            "text": "The individual is moderately organized. He/she has an inclination of needing to strucutre and plan events and surroundings. "
                        },
                        "self_discipline": {
                            "score": 72.5,
                            "text": "The individual is persistent. He/she has an inclination of working through challenges without quitting."
                        },
                        "self_efficacy": {
                            "score": 76.8,
                            "text": "The individual is a highly self-assured person. He/she has a high tendency to believe in his/her own capabilities in achieving goals. Actual abilities and capabilities may not align with an individual's perception of their abilities. "
                        }
                    },
                    "text": "Conscientiousness refers to an Indvidual's propensity for orderliness and a need to plan. "
                },
                "emotional_range": {
                    "score": 90.0,
                    "sub": {
                        "calm_in_pressure": {
                            "score": 90.0,
                            "text": "The individual is highly prone to stress. He/she has a high tendency to become anxious and overwhelmed in the face of stress."
                        },
                        "composure": {
                            "score": 90.0,
                            "text": "The individual is a highly bad-tempered person. He/she has a high tendency to resort to anger quickly."
                        },
                        "contentment": {
                            "score": 90.0,
                            "text": "The individual is a highly negative person. He/she has a high tendency to have a negative outlook on life and perceive the unfavourable side of situations."
                        },
                        "self_assuredness": {
                            "score": 90.0,
                            "text": "The individual is highly anxious. He/she has a high tendency of worrying about necessary and unnecessary future scenarios."
                        },
                        "self_confidence": {
                            "score": 90.0,
                            "text": "The individual is a highly self-conscious person. He/she has a high tendency to be strongly affected (mostly negatively) by others perception of one self."
                        },
                        "self_control": {
                            "score": 90.0,
                            "text": "The individual is highly self-indulgent. He/she has a high tendency of being tempted by desires."
                        }
                    },
                    "text": "Emotional Stability refers to how an an individual tends to respond to his/her environment or experience a negative state of mind."
                },
                "extroversion": {
                    "score": 80.0,
                    "sub": {
                        "activity_level": {
                            "score": 80,
                            "text": "The individual has a strong preference for more tasks at a faster pace. He/she will be engaged with several tasks at once. While he/she may be involved in many activities, busyness does not necessarily equate to productivity. "
                        },
                        "assertiveness": {
                            "score": 75,
                            "text": "The individual is a moderately assertive person. He/she has an inclination of speaking up in group environments and wil take charge of situations/events. "
                        },
                        "cheerfulness": {
                            "score": 95,
                            "text": "The individual displayes feelings of positivity, joy, enthusiasm, and optimism outwardly. "
                        },
                        "excitement_seeking": {
                            "score": 65,
                            "text": "The individual moderately prefers excitement. He/she has an inclination to seek out people and situations that are exciting or thrilling. "
                        },
                        "gregariousness": {
                            "score": 45.899,
                            "text": "The individual is moderately independent. He/she has an inclination to prefer having time for oneself over larger social gatherings."
                        },
                        "outgoing": {
                            "score": 89.23,
                            "text": "The individual is a highly outgoing person. He/she has a high tendency of being friendly toward new people and has a preference for being around others. "
                        }
                    },
                    "text": "Extroversion refers to an individual's tendency to seek stimulation via the company of others. Extroversion/Introversion does not imply interest in others; it refers to the energy one feels as a result of interactions with others."
                },
                "openness": {
                    "score": 66,
                    "sub": {
                        "adventurousness": {
                            "score": 69.36,
                            "text": "The individual has a moderate level of creativity and curiousity for new experiences. He/she has an inclination of having a wide range of interest and can visualize and appreciate the abstract or imagined. "
                        },
                        "artistic_interests": {
                            "score": 75.63,
                            "text": "The individual has a high level of enthusiasm about creativity or seeking beautiful experiences. He/she has a high tendency of needing to experience creativity and beautiful things and will seek out oportunities to experience and appreciate beauty around them. "
                        },
                        "authority_challenging": {
                            "score": 79.23,
                            "text": "The individual regularly challenges authority, norms, and traditions. He/she has a tendency to be accepting of values/lifestyles that are different from him/herself. "
                        },
                        "emotionality": {
                            "score": 49.23,
                            "text": "The individual demonstrates some emotional awareness. He/she has an inclination of being unaware or distant from his/her feelings."
                        },
                        "imagination": {
                            "score": 55.89,
                            "text": "The individual is a moderately imaginative person. He/she has an inclination of being comfortable with letting his/her imagination do the thinking while still keeping an eye on the facts. "
                        },
                        "intellect": {
                            "score": 59.36,
                            "text": "The individual is moderately philosophical. He/she has an inclination of being unsatisfied of what is and tends to prefer exploring new possibilities and what could be."
                        }
                    },
                    "text": "Openness refers to an individual's general willingness to engage in new experiences and learn new things. Individuals that score high in Openness are more imaginative and creative. Those low in Openness tend to be more analytical and resistant to change and prefer tradition."
                }
            },
            "psychological disorders": {
                "antisocial": {
                    "score": 0,
                    "text": "Analysis Pending..."
                },
                "avoidant": {
                    "score": 0,
                    "text": "Analysis Pending..."
                },
                "borderline": {
                    "score": 0,
                    "text": "Analysis Pending..."
                },
                "dependent": {
                    "score": 0,
                    "text": "Analysis Pending..."
                },
                "histrionic": {
                    "score": 0,
                    "text": "Analysis Pending..."
                },
                "narcissistic": {
                    "score": 0,
                    "text": "Analysis Pending..."
                },
                "obsessive_compulsive": {
                    "score": 0,
                    "text": "Analysis Pending..."
                },
                "paranoid": {
                    "score": 0,
                    "text": "Analysis Pending..."
                },
                "schizotypal": {
                    "score": 0,
                    "text": "Analysis Pending..."
                }
            },
            "risk": {
                "Aggression/Violence": {
                    "comments": [

                    ],
                    "score": 0,
                    "text": "Analysis Pending.."
                },
                "Delinquent Behavior": {
                    "comments": [

                    ],
                    "score": 0,
                    "text": "Analysis Pending.."
                },
                "Drug/Alcohol": {
                    "comments": [

                    ],
                    "score": 0,
                    "text": "Analysis Pending.."
                },
                "Emotional Distress": {
                    "comments": [

                    ],
                    "score": 0,
                    "text": "Analysis Pending.."
                },
                "Overall": {
                    "comments": [

                    ],
                    "score": 0.0,
                    "text": "Analysis Pending.."
                },
                "Sexual Behavior": {
                    "comments": [

                    ],
                    "score": 0,
                    "text": "Analysis Pending.."
                }
            }
        },
        "instagram": "sambowen98",
        "instagram_source_ln": "en",
        "is_paid": false,
        "linkedin": "na",
        "linkedin_source_ln": "en",
        "links": [

        ],
        "message": {
            "button_label": "",
            "content": "",
            "severity": "info",
            "show": false,
            "title": ""
        },
        "notes": {
            "blocks": [
                {
                    "data": {

                    },
                    "depth": 0,
                    "entityRanges": [
                        {
                            "key": 0,
                            "length": 28,
                            "offset": 0
                        }
                    ],
                    "inlineStyleRanges": [

                    ],
                    "key": "5fhfh",
                    "text": "https://youtu.be/4qWiDEhsYBs ",
                    "type": "unstyled"
                },
                {
                    "data": {

                    },
                    "depth": 0,
                    "entityRanges": [
                        {
                            "key": 1,
                            "length": 28,
                            "offset": 0
                        }
                    ],
                    "inlineStyleRanges": [

                    ],
                    "key": "dmcen",
                    "text": "https://youtu.be/OGzJSnFS0IQ ",
                    "type": "unstyled"
                },
                {
                    "data": {

                    },
                    "depth": 0,
                    "entityRanges": [
                        {
                            "key": 2,
                            "length": 28,
                            "offset": 0
                        }
                    ],
                    "inlineStyleRanges": [

                    ],
                    "key": "449iq",
                    "text": "https://youtu.be/Hr6jol5sD4A ",
                    "type": "unstyled"
                },
                {
                    "data": {

                    },
                    "depth": 0,
                    "entityRanges": [
                        {
                            "key": 3,
                            "length": 28,
                            "offset": 0
                        }
                    ],
                    "inlineStyleRanges": [

                    ],
                    "key": "cmtr",
                    "text": "https://youtu.be/D92BPtL2A10 ",
                    "type": "unstyled"
                },
                {
                    "data": {

                    },
                    "depth": 0,
                    "entityRanges": [
                        {
                            "key": 4,
                            "length": 28,
                            "offset": 0
                        }
                    ],
                    "inlineStyleRanges": [

                    ],
                    "key": "d9tg8",
                    "text": "https://youtu.be/TA_wfRm9ucQ ",
                    "type": "unstyled"
                },
                {
                    "data": {

                    },
                    "depth": 0,
                    "entityRanges": [
                        {
                            "key": 5,
                            "length": 28,
                            "offset": 0
                        }
                    ],
                    "inlineStyleRanges": [

                    ],
                    "key": "8bg66",
                    "text": "https://youtu.be/lDJ1qfyJM0A ",
                    "type": "unstyled"
                },
                {
                    "data": {

                    },
                    "depth": 0,
                    "entityRanges": [
                        {
                            "key": 6,
                            "length": 28,
                            "offset": 0
                        }
                    ],
                    "inlineStyleRanges": [

                    ],
                    "key": "7iqkt",
                    "text": "https://youtu.be/SMMgyu5aqmE ",
                    "type": "unstyled"
                },
                {
                    "data": {

                    },
                    "depth": 0,
                    "entityRanges": [
                        {
                            "key": 7,
                            "length": 28,
                            "offset": 0
                        }
                    ],
                    "inlineStyleRanges": [

                    ],
                    "key": "cidog",
                    "text": "https://youtu.be/aCHPkddPM_A ",
                    "type": "unstyled"
                },
                {
                    "data": {

                    },
                    "depth": 0,
                    "entityRanges": [

                    ],
                    "inlineStyleRanges": [

                    ],
                    "key": "3kjoo",
                    "text": "",
                    "type": "unstyled"
                }
            ],
            "entityMap": {
                "0": {
                    "data": {
                        "targetOption": "_self",
                        "url": "https://youtu.be/4qWiDEhsYBs"
                    },
                    "mutability": "MUTABLE",
                    "type": "LINK"
                },
                "1": {
                    "data": {
                        "targetOption": "_self",
                        "url": "https://youtu.be/OGzJSnFS0IQ"
                    },
                    "mutability": "MUTABLE",
                    "type": "LINK"
                },
                "2": {
                    "data": {
                        "targetOption": "_self",
                        "url": "https://youtu.be/Hr6jol5sD4A"
                    },
                    "mutability": "MUTABLE",
                    "type": "LINK"
                },
                "3": {
                    "data": {
                        "targetOption": "_self",
                        "url": "https://youtu.be/D92BPtL2A10"
                    },
                    "mutability": "MUTABLE",
                    "type": "LINK"
                },
                "4": {
                    "data": {
                        "targetOption": "_self",
                        "url": "https://youtu.be/TA_wfRm9ucQ"
                    },
                    "mutability": "MUTABLE",
                    "type": "LINK"
                },
                "5": {
                    "data": {
                        "targetOption": "_self",
                        "url": "https://youtu.be/lDJ1qfyJM0A"
                    },
                    "mutability": "MUTABLE",
                    "type": "LINK"
                },
                "6": {
                    "data": {
                        "targetOption": "_self",
                        "url": "https://youtu.be/SMMgyu5aqmE"
                    },
                    "mutability": "MUTABLE",
                    "type": "LINK"
                },
                "7": {
                    "data": {
                        "targetOption": "_self",
                        "url": "https://youtu.be/aCHPkddPM_A"
                    },
                    "mutability": "MUTABLE",
                    "type": "LINK"
                }
            }
        },
        "possible_duplicate_info": {
            "duplicate_candidate": {

            },
            "duplicate_candidate_id": "",
            "is_possible_duplicate": false
        },
        "race": "Swedish",
        "relationship_status": "Single",
        "report_settings": {
            "_id": "0ec89bdb-1764-49f6-b55e-1a07cd51778c",
            "action_plan": {
                "allowed": false
            },
            "executive_summary": {
                "allowed": false
            },
            "full_report": {
                "allowed": false
            }
        },
        "reports": {
            "action_plan_report": false,
            "executive_summary_report": false,
            "full_report": false
        },
        "reviewed_on": "",
        "risk": "low",
        "school": "AZ Alkmaar",
        "stats": {
            "instagram": {
                "negative_percentage": 10.0,
                "neutral_percentage": 24.29,
                "positive_percentage": 65.71,
                "total": 0,
                "total_negative": 21,
                "total_neutral": 51,
                "total_positive": 138
            },
            "negative_percentage": 10.0,
            "neutral_percentage": 24.29,
            "positive_percentage": 65.71,
            "total": 210,
            "total_negative": 21,
            "total_neutral": 51,
            "total_positive": 138,
            "tweets": {
                "negative_percentage": 0,
                "neutral_percentage": 0,
                "positive_percentage": 0,
                "total": 0,
                "total_negative": 0,
                "total_neutral": 0,
                "total_positive": 0
            },
            "videos": {
                "total": 0
            }
        },
        "status": "Approval Pending",
        "team": "Blu Chip Analytics",
        "twitter": "na",
        "twitter_source_ln": "en",
        "whatsapp": "N/A",
        "sport": "Football",
    },
    "0677fe11-0639-405c-80b4-8100f63aad6a": {
        "_id": "0677fe11-0639-405c-80b4-8100f63aad6a",
        "analysed_on": "06/04/2021, 12:42:03",
        "consent": "Received",
        "consent_form_id": "32958f70-6a09-4d15-a30b-b9c116b10031",
        "created_on": "06/03/2021, 22:54:04",
        "dob": "11/22/02",
        "email": "ppaul177@gmail.com",
        "full_name": "Peter Paul",
        "hometown": "Fort Pierce, Florida",
        "image_url": "http://127.0.0.1:3434/export/default_profile_pic",
        "insights": {
            "action_plan": {
                "Connection is Key": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Connection is Key",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Establish the Environment": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Establish the Environment",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Mental Mindset": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Mental Mindset",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                }
            },
            "custom_traits": {
                "character": {
                    "description": "An individual\u2019s emotional and mental qualities based on their morals, virtues, and humility that aid in executing tasks.",
                    "label": "Character",
                    "score": 61.72143750000001,
                    "sub": {
                        "hard_working": {
                            "left_label": "Hard-Working",
                            "right_label": "Lazy",
                            "score": 64.81875,
                            "text": ""
                        },
                        "high_integrity": {
                            "left_label": "High Integrity",
                            "right_label": "Low Integrity",
                            "score": 61.428375,
                            "text": ""
                        },
                        "humble": {
                            "left_label": "Humility",
                            "right_label": "Arrogant",
                            "score": 58.9171875,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of character. Individuals may work hard in certain performance domains. They may challenge others and express some levels of gratitude, humility, and integrity. Individuals may not be consistent in their ability to meet expectations, respect, and respond in positive manner in all situations."
                },
                "coachability": {
                    "description": "An individual\u2019s ability to listen, learn, be open to feedback, manage criticism, adapt their skill set, and perform at their optimal level.",
                    "label": "Coachability",
                    "score": 66.50062500000001,
                    "sub": {
                        "dependable": {
                            "left_label": "Dependable",
                            "right_label": "Unreliable",
                            "score": 73.5665625,
                            "text": ""
                        },
                        "willingness_to_learn ": {
                            "left_label": "Willingness to Learn",
                            "right_label": "Closed Minded",
                            "score": 59.434687499999995,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of coachability. Individuals may be moderately reliable, and adhere to meeting times, complete tasks, and perform as expected at times. Some may tend to be open to feedback, but could demonstrate difficulties managing criticism, or have difficulties adapting behavioral change to specific coaching feedback. Individuals may take time to learn and adjust performance goals."
                },
                "composure": {
                    "description": "An individual\u2019s ability to remain calm, persistent, and in control under pressure situations or after mistakes. Individuals\u2019 thrive in high pressure situations and demonstrate enhanced performance outcomes.",
                    "label": "Composure",
                    "score": 68.31571875,
                    "sub": {
                        "calm": {
                            "left_label": "Calm",
                            "right_label": "Susceptible to Stress",
                            "score": 61.217812499999994,
                            "text": ""
                        },
                        "driven": {
                            "left_label": "Driven",
                            "right_label": "Unambitious",
                            "score": 75.41362500000001,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 70-100% demonstrate a high level of composure. Individuals have the ability to manage emotions, stay calm during high stress situations, think clearly, and perform optimally in high pressure situations. Some individuals may strive in high pressure situations and perform at optimal level. Individuals have high drive and passion to meet performance outcomes and remain calm in pursuit of excellence."
                },
                "leadership": {
                    "description": "An individual\u2019s ability to influence, inspire, and communicate a common goal to others.",
                    "label": "Leadership",
                    "score": 70.668125,
                    "sub": {
                        "accountable": {
                            "left_label": "Accountable",
                            "right_label": "Unaccountable",
                            "score": 71.176875,
                            "text": ""
                        },
                        "confident": {
                            "left_label": "Confident",
                            "right_label": "Insecure",
                            "score": 63.35475,
                            "text": ""
                        },
                        "vocal": {
                            "left_label": "Vocal",
                            "right_label": "Unspoken",
                            "score": 77.47274999999999,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 70-100% demonstrate a high level of leadership capacity. Individuals will be accountable, inspire others to work toward a common goal, assist in communicating goals, and cultural message to others. Individuals are highly self-confident and display their belief in themselves and others through behavior and communicating vocally."
                }
            },
            "executive_summary": {
                "Significant Background Information": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Significant Background Information",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Elite 3 sport athlete (quarterback, point guard) in high school; chose baseball at UF over playing football. Feels baseball is \u201csafer\u201d than football.",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "7973v",
                            "text": "Seen as highly athletic and unique in ability to pick up sports and excel with little background in them. ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "6qo6e",
                            "text": "Grandmother passed recently. Florida Gators was her favorite team",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "2lavi",
                            "text": "\u201cHe puts god and family first,\u201d says high school athletic director",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5a02d",
                            "text": "Speaks about focusing on what he can control, staying focused on the process and going with the flow, in terms of his college sport choice and professional draft status  ",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Symptoms and Information Captured": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Symptoms and Information Captured",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "-Blessed and humbled for offers across all sports; reciprocal interactions and thank you",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "bk5bd",
                            "text": "-Supportive and praising of others; highlights their accomplishments ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "1qtje",
                            "text": "-Heart and prayer emojis",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "69rap",
                            "text": "-Highlights all sports he plays",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "f6fcp",
                            "text": "-Family and support; RIP grandma",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "9mt3i",
                            "text": "-Optimistic and interactive ",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "eoktp",
                            "text": "-Confident/hard working, driven and goal oriented",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "2b5dm",
                            "text": "-Motivational posting",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "equjc",
                            "text": "-Posts highlights, lot of videos of his play with captions",
                            "type": "unstyled"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "cdmaq",
                            "text": "-Long term girlfriend ",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                }
            },
            "personality traits": {
                "agreeableness": {
                    "score": 57.839999999999996,
                    "sub": {
                        "altruism": {
                            "score": 67.4925,
                            "text": "The individual is moderately altruistic. He/she has an inclination to help others with 'no strings attached' and feel fulfilled doing so."
                        },
                        "cooperation": {
                            "score": 54.622499999999995,
                            "text": "The individual is moderately accommodating. He/she has an inclination to compromise and/or please others. "
                        },
                        "modesty": {
                            "score": 22.4775,
                            "text": "The individual is highly comfortable with attention. He/she has a tendency to need to be the center of attention and welcomes, or may even seek, situations in which he/she is the center of attention. Individuals may be perceived as arrogant. "
                        },
                        "sympathy": {
                            "score": 54.2325,
                            "text": "The individual is empathetic. When an opportunity presents, he/she is able to put him/herself in others' shoes and has an inclination to show compassion toward others. "
                        },
                        "trust": {
                            "score": 83.385,
                            "text": "The individual is a highly trusting person. He/she has a high tendency to believe people are inherently good and trust them easily, regardless of their actions/inactions that substantiate or refute this disposition."
                        },
                        "uncompromising": {
                            "score": 64.815,
                            "text": "The individual is unwilling to break his/her own moral standards and has an inclination to be uncompromising in his/her values and beliefs."
                        }
                    },
                    "text": "Agreeableness refers to an individual's general congeniality and ability to put other people's needs before their own."
                },
                "conscientiousness": {
                    "score": 73.155,
                    "sub": {
                        "achievement_striving": {
                            "score": 86.69999999999999,
                            "text": "The individual is a highly driven person. He/she has a tendency to set high goals for him/herself and work hard to achieve them. Individuals in this range may appear to be compulsive or overly focused on achieving lofty goals."
                        },
                        "cautiousness": {
                            "score": 49.875,
                            "text": "The individual is a moderately bold thinker. He/she has an inclination to take bold action with limited forethought. "
                        },
                        "dutifulness": {
                            "score": 68.595,
                            "text": "The individual is moderately dutiful. He/she has an inclination to approach his/her obligations responsibly. These individuals are likely to fulfill their obligations and keep their promises. "
                        },
                        "orderliness": {
                            "score": 70.695,
                            "text": "The individual is moderately organized. He/she has an inclination of needing to strucutre and plan events and surroundings. "
                        },
                        "self_discipline": {
                            "score": 74.2125,
                            "text": "The individual is persistent. He/she has an inclination of working through challenges without quitting."
                        },
                        "self_efficacy": {
                            "score": 88.85249999999999,
                            "text": "The individual is a highly self-assured person. He/she has a high tendency to believe in his/her own capabilities in achieving goals. Actual abilities and capabilities may not align with an individual's perception of their abilities. "
                        }
                    },
                    "text": "Conscientiousness refers to an Indvidual's propensity for orderliness and a need to plan. "
                },
                "emotional_range": {
                    "score": 65.73750000000001,
                    "sub": {
                        "calm_in_pressure": {
                            "score": 61.2375,
                            "text": "The individual is moderately prone to stress. He/she has an inclination to get exited and anxious in stressful situations."
                        },
                        "composure": {
                            "score": 34.5075,
                            "text": "The individual is a moderately even-tempered person. He/she has an inclination of keeping calm in irritable/unfavorable situations."
                        },
                        "contentment": {
                            "score": 74.32499999999999,
                            "text": "The individual is a moderately negative person. He/she has an inclination of thinking about the negative aspects of one's life."
                        },
                        "self_assuredness": {
                            "score": 61.8975,
                            "text": "The individual is moderately anxious. He/she has an inclination of thinking about things in a negative/unfavorable manner."
                        },
                        "self_confidence": {
                            "score": 75.87,
                            "text": "The individual is a highly self-conscious person. He/she has a high tendency to be strongly affected (mostly negatively) by others perception of one self."
                        },
                        "self_control": {
                            "score": 86.565,
                            "text": "The individual is highly self-indulgent. He/she has a high tendency of being tempted by desires."
                        }
                    },
                    "text": "Emotional Stability refers to how an an individual tends to respond to his/her environment or experience a negative state of mind."
                },
                "extroversion": {
                    "score": 80.52000000000001,
                    "sub": {
                        "activity_level": {
                            "score": 87.285,
                            "text": "The individual has a strong preference for more tasks at a faster pace. He/she will be engaged with several tasks at once. While he/she may be involved in many activities, busyness does not necessarily equate to productivity. "
                        },
                        "assertiveness": {
                            "score": 84.39750000000001,
                            "text": "The individual is a highly assertive person. He/she has a tendency to take charge in group environments. This person may appear as a natural leader, although he/she may be seen as 'bossy' or be perceived as micomanaging others."
                        },
                        "cheerfulness": {
                            "score": 88.56,
                            "text": "The individual displayes feelings of positivity, joy, enthusiasm, and optimism outwardly. "
                        },
                        "excitement_seeking": {
                            "score": 62.2275,
                            "text": "The individual moderately prefers excitement. He/she has an inclination to seek out people and situations that are exciting or thrilling. "
                        },
                        "gregariousness": {
                            "score": 78.315,
                            "text": "The individual is highly sociable. He/she has a strong preference/tendency to enjoy large social gatherings regularly. "
                        },
                        "outgoing": {
                            "score": 82.365,
                            "text": "The individual is a highly outgoing person. He/she has a high tendency of being friendly toward new people and has a preference for being around others. "
                        }
                    },
                    "text": "Extroversion refers to an individual's tendency to seek stimulation via the company of others. Extroversion/Introversion does not imply interest in others; it refers to the energy one feels as a result of interactions with others."
                },
                "openness": {
                    "score": 37.0575,
                    "sub": {
                        "adventurousness": {
                            "score": 26.775,
                            "text": "The individual has a low level of creativity and curiosity for new experiences. He/she has an inclination of accepting tradition and status quo."
                        },
                        "artistic_interests": {
                            "score": 37.7775,
                            "text": "The individual has a low level of enthusiasm about creativity or seeking beautiful experiences. He/she has an inclination of being indifferent about creative or beautiful experiences."
                        },
                        "authority_challenging": {
                            "score": 17.1525,
                            "text": "The individual is complient, traditional, and obedient. He/she has a high tendency to obey authority, follow rules, and be closed to 'unconventional' lifesyles and values/beliefs."
                        },
                        "emotionality": {
                            "score": 79.1275,
                            "text": "The individual is highly emotionally aware and has a tendency of seeing emotions as being central to his/her life. This person is highly comfortable expressing feelings and will dedicate time specifically to experiencing those feelings. "
                        },
                        "imagination": {
                            "score": 51.735,
                            "text": "The individual is a moderately imaginative person. He/she has an inclination of being comfortable with letting his/her imagination do the thinking while still keeping an eye on the facts. "
                        },
                        "intellect": {
                            "score": 19.77,
                            "text": "The individual is highly practical. He/she has a high tendency of accepting facts with limited-to-no appreciation for theory. "
                        }
                    },
                    "text": "Openness refers to an individual's general willingness to engage in new experiences and learn new things. Individuals that score high in Openness are more imaginative and creative. Those low in Openness tend to be more analytical and resistant to change and prefer tradition."
                }
            },
            "psychological disorders": {
                "antisocial": {
                    "score": 23.136304676495907,
                    "text": "Possibility of existing level of Antisocial Personality Disorder is very low or none."
                },
                "avoidant": {
                    "score": 24.914136754417704,
                    "text": "Possibility of existing level of Avoidant Personality Disorder is very low or none."
                },
                "borderline": {
                    "score": 24.719152576270922,
                    "text": "Possibility of existing level of Borderline Personality Disorder is very low or none."
                },
                "dependent": {
                    "score": 25.50864787897207,
                    "text": "Possibility of existing level of Dependent Personality Disorder is very low or none."
                },
                "histrionic": {
                    "score": 80.73442083600524,
                    "text": "A considerable possibility of individual being self-centered and with less or no concern for others, rash decision making, constantly seeking approval and tendency to act with exaggerated actions and emotions. A closer look at the individual is recommended."
                },
                "narcissistic": {
                    "score": 45.18378498723163,
                    "text": "A moderate possibility of believing that he or she is special and unique while expecting admiration from others."
                },
                "obsessive_compulsive": {
                    "score": 76.09364375584562,
                    "text": "A considerable possibility of the individual showing compulsive and obsessive behaviours and a closer look at the individual is recommended."
                },
                "paranoid": {
                    "score": 21.857120691526564,
                    "text": "Possibility of existing level of Paranoid Personality Disorder is very low or none."
                },
                "schizotypal": {
                    "score": 23.05545469112169,
                    "text": "Possibility of existing level of Schizotypal Personality Disorder is very low or none."
                }
            },
            "risk": {
                "Aggression/Violence": {
                    "comments": [

                    ],
                    "score": 10,
                    "text": "Individuals low (1-3) in this range demonstrates minimal risk in aggression/violence. The individual demonstrates the ability to manage emotions (e.g. irritability, frustration, annoyances) and may utilize levels of anger as a motivating source for success."
                },
                "Delinquent Behavior": {
                    "comments": [

                    ],
                    "score": 20,
                    "text": "Individuals low (1-3) in this range demonstrate low expression of delinquent behavior. Individuals in this range adhere to societal, cultural norms. Generally, following standard procedures and expectations from authority figures. "
                },
                "Drug/Alcohol": {
                    "comments": [

                    ],
                    "score": 20,
                    "text": "Individuals low (1-3) in this range demonstrate minimal risky behavior in drug/alcohol use. Individuals either demonstrate normative minimal engagement in acceptable use that falls within normative range or does not indicate substance use per analysis. Should be noted individuals may be partaking in higher risk behavior but minimizing their full expression via social media/ public expression"
                },
                "Emotional Distress": {
                    "comments": [

                    ],
                    "score": 10,
                    "text": "Individuals low (1-3) in this range demonstrate minimal levels of emotional distress. Individuals can manage situational stressors. They are likely able to cope with a high stress, manage emotions effectively, and function in high adaptable scenarios. Individuals will most likely have positive social support, effective communication tools, and high awareness in one\u2019s self."
                },
                "Overall": {
                    "comments": [

                    ],
                    "score": 16.0,
                    "text": "Risk score invalid to generate analysis text"
                },
                "Sexual Behavior": {
                    "comments": [

                    ],
                    "score": 20,
                    "text": "Individuals low (1-3) in this range demonstrate minimal sexual expression, minimal indication of sexual behavioral risk. Should be noted individuals may be partaking in risky behavior but minimizing their expression via social media/public expression."
                }
            }
        },
        "instagram": "paul_177",
        "is_paid": true,
        "is_valid_format": true,
        "linkedin": "",
        "links": [],
        "message": {
            "button_label": "",
            "content": "",
            "severity": "info",
            "show": false,
            "title": ""
        },
        "possible_duplicate_info": {
            "duplicate_candidate": {

            },
            "duplicate_candidate_id": "",
            "is_possible_duplicate": false
        },
        "race": "",
        "relationship_status": "",
        "report_settings": {
            "_id": "0677fe11-0639-405c-80b4-8100f63aad6a",
            "action_plan": {
                "allowed": true
            },
            "executive_summary": {
                "allowed": true
            },
            "full_report": {
                "allowed": true
            }
        },
        "reports": {
            "action_plan_report": false,
            "executive_summary_report": false,
            "full_report": false
        },
        "reviewed_on": "06/25/2021, 12:36:09",
        "risk": "low",
        "school": "John Carroll HS",
        "stats": {
            "instagram": {
                "negative_percentage": 27.08,
                "neutral_percentage": 22.92,
                "positive_percentage": 50.0,
                "total": 366,
                "total_negative": 13,
                "total_neutral": 11,
                "total_positive": 24
            },
            "negative_percentage": 13.53,
            "neutral_percentage": 18.84,
            "positive_percentage": 67.63,
            "total": 414,
            "total_negative": 56,
            "total_neutral": 78,
            "total_positive": 280,
            "tweets": {
                "negative_percentage": 11.75,
                "neutral_percentage": 18.31,
                "positive_percentage": 69.95,
                "total": 366,
                "total_negative": 43,
                "total_neutral": 67,
                "total_positive": 256
            },
            "videos": {
                "total": 0
            }
        },
        "status": "Completed",
        "team": "Blu Chip Analytics",
        "twitter": "Peter_Paul177",
        "whatsapp": "",
        "sport": "Football",
    },
    "065becc1-631c-460d-bbea-457a67452ace": {
        "_id": "065becc1-631c-460d-bbea-457a67452ace",
        "analysed_on": "11/06/2020, 12:42:42",
        "consent": "Pending",
        "consent_form_id": "N/A",
        "created_on": "08/10/2020, 17:43:32",
        "dob": "5/27/99",
        "email": "info@bluchipanalytics.com",
        "formatted_record": {
            "dob": "5/27/99",
            "email": "info@bluchipanalytics.com",
            "full_name": "Brent Castro",
            "hometown": "College Station, TX",
            "instagram": "brentcastro",
            "linkedin": "",
            "notes": {

            },
            "race": "White",
            "relationship_status": "",
            "school": "Dallas Baptist",
            "team": "Blu Chip Analytics",
            "twitter": "brentcastro",
            "whatsapp": ""
        },
        "full_name": "Brent Castro",
        "hometown": "College Station, TX",
        "image_url": "http://127.0.0.1:3434/export/default_profile_pic",
        "insights": {
            "action_plan": {
                "Connection is Key": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Connection is Key",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Establish the Environment": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Establish the Environment",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                },
                "Mental Mindset": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Mental Mindset",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                }
            },
            "custom_traits": {
                "character": {
                    "description": "An individual\u2019s emotional and mental qualities based on their morals, virtues, and humility that aid in executing tasks.",
                    "label": "Character",
                    "score": 60.216249999999995,
                    "sub": {
                        "hard_working": {
                            "left_label": "Hard-Working",
                            "right_label": "Lazy",
                            "score": 62.272875000000006,
                            "text": ""
                        },
                        "high_integrity": {
                            "left_label": "High Integrity",
                            "right_label": "Low Integrity",
                            "score": 62.058375,
                            "text": ""
                        },
                        "humble": {
                            "left_label": "Humility",
                            "right_label": "Arrogant",
                            "score": 56.3175,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of character. Individuals may work hard in certain performance domains. They may challenge others and express some levels of gratitude, humility, and integrity. Individuals may not be consistent in their ability to meet expectations, respect, and respond in positive manner in all situations."
                },
                "coachability": {
                    "description": "An individual\u2019s ability to listen, learn, be open to feedback, manage criticism, adapt their skill set, and perform at their optimal level.",
                    "label": "Coachability",
                    "score": 63.895546875,
                    "sub": {
                        "dependable": {
                            "left_label": "Dependable",
                            "right_label": "Unreliable",
                            "score": 69.39421875000001,
                            "text": ""
                        },
                        "willingness_to_learn ": {
                            "left_label": "Willingness to Learn",
                            "right_label": "Closed Minded",
                            "score": 58.39687500000001,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of coachability. Individuals may be moderately reliable, and adhere to meeting times, complete tasks, and perform as expected at times. Some may tend to be open to feedback, but could demonstrate difficulties managing criticism, or have difficulties adapting behavioral change to specific coaching feedback. Individuals may take time to learn and adjust performance goals."
                },
                "composure": {
                    "description": "An individual\u2019s ability to remain calm, persistent, and in control under pressure situations or after mistakes. Individuals\u2019 thrive in high pressure situations and demonstrate enhanced performance outcomes.",
                    "label": "Composure",
                    "score": 64.993171875,
                    "sub": {
                        "calm": {
                            "left_label": "Calm",
                            "right_label": "Susceptible to Stress",
                            "score": 61.98421875,
                            "text": ""
                        },
                        "driven": {
                            "left_label": "Driven",
                            "right_label": "Unambitious",
                            "score": 68.002125,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of composure. Individuals may have the capacity to remain calm and manage stressful situations to a degree. They have a moderate drive and purpose to strive for. In high pressure situations they may react and have difficulties managing their emotions on a consistent basis."
                },
                "leadership": {
                    "description": "An individual\u2019s ability to influence, inspire, and communicate a common goal to others.",
                    "label": "Leadership",
                    "score": 67.2346875,
                    "sub": {
                        "accountable": {
                            "left_label": "Accountable",
                            "right_label": "Unaccountable",
                            "score": 64.48781249999999,
                            "text": ""
                        },
                        "confident": {
                            "left_label": "Confident",
                            "right_label": "Insecure",
                            "score": 63.71812499999999,
                            "text": ""
                        },
                        "vocal": {
                            "left_label": "Vocal",
                            "right_label": "Unspoken",
                            "score": 73.498125,
                            "text": ""
                        }
                    },
                    "text": "Individuals who fall within 30-70% demonstrate a moderate level of leadership capacity. Individuals may work hard toward goals and can inspire small groups or a few individuals. Individuals may lack the ability to be vocal and speak to larger members to inspire a large group to meet cultural goals. Individuals may have the capacity to make small changes and influence members around them. Individuals may have moderate level of confidence, but poor performances or influence may negatively impact self-worth and ability to perform at optimal level."
                }
            },
            "executive_summary": {
                "Significant Background Information": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Significant Background Information",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "5ssk7",
                            "text": "Spent 4th year of college acquiring Master's degree",
                            "type": "unordered-list-item"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "6u9l8",
                            "text": "Favorite Bible verse is Psalm 27:1 - \"The Lord is my light and my salvation, whom shall I fear? The Lord is the stronghold of my life, of whom shall I be afraid?\"",
                            "type": "unordered-list-item"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "54fqv",
                            "text": "Named after father and grandfather; grandfather passed in 2019",
                            "type": "unordered-list-item"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 38,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                }
                            ],
                            "key": "3k3o6",
                            "text": "Also played football in HS, plays golf",
                            "type": "unordered-list-item"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 18,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                }
                            ],
                            "key": "19aoa",
                            "text": "Has younger sister",
                            "type": "unordered-list-item"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 107,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                }
                            ],
                            "key": "3r734",
                            "text": "Shows religious foundation. Quoted in an article saying, \"I'm a big believer that God has a plan for me...\"",
                            "type": "unordered-list-item"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 77,
                                    "offset": 0,
                                    "style": "bgcolor-transparent"
                                },
                                {
                                    "length": 77,
                                    "offset": 0,
                                    "style": "fontsize-11pt"
                                },
                                {
                                    "length": 77,
                                    "offset": 0,
                                    "style": "fontfamily-Calibri, Calibri_EmbeddedFont, Calibri_MSFontService, sans-serif"
                                },
                                {
                                    "length": 77,
                                    "offset": 0,
                                    "style": "bgcolor-inherit"
                                },
                                {
                                    "length": 77,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                }
                            ],
                            "key": "8ferc",
                            "text": "first player in school history to pitch for the U.S. Collegiate National Team",
                            "type": "unordered-list-item"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 35,
                                    "offset": 0,
                                    "style": "color-windowtext"
                                },
                                {
                                    "length": 4,
                                    "offset": 36,
                                    "style": "color-windowtext"
                                },
                                {
                                    "length": 6,
                                    "offset": 41,
                                    "style": "color-windowtext"
                                },
                                {
                                    "length": 22,
                                    "offset": 48,
                                    "style": "color-windowtext"
                                },
                                {
                                    "length": 35,
                                    "offset": 0,
                                    "style": "bgcolor-transparent"
                                },
                                {
                                    "length": 4,
                                    "offset": 36,
                                    "style": "bgcolor-transparent"
                                },
                                {
                                    "length": 6,
                                    "offset": 41,
                                    "style": "bgcolor-transparent"
                                },
                                {
                                    "length": 22,
                                    "offset": 48,
                                    "style": "bgcolor-transparent"
                                },
                                {
                                    "length": 35,
                                    "offset": 0,
                                    "style": "fontsize-11pt"
                                },
                                {
                                    "length": 4,
                                    "offset": 36,
                                    "style": "fontsize-11pt"
                                },
                                {
                                    "length": 6,
                                    "offset": 41,
                                    "style": "fontsize-11pt"
                                },
                                {
                                    "length": 22,
                                    "offset": 48,
                                    "style": "fontsize-11pt"
                                },
                                {
                                    "length": 35,
                                    "offset": 0,
                                    "style": "fontfamily-Calibri, Calibri_EmbeddedFont, Calibri_MSFontService, sans-serif"
                                },
                                {
                                    "length": 4,
                                    "offset": 36,
                                    "style": "fontfamily-Calibri, Calibri_EmbeddedFont, Calibri_MSFontService, sans-serif"
                                },
                                {
                                    "length": 6,
                                    "offset": 41,
                                    "style": "fontfamily-Calibri, Calibri_EmbeddedFont, Calibri_MSFontService, sans-serif"
                                },
                                {
                                    "length": 22,
                                    "offset": 48,
                                    "style": "fontfamily-Calibri, Calibri_EmbeddedFont, Calibri_MSFontService, sans-serif"
                                },
                                {
                                    "length": 35,
                                    "offset": 0,
                                    "style": "bgcolor-inherit"
                                },
                                {
                                    "length": 4,
                                    "offset": 36,
                                    "style": "bgcolor-inherit"
                                },
                                {
                                    "length": 6,
                                    "offset": 41,
                                    "style": "bgcolor-inherit"
                                },
                                {
                                    "length": 22,
                                    "offset": 48,
                                    "style": "bgcolor-inherit"
                                },
                                {
                                    "length": 71,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                }
                            ],
                            "key": "f4bjt",
                            "text": "Appears to be good student. Apply & hard worker on and off the field.\u00a0 ",
                            "type": "unordered-list-item"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 38,
                                    "offset": 0,
                                    "style": "color-windowtext"
                                },
                                {
                                    "length": 12,
                                    "offset": 39,
                                    "style": "color-windowtext"
                                },
                                {
                                    "length": 11,
                                    "offset": 52,
                                    "style": "color-windowtext"
                                },
                                {
                                    "length": 38,
                                    "offset": 0,
                                    "style": "bgcolor-transparent"
                                },
                                {
                                    "length": 12,
                                    "offset": 39,
                                    "style": "bgcolor-transparent"
                                },
                                {
                                    "length": 11,
                                    "offset": 52,
                                    "style": "bgcolor-transparent"
                                },
                                {
                                    "length": 38,
                                    "offset": 0,
                                    "style": "fontsize-11pt"
                                },
                                {
                                    "length": 12,
                                    "offset": 39,
                                    "style": "fontsize-11pt"
                                },
                                {
                                    "length": 11,
                                    "offset": 52,
                                    "style": "fontsize-11pt"
                                },
                                {
                                    "length": 38,
                                    "offset": 0,
                                    "style": "fontfamily-Calibri, Calibri_EmbeddedFont, Calibri_MSFontService, sans-serif"
                                },
                                {
                                    "length": 12,
                                    "offset": 39,
                                    "style": "fontfamily-Calibri, Calibri_EmbeddedFont, Calibri_MSFontService, sans-serif"
                                },
                                {
                                    "length": 11,
                                    "offset": 52,
                                    "style": "fontfamily-Calibri, Calibri_EmbeddedFont, Calibri_MSFontService, sans-serif"
                                },
                                {
                                    "length": 38,
                                    "offset": 0,
                                    "style": "bgcolor-inherit"
                                },
                                {
                                    "length": 12,
                                    "offset": 39,
                                    "style": "bgcolor-inherit"
                                },
                                {
                                    "length": 11,
                                    "offset": 52,
                                    "style": "bgcolor-inherit"
                                },
                                {
                                    "length": 65,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                }
                            ],
                            "key": "5uvbu",
                            "text": "Has one sister who plays basketball at Southwestern university.  ",
                            "type": "unordered-list-item"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [
                                {
                                    "key": 0,
                                    "length": 80,
                                    "offset": 117
                                }
                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 81,
                                    "offset": 117,
                                    "style": "color-rgb(88,103,221)"
                                },
                                {
                                    "length": 25,
                                    "offset": 0,
                                    "style": "fontsize-11pt"
                                },
                                {
                                    "length": 89,
                                    "offset": 26,
                                    "style": "fontsize-11pt"
                                },
                                {
                                    "length": 1,
                                    "offset": 116,
                                    "style": "fontsize-11pt"
                                },
                                {
                                    "length": 199,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                },
                                {
                                    "length": 25,
                                    "offset": 0,
                                    "style": "bgcolor-transparent"
                                },
                                {
                                    "length": 89,
                                    "offset": 26,
                                    "style": "bgcolor-transparent"
                                },
                                {
                                    "length": 82,
                                    "offset": 116,
                                    "style": "bgcolor-transparent"
                                },
                                {
                                    "length": 25,
                                    "offset": 0,
                                    "style": "color-windowtext"
                                },
                                {
                                    "length": 89,
                                    "offset": 26,
                                    "style": "color-windowtext"
                                },
                                {
                                    "length": 82,
                                    "offset": 116,
                                    "style": "color-windowtext"
                                },
                                {
                                    "length": 81,
                                    "offset": 117,
                                    "style": "fontsize-9.5pt"
                                },
                                {
                                    "length": 25,
                                    "offset": 0,
                                    "style": "bgcolor-inherit"
                                },
                                {
                                    "length": 89,
                                    "offset": 26,
                                    "style": "bgcolor-inherit"
                                },
                                {
                                    "length": 82,
                                    "offset": 116,
                                    "style": "bgcolor-inherit"
                                },
                                {
                                    "length": 25,
                                    "offset": 0,
                                    "style": "fontfamily-Calibri, Calibri_EmbeddedFont, Calibri_MSFontService, sans-serif"
                                },
                                {
                                    "length": 89,
                                    "offset": 26,
                                    "style": "fontfamily-Calibri, Calibri_EmbeddedFont, Calibri_MSFontService, sans-serif"
                                },
                                {
                                    "length": 82,
                                    "offset": 116,
                                    "style": "fontfamily-Calibri, Calibri_EmbeddedFont, Calibri_MSFontService, sans-serif"
                                },
                                {
                                    "length": 80,
                                    "offset": 117,
                                    "style": "color-inherit"
                                }
                            ],
                            "key": "8t2eq",
                            "text": "Vocal about his emotions. Talks about his injury, his performance in games, how he is feeling when he misses a game (My boys got the job done yesterday. Man I wish I Could've played. #TigerBaseball) ",
                            "type": "unordered-list-item"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [
                                {
                                    "key": 1,
                                    "length": 60,
                                    "offset": 92
                                }
                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 60,
                                    "offset": 92,
                                    "style": "color-rgb(88,103,221)"
                                },
                                {
                                    "length": 4,
                                    "offset": 0,
                                    "style": "fontsize-11pt"
                                },
                                {
                                    "length": 5,
                                    "offset": 5,
                                    "style": "fontsize-11pt"
                                },
                                {
                                    "length": 23,
                                    "offset": 11,
                                    "style": "fontsize-11pt"
                                },
                                {
                                    "length": 6,
                                    "offset": 35,
                                    "style": "fontsize-11pt"
                                },
                                {
                                    "length": 49,
                                    "offset": 42,
                                    "style": "fontsize-11pt"
                                },
                                {
                                    "length": 40,
                                    "offset": 152,
                                    "style": "fontsize-11pt"
                                },
                                {
                                    "length": 192,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                },
                                {
                                    "length": 4,
                                    "offset": 0,
                                    "style": "bgcolor-transparent"
                                },
                                {
                                    "length": 5,
                                    "offset": 5,
                                    "style": "bgcolor-transparent"
                                },
                                {
                                    "length": 23,
                                    "offset": 11,
                                    "style": "bgcolor-transparent"
                                },
                                {
                                    "length": 6,
                                    "offset": 35,
                                    "style": "bgcolor-transparent"
                                },
                                {
                                    "length": 49,
                                    "offset": 42,
                                    "style": "bgcolor-transparent"
                                },
                                {
                                    "length": 100,
                                    "offset": 92,
                                    "style": "bgcolor-transparent"
                                },
                                {
                                    "length": 4,
                                    "offset": 0,
                                    "style": "color-windowtext"
                                },
                                {
                                    "length": 5,
                                    "offset": 5,
                                    "style": "color-windowtext"
                                },
                                {
                                    "length": 23,
                                    "offset": 11,
                                    "style": "color-windowtext"
                                },
                                {
                                    "length": 6,
                                    "offset": 35,
                                    "style": "color-windowtext"
                                },
                                {
                                    "length": 49,
                                    "offset": 42,
                                    "style": "color-windowtext"
                                },
                                {
                                    "length": 100,
                                    "offset": 92,
                                    "style": "color-windowtext"
                                },
                                {
                                    "length": 60,
                                    "offset": 92,
                                    "style": "fontsize-9.5pt"
                                },
                                {
                                    "length": 4,
                                    "offset": 0,
                                    "style": "bgcolor-inherit"
                                },
                                {
                                    "length": 5,
                                    "offset": 5,
                                    "style": "bgcolor-inherit"
                                },
                                {
                                    "length": 23,
                                    "offset": 11,
                                    "style": "bgcolor-inherit"
                                },
                                {
                                    "length": 6,
                                    "offset": 35,
                                    "style": "bgcolor-inherit"
                                },
                                {
                                    "length": 31,
                                    "offset": 42,
                                    "style": "bgcolor-inherit"
                                },
                                {
                                    "length": 15,
                                    "offset": 76,
                                    "style": "bgcolor-inherit"
                                },
                                {
                                    "length": 60,
                                    "offset": 92,
                                    "style": "bgcolor-inherit"
                                },
                                {
                                    "length": 4,
                                    "offset": 0,
                                    "style": "fontfamily-Calibri, Calibri_EmbeddedFont, Calibri_MSFontService, sans-serif"
                                },
                                {
                                    "length": 5,
                                    "offset": 5,
                                    "style": "fontfamily-Calibri, Calibri_EmbeddedFont, Calibri_MSFontService, sans-serif"
                                },
                                {
                                    "length": 23,
                                    "offset": 11,
                                    "style": "fontfamily-Calibri, Calibri_EmbeddedFont, Calibri_MSFontService, sans-serif"
                                },
                                {
                                    "length": 6,
                                    "offset": 35,
                                    "style": "fontfamily-Calibri, Calibri_EmbeddedFont, Calibri_MSFontService, sans-serif"
                                },
                                {
                                    "length": 49,
                                    "offset": 42,
                                    "style": "fontfamily-Calibri, Calibri_EmbeddedFont, Calibri_MSFontService, sans-serif"
                                },
                                {
                                    "length": 100,
                                    "offset": 92,
                                    "style": "fontfamily-Calibri, Calibri_EmbeddedFont, Calibri_MSFontService, sans-serif"
                                },
                                {
                                    "length": 60,
                                    "offset": 92,
                                    "style": "color-inherit"
                                }
                            ],
                            "key": "10tmv",
                            "text": "Many posts of appreciation for his coach, teammates, and his motivation.\u00a0\u00a0--Process focus.\u00a0 Incredibly thankful and fortunate, but it doesn't stop here.\u00a0posts about girlfriend-positive support",
                            "type": "unordered-list-item"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 19,
                                    "offset": 0,
                                    "style": "color-windowtext"
                                },
                                {
                                    "length": 19,
                                    "offset": 0,
                                    "style": "bgcolor-transparent"
                                },
                                {
                                    "length": 19,
                                    "offset": 0,
                                    "style": "fontsize-11pt"
                                },
                                {
                                    "length": 19,
                                    "offset": 0,
                                    "style": "fontfamily-Calibri, Calibri_EmbeddedFont, Calibri_MSFontService, sans-serif"
                                },
                                {
                                    "length": 19,
                                    "offset": 0,
                                    "style": "bgcolor-inherit"
                                },
                                {
                                    "length": 20,
                                    "offset": 0,
                                    "style": "fontsize-16"
                                }
                            ],
                            "key": "a1f32",
                            "text": "Likes to play golf. ",
                            "type": "unordered-list-item"
                        }
                    ],
                    "entityMap": {
                        "0": {
                            "data": {
                                "_map": {
                                    "data": {
                                        "targetOption": "_blank",
                                        "title": "<span class=\"TextRun Underlined  BCX1 SCXW183625969\" lang=\"EN-US\" xml:lang=\"EN-US\" data-contrast=\"none\" style=\"box-sizing: border-box; color: rgb(88, 103, 221); font-family: Calibri, Calibri_EmbeddedFont, Calibri_MSFontService, sans-serif; font-size: 9.5pt; line-height: 16.18px; margin: 0px; padding: 0px; text-decoration-line: underline; -webkit-tap-highlight-color: transparent;\"><span class=\"NormalTextRun  BCX1 SCXW183625969\" data-ccp-charstyle=\"Hyperlink\" style=\"box-sizing: border-box; background-color: inherit; margin: 0px; padding: 0px; -webkit-tap-highlight-color: transparent;\">My boys got the job done yesterday. Man I wish I Could've played. #TigerBaseball</span></span>",
                                        "url": "https://twitter.com/user/status/449892163958996993"
                                    },
                                    "mutability": "MUTABLE",
                                    "type": "LINK"
                                },
                                "targetOption": "_blank",
                                "title": "<span class=\"TextRun Underlined  BCX1 SCXW183625969\" lang=\"EN-US\" xml:lang=\"EN-US\" data-contrast=\"none\" style=\"box-sizing: border-box; color: rgb(88, 103, 221); font-family: Calibri, Calibri_EmbeddedFont, Calibri_MSFontService, sans-serif; font-size: 9.5pt; line-height: 16.18px; margin: 0px; padding: 0px; text-decoration-line: underline; -webkit-tap-highlight-color: transparent;\"><span class=\"NormalTextRun  BCX1 SCXW183625969\" data-ccp-charstyle=\"Hyperlink\" style=\"box-sizing: border-box; background-color: inherit; margin: 0px; padding: 0px; -webkit-tap-highlight-color: transparent;\">My boys got the job done yesterday. Man I wish I Could've played. #TigerBaseball</span></span>",
                                "url": "https://twitter.com/user/status/449892163958996993"
                            },
                            "mutability": "MUTABLE",
                            "type": "LINK"
                        },
                        "1": {
                            "data": {
                                "_map": {
                                    "data": {
                                        "targetOption": "_blank",
                                        "title": "<span class=\"TextRun Underlined  BCX1 SCXW183625969\" lang=\"EN-US\" xml:lang=\"EN-US\" data-contrast=\"none\" style=\"box-sizing: border-box; color: rgb(88, 103, 221); font-family: Calibri, Calibri_EmbeddedFont, Calibri_MSFontService, sans-serif; font-size: 9.5pt; line-height: 16.18px; margin: 0px; padding: 0px; text-decoration-line: underline; -webkit-tap-highlight-color: transparent;\"><span class=\"NormalTextRun  BCX1 SCXW183625969\" data-ccp-charstyle=\"Hyperlink\" style=\"box-sizing: border-box; background-color: inherit; margin: 0px; padding: 0px; -webkit-tap-highlight-color: transparent;\">Incredibly thankful and fortunate, but it doesn't stop here.</span></span>",
                                        "url": "https://twitter.com/user/status/827004280955666432"
                                    },
                                    "mutability": "MUTABLE",
                                    "type": "LINK"
                                },
                                "targetOption": "_blank",
                                "title": "<span class=\"TextRun Underlined  BCX1 SCXW183625969\" lang=\"EN-US\" xml:lang=\"EN-US\" data-contrast=\"none\" style=\"box-sizing: border-box; color: rgb(88, 103, 221); font-family: Calibri, Calibri_EmbeddedFont, Calibri_MSFontService, sans-serif; font-size: 9.5pt; line-height: 16.18px; margin: 0px; padding: 0px; text-decoration-line: underline; -webkit-tap-highlight-color: transparent;\"><span class=\"NormalTextRun  BCX1 SCXW183625969\" data-ccp-charstyle=\"Hyperlink\" style=\"box-sizing: border-box; background-color: inherit; margin: 0px; padding: 0px; -webkit-tap-highlight-color: transparent;\">Incredibly thankful and fortunate, but it doesn't stop here.</span></span>",
                                "url": "https://twitter.com/user/status/827004280955666432"
                            },
                            "mutability": "MUTABLE",
                            "type": "LINK"
                        }
                    }
                },
                "Symptoms and Information Captured": {
                    "blocks": [
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [

                            ],
                            "key": "drnm6",
                            "text": "Symptoms and Information Captured",
                            "type": "header-three"
                        },
                        {
                            "data": {

                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 15,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "a0abj",
                            "text": "Overall Risk: 4",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "text-align": "start"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 11,
                                    "offset": 0,
                                    "style": "color-rgb(0,0,0)"
                                },
                                {
                                    "length": 172,
                                    "offset": 12,
                                    "style": "color-rgb(0,0,0)"
                                },
                                {
                                    "length": 11,
                                    "offset": 0,
                                    "style": "fontsize-medium"
                                },
                                {
                                    "length": 172,
                                    "offset": 12,
                                    "style": "fontsize-medium"
                                },
                                {
                                    "length": 11,
                                    "offset": 0,
                                    "style": "fontfamily-Calibri, sans-serif"
                                },
                                {
                                    "length": 172,
                                    "offset": 12,
                                    "style": "fontfamily-Calibri, sans-serif"
                                },
                                {
                                    "length": 11,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "5ssk7",
                            "text": "Key Traits: Player is driven and hard-working. Displays leadership qualities. Expresses coachability. Player is able to remain positive and manage stress with high emotional stability.",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "text-align": "start"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 10,
                                    "offset": 0,
                                    "style": "color-rgb(0,0,0)"
                                },
                                {
                                    "length": 227,
                                    "offset": 11,
                                    "style": "color-rgb(0,0,0)"
                                },
                                {
                                    "length": 10,
                                    "offset": 0,
                                    "style": "fontsize-medium"
                                },
                                {
                                    "length": 227,
                                    "offset": 11,
                                    "style": "fontsize-medium"
                                },
                                {
                                    "length": 10,
                                    "offset": 0,
                                    "style": "fontfamily-Calibri, sans-serif"
                                },
                                {
                                    "length": 227,
                                    "offset": 11,
                                    "style": "fontfamily-Calibri, sans-serif"
                                },
                                {
                                    "length": 11,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "emh6n",
                            "text": "Strengths: Highly organized, detailed, and structured. Player is vocal and dependable. Player is dutiful. Persistent when faced with difficulties and challenges. Adheres to culture and structure of program. Maintains integrity and values.",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "text-align": "start"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 16,
                                    "offset": 0,
                                    "style": "color-rgb(0,0,0)"
                                },
                                {
                                    "length": 190,
                                    "offset": 17,
                                    "style": "color-rgb(0,0,0)"
                                },
                                {
                                    "length": 16,
                                    "offset": 0,
                                    "style": "fontsize-medium"
                                },
                                {
                                    "length": 190,
                                    "offset": 17,
                                    "style": "fontsize-medium"
                                },
                                {
                                    "length": 16,
                                    "offset": 0,
                                    "style": "fontfamily-Calibri, sans-serif"
                                },
                                {
                                    "length": 190,
                                    "offset": 17,
                                    "style": "fontfamily-Calibri, sans-serif"
                                },
                                {
                                    "length": 17,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "271a8",
                            "text": "Areas of Growth: Potentially rigid; may struggle deviating from routine, so working on adaptability and flexibility is key. Increase awareness in emotions and how to utilize effectively. Relies on structure.",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "text-align": "start"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 15,
                                    "offset": 0,
                                    "style": "color-rgb(0,0,0)"
                                },
                                {
                                    "length": 164,
                                    "offset": 16,
                                    "style": "color-rgb(0,0,0)"
                                },
                                {
                                    "length": 15,
                                    "offset": 0,
                                    "style": "fontsize-medium"
                                },
                                {
                                    "length": 164,
                                    "offset": 16,
                                    "style": "fontsize-medium"
                                },
                                {
                                    "length": 15,
                                    "offset": 0,
                                    "style": "fontfamily-Calibri, sans-serif"
                                },
                                {
                                    "length": 164,
                                    "offset": 16,
                                    "style": "fontfamily-Calibri, sans-serif"
                                },
                                {
                                    "length": 15,
                                    "offset": 0,
                                    "style": "BOLD"
                                }
                            ],
                            "key": "f7mad",
                            "text": "Risks/Concerns: May be less compassionate towards those around them. May consistently seek out or expect approval and admiration from those around them. Accepts things as they are.",
                            "type": "unstyled"
                        },
                        {
                            "data": {
                                "text-align": "start"
                            },
                            "depth": 0,
                            "entityRanges": [

                            ],
                            "inlineStyleRanges": [
                                {
                                    "length": 18,
                                    "offset": 0,
                                    "style": "color-rgb(0,0,0)"
                                },
                                {
                                    "length": 229,
                                    "offset": 19,
                                    "style": "color-rgb(0,0,0)"
                                },
                                {
                                    "length": 112,
                                    "offset": 249,
                                    "style": "color-rgb(0,0,0)"
                                },
                                {
                                    "length": 63,
                                    "offset": 362,
                                    "style": "color-rgb(0,0,0)"
                                },
                                {
                                    "length": 18,
                                    "offset": 0,
                                    "style": "fontsize-medium"
                                },
                                {
                                    "length": 229,
                                    "offset": 19,
                                    "style": "fontsize-medium"
                                },
                                {
                                    "length": 112,
                                    "offset": 249,
                                    "style": "fontsize-medium"
                                },
                                {
                                    "length": 63,
                                    "offset": 362,
                                    "style": "fontsize-medium"
                                },
                                {
                                    "length": 18,
                                    "offset": 0,
                                    "style": "fontfamily-Calibri, sans-serif"
                                },
                                {
                                    "length": 229,
                                    "offset": 19,
                                    "style": "fontfamily-Calibri, sans-serif"
                                },
                                {
                                    "length": 112,
                                    "offset": 249,
                                    "style": "fontfamily-Calibri, sans-serif"
                                },
                                {
                                    "length": 63,
                                    "offset": 362,
                                    "style": "fontfamily-Calibri, sans-serif"
                                },
                                {
                                    "length": 17,
                                    "offset": 0,
                                    "style": "BOLD"
                                },
                                {
                                    "length": 1,
                                    "offset": 247,
                                    "style": "fontfamily-Apple Color Emoji"
                                }
                            ],
                            "key": "9lalb",
                            "text": "Themes from Posts: Negative posts bottom out around 2015, spikes in positive posts in 2019 and 2020.Interactive on social media with new and old teammates, old coaches. Team oriented after both wins and losses. Uses a variety of emojis, primarily \u2764 with friends. Humor and memes throughout tweets. Use of \u201cblessed\u201d is reinforced by his own quotes in interviews. Tweets using pejorative terms like \u201chah gay\u201d and \u201cching chong\u201d.",
                            "type": "unstyled"
                        }
                    ],
                    "entityMap": {

                    }
                }
            },
            "personality traits": {
                "agreeableness": {
                    "score": 56.24250000000001,
                    "sub": {
                        "altruism": {
                            "score": 59.37,
                            "text": "The individual is moderately altruistic. He/she has an inclination to help others with 'no strings attached' and feel fulfilled doing so."
                        },
                        "cooperation": {
                            "score": 58.2975,
                            "text": "The individual is moderately accommodating. He/she has an inclination to compromise and/or please others. "
                        },
                        "modesty": {
                            "score": 55.282500000000006,
                            "text": "The individual moderately avoids attention. He/she has an inclination to shy away from being the focus of attention if possible."
                        },
                        "sympathy": {
                            "score": 36.239999999999995,
                            "text": "The individual tends not to show compassion toward others. He/she has an inclination not to help or feel for others."
                        },
                        "trust": {
                            "score": 70.98750000000001,
                            "text": "The individual is a trusting person. He/she has an inclination to see the best in others and may be highly charitable."
                        },
                        "uncompromising": {
                            "score": 57.27,
                            "text": "The individual is unwilling to break his/her own moral standards and has an inclination to be uncompromising in his/her values and beliefs."
                        }
                    },
                    "text": "Agreeableness refers to an individual's general congeniality and ability to put other people's needs before their own."
                },
                "conscientiousness": {
                    "score": 65.69999999999999,
                    "sub": {
                        "achievement_striving": {
                            "score": 72.30000000000001,
                            "text": "The individual is a moderately driven person. He/she has an inclination to set high goals and work hard to reach them them."
                        },
                        "cautiousness": {
                            "score": 56.79,
                            "text": "The individual is a moderately cautious thinker. He/she has an inclination to pause to think and consider before making decisions and/or taking action. "
                        },
                        "dutifulness": {
                            "score": 73.6575,
                            "text": "The individual is moderately dutiful. He/she has an inclination to approach his/her obligations responsibly. These individuals are likely to fulfill their obligations and keep their promises. "
                        },
                        "orderliness": {
                            "score": 52.1175,
                            "text": "The individual is moderately organized. He/she has an inclination of needing to strucutre and plan events and surroundings. "
                        },
                        "self_discipline": {
                            "score": 72.5175,
                            "text": "The individual is persistent. He/she has an inclination of working through challenges without quitting."
                        },
                        "self_efficacy": {
                            "score": 66.8325,
                            "text": "The individual is a moderately self-assured person. He/she has an inclination on putting faith in his/her abilities and skills."
                        }
                    },
                    "text": "Conscientiousness refers to an Indvidual's propensity for orderliness and a need to plan. "
                },
                "emotional_range": {
                    "score": 62.2125,
                    "sub": {
                        "calm_in_pressure": {
                            "score": 61.012499999999996,
                            "text": "The individual is moderately prone to stress. He/she has an inclination to get exited and anxious in stressful situations."
                        },
                        "composure": {
                            "score": 53.542500000000004,
                            "text": "The individual is a moderately bad-tempered person. He/she has an inclination of resorting to anger in irritable/unfavorable situations."
                        },
                        "contentment": {
                            "score": 70.92,
                            "text": "The individual is a moderately negative person. He/she has an inclination of thinking about the negative aspects of one's life."
                        },
                        "self_assuredness": {
                            "score": 69.19500000000001,
                            "text": "The individual is moderately anxious. He/she has an inclination of thinking about things in a negative/unfavorable manner."
                        },
                        "self_confidence": {
                            "score": 74.205,
                            "text": "The individual is a moderately self-conscious person. He/she has an inclination of paying attention to others opinions of one self."
                        },
                        "self_control": {
                            "score": 44.3925,
                            "text": "The individual is a moderately self-controlled. He/she has an inclination of being resistive to temptation."
                        }
                    },
                    "text": "Emotional Stability refers to how an an individual tends to respond to his/her environment or experience a negative state of mind."
                },
                "extroversion": {
                    "score": 70.86,
                    "sub": {
                        "activity_level": {
                            "score": 62.3175,
                            "text": "The individual moderately prefers more tasks at a faster pace. He/she has an inclination to focus on more than one project or task at at time. "
                        },
                        "assertiveness": {
                            "score": 68.03999999999999,
                            "text": "The individual is a moderately assertive person. He/she has an inclination of speaking up in group environments and wil take charge of situations/events. "
                        },
                        "cheerfulness": {
                            "score": 81.36,
                            "text": "The individual displayes feelings of positivity, joy, enthusiasm, and optimism outwardly. "
                        },
                        "excitement_seeking": {
                            "score": 44.4825,
                            "text": "The individual has a moderate preference for calmness. He/she has an inclination to actively avoid excitement and search for calmness."
                        },
                        "gregariousness": {
                            "score": 85.28999999999999,
                            "text": "The individual is highly sociable. He/she has a strong preference/tendency to enjoy large social gatherings regularly. "
                        },
                        "outgoing": {
                            "score": 83.67,
                            "text": "The individual is a highly outgoing person. He/she has a high tendency of being friendly toward new people and has a preference for being around others. "
                        }
                    },
                    "text": "Extroversion refers to an individual's tendency to seek stimulation via the company of others. Extroversion/Introversion does not imply interest in others; it refers to the energy one feels as a result of interactions with others."
                },
                "openness": {
                    "score": 29.580000000000002,
                    "sub": {
                        "adventurousness": {
                            "score": 22.81,
                            "text": "The individual has a very low level of creativity and sense of adventure. He/she has a high tendency to be obedient and rooted in facts, traditions, and conventional thinking. "
                        },
                        "artistic_interests": {
                            "score": 23.85,
                            "text": "The individual has a very low level of enthusiasm about creativity or seeking beautiful experiences. He/she has a very low-to-no need or preference to seek experiences that are aesthetically pleasing. "
                        },
                        "authority_challenging": {
                            "score": 28.3725,
                            "text": "The individual is a moderate rule follower. He/she has an inclination toward obedience, following authority, and respecting rules and traditions. "
                        },
                        "emotionality": {
                            "score": 76.3625,
                            "text": "The individual is highly emotionally aware and has a tendency of seeing emotions as being central to his/her life. This person is highly comfortable expressing feelings and will dedicate time specifically to experiencing those feelings. "
                        },
                        "imagination": {
                            "score": 22.02,
                            "text": "The individual has a high tendency of focusing on hard facts rather than imagination. "
                        },
                        "intellect": {
                            "score": 25.05,
                            "text": "The individual is highly practical. He/she has a high tendency of accepting facts with limited-to-no appreciation for theory. "
                        }
                    },
                    "text": "Openness refers to an individual's general willingness to engage in new experiences and learn new things. Individuals that score high in Openness are more imaginative and creative. Those low in Openness tend to be more analytical and resistant to change and prefer tradition."
                }
            },
            "psychological disorders": {
                "antisocial": {
                    "score": 31.444654577099715,
                    "text": "Possibility of existing level of Antisocial Personality Disorder is very low or none."
                },
                "avoidant": {
                    "score": 22.159317375302493,
                    "text": "Possibility of existing level of Avoidant Personality Disorder is very low or none."
                },
                "borderline": {
                    "score": 26.570154405157474,
                    "text": "Possibility of existing level of Borderline Personality Disorder is very low or none."
                },
                "dependent": {
                    "score": 22.028962902729,
                    "text": "Possibility of existing level of Dependent Personality Disorder is very low or none."
                },
                "histrionic": {
                    "score": 82.69847774552642,
                    "text": "A considerable possibility of individual being self-centered and with less or no concern for others, rash decision making, constantly seeking approval and tendency to act with exaggerated actions and emotions. A closer look at the individual is recommended."
                },
                "narcissistic": {
                    "score": 53.37807858461445,
                    "text": "A moderate possibility of believing that he or she is special and unique while expecting admiration from others."
                },
                "obsessive_compulsive": {
                    "score": 73.8442432497707,
                    "text": "A moderate possibility of the individual showing compulsive and obsessive behaviours in some areas of his/her life."
                },
                "paranoid": {
                    "score": 27.31009597713204,
                    "text": "Possibility of existing level of Paranoid Personality Disorder is very low or none."
                },
                "schizotypal": {
                    "score": 25.576968714324856,
                    "text": "Possibility of existing level of Schizotypal Personality Disorder is very low or none."
                }
            },
            "risk": {
                "Aggression/Violence": {
                    "comments": [

                    ],
                    "score": 10,
                    "text": "Individuals low (1-3) in this range demonstrates minimal risk in aggression/violence. The individual demonstrates the ability to manage emotions (e.g. irritability, frustration, annoyances) and may utilize levels of anger as a motivating source for success."
                },
                "Delinquent Behavior": {
                    "comments": [

                    ],
                    "score": 20,
                    "text": "Individuals low (1-3) in this range demonstrate low expression of delinquent behavior. Individuals in this range adhere to societal, cultural norms. Generally, following standard procedures and expectations from authority figures. "
                },
                "Drug/Alcohol": {
                    "comments": [

                    ],
                    "score": 20,
                    "text": "Individuals low (1-3) in this range demonstrate minimal risky behavior in drug/alcohol use. Individuals either demonstrate normative minimal engagement in acceptable use that falls within normative range or does not indicate substance use per analysis. Should be noted individuals may be partaking in higher risk behavior but minimizing their full expression via social media/ public expression"
                },
                "Emotional Distress": {
                    "comments": [

                    ],
                    "score": 10,
                    "text": "Individuals low (1-3) in this range demonstrate minimal levels of emotional distress. Individuals can manage situational stressors. They are likely able to cope with a high stress, manage emotions effectively, and function in high adaptable scenarios. Individuals will most likely have positive social support, effective communication tools, and high awareness in one\u2019s self."
                },
                "Overall": {
                    "comments": [

                    ],
                    "score": 18.0,
                    "text": "Risk score invalid to generate analysis text"
                },
                "Sexual Behavior": {
                    "comments": [

                    ],
                    "score": 30,
                    "text": "Individuals low (1-3) in this range demonstrate minimal sexual expression, minimal indication of sexual behavioral risk. Should be noted individuals may be partaking in risky behavior but minimizing their expression via social media/public expression."
                }
            }
        },
        "instagram": "brentcastro",
        "is_valid_format": true,
        "linkedin": "",
        "links": [

        ],
        "message": {
            "button_label": "",
            "content": "",
            "severity": "info",
            "show": false,
            "title": ""
        },
        "possible_duplicate_info": {
            "duplicate_candidate": {

            },
            "duplicate_candidate_id": "",
            "is_possible_duplicate": false
        },
        "race": "White",
        "relationship_status": "",
        "report_settings": {
            "_id": "065becc1-631c-460d-bbea-457a67452ace",
            "action_plan": {
                "allowed": false
            },
            "executive_summary": {
                "allowed": false
            },
            "full_report": {
                "allowed": false
            }
        },
        "reports": {
            "action_plan_report": false,
            "executive_summary_report": false,
            "full_report": false
        },
        "reviewed_on": "11/21/2020, 19:43:21",
        "risk": "low",
        "school": "Dallas Baptist",
        "stats": {
            "instagram": {
                "negative_percentage": 15.56,
                "neutral_percentage": 20.0,
                "positive_percentage": 64.44,
                "total": 140,
                "total_negative": 7,
                "total_neutral": 9,
                "total_positive": 29
            },
            "negative_percentage": 17.84,
            "neutral_percentage": 23.78,
            "positive_percentage": 58.38,
            "total": 185,
            "total_negative": 33,
            "total_neutral": 44,
            "total_positive": 108,
            "tweets": {
                "negative_percentage": 18.57,
                "neutral_percentage": 25.0,
                "positive_percentage": 56.43,
                "total": 140,
                "total_negative": 26,
                "total_neutral": 35,
                "total_positive": 79
            },
            "videos": {
                "total": 0
            }
        },
        "status": "Consent Pending",
        "team": "Blu Chip Analytics",
        "twitter": "brentcastro",
        "whatsapp": "",
        "sport": "Football",
    }
}

export default eachPlayer