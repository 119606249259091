import React from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

let useStyles = makeStyles(theme => ({
  root: {
    position: "static",
    display: "flex",
    backgroundColor: theme.palette.background.default,
    minHeight: 50,
    borderBottom: `1px solid #D5D5D5`
  },
  container: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(5)
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1)
  }
}));

let TitleBar = ({ title, Icon = <div></div>, ...rest }) => {
  let classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="xl" className={classes.container}>
        {React.cloneElement(Icon, { className: classes.icon, size: 30 })}
        <Typography variant="h5" color="textSecondary">
          {title}
        </Typography>
      </Container>
    </div>
  );
};

TitleBar.propTypes = {
  title: PropTypes.string.isRequired,
  Icon: PropTypes.node.isRequired
};

export default TitleBar;
