import React from "react";
import Action from "./Action";
import Summery from "./Summery";
import withPaymentLock from "../withPaymentLock";
import { accountTypes } from "../../constants";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
}));

let ActionPlan = (props) => {
  let classes = useStyles();

  let categories = {
    connectionIsKey: "Connection is Key",
    establishTheEnvironment: "Establish the Environment",
    mentalMindset: "Mental Mindset",
    significantBackgroundInformation: "Significant Background Information",
    symptomsAndInformationCaptured: "Symptoms and Information Captured",
  };

  let { playerId, readOnly = false, accountType } = props;
  let hasEditPriviledges =
    accountType === accountTypes.bcaPsychologist ||
    accountType === accountTypes.superAdmin;
  let isEditable = !readOnly && hasEditPriviledges;

  return (
    <div style={{ width: "100%" }}>
      <div className={classes.card}>
        <Action
          isEditable={isEditable}
          playerId={playerId}
          categoryId={categories.connectionIsKey}
        />
      </div>

      <div className={classes.card}>
        <Action
          isEditable={isEditable}
          playerId={playerId}
          categoryId={categories.establishTheEnvironment}
        />
      </div>

      <div className={classes.card}>
        <Action
          isEditable={isEditable}
          playerId={playerId}
          categoryId={categories.mentalMindset}
        />
      </div>

      <div className={classes.card}>
        <Summery
          isEditable={isEditable}
          playerId={playerId}
          categoryId={categories.significantBackgroundInformation}
        />
      </div>

      <div className={classes.card}>
        <Summery
          isEditable={isEditable}
          playerId={playerId}
          categoryId={categories.symptomsAndInformationCaptured}
        />
      </div>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    accountType: state.account.accountType,
  };
};
export default connect(mapStateToProps)(withPaymentLock(ActionPlan));
