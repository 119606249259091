import React, { useContext } from "react";
import List from "@material-ui/core/List";
import LinkItem from "./LinkItem";
import { ActionContext } from "../../../../contexts";
import { connect } from "react-redux";

const LinkList = (props) => {
  const { links, playerId } = props;

  const { playerActions } = useContext(ActionContext);

  const handleLinkClick = (url) => {
    window.open(url, "_blank");
  };

  const handleLinkDelete = (link_id) => {
    const data = {
      candidate_id: playerId,
      link_id,
    };
    playerActions.deleteCandidateLink(data);
  };

  return (
    <List>
      {links.map((link, index) => {
        return (
          <LinkItem
            key={index}
            link={link}
            onClick={handleLinkClick}
            onDelete={handleLinkDelete}
            playerId={playerId}
          />
        );
      })}
    </List>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { playerId } = ownProps;

  return {
    links: state.player.links[playerId] || [],
  };
};
export default connect(mapStateToProps)(LinkList);
