import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import Tooltip from "@material-ui/core/Tooltip";
import { connect } from "react-redux";

const RiskCommentItem = (props) => {
  let { comment, onDelete = (f) => f, editable = false } = props;

  let handleDelete = (id) => {
    return () => onDelete(id);
  };
  return (
    <ListItem>
      <ListItemText primary={comment.content} />
      {editable ? (
        <Tooltip title="Delete Comment">
          <IconButton onClick={handleDelete(comment.id)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </ListItem>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { id } = ownProps;
  return {
    comment: state.coach.risk.comments[id],
  };
};

export default connect(mapStateToProps)(RiskCommentItem);
