import createMuiTheme from "@material-ui/core/styles/createTheme";

let theme = createMuiTheme({
  //   breakpoints: {
  //     keys: ["xs", "sm", "md", "lg", "xl"],
  //     values: {
  //       xs: 0,
  //       sm: 768,
  //       md: 1282,
  //       lg: 1440,
  //       xl: 1920,
  //     },
  //   },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  palette: {
    type: "light",
    // primary: {
    //   main: "#1f4287",
    // },
    primary: {
      light: "#0dd3ff",
      main: "#0971ce",
      dark: "#1c79c0",
    },
    secondary: {
      light: "#ffff52",
      main: "#fdb525",
      dark: "#c7a100",
    },
    background: {
      default: "#f9f9f9",
      paper: "#ffffff",
    },
  },
});

export default theme;
