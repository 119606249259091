import React from "react";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { capitalize, formatDate } from "../../../../utils";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
// import eachCoach from "modules/dashboard/components/EachCoach"

let useStyles = makeStyles((theme) => ({
  label: {
    textTransform: "uppercase",
    fontSize: 11,
    fontWeight: 700,
    width: 176,
    marginRight: theme.spacing(2),
  },
  value: {
    flex: 1,
    textTransform: "capitalize",
  },
}));

let Item = ({ label, value }) => {
  let classes = useStyles();

  if (value === "" || value === null || value === undefined) {
    return null;
  } else if (value.toLowerCase() === "n/a") {
    return null;
  }

  return (
    <ListItem>
      <Typography
        variant="button"
        color="textSecondary"
        className={classes.label}
      >
        {label}
      </Typography>
      <Typography variant="body1" className={classes.value}>
        {capitalize(value)}
      </Typography>
    </ListItem>
  );
};

let BasicInfo = (props) => {
  let { coachId, basicInfo } = props;

  // const selectedCoach = eachCoach && eachCoach[coachId]  
  // const basicInfo = {
  //   id: selectedCoach && selectedCoach._id,
  //   birthday: selectedCoach && selectedCoach.dob,
  //   name: selectedCoach && selectedCoach.full_name,
  //   risk: selectedCoach && selectedCoach.risk,
  //   school: selectedCoach && selectedCoach.school,
  //   status: selectedCoach && selectedCoach.status,
  //   team: selectedCoach && selectedCoach.team,
  //   image: selectedCoach && selectedCoach.image_url,
  //   consent: selectedCoach && selectedCoach.consent,
  //   isPaid: selectedCoach && selectedCoach.is_paid,
  // };

  return (
    <List>
      <Item label="name" value={basicInfo.name} />
      <Item label="birthday" value={formatDate(basicInfo.birthday)} />
      <Item label="school" value={basicInfo.school} />
      <Item label="hometown" value={basicInfo.hometown} />
      <Item label="relationship" value={basicInfo.relationship} />
      <Item label="race" value={basicInfo.race} />
    </List>
  );
};

let mapStateToProps = (state, ownProps) => {
  return {
    basicInfo: state.coach.basic[ownProps.coachId],
  };
};
export default connect(mapStateToProps)(BasicInfo);
