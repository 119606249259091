import React, { useState } from "react";
import ExpansionArticle from "../ExpansionArticle";
import ChartAndDetails from "../ChartAndDetails";
import withPaymentLock from "../withPaymentLock";
import { chartColors } from "modules/players/constants";
import { connect } from "react-redux";
// import eachCoach from "modules/dashboard/components/EachCoach"
import { Box, Tabs, Tab, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import '../../../../index.css';

let useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Prometo',
    fontSize: '14px',
    color: '#fcb643',
  },
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

let options = {
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "40%",
        background: "#293450",
      },
      track: {
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 4,
          opacity: 0.15,
        },
      },
      dataLabels: {
        name: {
          show: false,
          offsetY: -10,
          color: "#fff",
          fontSize: "13px",
        },
        value: {
          color: "#fff",
          fontSize: "14px",
          offsetY: 6,
          show: true,
        },
      },
    },
  },
  stroke: {
    lineCap: "round",
  },
};

const DeepProfile = (props) => {
  let { coachId, deepProfile } = props;
  let classes = useStyles();
  // const selectedCoach = eachCoach && eachCoach[coachId]
  // const deepProfile = selectedCoach?.insights["psychological disorders"];

  const [selectedPage, setSelectedPage] = useState(0)

  const handleChange = (event, newValue) => {
    setSelectedPage(newValue);
  };

  let handleColors = (score) => {
    if (0 <= score && score < 25) {
      return chartColors.green;
    } else if (25 <= score && score < 50) {
      return chartColors.yellow;
    } else if (50 <= score && score < 75) {
      return chartColors.orange;
    } else if (75 <= score && score < 100) {
      return chartColors.red;
    }
  };

  return (
    <React.Fragment>
      <Box
        sx={{ flexGrow: 1, display: 'flex', margin: '0px 5rem' }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={selectedPage}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          <Tab className={selectedPage === 0 ? classes.root : ''} label="Antisocial" {...a11yProps(0)} />
          <Tab className={selectedPage === 1 ? classes.root : ''} label="Avoidant" {...a11yProps(1)} />
          <Tab className={selectedPage === 2 ? classes.root : ''} label="Borderline" {...a11yProps(2)} />
          <Tab className={selectedPage === 3 ? classes.root : ''} label="Dependent" {...a11yProps(3)} />
          <Tab className={selectedPage === 4 ? classes.root : ''} label="Histrionic" {...a11yProps(4)} />
          <Tab className={selectedPage === 5 ? classes.root : ''} label="Narcissistic" {...a11yProps(5)} />
          <Tab className={selectedPage === 6 ? classes.root : ''} label="Obsessive-Compulsive" {...a11yProps(6)} />
          <Tab className={selectedPage === 7 ? classes.root : ''} label="Paranoid" {...a11yProps(7)} />
          <Tab className={selectedPage === 8 ? classes.root : ''} label="Schizotypal" {...a11yProps(8)} />
        </Tabs>
        <TabPanel value={selectedPage} index={0} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
          <div>
            <ExpansionArticle
              title="Antisocial"
              details="An individual with a high score for this has a long-term pattern of manipulating, exploiting or violating the rights of others without any remorse. Psychopaths are considered to have a severe form of antisocial personality disorder."
            >
              <ChartAndDetails
                diagnosis={deepProfile["antisocial"]["text"]}
                details="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
                // score={(deepProfile["antisocial"]["score"]) / 100}
                score={(deepProfile["antisocial"]["score"])}
                options={options}
                handleColors={handleColors}
                tabName='DeepProfile'
              />
            </ExpansionArticle>
          </div>
        </TabPanel>
        <TabPanel value={selectedPage} index={1} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
          <div>
            <ExpansionArticle
              title="Avoidant"
              details="An individual with a high score for this has an enduring pattern of behavior related to social inhibition, feelings of inadequacy, and sensitivity to rejection that causes problems in work situations and relationships. Such an individual often suffers with feelings of nervousness and fear and has poor levels of self-esteem."
            >
              <ChartAndDetails
                diagnosis={deepProfile["avoidant"]["text"]}
                details="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
                // score={(deepProfile["avoidant"]["score"]) / 100}
                score={(deepProfile["avoidant"]["score"])}
                options={options}
                handleColors={handleColors}
                tabName='DeepProfile'
              />
            </ExpansionArticle>
          </div>
        </TabPanel>
        <TabPanel value={selectedPage} index={2} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
          <div>
            <ExpansionArticle
              title="Borderline"
              details="An Individual with a high score for this has an ongoing pattern of varying moods, self-image and behavior; resulting in impulsive actions and problems in relationships. Such an individual may experience intense episodes of anger, depression, and anxiety that may last from a few hours to days."
            >
              <ChartAndDetails
                diagnosis={deepProfile["borderline"]["text"]}
                details="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
                // score={(deepProfile["borderline"]["score"]) / 100}
                score={(deepProfile["borderline"]["score"])}
                options={options}
                handleColors={handleColors}
                tabName='DeepProfile'
              />
            </ExpansionArticle>
          </div>
        </TabPanel>
        <TabPanel value={selectedPage} index={3} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
          <div>
            <ExpansionArticle
              title="Dependent"
              details="An individual with a high score for this suffers from a neediness that is marked by an over reliance on others. His or her emotional and physical needs are dependent on the people closest. Common symptoms are the Inability to make common, everyday decisions without the reassurance of others, Avoidance of personal responsibility, An intense fear of abandonment, Difficulty being alone, Avoidance of disagreement with others out of fear of losing support or approval, Willingness to tolerate mistreatment and abuse from others, etc."
            >
              <ChartAndDetails
                diagnosis={deepProfile["dependent"]["text"]}
                details="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
                // score={(deepProfile["dependent"]["score"]) / 100}
                score={(deepProfile["dependent"]["score"])}
                options={options}
                handleColors={handleColors}
                tabName='DeepProfile'
              />
            </ExpansionArticle>
          </div>
        </TabPanel>
        <TabPanel value={selectedPage} index={4} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
          <div>
            <ExpansionArticle
              title="Histrionic"
              details="An Individual with a high score for this has a distorted mental image of themselves. Such an individual often bases his/her self-esteem on the approval of others and is in a need to be noticed thus often times tends to resort to dramatic antics get the attention."
            >
              <ChartAndDetails
                diagnosis={deepProfile["histrionic"]["text"]}
                details="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
                // score={(deepProfile["histrionic"]["score"]) / 100}
                score={(deepProfile["histrionic"]["score"])}
                options={options}
                handleColors={handleColors}
                tabName='DeepProfile'
              />
            </ExpansionArticle>
          </div>
        </TabPanel>
        <TabPanel value={selectedPage} index={5} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
          <div>
            <ExpansionArticle
              title="Narcissistic"
              details="An individual with a high score for this has a long-standing pattern of grandiosity (either in fantasy or actual behavior), often a complete lack of empathy toward others. Such an individual often believes he/she is of primary importance in life of everybody he/she interacts with."
            >
              <ChartAndDetails
                diagnosis={deepProfile["narcissistic"]["text"]}
                details="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
                // score={(deepProfile["narcissistic"]["score"]) / 100}
                score={(deepProfile["narcissistic"]["score"])}
                options={options}
                handleColors={handleColors}
                tabName='DeepProfile'
              />
            </ExpansionArticle>
          </div>
        </TabPanel>
        <TabPanel value={selectedPage} index={6} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
          <div>
            <ExpansionArticle
              title="Obsessive-Compulsive"
              details="This is known as OCD behavior which is built up based on two criteria; Obsession & Compulsion. Common types of obsessions include concerns with contamination, safety/harm, unwanted acts of aggression, unacceptable sexual or religious thoughts and the need for symmetry or exactness. Common compulsions include excessive cleaning, checking, ordering, and arranging rituals, counting, repeating routine activities and hoarding. While most compulsions are observable behaviors, some are performed as unobservable mental rituals."
            >
              <ChartAndDetails
                diagnosis={deepProfile["obsessive_compulsive"]["text"]}
                details="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
                // score={(deepProfile["obsessive_compulsive"]["score"]) / 100}
                score={(deepProfile["obsessive_compulsive"]["score"])}
                options={options}
                handleColors={handleColors}
                tabName='DeepProfile'
              />
            </ExpansionArticle>
          </div>
        </TabPanel>
        <TabPanel value={selectedPage} index={7} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
          <div>
            <ExpansionArticle
              title="Paranoid"
              details="An individual with a high score for this is suspicious of others which makes it difficult to form relationships. Such an individual often mistrusts the motives of others and believes that others want to harm them.  Also, reluctant to confide in others, bearing grudges, and finding demeaning or threatening subtext in even the most innocent of comments or events."
            >
              <ChartAndDetails
                diagnosis={deepProfile["paranoid"]["text"]}
                details="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
                // score={(deepProfile["paranoid"]["score"]) / 100}
                score={(deepProfile["paranoid"]["score"])}
                options={options}
                handleColors={handleColors}
                tabName='DeepProfile'
              />
            </ExpansionArticle>
          </div>
        </TabPanel>
        <TabPanel value={selectedPage} index={8} style={{ background: '#fff', borderRadius: '0.8rem', boxShadow: '3px 3px 10px 3px #cdcdcd', marginLeft: '1rem', width: '85%' }}>
          <div>
            <ExpansionArticle
              title="Schizotypal"
              details="An individual with a high score for this prefer to keep his/her distance from others and is uncomfortable being in relationships. Such an individual sometimes exhibits odd speech or behavior he/she has a limited or flat range of emotions."
            >
              <ChartAndDetails
                diagnosis={deepProfile["schizotypal"]["text"]}
                details="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat"
                // score={(deepProfile["schizotypal"]["score"]) / 100}
                score={(deepProfile["schizotypal"]["score"])}
                options={options}
                handleColors={handleColors}
                tabName='DeepProfile'
              />
            </ExpansionArticle>
          </div>
        </TabPanel>
      </Box>
    </React.Fragment>
  );
};

let mapStateToProps = (state, ownProps) => {
  return {
    deepProfile: state.coach.deepProfile[ownProps.coachId],
  };
};
// higher order component for checking payment
export default connect(mapStateToProps)(withPaymentLock(DeepProfile));
