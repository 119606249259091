import React, { useContext, useEffect } from 'react'
import { Card, Divider, CardHeader, Grid, Accordion, AccordionSummary, AccordionDetails, Typography } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ActionContext } from "../../../../contexts";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, fade } from "@material-ui/core/styles";
import CircularProgress from "modules/shared/CircularProgress";
import NoData from '../../../../assets/NoData.svg';

let useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.default,
        display: "flex",
        height: `calc(100vh - 50px)`,
        zIndex: theme.zIndex.appBar,
    },
    container: {
        padding: 16,
        marginRight: "auto",
        [theme.breakpoints.down("md")]: {
            maxWidth: 550,
        },
    },
    placeholder: {
        // height: 150,
        // width: 660,
        display: "block",
        width: '100%',
        textAlign: 'center',
        margin: '1rem 0rem',
        justifyContent: "center",
        alignItems: " center",
    },
    FullName: {
        fontWeight: '700',
        color: '#333333'
    },
    Team: {
        color: '#666666',
        fontSize: '12px'
    },
    Status: {
        fontSize: '11px',
        padding: '0.1rem 0.7rem',
        borderRadius: '0.9rem',
        fontWeight: 500,
    },
    PositionUI: {
        fontSize: '14px',
        padding: '0.5rem',
        marginBottom: '1rem',
        display: 'block',
        border: '1px solid #cdcdcd',
        borderRadius: '0.3rem'
    },
    avatar: {
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    secondaryAction: {
        display: "flex",
        flexDirection: "column",
    },
    badgeContent: {
        width: 18,
        height: 18,
        backgroundColor: theme.palette.background.paper,
        border: `2px solid #fff`,
    },
    card: {
        flexGrow: 0,
        padding: '14px',
        borderRadius: '9px',
        boxShadow: '0 2px 12px 0 rgba(0, 0, 0, 0.06)',
        backgroundColor: '#fff',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    search: {
        justifyContent: 'space-between',
        display: 'flex',
        position: 'relative',
        marginBottom: '14px',
        marginRight: '0px',
        marginTop: '14px',
        borderRadius: '10px',
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            alignItems: 'center',
            color: '#0971ce',
            fontWeight: 'bold',
            fontSize: '18px',
            width: 'auto',
            borderBottom: '1px solid #fdb525',
            borderRadius: 'inherit',
            paddingBottom: '1rem',
            background: 'none'
        },
    },



}));

function UnitDetails(props) {

    let classes = useStyles();

    const { result, positionLoading, sportPosition, selectedId, sportsData, loadingUnitSport, unitSportData, sportType } = props;
    let actions = useContext(ActionContext);
    let { sportsActions } = actions;

    const selectedGame = sportsData?.filter(v => v._id === selectedId) && sportsData?.filter(v => v._id === selectedId)[0]


    let history = useHistory();

    const [expandedId, setExpandedId] = React.useState(0);

    function handleChangeAccordion(id, name) {
        if (expandedId !== id) {
            setExpandedId(id)
            sportsActions.getSportPositionDetails(name, (selectedGame && selectedGame.name));
        } else {
            setExpandedId(0)
        }
    }

    const handleRedirect = () => {
        sportsActions.selectSportsPage("list")
        sportsActions.selectSportsType(null);
    }


    useEffect(() => {
        return () => {
            sportsActions.selectSportsPage("list")
            sportsActions.selectSportsType(null);
        }
    }, [])

    return (
        <React.Fragment>
            <div className={classes.container}>
                <div className={classes.search}>
                    <div>
                        <label><b>{sportType}</b>&nbsp; {"Units"} </label>

                    </div>
                    <div>
                        <span
                            style={{ border: '1px solid', padding: '0.3rem 0.5rem', borderRadius: '9px', margin: '0rem 1rem', color: '#0971ce', fontWeight: 700, cursor: 'pointer', }}
                            color="default"
                            onClick={handleRedirect}
                            disableElevation
                        >
                            List Page
                        </span>
                        {/* <InputBase
                            placeholder="Search Team Name"
                            value={searchText}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={ev => handleSearchText(ev.target.value)}
                        /> */}
                    </div>
                </div>
                <Grid >
                    <div style={{ padding: 8, height: "100%" }}>
                        {
                            unitSportData.length === 0 ?
                                <div className={classes.placeholder}>
                                    <img src={NoData} style={{ width: '11rem' }} />
                                    <div style={{ color: '#0971ce' }}> No sport unit found... </div>
                                </div>
                                :
                                <Grid spacing={2} justify="center">
                                    {loadingUnitSport ?
                                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                            <CircularProgress size={72} thickness={3} color="primary" />
                                        </div>
                                        :
                                        unitSportData && unitSportData.map((result, index) => (
                                            <Grid item xs={12} key={index} justify="center">
                                                <Accordion
                                                    style={{ margin: '0px 0px 10px 0px', width: '100%' }}
                                                    expanded={Number(expandedId) === Number(result.id)}

                                                    key={`${result.id}`}>
                                                    <AccordionSummary
                                                        style={{
                                                            background: '#aae0dc'
                                                        }}
                                                        expandIcon={
                                                            <ExpandMoreIcon className={classes.ExpandIcon}
                                                                onClick={() => handleChangeAccordion(result.id, result.unit)}
                                                            />}
                                                        aria-controls="panel1c-content"
                                                        id="panel1c-header"
                                                        className={classes.hoverColor}
                                                    >
                                                        <div>
                                                            {result.unit}
                                                        </div>
                                                    </AccordionSummary>
                                                    <Divider />
                                                    <AccordionDetails style={{ display: 'block' }} >
                                                        {
                                                            positionLoading ?
                                                                <CircularProgress size={72} thickness={3} color="primary" />
                                                                :
                                                                sportPosition && sportPosition.length === 0 ?
                                                                    <div style={{ padding: 8, height: "100%", width: '100%' }}>
                                                                        <div
                                                                            style={{
                                                                                width: "100%",
                                                                                display: "flex",
                                                                                flexDirection: "column",
                                                                                alignItems: "center",
                                                                                justifyItems: "center",
                                                                                marginTop: 200,
                                                                            }}
                                                                        >
                                                                            <Typography variant="body1">No position found...</Typography>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    sportPosition && sportPosition.map((list, index) => (
                                                                        <Grid item xs={12} key={index} container justify="center">
                                                                            <span variant="outlined" style={{ maxWidth: 650, width: "100%" }}>
                                                                                <span className={classes.PositionUI}>
                                                                                    {/* <CardHeader
                                                                                        title={list.position}
                                                                                    /> */}
                                                                                    {list.position}
                                                                                </span>
                                                                            </span>
                                                                            {/* <Card className={classes.card}>
                                                                                <div style={{ display: 'flex' }}>
                                                                                    <div style={{ width: '100%', marginLeft: '1rem' }}>
                                                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                                            <span className={classes.FullName}>{list.position}</span>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </Card> */}
                                                                        </Grid>
                                                                    ))
                                                        }
                                                    </AccordionDetails>
                                                </Accordion>
                                            </Grid>
                                        ))}
                                </Grid>
                        }
                    </div>

                </Grid>
            </div>
        </React.Fragment>

    )
}


let mapStateToProps = (state) => {
    return {
        sportPosition: state.sports.sportPosition,
        positionLoading: state.ui.sports.loadingSportPosition,
        unitSportData: state.sports.unitSport,
        loadingUnitSport: state.ui.sports.loadingUnitSport,
        sportsData: state.sports.sportsListData,
        selectedId: state.ui.sports.selectedSportId,
        sportType: state.ui.sports.selectedSportsTypeId,
    };
};

export default connect(mapStateToProps)(UnitDetails);