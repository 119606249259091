import React from "react";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/ClearOutlined";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import Editor from "./Editor";
import { Button } from "../Button";
import { makeStyles } from "@material-ui/core/styles";

let useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2, 3),
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 3, 3, 3),
  },
  editor: {
    border: "1px solid #f1f1f1",
    maxHeight: 400,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: 7,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.background.paper,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  actionBar: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "row-reverse",
  },
}));

const EditorDialog = (props) => {
  let classes = useStyles();
  let {
    onClose,
    readOnly,
    loading = false,
    onSave,
    onEditorStateChange,
    editorState,
    title,
    ...rest
  } = props;

  let handleCloseDialog = () => onClose();

  let handleSave = () => onSave();

  return (
    <div>
      <div className={classes.header}>
        <div>
          <Typography variant="h6">{title}</Typography>
        </div>
        <IconButton onClick={handleCloseDialog}>
          <ClearIcon />
        </IconButton>
      </div>
      <DialogContent className={classes.content}>
        <Editor
          classes={{ editor: classes.editor }}
          onEditorStateChange={onEditorStateChange}
          editorState={editorState}
          readOnly={loading || readOnly}
          {...rest}
        />
        <div className={classes.actionBar}>
          <Button
            variant="contained"
            color="primary"
            disableElevation
            onClick={handleSave}
            loading={loading}
          >
            Save
          </Button>
        </div>
      </DialogContent>
    </div>
  );
};

export default EditorDialog;
