import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { RadialGauge } from '@progress/kendo-react-gauges';
const RadialGaugeComponent = ({score}) => {
  const [value, setValue] = React.useState(0);
  React.useEffect(() => {
    setInterval(() => {
      setValue(Math.ceil(Math.random() * 100));
    }, 1000);
  }, []);
  const radialOptions = {
    pointer: [
        {
          value: score,
          color: "black", 
          length : 5
        } 
      ],
    shape: 'arrow',
    style:{width: 80, height: 80},
    scale: {
      height: 10,
      minorUnit: 1,
      majorUnit: 30,
      max: 100,
      startAngle: -80,
      endAngle: 260,
      rangeSize: 10,
      showLabels: false,
      majorTicks: {
        visible: false,
        color: '#ffc700',
      },
      minorTicks: {
        visible: false,
        color: '#ffc700',
      },
      labels: {
        visible: false,
      },
      ranges: [
        {
          from: 0,
          to: 10,
          color: '#ffffff'
        },{
        from: 10,
        to: 20,
        color: '#ffc700'
      }, {
        from: 20,
        to: 49,
        color: '#326fc7'
      }, {
        from: 49,
        to: 79,
        color: '#181957'
      }, {
        from: 79,
        to: 89,
        color: '#ffc700'
      }, {
        from: 89,
        to: 100,
        color: '#ffffff'
      }]
    }
  };
  return <RadialGauge {...radialOptions} />;
};
export default RadialGaugeComponent;

// ReactDOM.render(<RadialGaugeComponent />, document.querySelector('my-app'));