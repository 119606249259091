import { makeStyles } from "@material-ui/core/styles";
import { ActionContext } from "../../../contexts";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Popover from '@material-ui/core/Popover';
import React, { useEffect, useState, useContext } from 'react';


let useStyles = makeStyles((theme) => ({
    // root: {
    //     // backgroundColor: theme.palette.background.default,
    //     background: '#fff',
    //     display: "flex",
    //     height: `calc(100vh - 50px)`,
    //     zIndex: theme.zIndex.appBar,
    // },
    root: {
        //minWidth: 275,
        height: '5rem',
        backgroundImage: 'url("/assets/images/illustrations/ChatBackground.jpeg")',
        //border: '1px solid #2dbcb2',
        boxShadow: '3px 3px 10px 3px #2dbcb2',
        '&:hover': {
            boxShadow: '3px 3px 10px 3px #F4AB3D',
            cursor: 'pointer'
        },
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        },
    },
    container: {
        padding: 16,
        // paddingTop: 48,
        // marginLeft: "auto",
        // marginRight: "auto",
        // flexWrap: "wrap",
        width: '100%',
        [theme.breakpoints.down("md")]: {
            maxWidth: 550,
        },
    },
    GridFlowALign: {
        // display: 'flex',
        // width: '-webkit-fill-available',
        // overflow: 'auto',
        // height: 'fit-content',
        padding: '1rem 2rem',
        background: '#fff'
    },
    OwnerAlignData: {
        // display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '7px',
        width: '100%',
        padding: '0px 6px'
    },
    btnPrimaryIcon: {
        color: theme.palette.primary.main,
        //background: theme.palette.primary.main,
        //border: '1px solid',
        //height: '30px',
        //padding: '10px',
        marginRight: '10px',
        //borderRadius: '20px',
        '&:hover': {
            //background: theme.palette.primary.main,
            color: theme.palette.primary.main,
        }
    },
    btnSecondaryIcon: {
        color: theme.palette.secondary.main,
        //background: theme.palette.secondary.main,
        // border: '1px solid',
        // height: '30px',
        // padding: '10px',
        // marginRight: '10px',
        // borderRadius: '20px',
        '&:hover': {
            //background: theme.palette.secondary.main,
            color: theme.palette.secondary.main
        }
    },
    HeightAlignment: {
        margin: '10px 5px'
    },
    flexFlowAlign: {
        display: 'flex'
    },
    flexAlign: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    NameDataAlign: {
        fontSize: '17px',
        fontWeight: 600,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

const SportsCard = (props) => {
    let classes = useStyles();
    let { sports,closeDialog } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    let { dashboardActions } =
        useContext(ActionContext);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);

    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClose = () => {
        setAnchorEl(null);
    };
    const openSport = (name) => {
        dashboardActions.selectDashboardFilter("searchSport", name);
        dashboardActions.selectCard("Team Dashboard");
        // handleClose();
        handleCloseDialog();
    };
    const handleCloseDialog = () => {
       closeDialog();
    };

    return (
        <>
            <div >
                {/* <div aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
                    {props.icon}
                </div>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                > */}
                <React.Fragment>
                        <div className={classes.HeightAlignment}>
                            <Grid container spacing={2}>
                                {
                                    sports && sports.map((v) => (
                                        <Grid item xs={12} sm={3}>
                                            <Card className={classes.root}>
                                                <CardContent >
                                                    <div className={classes.flexFlowAlign}>

                                                        <div>
                                                            <span className={classes.flexAlign}>
                                                                <div className={classes.NameDataAlign} onClick={() => openSport(v.name)}>
                                                                    {v.name}
                                                                </div>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </Grid>


                                    ))
                                }
                            </Grid>

                        </div>


                </React.Fragment>
                {/* </Popover> */}
            </div>
        </>
    )


};
let mapStateToProps = (state) => {
    return {

        selectedId: state.ui.dashboard.selectedCardId,
        updateFilter: state.dashboard.updateDashboardFilter,

    };
};
export default connect(mapStateToProps)(SportsCard);
