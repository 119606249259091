import apiHandler, { getAuthHeader } from "./apiHandler";


export const getAllSports = async () => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get("/sports", { headers });
    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    const stats = response.data.info.sports;


    return stats;
  } catch (err) {
    throw new Error(err);
  }
};

export const getUnitDetails = async (sportId) => {
  try {
    const headers = getAuthHeader();

    const response = await apiHandler.get(`/findsportunits/${sportId}`, {
      headers,
    });

    if (response && response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    const sportData = response.data.info.units;

    return sportData;
  } catch (err) {
    throw new Error(err);
  }
};

export const getPositionDetails = async (unitId, sportId) => {
  try {
    const headers = getAuthHeader();

    const response = await apiHandler.get(`/sports_positions/${sportId}/${unitId}`, {
      headers,
    });

    if (response && response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    const sportData = response.data.info.positions;

    return sportData;
  } catch (err) {
    throw new Error(err);
  }
};

export const getTeamUsers = async (teamId) => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get(`/load-team-users/${teamId}`, { headers });
    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }
    const stats = response.data.info.accounts
    return stats;
  } catch (err) {
    throw new Error(err);
  }
};


const sports = {
  getAllSports,
  getUnitDetails,
  getPositionDetails,
  getTeamUsers
};

export default sports;