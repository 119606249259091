import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import { makeStyles } from "@material-ui/core/styles";
import { useConfirm } from "material-ui-confirm";
import React, { useContext } from "react";
import { connect, useSelector } from "react-redux";
import PdfIcon from "../../../assets/pdf.png";
import { ActionContext } from "../../../contexts";
import { Button } from "../../shared/Button";
// import allConsents from "modules/dashboard/components/ConsentDummyData"

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #dfdfdf",
    borderRadius: 8,
    maxWidth: 300,
    width: "100%",
    transition: "transform .2s",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  avatar: {
    width: 32,
    height: 32,
  },
}));

let ConsentFormCard = (props) => {
  let classes = useStyles();
  let confirm = useConfirm();
  let { consentFormId, consentForm, linkingConsentForm } = props;
  // let { consentFormId, linkingConsentForm } = props;
  let { consentActions } = useContext(ActionContext);

  // const consentForm = allConsents["linked_consent_forms"][consentFormId]

  let player = useSelector(
    (state) => state.player.players.byId[consentForm.playerId]
  );


  let handleUnlinkConsentForm = () => {
    confirm({
      description: "Do you really want to unlink the consent form?",
      confirmationText: "Yes",
    })
      .then(() => {
        let data = {
          candidate_id: consentForm.playerId,
        };
        consentActions.unlinkConsentForm(data, consentFormId);
      })
      .catch(() => {
        // do nothing
      });
  };

  return (
    <Card className={classes.root} variant="outlined">
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar} src={null} />
        }
        title={consentForm.name}
        titleTypographyProps={{ variant: "subtitle2" }}
        // subheader={player.name}
        subheaderTypographyProps={{ variant: "caption" }}
      />
      <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
        <img alt="pdf-icon" src={PdfIcon} width={50} />
      </div>
      <CardActions>
        {/* <Button
          size="small"
          color="primary"
          variant="text"
          onClick={handleCardClick}
        >
          View
        </Button> */}
        <Button
          size="small"
          color="primary"
          variant="text"
          onClick={handleUnlinkConsentForm}
          loading={linkingConsentForm}
          // disabled={true}
        >
          Unlink
        </Button>
      </CardActions>
    </Card>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { consentFormId } = ownProps;
  return {
    consentForm: state.consent.linkedConsentForms.byId[consentFormId],
    linkingConsentForm: state.ui.consent.linkingConsentForm[consentFormId],
  };
};
export default connect(mapStateToProps)(ConsentFormCard);
