import React from "react";
import { Article } from "../../../shared/Article";
import BasicInfo from "./BasicInfo";
import ContactInfo from "./ContactInfo";

const PersonalInfo = ({ playerId }) => {
  return (
    <React.Fragment>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Article  title="Basic Info">
          <BasicInfo playerId={playerId} />
        </Article>
      </div>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <Article title="Contact Info">
          <ContactInfo playerId={playerId} />
        </Article>
      </div>
    </React.Fragment>
  );
};

export default PersonalInfo;
