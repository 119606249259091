
export const GET_ALL_SPORTS_REQUEST = "GET_ALL_SPORTS_REQUEST";
export const GET_ALL_SPORTS_SUCCESS = "GET_ALL_SPORTS_SUCCESS";
export const GET_ALL_SPORTS_FAILED = "GET_ALL_SPORTS_FAILED";

export const SEARCH_SPORT = "SEARCH_SPORT";
export const RESET_SEARCH = "RESET_SEARCH";

export const SELECT_SPORT = "SELECT_SPORT";
export const DESELECT_SPORT = "DESELECT_SPORT";

export const GET_SPORT_UNIT_BY_SPORT_ID_REQUEST = "GET_SPORT_UNIT_BY_SPORT_ID_REQUEST";
export const GET_SPORT_UNIT_BY_SPORT_ID_SUCCESS = "GET_SPORT_UNIT_BY_SPORT_ID_SUCCESS";
export const GET_SPORT_UNIT_BY_SPORT_ID_FAILED = "GET_SPORT_UNIT_BY_SPORT_ID_FAILED";
export const RESET_SPORT_UNIT_BY_SPORT_ID = "RESET_SPORT_UNIT_BY_SPORT_ID"

export const GET_SPORT_POSITION_BY_UNIT_ID_REQUEST = "GET_SPORT_POSITION_BY_UNIT_ID_REQUEST";
export const GET_SPORT_POSITION_BY_UNIT_ID_SUCCESS = "GET_SPORT_POSITION_BY_UNIT_ID_SUCCESS";
export const GET_SPORT_POSITION_BY_UNIT_ID_FAILED = "GET_SPORT_POSITION_BY_UNIT_ID_FAILED";
export const RESET_SPORT_POSITION_BY_UNIT_ID = "RESET_SPORT_POSITION_BY_UNIT_ID"

export const SELECTED_PAGE_TYPE = "SELECTED_PAGE_TYPE"
export const SELECTED_SPORTS_TYPE = "SELECTED_SPORTS_TYPE"

export const GET_TEAM_USERS_REQUEST = "GET_TEAM_USERS_REQUEST";
export const GET_TEAM_USERS_SUCCESS = "GET_TEAM_USERS_SUCCESS";
export const GET_TEAM_USERS_FAILED = "GET_TEAM_USERS_FAILED";