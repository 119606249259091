import createContext from "./createContext";

export const [ActionContext, ActionContextProvider] = createContext();

const actionContext = {
  ActionContext,
  ActionContextProvider,
};

export default actionContext;
