import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import { round } from "utils";
import { Tabs } from "../../../shared/Tabs";
import ChartAndDetails from "./ChartAndDetails";
import ExpansionArticle from "./ExpansionArticle";
import { List, ListItem, ListItemText, Slide } from "@material-ui/core"
import CircleIcon from '@material-ui/icons/Brightness1';
import PlayerDetails from "../../../athlet-management/components/PlayerDetails";
import { ActionContext } from "../../../../../src/contexts";



const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="left"
      ref={ref}
      timeout={{ enter: 1000, exit: 1000 }}
      {...props}
    />
  );
});


const chartColors = {
  red: "#f44336",
  orange: "#ff9800",
  yellow: "#fdd835",
  green: "#4caf50",
};

let useStyles = makeStyles((theme) => ({
  risk: {
    display: "flex",
    justifyContent: "center",
  },
}));

let roundTo1PrecisionPoint = (value) => {
  return round(value, 0);
};

let radialBarOptions = {
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "40%",
        background: "#293450",
      },
      track: {
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 4,
          opacity: 0.15,
        },
      },
      dataLabels: {
        name: {
          show: false,
          offsetY: -10,
          color: "#fff",
          fontSize: "13px",
        },
        value: {
          color: "#fff",
          fontSize: "14px",
          offsetY: 6,
          show: true,
        },
      },
    },
  },
  stroke: {
    lineCap: "butt",
  },
};

let PlayerAttributes = (props) => {
  let classes = useStyles();
  let [selectedIndex, setSelectedIndex] = useState(0);

  let actions = useContext(ActionContext);

  const { teamPlayers, loadingPlayerFilters } = props

  let { playerActions, dialogActions, coachActions } = actions;

  let customTraits = {
    character: {
      description: "The strength of one’s character is based in the individual's social, emotional and behavioral functioning and exhibited by their work ethic, integrity, and the quality of their relationships.",
      label: "Character",
      score: 30,
      text: "Individuals who fall within 0-30% demonstrate a minimal level of character. Individuals may lack drive and are lazy in their efforts to meet performance outcomes. Individuals may have low integrity, question or challenge authority, break team rules/norms. Individuals low in character may be arrogant and lack humility.",
      sub: {
        hard_working: {
          left_label: "Hard-Working",
          right_label: "Lazy",
          score: 30,
          text: ""
        },
        high_integrity: {
          left_label: "High Integrity",
          right_label: "Low Integrity",
          score: 45,
          text: ""
        },
        humble: {
          left_label: "Humility",
          right_label: "Arrogant",
          score: 0,
          text: ""
        }
      }
    },
    coachability: {
      description: "Athlete’s high in coachability actively build trust with their coach, are adaptable, and display an openness in their ability to learn and execute the vital components needed to succeed on the field.  In short, coachability refers to an athlete’s ability to learn, adapt and execute.",
      label: "Coachability",
      score: 24.375,
      text: "Individuals who fall within 0-30% demonstrate a minimal level of coachability. The individual may tend to be unreliable, closed minded, or have difficulty managing feedback and criticism. They may display a negative mindset, defensiveness, and not adhere to cultural standards to assist in growth in their performance.",
    },
    composure: {
      description: "Composed athletes remain goal-focused and in control of their decision-making processes when under pressure. Composure is an important characteristic for re-setting after mistakes to maintain a high-level of performance.",
      label: "Composure",
      score: 40.3125,
      text: "Individuals who fall within 30-70% demonstrate a moderate level of composure. Individuals may have the capacity to remain calm and manage stressful situations to a degree. They have a moderate drive and purpose to strive for. In high pressure situations they may react and have difficulties managing their emotions on a consistent basis.",
    },
    leadership: {
      description: "Successful leaders keep the momentum of the team moving in the direction of the mission and continue to hold all team members accountable for their roles even when the pressure mounts. Great leaders have an ability to empower and motivate their followers by tapping into the individual strengths of each team member.",
      label: "Leadership",
      score: 38,
      text: "Individuals who fall within 30-70% demonstrate a moderate level of leadership capacity. Individuals may work hard toward goals and can inspire small groups or a few individuals. Individuals may lack the ability to be vocal and speak to larger members to inspire a large group to meet cultural goals. Individuals may have the capacity to make small changes and influence members around them. Individuals may have moderate level of confidence, but poor performances or influence may negatively impact self-worth and ability to perform at optimal level.",

    }
  }

  let customTraitLabels = ['character', 'coachability', 'composure', 'leadership']//Object.keys(customTraits);
  let customTrait = Object.values(customTraits)[selectedIndex];

  let handleTabChange = (e, index) => {
    setSelectedIndex(index);
  };

  let handleColors = (score) => {
    if (0 <= score && score < 30) {
      return chartColors.red;
    } else if (30 <= score && score < 70) {
      return chartColors.orange;
    } else if (70 <= score && score < 100) {
      return chartColors.green;
    }
  };

  let players = [
    {
      "name": "Luke Coutinho",
      "id": "0446e560-5a37-4d33-8bf9-7485d36622d0",
      "score": "85",
      "conference": "NFL",
      "conference_type": "Professional",
      "team": "Blu Chip Analytics",
      "sport": "Football",
      "position": "",
      "unit": "Offense",
    },
    {
      "name": "John Doe",
      "id": "0a17814d-b3ec-4a4e-ac05-bfeae9e890f5",
      "score": "73",
      "conference": "NFL",
      "conference_type": "Professional",
      "team": "Blu Chip Analytics",
      "sport": "Football",
      "position": "",
      "unit": "Offense",
    },
    {
      "name": "Han Solo",
      "id": "9cf009f4-f1ad-41ee-a406-a591e2a56f35",
      "score": "64",
      "conference": "NFL",
      "conference_type": "Professional",
      "team": "Blu Chip Analytics",
      "sport": "Football",
      "position": "",
      "unit": "Offense",
    }
  ]

  let risks = [
    {
      "name": "Brent Castro",
      "id": "065becc1-631c-460d-bbea-457a67452ace",
      "score": "26",
      "team": "Chicago Cubs",
      "conference": "",
      "conference_type": "",
      "sport": "",
      "position": "",
      "unit": "",
    },
    {
      "name": "Peter Paul",
      "id": "0677fe11-0639-405c-80b4-8100f63aad6a",
      "score": "34",
      "team": "Blu Chip Analytics",
      "conference": "",
      "conference_type": "",
      "sport": "",
      "position": "",
      "unit": "",
    },
    {
      "name": "Sam Bowen",
      "id": "a1664bf0-30a5-4977-a56a-6cd769c268e8",
      "score": "55",
      "team": "Blu Chip Analytics",
      "conference": "",
      "conference_type": "",
      "sport": "",
      "position": "",
      "unit": "",
    }
  ]

  const handleredirect = (playerId) => {
    playerActions.getPlayer(playerId);
    playerActions.selectPlayer(playerId);

    dialogActions.openDialog(<PlayerDetails playerId={playerId} fromTeam={true} />, {
      fullScreen: true,
      TransitionComponent: Transition,
    });
  }

  const customPositions = [];

  teamPlayers?.forEach((list) => {
    if (selectedIndex === 0) {
      customPositions.push({
        id: list["_id"],
        name: list["full_name"],
        score: Number(list.Character),
      });
    } else if (selectedIndex === 1) {
      customPositions.push({
        id: list["_id"],
        name: list["full_name"],
        score: Number(list.Coachability),
      });
    } else if (selectedIndex === 2) {
      customPositions.push({
        id: list["_id"],
        name: list["full_name"],
        score: Number(list.Composure),
      });
    } else {
      customPositions.push({
        id: list["_id"],
        name: list["full_name"],
        score: Number(list.Leadership),
      });
    }
    return customPositions
  }
  )

  const sample = customPositions.sort((a, b) => b.score - a.score)
  const totalScore = customPositions.reduce((a, v) => a = a + v.score, 0)

  let roundTo1PrecisionPoint = (value) => {
    return round(value, 0);
  };

  const finalScore = roundTo1PrecisionPoint(isNaN((totalScore / customPositions.length)) ? 0 : (totalScore / customPositions.length))

  const RISK_REVIEWS = {
    "Coachability": {
      "low": "This individual has vulnerabilities related to their versatility, adaptability, and/or grit. They could benefit from learning strategies to increase behavioral flexibility, managing stress, and building a growth mindset. They may also need support in fostering intrinsic drive and building self-belief. This individual may appear resistant to or have difficulty shifting their behavior in these areas.",
      "mid-low": "This individual has vulnerabilities related to their versatility, adaptability, and/or grit. They could benefit from learning strategies to increase behavioral flexibility, managing stress, and building a growth mindset. They may also need support in fostering intrinsic drive and building self-belief. This individual may appear more flexible or willing to shift their behavior in these areas.",
      "mid-high": "This individual has strengths related to their versatility, adaptability, and/or grit. They tend to use learning strategies that encourage behavioral flexibility, manage stress, and have a growth mindset. They tend to possess intrinsic drive and self-belief. Strength based interventions may continue to support their growth as a player and teammate.",
      "high": "This individual is versatile, adaptable, and gritty. They use learning strategies that encourage behavioral flexibility, manage stress, and have a growth mindset. They possess intrinsic drive and self-belief. This individual is likely to seek out strategies to continue to prioritize their stengths in these areas."
    },
    "Leadership": {
      "low": "This individual has vulnerabilities related to their stabililty, accountability, and/or being encouraging. They could benefit from learning strategies to manage their emotions during stress, communicate effectively, and elevate their teammates. They may also need support in learning to effectively motivate others and uphold team values. This individual may appear resistant to or have difficulty shifting their behavior in these areas.",
      "mid-low": "This individual has vulnerabilities related to their stabililty, accountability, and/or being encouraging. They could benefit from learning strategies to manage their emotions during stress, communicate effectively, and elevate their teammates. They may also need support in learning to effectively motivate others and uphold team values. This individual may appear resistant to or have difficulty shifting their behavior in these areas. This individual may appear more flexible or willing to shift their behavior in these areas.",
      "mid-high": "This individual has strengths related to their stabililty, accountability, and/or being encouraging. They tend to have strategies to manage their emotions during stress, communicate effectively, and elevate their teammates. They typically can effectively motivate others and uphold team values. Strength based interventions may continue to support their growth as a player and teammate.",
      "high": "This individual is stable, accountable, and encouraging. They have strategies to manage their emotions during stress, communicate effectively, and elevate their teammates. They can effectively motivate others and uphold team values. This individual is likely to seek out strategies to continue to prioritize their stengths in these areas."
    },
    "Composure": {
      "low": "This individual has vulnerabilities related to their emotional regulation, resilience, and/or mission focus. They could benefit from learning strategies to reset after making mistakes, increase emotional awareness, and focus on responding versus reacting. They may also need support in prioritizing goals, decision making, and building self-efficacy. This individual may appear resistant to or have difficulty shifting their behavior in these areas.",
      "mid-low": "This individual has vulnerabilities related to their emotional regulation, resilience, and/or mission focus. They could benefit from learning strategies to reset after making mistakes, increase emotional awareness, and focus on responding versus reacting. They may also need support in prioritizing goals, decision making, and building self-efficacy. This individual may appear more flexible or willing to shift their behavior in these areas.",
      "mid-high": "This individual has strengths related to their emotional regulation, resilience, and/or mission focus. They tend to have strategies for resetting after making mistakes, posess emotional awareness, and focus on responding versus reacting. They tend to be able to prioritize goals, feel comfortable making decisions, and posesses self-efficacy. Strength based interventions may continue to support their growth as a player and teammate.",
      "high": "This individual has strong emotional regulation, resilience, and mission focus. They have strategies for resetting after making mistakes, posess emotional awareness, and focus on responding versus reacting. They can prioritize goals, feel comfortable making decisions, and posesses self-efficacy. This individual is likely to seek out strategies to continue to prioritize their stengths in these areas."
    },
    "Character": {
      "low": "This individual has vulnerabilities related to their work ethic, integrity and/or building reationships. They could benefit from learning how to manage their stress and prioritize goals. They may also need support in upholding team values, fufilling daily obligations, and effective communication. This individual may appear resistant to or have difficulty shifting their behavior in these areas.",
      "mid-low": "This individual has vulnerabilities related to their work ethic, integrity and/or building reationships. They could benefit from learning how to manage their stress and prioritize goals. They may also need support in upholding team values, fufilling daily obligations, and effective communication. This individual may appear more flexible or willing to shift their behavior in these areas.",
      "mid-high": "This individual has strengths related to their work ethic, integrity and/or building reationships. They are likely to have the capacity to manage their stress and prioritize goals. They tend to prioritize upholding team values, fufilling daily obligations, and having effective communication. Strength based interventions may continue to support their growth as a player and teammate.",
      "high": "This individual has strengths related to work ethic, integrity and building reationships. They have the capacity to manage their stress and prioritize goals. They prioritize upholding team values, fufilling daily obligations, and having effective communication. This individual is likely to seek out strategies to continue to prioritize their stengths in these areas."
    }
  }

  function get_text_by_score(category, score) {
    let text = ""
    if (score == 0) {
      text = "Analysis Pending.."
    }
    else if (score <= 30) {
      text = RISK_REVIEWS[category]['low']
    }
    else if (score <= 49) {
      text = RISK_REVIEWS[category]['mid-low']
    }
    else if (score <= 69) {
      text = RISK_REVIEWS[category]['mid-high']
    }
    else if (score <= 100) {
      text = RISK_REVIEWS[category]['high']
    }
    else {
      text = "Custom trait score invalid to generate analysis text"
    }

    return text
  }

  const selectedPeople = [];

  const scoreRanges = [
    { min: 65, max: 75 },
    { min: 60, max: 65 },
    { min: 75, max: 80 },
    { min: 55, max: 60 },
  ];

  for (const range of scoreRanges) {
    const remainingPeople = customPositions && customPositions.filter(
      (person) => person.score >= range.min && person.score <= range.max
    );

    if (remainingPeople.length > 0) {
      selectedPeople.push(...remainingPeople);
    } else {
      continue;
    }
  }

  return (
    <div>
      <div style={{ display: "flex", justifyContent: 'center', color: '#326fc7', fontFamily: 'Prometo', }}><h3>Player Attributes</h3></div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Tabs
          labels={customTraitLabels}
          value={selectedIndex}
          onChange={handleTabChange}
        />
        <ExpansionArticle
          title={customTrait.label}
          details={customTrait.description}
        >
          <div>
            <ChartAndDetails
              diagnosis={get_text_by_score(customTrait.label, finalScore)}//customTrait["text"]}
              // score={customTrait["score"]}
              score={isNaN((totalScore / customPositions.length)) ? 0 : (totalScore / customPositions.length)}
              handleColors={handleColors}
            />
            <>

              <div style={{ width: "100%", display: "flex", justifyContent: "center", }} >
                <div>
                  <div style={{
                    margin: '1rem 0rem 1rem 1rem',
                    backgroundColor: '#0070b9',
                    fontSize: '15px',
                    padding: '12px 118px 12px 119px',
                    opacity: '0.8',
                    borderRadius: '9px',
                    fontWeight: 'bold',
                    color: '#fff', fontFamily: 'Prometo'
                  }}>
                    Key Performers
                  </div>
                  <div style={{
                    margin: '0rem 0rem 1rem 1rem',
                    backgroundColor: '#8ac3e8',
                    padding: '1rem 30px',
                    borderRadius: '10px',
                    color: '#fff'
                  }}>
                    {/* {
                      players && players.map((value) => (
                        <div style={{ display: 'flex', justifyContent: 'space-between', opacity: '1', alignItems: 'center' }}>
                          <span>
                            <span><CircleIcon style={{ height: 10 }} /></span>
                            <a style={{ textDecoration: 'underline', }} onClick={() => handleredirect(value.id)}>{value.name}</a>
                          </span>
                          <span>{value.score} %</span>
                        </div>
                      ))
                    } */}
                    {
                      selectedPeople.length < 3 ?
                        selectedPeople && selectedPeople.map((value) => (
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span>
                              <a style={{ textDecoration: 'underline', }} onClick={() => handleredirect(value.id)}>{value.name}</a>
                            </span>
                            <span>{Math.round(value.score)}%</span>
                          </div>
                        ))
                        :
                        selectedPeople && selectedPeople.slice(0, 3).map((value) => (
                          <div style={{ display: 'flex', justifyContent: 'space-between', opacity: '1', alignItems: 'center' }}>
                            <span>
                              <a style={{ textDecoration: 'underline', }} onClick={() => handleredirect(value.id)}>{value.name}</a>
                            </span>
                            <span>{Math.round(value.score)}%</span>
                          </div>
                        ))
                    }
                  </div>
                </div>

                <div>
                  <div style={{
                    margin: '1rem 0rem 1rem 1rem',
                    backgroundColor: '#999999',
                    fontSize: '15px',
                    padding: '12px 118px 12px 119px',
                    opacity: '0.8',
                    borderRadius: '9px',
                    fontWeight: 'bold',
                    color: '#fff', fontFamily: 'Prometo'
                  }}>
                    Possible Improvements
                  </div>
                  <div style={{
                    margin: '0rem 0rem 1rem 1rem',
                    backgroundColor: '#cccccc',
                    padding: '1rem 30px',
                    borderRadius: '10px'
                  }}>
                    {/* {
                      risks && risks.map((value) => (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <span>
                            <span><CircleIcon style={{ height: 10 }} /></span>
                            <a style={{ textDecoration: 'underline', }} onClick={() => handleredirect(value.id)}>{value.name}</a>
                          </span>
                          <span>{value.score} %</span>
                        </div>
                      ))
                    } */}
                    {
                      customPositions.length < 3 ?
                        customPositions && customPositions.reverse().map((value) => (
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span>
                              <a style={{ textDecoration: 'underline', }} onClick={() => handleredirect(value.id)}>{value.name}</a>
                            </span>
                            <span>{Math.round(value.score)}%</span>
                          </div>
                        ))
                        :
                        customPositions && customPositions.slice(Math.max(customPositions.length - 3, 1)).map((value) => (
                          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <span>
                              <a style={{ textDecoration: 'underline', }} onClick={() => handleredirect(value.id)}>{value.name}</a>
                            </span>
                            <span>{Math.round(value.score)}%</span>
                          </div>
                        ))
                    }
                  </div>
                </div>

              </div>
            </>
            {/* <div style={{ display: 'flex', justifyContent: 'center', margin: "2rem 0rem 2rem 0rem", fontWeight: 'bold' }}>No Team player found</div> */}
          </div>
        </ExpansionArticle>
      </div>
    </div>
  );
};

let mapStateToProps = (state, ownProps) => {

  return {
    teamPlayers: state.player.filterPlayers,
    loadingPlayerFilters: state.ui.player.loadingPlayerFilters
  };
};

export default connect(mapStateToProps)(PlayerAttributes);
