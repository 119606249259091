import React, { useState, useContext } from "react";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from "@material-ui/icons/ExpandMore";
import ExpandMoreIcon from "@material-ui/icons/ExpandLess";
import Approve from "./Approve";
import PlayerDuplicateInfo from "./PlayerDuplicateInfo";
import { ActionContext } from "../../../../contexts";
import { statusTypes, accountTypes } from "../../constants";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    // borderTop: `1px solid ${theme.palette.divider}`,
    boxShadow: "0px -30px 77px -14px rgba(0,0,0,0.09)",
    zIndex: 1100,
  },
  heading: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2, 3),
  },
  duplicates: {
    display: "flex",
    justifyContent: "space-around",
    paddingBottom: theme.spacing(1),
  },
}));

const ExpandButton = (props) => {
  let { expanded = true, onChange } = props;

  let handleChange = (state) => {
    return () => {
      onChange(state);
    };
  };

  if (expanded) {
    return (
      <IconButton onClick={handleChange(false)}>
        <ExpandLessIcon />
      </IconButton>
    );
  }

  return (
    <IconButton onClick={handleChange(true)}>
      <ExpandMoreIcon />
    </IconButton>
  );
};

const ApprovePanel = (props) => {
  let classes = useStyles();
  let {
    analysisStatus,
    hasDuplicate,
    originalPlayer,
    currentPlayer,
    playerId,
    possiblePlayerId,
    approvingCandidateProfile,
    rejectingCandidateProfile,
    accountType,
  } = props;
  let [expanded, setExpanded] = useState(true);

  let { playerActions, snackbarActions } = useContext(ActionContext);

  let hasAccountPriviledges =
    accountType === accountTypes.superAdmin ||
    accountType === accountTypes.bcaDdiAdmin;
  let showApprovePane =
    hasAccountPriviledges && analysisStatus === statusTypes.approvalPending;

  let handleExpand = (state) => {
    setExpanded(state);
  };

  let handleApproval = (copyArchives, copyReviews, copyAnalysis) => {
    let data = {
      candidate_id: playerId,
      copy_archive: copyArchives,
      copy_reviews: copyReviews,
      copy_analysis: copyAnalysis,
      matching_candidate_id: possiblePlayerId,
    };

    let success = () => {
      return snackbarActions.showSnackbar("Candidate Approved Successfully");
    };

    playerActions.approveCandidateProfile(data, success);
  };

  let handleReject = () => {
    let success = () => {
      return snackbarActions.showSnackbar("Candidate Rejected");
    };

    let data = {
      candidate_id: playerId,
    };

    playerActions.rejectCandidateProfile(data, success);
  };

  if (!showApprovePane) {
    return null;
  }

  return (
    <div className={classes.root}>
      {hasDuplicate ? (
        <div>
          <div className={classes.heading}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="subtitle1">Possible duplicates</Typography>
            </div>
            <ExpandButton expanded={expanded} onChange={handleExpand} />
          </div>

          {expanded ? (
            <div className={classes.duplicates}>
              <PlayerDuplicateInfo player={currentPlayer} />
              <PlayerDuplicateInfo player={originalPlayer} original />
            </div>
          ) : null}
        </div>
      ) : null}
      <Approve
        onApprove={handleApproval}
        onReject={handleReject}
        showDuplicateActions={hasDuplicate}
        approvingCandidateProfile={approvingCandidateProfile}
        rejectingCandidateProfile={rejectingCandidateProfile}
      />
    </div>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { playerId } = ownProps;
  let basicInfo = state.player.basic[playerId];
  let contactInfo = state.player.contact[playerId];
  let possibleDuplicateInfo = state.player.possibleDuplicateInfo[playerId];

  let original = {
    id: possibleDuplicateInfo["possiblePlayerId"],
    name: possibleDuplicateInfo["possiblePlayerName"],
    birthday: possibleDuplicateInfo["possiblePlayerBirthday"],
    school: possibleDuplicateInfo["possiblePlayerSchool"],
    team: possibleDuplicateInfo["possiblePlayerTeam"],
    email: possibleDuplicateInfo["possiblePlayerEmail"],
    phone: possibleDuplicateInfo["possiblePlayerPhone"],
    createdDate: possibleDuplicateInfo["possiblePlayerCreatedOn"],
    reviewedDate: possibleDuplicateInfo["possiblePlayerReviewedOn"],
  };

  let current = {
    id: playerId,
    name: basicInfo["name"],
    birthday: basicInfo["birthday"],
    school: basicInfo["school"],
    team: basicInfo["team"],
    email: contactInfo["email"],
    phone: contactInfo["phone"],
  };

  return {
    analysisStatus: basicInfo.status,
    hasDuplicate: possibleDuplicateInfo.isPossibleDuplicate,
    originalPlayer: original,
    currentPlayer: current,
    possiblePlayerId: possibleDuplicateInfo["possiblePlayerId"],
    approvingCandidateProfile:
      state.ui.player.approvingCandidateProfile[playerId],
    rejectingCandidateProfile:
      state.ui.player.rejectingCandidateProfile[playerId],
    accountType: state.account.accountType,
  };
};
export default connect(mapStateToProps)(ApprovePanel);
