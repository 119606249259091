import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ReportDialog from "./ReportDialog";
// import { accountTypes } from "modules/players/constants";
import { ActionContext } from "../../../../contexts";

const Reports = (props) => {
  const { coachId, status } = props;
  const { dialogActions } = useContext(ActionContext);

  const handleClick = () => {
    dialogActions.openDialog(
      <ReportDialog coachId={coachId} status={status} />,
      {
        maxWidth: "xs",
      }
    );
  };

  return (
    <div style={{ marginLeft: 8 }}>
      <span
        style={{
          border: '1px solid',
          padding: '0.3rem 0.5rem',
          borderRadius: '9px',
          margin: '0rem 1rem',
          color: '#0971ce',
          fontWeight: 700,
          cursor: 'pointer',
        }}
        color="default"
        startIcon={<AssessmentIcon />}
        onClick={handleClick}
        disableElevation
      >
        Reports
      </span>
    </div>
  );
};

export default Reports;
