import { combineReducers } from "redux";
import * as types from "./sportsType";

const getAllSportsListData = (state = [], action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_ALL_SPORTS_SUCCESS: {
      return payload
    }

    default:
      return state;
  }
};


const unitSport = (state = [], action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_SPORT_UNIT_BY_SPORT_ID_SUCCESS: {
      return payload
    }

    case types.RESET_SPORT_UNIT_BY_SPORT_ID: {
      return payload 
    }

    default:
      return state;
  }
}

const sportPosition = (state = [], action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_SPORT_POSITION_BY_UNIT_ID_SUCCESS: {
      return payload
    }

    case types.RESET_SPORT_POSITION_BY_UNIT_ID: {
      return payload 
    }

    default:
      return state;
  }
}
const getUserTypesList = (state = [], action) => {
  const { payload } = action;
  switch (action.type) {
    case types.GET_TEAM_USERS_SUCCESS: {
      return payload
    }

    default:
      return state;
  }
};

export default combineReducers({
    sportsListData: getAllSportsListData,
    unitSport,
    sportPosition,
    userTypesList:getUserTypesList
  });