import React, { useContext } from "react";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import Divider from "@material-ui/core/Divider";
import Switch from "@material-ui/core/Switch";
import PersonIcon from "@material-ui/icons/Person";
import AssesmentIcon from "@material-ui/icons/Assessment";
import { statusTypes } from "modules/players/constants";
import { ActionContext } from "../../../../contexts";
import { connect } from "react-redux";
import { blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
// import eachPlayer from "modules/dashboard/components/EachPlayer"

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  enabled: {
    color: theme.palette.success.main,
  },
  pending: {
    color: theme.palette.warning.main,
  },
}));

const ReportDialog = (props) => {
  const classes = useStyles();

  const { playerActions } = useContext(ActionContext);

  const {
    status,
    playerId,
    closeDialog,
    isProfileAnalysisEnabled,
    loadingProfileAnalysis,
    reportSettings,
    loadingFullReportPaymentStatus,
    loadingExecutiveSummaryReportPaymentStatus,
    loadingActionPlanReportPaymentStatus,
  } = props;

  // const selectedPlayer = eachPlayer && eachPlayer[playerId]
  // const reportSettings = selectedPlayer && selectedPlayer?.reportSettings

  // const isProfileAnalysisEnabled = selectedPlayer && selectedPlayer.is_paid

  const isFullReportReady = true;

  const isExecutiveSummeryReportReady = true;

  const isActionPlanReportReady =
    status === statusTypes.consentPending || status === statusTypes.completed;

  const handleCloseDialog = () => {
    closeDialog();
  };

  const handleProfileAnalysis = () => {
    return isProfileAnalysisEnabled
      ? playerActions.markCandidateAsUnpaid(playerId)
      : playerActions.markCandidateAsPaid(playerId);
  };

  const handleFullReportPaymentStatus = () => {
    const status = reportSettings?.fullReport;
    playerActions.toggleFullReport(playerId, !status);
  };

  const handleExecutiveSummaryReportPaymentStatus = () => {
    const status = reportSettings?.executiveSummaryReport;
    playerActions.toggleExecutiveSummaryReport(playerId, !status);
  };

  const handleActionPlanReportPaymentStatus = () => {
    const status = reportSettings?.actionPlanReport;
    playerActions.toggleActionPlanReport(playerId, !status);
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex" }}>
        <DialogTitle id="simple-dialog-title" style={{ flex: 1 }}>
          Select Payment Status
        </DialogTitle>
        <div style={{ margin: "8px 16px 8px 8px" }}>
          <IconButton onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>

      <List>
        {/* Profile analysis */}
        <ListItem>
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <PersonIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Action Plan" />
          <ListItemSecondaryAction>
            <Switch
              // disabled={true}
              color="primary"
              checked={isProfileAnalysisEnabled}
              onChange={handleProfileAnalysis}
              disabled={loadingProfileAnalysis}
            />
          </ListItemSecondaryAction>
        </ListItem>

        <Divider />

        {/* Full Report */}
        <ListItem>
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <AssesmentIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Full Report"
            secondary={isFullReportReady ? "Ready" : "Pending"}
            secondaryTypographyProps={{
              className: isFullReportReady ? classes.enabled : classes.pending,
            }}
          />
          <ListItemSecondaryAction>
            <Switch
              color="primary"
              checked={reportSettings?.fullReport}
              onChange={handleFullReportPaymentStatus}
              disabled={loadingFullReportPaymentStatus}
              // disabled={true}
            />
          </ListItemSecondaryAction>
        </ListItem>

        {/* Executive Summary Report */}
        <ListItem>
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <AssesmentIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Executive Summary Report"
            secondary={isExecutiveSummeryReportReady ? "Ready" : "Pending"}
            secondaryTypographyProps={{
              className: isExecutiveSummeryReportReady
                ? classes.enabled
                : classes.pending,
            }}
          />
          <ListItemSecondaryAction>
            <Switch
              color="primary"
              checked={reportSettings?.executiveSummaryReport}
              onChange={handleExecutiveSummaryReportPaymentStatus}
              disabled={loadingExecutiveSummaryReportPaymentStatus}
              // disabled={true}
            />
          </ListItemSecondaryAction>
        </ListItem>

        {/* Action Plan Report */}
        <ListItem>
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <AssesmentIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Action Plan Report"
            secondary={isActionPlanReportReady ? "Ready" : "Pending"}
            secondaryTypographyProps={{
              className: isActionPlanReportReady
                ? classes.enabled
                : classes.pending,
            }}
          />
          <ListItemSecondaryAction>
            <Switch
              color="primary"
              checked={reportSettings?.actionPlanReport}
              onChange={handleActionPlanReportPaymentStatus}
              disabled={loadingActionPlanReportPaymentStatus}
              // disabled={true}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { playerId } = ownProps;

  return {
    accountType: state.account.accountType,
    isProfileAnalysisEnabled: state.player.basic[playerId].isPaid,
    reportSettings: state.player.reportSettings[playerId],
    loadingProfileAnalysis:
      state.ui.player.updatingCandidatePaidStatus[playerId] || false,
    loadingFullReportPaymentStatus:
      state.ui.player.loadingFullReportPaymentStatus[playerId] || false,
    loadingExecutiveSummaryReportPaymentStatus:
      state.ui.player.loadingExecutiveSummaryReportPaymentStatus[playerId] ||
      false,
    loadingActionPlanReportPaymentStatus:
      state.ui.player.loadingActionPlanReportPaymentStatus[playerId] || false,
  };
};
export default connect(mapStateToProps)(ReportDialog);
