import React from "react";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import Verify from "./Verify";
import playersArt from "../../../assets/perc3pt/player2.jpg";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import Logo from "../../../assets/perc3pt/image2vector.svg";
import ddiLogo from "../../../assets/ddi.png";
import bluechipLogo from "../../../assets/bluechiplogo2.png";
import Hidden from "@material-ui/core/Hidden";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const leftBarWidth = 450;

let useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    display: "flex",
    width: "100%",
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      maxWidth: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  logo: {
    minHeight: 58,
    marginLeft: -4,
    marginTop: theme.spacing(3),
  },
  alert: {
    // minHeight: 50,
    marginBottom: theme.spacing(2),
  },
  left: {
    display: "flex",
    flexDirection: "column",
    width: leftBarWidth,
    padding: theme.spacing(0, 3, 3, 3),
    borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: 7,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.palette.background.paper,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.grey[200],
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      borderRight: "none",
    },
  },
  right: {
    position: "absolute",
    display: "flex",
    right: 0,
    width: `calc(100vw - ${leftBarWidth}px)`,
    height: "100vh",
    backgroundColor: theme.palette.background.paper,
  },
  image: {
    backgroundColor: "rgba(9,44,70,0.9)",
  },
  imageOverlay: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    bottom: 0,
    height: 60,
    backgroundColor: "rgba(9,44,70,0.7)",
    width: "100%",
    opacity: 1,
    padding: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
}));

let LoginContainer = (props) => {
  let classes = useStyles();
  let { appAlert } = props;

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.left}>
          <div className={classes.logo}>
            <img src={Logo} alt="logo" height={55} />
          </div>
          <div className={classes.alert}>
            {appAlert ? <Alert severity="warning">{appAlert}</Alert> : null}
          </div>
          <Switch>
            <Route path="/signin/recovery" exact component={ForgotPassword} />
            <Route path="/signin/verify" exact component={Verify} />
            <Route path="/signin" exact component={Login} />
            <Redirect to="/notfound" />
          </Switch>
        </div>
        <Hidden mdDown>
          <div className={classes.right}>
            <img
              src={playersArt}
              alt="fans"
              className={classes.image}
              width="100%"
            />
            <div className={classes.imageOverlay}>
              <img
                src={bluechipLogo}
                alt="bluechip-logo"
                height={30}
                style={{ marginRight: 24 }}
              />

              {/* <Typography
                variant="subtitle1"
                style={{ cursor: "pointer" }}
                noWrap
              >
                <a target="_blank" className={classes.link}>
                  Powered by Deep Data Insight
                </a>
              </Typography>
              <img
                src={ddiLogo}
                alt="ddi-logo"
                height={30}
                style={{ marginLeft: 24 }}
              /> */}
            </div>
          </div>
        </Hidden>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appAlert: state.ui.account.appAlert,
  };
};
export default connect(mapStateToProps)(LoginContainer);
