import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { round } from "../../../../utils";
import { RadialBar } from "../../../shared/Charts";
import { RadialGauge } from "../../../shared/Charts";


let roundTo1PrecisionPoint = (value) => {
  return round(value, 0);
};

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 16,
  },
  details: {
    display: "flex",
    flex: 1,
    paddingRight: theme.spacing(1),
  },
}));

let options = {
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "40%",
        background: "#293450",
      },
      track: {
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          blur: 4,
          opacity: 0.15,
        },
      },
      dataLabels: {
        name: {
          show: false,
          offsetY: -10,
          color: "#fff",
          fontSize: "13px",
        },
        value: {
          color: "#fff",
          fontSize: "14px",
          offsetY: 6,
          show: true,
        },
      },
    },
  },
  stroke: {
    lineCap: "butt",
  },
};

const ChartAndDetails = (props) => {
  let classes = useStyles();
  let { diagnosis, score = 0, handleColors, tabName } = props;

  let color = handleColors(score);

  return (
    <div className={classes.root}>
      <div style={{ width: 8 }}></div>
      {tabName && tabName === 'Risk Factors' ?
        <RadialBar
          score={roundTo1PrecisionPoint(score)}
          options={options}
          color={color}
          width="100"
        /> :

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div><RadialGauge score={roundTo1PrecisionPoint(score)} /></div>
          {/* <div>{roundTo1PrecisionPoint(score)}%</div> */}
        </div>
      }

      <div className={classes.details}>
        <div
          style={{ display: "flex", flex: 1, justifyContent: "space-between" }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle2" style={{ paddingLeft: 16 }}>
              {diagnosis}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChartAndDetails;
