import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import {
    AppBar,
    Avatar,
    Checkbox,
    Divider,
    Toolbar,
    Typography,
    TextField,
    Button,
    TextareaAutosize,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import React, { useState, useEffect, useContext, useRef } from "react";
import { connect } from "react-redux";
import { capitalize } from "../../utils";
import { IconButton } from "../shared/Button";
import InfoIcon from "@material-ui/icons/Info";
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { ActionContext } from "../../contexts";
import '../../index.css';
import DeleteIcon from "@material-ui/icons/Delete";
import { useHistory } from "react-router-dom";
import moment from "moment";


let useStyles = makeStyles((theme) => ({
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        borderTopLeftRadius: 8,
        background: '#0971ce1a',
        margin: '1rem',
        margin: '1rem 1rem 3rem 1rem',
        width: '97%',
        borderRadius: '9px',
        "& .MuiToolbar-regular": {
            minHeight: '30px',
        },
    },
    content: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        paddingTop: theme.spacing(2),
        fontFamily: 'Prometo',
        color: '#326fc7'
    },
    nav: {
        backgroundColor: '#00000029',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: '9px',
        boxShadow: '0 2px 11px 0 rgb(0 0 0 / 16%)',
        backgroundColor: '#eeeef0',
        position: 'absolute',
        top: '5.9rem',
        zIndex: 1,
        // width: 101,
        // borderLeft: `1px solid ${theme.palette.divider}`,
        // paddingTop: theme.spacing(1),
        // borderTopRightRadius: theme.spacing(1),
        // borderBottomRightRadius: theme.spacing(1),
    },
    avatar: {
        width: 30,
        height: 30,
        marginRight: 8,
    },
    name: {
        width: 95,
        fontWeight: theme.typography.fontWeightBold,
    },
    team: {
        width: 95,
        fontSize: 10,
    },
    NameAlignData: {
        color: '#326fc7',
        fontSize: '16px',
        marginLeft: '10px'
    },
    TeamALign: {
        color: '#666666',
        fontSize: '14px',
    },
    StatusAligncss: {
        border: '1px solid #000',
        padding: '0.2rem 0.6rem',
        fontSize: '13px',
        fontWeight: '600',
        borderRadius: '9px',
    },
    SportALign: {
        color: '#666666',
        fontSize: '14px',
    },
    page: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        overflowY: "scroll",
        scrollbarWidth: 7,
        background: '#ebf4fb',
        scrollbarColor: theme.palette.grey[200],
        "&::-webkit-scrollbar": {
            width: 8,
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: theme.palette.background.default,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.grey[300],
            height: 50,
        },
        padding: theme.spacing(2),
    },
    checkbox: {
        color: "red",
    },
    AvatarCellDesign: {
        cursor: 'pointer',
        backgroundColor: theme.palette.primary.main,
        fontSize: '12px',
        height: '32px',
        width: '32px',
        boxShadow: '3px 3px 8px 0px #cdcdcd',
        color: '#fff',
        fontWeight: '700'
    },
}));

const AddVideoDetails = (props) => {
    let classes = useStyles();
    let history = useHistory();

    let { playerId, basicInfo, closeDialog } = props;

    const [formData, setFormData] = useState({
        handle: basicInfo && basicInfo.name,
        title: '',
        url: '',
        video_id: '',
        published_date: '',
        duration: '',
        score: 0.00,
        dialogs: [],

    });
    const { playerActions, snackbarActions } = useContext(ActionContext);
    const playerName = basicInfo && basicInfo.name.split(" ")
    const [additionalDialogs, setAdditionalDialogs] = useState([
        { text: '', is_self: false },
    ]);



    const handleAddDialog = () => {
        setAdditionalDialogs([...additionalDialogs, { text: '', is_self: false }]);
    };

    const handleTextareaChange = (index, event) => {
        const newDialogs = [...additionalDialogs];
        newDialogs[index] = {
            ...newDialogs[index],
            text: event.target.value,
        };
        setAdditionalDialogs(newDialogs);
    };

    const handleCheckboxChange = (index, event) => {
        const newDialogs = [...additionalDialogs];
        newDialogs[index] = {
            ...newDialogs[index],
            is_self: event.target.checked,
        };
        setAdditionalDialogs(newDialogs);
    };

    const [enableSave, setEnableSave] = useState(false);
    const firstTextFieldRef = useRef(null);
    useEffect(() => {
        if (firstTextFieldRef.current) {
            firstTextFieldRef.current.focus();
        }
    }, []);

    const [isDateFormatValid, setIsDateFormatValid] = useState(true);

    const handleInputChange = (field, value) => {
        setFormData({ ...formData, [field]: value });
        const allFieldsHaveData = Object.values({ ...formData, [field]: value }).every((fieldValue) => {
            if (typeof fieldValue === 'string') {
                return fieldValue.trim() !== '';
            }
            return true;
        });
        setEnableSave(allFieldsHaveData);
        if (field === 'published_date') {
            const isValidDateFormat = moment(value, ['MM/DD/YYYY', 'MM-DD-YYYY', 'MM_DD_YYYY'], true).isValid();
            setIsDateFormatValid(isValidDateFormat);
            if (isValidDateFormat) {
                const selectedDate = moment(value, ['MM/DD/YYYY', 'MM-DD-YYYY', 'MM_DD_YYYY'], true);
                const today = moment().startOf('day');
                if (selectedDate.isAfter(today)) {
                    setIsDateFormatValid(false);
                }

            }
        }

        else if (field === 'duration') {
            value = value.replace(/[^0-9.]/g, '');
            const parts = value.split('.');
            let minutes = parseInt(parts[0] || '0');
            let seconds = parseFloat(parts[1] || '0');
            if (isNaN(minutes) || minutes < 0 || minutes > 60) {
                setFormData({ ...formData });
                return;
            }
            if (isNaN(seconds) || seconds < 0 || seconds >= 60) {
                setFormData({ ...formData });
                return;
            }
            seconds = seconds.toFixed(2);
            setFormData({ ...formData, [field]: value });

        }
    };

    const isAtLeastOneDialogWithText = () => {
        return additionalDialogs.some((dialog) => dialog.text.trim() !== '');
    };
    const hasDialogsWithText = isAtLeastOneDialogWithText();

    const isDeleteAllowed = () => {
        return additionalDialogs.length > 1;
    };

    const handleDeleteDialog = (index) => {
        if (isDeleteAllowed()) {
            const updatedDialogs = [...additionalDialogs];
            updatedDialogs.splice(index, 1);
            setAdditionalDialogs(updatedDialogs);
        }
    };
    const handleSave = () => {
        // const formattedDate = formData.published_date ? new Date(formData.published_date).toISOString().slice(0, 19).replace('T', ' ') : '';
        const formattedDate = formData.published_date ? moment(formData.published_date, "MM_DD_YYYY").format("YYYY-MM-DD 00:00:00") : ""
        const filteredDialogs = additionalDialogs.filter((dialog) => dialog.text.trim() !== "");


        const newDialogs = filteredDialogs.map((dialog) => ({
            text: dialog.text,
            score: parseFloat(0),
            is_self: dialog.is_self !== undefined ? dialog.is_self : false,
        }));

        const newFormData = {
            ...formData,
            published_date: formattedDate,
            dialogs: newDialogs,
        };
        let success = () => {
            snackbarActions.showSnackbar("video transcription added successfully");
            closeDialog();
        };
        playerActions.getVideoTranscript(newFormData, success)
    };

    return (
        <React.Fragment>
            <div className={classes.content}>
                <AppBar
                    position="relative"
                    color="inherit"
                    elevation={0}
                    className={classes.appBar}
                >
                    <Toolbar>
                        {/* <Hidden> */}
                        <IconButton icon={<ArrowBackIcon />} onClick={closeDialog} />
                        {/* </Hidden> */}
                        <Avatar className={classes.AvatarCellDesign} style={{ cursor: 'pointer', }} title={basicInfo && basicInfo.name}>
                            {
                                basicInfo && basicInfo.name ?
                                    (
                                        playerName.length > 1 ?
                                            basicInfo && basicInfo.name.split(" ")[0][0].toUpperCase() + basicInfo && basicInfo.name.split(" ")[1][0].toUpperCase()
                                            :
                                            basicInfo && basicInfo.name.split(" ")[0][0].toUpperCase()
                                    )
                                    :
                                    ""
                            }
                        </Avatar>
                        <Typography variant="h6" className={classes.NameAlignData}>{capitalize(basicInfo && basicInfo.name)}</Typography>
                        <Divider orientation="vertical" style={{ margin: '0px 10px 0px 10px' }} />
                        <Typography className={classes.TeamALign}>{capitalize(basicInfo && basicInfo.team)}</Typography>
                        <Divider orientation="vertical" style={{ margin: '0px 10px 0px 10px' }} />
                        <Typography className={classes.SportALign}>{capitalize(basicInfo && basicInfo.sport) || "NA"}</Typography>
                        <Divider orientation="vertical" style={{ margin: '0px 10px 0px 10px' }} />
                        <Typography className={classes.StatusAligncss}
                            style={{
                                background: basicInfo && basicInfo.status === 'Completed' ? '#e0f5ea' : basicInfo && basicInfo.status === 'Approval Pending' ? '#cfe3f2' : basicInfo && basicInfo.status === 'Rejected' ? '#EBE2E9' : basicInfo && basicInfo.status === 'Processing' ? '#fbf3d9' : basicInfo && basicInfo.status === 'Psych Review Pending' ? '#f2f2f2' : basicInfo && basicInfo.status === 'Consent Pending' ? '#cccccc' : basicInfo && basicInfo.status === 'Error' ? '#fddfdf' : '',
                                border: basicInfo && basicInfo.status === 'Completed' ? '1px solid #e0f5ea' : basicInfo && basicInfo.status === 'Approval Pending' ? '1px solid #cfe3f2' : basicInfo && basicInfo.status === 'Rejected' ? '1px solid #EBE2E9' : basicInfo && basicInfo.status === 'Processing' ? '#fbf3d9' : basicInfo && basicInfo.status === 'Psych Review Pending' ? '1px solid #f2f2f2' : basicInfo && basicInfo.status === 'Consent Pending' ? '1px solid #cccccc' : basicInfo && basicInfo.status === 'Error' ? '1px solid #fddfdf' : '',
                                color: basicInfo && basicInfo.status === 'Completed' ? '#00ae4d' : basicInfo && basicInfo.status === 'Approval Pending' ? '#1075bf' : basicInfo && basicInfo.status === 'Rejected' ? '#BC5353' : basicInfo && basicInfo.status === 'Processing' ? '#e7b618' : basicInfo && basicInfo.status === 'Psych Review Pending' ? '#666666' : basicInfo && basicInfo.status === 'Consent Pending' ? '#000000' : basicInfo && basicInfo.status === 'Error' ? '#f33a3a' : ''
                            }}
                        ><span >{capitalize(basicInfo && basicInfo.status)}</span></Typography>


                    </Toolbar>
                </AppBar>
                <div className={classes.page}>
                    <div style={{ marginTop: '2rem', width: '90%' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div
                                style={{
                                    display: 'block',
                                    textAlign: 'center',
                                    borderBottom: '2px solid #ffd201',
                                    marginBottom: '2rem',
                                }}
                            ></div>
                        </div>
                        <div style={{ display: 'flex', width: '100%' }}>
                            <div style={{ flex: '50%', marginRight: '2rem' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                                    <TextField
                                        variant="outlined"
                                        label="Video ID *"
                                        value={formData.video_id}
                                        onChange={(e) => handleInputChange('video_id', e.target.value)}
                                        inputRef={firstTextFieldRef}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>

                                    <TextField
                                        variant="outlined"
                                        label="Video URL *"
                                        value={formData.url}
                                        onChange={(e) => handleInputChange('url', e.target.value)}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>
                                    <TextField
                                        variant="outlined"
                                        label="Title *"
                                        value={formData.title}
                                        onChange={(e) => handleInputChange('title', e.target.value)}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>

                                    <TextField
                                        variant="outlined"
                                        placeholder="mm/dd/yyyy"
                                        label="Video published date *"
                                        value={formData.published_date}
                                        onChange={(e) => handleInputChange('published_date', e.target.value)}
                                        error={!isDateFormatValid}
                                        helperText={!isDateFormatValid ? 'Invalid date format.' : ''}
                                    />
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1rem' }}>

                                    <TextField
                                        variant="outlined"
                                        label="Video length *"
                                        value={formData.duration}
                                        onChange={(e) => handleInputChange('duration', e.target.value)}
                                        inputProps={{ maxLength: 5 }}
                                    />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                                    <div style={{ flex: '50%', color: 'red', textAlign: 'start', marginTop: '1rem' }}>
                                        *all fields are mandatory.
                                    </div>
                                    <Button variant="contained" color="primary" style={{ marginRight: '1rem' }} onClick={handleSave} disabled={!enableSave || hasDialogsWithText !== true || !isDateFormatValid}>
                                        Save
                                    </Button>
                                    <Button variant="contained" color="default" onClick={closeDialog}>
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                            <Divider orientation="vertical" flexItem />
                            <div style={{ flex: '50%' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {additionalDialogs.map((dialog, index) => (
                                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                                            <Checkbox
                                                checked={dialog.is_self}
                                                onChange={(event) => handleCheckboxChange(index, event)}
                                            />
                                            <TextareaAutosize
                                                rows={4}
                                                value={dialog.text}
                                                onChange={(event) => handleTextareaChange(index, event)}
                                                placeholder="Textarea"
                                                style={{ width: '100%', resize: 'none', marginTop: '0.5rem' }}
                                            />
                                            {isDeleteAllowed() && (
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={() => handleDeleteDialog(index)}
                                                    icon={<DeleteIcon style={{ color: 'rgb(255, 165, 0)' }} />}
                                                />
                                            )}
                                        </div>
                                    ))}
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                                        <Button variant="contained" color="primary" onClick={handleAddDialog} style={{ marginRight: '1rem' }}>
                                            Add Dialog
                                        </Button>
                                    </div>
                                    <div style={{ flex: '50%', display: 'flex', alignItems: 'center', color: 'red', textAlign: 'start' }}>
                                        {!isAtLeastOneDialogWithText() && "* at least one dialog is required with text."}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    );
};

let mapStateToProps = (state, ownProps) => {
    let { playerId } = ownProps;

    return {
        basicInfo: state.player.basic[playerId],

    };
};
export default connect(mapStateToProps)(AddVideoDetails);
