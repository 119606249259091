import React from "react";
import IconButton from "@material-ui/core/IconButton";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.grey[100],
  },
  AvatarCellDesign: {
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    fontSize: '12px',
    height: '32px',
    width: '32px',
    boxShadow: '3px 3px 8px 0px #cdcdcd',
    color: '#fff',
    fontWeight: '700'
  },
}));

const SettingsWithTooltip = ({ fullName, email, onClick }) => {
  let classes = useStyles();
  let handleClick = (e) => onClick(e);
  return (
    <IconButton size="small" onClick={handleClick} className={classes.root}>
      {/* <SettingsIcon style={{ color: '#000' }} />      */}
      <Avatar className={classes.AvatarCellDesign} style={{ cursor: 'pointer', }} title={fullName}>
        {fullName ? fullName.split(" ")[0][0].toUpperCase() + " " + fullName.split(" ")[1][0].toUpperCase() : ""}
      </Avatar>
    </IconButton>
  );
};

export default SettingsWithTooltip;
