import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import AtIcon from "@material-ui/icons/AlternateEmail";
import { Field, Form, Formik } from "formik";
import { Select, TextField } from "formik-material-ui";
import React from "react";
import { object, string } from "yup";
import Button from "../../shared/Button";

const Query = ({ onSubmit, searching }) => {
  return (
    <Formik
      initialValues={{
        name: "",
        city: "",
        country: "",
        sport: "",
        twitter: "",
        instagram: "",
        keywords: "",
      }}
      validationSchema={object().shape({
        name: string().required("Enter Athlete Name"),
        city: string(),
        country: string(),
        sport: string(),
        twitter: string(),
        instagram: string(),
        keywords: string(),
      })}
      onSubmit={onSubmit}
    >
      {(formik) => {
        let { errors, touched } = formik;

        return (
          <Form>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography varaiant="body1">Basic Info</Typography>
              </Grid>
              <Grid item>
                <Field
                  name="name"
                  label="Athlete Name"
                  placeholder="Athlete Name"
                  component={TextField}
                  disabled={searching}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item>
                <Field
                  name="city"
                  label="City"
                  placeholder="City"
                  component={TextField}
                  disabled={searching}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item>
                <Field
                  name="country"
                  label="Country"
                  placeholder="Country"
                  component={TextField}
                  disabled={searching}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={touched["sport"] && !!errors["sport"]}
                >
                  <InputLabel htmlFor="sport">Sport</InputLabel>
                  <Field
                    component={Select}
                    name="sport"
                    label="Sport"
                    placeholder="Sport"
                    labelId="sport"
                    disabled={searching}
                    inputProps={{
                      id: "sport",
                    }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="en">Basketball</MenuItem>
                    <MenuItem value="es">Football</MenuItem>
                    <MenuItem value="fr">Hockey</MenuItem>
                  </Field>
                  {touched["sport"] && !!errors["sport"] ? (
                    <FormHelperText>{errors["sport"]}</FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>

              <Grid item>
                <Typography varaiant="body1">Known Aliases</Typography>
              </Grid>
              <Grid item>
                <Field
                  name="twitter"
                  label="Twitter handle"
                  placeholder="Twitter handle"
                  component={TextField}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AtIcon style={{ color: "#b1b1b1" }} />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Field
                  name="instagram"
                  label="Instagram handle"
                  placeholder="Instagram handle"
                  component={TextField}
                  variant="outlined"
                  disabled={searching}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AtIcon style={{ color: "#b1b1b1" }} />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Typography varaiant="body1">Additional Info</Typography>
              </Grid>
              <Grid item>
                <Field
                  name="keywords"
                  label="Keywords"
                  placeholder="Keywords"
                  component={TextField}
                  disabled={searching}
                  variant="outlined"
                  fullWidth
                />
                <FormHelperText>
                  Enter comma seperated values. Eg: Nashville, Captain
                </FormHelperText>
              </Grid>
              <Grid item container justify="flex-end" spacing={2}>
                <Grid item>
                  <Button
                    type="button"
                    color="default"
                    size="large"
                    disabled={!searching}
                    onClick={() => window.location.reload()}
                  >
                    Cancel Search
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    color="primary"
                    size="large"
                    loading={searching}
                    // disabled={true}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Query;
