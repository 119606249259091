import React, { useContext } from "react";
import ActionDialog from "./ActionDialog";
import { ActionContext } from "../../../../contexts";
import { connect } from "react-redux";
import { EditorArticle } from "../../../shared/Editor";
import { EditorState, convertFromRaw } from "draft-js";
// import eachPlayer from "modules/dashboard/components/EachPlayer"

let Action = (props) => {
  let { playerId, categoryId, isEditable , actionPlan,open, content, id,currentData, accountType,customData } = props;
  let { dialogActions,playerActions } = useContext(ActionContext);
  // const selectedPlayer = eachPlayer && eachPlayer[playerId]
  // const actionPlanData = selectedPlayer?.insights["action_plan"];
  
  // const actionPlan = actionPlanData[categoryId]

  // let editorState = actionPlan.blocks
  //   ? EditorState.createWithContent(convertFromRaw(actionPlan))
  //   : EditorState.createEmpty();
  let editorState = content && content.blocks
  ? EditorState.createWithContent(convertFromRaw(content))
  : EditorState.createEmpty()

  let handleEditClick = () => {
    dialogActions.openDialog(
      <ActionDialog
        playerId={playerId}
        categoryId={categoryId}
        initialEditorState={editorState}
        openEdit={open}
        id={id}
      />
    );
  };
  function handleDelete() {
    let data = {
      candidate_id: playerId,
      category: "Custom",
      _id: id
    };

    playerActions.deleteActionPlanComment(data,currentData);
  }

  return (
    <EditorArticle
      onEdit={handleEditClick}
      isEditable={isEditable}
      editorState={editorState}
      placeholder="Psychologist comments pending"
      isDelete={open}
      playerId={playerId}
      id={id}
      onDelete={handleDelete}
      currentData={currentData}
      accountType={accountType}
    />
  );
};

let mapStateToProps = (state, ownProps) => {
  let { playerId, categoryId } = ownProps;
  return {
    accountType: state.account.accountType,
    actionPlan: state.player.actionPlan[`${playerId}.${categoryId}`],
  };
};
export default connect(mapStateToProps)(Action);
