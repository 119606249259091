import apiHandler, { getAuthHeader } from "./apiHandler";


export const getConference = async () => {
  try {
    let headers = getAuthHeader();
    let response = await apiHandler.get("/conference", { headers });
    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    const stats = response.data.info.conferences;


    return stats;
  } catch (err) {
    throw new Error(err);
  }
};

export const getTeamDetails = async (conferenceId) => {
  try {
    const headers = getAuthHeader();

    const response = await apiHandler.get(`/findteamsbyconference/${conferenceId}`, {
      headers,
    });

    if (response && response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    const teamData = response.data.info;

    return teamData;
  } catch (err) {
    throw new Error(err);
  }
};
export const getSportDetails = async (conferenceId) => {
  try {
    const headers = getAuthHeader();

    const response = await apiHandler.get(`/findsportsbyconference/${conferenceId}`, {
      headers,
    });

    if (response && response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    const teamData = response.data.info.sports;

    return teamData;
  } catch (err) {
    throw new Error(err);
  }
}

export const addNewConference = async (model) => {
  try {
    let headers = getAuthHeader();
    const response = await apiHandler.post("/create_conference", model, { headers });
    return response.data;
  } catch (err) {
    console.error(err);
    throw new Error("Failed to create conference");
  }
};


export const deleteConference = async (conferenceId) => {
  try {
    let headers = getAuthHeader()
    let response = await apiHandler.delete(`/conference/delete/${conferenceId}`, { headers });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    return response.data;
  } catch (err) {
    throw new Error(err);
  }
}

export const getAllCountries = async () => {
  try {
    let headers = getAuthHeader()
    let response = await apiHandler.get(`/country`, { headers });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }



    let organizationCountry = response.data.info.country.reduce((obj, item) => {
      obj[item] = item;
      return obj;
    }, {});

    let candidateCountry = JSON.parse(JSON.stringify(organizationCountry));

    // return response.data;
    return {
      candidateCountry
    }

  } catch (err) {
    throw new Error(err)
  }
}

export const getStates = async (countryName) => {
  try {
    let headers = getAuthHeader()
    let response = await apiHandler.get(`/country_states/${countryName}`, { headers });

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    let organizationState = response.data.info.states.reduce((obj, item) => {
      obj[item] = item;
      return obj;
    }, {});

    let candidateState = JSON.parse(JSON.stringify(organizationState));

    // return response.data;
    return {
      candidateState
    }

  } catch (err) {
    throw new Error(err)
  }
}

export const getConferenceByType = async (conference_type) => {
  try {
    let headers = getAuthHeader()
    let response = await apiHandler.get(`/GetConferencesByType/${conference_type}`, { headers })

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification);
    }

    const conferenceData = response.data.info.conferenceType;

    return conferenceData;
  } catch (err) {
    throw new Error(err)
  }
}

export const createNewTeam = async (model) => {
  try {
    let headers = getAuthHeader()
    let response = await apiHandler.post(`/create-team`,model, { headers })

    if (response.data.status !== "Success") {
      throw new Error(response.data.notification)
    }

    return response.data.info

  }
  catch (err) {
    throw new Error(err)
  }
}

const conference = {
  getConference,
  getTeamDetails,
  addNewConference,
  deleteConference,
  getAllCountries,
  getStates,
  getConferenceByType,
  getSportDetails,
  createNewTeam
};

export default conference;