import React from "react";
import Grid from "@material-ui/core/Grid";
import LinkedConsentFormCard from "./LinkedConsentFormCard";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
// import allConsents from "modules/dashboard/components/ConsentDummyData"

const LinkedConsentForms = (props) => {
  let { consentFormsById } = props;

  // const consentFormsById = Object.keys(allConsents["linked_consent_forms"])

  return (
    <div>
      {consentFormsById.length > 0 ? (
        <Grid container spacing={4}>
          {consentFormsById.map((id) => {
            return (
              <Grid
                key={id}
                lg={3}
                md={3}
                sm={6}
                justifyContent="center"
                container
                item
              >
                <LinkedConsentFormCard consentFormId={id} />
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            height: 200,
          }}
        >
          <Typography variant="caption" color="textSecondary" align="center">
            No Linked Consent Forms
          </Typography>
        </div>
      )}
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    consentFormsById: state.consent.linkedConsentForms.allIds,
    loadingConsentForms: state.ui.consent.loadingLinkedConsentForms,
  };
};

export default connect(mapStateToProps)(LinkedConsentForms);
