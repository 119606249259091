import { blue } from "@material-ui/core/colors";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import React from "react";
import { useContext } from "react";
import { connect } from "react-redux";
import { ActionContext } from "../../contexts";
import { Button, DialogActions } from "@material-ui/core";
import { useEffect } from "react";
import { createContext } from 'react';

const useStyles = makeStyles((theme) => ({
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
    enabled: {
        color: theme.palette.success.main,
    },
    pending: {
        color: theme.palette.warning.main,
    },
    btnPrimary: {
        color: theme.palette.primary.main,
        border: '1px solid',
        height: '30px',
        padding: '10px',
        marginRight: '10px',
        borderRadius: '20px',
        '&:hover': {
            background: theme.palette.primary.main,
            color: '#fff'
        },
    },
    btnSecondary: {
        color: theme.palette.secondary.main,
        border: '1px solid',
        height: '30px',
        padding: '10px',
        marginRight: '10px',
        borderRadius: '20px',
        '&:hover': {
            background: theme.palette.secondary.main,
            color: '#fff'
        }
    },
}));

const AccountActivateDialog = (props) => {
    const classes = useStyles();
    let { dashboardActions, playerActions, snackbarActions } = useContext(ActionContext);
    const {
        data, loadingAccounts,
        closeDialog,
    } = props;



    const handleCloseDialog = () => {
        closeDialog();
    };

    const handleSuccess = (account) => {
        closeDialog()
        snackbarActions.showSnackbar(account === 'deactive' ? "Account Deactivated" : "Account Activated");
        dashboardActions.getAccountsList({ team: "" });
    };

    const handleSave = () => {
        let model = {
            email: data.email
        }
        if (data.status === 'ACTIVE' || data.status === 'PENDING') {
            dashboardActions.deActivateAccount(model, handleSuccess('deactive'))
        } else if (data.status === 'DEACTIVATED') {
            dashboardActions.activateAccount(model, handleSuccess('active'))
        }
    }

    useEffect(() => {
        if (loadingAccounts) {
            handleCloseDialog()
        }
    }, [loadingAccounts])

    return (
        <React.Fragment>
            <div style={{ display: "flex", background: '#cdcdcd', justifyContent: 'flex-end' }}>
                {/* <DialogTitle id="simple-dialog-title" style={{ flex: 1 }}>
                    Confirmation
                </DialogTitle> */}
                <div style={{ margin: "8px 16px 8px 8px", display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton onClick={handleCloseDialog}>
                        <CloseIcon style={{ color: "blue" }} />
                    </IconButton>
                </div>
            </div>

            <div style={{ display: "flex", justifyContent: 'center' }}>

                <h3 style={{
                    display: "flex", justifyContent: 'center', height: '7rem',
                    color: '#0070b9',
                    fontSize: '1.5rem',
                    fontWeight: 700,
                    alignItems: 'center'
                }}> {data.status === 'ACTIVE' || data.status === 'PENDING' ? 'Would you like to Deactivate Account?' : 'Would you like to Activate Account?'}</h3>

            </div>

            <div>
                <DialogActions>
                    {
                        <>
                            <Button className={classes.btnSecondary} onClick={handleCloseDialog}>No</Button>
                            <Button className={classes.btnPrimary} onClick={handleSave}>Yes</Button>
                        </>
                    }
                </DialogActions>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state, ownProps) => {

    return {
        // loadingReqPlanReports: state.ui.player.loadingReqPlanReports,
        // alreadyRequestedPlusReport: state.ui.player.alreadyRequestedPlusReport,
        // requestedReportData: state.player.requestedReportData,
    };
};
export default connect(mapStateToProps)(AccountActivateDialog);
