import { combineReducers } from "redux";
import * as types from "./sportsType";

const sportsListLoading = (state = false, action) => {
    switch (action.type) {
        case types.GET_ALL_SPORTS_REQUEST: {
            return true;
        }

        case types.GET_ALL_SPORTS_SUCCESS:
        case types.GET_ALL_SPORTS_FAILED: {
            return false;
        }

        default:
            return state;
    }
};

const searchKey = (state = "", action) => {
    switch (action.type) {
        case types.SEARCH_SPORT: {
            return action.payload.key;
        }

        case types.RESET_SEARCH: {
            return "";
        }

        default:
            return state;
    }
};

const selectedSportId = (state = null, action) => {
    switch (action.type) {
        case types.SELECT_SPORT: {
            return action.payload.sportId;
        }

        case types.DESELECT_SPORT: {
            return null;
        }

        default:
            return state;
    }
};

const loadingUnitSport = (state = [], action) => {
    switch (action.type) {
        case types.GET_SPORT_UNIT_BY_SPORT_ID_REQUEST: {
            return true;
        }

        case types.GET_SPORT_UNIT_BY_SPORT_ID_SUCCESS:
        case types.GET_SPORT_UNIT_BY_SPORT_ID_FAILED: {
            return false
        }

        default:
            return state;
    }
};

const loadingSportPosition = (state = [], action) => {
    switch (action.type) {
        case types.GET_SPORT_POSITION_BY_UNIT_ID_REQUEST: {
            return true;
        }

        case types.GET_SPORT_POSITION_BY_UNIT_ID_SUCCESS:
        case types.GET_SPORT_POSITION_BY_UNIT_ID_FAILED: {
            return false
        }

        default:
            return state;
    }
};

const selectedSportsPageId = (state = 'list', action) => {
    switch (action.type) {
      
      case types.SELECTED_PAGE_TYPE: {
        return action.payload;
      }
  
      default:
        return state;
    }
  };
  
  const selectedSportsTypeId = (state = null, action) => {
    switch (action.type) {
      
      case types.SELECTED_SPORTS_TYPE: {
        return action.payload;
      }
  
      default:
        return state;
    }
  };

export default combineReducers({
    sportsListLoading,
    searchKey,
    selectedSportId,
    loadingUnitSport,
    loadingSportPosition,
    selectedSportsPageId,
    selectedSportsTypeId
});