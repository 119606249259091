import { combineReducers } from "redux";
import * as types from "./conferenceType";

const getAllConferenceData = (state = [], action) => {
  const { payload } = action;
  switch (action.type) {
    case types.SET_CONFERENCES_SUCCESS: {
      return payload
    }

    default:
      return state;
  }
};

const teamByConference = (state = [], action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_TEAMS_BY_CONFERENCE_ID_SUCCESS: {
      // return {
      //   ...state,
      //   payload
      // };
      return payload.teams
    }

    case types.RESET_TEAMS_BY_CONFERENCE_ID: {
      return payload 
    }

    default:
      return state;
  }
}
const sportInConference = (state = [], action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_TEAMS_BY_CONFERENCE_ID_SUCCESS: {
      // return {
      //   ...state,
      //   payload
      // };
      return payload.sports
    }

    case types.RESET_TEAMS_BY_CONFERENCE_ID: {
      return payload 
    }


    default:
      return state;
  }
}


const sportByConference = (state = [], action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_SPORTS_BY_CONFERENCE_ID_SUCCESS: {
      return payload
    }

    case types.RESET_SPORTS_BY_CONFERENCE_ID: {
      return payload 
    }

    default:
      return state;
  }
}

const allCountries = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_COUNTRIES_SUCCESS: {
      // return payload
      return { ...payload.country };
    }
    default:
      return state
  }
}

const allStateByCountry = (state = {}, action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_COUNTRIES_STATES_SUCCESS: {
      // return payload
      return { ...payload.state };
    }
    default: {
      return state
    }
  }
}

const allConferenceByType = (state = [], action) => {
  const { payload } = action;

  switch (action.type) {
    case types.GET_CONFERENCE_BY_TYPE_SUCCESS: {
      return payload
    }

    default: {
      return state
    }
  }
}

const newTeamId = (state = "", action) => {
  const { payload } = action;

  switch (action.type) {

    case types.CREATE_NEW_TEAM_SUCCESS: {
      return payload.id
    }

    case types.RESET_CREATE_NEW_TEAM: {
      return ""
    }

    default:
      return state;
  }
}

const allConferenceList = combineReducers({
  allData: getAllConferenceData,
});


export default combineReducers({
  allConferenceList,
  teamByConference,
  allCountries,
  allStateByCountry,
  allConferenceByType,
  sportByConference,
  sportInConference,
  newTeamId
});


// const stats = (
//   state = {
//     conferenceList: [],
//   },
//   action
// ) => {
//   const { payload } = action;
//   switch (action.type) {
//     case types.SET_CONFERENCES_SUCCESS: {
//       return {
//         conferenceList: payload
//       };
//     }
//     default:
//       return state;
//   }
// };
// export default combineReducers({
//   stats,
// });
