import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Typography, Grid, Card, CardHeader } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

let useStyles = makeStyles((theme) => ({
  card: {
    backgroundImage: 'url("/assets/images/illustrations/ChatBackground.jpeg")',
    boxShadow: '3px 3px 10px 3px #2dbcb2',
    '&:hover': {
        boxShadow: '3px 3px 10px 3px #F4AB3D',
        cursor: 'pointer'
    }
},
  avatar: {
    marginRight: theme.spacing(1),
  },
}));

const PlayerCardSkeleton = () => {
  const classes = useStyles();
  return (
      <Grid item xs={12} sm={3}>
        <Card className={classes.card}>
          <CardHeader
            avatar={
              <Skeleton
                variant="circle"
                width={40}
                height={40}
              />
            }
            action={<Typography variant="body1">
              <Skeleton width={130} />
            </Typography>}
            title={<Typography variant="subtitle2">
              <Skeleton width={130} />
            </Typography>}
            subheader={<Typography variant="subtitle2">
              <Skeleton width={100} />
            </Typography>}
          />
        </Card>
      </Grid>
  );
};

export default function Animations() {
  let classes = useStyles();
  return (
    <React.Fragment>
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
        <PlayerCardSkeleton />
    </React.Fragment>
  );
}
