import React from "react";
import Tabs from "./Tabs";
import Tab from "./Tab";

let TabsContainer = ({ labels = [], value, onChange, ...rest }) => {
  return (
    <Tabs value={value} onChange={onChange} {...rest}>
      {labels.map((label, index) => {
        return <Tab style={{ color: value === index ? '#fcb643' : '' }} key={index} label={label} />;
      })}
    </Tabs>
  );
};

export default TabsContainer;
