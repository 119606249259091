import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { convertFromRaw, EditorState } from "draft-js";
import React, { useContext } from "react";
import { connect } from "react-redux";
import { ActionContext } from "../../../../contexts";
import { Article } from "../../../shared/Article";
import { EditorArticle } from "../../../shared/Editor";
import { accountTypes } from "../../constants";
import LinkList from "./LinkList";
import LinksDialog from "./LinksDialog";
import NotesDialog from "./NotesDialog";

let useStyles = makeStyles((theme) => ({
  heading: {
    justifyContent: "space-between",
  },
}));

let Notes = (props) => {
  let classes = useStyles();
  let { dialogActions } = useContext(ActionContext);
  let { notes, playerId, accountType } = props;

  let editorState = notes.blocks
    ? EditorState.createWithContent(convertFromRaw(notes))
    : EditorState.createEmpty();

  let handleEditClick = () => {
    dialogActions.openDialog(
      <NotesDialog playerId={playerId} initialEditorState={editorState} />
    );
  };

  let allowedAccountTypes = [
    accountTypes.superAdmin,
    accountTypes.bcaPsychologist,
    accountTypes.bcaDdiAdmin,
  ];

  let isEditable = allowedAccountTypes.includes(accountType);

  let handleLink = () => {
    dialogActions.openDialog(<LinksDialog playerId={playerId} />, {
      maxWidth: "sm",
    });
  };

  return (
    <React.Fragment>
      <EditorArticle
        placeholder="Notes not added yet"
        editorState={editorState}
        isEditable={isEditable}
        onEdit={handleEditClick}
      />
      <Article
        title="Links"
        classes={{ heading: classes.heading }}
        button={
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<AddIcon />}
            onClick={handleLink}
            disableElevation
          >
            Add Link
          </Button>
        }
      >
        <LinkList playerId={playerId} />
      </Article>
    </React.Fragment>
  );
};

let mapStateToProps = (state, ownProps) => {
  let { playerId } = ownProps;
  return {
    notes: state.player.notes[playerId],
    accountType: state.account.accountType,
  };
};
export default connect(mapStateToProps)(Notes);
