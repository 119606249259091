import React from "react";
import IconButton from "@material-ui/core/IconButton";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import Badge from "@material-ui/core/Badge";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.grey[100],
  },
}));

let AlertIconButton = (props) => {
  let { onClick, notificationCount = null } = props;
  let classes = useStyles();
  let handleClick = (e) => onClick(e);

  return (
    <IconButton size="small" onClick={handleClick} className={classes.root}>
      <Badge badgeContent={notificationCount} color="primary">
        <NotificationsIcon style={{color: '#000'}} />
      </Badge>
    </IconButton>
  );
};

export default AlertIconButton;
