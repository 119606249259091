import React from "react";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import PositiveIcon from "@material-ui/icons/SentimentVerySatisfied";
import NegativeIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import NeutralIcon from "@material-ui/icons/SentimentSatisfied";
import Typography from "@material-ui/core/Typography";

const FeedBottomNavigation = (props) => {
  let { tab, onChange, stats } = props;

  return (
    <BottomNavigation value={tab} onChange={onChange} showLabels>
      <BottomNavigationAction
        label={
          <Typography variant="subtitle1">
            {stats.positivePercentage === "nodata"
              ? "Positives"
              : `Positives ${stats.positivePercentage}%`}
          </Typography>
        }
        icon={<PositiveIcon />}
      />

      <BottomNavigationAction
        label={
          <Typography variant="subtitle1">
            {stats.neutralPercentage === "nodata"
              ? "Neutrals"
              : `Neutrals ${stats.neutralPercentage}%`}
          </Typography>
        }
        icon={<NeutralIcon />}
      />
      <BottomNavigationAction
        label={
          <Typography variant="subtitle1">
            {stats.negativePercentage === "nodata"
              ? "Negatives"
              : `Negatives ${stats.negativePercentage}%`}
          </Typography>
        }
        icon={<NegativeIcon />}
      />
    </BottomNavigation>
  );
};

export default FeedBottomNavigation;
