import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Slide, InputBase, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ActionContext } from "../../contexts";
import AccountActivateDialog from "./dialogActivate"
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "modules/shared/CircularProgress";
import Grid from "@material-ui/core/Grid";


let useStyles = makeStyles((theme) => ({
    root: {
        // backgroundColor: theme.palette.background.default,
        background: '#fff',
        display: "flex",
        height: `calc(100vh - 50px)`,
        zIndex: theme.zIndex.appBar,
    },
    DataTableALignmentFullName: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        cursor: 'pointer',
        width: '100rem',
        height: '1.2em',
        whiteSpace: 'nowrap',
        marginRight: '1rem'
    },
    DataTableALignmentEmail: {
        // width: '15rem',
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'flex-start',
        width: '150rem',
        cursor: 'pointer',
        height: '1.2em',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        marginRight: '1rem',
        textOverflow: 'ellipsis',
    },
    StatusAligncss: {
        border: '1px solid #000',
        padding: '0.2rem 0.6rem',
        fontSize: '12px',
        fontWeight: '500',
        borderRadius: '9px',
    },
    ViewALlbutton: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '1rem',
        fontSize: '13px',
        color: '#0971ce',
        fontWeight: 700,
        cursor: 'pointer',
    },
    fullName: {
        '&:hover': {
            color: '#fcb643',
            borderBottom: '1px solid #fcb643',
            cursor: 'pointer'
        },
    },
    RowDataALignment: {
        display: 'block',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    CenterAlignwidth: {
        // justifyContent: 'center',
        marginBottom: '2rem',
        cursor: 'pointer'
    },
    FlexALignDataflow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '2px solid #fcb643',
    },
    RowALignment: {
        border: 'solid 1px #f2f2f2',
        borderRadius: '9px',
        backgroundColor: '#fff',
        marginBottom: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        padding: '0rem 0.5rem'
    },
    FlexAlignData: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    container: {
        padding: 16,
        // paddingTop: 48,
        // marginLeft: "auto",
        // marginRight: "auto",
        // flexWrap: "wrap",
        width: '100%',
        [theme.breakpoints.down("md")]: {
            maxWidth: 550,
        },
    },
    title: {
        marginBottom: theme.spacing(4),
    },
    GridFlowALign: {
        // display: 'flex',
        // width: '-webkit-fill-available',
        // overflow: 'auto',
        // height: 'fit-content',
        padding: '1rem 2rem',
        background: '#fff'
    },
    inputRoot: {
        color: 'inherit',
    },
    DataTableALignment: {
        width: '100%',
        padding: '0.8rem 0.4rem',
        fontWeight: '300',
        display: 'block',
        color: '#333333',
        fontSize: '12px',
        justifyContent: 'start',
    },
    HeaderDataTableALignment: {
        width: '100%',
        padding: '0.8rem 0.4rem',
        display: 'block',
        fontWeight: 700,
        justifyContent: 'start',
        margin: '0rem 3rem'
    },
    FlexALignData: {
        display: 'flex'
    },
    inputInput: {
        padding: '8px 15px',
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        paddingLeft: '10px',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
            border: '1px solid #0971d6',
            color: '#0971ce',
        },
        borderRadius: '10px',
        boxShadow: '3px 1px 9px 0px #cdcdcd',
    },
    link: {
        textDecoration: "underline",
        color: theme.palette.primary.main,
    },
    btnPrimary: {
        color: '#fff',
        border: '1px solid',
        padding: '10px',
        background: '#0971ce',
        width: '13rem',
        marginRight: '10px',
        borderRadius: '20px',
        '&:hover': {
            background: '#0971ce',
            color: '#fff'
        },

    },
    load: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        // width: 215,
        borderLeft: '5px solid #fcb643',
        borderRadius: '10px',
        // minHeight: 113,
        padding: '10px 16px',
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            flexDirection: "row-reverse",
            justifyContent: "flex-end",
            height: 64,
            padding: theme.spacing(2),
        },
    },

}));
const AccountList = (props) => {
    let classes = useStyles();
    let history = useHistory();
    let { accountListDetails, teams, updateFilter, loadingAccounts, accountType, userEmail } = props;
    let { dashboardActions, playerActions, dialogActions } = useContext(ActionContext);

    useEffect(() => {
        dashboardActions.getAccountsList({ team: '' });
        // dashboardActions.selectAccountFilter("searchTeam", '');
        playerActions.getTeamList();

    }, []);


    const handleCreateAccount = () => {
        history.push("/create-user");
    }

    function handleSearchText(v) {
        dashboardActions.selectAccountFilter("searchText", v);
    }
    const handleTeamChange = (event) => {
        dashboardActions.selectAccountFilter("searchTeam", event.target.value);
        let model = {
            team: event.target.value
        }
        dashboardActions.getAccountsList(model);
    };
    function handleViewAllChange() {
        dashboardActions.selectAccountFilter("searchText", undefined);
        dashboardActions.selectAccountFilter("searchTeam", undefined);
        dashboardActions.getAccountsList({ team: "" });
    }
    const handleOpenDialog = (data) => {
        dialogActions.openDialog(
            <AccountActivateDialog data={data} loadingAccounts={loadingAccounts} />,
            {
                maxWidth: "xs",
            })

    };
    // const filteredAccountsData = searchText && searchText ? accountListDetails?.filter(v => v.full_name?.toLowerCase().includes(searchText.toLowerCase())) : accountListDetails && accountListDetails;

    const filteredSearchData = updateFilter?.searchText ? accountListDetails?.filter(v => v.full_name.toLowerCase().includes(updateFilter.searchText.toLowerCase())) : accountListDetails && accountListDetails
    const filteredAccountsData = updateFilter?.searchTeam ? filteredSearchData?.filter(v => v.team?.toLowerCase().includes(updateFilter.searchTeam.toLowerCase())) : filteredSearchData && filteredSearchData

    return (
        <div>
            <div className={classes.root} >
                <div className={classes.container}>
                    <div className={classes.GridFlowALign}>
                        <div className={classes.FlexALignDataflow}>
                            <div className={classes.FlexAlignData}>
                                <div style={{ fontSize: '18px', fontFamily: 'Prometo', fontWeight: 'bold', color: '#0971ce' }}>Accounts List</div>

                            </div>
                            <div>
                                <Button onClick={handleCreateAccount} className={classes.btnPrimary}> Create Account</Button>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div className={classes.ViewALlbutton} onClick={handleViewAllChange}>View All</div>
                                <InputBase
                                    style={{ marginRight: '1rem' }}
                                    placeholder="Search Account Name"
                                    // value={searchText}
                                    value={updateFilter?.searchText !== undefined ? updateFilter?.searchText : ""}
                                    name="searchText"
                                    classes={{
                                        root: classes.inputRoot,
                                        input: classes.inputInput,
                                    }}
                                    inputProps={{ 'aria-label': 'search' }}
                                    onChange={ev => handleSearchText(ev.target.value)}
                                />


                                <FormControl variant="filled">
                                    <InputLabel id="demo-simple-select-autowidth-label">Team</InputLabel>
                                    <Select
                                        style={{ width: "200px", marginBottom: '1rem', background: '#fff' }}
                                        labelId="demo-simple-select-autowidth-label"
                                        id="demo-simple-select-autowidth"
                                        // value={team}
                                        value={updateFilter?.searchTeam !== undefined ? updateFilter?.searchTeam : ""}
                                        onChange={handleTeamChange}
                                        label="Team"
                                        name="team"
                                    >
                                        {
                                            Object.values(teams) && Object.values(teams).map((list, i) =>
                                                <MenuItem style={{ background: '#fff' }} key={i} value={teams[list]}>{teams[list]}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>

                            </div>
                        </div>
                        <TableContainer>
                            <div>
                                <div>
                                    {/* <div className={classes.FlexALignData}>
                                        <span className={classes.HeaderDataTableALignment} align="left">Name</span>
                                        <span className={classes.HeaderDataTableALignment} align="left">Email</span>
                                        <span className={classes.HeaderDataTableALignment} align="left">Team</span>
                                        <span className={classes.HeaderDataTableALignment} align="left">Role</span>
                                      
                                        <span className={classes.HeaderDataTableALignment} align="right">Status</span>
                                        <span className={classes.HeaderDataTableALignment} align="right">Action</span>

                                    </div> */}
                                </div>
                                {

                                    <div className={classes.RowDataALignment}>
                                        <Grid style={{
                                            margin: '1rem 1rem',
                                            boxShadow: '0 2px 12px 0 rgb(0 0 0 / 10%)',
                                            padding: '1rem',
                                        }}>
                                            {loadingAccounts && loadingAccounts ?
                                                // <Skeleton animation="wave" width={1200} height={100} />

                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <CircularProgress size={72} thickness={3} color="primary" />
                                                </div>

                                                :
                                                (filteredAccountsData && filteredAccountsData.length === 0) ?
                                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', color: '#e61e21' }}>
                                                        {"No Accounts found"}
                                                    </div> :
                                                    filteredAccountsData && filteredAccountsData.map((result, index) => (
                                                        <div className={classes.RowALignment}>
                                                            <span className={classes.DataTableALignmentFullName} style={{ fontWeight: '700', fontFamily: 'Prometo', color: '#333333', }} title={result.full_name} align="left" >{result.full_name}</span>
                                                            <span className={classes.DataTableALignmentEmail} style={{ color: '#666666' }} title={result.email} align="left">{result.email}</span>
                                                            <span className={classes.DataTableALignment} style={{ color: '#666666' }} align="left">{result.team}</span>
                                                            <span className={classes.DataTableALignment} style={{ color: '#666666' }} align="left">{result.account_type}</span>
                                                            {/* <span className={classes.DataTableALignment} style={{ color: '#666666' }} align="left">{result.sport}</span> */}
                                                            <span className={classes.DataTableALignment} align="left">{result.status}</span>
                                                            <span className={classes.DataTableALignment} align="right">
                                                                {
                                                                    (userEmail === result.email) ? ""
                                                                        :
                                                                        ((accountType === 'BCA_DDI_ADMIN' && result.team !== 'BCA-DDI') || (accountType === 'SUPER_ADMIN') ?
                                                                            <a className={classes.link} onClick={() => handleOpenDialog(result)}>{result.status === 'ACTIVE' || result.status === 'PENDING' ? "Deactivate" : 'Activate'}</a>
                                                                            : '')
                                                                }
                                                            </span>

                                                        </div>
                                                    ))

                                            }
                                        </Grid>
                                    </div>
                                }
                            </div>
                        </TableContainer>
                    </div>
                </div>
            </div >

        </div >
    );
}
let mapStateToProps = (state) => {
    return {
        accountType: state.account.accountType,
        accountListDetails: state.dashboard.accountListReducer,
        teams: state.ui.player.allCandidateTeams,
        userTeam: state.account.team,
        updateFilter: state.dashboard.updateAccountFilter,
        loadingAccounts: state.ui.dashboard.loadingAccounts,
        userEmail: state.account.email,
    };
};
export default connect(mapStateToProps)(AccountList);