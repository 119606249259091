import React from "react";

const IFrame = (props) => {
  const { width = 560, height = 315, url } = props;
  return (
    <iframe
      title={url}
      width={width}
      height={height}
      style={{ borderRadius: 8 }}
      src={url}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
};

export default IFrame;
